import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function EmergencyNurseVitals() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);


  const [page, setPage] = useState(0);
  const [summa, setsumma] = useState([]);
  const [getdatastate, setgetdatastate] = useState(false);

  const [VitalFormData, setVitalFormData] = useState({
    Temperature: "",
    PulseRate: "",
    SPO2: "",
    HeartRate: "",
    RR: "",
    SBP: "",
    DBP: "",
    Position: "",
    Part: "",
    Method: "",
    Height: "",
    Weight: "",
    BMI: "",
    WC: "",
    HC: "",
    ETCO2: "",
    BreathSounds: "",
    CapturedDate: "",
    Time: "",
  });
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);
  
    
      setVitalFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    
};

const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  

  useEffect(() => {
    const datte = format(new Date(), "yyyy-MM-dd");
    setVitalFormData((prev) => ({
      ...prev,
      CapturedDate: datte,
    }));
  }, []);
  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `https://vesoftometic.co.in/EmergencyManagement/get_Vital_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data;
          console.log("222", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching doctor names:", error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id, getdatastate]);

  const pageSize = 10;
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  useEffect(() => {
    if (VitalFormData.Weight && VitalFormData.Height) {
      const parsedWeight = parseFloat(VitalFormData.Weight);
      const parsedHeight = parseFloat(VitalFormData.Height) / 100; // Convert cm to m
      const calculatedBMI = (
        parsedWeight /
        (parsedHeight * parsedHeight)
      ).toFixed(2);

      setVitalFormData((prev) => ({
        ...prev,
        BMI: calculatedBMI,
      }));
    }
  }, [VitalFormData.Weight, VitalFormData.Height]);

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: 'id',
      headerName: 'S_No',
      width: 40,
    },
    ...Object.keys(VitalFormData).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);
  
      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 60,
      };
    })
    
  ];
  
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }

  const cleardata = () => {
    setVitalFormData({
      Temperature: "",
      PulseRate: "",
      SPO2: "",
      HeartRate: "",
      RR: "",
      SBP: "",
      DBP: "",
      Position: "",
      Part: "",
      Method: "",
      Height: "",
      Weight: "",
      BMI: "",
      WC: "",
      HC: "",
      ETCO2:"",
      BreathSounds:"",
      CapturedDate: "",
      Time: "",
    });
  };

  const Post_vital_data = () => {
    const requiredfields = [
      "Temperature",
      "PulseRate",
      "SPO2",
      "HeartRate",
      "RR",
      "SBP",
      "DBP",
      "Position",
      "Part",
      "Method",
      "Height",
      "Weight",
      "BMI",
      "WC",
      "HC",
      "ETCO2",
      "BreathSounds",
      "CapturedDate",
      "Time",
    ];
    const existing = requiredfields.filter((field) => !VitalFormData[field]);

    if (existing > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const Allsenddata = {
        ...VitalFormData,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/EmergencyManagement/insert_Vital_datas`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          cleardata();
          setgetdatastate(!getdatastate);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="RegisFormcon">
          {Object.keys(VitalFormData).map((labelname, index) => (
            <div className="RegisForm_1" key={index}>
              <label>
                {formatLabel(labelname)}  <span>:</span>
              </label>
              {labelname === "Method" ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Electronic">Electronic</option>
                  <option value="Manual">Manual</option>
                  <option value="Direct Arterial Line">Direct Arterial Line</option>
                </select>
              ) : labelname === "Position" ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Prone">Prone</option>
                  <option value="Supine">Supine</option>
                  <option value="Left Lateral">Left Lateral</option>
                  <option value="Right Lateral">Right Lateral</option>
                </select>
              ) : labelname === "Part" ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Left Arm">Left Arm</option>
                  <option value="Right Arm">Right Arm</option>
                  <option value="Right Leg">Right Leg</option>
                  <option value="Left Leg">Left Leg</option>
                </select>
              ) : labelname === "BreathSounds" ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Clear">Clear</option>
                  <option value="Ronchi">Ronchi</option>
                  <option value="Wheezes">Wheezes</option>
                  <option value="Crackles">Crackles</option>
                  <option value="Bilat equal & clear">Bilat equal & clear</option>
                </select>
              ) : (
                <input
                  type={labelname === "CapturedDate" ? "date" : labelname === "Time" ? "time":  "number"}
                  name={labelname}
                  onKeyDown={blockInvalidChar}
                  // placeholder={`Enter the ${formatLabel(labelname)}`}
                  value={VitalFormData[labelname]}
                  readOnly={labelname === "CapturedDate"}
                  onChange={handleInputChange}
                />
              ) }
            </div>
          ))}
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={Post_vital_data}>
            Add
          </button>
    
        </div>

        {/* <div style={{display:'grid',placeItems:'center',width:'100%'}}>
          <button  className='btn-add' onClick={Post_vital_data}>
            Add
          </button>
        </div> */}
        
        

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
     
     
      </div>
      <ToastContainer />
    </>
  );
}
