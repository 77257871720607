import { React, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import HotelIcon from '@mui/icons-material/Hotel';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { differenceInYears, format } from "date-fns";
import "../../RegistrationForm/Registration.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import femalpatient from "../../assets/femalpatient.png";
import malepatient from "../../assets/malepatient.png";
function InPatientRegistration() {

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: 'toast-container-over-header',
      style: { marginTop: '50px' },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };
  const errmsg = (errormsg) => {
    toast.error(`${errormsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };



  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const userRecord = useSelector(state => state.userRecord?.UserData);


  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);



  const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const [Registerformdata, setRegisterformdata] = useState({
    // BookingId:'',
    AdmissionPurpose: '',
    PatientID: '',
    PhoneNo: '',
    FamilyPhoneNo: "",
    Title: "",
    FirstName: "",
    MiddleName: "",
    SurName: "",
    Email: '',
    Gender: '',
    DOB: '',
    Age: '',
    BloodGroup: '',
    Relegion: '',
    Nationality: "",
    UniqueIdType: "",
    UniqueIdNo: "",
    Specialization: "",
    PrimaryDoctor: '',
    RouteNo: "",
    RouteName: "",
    TeshilName: "",
    VillageName: "",
    RefferType: "",
    RefferedBy: "",
    AdmitDate: '',
    AdmitTime: '',
    AttenderName: '',
    AttenderMobileNo: '',
    Relation: '',
    PrimaryDisease: '',
    Complaint: '',
    PatientPhoto: "",
    PatientType: "General",
    PatientCategory: "General",
    RateCardType: "General",
    ColorFlag: "",
    MLC: 'No',
    Location: '',
    CreatedBy: '',


    BuildingName: '',
    BlockName: '',
    FloorName: '',
    WardType: '',
    RoomType: '',
    RoomCharge: '',
    GSTCharge: '',
    RoomNo: '',
    BedNo: '',
    RoomId: '',


    Country: '',
    State: '',
    City: '',
    Place: '',
    Street: '',
    Pincode: '',


    SameAsContactAddress: 'No',
    AttenderCountry: '',
    AttenderState: '',
    AttenderCity: '',
    AttenderPlace: '',
    AttenderStreet: '',
    AttenderPincode: '',

    ClientName: '',

    InsuranceName: '',
    Scheme: '',
    Plan: '',
    TPA: '',
    InsuranceNo: '',
    AvailedType: '',
    ValidFrom: '',
    ValidUpto: '',
    InsuredPerson: '',
    Remarks: '',
    EstimateBill: '',
    PreAuthAmount: '',
    CoPaymentType: 'Percentage',
    InsuranceContribution: '',
    PatientContribution: '',
    CoPaymentLogic: '',


    InformedBY: '',
    OfficerName: '',
    PoliceStation: '',
    FIRNo: '',
    CodeStatus: '',
    TypeofAdmission: '',
    AdmittingDiagnosis: '',


  })



  const [PatientListSearch, setPatientListSearch] = useState([]);
  const [FilteredFormdata, setFilteredFormdata] = useState([]);
  const [SpecialitiesData, setSpecialitiesData] = useState([])
  const [InsuranceName, setInsuranceName] = useState([]);
  const [ClientName, setClinentName] = useState([]);
  const [RouteNo, setRoute_No] = useState([]);
  const [RouteName, setRouteName] = useState([]);
  const [ThasilName, setThasilName] = useState([]);
  const [VillageName, setVillageName] = useState([]);
  const [ReferType, setReferType] = useState([]);
  const [ReferName, setReferName] = useState([]);
  const [ReligionData, setReligionData] = useState([]);
  const [doctorNames, setDoctorNames] = useState([]);
  const [BuildingsData, setBuildingsData] = useState([]);
  const [BlocksData, setBlocksData] = useState([]);
  const [FloorData, setFloorData] = useState([]);
  const [WardType, setWardType] = useState([])
  const [RoomType, setRoomType] = useState([])
  const [RoomNo, setRoomNo] = useState([])
  const [BedNo, setBedNo] = useState([])
  const [isbookingedit, setisbookingedit] = useState(false)

  

  const IpAdmissionEdit = useSelector(state => state.InPatients?.IpAdmissionEdit);
  const [geteditdata,setgeteditdata]=useState(null)







  useEffect(() => {
    if (Object.keys(IpAdmissionEdit).length !== 0) {
      setisbookingedit(true)
      // console.log('eeeee',IpAdmissionEdit)
      axios.get(`https://vesoftometic.co.in/ipregistration/get_data_by_Booking_Id?Booking_Id=${IpAdmissionEdit?.BookingId}`)
        .then((res) => {
          const dat = res.data
          console.log('veerasss',dat);
          setgeteditdata({
            ...dat
          })
          setRegisterformdata({
            ...dat
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else { 
      setisbookingedit(false)
    }
  }, [IpAdmissionEdit])
  
  const handleFormChangeOp = (event) => {
    const { name, value } = event.target;

    if (name === "DOB") {
      const newDate = new Date();

      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setRegisterformdata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else if (name === "Gender") {
      let defaultPhotoFile = null;

      const defaultPhotoPath = value === "Male" ? malepatient : femalpatient;

      fetch(defaultPhotoPath)
        .then((response) => response.blob()) // Convert response to blob
        .then((blob) => {
          // Create a File object from the blob
          defaultPhotoFile = new File([blob], `${value}_captured_image.jpg`, {
            type: "image/jpeg",
          });

          // Update formData with the File object
          setRegisterformdata((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            PatientPhoto: defaultPhotoFile,
          }));
        })
        .catch((error) => { });
    } else if (name === "PhoneNo" || name === 'FamilyPhoneNo' || name === 'AttenderMobileNo') {
      const newval = value.length;
      if (newval <= 10) {
        setRegisterformdata((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Phone No must contain 10 digits");
      }
    } else {
      setRegisterformdata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };




  useEffect(() => {
    if (
      (Registerformdata.DoctorName &&
        Registerformdata.Specialization &&
        Registerformdata.RateCardType &&
        userRecord?.location) &&
      (Registerformdata.ClientName ||
        Registerformdata.InsuranceName)
    ) {
      axios
        .get(
          `${UrlLink}usercontrol/get_doctor_service_charge?ServiceType=Doctor&ServiceName=${Registerformdata.DoctorName}&specialties=${Registerformdata.Specialization}&PatientCategory=${Registerformdata.RateCardType}&location=${userRecord?.location}&PatientCategoryName=${Registerformdata.RateCardType === 'Client' ? Registerformdata.ClientName : Registerformdata.InsuranceName}`
        )
        .then((response) => {
          const data = response.data;
          if (data.Charge) {
            setRegisterformdata((prev) => ({
              ...prev,
              ConsultancyFee: parseFloat(data.Charge),
              GstCharge: parseFloat(data.GST),
            }));
          }
        })
        .catch((error) => { });
    }

  }, [
    Registerformdata.DoctorName,
    Registerformdata.RateCardType,
    Registerformdata.Specialization,
    Registerformdata.ClientName,
    Registerformdata.InsuranceName,
    userRecord?.location
  ]);



  useEffect(() => {
    setRegisterformdata((prev) => ({
      ...prev,
      Location: userRecord?.location,
      CreatedBy: userRecord?.username,
      AdmitDate: format(new Date(), 'yyyy-MM-dd'),
      AdmitTime: format(new Date(), 'hh:mm:ss'),
    }))
    axios
      .get(`${UrlLink}usercontrol/get_Specialities_data`)
      .then((response) => {
        const data = response.data;
        console.log(data);

        setSpecialitiesData(data.filter((p) => p.status === 'Active').map((p) => ({
          ...p,
          Specialities_name: p.Specialities_name.trim()
        })));
      })
      .catch((error) => {
        console.error("Error fetching Specialities data:", error);
      });

    axios
      .get(
        `${UrlLink}usercontrol/get_Insurance_client_Names?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        if (data) {
          if (data.ClientName) {
            setClinentName(data.ClientName || []);
          }
          if (data.InsuranceName) {
            setInsuranceName(data.InsuranceName || []);
          }
        }
      })
      .catch((error) => { });
    axios
      .get(`${UrlLink}usercontrol/getReligion`)
      .then((response) => {
        const data = response.data;
        console.log("religion", response);

        setReligionData(data);
      })
      .catch((error) => {
        console.error("Error Religion data:", error);
      });
    axios
      .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
      .then((response) => {

        const data = [...new Set(response.data.map(p => p.Building))]

        console.log(response.data);
        console.log(data);
        setBuildingsData(
          data
        );
      })
      .catch((error) => {
        console.log(error);
      });

  }, [userRecord?.location, UrlLink]);

  // ------------
  useEffect(() => {
    if (userRecord?.location && Registerformdata.BuildingName) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName).map(p => p.Block))]
          console.log(data);
          setBlocksData(
            data
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setBlocksData([])
      setRegisterformdata((prev) => ({
        ...prev,
        BlockName: '',
        FloorName: '',
        WardType: '',
        RoomType: '',
        RoomNo: '',
        BedNo: '',
        RoomCharge: '',
        GSTCharge: ''
      }))
    }

  }, [userRecord?.location, Registerformdata.BuildingName])
  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName && p.Block === Registerformdata.BlockName).map(p => p.Floor))]
          setFloorData(
            data
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setFloorData([])
      setRegisterformdata((prev) => ({
        ...prev,
        FloorName: '',
        WardType: '',
        RoomType: '',
        RoomNo: '',
        BedNo: '',
        RoomCharge: '',
        GSTCharge: ''
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName])

  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName &&
      Registerformdata.FloorName) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName).map(p => p.Ward))]
          setWardType(
            data
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setWardType([])
      setRegisterformdata((prev) => ({
        ...prev,
        WardType: '',
        RoomType: '',
        RoomNo: '',
        BedNo: '',
        RoomCharge: '',
        GSTCharge: ''
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName,
  Registerformdata.FloorName
  ])

  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName &&
      Registerformdata.FloorName &&
      Registerformdata.WardType) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName &&
            p.Ward === Registerformdata.WardType).map(p => p.RoomType))]
          setRoomType(
            data
          );
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      setRoomType([])
      setRegisterformdata((prev) => ({
        ...prev,
        RoomType: '',
        RoomNo: '',
        BedNo: '',
        RoomCharge: '',
        GSTCharge: ''
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName,
  Registerformdata.FloorName,
  Registerformdata.WardType])


  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName &&
      Registerformdata.FloorName &&
      Registerformdata.WardType &&
      Registerformdata.RoomType) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=All&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName &&
            p.Ward === Registerformdata.WardType &&
            p.RoomType === Registerformdata.RoomType &&
            p.BookingStatus === isbookingedit?'All':'Available').map(p => p.Room))]

          setRoomNo(
            data
          );
          const chargeval = response.data.find((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName &&
            p.Ward === Registerformdata.WardType &&
            p.RoomType === Registerformdata.RoomType)

          console.log(chargeval, '-----');
          setRegisterformdata((prev) => ({
            ...prev,
            RoomCharge: chargeval?.Charge,
            GSTCharge: chargeval?.GST
          }))
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      setRoomNo([])
      setRegisterformdata((prev) => ({
        ...prev,
        RoomNo: '',
        BedNo: '',
        RoomCharge: '',
        GSTCharge: ''
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName,
  Registerformdata.FloorName,
  Registerformdata.WardType,
  Registerformdata.RoomType,
  isbookingedit])

  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName &&
      Registerformdata.FloorName &&
      Registerformdata.WardType &&
      Registerformdata.RoomType &&
      Registerformdata.RoomNo) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=${isbookingedit?'All':'Available'}&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response);
          const data = [...new Set(response.data.filter((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName &&
            p.Ward === Registerformdata.WardType &&
            p.RoomType === Registerformdata.RoomType &&
            p.Room === Registerformdata.RoomNo).map(p => p.BedNo))]

          setBedNo(
            data
          );

        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      setBedNo([])
      setRegisterformdata((prev) => ({
        ...prev,
        BedNo: '',
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName,
  Registerformdata.FloorName,
  Registerformdata.WardType,
  Registerformdata.RoomType,
  Registerformdata.RoomNo,
  isbookingedit])



  useEffect(() => {
    if (userRecord?.location &&
      Registerformdata.BuildingName &&
      Registerformdata.BlockName &&
      Registerformdata.FloorName &&
      Registerformdata.WardType &&
      Registerformdata.RoomType &&
      Registerformdata.RoomNo &&
      Registerformdata.BedNo) {
      axios
        .get(`${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=${isbookingedit?'All':'Available'}&Status=Active&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response, '-------roomid');
          const data = response.data.filter((p) => p.Building === Registerformdata.BuildingName &&
            p.Block === Registerformdata.BlockName &&
            p.Floor === Registerformdata.FloorName &&
            p.Ward === Registerformdata.WardType &&
            p.RoomType === Registerformdata.RoomType &&
            p.Room === Registerformdata.RoomNo &&
            p.BedNo === Registerformdata.BedNo)

          setRegisterformdata((prev) => ({
            ...prev,
            RoomId: data[0]?.S_No || '',
          }))

        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      setRegisterformdata((prev) => ({
        ...prev,
        RoomId: '',
      }))
    }

  }, [userRecord?.location,
  Registerformdata.BuildingName,
  Registerformdata.BlockName,
  Registerformdata.FloorName,
  Registerformdata.WardType,
  Registerformdata.RoomType,
  Registerformdata.RoomNo,
  Registerformdata.BedNo])

  // -------------------
  useEffect(() => {
    if (userRecord?.location) {
      axios
        .get(`${UrlLink}usercontrol/get_route?location=${userRecord?.location}`)
        .then((response) => {
          console.log("routeno", response.data);
          setRoute_No(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord?.location]);

  useEffect(() => {
    if (userRecord?.location && Registerformdata.RouteNo) {
      axios
        .get(
          `${UrlLink}usercontrol/get_route_name?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}`
        )
        .then((response) => {
          console.log("routename", response.data);
          setRouteName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord?.location, Registerformdata.RouteNo]);
  useEffect(() => {
    if (userRecord?.location && Registerformdata.RouteNo) {
      axios
        .get(
          `${UrlLink}usercontrol/get_route_name?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}`
        )
        .then((response) => {
          console.log("routename", response.data);
          setRouteName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord?.location, Registerformdata.RouteNo]);
  useEffect(() => {
    if (userRecord?.location && Registerformdata.RouteNo && Registerformdata.RouteName) {
      axios
        .get(
          `${UrlLink}usercontrol/get_tehsilby_route?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}&routename=${Registerformdata.RouteName}`
        )
        .then((response) => {
          console.log("thasilname", response.data);
          setThasilName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord?.location, Registerformdata.RouteNo, Registerformdata.RouteName]);

  useEffect(() => {
    if (
      userRecord?.location &&
      Registerformdata.RouteNo &&
      Registerformdata.RouteName &&
      Registerformdata.TeshilName
    ) {
      axios
        .get(
          `${UrlLink}usercontrol/get_village_route?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}&routename=${Registerformdata.RouteName}&thasilname=${Registerformdata.TeshilName}`
        )
        .then((response) => {
          console.log("villagename", response.data);
          setVillageName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    userRecord?.location,
    Registerformdata.RouteNo,
    Registerformdata.RouteName,
    Registerformdata.TeshilName,
  ]);

  useEffect(() => {
    if (
      userRecord?.location &&
      Registerformdata.RouteNo &&
      Registerformdata.RouteName &&
      Registerformdata.TeshilName &&
      Registerformdata.VillageName
    ) {
      axios
        .get(
          `${UrlLink}usercontrol/get_referal_Type?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}&routename=${Registerformdata.RouteName}&thasilname=${Registerformdata.TeshilName}&villagename=${Registerformdata.VillageName}`
        )
        .then((response) => {
          console.log("setReferType", response.data);
          setReferType(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    userRecord?.location,
    Registerformdata.RouteNo,
    Registerformdata.RouteName,
    Registerformdata.TeshilName,
    Registerformdata.VillageName
  ]);


  useEffect(() => {
    if (
      userRecord?.location &&
      Registerformdata.RouteNo &&
      Registerformdata.RouteName &&
      Registerformdata.TeshilName &&
      Registerformdata.VillageName &&
      Registerformdata.RefferType
    ) {
      axios
        .get(
          `${UrlLink}usercontrol/get_refferedby_name?location=${userRecord?.location}&routerno=${Registerformdata.RouteNo}&routename=${Registerformdata.RouteName}&thasilname=${Registerformdata.TeshilName}&villagename=${Registerformdata.VillageName}&refertype=${Registerformdata.RefferType}`
        )
        .then((response) => {
          console.log("setReferName", response.data);
          setReferName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    userRecord?.location,
    Registerformdata.RouteNo,
    Registerformdata.RouteName,
    Registerformdata.TeshilName,
    Registerformdata.VillageName,
    Registerformdata.RefferType
  ]);


  useEffect(() => {
    if (Registerformdata.Specialization) {
      axios
        .get(
          `${UrlLink}usercontrol/get_doctor_info?location=${userRecord?.location}&Specialization=${Registerformdata.Specialization.trim()}`
        )
        .then((response) => {
          const data = response.data;
          setDoctorNames(data || []);
          setRegisterformdata((prev)=>({
            ...prev,
            PrimaryDoctor:''
            
          }))
        })
        .catch((error) => { });
    }

  }, [Registerformdata.Specialization, userRecord?.location])
  
  console.log('Registerformdata', Registerformdata)
  // ---------------
  const handleSearch = () => {
    const patientID = Registerformdata.PatientID || '';
    const phone = Registerformdata.PhoneNo || '';
    const Familyphone = Registerformdata.FamilyPhoneNo || '';

    if (patientID || phone || Familyphone) {
      axios
        .get(
          `${UrlLink}appointmentmanagement/get_for_directappointmentreg?selected_patientid=${patientID}&phonenumber=${phone}&Familyphone=${Familyphone}`
        )
        .then((response) => {
          console.log('response', response.data);
          if (Array.isArray(response.data) && response.data !== null) {
            setPatientListSearch(response.data)
            setRegisterformdata((prev) => ({
              ...prev,
              AdmissionPurpose: '',
              PatientID: '',
              PhoneNo: '',
              FamilyPhoneNo: "",
              Title: "",
              FirstName: "",
              MiddleName: "",
              SurName: "",
              Email: '',
              Gender: '',
              DOB: '',
              Age: '',
              BloodGroup: '',
              Relegion: '',
              Nationality: "",
              UniqueIdType: "",
              UniqueIdNo: "",
              Specialization: "",
              PrimaryDoctor: '',
              RouteNo: "",
              RouteName: "",
              TeshilName: "",
              VillageName: "",
              RefferType: "",
              RefferedBy: "",
              AdmitDate: '',
              AdmitTime: '',
              AttenderName: '',
              AttenderMobileNo: '',
              Relation: '',
              PrimaryDisease: '',
              Remarks: '',
              PatientPhoto: "",
              PatientType: "General",
              PatientCategory: "General",
              RateCardType: "General",
              ColorFlag: "",
              MLC: 'No',
              AttenderName: '',
              AttenderMobileNo: '',
              Relation: '',
              Country: '',
              State: '',
              City: '',
              Place: '',
              Street: '',
              Pincode: '',
              BloodGroup: '',
              Relegion: '',
              Location: userRecord?.location,
              CreatedBy: userRecord?.username
            }))
            console.log('----');
          } else if (response.data !== null) {
            setPatientListSearch([])
            console.log(response.data, '----')
            const rowdata = response.data
            setRegisterformdata((prevFormData) => ({
              ...prevFormData,
              PatientID: rowdata?.PatientID,
              PhoneNo: rowdata?.PhoneNo,
              FamilyPhoneNo: rowdata?.FamilyPhoneNo,
              Title: rowdata?.Title,
              FirstName: rowdata?.FirstName,
              MiddleName: rowdata?.MiddleName,
              SurName: rowdata?.SurName,
              Gender: rowdata?.Gender,
              DOB: rowdata?.DOB,
              Age: rowdata?.Age,
              Email: rowdata?.Email,
              Nationality: rowdata?.Nationality,
              UniqueIdType: rowdata?.UniqueIdType,
              UniqueIdNo: rowdata?.UniqueIdNo,
              RouteNo: rowdata?.RouteNo,
              RouteName: rowdata?.RouteName,
              TeshilName: rowdata?.TeshilName,
              VillageName: rowdata?.VillageName,
              RefferType: rowdata?.RefferType,
              RefferedBy: rowdata?.RefferedBy,
              PatientType: rowdata?.PatientType,
              PatientCategory: rowdata?.PatientCategory,
              RateCardType: rowdata?.RateCardType,
              ClientName: rowdata?.ClientName,
              InsuranceName: rowdata?.InsuranceName,
              ColorFlag: rowdata?.ColorFlag,
              AttenderName: rowdata?.EmergencyContactName,
              AttenderMobileNo: rowdata?.EmergencyContactPhone,
              Relation: rowdata?.RelationshipStatus,
              Country: rowdata?.Country,
              State: rowdata?.State,
              City: rowdata?.City,
              Place: rowdata?.Area,
              Street: rowdata?.Street,
              Pincode: rowdata?.Pincode,
              BloodGroup: rowdata?.BloodGroup,
              Relegion: rowdata?.Religion,
              CreatedBy: userRecord?.username,
              Location: userRecord?.location
            }));

          } else {
            setPatientListSearch([])
            if (response.data === null) {
              setRegisterformdata((prev) => ({
                ...prev,
                AdmissionPurpose: '',
                PatientID: '',
                PhoneNo: '',
                FamilyPhoneNo: "",
                Title: "",
                FirstName: "",
                MiddleName: "",
                SurName: "",
                Email: '',
                Gender: '',
                DOB: '',
                Age: '',
                BloodGroup: '',
                Relegion: '',
                Nationality: "",
                UniqueIdType: "",
                UniqueIdNo: "",
                Specialization: "",
                PrimaryDoctor: '',
                RouteNo: "",
                RouteName: "",
                TeshilName: "",
                VillageName: "",
                RefferType: "",
                RefferedBy: "",
                AdmitDate: '',
                AdmitTime: '',
                AttenderName: '',
                AttenderMobileNo: '',
                Relation: '',
                PrimaryDisease: '',
                Remarks: '',
                PatientPhoto: "",
                PatientType: "General",
                PatientCategory: "General",
                RateCardType: "General",
                ColorFlag: "",
                MLC: 'No',
                AttenderName: '',
                AttenderMobileNo: '',
                Relation: '',
                Country: '',
                State: '',
                City: '',
                Place: '',
                Street: '',
                Pincode: '',
                BloodGroup: '',
                Relegion: '',
                Location: userRecord?.location,
                CreatedBy: userRecord?.username
              }))
              setPatientListSearch([])
              userwarn("Patient ID is not avaiable! Please Register a New Patient");
            }
          }

        })
        .catch((error) => { });
    } else {
      userwarn("plesae fill patient id or phone number");
    }
  };




  // -------------

  useEffect(() => {
    if (PatientListSearch.length > 0 && Registerformdata.PatientID) {
      console.log('----00000');
      const rowdata = PatientListSearch.find((p) => {
        return (p.PatientID === Registerformdata.PatientID)
      })
      if (rowdata) {
        setRegisterformdata((prevFormData) => ({
          ...prevFormData,
          PatientID: rowdata?.PatientID,
          PhoneNo: rowdata?.PhoneNo,
          FamilyPhoneNo: rowdata?.FamilyPhoneNo,
          Title: rowdata?.Title,
          FirstName: rowdata?.FirstName,
          MiddleName: rowdata?.MiddleName,
          SurName: rowdata?.SurName,
          Gender: rowdata?.Gender,
          DOB: rowdata?.DOB,
          Age: rowdata?.Age,
          Email: rowdata?.Email,
          Nationality: rowdata?.Nationality,
          UniqueIdType: rowdata?.UniqueIdType,
          UniqueIdNo: rowdata?.UniqueIdNo,
          RouteNo: rowdata?.RouteNo,
          RouteName: rowdata?.RouteName,
          TeshilName: rowdata?.TeshilName,
          VillageName: rowdata?.VillageName,
          RefferType: rowdata?.RefferType,
          RefferedBy: rowdata?.RefferedBy,
          PatientType: rowdata?.PatientType,
          PatientCategory: rowdata?.PatientCategory,
          RateCardType: rowdata?.RateCardType,
          ClientName: rowdata?.ClientName,
          InsuranceName: rowdata?.InsuranceName,
          InsuranceNo: rowdata?.InsuranceNo,
          ColorFlag: rowdata?.ColorFlag,
          AttenderName: rowdata?.EmergencyContactName,
          AttenderMobileNo: rowdata?.EmergencyContactPhone,
          Relation: rowdata?.RelationshipStatus,
          Country: rowdata?.Country,
          State: rowdata?.State,
          City: rowdata?.City,
          Place: rowdata?.Area,
          Street: rowdata?.Street,
          Pincode: rowdata?.Pincode,
          BloodGroup: rowdata?.BloodGroup,
          Relegion: rowdata?.Religion,
          CreatedBy: userRecord?.username
        }));
      } else {
        setRegisterformdata((prev) => ({
          ...prev,
          AdmissionPurpose: '',
          PatientID: '',
          PhoneNo: '',
          FamilyPhoneNo: "",
          Title: "",
          FirstName: "",
          MiddleName: "",
          SurName: "",
          Email: '',
          Gender: '',
          DOB: '',
          Age: '',
          BloodGroup: '',
          Relegion: '',
          Nationality: "",
          UniqueIdType: "",
          UniqueIdNo: "",
          Specialization: "",
          PrimaryDoctor: '',
          RouteNo: "",
          RouteName: "",
          TeshilName: "",
          VillageName: "",
          RefferType: "",
          RefferedBy: "",
          AdmitDate: '',
          AdmitTime: '',
          AttenderName: '',
          AttenderMobileNo: '',
          Relation: '',
          PrimaryDisease: '',
          Remarks: '',
          PatientPhoto: "",
          PatientType: "General",
          PatientCategory: "General",
          RateCardType: "General",
          ColorFlag: "",
          MLC: 'No',
          AttenderName: '',
          AttenderMobileNo: '',
          Relation: '',
          Country: '',
          State: '',
          City: '',
          Place: '',
          Street: '',
          Pincode: '',
          BloodGroup: '',
          Relegion: '',
          Location: userRecord?.location,
          CreatedBy: userRecord?.username
        }))
      }
    }


  }, [Registerformdata.PatientID, PatientListSearch])
  // -----------------

  useEffect(() => {
    let insval = [
      "InsuranceName",
      "Scheme",
      "Plan",
      "TPA",
      "InsuranceNo",
      "AvailedType",
      "ValidFrom",
      "ValidUpto",
      "InsuredPerson",
      "Remarks",
      "EstimateBill",
      "PreAuthAmount",
      "CoPaymentType",
      "InsuranceContribution",
      "PatientContribution",
      "CoPaymentLogic",
    ]
    let mlcval = [
      "InformedBY",
      "OfficerName",
      "PoliceStation",
      "FIRNo",
      "CodeStatus",
      "TypeofAdmission",
      "AdmittingDiagnosis",
    ]
    let regisdata=['Location', 'CreatedBy', 'PatientPhoto', 'ClientName', 'RoomId', ...insval, ...mlcval]

    
    let fdata = Object.keys(Registerformdata).filter((f) => !regisdata.includes(f))
    if (Registerformdata.PatientCategory === 'Insurance') {
      const categoryIndex = fdata.indexOf('PatientCategory');

      fdata.splice(categoryIndex + 1, 0, ...insval);
    }
    if (Registerformdata.PatientCategory === 'Client') {
      const categoryIndex = fdata.indexOf('PatientCategory');

      fdata.splice(categoryIndex + 1, 0, 'ClientName');
    }
    if (Registerformdata.MLC === 'Yes') {
      const categoryIndex = fdata.indexOf('MLC');

      fdata.splice(categoryIndex + 1, 0, ...mlcval);
    }

    setFilteredFormdata(fdata)
  }, [Registerformdata.PatientCategory, Registerformdata.MLC])



  // --------------nurseque

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };



  const handlesave = () => {
    let filterdata = []
    if (Registerformdata.AdmissionPurpose === 'Emergency') {
      filterdata = FilteredFormdata.filter((p) => ['AdmissionPurpose', 'BuildingName', 'BlockName', 'FloorName', 'WardType', 'RoomType', 'RoomNo', 'BedNo', 'Specialization', 'PrimaryDoctor','PrimaryDisease','Complaint'].includes(p))
    } else {
      filterdata = FilteredFormdata
    }
    const existing = filterdata.filter((p) => p !== 'PatientID').filter((field) => !Registerformdata[field])
    const formData1 = new FormData();
    Object.keys(Registerformdata).forEach((key) => {
      formData1.append(key, Registerformdata[key] || '');
    });
    console.log(Registerformdata, '000--');
    if (userRecord?.location !== 'ALL') {
      if (existing.length === 0) {
        axios.post(`${UrlLink}ipregistration/${Registerformdata&& Registerformdata?.BookingId&&Registerformdata?.BookingId!==''? 'update_Ip_Admission':'insert_Ip_Admission'}`, formData1)
          .then((res) => {
            
            navigate('/Home/IpRegistrationlist')
          })
          .catch((err) => {
            console.log(err);
          })
      } else {
        userwarn(`Please fill  all the Required Fields : ${existing.join(' , ')}`)
      }
    }

  }


  // ----------------------------
  const updateColumnGap = (containerWidth) => {
    const items = document.querySelectorAll(".RegisForm_1");
    let totalWidth = 0;
    let currentRowItemsCount = 0;

    items.forEach((item) => {
      
      const itemStyles = getComputedStyle(item);
      const itemWidth =
        item.offsetWidth +
        parseFloat(itemStyles.marginLeft) +
        parseFloat(itemStyles.marginRight);
   
      if (totalWidth + itemWidth <= containerWidth) {
        totalWidth += itemWidth;
        currentRowItemsCount++;
      }
    });

    const remainingGap = containerWidth - totalWidth;
    const gapBetweenItems = Math.ceil(remainingGap / currentRowItemsCount);
    const container = document.getElementById("RegisFormcon_111");
    container.style.columnGap = `${gapBetweenItems}px`;
  };

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById("RegisFormcon_111");
      if (container) {
        const containerWidth = container.offsetWidth;
        updateColumnGap(containerWidth);
      }
    };

    // Initial call
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateColumnGap]);

  // ---------------------

  return (
    <>
      <div className="RegisFormcon" id="RegisFormcon_111" >

        {FilteredFormdata.map((fieldName, index) => (
          <div
            className={
              ["PatientID", "PhoneNo", "FamilyPhoneNo"].includes(fieldName)
                ? "RegisForm_1 input-with-icon"
                : "RegisForm_1"
            }
            key={index}
          >
            
            <label htmlFor={fieldName}>
              <span>
                {formatLabel(fieldName)}
              </span>
              {/* {fieldName === "PatientID" && (
                  <button className="newicon_regis" title="New Register">
                    <PersonAddIcon onClick={handlenavigatePatientRegis} />
                  </button>
                )} */}
              <span>:</span>
            </label>
            {
              fieldName === "AdmissionPurpose" ||
                fieldName === "Title" ||
                fieldName === "Gender" ||
                fieldName === "Nationality" ||
                fieldName === "UniqueIdType" ||
                fieldName === "PatientType" ||
                fieldName === "PatientCategory" ||
                fieldName === "RefferType" ||
                fieldName === "RateCardType" ||
                fieldName === "ColorFlag" ||
                fieldName === "PrimaryDoctor" ||
                fieldName === "Relegion" ||
                fieldName === "Relation" ||
                fieldName === "BloodGroup" ||
                fieldName === "BuildingName" ||
                fieldName === "BlockName" ||
                fieldName === "FloorName" ||
                fieldName === "WardType" ||
                fieldName === "RoomType" ||
                fieldName === "RoomNo" ||
                fieldName === "BedNo" ||
                fieldName === "Specialization" ? (
                fieldName === "ColorFlag" ? (
                  <div className="select-container_ioy">
                    <select
                      id={fieldName}
                      name={fieldName}
                      value={Registerformdata[fieldName]}
                      onChange={handleFormChangeOp}
                      
                      required={

                        fieldName === "ColorFlag"
                      }
                    >
                      <option value="">Select</option>
                      <option value="gold">VIP</option>
                      <option value="purple">Client</option>
                      <option value="blue">Insurance</option>
                      <option value="grey">MLC</option>
                      <option value="green">Donations</option>
                      <option value="cyan">Discount</option>
                      {Registerformdata.VisitId > 5 ? (
                        <>
                          <option value="orange">Loyal Patient</option>
                          <option value="pink">Loyal Family</option>
                        </>
                      ) : null}

                      <option value="red">Ruckus-behavioral infraction</option>
                    </select>
                    {Registerformdata.ColorFlag && (
                      <span
                        className="square-flag"
                        style={{ backgroundColor: Registerformdata.ColorFlag }}
                      ></span>
                    )}
                  </div>
                ) : 
               isbookingedit && 
               (fieldName === "BuildingName" ||
               fieldName === "BlockName" ||
               fieldName === "FloorName" ||
               fieldName === "WardType" ||
               fieldName === "RoomType" ||
               fieldName === "RoomNo" ||
               fieldName === "BedNo" ) ? <input autocomplete="off"
                    id={fieldName}
                    name={fieldName}
                    value={Registerformdata[fieldName]}
                    disabled /> :
                (
                  
                  <select
                    id={fieldName}
                    name={fieldName}
                    value={Registerformdata[fieldName]}
                    onChange={handleFormChangeOp}
                    disabled={!['Specialization','PrimaryDoctor','PatientType','PatientCategory','RateCardType'].includes(fieldName)&&isbookingedit && geteditdata &&geteditdata[fieldName] !==''}
                    required={
                      fieldName === "AdmissionPurpose" ||
                      fieldName === "Title" ||
                      fieldName === "Gender" ||
                      fieldName === "Nationality" ||
                      fieldName === "UniqueIdType" ||
                      fieldName === "PatientType" ||
                      fieldName === "PatientCategory" ||
                      fieldName === "RefferType" ||
                      fieldName === "BloodGroup" ||
                      fieldName === "Relegion" ||
                      fieldName === "RateCardType" ||
                      fieldName === "PrimaryDoctor" ||
                      fieldName === "Relation" ||
                      fieldName === "BuildingName" ||
                      fieldName === "BlockName" ||
                      fieldName === "FloorName" ||
                      fieldName === "WardType" ||
                      fieldName === "RoomType" ||
                      fieldName === "RoomNo" ||
                      fieldName === "BedNo" ||
                      fieldName === "Specialization"
                    }
                  >
                    <option value="">Select</option>
                    {fieldName === "AdmissionPurpose" && (
                      <>
                        <option value="Surgery">Surgery</option>
                        <option value="MedicalManagement">Medical Management</option>
                        <option value="DayCare">DayCare </option>
                        <option value="Emergency">Emergency </option>
                      </>
                    )}
                    {fieldName === "Relation" && (
                      <>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Son">Son</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Brother">Brother</option>
                        <option value="Sister">Sister</option>
                        <option value="Friend">Friend</option>
                      </>
                    )}
                    {fieldName === "Relegion" && (
                      ReligionData.map((p, indx) => (
                        <option key={indx} value={p.religion}>{p.religion}</option>
                      ))
                    )}
                    {fieldName === "BuildingName" && (
                      BuildingsData.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}
                    {fieldName === "BlockName" && (
                      BlocksData.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}
                    {fieldName === "FloorName" && (
                      FloorData.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}
                    {fieldName === "WardType" && (
                      WardType.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}
                    {fieldName === "RoomType" && (
                      RoomType.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}
                    {fieldName === "RoomNo" && (
                      RoomNo.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}

                    {fieldName === "BedNo" && (
                      BedNo.map((p, indx) => (
                        <option key={indx} value={p}>{p}</option>
                      ))
                    )}

                    {fieldName === "BloodGroup" && (
                      <>

                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </>
                    )}
                    {fieldName === "Nationality" && (
                      <>
                        <option value="Indian">Indian</option>
                        <option value="International">International</option>
                      </>
                    )}
                    {fieldName === "Title" && (
                      <>
                        <option value="Mr">Mr.</option>
                        <option value="Ms">Ms.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Others">Others.</option>
                      </>
                    )}
                    {fieldName === "Gender" && (
                      <>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="TransGender">TransGender</option>
                      </>
                    )}
                    {fieldName === "UniqueIdType" &&
                      Registerformdata.Nationality === "Indian" && (
                        <>
                          <option value="Aadhar">Aadhar</option>
                          <option value="VoterId">Voter Id</option>
                          <option value="SmartCard">SmartCard</option>
                        </>
                      )}
                    {fieldName === "UniqueIdType" &&
                      Registerformdata.Nationality === "International" && (
                        <>
                          <option value="DrivingLicence">Driving Licence</option>
                          <option value="Passport">Passport</option>
                        </>
                      )}


                    {fieldName === "PatientType" && (
                      <>
                        <option value="General">General</option>
                        <option value="VIP">VIP</option>
                      </>
                    )}
                    {fieldName === "PatientCategory" && (
                      <>
                        <option value="General">General</option>
                        <option value="Client">Client</option>
                        <option value="Insurance">Insurance</option>
                      </>
                    )}
                    {fieldName === "RateCardType" && (
                      <>
                        <option value="General">General</option>
                        <option value="Special">Special</option>
                        <option value="VIP">VIP</option>
                        <option value="Client">Client</option>
                        <option value="Insurance">Insurance</option>
                      </>
                    )}
                    {fieldName === "Specialization" && (
                      <>
                        {SpecialitiesData.map((p, indx) => (
                          <option value={p.Specialities_name} key={indx}>{p.Specialities_name}</option>
                        ))
                        }
                      </>
                    )}
                    {fieldName === "PrimaryDoctor" && (
                      <>
                        {doctorNames.map((p, indx) => (
                          <option value={p.EmployeeName} key={indx}>{p.EmployeeName}</option>
                        ))
                        }
                      </>
                    )}

                    {fieldName === "RefferType" && (
                      ReferType.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}

                  </select>
                )

              ) : (
                <>
                  {["PatientID", "PhoneNo", "FamilyPhoneNo", "RouteNo", "RouteName", "TeshilName", "VillageName", "RefferedBy", 'ClientName', 'InsuranceName'].includes(fieldName) && (
                    fieldName === 'PhoneNo' || fieldName === 'FamilyPhoneNo' ? (
                      <>
                        <input
                          type="number"
                          id={fieldName}
                          autoComplete="off" // Add this attribute
                          list={`${fieldName}_iddd`}
                          name={fieldName}
                          onKeyDown={blockInvalidChar}
                          value={Registerformdata[fieldName]}
                          onChange={handleFormChangeOp}
                          required={["PhoneNo", "FamilyPhoneNo"].includes(fieldName)
                          }
                        />
                      </>
                    ) : (
                      <input
                        type="text"
                        id={fieldName}
                        list={`${fieldName}_iddd`}
                        name={fieldName}
                        disabled={!['PhoneNo','FamilyPhoneNo'].includes(fieldName)&&isbookingedit && geteditdata &&geteditdata[fieldName] !==''}
                        value={Registerformdata[fieldName]}
                        onChange={handleFormChangeOp}
                        required={["RouteNo", "RouteName", "TeshilName", "VillageName", "RefferedBy", 'ClientName', 'InsuranceName'].includes(fieldName)
                        }
                      />
                    )

                  )}

                  <datalist id={`${fieldName}_iddd`}>
                    <option value="">Select</option>

                    {fieldName === "RouteNo" && (
                      RouteNo.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}
                    {fieldName === "RouteName" && (
                      RouteName.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}
                    {fieldName === "TeshilName" && (
                      ThasilName.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}
                    {fieldName === "VillageName" && (
                      VillageName.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}
                    {fieldName === "RefferedBy" && (
                      ReferName.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      )))}
                    {fieldName === "ClientName" && (
                      ClientName.map((name, index) => (
                        <option key={index} value={name.Name}>
                          {`${name.Code}`}
                        </option>
                      )))}
                    {fieldName === "InsuranceName" && (
                      InsuranceName.map((name, index) => (
                        <option key={index} value={name.Name}>
                          {`${name.Code}`}
                        </option>
                      )))}
                    {fieldName === "PatientID" && (
                      PatientListSearch.map((name, index) => (
                        <option key={index} value={name.PatientID}>
                          {`PhoneNo :${name.PhoneNo}`}<br />
                          {`PatientName: ${name.FirstName} ${name.MiddleName} ${name.SurName}`}
                        </option>
                      )))}
                    {fieldName === "PhoneNo" && (
                      PatientListSearch.map((name, index) => (
                        <option key={index} value={name.PhoneNo}>
                          {`PatientID :${name.PatientID}`}
                          {`PatientName :${name.FirstName} ${name.MiddleName} ${name.SurName}`}
                        </option>
                      )))}
                  </datalist>


                  {fieldName !== "RouteNo" &&
                    fieldName !== "RouteName" &&
                    fieldName !== "RefferedBy" &&
                    fieldName !== "TeshilName" &&
                    fieldName !== "VillageName" &&
                    fieldName !== "ClientName" &&
                    fieldName !== "InsuranceName" &&
                    fieldName !== "PatientID" &&
                    fieldName !== "PhoneNo" &&
                    fieldName !== "FamilyPhoneNo" &&
                    (fieldName === "Complaint" ? (
                      <textarea
                        id="Complaint"
                        name="Complaint"
                        placeholder="maximum 250 letters"
                        value={Registerformdata.Reason}
                        cols="25"
                        rows="3"
                        onChange={handleFormChangeOp}
                      />
                    ) : fieldName === 'MLC' || fieldName === 'SameAsContactAddress' ? (
                      fieldName === 'MLC' ?
                        (<div style={{ display: 'flex', justifyContent: 'space-between', width: '150px' }}>
                          <label style={{ width: '60px' }}>
                            <input
                              id='MLCYes'
                              type="radio"
                              name="MLC"
                              value="Yes"
                              checked={Registerformdata.MLC === 'Yes'}
                              onChange={(e) => setRegisterformdata(prevState => ({
                                ...prevState,
                                MLC: 'Yes'
                              }))}
                            />
                            Yes
                          </label>
                          <label style={{ width: '60px' }}>
                            <input
                              id='MLCNo'
                              type="radio"
                              name="MLC"
                              value="No"
                              disabled={isbookingedit && geteditdata &&geteditdata[fieldName] ==='Yes'}
                              checked={Registerformdata.MLC === 'No'}
                              onChange={(e) => setRegisterformdata(prevState => ({
                                ...prevState,
                                MLC: 'No'
                              }))}
                            />
                            No
                          </label>
                        </div>) :
                        (<div style={{ display: 'flex', justifyContent: 'space-between', width: '150px' }}>
                          <label style={{ width: '60px' }}>
                            <input
                              id='MLCYes'
                              type="radio"
                              name="SameAsContactAddress"
                              value="Yes"
                              checked={Registerformdata.SameAsContactAddress === 'Yes'}
                              onChange={(e) => setRegisterformdata(prevState => ({
                                ...prevState,
                                SameAsContactAddress: 'Yes',
                                AttenderCountry: Registerformdata.Country,
                                AttenderState: Registerformdata.State,
                                AttenderCity: Registerformdata.City,
                                AttenderPlace: Registerformdata.Place,
                                AttenderStreet: Registerformdata.Street,
                                AttenderPincode: Registerformdata.Pincode,
                              }))}
                            />
                            Yes
                          </label>
                          <label style={{ width: '60px' }}>
                            <input
                              id='SameAsContactAddress'
                              type="radio"
                              name="SameAsContactAddress"
                              value="No"
                              checked={Registerformdata.SameAsContactAddress === 'No'}
                              onChange={(e) => setRegisterformdata(prevState => ({
                                ...prevState,
                                SameAsContactAddress: 'No',
                                AttenderCountry: '',
                                AttenderState: '',
                                AttenderCity: '',
                                AttenderPlace: '',
                                AttenderStreet: '',
                                AttenderPincode: '',
                              }))}
                            />
                            No
                          </label>
                        </div>)
                    ) : fieldName === 'AttenderMobileNo' || fieldName === 'Pincode' || fieldName === 'AttenderPincode' ?
                      (
                        <input
                          type='number'
                          id={fieldName}
                          autoComplete="off" // Add this attribute
                          name={fieldName}
                          value={Registerformdata[fieldName]}
                          onKeyDown={blockInvalidChar}
                          onChange={handleFormChangeOp}
                          required
                        />
                      ) :
                      (
                        <input
                          type={
                            fieldName === "DOB" || fieldName === "AdmitDate" || fieldName === 'ValidFrom' || fieldName === 'ValidUpto'
                              ? "date"
                              : fieldName === 'AdmitTime' ? "time" : "text"
                          }
                          id={fieldName}
                          name={fieldName}
                          disabled={![
                          'AttenderCountry',
                          'AttenderState',
                          'AttenderCity',
                          'AttenderPlace',
                          'AttenderStreet',
                          'Country',
                          'State',
                          'City',
                          'Place',
                          'Street',
                          ].includes(fieldName)&&isbookingedit && geteditdata &&geteditdata[fieldName] !==''}
                          value={Registerformdata[fieldName]}
                          readOnly={Registerformdata.SameAsContactAddress === 'Yes' && ['AttenderCountry',
                            'AttenderState',
                            'AttenderCity',
                            'AttenderPlace',
                            'AttenderStreet',
                            'AttenderPincode',
                            "RoomCharge"
                          ].includes(fieldName) && true}
                          onChange={handleFormChangeOp}
                          required={
                            fieldName === "DOB" || fieldName === "AppointmentDate"
                          }
                        />
                      )
                    )}
                </>
              )} 
             
            {["PatientID", "PhoneNo", "FamilyPhoneNo"].includes(fieldName) &&(isbookingedit?(isbookingedit && ["PatientID", "PhoneNo", "FamilyPhoneNo"].includes(fieldName) && geteditdata && geteditdata[fieldName] ===''):true) && (
              <button className="searching_input_icon3" onClick={handleSearch}> 
                <SearchIcon />
              </button>
            )}
          </div>
        ))}
      </div>


      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handlesave} >
          {Registerformdata&& Registerformdata?.BookingId&&Registerformdata?.BookingId!==''?'Update':'Save'}
        </button>
      </div>
      <ToastContainer />
    </>
  );
}

export default InPatientRegistration;

