import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function CounselorFullList() {
  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const pageSize = 10;
  const showdown = rows.length;

  const [columns] = React.useState([
    {
      field: "PatientName",
      headerName: "Patient Name",
      width: 200,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.Number_of_Sessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.Remaningsession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "PhoneNo",
      headerName: "PhoneNumber",
      width: 200,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.Number_of_Sessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.Remaningsession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    // { field: 'Status', headerName: 'Status', width: 200 },
    {
      field: "Reason",
      headerName: "Reason",
      width: 241,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.Number_of_Sessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.Remaningsession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "FollowupDate",
      headerName: "Follow-up Date",
      width: 200,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.Number_of_Sessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.Remaningsession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ]);

  useEffect(() => {
    fetch("https://vesoftometic.co.in/Counselor/get_counselor_procedure")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const Records = data.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.PatientName,
          Treatment_Procedure: userdata.Treatment_Procedure,
          Number_of_Sessions: userdata.Number_of_Sessions,
          completedsession: userdata.completedsession,
          Remaningsession: userdata.Remaningsession,
          currentsession: userdata.currentsession,
          PhoneNo: userdata.PhoneNumber, // Change the field name to match your actual data
          // Status: userdata.Status,
          Reason: userdata.Reason, // Change the field name to match your actual data
          FollowupDate: userdata.FollowUp_Date, // Change the field name to match your actual data
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [FirstName, setFirstName] = useState([]);
  const [PhoneNo, setPhoneNo] = useState([]);

  const handleSearch = () => {
    fetch("https://vesoftometic.co.in/appointmentmanagement/get_appointments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchName: PatientFirstName.toLowerCase(),
        searchPhoneNo: PatientPhoneNo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && Array.isArray(data.appointmentsregData)) {
          const Records = data.appointmentsregData.map((userdata) => ({
            id: userdata.SerialNo,
            PatientName: userdata.First_Name + " " + userdata.Last_Name,
            PhoneNo: userdata.Phone_No,
            Status: userdata.Status,
            Reason: userdata.Reason, // Change the field name to match your actual data
            FollowupDate: userdata.FollowupDate, // Change the field name to match your actual data
          }));
          setFirstName(Records);
        } else {
          console.error("Fetched data is not as expected:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });

    // fetch('https://vesoftometic.co.in/treatmentrecord/fetch_appointment_reg_data_names', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     searchQuery: PatientPhoneNo,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.success && Array.isArray(data.appointmentsregData)) {
    //       const Records = data.appointmentsregData.map((userdata) => ({
    //         id: userdata.SerialNo,
    //         PatientName: userdata.First_Name + ' ' + userdata.Last_Name,
    //         PhoneNo: userdata.Phone_No,
    //         Status: userdata.Status,
    //         Reason: userdata.Reason, // Change the field name to match your actual data
    //         FollowupDate: userdata.FollowupDate, // Change the field name to match your actual data
    //       }));
    //       setPhoneNo(Records);
    //     } else {
    //       console.error('Fetched data is not as expected:', data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching filtered data:', error);
    //   });
  };

  const filterRows = (rowData) => {
    const lowerCaseFirstName = (rowData.First_Name || "").toLowerCase();
    const phoneNoString = rowData.PhoneNo.toString() || "";
    const lowerCaseSearchName = PatientFirstName.toLowerCase();
    const lowerCaseSearchPhoneNo = PatientPhoneNo.toString();

    return (
      (PatientFirstName === "" ||
        lowerCaseFirstName.startsWith(lowerCaseSearchName)) &&
      (PatientPhoneNo === "" ||
        phoneNoString.startsWith(lowerCaseSearchPhoneNo))
    );
  };

  const filteredByName = FirstName.filter(filterRows);
  const filteredByPhoneNo = PhoneNo.filter(filterRows);

  const mergedData = [...filteredByName, ...filteredByPhoneNo];

  const uniqueData = Array.from(new Set(mergedData.map((item) => item.id))).map(
    (id) => {
      return mergedData.find((item) => item.id === id);
    }
  );

  if (uniqueData.length === 0) {
    // alert('No matching users found.');
  } else {
    setRows(uniqueData);
  }

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Patient Queue List(C)</h4>
        </div>
        <form onSubmit={handleSearch}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                First Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No <span>:</span>
              </label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
