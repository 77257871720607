import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const AddvaceConsume = () => {
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);


  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(`${urllink}HRmanagement/get_all_advance_register_by_employeeid?EmployeeID=${employeeid}&location=${userRecord.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            reqdate: userdata.RequestDate,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
            installment: userdata.RepaymentDue,
            status: userdata.Status,
            rejectreason: userdata.RejectReason,
            approvedby: userdata.ApproverName,
            issueddate: userdata.IssuedDate,
            issuedby: userdata.IssueverName
          }));
          setRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Request Amount</th>
              <th id="slectbill_ins">Reason</th>
              <th id="slectbill_ins">No.of.Installment</th>
              <th id="slectbill_ins">Status</th>
              <th id="slectbill_ins">Reject Reason</th>
              <th id="slectbill_ins">Issued Date</th>
              <th id="slectbill_ins">Issued By</th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 && rows.map((leave, index) => (
              <tr key={index}>
                <td>{leave.employeeid}</td>
                <td>{leave.employeename}</td>
                <td>{leave.reqamount}</td>
                <td>{leave.reason}</td>
                <td>{leave.installment}</td>
                <td>{leave.status}</td>
                <td>{leave.rejectreason || '-'}</td>
                <td>{leave.issueddate}</td>
                <td>{leave.issuedby}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
};

export default AddvaceConsume;
