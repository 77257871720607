import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMtpFormI() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [checkboxValues, setCheckboxValues] = useState({
    Checkbox: "",
   
  })

   console.log(checkboxValues,'checkboxValues')


  const [ConcernformData, setFormData] = useState({
    Name: "",
    PermanentAddress: "",
    FreeText: "",
    Number: "",
    Time: "",

    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    
    });
  };



  const handleCheckbox = (event) => {
    const { value } = event.target;
    setCheckboxValues({
      Checkbox: value,
    });
  };



//   const handleChange = (e) => {
//     const { name, type, checked, value } = e.target;
  
//     if (type === 'checkbox') {
//       setFormData((prev) => ({
//         ...prev,
//         [name]: checked ? value : ''
//       }));
//     } else {
//       setFormData({
//         ...ConcernformData,
//         [name]: value
//       });
//     }
//   };

  


  // const handleChange = (e) => {
  //   const { name,type,checked, value } = e.target;
  //   setFormData({
  //     ...ConcernformData,
  //     [name]: value,
  //   });

  //   if(type === 'checkbox' ) 
  //     {
  //       setFormData((prev) => ({
  //         ...prev,
  //         [name]:checked? 'Yes': ''
  //       }))
  //     }






  //     setCheckboxValues(prevState => ({
  //       ...prevState,
  //       [name]: value
  //     }));


  //     // if (type === 'checkbox') {
  //     //   setFormData((prev) => ({
  //     //     ...prev,
  //     //     [name]: checked ? value : ''
  //     //   }));
  //     // } else {
  //     //   setFormData({
  //     //     ...ConcernformData,
  //     //     [name]: value
  //     //   });
  //     // }
      


  // };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Name', ConcernformData.Name);
              formData.append('PermanentAddress', ConcernformData.PermanentAddress);
              formData.append('FreeText', ConcernformData.FreeText);
              formData.append('Number', ConcernformData.Number);
              formData.append('Time', ConcernformData.Time);
              formData.append('Checkbox', checkboxValues.Checkbox);
                formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MtpForm_I`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >
         
         

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>FORM I</h1>
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h3>As per Clause 3 of Medical Termination of Pregnancy Regulations, 2003</h3>
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>DR.JYOTI NITIN CHAVAN,</h1><h4>M.D.;D.G.O. (Reg No. 79693)</h4>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h4>(Name and qualifications of the Registered Medical Practitioner in Block Letters )</h4>
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h2>CHIRAYU HOSPITAL, Salvi stop, Ratnagiri 415639</h2>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h4>Full Address of the Registered Practitoners</h4>
          </div>

         
          
        </div>

    

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '13px', fontWeight: 'bold' }}>
            I here by certify that I am opinion formed in good faith that it is necessary to terminate the pregnancy of
            <input
                type="text"
                name="Name"
                value={ConcernformData.Name}
                onChange={handleChange}
                style={{width: '580px'}}
                required
                
                />
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                    <h5>(Full Name of Pregnant woman in block letters)</h5>
                </div>
                resident of
                <input
                type="text"
                name="PermanentAddress"
                value={ConcernformData.PermanentAddress}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                
                />
                <br/>
                <br/>

                for the reasons given below
                <input
                type="text"
                name="FreeText"
                value={ConcernformData.FreeText}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                
                />

            </p>
          </div>
        </div>

        
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
              
            *I/We hereby give intimation that *I/We terminated the pregnancy of the woman referred
             to above who bears the Serial Number
             <input
                type="text"
                name="Number"
                value={ConcernformData.Number}
                onChange={handleChange}
                style={{width: '280px'}}
                required
                
                />
                in the Admission Register of the hospital / approved place. The pregnancy was terminated at

                <input
                type="text"
                name="Time"
                value={ConcernformData.Time}
                onChange={handleChange}
                style={{width: '280px'}}
                required
                
                />
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                    <h5>(here mention time)</h5>
                </div>

                 and this report is certified within 3 hours of such termination.
             
          

            </p>
            

          </div>


        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            <h3>Place : RATNAGIRI</h3>

           </p>
            </div>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            
            <h3>Date: </h3>

           </p>
            </div>
        </div>    

        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature(s) of the Registered Medical Practitioner(s)</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


        </div>


        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            <h3>Of the reasons specified items (i) to (v) write the one which is appropriate:</h3>
            <input 
  type="checkbox"
  name="Checkbox1"
  value="in order to save the life of the pregnant woman"
  checked={checkboxValues.Checkbox === "in order to save the life of the pregnant woman"}
  onChange={handleCheckbox}
  required
/>
i) in order to save the life of the pregnant woman,<br/>
<br/>

<input 
  type="checkbox"
  name="Checkbox2"
  value="In order to prevent grave injury to the physical and mental health of the pregnant woman"
  checked={checkboxValues.Checkbox === "In order to prevent grave injury to the physical and mental health of the pregnant woman"}
  onChange={handleCheckbox}
  required
/>
ii) In order to prevent grave injury to the physical and mental health of the pregnant woman.<br/>
<br/>

<input 
  type="checkbox"
  name="Checkbox3"
  value="In view of the substantial risk that if the child was born, it would suffer from such physical or mental abnormalities as to be seriously handicapped"
  checked={checkboxValues.Checkbox === "In view of the substantial risk that if the child was born, it would suffer from such physical or mental abnormalities as to be seriously handicapped"}
  onChange={handleCheckbox}
  required
/>
iii) In view of the substantial risk that if the child was born, it would suffer from such physical or mental abnormalities as to be seriously handicapped<br/>
<br/>

<input 
  type="checkbox"
  name="Checkbox4"
  value="as the pregnancy is alleged by pregnant woman to have been caused by rape"
  checked={checkboxValues.Checkbox === "as the pregnancy is alleged by pregnant woman to have been caused by rape"}
  onChange={handleCheckbox}
  required
/>
iv) as the pregnancy is alleged by pregnant woman to have been caused by rape<br/>
<br/>

<input 
  type="checkbox"
  name="Checkbox5"
  value="as the pregnancy has occurred as result of failure of any contraceptive device or methods used by married woman or her husband for the purpose of limiting the number of children"
  checked={checkboxValues.Checkbox === "as the pregnancy has occurred as result of failure of any contraceptive device or methods used by married woman or her husband for the purpose of limiting the number of children"}
  onChange={handleCheckbox}
  required
/>
v) as the pregnancy has occurred as result of failure of any contraceptive device or methods used by married woman or her husband for the purpose of limiting the number of children.<br/>
<br/>
<br/>

            <div className=" summadiv">
            <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                <h3>Note :</h3>
            </p>
                </div>
            </div>

            <div className=" summadiv">
            <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                
                Account may be taken of the pregnant woman's actual or reasonably foreseeable
                environment in determining whether the continuance of her pregnancy would involve a
                grave injury to her physical or mental health.
            </p>
                </div>
            </div>
                
            <div className=" summadiv">
            <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                <h3>Place : RATNAGIRI</h3>

            </p>
                </div>
            </div>

            <div className=" summadiv">
            <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                <h3>Date : </h3>

            </p>
                </div>
            </div>

           </p>
            </div>
        </div>  
        

        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature(s) of the Registered Medical Practitioner(s)</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


        </div>

    

        
     


        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMtpFormI;
