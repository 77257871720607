import React from 'react'

function OTAnaesthesiaDrug() {
  return (
    <div>
      -----------------Hi Im AnaesthesiaDrug
    </div>
  )
}

export default OTAnaesthesiaDrug
