import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { differenceInYears } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// import LogoImg from "../Claims/LogoImg.png";
import LaunchIcon from "@mui/icons-material/Launch";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Select } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

function Claims() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IPPatientDetailes = useSelector(
    (state) => state.InsuranceStore?.IPPatientDetailes
  );

  const Navigate = useNavigate();

  const [value, setValue] = React.useState("1");
  const [expanded, setExpanded] = useState(false);

  const [isCardCollapsed, setIsCardCollapsed] = useState(true);

  const [formData1, setFormData1] = useState({
    MRN: "",
    PatientName: "",
    Gender: "",
    DOB: "",
    Age: "",
    ContactNumber: "",
    Address: "",
    PinCode: "",

    InsuranceCompany: "", // Added InsuranceCompany
    PolicyNumber: "",
    PolicyType: "",
    PayerTPAName: "",
    PayerTPAZone: "",
    PayerTPAMemberID: "",

    CorporateName: "",
  });

  const [formDataTxtarea, setFormDataTxtarea] = useState({
    ComplaintsDuration: "",
    HistoryPresentingIllness: "",
    PastHistory: "",
  });

  const [esicCoverage, setEsicCoverage] = useState("No");

  const [employed, setEmployed] = useState("No");

  const [diabetes, setDiabetes] = useState("No");
  const [diabetesDuration, setDiabetesDuration] = useState("");
  const [diabetesMedication, setDiabetesMedication] = useState("");

  const [hyperTension, setHyperTension] = useState("No");
  const [hyperTensionDuration, setHyperTensionDuration] = useState("");
  const [hyperTensionMedication, setHyperTensionMedication] = useState("");

  const [hyperlipiDemias, setHyperlipiDemias] = useState("No");
  const [hyperlipiDemiasDuration, setHyperlipiDemiasDuration] = useState("");
  const [hyperlipiDemiasMedication, setHyperlipiDemiasMedication] =
    useState("");

  const [osteoarthritis, setOsteoarthritis] = useState("No");
  const [osteoarthritisDuration, setOsteoarthritisDuration] = useState("");
  const [osteoarthritisMedication, setOsteoarthritisMedication] = useState("");

  const [bronchialAsthma, setBronchialAsthma] = useState("No");
  const [bronchialAsthmaDuration, setBronchialAsthmaDuration] = useState("");
  const [bronchialAsthmaMedication, setBronchialAsthmaMedication] =
    useState("");

  const [cvd, setCvd] = useState("No");
  const [cvdDuration, setCvdDuration] = useState("");
  const [cvdMedication, setCvdMedication] = useState("");

  const [cva, setCva] = useState("No");
  const [cvaDuration, setCvaDuration] = useState("");
  const [cvaMedication, setCvaMedication] = useState("");

  const [kochs, setKochs] = useState("No");
  const [kochsDuration, setKochsDuration] = useState("");
  const [kochsMedication, setKochsMedication] = useState("");

  const [malignancy, setMalignancy] = useState("No");
  const [malignancyDuration, setMalignancyDuration] = useState("");
  const [malignancyMedication, setMalignancyMedication] = useState("");

  const [formDataTxtarea2, setFormDataTxtarea2] = useState({
    ComplaintsDuration: "",
    HistoryPresentingIllness: "",
    PastHistory: "",
  });

  const [rows, setRows] = useState([
    { provisionalDiagnosis: "", icdCode: "", type: "Primary" },
  ]);

  const [rows2, setRows2] = useState([
    { finalDiagnosis: "", icdCode2: "", type2: "Primary" },
  ]);

  const [rows3, setRows3] = useState([
    {
      department: "",
      treatingDoctor: "",
      contactNumber3: "",
      registrationNumber: "",
      medicalCouncil: "",
    },
  ]);

  const [checkboxStateTreatment, setCheckboxStateTreatment] = useState({
    icuManagement: false,
    dialysis: false,
    chemotherapy: false,
    radiation: false,
  });

  const [medicalManagement, setMedicalManagement] = useState("No");
  const [formDataTxtareaDetails, setFormDataTxtareaDetails] = useState({
    details: "",
  });

  const [rows4, setRows4] = useState([
    {
      treatment: "",
      pcsHospitalCode: "",
    },
  ]);

  const [surgicalManagement, setSurgicalManagement] = useState("No");
  const [department3, setDepartment3] = useState([
    {
      departmentSurgical: "",
      surgeonSurgical: "",
    },
  ]);

  const [checkboxStateAnesthesia, setCheckboxStateAnesthesia] = useState({
    laAnesthesia: false,
    raAnesthesia: false,
    saAnesthesia: false,
    eaAnesthesia: false,
    gaAnesthesia: false,
  });

  const [type, setType] = useState("elective");

  const [rows5, setRows5] = useState([
    {
      procedureSurgical: "",
      pcdHospitalCodeSurgical: "",
    },
  ]);

  const [billingInfo, setBillingInfo] = useState({
    dateofAdmission: "",
    typeofAdmission: "",
    durationStayinDays: "",
    roomWardTypeBilling: "",
    dateofSurgery: "",
    dateofDischarge: "",
    roomWardTypeBilling: "",
  });

  const [rows6, setRows6] = useState([
    {
      costOfHospitalization: "",
      totalBillAmount: "",
    },
  ]);

  const [MedicalLegalCase, setMedicalLegalCase] = useState("No");

  const [rows7, setRows7] = useState([
    {
      photoIdentityCardFile: "",
      photoIdentityCardRemarks: "",
      photoIdentityCardDate: "",
      photoIdentityCardACK: false,
    },
  ]);

  const [rows8, setRows8] = useState([
    {
      signedFormFile: "",
      signedFormRemarks: "",
      signedFormDate: "",
      signedFormACK: false,
    },
  ]);

  const [rows9, setRows9] = useState([
    {
      otherDocumentsFile: "",
      otherDocumentsRemarks: "",
      otherDocumentsDate: "",
      otherDocumentsACK: false,
    },
  ]);

  //Enhanced
  const [rows10, setRows10] = useState([
    {
      doctorsLetterFile: "",
      doctorsLetterRemarks: "",
      doctorsLetterDate: "",
      doctorsLetterACK: false,
    },
  ]);

  const [rows11, setRows11] = useState([
    {
      interimBillFile: "",
      interimBillRemarks: "",
      interimBillDate: "",
      interimBillACK: false,
    },
  ]);

  const [rows12, setRows12] = useState([
    {
      enhancedOtherDocumentsFile: "",
      enhancedOtherDocumentsRemarks: "",
      enhancedOtherDocumentsDate: "",
      enhancedOtherDocumentsACK: false,
    },
  ]);

  //Discharged

  const [rows13, setRows13] = useState([
    {
      investigationReport: "",
      investigationReportRemarks: "",
      investigationReportDate: "",
      investigationReportACK: false,
    },
  ]);

  const [rows14, setRows14] = useState([
    {
      finalBillFile: "",
      finalBillRemarks: "",
      interimBillDate: "",
      interimBillACK: false,
    },
  ]);

  const [rows15, setRows15] = useState([
    {
      dischargeSummaryFile: "",
      dischargeSummaryRemarks: "",
      dischargeSummaryDate: "",
      dischargeSummaryACK: false,
    },
  ]);

  const [rows16, setRows16] = useState([
    {
      dischargeSummaryOtherDocumentsFile: "",
      dischargeSummaryOtherDocumentsRemarks: "",
      dischargeSummaryOtherDocumentsDate: "",
      dischargeSummaryOtherDocumentsACK: false,
    },
  ]);

  const [formDataProfile, setFormDataProfile] = useState({
    mrn: "",
    alNumber: "",
    clNumber: "",
    inwardNumber: "",
    requestedAmount: "",
    approvedAmount: "",
  });

  const [status, setStatus] = useState("Enhanced");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(" ");
        const data = await response.json();

        if (data.length === 0) {
          setStatus("Discharged");
        } else {
          setStatus("Enhanced");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        // Handle error and set status accordingly
        setStatus("Discharged");
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  // ---------------------------------------------------------------------------------------------------------------------

  const handleChangeProfile = (e) => {
    const { id, value } = e.target;
    setFormDataProfile((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleCardVisibility = () => {
    setIsCardCollapsed(!isCardCollapsed);
    setIsTabCollapsed(true);
  };

  const [isTabCollapsed, setIsTabCollapsed] = useState(true);
  const toggleTabVisibility = () => {
    setIsTabCollapsed(!isTabCollapsed);
    setIsCardCollapsed(true);
  };

  const handleContentCardClick = () => {
    setIsCardCollapsed(true);
    setIsTabCollapsed(true);
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (name === "ContactNumber") {
      const newval = value.length;
      if (newval <= 10) {
        setFormData1((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Contact Number must contain 10 digits");
      }
    } else if (name === "DOB") {
      const newDate = new Date();
      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleTextAreaChange = (event) => {
    const { name, value } = event.target;
    setFormDataTxtarea({
      ...formDataTxtarea,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    setEsicCoverage(event.target.value);
  };

  const handleCheckboxChange2 = (event) => {
    setEmployed(event.target.value);
  };

  const handleCheckboxChange3 = (event) => {
    setDiabetes(event.target.value);
  };
  const handleDurationChange = (event) => {
    setDiabetesDuration(event.target.value);
  };

  const handleMedicationChange = (event) => {
    setDiabetesMedication(event.target.value);
  };

  const handleCheckboxChange4 = (event) => {
    setHyperTension(event.target.value);
  };
  const handleHyperTensionDurationChange = (event) => {
    setHyperTensionDuration(event.target.value);
  };

  const handleHyperTensionMedicationChange = (event) => {
    setHyperTensionMedication(event.target.value);
  };

  const handleCheckboxChange5 = (event) => {
    setHyperlipiDemias(event.target.value);
  };
  const handleHyperlipiDemiasDurationChange = (event) => {
    setHyperlipiDemiasDuration(event.target.value);
  };

  const handleHyperlipiDemiasMedicationChange = (event) => {
    setHyperlipiDemiasMedication(event.target.value);
  };

  const handleCheckboxChange6 = (event) => {
    setOsteoarthritis(event.target.value);
  };
  const handleOsteoarthritisDurationChange = (event) => {
    setOsteoarthritisDuration(event.target.value);
  };

  const handleOsteoarthritisMedicationChange = (event) => {
    setOsteoarthritisMedication(event.target.value);
  };

  const handleCheckboxChange7 = (event) => {
    setBronchialAsthma(event.target.value);
  };
  const handleBronchialAsthmaDurationChange = (event) => {
    setBronchialAsthmaDuration(event.target.value);
  };

  const handleBronchialAsthmaMedicationChange = (event) => {
    setBronchialAsthmaMedication(event.target.value);
  };

  const handleCheckboxChange8 = (event) => {
    setCvd(event.target.value);
  };
  const handleCvdDurationChange = (event) => {
    setCvdDuration(event.target.value);
  };

  const handleCvdMedicationChange = (event) => {
    setCvdMedication(event.target.value);
  };

  const handleCheckboxChange9 = (event) => {
    setCva(event.target.value);
  };
  const handleCvaDurationChange = (event) => {
    setCvaDuration(event.target.value);
  };

  const handleCvaMedicationChange = (event) => {
    setCvaMedication(event.target.value);
  };

  const handleCheckboxChange10 = (event) => {
    setKochs(event.target.value);
  };
  const handleKochsDurationChange = (event) => {
    setKochsDuration(event.target.value);
  };

  const handleKochsMedicationChange = (event) => {
    setKochsMedication(event.target.value);
  };

  const handleCheckboxChange11 = (event) => {
    setMalignancy(event.target.value);
  };
  const handleMalignancyDurationChange = (event) => {
    setMalignancyDuration(event.target.value);
  };

  const handleMalignancyMedicationChange = (event) => {
    setMalignancyMedication(event.target.value);
  };

  const handleTextAreaChange2 = (event) => {
    const { name, value } = event.target;
    setFormDataTxtarea2({
      ...formDataTxtarea,
      [name]: value,
    });
  };

  const handleChangeRow = (index, key, value) => {
    const newRows = [...rows];
    newRows[index][key] = value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { provisionalDiagnosis: "", icdCode: "", type: "Primary" },
    ]);
  };

  const removeRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleChangeRow2 = (index, key, value) => {
    const newRows = [...rows2];
    newRows[index][key] = value;
    setRows2(newRows);
  };

  const addRow2 = () => {
    setRows2([
      ...rows2,
      {
        finalDiagnosis: "",
        icdCode2: "",
        type2: "Primary",
      },
    ]);
  };

  const removeRow2 = (index) => {
    const newRows = [...rows2];
    newRows.splice(index, 1);
    setRows2(newRows);
  };

  const handleChangeRow3 = (index, key, value) => {
    const newRows = [...rows3];
    newRows[index][key] = value;
    setRows3(newRows);
  };

  const addRow3 = () => {
    setRows3([
      ...rows3,
      {
        department: "",
        treatingDoctor: "",
        contactNumber3: "",
        registrationNumber: "",
        medicalCouncil: "",
      },
    ]);
  };

  const removeRow3 = (index) => {
    const newRows = [...rows3];
    newRows.splice(index, 1);
    setRows3(newRows);
  };

  const handleCheckboxChangeTreatment = (event) => {
    const { name, checked } = event.target;
    setCheckboxStateTreatment({ ...checkboxStateTreatment, [name]: checked });
  };

  const handleCheckboxChangeMedical = (event) => {
    setMedicalManagement(event.target.value);
  };

  const handleTextAreaChangeDetails = (event) => {
    const { name, value } = event.target;
    setFormDataTxtareaDetails({
      ...formDataTxtareaDetails,
      [name]: value,
    });
  };

  const handleChangeRow4 = (index, key, value) => {
    const newRows = [...rows4];
    newRows[index][key] = value;
    setRows4(newRows);
  };

  const addRow4 = () => {
    setRows4([
      ...rows4,
      {
        treatment: "",
        pcsHospitalCode: "",
      },
    ]);
  };

  const removeRow4 = (index) => {
    const newRows = [...rows4];
    newRows.splice(index, 1);
    setRows4(newRows);
  };

  const handleCheckboxChangeSurgical = (event) => {
    setSurgicalManagement(event.target.value);
  };

  const handleChangeSurgical = (index, key, value) => {
    const newRows = [...department3];
    newRows[index][key] = value;
    setDepartment3(newRows);
  };

  const handleCheckboxChangeAnesthesia = (event) => {
    const { name, checked } = event.target;
    const updatedCheckboxState = {
      laAnesthesia: false,
      raAnesthesia: false,
      saAnesthesia: false,
      eaAnesthesia: false,
      gaAnesthesia: false,
      [name]: checked,
    };
    setCheckboxStateAnesthesia(updatedCheckboxState);
  };

  const handleCheckboxChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeRow5 = (index, key, value) => {
    const newRows = [...rows5];
    newRows[index][key] = value;
    setRows4(newRows);
  };

  const addRow5 = () => {
    setRows5([
      ...rows5,
      {
        procedureSurgical: "",
        pcdHospitalCodeSurgical: "",
      },
    ]);
  };

  const removeRow5 = (index) => {
    const newRows = [...rows5];
    newRows.splice(index, 1);
    setRows5(newRows);
  };

  const handleChangeBillingInfo = (event) => {
    const { name, value } = event.target;
    setBillingInfo({
      ...billingInfo,
      [name]: value,
    });
  };

  const handleChangeRowBillingItem = (index, key, value) => {
    const newRows = [...rows6];
    newRows[index][key] = value;
    setRows6(newRows);
  };

  const addRow6 = () => {
    setRows6([
      ...rows,
      {
        costOfHospitalization: "",
        totalBillAmount: "",
      },
    ]);
  };

  const removeRow6 = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows6(newRows);
  };

  const handleCheckboxChangeLegalCase = (event) => {
    setMedicalLegalCase(event.target.value);
  };

  const handleChangeRowDocuments = (index, key, value) => {
    const updatedRows = [...rows7];
    updatedRows[index][key] = value;
    setRows7(updatedRows);
  };

  const addRow7 = () => {
    setRows7((prevRows) => [
      ...prevRows,
      {
        photoIdentityCardFile: "",
        photoIdentityCardRemarks: "",
        photoIdentityCardDate: "",
        photoIdentityCardACK: "",
      },
    ]);
  };
  const removeRow7 = (index) => {
    setRows7((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const handleChangeRowDocuments2 = (index, key, value) => {
    const updatedRows = [...rows8];
    updatedRows[index][key] = value;
    setRows8(updatedRows);
  };

  const addRow8 = () => {
    setRows8((prevRows) => [
      ...prevRows,
      {
        signedFormFile: "",
        signedFormRemarks: "",
        signedFormDate: "",
        signedFormACK: "",
      },
    ]);
  };
  const removeRow8 = (index) => {
    setRows8((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const handleChangeRowDocuments3 = (index, key, value) => {
    const updatedRows = [...rows9];
    updatedRows[index][key] = value;
    setRows9(updatedRows);
  };

  const addRow9 = () => {
    setRows9((prevRows) => [
      ...prevRows,
      {
        otherDocumentsFile: "",
        otherDocumentsRemarks: "",
        otherDocumentsDate: "",
        otherDocumentsACK: "",
      },
    ]);
  };
  const removeRow9 = (index) => {
    setRows9((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  // Enhanced

  const handleChangeRowDocuments4 = (index, key, value) => {
    const updatedRows = [...rows10];
    updatedRows[index][key] = value;
    setRows10(updatedRows);
  };

  const addRow10 = () => {
    setRows10((prevRows) => [
      ...prevRows,
      {
        doctorsLetterFile: "",
        doctorsLetterRemarks: "",
        doctorsLetterDate: "",
        doctorsLetterACK: false,
      },
    ]);
  };
  const removeRow10 = (index) => {
    setRows10((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-------

  const handleChangeRowDocuments5 = (index, key, value) => {
    const updatedRows = [...rows11];
    updatedRows[index][key] = value;
    setRows11(updatedRows);
  };

  const addRow11 = () => {
    setRows11((prevRows) => [
      ...prevRows,
      {
        interimBillFile: "",
        interimBillRemarks: "",
        interimBillDate: "",
        interimBillACK: false,
      },
    ]);
  };
  const removeRow11 = (index) => {
    setRows11((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-----

  const handleChangeRowDocuments6 = (index, key, value) => {
    const updatedRows = [...rows12];
    updatedRows[index][key] = value;
    setRows12(updatedRows);
  };

  const addRow12 = () => {
    setRows12((prevRows) => [
      ...prevRows,
      {
        enhancedOtherDocumentsFile: "",
        enhancedOtherDocumentsRemarks: "",
        enhancedOtherDocumentsDate: "",
        enhancedOtherDocumentsACK: false,
      },
    ]);
  };
  const removeRow12 = (index) => {
    setRows12((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //------

  const handleChangeRowDocuments7 = (index, key, value) => {
    const updatedRows = [...rows13];
    updatedRows[index][key] = value;
    setRows13(updatedRows);
  };

  const addRow13 = () => {
    setRows13((prevRows) => [
      ...prevRows,
      {
        investigationReport: "",
        investigationReportRemarks: "",
        investigationReportDate: "",
        investigationReportACK: false,
      },
    ]);
  };
  const removeRow13 = (index) => {
    setRows13((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments8 = (index, key, value) => {
    const updatedRows = [...rows14];
    updatedRows[index][key] = value;
    setRows14(updatedRows);
  };

  const addRow14 = () => {
    setRows14((prevRows) => [
      ...prevRows,
      {
        investigationReport: "",
        investigationReportRemarks: "",
        investigationReportDate: "",
        investigationReportACK: false,
      },
    ]);
  };
  const removeRow14 = (index) => {
    setRows14((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments9 = (index, key, value) => {
    const updatedRows = [...rows15];
    updatedRows[index][key] = value;
    setRows15(updatedRows);
  };

  const addRow15 = () => {
    setRows15((prevRows) => [
      ...prevRows,
      {
        dischargeSummaryFile: "",
        dischargeSummaryRemarks: "",
        dischargeSummaryDate: "",
        dischargeSummaryACK: false,
      },
    ]);
  };
  const removeRow15 = (index) => {
    setRows15((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments10 = (index, key, value) => {
    const updatedRows = [...rows16];
    updatedRows[index][key] = value;
    setRows16(updatedRows);
  };

  const addRow16 = () => {
    setRows16((prevRows) => [
      ...prevRows,
      {
        dischargeSummaryOtherDocumentsFile: "",
        dischargeSummaryOtherDocumentsRemarks: "",
        dischargeSummaryOtherDocumentsDate: "",
        dischargeSummaryOtherDocumentsACK: false,
      },
    ]);
  };
  const removeRow16 = (index) => {
    setRows16((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const [courierInfo, setCourierinfo] = useState([
    {
      courierName: "",
      courierTrackingNumber: "",
      courierDateSent: "",
      courierDateReceived: "",
    },
  ]);

  const handleChangeCourier = (index, key, value) => {
    const newRows = [...courierInfo];
    newRows[index][key] = value;
    setCourierinfo(newRows);
  };

  //--- useEffect

  const handleSave = () => {
    console.log("Form data saved.");
  };

  const handleSubmit = () => {
    console.log("Form data submitted.");
  };

  const handleCancel = () => {
    console.log("Changes Cancel.");
  };

  const handleChangeReconcilation = () =>{
    Navigate('/Home/Claims-Reconciliation')
  }

  return (
    <>
      <div className="toggle_head_insuran">
        <button onClick={toggleCardVisibility} className="launch_toggle_isu">
          <LaunchIcon />
        </button>
        <button onClick={toggleTabVisibility} className="launch_toggle_isu">
          <MenuOpenIcon />
        </button>
      </div>
      <div className="dashboard34">
        <div
          className={`card profile-card ${isCardCollapsed ? "collapsed" : ""}`}
        >
          <div className="aproved_div_pro">
            <span>Approved</span>
            <p>({status})</p>
          </div>
          <br></br>
          <div className="profile-header">
            <img
              // src={LogoImg}
              alt="Profile"
              className="profile-image"
            />
          </div>
          <br></br>
          <div className="profile_name_insu">
            <span>Malar</span>
          </div>
          <div className="profile-info-auth">
            <div className="input-group-grp2">
              <label htmlFor="mrn">MRN :</label>
              <input
                type="text"
                id="mrn"
                value={formDataProfile.mrn}
                placeholder="Enter MRN"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="alNumber">AL Number :</label>
              <input
                type="text"
                id="alNumber"
                value={formDataProfile.alNumber}
                placeholder="Enter Al No"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="clNumber">Cl Number :</label>
              <input
                type="text"
                id="clNumber"
                value={formDataProfile.clNumber}
                placeholder="Enter Cl No"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="InwardNumber">Inward Number :</label>
              <input
                type="text"
                id="inwardNumber"
                value={formDataProfile.inwardNumber}
                placeholder="Enter Inward No"
                onChange={handleChangeProfile}
              />
            </div>
          </div>
          <br></br>
          <hr></hr>
          <div className="amount-section">
            <h4>Amount</h4>
            <br></br>
            <div className="amount-inputs">
              <div className="input-group-amount">
                <label htmlFor="requestedAmount">
                  Pre-Auth Approved <span>:</span>
                </label>
                <input type="text" id="requestedAmount" />
              </div>
            </div>
            <br></br>
            <div className="amount-inputs">
              <div className="input-group-amount">
                <label htmlFor="approvedAmount">
                  TPA Approved <span>:</span>
                </label>
                <input type="text" id="approvedAmount" />
              </div>
            </div>
            <br></br>
            <div className="amount-inputs">
              <div className="input-group-amount">
                <label htmlFor="approvedAmount">
                  Settled Amount <span>:</span>
                </label>
                <input type="text" id="approvedAmount" />
              </div>
            </div>
            <button onClick={handleChangeReconcilation}>
                Reconciliation Details
            </button>
          </div>
        </div>

        <div
          className={`card content-card ${
            isCardCollapsed && window.innerWidth <= 950
              ? "profile-card-open"
              : ""
          }`}
          onClick={handleContentCardClick}
        >
          <div>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expanded !== "panel2" &&
                expanded !== "panel3" &&
                expanded !== "panel4" &&
                expanded !== "panel5" &&
                expanded !== "panel6" &&
                expanded !== "panel7" &&
                expanded !== "panel8" &&
                expanded !== "panel9" &&
                expanded !== "panel10" &&
                expanded !== "panel11"
              }
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Pre- Auth Form
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="h_heade34">
                      <h3>Patient Info</h3>
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        MRN <span>:</span>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter MRN"
                        name="MRN"
                        value={formData1.MRN}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Patient Name <span>:</span>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Patient Name"
                        name="PatientName"
                        value={formData1.PatientName}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label htmlFor="Title">
                        Gender <span>:</span>
                      </label>
                      <select
                        name="Gender"
                        value={formData1.Gender}
                        onChange={handleInputChange1}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="TransGender">TransGender</option>
                      </select>
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Date Of Birth <span>:</span>{" "}
                      </label>
                      <input
                        type="date"
                        placeholder="Enter Date Of Birth"
                        name="DOB"
                        value={formData1.DOB}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Age <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Age"
                        name="Age"
                        value={formData1.Age}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>

                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Contact Number <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Contact Number"
                        name="ContactNumber"
                        value={formData1.ContactNumber}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        Address <span>:</span>
                      </label>
                      <textarea
                        name="Address"
                        className="txt-ara-lclprntm"
                        placeholder="Enter Address"
                        value={formData1.Address}
                        onChange={handleInputChange1}
                        required
                      ></textarea>
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Pin Code <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Pin Code"
                        name="PinCode"
                        value={formData1.PinCode}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance  Spl_backcolr_09_bottom">
                      <label>
                        ESI Coverage <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="esicYes"
                            name="ESICoverage"
                            value="Yes"
                            checked={esicCoverage === "Yes"}
                            onChange={handleCheckboxChange}
                          ></input>

                          <label htmlFor="esicYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="esicNo"
                            name="ESICoverage"
                            value="No"
                            checked={esicCoverage === "No"}
                            onChange={handleCheckboxChange}
                          ></input>
                          <label htmlFor="esicNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {esicCoverage === "No" && (
                    <>
                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Insurance Company <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Insurance Company"
                            name="InsuranceCompany"
                            value={formData1.InsuranceCompany}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Policy Number<span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Policy Number"
                            name="PolicyNumber"
                            value={formData1.PolicyNumber}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Policy Type <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Policy Type "
                            name="PolicyType"
                            value={formData1.PolicyType}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                      </div>

                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Name <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            name="PayerTPAName"
                            value={formData1.PayerTPAName}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Zone <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Zone"
                            name="PayerTPAZone"
                            value={formData1.PayerTPAZone}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Member ID <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Member ID"
                            name="PayerTPAMemberID"
                            value={formData1.PayerTPAMemberID}
                            onChange={handleInputChange1}
                            required
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                      <label>
                        Employed ? <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="employedYes"
                            name="Employed"
                            value="Yes"
                            checked={employed === "Yes"}
                            onChange={handleCheckboxChange2}
                          ></input>

                          <label htmlFor="employedYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="employedNo"
                            name="Employed"
                            value="No"
                            checked={employed === "No"}
                            onChange={handleCheckboxChange2}
                          ></input>
                          <label htmlFor="employedNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                      <label>
                        Medico-Legal Case <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoYes"
                            name="MedicoLegalCase"
                            value="Yes"
                            checked={MedicalLegalCase === "Yes"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>

                          <label htmlFor="MedicoYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoNo"
                            name="MedicoLegalCase"
                            value="No"
                            checked={MedicalLegalCase === "No"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>
                          <label htmlFor="MedicoNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {employed === "Yes" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                        <label>
                          Corporate Name <span>:</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Corporate Name"
                          name="CorporateName"
                          value={formData1.CorporateName}
                          onChange={handleInputChange1}
                          required
                        />
                      </div>
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                        <label>
                          Employee ID <span>:</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Employee ID"
                          name="EmployeeID"
                          value={formData1.EmployeeID}
                          onChange={handleInputChange1}
                          required
                        />
                      </div>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Medical History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="medical_hstry_txtars3">
                    <div className="txtars3_wit_hdj">
                      <label>Chief Complaints & Duration</label>
                      <textarea
                        name="ComplaintsDuration"
                        placeholder="Enter Complaints & Duration"
                        value={formDataTxtarea.ComplaintsDuration}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>
                    <div className="txtars3_wit_hdj">
                      <label>History of Presenting Illness</label>
                      <textarea
                        name="HistoryPresentingIllness"
                        placeholder="Enter History of Presenting Illness"
                        value={formDataTxtarea.HistoryPresentingIllness}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>
                    <div className="txtars3_wit_hdj">
                      <label>Past History</label>
                      <textarea
                        name="PastHistory"
                        placeholder="Enter Past History"
                        value={formDataTxtarea.PastHistory}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>

                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="HistoryOfChronicIllness">
                              History Of Chronic Illness
                            </th>
                            <th className="wiejdwi8">Duration in Years</th>
                            <th className="wiejdwi8">Medication</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Diabetes <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="diabetesYes"
                                      name="diabetesYes"
                                      value="Yes"
                                      checked={diabetes === "Yes"}
                                      onChange={handleCheckboxChange3}
                                    ></input>

                                    <label htmlFor="diabetesYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="diabetesNo"
                                      name="diabetesNo"
                                      value="No"
                                      checked={diabetes === "No"}
                                      onChange={handleCheckboxChange3}
                                    ></input>
                                    <label htmlFor="diabetesNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {diabetes == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={diabetesDuration}
                                  onChange={handleDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {diabetes == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={diabetesMedication}
                                  onChange={handleMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Hypertension <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionYes"
                                      name="hyperTensionYes"
                                      value="Yes"
                                      checked={hyperTension === "Yes"}
                                      onChange={handleCheckboxChange4}
                                    ></input>

                                    <label htmlFor="hyperTensionYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionNo"
                                      name="hyperTensionNo"
                                      value="No"
                                      checked={hyperTension === "No"}
                                      onChange={handleCheckboxChange4}
                                    ></input>
                                    <label htmlFor="hyperTensionNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {hyperTension == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={hyperTensionDuration}
                                  onChange={handleHyperTensionDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {hyperTension == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={hyperTensionMedication}
                                  onChange={handleHyperTensionMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Hyperlipidemias <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperlipiDemiasYes"
                                      name="hyperlipiDemiasYes"
                                      value="Yes"
                                      checked={hyperlipiDemias === "Yes"}
                                      onChange={handleCheckboxChange5}
                                    ></input>

                                    <label htmlFor="hyperlipiDemiasYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperlipiDemiasNo"
                                      name="hyperlipiDemiasNo"
                                      value="No"
                                      checked={hyperlipiDemias === "No"}
                                      onChange={handleCheckboxChange5}
                                    ></input>
                                    <label htmlFor="hyperlipiDemiasNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {hyperlipiDemias == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={hyperlipiDemiasDuration}
                                  onChange={handleHyperlipiDemiasDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {hyperlipiDemias == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={hyperlipiDemiasMedication}
                                  onChange={
                                    handleHyperlipiDemiasMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Osteoarthritis <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="osteoarthritisYes"
                                      name="osteoarthritisYes"
                                      value="Yes"
                                      checked={osteoarthritis === "Yes"}
                                      onChange={handleCheckboxChange6}
                                    ></input>

                                    <label htmlFor="osteoarthritisYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="osteoarthritisNo"
                                      name="osteoarthritisNo"
                                      value="No"
                                      checked={osteoarthritis === "No"}
                                      onChange={handleCheckboxChange6}
                                    ></input>
                                    <label htmlFor="osteoarthritisNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {osteoarthritis == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={osteoarthritisDuration}
                                  onChange={handleOsteoarthritisDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {osteoarthritis == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={osteoarthritisMedication}
                                  onChange={
                                    handleOsteoarthritisMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Bronchial Asthma <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="bronchialAsthmaYes"
                                      name="bronchialAsthmaYes"
                                      value="Yes"
                                      checked={bronchialAsthma === "Yes"}
                                      onChange={handleCheckboxChange7}
                                    ></input>

                                    <label htmlFor="bronchialAsthmaYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="bronchialAsthmaNo"
                                      name="bronchialAsthmaNo"
                                      value="No"
                                      checked={bronchialAsthma === "No"}
                                      onChange={handleCheckboxChange7}
                                    ></input>
                                    <label htmlFor="bronchialAsthmaNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {bronchialAsthma == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={bronchialAsthmaDuration}
                                  onChange={handleBronchialAsthmaDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {bronchialAsthma == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={bronchialAsthmaMedication}
                                  onChange={
                                    handleBronchialAsthmaMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  C.V.D <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvdYes"
                                      name="cvdYes"
                                      value="Yes"
                                      checked={cvd === "Yes"}
                                      onChange={handleCheckboxChange8}
                                    ></input>

                                    <label htmlFor="cvdYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvdNo"
                                      name="cvdNo"
                                      value="No"
                                      checked={cvd === "No"}
                                      onChange={handleCheckboxChange8}
                                    ></input>
                                    <label htmlFor="cvdNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {cvd == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={cvdDuration}
                                  onChange={handleCvdDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {cvd == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={cvdMedication}
                                  onChange={handleCvdMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  C.V.A <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvaYes"
                                      name="cvaYes"
                                      value="Yes"
                                      checked={cva === "Yes"}
                                      onChange={handleCheckboxChange9}
                                    ></input>

                                    <label htmlFor="cvaYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvaNo"
                                      name="cvaNo"
                                      value="No"
                                      checked={cva === "No"}
                                      onChange={handleCheckboxChange9}
                                    ></input>
                                    <label htmlFor="cvaNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {cva == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={cvaDuration}
                                  onChange={handleCvaDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {cva == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={cvaMedication}
                                  onChange={handleCvaMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Kochs <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsYes"
                                      name="kochsYes"
                                      value="Yes"
                                      checked={kochs === "Yes"}
                                      onChange={handleCheckboxChange10}
                                    ></input>

                                    <label htmlFor="kochsYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsNo"
                                      name="kochsNo"
                                      value="No"
                                      checked={kochs === "No"}
                                      onChange={handleCheckboxChange10}
                                    ></input>
                                    <label htmlFor="kochsNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {kochs == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={kochsDuration}
                                  onChange={handleKochsDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {kochs == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={kochsMedication}
                                  onChange={handleKochsMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Malignancy <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="malignancyYes"
                                      name="malignancyYes"
                                      value="Yes"
                                      checked={malignancy === "Yes"}
                                      onChange={handleCheckboxChange11}
                                    ></input>

                                    <label htmlFor="malignancyYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="malignancyNo"
                                      name="malignancyNo"
                                      value="No"
                                      checked={malignancy === "No"}
                                      onChange={handleCheckboxChange11}
                                    ></input>
                                    <label htmlFor="malignancyNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {malignancy == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={malignancyDuration}
                                  onChange={handleMalignancyDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {malignancy == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={malignancyMedication}
                                  onChange={handleMalignancyMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Examination Findings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="medical_hstry_txtars3">
                    <div className="txtars3_wit_hdj">
                      <label>Relevant Clinical Findings</label>
                      <textarea
                        name="RelevantClinicalFindings"
                        placeholder="Enter Relevant Clinical Findings"
                        value={formDataTxtarea2.RelevantClinicalFindings}
                        onChange={handleTextAreaChange2}
                      ></textarea>
                    </div>
                  </div>
                  <br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Diagnosis
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Provisional Diagnosis
                          </th>
                          <th className="ICD_Code">ICD Code</th>
                          <th className="ICD_Code">Type</th>
                          <th className="add32_Code">
                            <span onClick={addRow}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="Provisional_Diagnosis"
                                value={row.provisionalDiagnosis}
                                onChange={(e) =>
                                  handleChangeRowBillingItem(
                                    index,
                                    "provisionalDiagnosis",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="ICD_Code"
                                value={row.icdCode}
                                onChange={(e) =>
                                  handleChangeRowBillingItem(
                                    index,
                                    "icdCode",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <select
                                className="Type_code"
                                value={row.type}
                                onChange={(e) =>
                                  handleChangeRow(index, "type", e.target.value)
                                }
                              >
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                                <option value="Comorbidity">Comorbidity</option>
                              </select>
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRow(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <br></br>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Final Diagnosis
                          </th>
                          <th className="ICD_Code">ICD Code</th>
                          <th className="ICD_Code">Type</th>
                          <th className="add32_Code">
                            <span onClick={addRow2}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows2.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="Provisional_Diagnosis"
                                value={row.finalDiagnosis}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "finalDiagnosis",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="ICD_Code"
                                value={row.icdCode2}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "icdCode2",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <select
                                className="Type_code"
                                value={row.type2}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "type2",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                                <option value="Comorbidity">Comorbidity</option>
                              </select>
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRow2(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Treatment Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <>
                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="Provisional_Diagnosis">
                              Department
                            </th>
                            <th className="ICD_Code">Treating Doctor</th>
                            <th className="ICD_Code">Contact Number</th>
                            <th className="ICD_Code">Registration Number</th>
                            <th className="ICD_Code">Medical Council</th>
                            <th className="add32_Code">
                              <span onClick={addRow3}>
                                <AddIcon className="add32_Code" />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows3.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <select
                                  className="department_tretmt"
                                  value={row.department}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "department",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Cardiology">Cardiology</option>
                                  <option value="Urology">Urology</option>
                                  <option value="Dermatology">
                                    Dermatology
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="department_tretmt_with"
                                  value={row.treatingDoctor}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "treatingDoctor",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Dr.Vishwa MBBS,MBA">
                                    Dr.Vishwa MBBS,MBA
                                  </option>
                                  <option value="Dr.Tamil B.SC,MS,MBBS">
                                    Dr.Tamil B.SC,MS,MBBS
                                  </option>
                                  <option value="Dr.Rajesh MBBS,BA">
                                    Dr.Rajesh MBBS,BA
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="ICD_Code"
                                  value={row.contactNumber3}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "contactNumber3",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="ICD_Code"
                                  value={row.registrationNumber}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "registrationNumber",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.medicalCouncil}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "medicalCouncil",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                <span onClick={() => removeRow3(index)}>
                                  <RemoveIcon className="add32_Code" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br></br>

                    <div className="div_ckkkbox_head">
                      <div className="div_ckkck_box tremt_chkbox_info">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="icuManagement"
                            checked={checkboxStateTreatment.icuManagement}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          ICU Management
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="dialysis"
                            checked={checkboxStateTreatment.dialysis}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Dialysis
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="chemotherapy"
                            checked={checkboxStateTreatment.chemotherapy}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Chemotherapy
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="radiation"
                            checked={checkboxStateTreatment.radiation}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Radiation
                        </label>
                      </div>
                    </div>
                    <br></br>

                    <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                        <label className="fe_l5f">
                          Medical Management <span>:</span>
                        </label>

                        <div className="ewj_i87_head">
                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="medicalManagementYes"
                              name="medicalManagement"
                              value="Yes"
                              checked={medicalManagement === "Yes"}
                              onChange={handleCheckboxChangeMedical}
                            ></input>

                            <label htmlFor="medicalManagementYes">Yes</label>
                          </div>

                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="medicalManagementNo"
                              name="medicalManagement"
                              value="No"
                              checked={medicalManagement === "No"}
                              onChange={handleCheckboxChangeMedical}
                            ></input>
                            <label htmlFor="medicalManagementNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {medicalManagement === "Yes" && (
                      <>
                        <div className="medical_hstry_txtars3">
                          <div className="txtars3_wit_hdj">
                            <label>Details</label>
                            <textarea
                              name="details"
                              placeholder="Enter Details"
                              value={formDataTxtareaDetails.details}
                              onChange={handleTextAreaChangeDetails}
                            ></textarea>
                          </div>
                        </div>

                        <>
                          <div className="Selected-table-container">
                            <table className="selected-medicine-table2 _hide_hover_table">
                              <thead className="Spl_backcolr_09">
                                <tr>
                                  <th className="Provisional_Diagnosis">
                                    Treament
                                  </th>
                                  <th className="ICD_Code">
                                    ICD 10 PCS/Hospital Code
                                  </th>

                                  <th className="add32_Code">
                                    <span onClick={addRow4}>
                                      <AddIcon className="add32_Code" />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {rows4.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <select
                                        className="department_tretmt_with"
                                        value={row.treatment}
                                        onChange={(e) =>
                                          handleChangeRow4(
                                            index,
                                            "treatment",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="Treament1">
                                          Treament 1
                                        </option>
                                        <option value="Treament2">
                                          Treament 2
                                        </option>
                                        <option value="Treament3">
                                          Treament 3
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        className="Provisional_Diagnosis"
                                        value={row.pcsHospitalCode}
                                        onChange={(e) =>
                                          handleChangeRow4(
                                            index,
                                            "pcsHospitalCode",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="add32_Code">
                                      <span onClick={() => removeRow4(index)}>
                                        <RemoveIcon className="add32_Code" />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </>
                    )}
                    <br></br>

                    <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                        <label className="fe_l5f">
                          Surgical Management <span>:</span>
                        </label>

                        <div className="ewj_i87_head">
                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="surgicalManagementYes"
                              name="surgicalManagement"
                              value="Yes"
                              checked={surgicalManagement === "Yes"}
                              onChange={handleCheckboxChangeSurgical}
                            ></input>

                            <label htmlFor="surgicalManagementYes">Yes</label>
                          </div>

                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="surgicalManagementNo"
                              name="surgicalManagement"
                              value="No"
                              checked={surgicalManagement === "No"}
                              onChange={handleCheckboxChangeSurgical}
                            ></input>
                            <label htmlFor="surgicalManagementNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  {surgicalManagement === "Yes" && (
                    <>
                      {department3.map((department, index) => (
                        <div key={index}>
                          <div className="surgical_flex_srtgt">
                            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                              <label>
                                Department <span>:</span>{" "}
                              </label>
                              <select
                                className="department_tretmt"
                                name="departmentSurgical"
                                value={department.departmentSurgical}
                                onChange={(e) =>
                                  handleChangeSurgical(
                                    index,
                                    "departmentSurgical",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select</option>
                                <option value="Cardiology">Cardiology</option>
                                <option value="Urology">Urology</option>
                                <option value="Dermatology">Dermatology</option>
                              </select>
                            </div>
                            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                              <label>
                                {" "}
                                Surgeon <span>:</span>{" "}
                              </label>
                              <select
                                className="department_tretmt"
                                name="surgeonSurgical"
                                value={department.surgeonSurgical}
                                onChange={(e) =>
                                  handleChangeSurgical(
                                    index,
                                    "surgeonSurgical",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select</option>
                                <option value="Dr.Vishwa MBBS,MBA">
                                  Dr.Vishwa MBBS,MBA
                                </option>
                                <option value="Dr.Tamil B.SC,MS,MBBS">
                                  Dr.Tamil B.SC,MS,MBBS
                                </option>
                                <option value="Dr.Rajesh MBBS,BA">
                                  Dr.Rajesh MBBS,BA
                                </option>
                              </select>
                            </div>
                            <div className="div_ckkkbox_head dwcw3wd">
                              <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div efws">
                                <label
                                  style={{ width: "280px", padding: "5px" }}
                                >
                                  {" "}
                                  Type of Anesthesia <span>:</span>{" "}
                                </label>
                              </div>
                              <div className="jkdll_piss_head">
                                <div className="jkdll_piss">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="laAnesthesia"
                                      checked={
                                        checkboxStateAnesthesia.laAnesthesia
                                      }
                                      onChange={handleCheckboxChangeAnesthesia}
                                    />
                                    LA
                                  </label>
                                </div>
                                <div className="jkdll_piss">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="raAnesthesia"
                                      checked={
                                        checkboxStateAnesthesia.raAnesthesia
                                      }
                                      onChange={handleCheckboxChangeAnesthesia}
                                    />
                                    RA
                                  </label>
                                </div>
                                <div className="jkdll_piss">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="saAnesthesia"
                                      checked={
                                        checkboxStateAnesthesia.saAnesthesia
                                      }
                                      onChange={handleCheckboxChangeAnesthesia}
                                    />
                                    SA
                                  </label>
                                </div>
                                <div className="jkdll_piss">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="eaAnesthesia"
                                      checked={
                                        checkboxStateAnesthesia.eaAnesthesia
                                      }
                                      onChange={handleCheckboxChangeAnesthesia}
                                    />
                                    EA
                                  </label>
                                </div>
                                <div className="jkdll_piss">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="gaAnesthesia"
                                      checked={
                                        checkboxStateAnesthesia.gaAnesthesia
                                      }
                                      onChange={handleCheckboxChangeAnesthesia}
                                    />
                                    GA
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                          <label>
                            Type <span>:</span>
                          </label>

                          <div className="ewj_i87_head">
                            <div className="ewj_i87 ewj_i87_secd">
                              <input
                                type="radio"
                                id="emergencyYes"
                                name="Type"
                                value="emergency"
                                checked={type === "emergency"}
                                onChange={handleCheckboxChangeType}
                              ></input>

                              <label htmlFor="emergencyYes">Emergency</label>
                            </div>

                            <div className="ewj_i87 ewj_i87_secd">
                              <input
                                type="radio"
                                id="electiveYes"
                                name="Type"
                                value="elective"
                                checked={type === "elective"}
                                onChange={handleCheckboxChangeType}
                              ></input>
                              <label htmlFor="electiveYes">Elective</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="Selected-table-container">
                        <table className="selected-medicine-table2 _hide_hover_table">
                          <thead className="Spl_backcolr_09">
                            <tr>
                              <th className="Provisional_Diagnosis">
                                Procedure
                              </th>
                              <th className="ICD_Code">
                                ICD 10 PCS / Hospital Code
                              </th>

                              <th className="add32_Code">
                                <span onClick={addRow5}>
                                  <AddIcon className="add32_Code" />
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows5.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="Provisional_Diagnosis"
                                    value={row.procedureSurgical}
                                    onChange={(e) =>
                                      handleChangeRow5(
                                        index,
                                        "procedureSurgical",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className="ICD_Code"
                                    value={row.pcdHospitalCodeSurgical}
                                    onChange={(e) =>
                                      handleChangeRow5(
                                        index,
                                        "pcdHospitalCodeSurgical",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  <span onClick={() => removeRow5(index)}>
                                    <RemoveIcon className="add32_Code" />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Billing Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <>
                    <div className="gap_biling_ifo">
                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Admission <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofAdmission"
                            value={billingInfo.dateofAdmission}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Type Of Admission <span>:</span>{" "}
                          </label>
                          <select
                            className="department_tretmt"
                            name="typeofAdmission"
                            value={billingInfo.typeofAdmission}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="">Admission Type 1</option>
                            <option value="">Admission Type 2</option>
                            <option value="">Admission Type 3</option>
                          </select>
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Duration(stay in Days) <span>:</span>{" "}
                          </label>
                          <input
                            type="number"
                            className="duration_input_with_clr"
                            name="durationStayinDays"
                            value={billingInfo.durationStayinDays}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                      </div>
                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Room / Ward Type <span>:</span>{" "}
                          </label>
                          <select
                            type="date"
                            className="department_tretmt"
                            name="roomWardTypeBilling"
                            value={billingInfo.roomWardTypeBilling}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="">Room 1</option>
                            <option value="">Room 2</option>
                            <option value="">Room 3</option>
                            <option value="">Room 4</option>
                          </select>
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Surgery <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofSurgery"
                            value={billingInfo.dateofSurgery}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Discharge <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofDischarge"
                            value={billingInfo.dateofDischarge}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                      </div>

                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Discharge Type <span>:</span>{" "}
                          </label>
                          <select
                            type="date"
                            className="department_tretmt"
                            name="dateofAdmission"
                            value={billingInfo.dateofAdmission}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="">Discharge Type 1</option>
                            <option value="">Discharge Type 2</option>
                            <option value="">Discharge Type 3</option>
                            <option value="">Discharge Type 4</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Billing Item
                          </th>
                          <th className="ICD_Code">Charges</th>

                          <th className="add32_Code">
                            <span onClick={addRow6}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows6.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <div className="bilng_itm_colum8">
                                <label>Expected Cost of Hospitalization*</label>
                                <input
                                  type="text"
                                  className="Provisional_Diagnosis"
                                  value={row.costOfHospitalization}
                                  onChange={(e) =>
                                    handleChangeRowBillingItem(
                                      index,
                                      "costOfHospitalization",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="bilng_itm_colum8">
                                <label>Total Bill Amount*</label>
                                <input
                                  type="number"
                                  className="Provisional_Diagnosis"
                                  value={row.totalBillAmount}
                                  onChange={(e) =>
                                    handleChangeRowBillingItem(
                                      index,
                                      "totalBillAmount",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRow6(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="h_heade34">
                      <h3>Pre-Auth Documents</h3>
                    </div>

                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="Provisional_Diagnosis">File Name</th>
                            <th className="ICD_Code">Remarks</th>
                            <th className="ICD_Code">Submitted Date</th>
                            <th className="ICD_Code">ACK</th>
                            <th className="ICD_Code">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows7.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Photo Identity Card{" "}
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    value={row.photoIdentityCardFile}
                                    onChange={(e) =>
                                      handleChangeRowDocuments(
                                        index,
                                        "photoIdentityCardFile",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.photoIdentityCardRemarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "photoIdentityCardRemarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.photoIdentityCardDate}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "photoIdentityCardDate",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.photoIdentityCardACK}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "photoIdentityCardACK",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow7}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow7(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}

                          {rows8.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Signed Form
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    value={row.signedFormFile}
                                    onChange={(e) =>
                                      handleChangeRowDocuments2(
                                        index,
                                        "signedFormFile",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.signedFormRemarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments2(
                                      index,
                                      "signedFormRemarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.signedFormDate}
                                  onChange={(e) =>
                                    handleChangeRowDocuments2(
                                      index,
                                      "signedFormDate",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.signedFormACK}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "signedFormACK",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow8}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow8(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}

                          {rows9.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Other Documents
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    value={row.otherDocumentsFile}
                                    onChange={(e) =>
                                      handleChangeRowDocuments3(
                                        index,
                                        "otherDocumentsFile",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.otherDocumentsRemarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "otherDocumentsRemarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.otherDocumentsDate}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "otherDocumentsDate",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.otherDocumentsACK}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "otherDocumentsACK",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow9}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow9(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Enhanced */}
                  {status === "enhanced" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="h_heade34">
                        <h3>Pre-Auth Documents</h3>
                      </div>

                      <div className="Selected-table-container">
                        <table className="selected-medicine-table2 _hide_hover_table">
                          <thead className="Spl_backcolr_09">
                            <tr>
                              <th className="Provisional_Diagnosis">
                                File Name
                              </th>
                              <th className="ICD_Code">Remarks</th>
                              <th className="ICD_Code">Submitted Date</th>
                              <th className="ICD_Code">ACK</th>
                              <th className="ICD_Code">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows10.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Doctor's Letter{" "}
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.doctorsLetterFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments4(
                                          index,
                                          "doctorsLetterFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.doctorsLetterRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.doctorsLetterDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.doctorsLetterACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow10}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow10(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows11.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Signed Form
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.interimBillFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments5(
                                          index,
                                          "interimBillFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.interimBillRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.interimBillDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.interimBillACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow11}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow11(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows12.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Other Documents
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.enhancedOtherDocumentsFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments6(
                                          index,
                                          "enhancedOtherDocumentsFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.enhancedOtherDocumentsRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedOtherDocumentsRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.enhancedOtherDocumentsDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedOtherDocumentsDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.enhancedOtherDocumentsACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedOtherDocumentsACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow12}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow12(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {/* Discharged */}

                  {status === "Discharged" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="h_heade34">
                        <h3>Pre-Auth Documents</h3>
                      </div>

                      <div className="Selected-table-container">
                        <table className="selected-medicine-table2 _hide_hover_table">
                          <thead className="Spl_backcolr_09">
                            <tr>
                              <th className="Provisional_Diagnosis">
                                File Name
                              </th>
                              <th className="ICD_Code">Remarks</th>
                              <th className="ICD_Code">Submitted Date</th>
                              <th className="ICD_Code">ACK</th>
                              <th className="ICD_Code">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows13.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Investigation Report{" "}
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.investigationReport}
                                      onChange={(e) =>
                                        handleChangeRowDocuments7(
                                          index,
                                          "investigationReport",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.investigationReportRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.investigationReportDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.investigationReportACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow13}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow13(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows14.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Final Bill
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.finalBillFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments8(
                                          index,
                                          "finalBillFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.finalBillRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "finalBillRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.interimBillDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "interimBillDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.interimBillACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "interimBillACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow14}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow14(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows15.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Discharge Summary
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.dischargeSummaryFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments9(
                                          index,
                                          "dischargeSummaryFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.dischargeSummaryRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={row.dischargeSummaryDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.dischargeSummaryACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow15}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow15(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows16.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Other Documents
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={
                                        row.dischargeSummaryOtherDocumentsFile
                                      }
                                      onChange={(e) =>
                                        handleChangeRowDocuments10(
                                          index,
                                          "dischargeSummaryOtherDocumentsFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={
                                      row.dischargeSummaryOtherDocumentsRemarks
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryOtherDocumentsRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="medication_90"
                                    value={
                                      row.dischargeSummaryOtherDocumentsDate
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryOtherDocumentsDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={
                                      row.dischargeSummaryOtherDocumentsACK
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryOtherDocumentsACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow16}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow16(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Received Documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Courier Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="ICD_Code">Courier Name</th>
                          <th className="ICD_Code">Tracking Number</th>
                          <th className="ICD_Code">Date Sent</th>
                          <th className="ICD_Code">Date Received</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows3.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                className="department_tretmt_with"
                                value={row.courierName}
                                onChange={(e) =>
                                  handleChangeCourier(
                                    index,
                                    "courierName",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select</option>
                                <option value="Profisional">
                                  Profisional 1
                                </option>
                                <option value="Profisional">
                                  Profisional 2
                                </option>
                                <option value="Profisional">
                                  Profisional 3{" "}
                                </option>
                              </select>
                            </td>

                            <td>
                              <input
                                type="number"
                                className="ICD_Code"
                                value={row.courierTrackingNumber}
                                onChange={(e) =>
                                  handleChangeCourier(
                                    index,
                                    "courierTrackingNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="medication_90"
                                value={row.courierDateSent}
                                onChange={(e) =>
                                  handleChangeCourier(
                                    index,
                                    "courierDateSent",
                                    e.target.value
                                  )
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="date"
                                className="medication_90"
                                value={row.courierDateReceived}
                                onChange={(e) =>
                                  handleChangeCourier(
                                    index,
                                    "courierDateReceived",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Additional Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div
          className={`card Tab-card Tab-Claim ${
            isTabCollapsed ? "collapsed" : ""
          }`}
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeValue}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Inbox" className="ITEM_ONE_INSU_head" value="1" />
                  <Tab
                    label="Sent Items"
                    className="ITEM_ONE_INSU_head"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" className="ITEM_ONE_INSU">
                Item One
              </TabPanel>
              <TabPanel value="2" className="ITEM_ONE_INSU">
                Item Two
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className="Register_btn_con insu_submit_btn">
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export default Claims;
