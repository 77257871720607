import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import "./RoomsAvail.css";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { values } from "lodash";

function CustomTooltip({ content, children }) {
  return (
    <Tooltip title={content} placement="top" arrow>
      {children}
    </Tooltip>
  );
}
function RoomsAvail() {
  const dispatchvalue = useDispatch();
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const showroom = useSelector((state) => state.InPatients?.showroom);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [roomTypeFilter, setRoomTypeFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("Available");
  const [roomCounts, setRoomCounts] = useState([]);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  useEffect(() => {
    if (userRecord && userRecord?.location) {
      axios
        .get(
          `${UrlLink}ipregistration/get_room_count_data_total?Location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;
          setRoomCounts(data);
          console.log("data", data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord, userRecord?.location]);



  useEffect(() => {
    axios.get(`${UrlLink}usercontrol/get_ward_type`)
      .then((response) => {
        console.log(response)
        setWards(response.data.map((p) => ({
          ...p,
          id: p.WardId
        })))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const roomCards = filteredData.map((room, index) => {
    // Check if 'attender' is an array and join it if it is, otherwise display an empty string
    const attendersText = Array.isArray(room.attenders)
      ? room.attenders.join(", ")
      : room.attenders;

    const tooltipContent = (
      <div>
        Patient: {room.bookedBy}
        <br />
        Attenders: {attendersText}
        <br />
        Contact: {room.contactNumber}
        <br />
        Booked Date: {room.Admitdate}
      </div>
    );

    return (
      <CustomTooltip
        content={room.BookingStatus === "Occupied" ? tooltipContent : ""}
        key={index}
      >
        <Card sx={{ maxWidth: 350 }}>
          <div className="tooltip-trigger">
            <CardContent className="Rooms_avail_card_container">
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                className="Rooms_avail_card_container_container"
              >
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Building <span>:</span>
                    </label>
                    <div>{room.Building}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Block Name<span>:</span>
                    </label>
                    <div>{room.Block}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Floor Name <span>:</span>
                    </label>
                    <div>{room.Floor}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Ward Type <span>:</span>
                    </label>
                    <div>{room.Ward}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Room Type <span>:</span>
                    </label>
                    <div>{room.RoomType}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Room No <span>:</span>
                    </label>
                    <div>{room.Room}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Bed No <span>:</span>
                    </label>
                    <div>{room.BedNo}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Charge <span>:</span>
                    </label>
                    <div>{room.Charge}</div>
                  </div>
                </div>
              </Typography>
              <Typography variant="h5" className="Rooms_avail_card_icon">
                <FontAwesomeIcon
                  icon={faBed}
                  style={{
                    color:
                      room.BookingStatus === "Occupied"
                        ? "red"
                        : room.BookingStatus === "Maintance"
                        ? "blue"
                        : "green",
                  }}
                  className={`Rooms_avail_carditems_availableIcon`}
                />
              </Typography>
            </CardContent>
            <CardActions className="Rooms_avail_card_btns">
              
                <>
                 
                 
                  {room.BookingStatus === "Occupied" && (
                    <Button size="small" style={{ color: "red" }}>
                      Occupied
                    </Button>
                  )}
                  {room.BookingStatus === "Maintance" && (
                    <Button size="small" style={{ color: "blue" }}>
                      Under Maintenance
                    </Button>
                  )}
                  {room.BookingStatus === "Available" && (
                    <Button size="small" style={{ color: "green" }}>
                      Available
                    </Button>
                  )}
                </>
              
            </CardActions>
          </div>
        </Card>
      </CustomTooltip>
    );
  });

  useEffect(() => {
    // Fetch room data using Axios when the component mounts
    console.log(userRecord);
    if (userRecord && userRecord?.location) {
      axios
        .get(
          `${UrlLink}ipregistration/get_Room_Master_Data?BookingStatus=${statusFilter}&Status=Active&Location=${userRecord?.location}`
        ) // Replace with your API endpoint
        .then((response) => {
          const data = response.data;
          setInitialData(data);
        })
        .catch((error) => {
          console.error("Error fetching room data:", error);
        });
    }
  }, [ statusFilter, userRecord, userRecord?.location]);

  // useEffect(() => {
  //   // Copy the initial data to prevent it from changing
  //   const dataCopy = [...RoomsDatas];
  //   setInitialData(dataCopy);
  //   setFilteredData(dataCopy)
  // }, []);
  useEffect(() => {
    // Filter the data based on the selected filters
    let filteredRooms = initialData.filter((room) => {
      if (
        
        (roomTypeFilter === "All" || room.Ward === roomTypeFilter)
      ) {
        return true;
      }
      return false;
    });

    setFilteredData(filteredRooms);
  }, [initialData, roomTypeFilter]);
  const [Wards, setWards] = useState([])

 
  return (
    <>
      <div className="yuuuuy">
        <div className="Ip_room_avail_container">
          <div className="head_rom_mage">
            <h3>Room Management</h3>
          </div>
          <div className="Inv_form_div_container_pr">
            <div className="Inv_form_row_pr">
            <div className="Inv_form_div_pr">
                <div className="eddw">
                  <label>
                    Room Type <span>:</span>
                  </label>
                </div>
                <select
                  name="roomTypeFilter"
                  id="roomTypeFilter"
                  value={roomTypeFilter}
                  onChange={(e) => setRoomTypeFilter(e.target.value)}
                >
                  <option value='All'>Select</option>
                {
                  Array.from(new Set(Wards.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
                </select>
              </div>
          
             
              <div className="Inv_form_div_pr">
                <div className="eddw">
                  <label>
                    Status <span>:</span>
                  </label>
                </div>
                <select
                  name="statusFilter"
                  id="statusFilter"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="Available">Available</option>
                  <option value="Occupied">Occupied</option>
                  <option value="Maintance">Under Maintance</option>
                </select>
              </div>
            </div>
          </div>

          <div className="Rooms_avail_table">
            <table>
              <thead>
                <tr>
                  {roomCounts &&
                    roomCounts.length > 0 &&
                    roomCounts.map((room, index) => (
                      <th key={index}>
                        {Object.keys(room)[0]} : {Object.values(room)[0]}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {roomCounts &&
                  roomCounts.length > 0 &&
                  Object.keys(roomCounts[0])
                    .filter((key, indx) => indx !== 0)
                    .map((key) => (
                      <tr key={key}>
                        {roomCounts.map((item, index) => (
                          <td key={index}>
                            <div>
                              <span>{key}</span> : <span>{item[key]}</span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div
            className="Rooms_avail_card"
            style={{ width: showroom ? "83%" : "92%" }}
          >
            {roomCards}
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomsAvail;
