import { React, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Pregnancy.css'

const Pregnancy = () => {

    const [expanded, setExpanded] = useState(false);


    const [checkboxes, setCheckboxes] = useState({
        certain: null,
        regular: null,
        planned: null,
        assisted: null,

    });


    const handleCheckboxChange = (name, value) => () => {
        setCheckboxes(prevState => ({
            ...prevState,
            [name]: value
        }));
    };




    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handlePost = () => {

    }

    return (
        <>
            <Accordion expanded={expanded !== 'panel2' && expanded !== 'panel3'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"

                >
                    <Typography sx={{ width: '50%', flexShrink: 0 }} id="panel1bh-header">
                        Pregnancy Summary
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="pregnancy-container">
                            <div className="new-patient-info-container1">
                                <div className="new-custom-form-row1 ">
                                    <label htmlFor="newTemperature" className="new-custom-label-title1">
                                        Cycle-q <span>:</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="newTemperature"
                                        name="newTemperature"
                                        placeholder="Enter Cycle-q"

                                        required
                                    />
                                </div>
                                <div className="new-custom-form-row1 ">
                                    <label htmlFor="newPulse" className="new-custom-label-title1">
                                        Contracetive-Type <span>:</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="newPulse"
                                        name="newPulse"
                                        placeholder="Enter type"

                                        required
                                    />
                                </div>
                                <div className="new-custom-form-row1 ">
                                    <label htmlFor="newPulse" className="new-custom-label-title1">
                                        Details <span>:</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="newSPO2"
                                        name="newSPO2"
                                        placeholder="Enter Details"

                                        required
                                    />
                                </div>
                            </div>
                            <div className="new-patient-contact-container1">
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newHeartRate" className="new-custom-label-title1">
                                        LMP <span>:</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="newHeartRate"
                                        className="new-custom-input-phone"
                                        name="newHeartRate"

                                        placeholder="Enter Heart Rate"
                                        required
                                    />
                                </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newRR" className="new-custom-label-title1">
                                        Last-used <span>:</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="newRR"
                                        className="new-custom-input-phone"
                                        name="newRR"

                                        placeholder="Enter RR"
                                        required
                                    />
                                </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newSBP" className="new-custom-label-title1">
                                        EDD by LMP <span>:</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="newSBP"
                                        name="newSBP"

                                        placeholder="Enter SBP"
                                        required
                                    />
                                </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newDBP" className="new-custom-label-title1">
                                        Final EDD <span>:</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="newDBP"
                                        name="newDBP"

                                        placeholder="Enter DBP"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="new-patient-contact-container1">
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newHeartRate" className="new-data-check-label">
                                        Certain <span>:</span>
                                    </label>
                                    <div className="req-check-box-pre">
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                
                                                
                                                id="certainYes"
                                                checked={checkboxes.certain === 'Yes'}
                                                onChange={handleCheckboxChange('certain', 'Yes')}

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >Yes</label>
                                        </div>
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="certainNo"
                                                checked={checkboxes.certain === 'No'}
                                                onChange={handleCheckboxChange('certain', 'No')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newRR" className="new-data-check-label">
                                        Regular <span>:</span>
                                    </label>
                                    <div className="req-check-box-pre">
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="regularYes"
                                                checked={checkboxes.regular === 'Yes'}
                                                onChange={handleCheckboxChange('regular', 'Yes')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >Yes</label>
                                        </div>
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="regularNo"
                                                checked={checkboxes.regular === 'No'}
                                                onChange={handleCheckboxChange('regular', 'No')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >No</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="newDBP" className='new-data-check-label'>
                                        Planned-preg <span>:</span>
                                    </label>
                                    <div className="req-check-box-pre">
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="plannedYes"
                                                checked={checkboxes.planned === 'Yes'}
                                                onChange={handleCheckboxChange('planned', 'Yes')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >Yes</label>
                                        </div>
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="plannedNo"
                                                checked={checkboxes.planned === 'No'}
                                                onChange={handleCheckboxChange('planned', 'No')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >No</label>
                                        </div>
                                    </div>                                
                                    </div>
                                <div className="new-custom-form-row1">
                                    <label htmlFor="" className='new-data-check-label'>Conception/Assisted <span>:</span></label>
                                    <div className="req-check-box-pre">
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="assistedYes"
                                                checked={checkboxes.assisted === 'Yes'}
                                                onChange={handleCheckboxChange('assisted', 'Yes')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >Yes</label>
                                        </div>
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id="assistedNo"
                                                checked={checkboxes.assisted === 'No'}
                                                onChange={handleCheckboxChange('assisted', 'No')}
                                                name="newHeartRate"

                                                placeholder="Enter Heart Rate"
                                                required
                                            />
                                            <label >No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="new-patient-contact-container1">
                                <div className="req-qus">
                                    <div className="req-qus2">
                                        <label className="new-custom-label-title1" htmlFor="dating">Dating Method</label>

                                        <div className="check1">
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />
                                                <label htmlFor="check">T<sub>1</sub>US</label>
                                            </div>
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />
                                                <label htmlFor="check">T<sub>2</sub>US</label>
                                            </div>
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />
                                                <label htmlFor="check">LMP</label>
                                            </div>
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />

                                                <label htmlFor="check">IUI</label>


                                            </div>
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />

                                                <label htmlFor="check">Embryo Transfer</label>


                                            </div>
                                            <div className="req-qus3">
                                                <input type="checkbox" name="check" id="" />
                                                <label htmlFor="check">Other</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table_container1_preg">
                                <table className='input_table_preg'>
                                    <thead>
                                        <tr>
                                            <th>Gravida</th>
                                            <th>Term</th>
                                            <th>Preterm</th>
                                            <th>Abortus</th>
                                            <th>Living Children</th>
                                            <th>Stillbirth</th>
                                            <th>Neonatal/Child Death</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                            <td className='input-sech-fld1'>
                                                <input type="text" name="" id="" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            

                        </div>
                        


                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"

                >
                    <Typography sx={{ width: '45%', flexShrink: 0 }} id="panel1bh-header">
                        Obsterical History
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="table_container1_preg1">
                            <table className='input_table_preg'>
                                <thead>
                                    <tr>
                                        <th>Year/Month</th>
                                        <th>Place Of Birth</th>
                                        <th>Gest(wks)</th>
                                        <th>Labour Length</th>
                                        <th>Comments regarding Abortus,Pregnancy,Birth and
                                            Newborn
                                        </th>
                                        <th>Sex (Male/Female)</th>
                                        <th>Birth weight</th>
                                        <th>Breastfed/Duration</th>
                                        <th>Children Curent health</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><textarea name="comments" id="" cols="50" rows="1"></textarea></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                        <td className='input-sech-fld1'><input type="text" name="" id="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className="Register_btn_con1">
                <div className=""><button onClick={handlePost} className='RegisterForm_1_btns'>Register</button></div>
            </div>


            
        </>
    );
}
export default Pregnancy;

