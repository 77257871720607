import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const DayBookReport = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [Select_namedata, setSelect_namedata] = useState([]);

  const [locationoptions, setlocationOptions] = useState([]);
  const [formData, setFormData] = useState({
    BranchName: userRecord?.location,
    FromStaff: "",
    ToStaff: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    axios
      .get("https://vesoftometic.co.in/usercontrol/getlocationdata")
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");
      if (value === "Current") {
        setFormData({
          ...formData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const dynamicColumns = [
    { field: "id", headerName: "S.NO", width: 60 },
    { field: "DCD_Date", headerName: "Date", width: 80 },
    { field: "Issue_from_Name", headerName: "Issue From Name", width: 140 },
    { field: "Issue_To_Name", headerName: "Issue To Name", width: 140 },
    { field: "Opening_Cash", headerName: "Opening Cash", width: 120 },
    {
      field: "Pharmacy_Collected_Cash",
      headerName: " Pharmacy Collected Cash",
      width: 120,
    },
    {
      field: "Procedure_Collected_Cash",
      headerName: "Procedure Collected Cash",
      width: 120,
    },
    {
      field: "Consultancy_Collected_Cash",
      headerName: "Consultancy Collected Cash",
      width: 120,
    },
    { field: "Expenses_Cash", headerName: "Expenses Cash", width: 120 },
    { field: "Available_cash", headerName: "Available cash", width: 120 },
    { field: "HandOver_Cash", headerName: "HandOver Cash", width: 120 },
    { field: "Balance_Cash", headerName: "Balance Cash", width: 120 },
    { field: "Reason", headerName: "Reason", width: 120 },
  ];

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/usercontrol/getuserregisterID_NAME?location=${userRecord?.location}`
      )
      .then((response) => {
        setSelect_namedata(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handletoSearch = () => {
    const params = {
      BranchName: formData.BranchName, // Replace with actual values or variables
      FromStaff: formData.FromStaff,
      ToStaff: formData.ToStaff,
      DateType: formData.DateType,
      CurrentDate: formData.CurrentDate,
      FromDate: formData.FromDate,
      ToDate: formData.ToDate,
    };
    if (
      params.DateType === "" &&
      params.FromStaff === "" &&
      params.ToStaff === ""
    ) {
      alert("Enter Data");
    } else {
      axios
        .get(
          `https://vesoftometic.co.in/Pettycash_link/get_day_closing_data_Detially`,
          { params }
        )
        .then((response) => {
          console.log("111", response.data);
          const A_data = response.data;
          const B_data = A_data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));
          setFilteredRows(B_data);
          setFormData({
            BranchName: userRecord?.location,
            FromStaff: "",
            ToStaff: "",
            DateType: "",
            CurrentDate: "",
            FromDate: "",
            ToDate: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Date", dataKey: "DCD_Date" },
        { header: "Issue From", dataKey: "Issue_from_Name" },
        { header: "Issue To", dataKey: "Issue_To_Name" },
        { header: "Opening Amount", dataKey: "Opening_Cash" },
        {
          header: " Pharmacy Collected Cash",
          dataKey: "Pharmacy_Collected_Cash",
        },
        {
          header: "Procedure Collected Cash",
          dataKey: "Procedure_Collected_Cash",
        },
        {
          header: "Consultancy Collected Cash",
          dataKey: "Consultancy_Collected_Cash",
        },
        { header: "Expense Amount", dataKey: "Expenses_Cash" },
        { header: "Available Amount", dataKey: "Available_cash" },
        { header: "HandOver Amount", dataKey: "HandOver_Cash" },
        { header: "Balance Amount", dataKey: "Balance_Cash" },
        { header: "Reason", dataKey: "Reason" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "Report.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Day Closing Report</h4>
      </div>
      <br />
      <div className="RegisFormcon">

        <div className="RegisForm_1">
          <label htmlFor="">
            Branch Name <span>:</span>
          </label>

          <select
            name="BranchName"
            value={formData.DateBranchNameType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {locationoptions.map((location, index) => (
              <option key={index} value={location.location_name}>
                {location.location_name}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={formData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>

        {formData.DateType === "Customize" && (
         
          <>
            <div className="RegisForm_1">
              <label htmlFor="">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={formData.FromDate}
                onChange={handleChange}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={formData.ToDate}
                onChange={handleChange}
              />
            </div>
          </>
        )}

        <div className="RegisForm_1">
          <label htmlFor="">
            From Staff<span>:</span>
          </label>
          <input
            name="FromStaff"
            value={formData.FromStaff}
            onChange={handleChange}
            list="issueFrom_1"
          />
          <datalist id="issueFrom_1">
            {Select_namedata.map((item, index) => (
              <option key={index} value={item.username}></option>
            ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            To Staff <span>:</span>
          </label>
          <input
            name="ToStaff"
            value={formData.ToStaff}
            onChange={handleChange}
            list="issueTo_2"
          />
          <datalist id="issueTo_2">
            {Select_namedata.map((item, index) => (
              <option key={index} value={item.username}></option>
            ))}
          </datalist>
        </div>
        
      </div>
      <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handletoSearch}>
            Search
          </button>
        </div>
      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
        </div>
      )}
    </div>
  );
};

export default DayBookReport;
