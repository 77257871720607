import React, { useState, useEffect } from "react";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import SearchIcon from "@mui/icons-material/Search";
// import Button from "@mui/material/Button";
// import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

// const theme = createTheme({
//   components: {
//     MuiDataGrid: {
//       styleOverrides: {
//         columnHeader: {
//           backgroundColor: "var(--ProjectColor)",
//           textAlign: "Center",
//         },
//         root: {
//           "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
//           {
//             textAlign: "center",
//             display: "flex !important",
//             justifyContent: "center !important",
//           },
//           "& .MuiDataGrid-window": {
//             overflow: "hidden !important",
//           },
//         },
//         cell: {
//           borderTop: "0px !important",
//           borderBottom: "1px solid  var(--ProjectColor) !important",
//           display: "flex",
//           justifyContent: "center",
//         },
//       },
//     },
//   },
// });

const PerformanceManagement = () => {
  // const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [rows, setRows] = useState([]);
  const [FilteredRows, setFilteredRows] = useState([])
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  
  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "employeeName") {
      setSearchQuery(value);
    } else if (id === "designation") {
      setSearchQuery1(value);
    } else if (id === "date") {
      setSearchQuery2(value);
    }
  };



  useEffect(() => {
    const location = userRecord.location;
    axios
      .get(
        `${urllink}HRmanagement/get_employeeperformance?location=${location}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        setRows(data);

      })
      .catch((error) => {
        console.log(error);
      });
  }, [userRecord, urllink]);



  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowercaseEmployeeName = row.EmployeeName.toLowerCase();
      const lowerCaseDesignation = row.Designation.toString();
      const lowercasedate = row.Date.toString();

      const startsWithEmployeeName = lowercaseEmployeeName.startsWith(searchQuery.toLowerCase());
      const startsWithDesignation  = lowerCaseDesignation.startsWith(searchQuery1.toLowerCase());
      const startsWithDate  = lowercasedate.startsWith(searchQuery2.toLowerCase());


      return (startsWithEmployeeName || !searchQuery) && (startsWithDesignation || !searchQuery1) && (startsWithDate || !searchQuery2);
    });

    // If there is a search query, sort the data to bring the left-to-right matching rows to the top


    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1,searchQuery2, rows]);



  return (

    <div className="appointment">
      <div className="h_head">
        <h4>Performance Management </h4>
      </div>


      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="employeeName">Employee Name <span>:</span></label>
          <input
            type="text"
            id="employeeName"
            name="employeeName"
            placeholder="Enter Employee Name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="inp_1">
          <label htmlFor="designation">Designation <span>:</span></label>
          <select
            name="designation"
            className="new-custom-input-phone vital_select"
            id="designation"
            value={searchQuery1}
            onChange={handleSearchChange}
          >
            <option value="alldesignation"> Select</option>
            <option value="doctor">Doctor</option>
            <option value="Nurse"> Nurse</option>
            <option value="frontoffice">Front Office </option>
          </select>
        </div>
      </div>
      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="date">Date <span>:</span></label>
          <input
            type="date"
            id="date"
            name="date"
            value={searchQuery2}
            onChange={handleSearchChange}
          />
        </div>

      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Sl.No</th>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Date</th>
              <th id="slectbill_ins">Previous Basic Pay</th>
              <th id="slectbill_ins">Performance_Rate</th>
              <th id="slectbill_ins">Hike Percentage</th>
              <th id="slectbill_ins">Hike Amount</th>
              <th id="slectbill_ins">New Salary</th>
              <th id="slectbill_ins">Remarks</th>
              <th id="slectbill_ins">Approved By</th>
            </tr>
          </thead>
          <tbody>
            {FilteredRows.map((row) => (
              <tr key={row.id}>
                <td>{row.Sl_No}</td>
                <td>{row.EmployeeID}</td>
                <td>{row.EmployeeName}</td>
                <td>{row.Date}</td>
                <td>{row.Current_Payment}</td>
                <td>{row.Performance_Rate}</td>
                <td>{row.Hike_Percentage}</td>
                <td>{row.Hike_Amount}</td>
                <td>{row.New_Pay}</td>
                <td>{row.Remarks}</td>
                <td>{row.ApprovedBy}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default PerformanceManagement;
