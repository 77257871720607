import React, { useEffect, useState } from 'react';
// import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function Container() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const[containerData,setContainerData]=useState([])
  const [container,setContainer] = useState('');
  const [containerCode,setContainerCode] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid ] = useState(null);

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: 'toast-container-over-header',
      style: { marginTop: '50px' },
    });
  };

  const handleSubmitContainer=()=>{
    if (!container.trim() || !containerCode.trim()) {
      alert('Both container and containercode are required.');
      return; // Exit the function early if validation fails
    }
    axios.post(`${urllink}mainddepartment/insertcontainer`, {
        container,
        containerCode,
      })
      .then((response)=>{
        successMsg(response.data.message)
        setContainer('');
        setContainerCode('')
        fetchContainerData()
      })
      .catch((error)=>{
        console.error('An error occurred:', error);
       
      })
  }



  useEffect(() => {
    fetchContainerData();
  }, []);

  const fetchContainerData = () => {
    axios.get(`${urllink}mainddepartment/getcontainer`)
      .then((response) => {
        const data = response.data;
        console.log("data",data)
  
        setContainerData(data)
      })
      .catch((error) => {
        console.error('Error fetching container data:', error);
      });
  };

  const handleEdit = (row) => {
    // Assuming `method_id` is the identifier
    setContainer(row.container_name);
      setContainerCode(row.container_code)
      setIsEditMode(true)
      setSelectedid(row.container_id)
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(`${urllink}mainddepartment/updatecontainer`, {
        method_id: selectedid,
        method_name: container,
        method_code: containerCode,
      });
      // console.log(response.data);
      successMsg("suceess")
      setContainer('');
      setContainerCode('')
      setIsEditMode(false)
      setSelectedid('')
      
      // Optionally, you can reset the state after a successful submission
     
      fetchContainerData();
      
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  
//   const userwarn = (warningMessage) => {
//     toast.warn(`${warningMessage}`, {
//       position: "top-center",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "dark",
//       style: { marginTop: '50px'},
// });
// };


  return (
    <>
    <div className='ShiftClosing_over'>
      <div className="ShiftClosing_Container">
        <div className="ShiftClosing_header">
          <h3>Container Management</h3>
        </div>
      </div>
      
      <div className="ShiftClosing_Container">
      <div className='FirstpartOFExpensesMaster'>
        <h2 style={{ textAlign: 'center' }}>Container</h2>


            <div className="con_1 ">
            <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Container Name :</label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={container}
                  onChange={(e) => setContainer(e.target.value)}
                  placeholder="Enter Designation Name"
                  style={{ width: "150px" }}
                />

              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Container Code :</label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={containerCode}
                  onChange={(e) => setContainerCode(e.target.value)}
                  placeholder="Enter Designation Name"
                  style={{ width: "150px" }}
                />
              </div>
              <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitContainer}>
  {isEditMode ? 'Update' : <AddIcon />}
</button>

            </div>


            <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th >S.No</th>
                      <th>Container Name</th>
                      <th >Container Code</th>
                      <th >Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                  {containerData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.container_id}</td>
                          <td>{row.container_name}</td>
                          <td>

                            {row.container_code}
                          </td>
                          <td>
                             <button onClick={() => handleEdit(row)}><EditIcon/></button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

              </div>

            </div>

          </div>  
      </div>
      
    </div>
    <ToastContainer/>
  </>
 
  );
}

export default Container;
