import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import IcuDocVitals from "./IcuDocVitals";
import IcuDocIntakeOutput from "./IcuDocIntakeOutput";
import IcuDocDrugAdminis from "./IcuDocDrugAdminis";
import IcuLabTest from "../ICUNurseflow/IcuLabTest";
import IcuMedicalRecords from "./IcuMedicalRecords";
import IcuSurgicalHistory from "./IcuSurgicalHistory";
import ConsentFormCreate from "./ConsentFormCreate";
import IcuTreatment from "./IcuTreatment";
import IcuProgressNotes from "./IcuProgressNotes";
import IcuDocTreatment from "./IcuDocTreatment";
import IcuAssesment from "./IcuAssesment";
import MedicalHistoryForm from "./MedicalHistoryForm";
import MedicalHistoryForm2 from "./MedicalHistoryForm2";
import InputOutput from "./InputOutput";
import BedTransfer from "./BedTransfer";
import Mlc from "./Mlc";
import PreOpChecklist from "./PreOpChecklist";
import Discharge from "./Discharge";
import CrossConsultation from "./CrossConsultation";
import Dama from "./Dama";
import IcuIntakeOutput from "../ICUNurseflow/IcuIntakeOutput";
import OtManagementForm from "../../IpManagement/IpDoctorflow/IpPreoperativeChecklist"


function IcuDoctorWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("vitalForm");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/IcuDocWorkBench')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.Booking_Id}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("IcuAssesment")} style={{ color: activeTab === 'IcuAssesment' ? "white" : '' }}>
                            Assesment
                            </button>
                            
                            |
                            <button onClick={() => handleTabChange("IcuDocTreatment")} style={{ color: activeTab === 'IcuDocTreatment' ? "white" : '' }}>
                            ICD Diagnosis / Treatment
                            </button>
                            |
                           
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                            Prescription
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("ConsentForm")} style={{ color: activeTab === 'ConsentForm' ? "white" : '' }}>
                            Consent Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("ProgressNotes")} style={{ color: activeTab === 'ProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            |
                            <button onClick={() => handleTabChange("InputOutput")} style={{ color: activeTab === 'InputOutput' ? "white" : '' }}>
                            Input/Output chart
                            </button>
                            |
                            <button onClick={() => handleTabChange("BedTransfer")} style={{ color: activeTab === 'BedTransfer' ? "white" : '' }}>
                             Bed Transfer
                            </button>
                            |
                            <button onClick={() => handleTabChange("Mlc")} style={{ color: activeTab === 'Mlc' ? "white" : '' }}>
                            MLC
                            </button>
                            |
                            <button onClick={() => handleTabChange("PreOpChecklist")} style={{ color: activeTab === 'PreOpChecklist' ? "white" : '' }}>
                            PreOpChecklist
                            </button>
                            |
                            <button onClick={() => handleTabChange("Discharge")} style={{ color: activeTab === 'Discharge' ? "white" : '' }}>
                            Discharge
                            </button>
                            |
                            <button onClick={() => handleTabChange("CrossConsultation")} style={{ color: activeTab === 'CrossConsultation' ? "white" : '' }}>
                            Cross Consultation
                            </button>
                            |
                            <button onClick={() => handleTabChange("Dama")} style={{ color: activeTab === 'Dama' ? "white" : '' }}>
                            DAMA
                            </button>
                            
                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("IcuAssesment")} style={{ color: activeTab === 'IcuAssesment' ? "white" : '' }}>
                            Assesment
                            </button>
                            
                            |
                            <button onClick={() => handleTabChange("IcuDocTreatment")} style={{ color: activeTab === 'IcuDocTreatment' ? "white" : '' }}>
                            ICD Diagnosis / Treatment
                            </button>
                            |
                    
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Prescription
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("ConsentForm")} style={{ color: activeTab === 'ConsentForm' ? "white" : '' }}>
                            Consent Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("ProgressNotes")} style={{ color: activeTab === 'ProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            |
                            <button onClick={() => handleTabChange("InputOutput")} style={{ color: activeTab === 'InputOutput' ? "white" : '' }}>
                            Input/Output chart
                            </button>
                            |
                            <button onClick={() => handleTabChange("BedTransfer")} style={{ color: activeTab === 'BedTransfer' ? "white" : '' }}>
                             Bed Transfer
                            </button>
                            |
                            <button onClick={() => handleTabChange("Mlc")} style={{ color: activeTab === 'Mlc' ? "white" : '' }}>
                            MLC
                            </button>
                            |
                            <button onClick={() => handleTabChange("PreOpChecklist")} style={{ color: activeTab === 'PreOpChecklist' ? "white" : '' }}>
                            PreOpChecklist
                            </button>
                            |
                            <button onClick={() => handleTabChange("Discharge")} style={{ color: activeTab === 'Discharge' ? "white" : '' }}>
                            Discharge
                            </button>
                            |
                            <button onClick={() => handleTabChange("CrossConsultation")} style={{ color: activeTab === 'CrossConsultation' ? "white" : '' }}>
                            Cross Consultation
                            </button>
                            |
                            <button onClick={() => handleTabChange("Dama")} style={{ color: activeTab === 'Dama' ? "white" : '' }}>
                            DAMA
                            </button>
                           
                            

                        </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <IcuDocVitals />}
            {activeTab === "IcuAssesment" && <IcuAssesment />}
            {activeTab === "MedicalHistory" && <IcuSurgicalHistory/>}
            {activeTab === "Intake/OutPut" && <IcuDocIntakeOutput/>}
            {activeTab === "DrugAdministration" && <IcuDocDrugAdminis/>}
            {activeTab === "Lab" && <IcuLabTest/>}
            {activeTab === "ConsentForm" && <ConsentFormCreate/>}
            {/* {activeTab === "IcuTreatment" && <IcuTreatment/>} */}
            {activeTab === "ProgressNotes" && <IcuProgressNotes/>}
            {activeTab === "IcuDocTreatment" && <IcuDocTreatment/>}
            {activeTab === "MedicalHistoryForm" && <MedicalHistoryForm/>}
            {activeTab === "MedicalHistoryForm2" && <MedicalHistoryForm2/>}
            {activeTab === "InputOutput" && <IcuIntakeOutput/>}
            {/* {activeTab === "InputOutput" && <IpPreoperativeIns/>} */}
            {activeTab === "BedTransfer" && <BedTransfer/>}
            {activeTab === "Mlc" && <Mlc/>}
            {activeTab === "PreOpChecklist" && <OtManagementForm/>}
            {activeTab === "Discharge" && <Discharge/>}
            {activeTab === "CrossConsultation" && <CrossConsultation/>}
            {activeTab === "Dama" && <Dama/>}
        </>
    );
}

export default IcuDoctorWorkbench;

