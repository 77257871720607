const initstate={
    RateCardEditdata:{}

};

const RateCardStore=(state=initstate,action)=>
{
    switch(action.type)
    {
        case 'RateCarddata':
            return{...state,RateCardEditdata:action.value}
        
        
        default:
            return state;
    }
}

export default RateCardStore;