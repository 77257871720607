import React, { useState } from "react";
import * as XLSX from 'xlsx';
import "./News.css";

function News() {
    // onchange state
    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);
    // submit state
    const [excelData, setExcelData] = useState(null);

    // onchange event
    const handleFile = (e) => {
        let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileTypes.includes(selectedFile.type)) {
                setTypeError(null);
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFile(e.target.result);
                }
            } else {
                setTypeError('Please select only Excel file types');
                setExcelFile(null);
            }
        } else {
            console.log('Please select your file');
        }
    }

    // submit event
    const handleFileSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            // Ensure data is a 2D array
            const formattedData = Array.isArray(data) ? data : [data];
            setExcelData(formattedData);
        }
    }

    return (
        <div className="wrapper">
            <h3>Upload & View Excel Sheets</h3>
            {/* form */}
            <form className="form-group custoom-form" onSubmit={handleFileSubmit} encType="multipart/form-data">
                <input type="file" className="form-control" required onChange={handleFile} />
                <button type="submit" className="btn btn-success btn-md">UPLOAD</button>
                {typeError && (
                    <div className="alert alert-danger" role="alert">{typeError}</div>
                )}
            </form>
            {/* view data */}
            <div className="viewer">
                {excelData ? (
                    <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    {excelData[0].map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {excelData.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>No file is uploaded yet!</div>
                )}
            </div>
        </div>
    );
}

export default News;
