import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useSelector } from "react-redux";

function CounselorProcedure() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const for_counselor_navigation_data = useSelector(
    (state) => state.userRecord?.for_counselor_navigation_data
  );
  console.log(for_counselor_navigation_data);
  const create = userRecord?.username;
  const location = userRecord?.location;

  const [proceduredata, setproceduredata] = useState([]);
  const [ServiceEdit, setServiceEdit] = useState(false);
  const [editedServiceIndex, setEditedServiceIndex] = useState(null);
  const [appointmentdate, setAppointmentdate] = useState("");
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [formData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
    PhoneNumber: "",
    ConsultationStatus: "",
    ProcedureStatus: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://vesoftometic.co.in/appointmentmanagement/get_appointments_Navigation?patientid=${for_counselor_navigation_data.Patientid}&location=${userRecord.location}`
        );
        console.log(response.data);

        const matchingAppointment = response.data[0];
        console.log(matchingAppointment);

        setFormData({
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.AppointmentDate,
          Complaint: matchingAppointment.Complaint,
          PatientPhoto: `data:image/jpeg;base64,${matchingAppointment.PatientPhoto}`,
          Age: matchingAppointment.Age,
          Gender: matchingAppointment.Gender,
          Location: matchingAppointment.Location,
          PhoneNumber: matchingAppointment.PhoneNumber,
          ConsultationStatus: matchingAppointment.consultation_status,
          ProcedureStatus: matchingAppointment.procedure_status,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData(userRecord?.location);
  }, [for_counselor_navigation_data.Patientid, userRecord?.location]);

  console.log(formData);

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/Counselor/get_procedure_forcounselor?patientid=${formData.PatientID}&visitid=${formData.visitNo}`
      )
      .then((response) => {
        console.log(response.data);
        setFormValues(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formData.PatientID, formData.visitNo]);

  const [formValues, setFormValues] = useState({
    DoctorName: "",
    ProcedureName: "",
    Sessions: "",
    nextAppointment: "",
    Amount: "",
    TotalAmount: "",
    appointmentDate: "",
    instruction: "",
  });

  const handleAdd = () => {
    if (
      formValues.DoctorName &&
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.nextAppointment &&
      formValues.instruction
    ) {
      const form = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: "Procedure",
        DoctorName: formValues.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        PaidSessions: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount,
        nextAppointment: formValues.nextAppointment,
        Status: "Pending",
        appointmentDate: formData.AppointmentDate,
        instruction: formValues.instruction,
        CreatedBy: create,
        location: location,
        PhoneNumber: formData.PhoneNumber,
        ConsultationStatus: formData.ConsultationStatus,
        ProcedureStatus: formData.ProcedureStatus,
      };
      console.log("forms :", form);
      setproceduredata((prev) => [...prev, form]);
      setFormValues({
        DoctorName: "",
        ProcedureName: "",
        Sessions: "",
        Amount: "",
        TotalAmount: "",
        appointmentDate: "",
        nextAppointment: "",
        instruction: "",
      });
    } else {
      alert("Please fill in all the fields");
    }
  };

  const handleUpdate = () => {
    if (
      formValues.DoctorName &&
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.instruction
    ) {
      const updatedProcedure = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: "Procedure",
        DoctorName: formValues.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        PaidSessions: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount,
        nextAppointment: formValues.nextAppointment,
        Status: "Pending",
        appointmentDate: appointmentdate,
        instruction: formValues.instruction,
        CreatedBy: create,
        location: location,
        PhoneNumber: formData.PhoneNumber,
      };

      const updatedProcedureData = [...proceduredata];
      updatedProcedureData[editedServiceIndex] = updatedProcedure;

      setproceduredata(updatedProcedureData);
      setFormValues({
        DoctorName: "",
        ProcedureName: "",
        Sessions: "",
        Amount: "",
        TotalAmount: "",
        appointmentDate: "",
        nextAppointment: "",
        instruction: "",
        CreatedBy: create,
        location: location,
      });
      setServiceEdit(false);
      setEditedServiceIndex(null);
    } else {
      alert("Please fill in all the fields");
    }
  };

  const handleEdit = (index) => {
    setFormValues(proceduredata[index]);
    setEditedServiceIndex(index);
    setServiceEdit(true);
  };

  const handleSave = () => {
    console.log("procedure data :", proceduredata);
    axios
      .post(
        "https://vesoftometic.co.in/Counselor/insert_counselorprocedure",
        proceduredata
      )
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        navigate("/Home/Treament-CounselorFullList");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <>
      <div className="appointment">
        <div className="dctr_info_up_head">
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
              <img src={formData.PatientPhoto || ""} />
              <label>Profile </label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.firstName} {formData.lastName}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.PatientID}
              </span>
            </div>

            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Age}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Gender}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Location <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Location}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Primary Doctor <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.DoctorName}
              </span>
            </div>
          </div>
        </div>
        {/*       
        </div>
        <div className="appointment"> */}
        <div className="h_head">
          <h4>Counselor Procedure</h4>
        </div>

        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProcedureName">Treatment Procedure:</label>
            <input
              id="ProcedureName"
              name="ProcedureName"
              value={formValues?.ProcedureName}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  ProcedureName: e.target.value,
                }))
              }
              readOnly
            ></input>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="DoctorName">Therapist Name:</label>
            <input
              id="DoctorName"
              name="DoctorName"
              value={formValues?.DoctorName}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  DoctorName: e.target.value,
                }))
              }
              readOnly
            ></input>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Amount">Procedure Amount:</label>
            <input
              type="number"
              id="Amount"
              name="Amount"
              value={formValues?.Amount}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  Amount: e.target.value,
                }))
              }
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Sessions">Number of Sessions:</label>
            <input
              type="number"
              id="Sessions"
              name="Sessions"
              value={formValues?.Sessions}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  Sessions: e.target.value,
                }))
              }
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Amount">Total Procedure Amount:</label>
            <input
              type="number"
              id="Amount"
              name="TotalAmount"
              readOnly
              value={formValues?.TotalAmount}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="nextAppointment">Follow up Date :</label>
            <input
              type="date"
              id="nextAppointment"
              name="nextAppointment"
              value={formValues?.nextAppointment}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  nextAppointment: e.target.value,
                }))
              }
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="nextAppointment">Reason :</label>
            <textarea
              type="text"
              id="instruction"
              name="instruction"
              value={formValues?.instruction}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  instruction: e.target.value,
                }))
              }
              required
            />
          </div>
        </div>
        <br />
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            onClick={ServiceEdit ? handleUpdate : handleAdd}
          >
            {ServiceEdit ? "Update" : "Add"}
          </button>
        </div>
        <div className="Selected-table-container">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th id="vital_Twidth">Service Type</th>
                <th id="vital_Twidth">Therapist Name</th>
                <th id="vital_Twidth">Procedure Name</th>
                <th id="vital_Twidth"> Charge </th>
                <th id="vital_Twidth">Sessions</th>
                <th id="vital_Twidth">Total </th>
                <th id="vital_Twidth"> Follow-up Date </th>
                <th id="vital_Twidth">Action</th>
              </tr>
            </thead>
            <tbody>
              {proceduredata.map((client, index) => (
                <tr key={index}>
                  <td>{client.ServiceType}</td>
                  <td>{client.DoctorName}</td>
                  <td>{client.ProcedureName}</td>
                  <td>{client.Amount}</td>
                  <td>{client.Sessions}</td>
                  <td>{client.TotalAmount}</td>
                  <td>{client.nextAppointment}</td>
                  <td>
                    <button
                      className="edit-edit34"
                      onClick={() => handleEdit(index)}
                    >
                      <EditNoteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default CounselorProcedure;
