import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import './Neww.css';
import Button from '@mui/material/Button';
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#54d854bf",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  #54d854bf !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const ExternalLabList = () => {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    const pageSize = 10;
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const showdown = filteredRows.length;

    useEffect(() => {
        axios.get(`${urllink}usercontrol/getexternallablist`)
            .then((response) => {
                const data = response.data.map((row) => ({
                    id: row.S_No,
                    ...row,
                }));
                setsumma(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {  
            const lowerCaseSupplierName = row.PatientName ? row.PatientName.toLowerCase() : '';
            return lowerCaseSupplierName.includes(lowerCaseQuery);
        });
        setFilteredRows(filteredData);
    }, [searchQuery, summa]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const dynamicColumns = [
        { field: 'S_No', headerName: 'Lab Id', width: 150 },
        { field: 'Lab_Name', headerName: 'Lab Name', width: 200 },
        { field: 'Register_No', headerName: 'Register No', width: 150 },
        { field: 'Labcode', headerName: 'Lab Code', width: 150 },
        { field: 'Phone_No', headerName: 'Phone No', width: 150 },
        { field: 'Location', headerName: 'Location', width: 150 },
        { field: 'SourceType', headerName: 'Source', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: () => (
                <>
                    <Button className='cell_btn'>
                        <EditIcon />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>Lab List</h3>
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Lab Name :</label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Item Name' />
                    </div>
                    <button className='btn_1' type='submit'>
                        <SearchIcon />
                    </button>
                </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ExternalLabList;
