import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../ICUNurseflow/IcuNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IcuDocDrugAdminis = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const [summa, setsumma] = useState([]);
  const [summa1, setsumma1] = useState(null);
  const showdown = summa.length;
  const showdown1 = summa1?.medicinedata.length || 0;
  const totalPages = Math.ceil(summa.length / 10);
  const totalPages1 = Math.ceil(summa1?.medicinedata.length / 10);
  const handlePageChange = (params) => {
    if (type === "Intake") {
      setPage(params.page);
    } else {
      setPage1(params.page);
    }
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const [type, setType] = useState("Intake");
  const [AddPriscribtion, setAddPriscribtion] = useState({
    Department: "",
    DoctorName: "",
    GenericName: "",
    MedicineCode: "",
    MedicineName: "",
    Dosage: "",
    Route: "",
    Site:"",
    FrequencyMethod: "Regular",
    FrequencyType: "",
    Frequency: "",
    FrequencyTime: "",
    Duration: "",
    DurationType: "days",
    AdminisDose: "",
    Quantity: "",
    Location: "",
    InfusionRate: "",
    PrescribedDate: "",
    Date: "",
    Time: "",
    Instruction: "",
  });

  const [selectedTimes, setSelectedTimes] = useState([]);

  useEffect(() => {
    if (AddPriscribtion.Frequency) {
      const frequencytypeval = selectedTimes.find(
        (p) => p.Frequency === AddPriscribtion.Frequency
      );
      setAddPriscribtion((prev) => ({
        ...prev,
        FrequencyTime: frequencytypeval?.FrequencyTime,
      }));
    }
  }, [AddPriscribtion.Frequency, selectedTimes]);
  const [getdatastate, setgetdatastate] = useState(false);

  const warnmessage = (warnmsg) => {
    toast.warn(`${warnmsg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (name === "MedicineName") {
      setAddPriscribtion((prev) => ({
        ...prev,
        [name]: value.split(",")[1],
        MedicineCode: value.split(",")[0],
      }));
    } else {
      setAddPriscribtion((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (
      AddPriscribtion?.Frequency &&
      AddPriscribtion?.Duration &&
      AddPriscribtion?.DurationType
    ) {
      // Split the Frequency string into an array
      const frequency = AddPriscribtion?.Frequency.split("-");
      // Calculate the sum of frequencies
      const sum = frequency.reduce((acc, val) => acc + parseInt(val), 0);

      let durationdata = 0;
      // Adjust durationdata based on the DurationType
      if (AddPriscribtion?.DurationType === "Weeks") {
        durationdata = sum * 7;
      } else if (AddPriscribtion?.DurationType === "Months") {
        durationdata = sum * 30;
      } else {
        durationdata = sum * 1;
      }
      console.log("durationdata", durationdata);

      let quantityData = 0;

      if (AddPriscribtion?.Route === "Syrup") {
        const dattt = durationdata * AddPriscribtion?.Duration;
        console.log("dattt", dattt);
        const rrrr = dattt * AddPriscribtion?.AdminisDose;
        let qnt = 0;
        const dosageSplit = AddPriscribtion?.Dosage.split(" ");
        const dosageValue = parseFloat(dosageSplit[0]);
        const dosageUnit = dosageSplit[1];
        if (dosageUnit === "ml") {
          if (rrrr > dosageValue) {
            qnt = rrrr / dosageValue;
          } else {
            qnt = 1;
          }
        } else if (dosageUnit === "l") {
          qnt = dosageValue * 1000; // Convert liters to milliliters
          if (rrrr > dosageValue * 1000) {
            qnt = rrrr / (dosageValue * 1000);
          } else {
            qnt = 1;
          }
        }

        console.log("qnt", qnt);
        console.log("rrrr", rrrr);
        quantityData = Math.ceil(qnt);
      } else {
        // Calculate Quantity based on durationdata and Duration
        quantityData = durationdata * AddPriscribtion.Duration;
      }

      console.log("Route", AddPriscribtion.Route);

      // Update the state with the new Quantity value
      setAddPriscribtion((prev) => ({
        ...prev,
        Quantity: quantityData,
      }));
    }
  }, [
    AddPriscribtion?.Frequency,
    AddPriscribtion?.Duration,
    AddPriscribtion?.DurationType,
    AddPriscribtion?.AdminisDose,
    AddPriscribtion?.Route,
    AddPriscribtion?.Site,
    AddPriscribtion?.Dosage,
  ]);

  const handlecleardata = () => {
    setAddPriscribtion((prev) => ({
      ...prev,
      Department: "",
      DoctorName: "",
      GenericName: "",
      MedicineCode: "",
      MedicineName: "",
      Dosage: "",
      Route: "",
      Site:"",
      FrequencyMethod: "Regular",
      FrequencyType: "",
      Frequency: "",
      FrequencyTime: "",
      Duration: "",
      DurationType: "days",
      AdminisDose: "",
      Quantity: "",
      Location: "",
      InfusionRate: "",
      PrescribedDate: "",
      Date: "",
      Time: "",
      Instruction: "",
    }));
  };
  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/IcuManagement/get_frequency_masters`)
      .then((response) => {
        const data = response.data.map((p, index) => ({
          ...p,
          id: p.Frequency_Id,
        }));
        setSelectedTimes(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/IcuManagement/get_for_ip_durgs_doctor_show?Booking_id=${IpNurseQueSelectedRow?.Booking_Id}`
      )
      .then((response) => {
        const data = response.data;

        console.log("data", data);
        // Function to convert time to AM/PM format
        // Function to convert time to AM/PM format
        const convertToAMPM = (time) => {
          const numTime = parseInt(time);
          return numTime >= 1 && numTime <= 11
            ? numTime + " AM"
            : numTime === 12
            ? "12 PM"
            : numTime - 12 + " PM";
        };

        // Extract unique times from FrequencyTime arrays and convert them to AM/PM format
        const freqdata = [
          ...new Set(
            data.flatMap((p) =>
              p.FrequencyIssued.flatMap((r) => r.FrequencyIssued)
            )
          ),
        ]
          .map((time) => convertToAMPM(time))
          .sort((a, b) => {
            // Extract AM/PM and numerical value from time string
            const [aNum, aPeriod] = a.split(" ");
            const [bNum, bPeriod] = b.split(" ");

            // Compare periods (AM comes before PM)
            if (aPeriod !== bPeriod) {
              return aPeriod.localeCompare(bPeriod);
            }

            // If periods are the same, sort numerically
            return parseInt(aNum) - parseInt(bNum);
          });

        console.log("freqdata", freqdata);

        setsumma1({
          frequencyTime: freqdata,
          medicinedata: data.map((p, indx) => ({ ...p, id: indx + 1 })),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IpNurseQueSelectedRow?.Booking_Id]);
  useEffect(() => {
    const now = new Date();
    const formattedTime = format(now, "HH:mm:ss");
    const newdate = format(new Date(), "yyyy-MM-dd");
    setAddPriscribtion((prev) => ({
      ...prev,
      Time: formattedTime,
      Date: newdate,
    }));
  }, []);

  const handleChange = (event) => {
    setType(event.target.value);
  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...Object.keys(AddPriscribtion).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: ["Instruction", "Date", "Time"].find((f) => f === labelname)
          ? labelWidth + 100
          : labelWidth + 30,
        valueGetter: (params) => {
          const value = params.row[labelname];
          return value ? value : "-";
        },
      };
    }),
  ];

  const dynamicColumns1 = () => {
    const dataaa = [
      "PrescribedDate",
      "IssuedDate",
      "Department",
      "DoctorName",
      "MedicineName",
      "FrequencyMethod",
      ...summa1?.frequencyTime,
    ];
    return [
      {
        field: "id",
        headerName: "S_No",
        width: 40,
      },
      ...dataaa.map((labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: ["Instruction", "MedicineName"].find((f) => f === labelname)
            ? labelWidth + 100
            : [...summa1?.frequencyTime].includes(labelname)
            ? labelWidth + 70
            : labelWidth + 20,
          renderCell: (params) => {
            const checkval = ![
              "PrescribedDate",
              "IssuedDate",
              "Department",
              "DoctorName",
              "MedicineName",
              "FrequencyMethod",
            ].includes(labelname.split(" ")[0]);
            if (checkval) {
              const newwtime = labelname.split(" ");
              let ttt = 0;
              if (newwtime[1] === "PM") {
                ttt = +newwtime[0] + 12;
              } else {
                ttt = +newwtime[0];
              }
              const med = params.row;
              if (med.FrequencyIssued) {
                const isChecked = med.FrequencyIssued.some(
                  (f) => +f.FrequencyIssued === ttt
                );
                const statusPending = med.FrequencyIssued.some(
                  (f) => f.Status === "Pending" && +f.FrequencyIssued === ttt
                );
                const statusIssued = med.FrequencyIssued.some(
                  (f) => f.Status === "Issued" && +f.FrequencyIssued === ttt
                );
                const statusBefore = med.FrequencyIssued.some(
                  (f) => f.Status === "Before" && +f.FrequencyIssued === ttt
                );
                const statusAfter = med.FrequencyIssued.some(
                  (f) =>
                    f.Status === "Delay" && +f.FrequencyIssued === ttt
                );
                const statusNotIssued = med.FrequencyIssued.some(
                  (f) => f.Status === "NotIssued" && +f.FrequencyIssued === ttt
                );
                const showdata = med.FrequencyIssued.filter(
                  (rr) => +rr.FrequencyIssued === ttt
                ).map((p) => ({
                  IssuedBy: p.CapturedBy,
                  IssuedDate: p.Completed_Date,
                  IssuedTime: p.Completed_Time,
                  Remarks: p.Completed_Remarks,
                }));
                const dataone = showdata[0];
                return isChecked ? (
                  statusPending ? (
                    <span style={{ color: "yellow" }}>Pending</span>
                  ) : statusIssued ? (
                    <Tooltip
                      title={
                        <div>
                          {Object.keys(dataone).map((field, indx) => (
                            <h4
                              key={indx}
                              style={{
                                padding: "3px",
                                display: "block",
                                // width: "max-content",
                                fontSize: "11px",
                              }}
                            >
                              {formatLabel(field)} <span>:</span>{" "}
                              {dataone[field]}
                            </h4>
                          ))}
                        </div>
                      }
                    >
                      <span className="check_box_clrr_1">Issued</span>
                    </Tooltip>
                  ) : statusBefore ? (
                    <Tooltip
                      title={
                        <div>
                          {Object.keys(dataone).map((field, indx) => (
                            <h4
                              key={indx}
                              style={{
                                padding: "3px",
                                display: "block",
                                // width: "max-content",
                                fontSize: "11px",
                              }}
                            >
                              {formatLabel(field)} <span>:</span>{" "}
                              {dataone[field]}
                            </h4>
                          ))}
                        </div>
                      }
                    >
                     <span className="check_box_clrr_2">Previous</span>
                    </Tooltip>
                    
                  ) : statusAfter ? (
                    <Tooltip
                      title={
                        <div>
                          {Object.keys(dataone).map((field, indx) => (
                            <h4
                              key={indx}
                              style={{
                                padding: "3px",
                                display: "block",
                                // width: "max-content",
                                fontSize: "11px",
                              }}
                            >
                              {formatLabel(field)} <span>:</span>{" "}
                              {dataone[field]}
                            </h4>
                          ))}
                        </div>
                      }
                    >
                      <span className="check_box_clrr_3">Delayed</span>
                    </Tooltip>
                   
                  ) :statusNotIssued?
                  (
                    <span className="check_box_clrr_4">Not Issued</span>
                  )
                  : (
                    <Tooltip
                      title={
                        <div>
                          {Object.keys(dataone).map((field, indx) => (
                            <h4
                              key={indx}
                              style={{
                                padding: "3px",
                                display: "block",
                                // width: "max-content",
                                fontSize: "11px",
                              }}
                            >
                              {formatLabel(field)} <span>:</span>{" "}
                              {dataone[field]}
                            </h4>
                          ))}
                        </div>
                      }
                    >
                      <span className="check_box_clrr_5">Stopped</span>
                    </Tooltip>
                    
                  )
                ) : (
                  "-"
                );
              } else {
                return "-";
              }
            } else {
              return params.value;
            }
          },
        };
      }),
    ];
  };

  const handlesubmitdata = () => {
    let requiredfields = [];
    if (AddPriscribtion.FrequencyMethod === "Regular") {
      requiredfields = [...Object.keys(AddPriscribtion)].filter(
        (key) => key !== "Instruction"
      );
    } else {
      requiredfields = [...Object.keys(AddPriscribtion)].filter(
        (key) =>
          ![
            "Instruction",
            "FrequencyType",
            "Frequency",
            "FrequencyTime",
            "Duration",
            "DurationType",
          ].includes(key)
      );
    }

    const exist = requiredfields.filter((field) => !AddPriscribtion[field]);

    if (exist.length > 0) {
      warnmessage(`The following fields are required: ${exist.join(", ")}`);
    } else {
      const indxx = summa.length + 1;
      const senddata = {
        ...AddPriscribtion,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
        Booking_Id: IpNurseQueSelectedRow?.Booking_Id,
        id: indxx, // Use the length of the array as the index
      };
      setsumma((prevSumma) => [...prevSumma, senddata]);
      setgetdatastate(!getdatastate);
      handlecleardata();
    }
  };
  const submitalldata = () => {
    if (summa.length > 0) {
      axios
        .post(
          `https://vesoftometic.co.in/IcuManagement/insert_Drug_Administration_datas`,
          summa
        )
        .then((response) => {
          console.log(response);
          // setsumma([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Intake"
              style={{
                height: "30px",
                width: "180px",
                backgroundColor:
                  type === "Intake"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              Add Drugs
            </ToggleButton>
            <ToggleButton
              value="Output"
              style={{
                backgroundColor:
                  type === "Output"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
                width: "180px",
                height: "30px",
              }}
              className="togglebutton_container"
            >
              Issue Drugs
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {type === "Intake" && (
          <>
            <div className="Total_input_container">
              <div className="inp_container_all_intakeoutput">
                <label>
                  Department <span>:</span>
                </label>

                <select
                  name="Department"
                  value={AddPriscribtion.Department}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Primary">Primary</option>
                  <option value="Duty">Duty</option>
                  <option value="Special">Special</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Doctor Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="DoctorName"
                  list="IpDoctorNameList"
                  value={AddPriscribtion.DoctorName}
                  onChange={handleInputChange}
                />
                <datalist id="IpDoctorNameList">
                  {["Ram", "Ragu", "Raja"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Generic Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="GenericName"
                  list="IpGenericNameList"
                  value={AddPriscribtion.GenericName}
                  onChange={handleInputChange}
                />
                <datalist id="IpGenericNameList">
                  {["Paracetomal", "Cerizine"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Medicine Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="MedicineName"
                  list="IpMedicineNameList"
                  value={AddPriscribtion.MedicineName}
                  onChange={handleInputChange}
                />
                <datalist id="IpMedicineNameList">
                  {["111,Dolo", "112,Calpol"].map((row, index) => (
                    <option key={index} value={row}>
                      {row.split(",")[0]}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Dose <span>:</span>
                </label>

                <input
                  name="Dosage"
                  type="text"
                  list="IpDosageList"
                  value={AddPriscribtion.Dosage}
                  onChange={handleInputChange}
                />

                <datalist id="IpDosageList">
                  {["650 Mg", "250 mg"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Route <span>:</span>
                </label>

                <input
                  name="Route"
                  type="text"
                  list="IpRouteList"
                  value={AddPriscribtion.Route}
                  onChange={handleInputChange}
                />

                <datalist id="IpRouteList">
                  {["IV-Direct","IV-Infusion Pumps","Intramuscular","Subcutaneous","Sublingual or Buccal","Rectal","Oral","Inhalational","Nasal"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Site <span>:</span>
                </label>

                <input
                  name="Site"
                  type="text"
                  list="IpSiteList"
                  value={AddPriscribtion.Site}
                  onChange={handleInputChange}
                  disabled={AddPriscribtion.Route !== "IV-Direct" && AddPriscribtion.Route !== "IV-Infusion Pumps"}
                />

                <datalist id="IpSiteList">
                  {["External Jugular","Subclavian vein","Femoral vein","Dorsal Venous Network of Hand","Radial vein","Median Cubital vein","Cephalic vein","Dorsal Venous Network of Leg","Saphaneous vein","Superficial Temporal vein"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>

              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Freq Method <span>:</span>
                </label>

                <select
                  name="FrequencyMethod"
                  value={AddPriscribtion.FrequencyMethod}
                  onChange={handleInputChange}
                >
                  <option value="Regular">Regular</option>
                  <option value="SOS">SOS</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Frequency Type <span>:</span>
                </label>

                <select
                  name="FrequencyType"
                  value={AddPriscribtion.FrequencyType}
                  disabled={AddPriscribtion.FrequencyMethod === "SOS"}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="BeforeFood">BeforeFood</option>
                  <option value="AfterFood">AfterFood</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Frequency <span>:</span>
                </label>

                <select
                  name="Frequency"
                  value={AddPriscribtion.Frequency}
                  disabled={AddPriscribtion.FrequencyMethod === "SOS"}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {selectedTimes
                    .filter(
                      (p) => p.FrequencyType === AddPriscribtion.FrequencyType
                    )
                    .map((fields, index) => (
                      <option value={fields.Frequency} key={index}>
                        {fields.Frequency}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Frequency Time <span>:</span>
                </label>

                <input
                  name="FrequencyTime"
                  type="text"
                  readOnly
                  disabled={AddPriscribtion.FrequencyMethod === "SOS"}
                  value={AddPriscribtion.FrequencyTime}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Duration <span>:</span>
                </label>

                <input
                  name="Duration"
                  type="number"
                  onKeyDown={blockInvalidChar}
                  style={{ width: "20px" }}
                  disabled={AddPriscribtion.FrequencyMethod === "SOS"}
                  value={AddPriscribtion.Duration}
                  onChange={handleInputChange}
                />
                <select
                  name="DurationType"
                  style={{ width: "110px" }}
                  value={AddPriscribtion.DurationType}
                  onChange={handleInputChange}
                  disabled={AddPriscribtion.FrequencyMethod === "SOS"}
                >
                  <option value="days">Hours</option>
                  <option value="days">days</option>
                  <option value="Weeks">weeks</option>
                  <option value="Months">Months</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Adminis Dose <span>:</span>
                </label>

                <input
                  name="AdminisDose"
                  type="number"
                  onKeyDown={blockInvalidChar}
                  value={AddPriscribtion.AdminisDose}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Quantity <span>:</span>
                </label>

                <input
                  name="Quantity"
                  type="number"
                  onKeyDown={blockInvalidChar}
                  value={AddPriscribtion.Quantity}
                  readOnly={AddPriscribtion.FrequencyMethod === "Regular"}
                  onChange={handleInputChange}
                />
              </div>


              <div className="inp_container_all_intakeoutput">
                <label>
                  Location <span>:</span>
                </label>

                <input
                  name="Location"
                  type="text"
                  list="IpLocationList"
                  value={AddPriscribtion.Location}
                  onChange={handleInputChange}
                />

                <datalist id="IpLocationList">
                  {["Left","Right"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>

              <div className="inp_container_all_intakeoutput">
                <label>
                  Infustion Rate (ml/min) <span>:</span>
                </label>

                <input
                  name="InfusionRate"
                  type="text"
                  
                  value={AddPriscribtion.InfusionRate}
                  onChange={handleInputChange}
                />

                {/* <datalist id="IpLocationList">
                  {["Left","Right"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist> */}
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Prescribed Date <span>:</span>
                </label>

                <input
                  name="PrescribedDate"
                  type="date"
                  value={AddPriscribtion.PrescribedDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Date <span>:</span>
                </label>

                <input
                  name="Date"
                  type="date"
                  value={AddPriscribtion.Date}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Time <span>:</span>
                </label>

                <input
                  name="Time"
                  type="time"
                  value={AddPriscribtion.Time}
                  onChange={handleInputChange}
                />
              </div>

              <div className="inp_container_all_intakeoutput">
                <label>
                  Instructions <span>:</span>
                </label>

                <textarea
                  name="Instruction"
                  value={AddPriscribtion.Instruction}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div
              style={{ display: "grid", placeItems: "center", width: "100%" }}
            >
              <button className="btn-add" onClick={handlesubmitdata}>
                Add
              </button>
            </div>
            {summa.length !== 0 && (
          <>
            <div className="IP_grid">
              <ThemeProvider theme={theme}>
                <div className="IP_grid_1">
                  <DataGrid
                    rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                    columns={dynamicColumns} // Use dynamic columns here
                    pageSize={10}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10]}
                    onPageChange={handlePageChange}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    className="Ip_data_grid"
                  />
                  {showdown > 0 && summa.length > 10 && (
                    <div className="IP_grid_foot">
                      <button
                        onClick={() =>
                          setPage((prevPage) => Math.max(prevPage - 1, 0))
                        }
                        disabled={page === 0}
                      >
                        Previous
                      </button>
                      Page {page + 1} of {totalPages}
                      <button
                        onClick={() =>
                          setPage((prevPage) =>
                            Math.min(prevPage + 1, totalPages - 1)
                          )
                        }
                        disabled={page === totalPages - 1}
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
              </ThemeProvider>
              {showdown !== 0 && summa.length !== 0 ? null : (
                <div className="IP_norecords">
                  <span>No Records Found</span>
                </div>
              )}
            </div>
            <div
              style={{ display: "grid", placeItems: "center", width: "100%" }}
            >
              <button className="btn-add" onClick={submitalldata}>
                Submit
              </button>
            </div>
          </>
        )}
          </>
        )}

       

        {type === "Output" && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={summa1?.medicinedata.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className="Ip_data_grid"
                />
                {showdown1 > 0 && summa1?.medicinedata.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages1 - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && summa1?.medicinedata.length !== 0 ? null : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default IcuDocDrugAdminis;
