import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// import "./PatientQueueList.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const OtQuelist = () => {


  const dispatchvalue = useDispatch();
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [otData, setOtData] = useState([]);
  const [otData1, setOtData1] = useState([]);
  const navigate = useNavigate();
  const [ApprovedDate, setApprovedDate] = useState("");
  const [Approvedtime, setApprovedtime] = useState("");
  const [status, setstatus] = useState("Pending");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = otData.length;
  const [openModal, setOpenModal] = useState(false);
  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [dynamicolumn, setdynamicolumn] = useState([])


  React.useEffect(() => {
    fetchOtRequestData();
  }, [status,userRecord]);


  const fetchOtRequestData = () => {
    axios
      .get(`${urllink}OTManagement/get_Ot_Request?status=${status}&location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data;
        console.log("orrequestdata", data);

        setOtData([
          ...data.map((row, index) => ({
            id: index + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.log("error fetching otrequest data:", error);
      });
  };


  const handleEditClick1 = (params) => {
    console.log(params);
    setOpenModal(true);
    setOtData1(params.row);
  };


  const handleSubmission = () => {

    const submissionData = {
      bookingid: otData1.BookingId,
      Approveddate: ApprovedDate,
      Approvedtime: Approvedtime,
    };
    console.log(submissionData);

    axios
      .post(
        `${urllink}OTManagement/update_Ot_Queuelist`,
        submissionData
      )
      .then((response) => {
        console.log(response.data);
        setApprovedDate("");
        setApprovedtime("");
        setOpenModal(false)
        fetchOtRequestData()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleNavigateOTTheater = (params) => {


    dispatchvalue({ type: "submissionData", value: params.row })
    navigate('/Home/OT-Management')
  }

  const coloumnss = [
    { field: "id", headerName: "Ot Request Id", width: 100 },
    { field: "BookingId", headerName: "Booking Id", width: 150 },
    { field: 'PatientName', headerName: 'Patient_Name', width: 150, },
    { field: "Surgery_Name", headerName: "Surgery Name", width: 130 },
    { field: "Surgeon_Name", headerName: "Surgeon Name", width: 130 },
    { field: "Requested_Date", headerName: "Requested Date", width: 140 },
    { field: "Requested_Time", headerName: "Requested Time", width: 140 },
    { field: "Primary_Doctor", headerName: "Primary Doctor", width: 140 },
    { field: "Approved_Date", headerName: "Approved Date", width: 150 },
    { field: "Approve_Time", headerName: "Approve Time", width: 150 },
    { field: "Status", headerName: "Status", width: 100 },
    {
      field: "Approve",
      headerName: "Approve",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn">
            <CheckCircleOutlineIcon onClick={() => handleEditClick1(params)} />
          </Button>
        </>
      ),
    },
  ];


  const coloumnss1 = [
    { field: "id", headerName: "Ot Request Id", width: 100 },
    { field: "BookingId", headerName: "Booking Id", width: 150 },
    { field: 'PatientName', headerName: 'Patient_Name', width: 150, },
    { field: "Surgery_Name", headerName: "Surgery Name", width: 130 },
    { field: "Surgeon_Name", headerName: "Surgeon Name", width: 130 },
    { field: "Requested_Date", headerName: "Requested Date", width: 140 },
    { field: "Requested_Time", headerName: "Requested Time", width: 140 },
    { field: "Primary_Doctor", headerName: "Primary Doctor", width: 140 },
    { field: "Approved_Date", headerName: "Approved Date", width: 150 },
    { field: "Approve_Time", headerName: "Approve Time", width: 150 },
    { field: "Status", headerName: "Status", width: 100 },
    {
      field: "Proceed",
      headerName: "Proceed",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleNavigateOTTheater(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ];


 




  useEffect(() => {
    if (status == 'Pending') {
      setdynamicolumn(coloumnss)
    } else if (status == 'Approved') {
      setdynamicolumn(coloumnss1)
    }  

  }, [status])


  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const totalPages = Math.ceil(otData.length / 10);

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>OT Queue List</h4>
          <div className="doctor_select_1 selt-dctr-nse vcxw2er">
            <label htmlFor="status">Status:</label>
            <select
              name="status"
              id="status"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
            </select>
          </div>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">First Name :</label>
            <input
              type="text"
              id="FirstName"
              value={PatientFirstName}
              onChange={(e) => setPatientFirstName(e.target.value)}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No :</label>
            <input
              type="text"
              id="PhoneNo"
              value={PatientPhoneNo}
              onChange={(e) => setPatientPhoneNo(e.target.value)}
              placeholder="Enter the Phone No"
            />
          </div>
        </div>

        <div className='IP_grid'>
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={otData.slice(page * pageSize, (page + 1) * pageSize)}
                pageSize={10}
                columns={dynamicolumn} // You need to define your dynamic columns here
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="Ip_data_grid"
              />
              {showdown > 0 && otData.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          <br />
          {otData.length === 0 && (
            <div className="norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="bookingid">
                  Booking ID<span>:</span>
                </label>
                <input
                  type="input"
                  name="bookingid"
                  value={otData1.BookingId}
                  readOnly
                />
              </div>
            </div>
            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="ApprovedDate">
                  Approved Date<span>:</span>
                </label>
                <input
                  type="date"
                  name="Approveddate"
                  value={ApprovedDate}
                  onChange={(e) => {
                    setApprovedDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="Approvedtime">
                  Approved Time<span>:</span>
                </label>
                <input
                  type="time"
                  name="Approvedtime"
                  value={Approvedtime}
                  onChange={(e) => {
                    setApprovedtime(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={handleSubmission}
              >
                Submit
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtQuelist;



