import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
// import bgImg2 from "../../assets/bgImg2.jpg";
import OTpic from "../../assets/OTpic.jpg";
import bgImg2 from '../../assets/bgImg2.jpg';

import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
// import html2pdf from "html2pdf.js";
// import OTpic from "../../assets/OTpic.jpg";
import "./IpPreoperativeIns.css"; // Import CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faArrowRight } from "@fortawesome/free-solid-svg-icons";


const PrintContent = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} id="reactprintcontent">
        {props.children}
      </div>
    );
  });



function IpPreoperativeIns() {

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const dispatchvalue = useDispatch();


  const [annotations, setAnnotations] = useState([]);
  const [currentAnnotationIndex, setCurrentAnnotationIndex] = useState(0);
  const imageRef = useRef();
  const imageWidth = 1000; // Set your image width here
  const imageHeight = 600; // Set your image height here

  const handleAnnotation = (event) => {
    const imageRect = imageRef.current.getBoundingClientRect();
    const offsetX = event.clientX - imageRect.left;
    const offsetY = event.clientY - imageRect.top;
    const x = (offsetX / imageRect.width) * 100;
    const y = (offsetY / imageRect.height) * 100;
    setAnnotations([...annotations, { x, y }]);
  };

  const handleResetAnnotation = () => {
    if (annotations.length === 0) return;
    const newAnnotations = [...annotations];
    newAnnotations.pop();
    setAnnotations(newAnnotations);
    setCurrentAnnotationIndex(
      currentAnnotationIndex > 0 ? currentAnnotationIndex - 1 : 0
    );
  };

  const [inputValuesIns, setInputValuesIns] = useState({
    ScalpHair: false,
    Nails: false,
    Givemouth: false,
    Vaginal: false,
    Bowel: false,
    Enema: false,
    textareaValue: "",
    textareaValue1: "",
    textareaValue2: "",
  });
  
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInputValuesIns((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };
  
  const handleTextareaChange = (e) => {
    const { value } = e.target;
    setInputValuesIns((prevValues) => ({
      ...prevValues,
      textareaValue: value,
      
    }));
  };
  

  const [selectedOption, setSelectedOption] = useState({
    selectedOption: "",
    textareaValue: "",
    
  });

  const handleCheckboxChange2 = (option) => {
    setSelectedOption((prevData) => ({
      ...prevData,
      selectedOption: option,
    }));
  };

  const handleTextareaChange2 = (e) => {
    const { value } = e.target;
    setSelectedOption((prevData) => ({
      ...prevData,
      textareaValue: value,
      
    }));
  };

  const [startIV, setStartIV] = useState({
    nilOrallyAfter: {
      value: "",
      period: "am",
    },
    ivWithVenflo: "",
    ivDripAt: {
      value: "",
      period: "am",
    },
    ivSiteList: "Select",
    location: "Select",
  });

  const handleInputChange3 = (e, fieldName, nestedField = null) => {
    const { value } = e.target;
    if (nestedField) {
      setStartIV((prevData) => ({
        ...prevData,
        [fieldName]: {
          ...prevData[fieldName],
          [nestedField]: value,
        },
      }));
    } else {
      setStartIV((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  // Rest of your state and logic...

  const componentRef = useRef();

  const handlePrint2 = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      // Additional action after printing, if needed
    },
  });

  const Submitalldata = () => {
    setIsPrintButtonVisible(false);
    setTimeout(() => {
      handlePrint2();
      setIsPrintButtonVisible(true); // Resetting print button visibility
    }, 500); // Adjust delay as needed
  };

  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);
  const [location, setlocation] = useState("");

  useEffect(() => {
    const location = userRecord?.location;

    axios
      .get("your_api_endpoint")
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.Clinic_Name);
          setClinicLogo(`data:image/png;base64,${data.Clinic_Logo}`);
          setlocation(data.location);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);
  //

  const [workbenchformData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
  });

  console.log(workbenchformData);
  dispatchvalue({
    type: "workbenchformData",
    value: workbenchformData,
  });

  return (
    <>
      {isPrintButtonVisible ? (
        
        <div className="Supplier_Master_Container">
          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "10px",
            }}
          >
            Preoperative Intructions 
          </h4>

          <div
            className="wdqqwqxxz"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label>
              <p style={{ marginRight: "10px" }}>1.</p> (a) Prepare the
              following area (mark the area) :
            </label>
            <div className="image-container">
              <img
                src={OTpic}
                alt="PHOTO"
                className="image_ANNNNT"
                ref={imageRef}
              />
              <div className="annotation-overlay" onClick={handleAnnotation}>
                {/* Render annotations as div elements */}
                {annotations.map((annotation, index) => (
                  <div
                    key={index}
                    className="annotation"
                    style={{
                      left: `${annotation.x}%`,
                      top: `${annotation.y}%`,
                    }}
                  />
                ))}
              </div>
              {/* Buttons for resetting annotation and navigating to next annotation */}
              <div className="annotation-buttons">
                <button onClick={handleResetAnnotation}>
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            </div>
          </div>

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (b)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "280px",
                }}
              >
                Scalp Hair to he covered braided / clipped<span>:</span>
              </span>
            </label>

            <div className="dccffcfc5">
              <label htmlFor="ScalpHair">
                <input
                  type="checkbox"
                  id="ScalpHair"
                  name="ScalpHair"
                  checked={inputValuesIns.ScalpHair}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>
          <br />

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (c)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "280px",
                }}
              >
                {" "}
                Nails to be cleaned,clipped<span>:</span>
              </span>
            </label>

            <div className="dccffcfc5">
              <label htmlFor="Nails">
                <input
                  type="checkbox"
                  id="Nails"
                  name="Nails"
                  checked={inputValuesIns.Nails}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>2.</p>Give mouth wash
              </label>
              <label htmlFor="Givemouth" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Givemouth"
                  name="Givemouth"
                  checked={inputValuesIns.Givemouth}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Vaginal douche</label>
              <label htmlFor="Vaginal" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Vaginal"
                  name="Vaginal"
                  checked={inputValuesIns.Vaginal}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Bowel wash</label>
              <label htmlFor="Bowel" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Bowel"
                  name="Bowel"
                  checked={inputValuesIns.Bowel}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Enema </label>
              <label htmlFor="Enema" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Enema"
                  name="Enema"
                  checked={inputValuesIns.Enema}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue}
              onChange={handleTextareaChange}
            ></textarea>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>3.</p> Pass urinary catheter
              </label>
              <label htmlFor="urinaryCatheter" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="urinaryCatheter"
                  name="urinaryCatheter"
                  checked={selectedOption.selectedOption === "urinaryCatheter"}
                  onChange={() => handleCheckboxChange2("urinaryCatheter")}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>nasogastric tube</label>
              <label htmlFor="nasogastricTube" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="nasogastricTube"
                  name="nasogastricTube"
                  checked={selectedOption.selectedOption === "nasogastricTube"}
                  onChange={() => handleCheckboxChange2("nasogastricTube")}
                />
              </label>
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={selectedOption.textareaValue}
              onChange={handleTextareaChange2}
            ></textarea>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p
                  style={{
                    marginRight: "10px",
                    width: "10px !important",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  4.
                </p>
                <span
                  className="ddddd445"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    width: "140px",
                  }}
                >
                  Nil orally after <span>:</span>
                </span>
              </label>
              <label htmlFor="" className="jyutr">
                <input
                  type="number"
                  value={startIV.nilOrallyAfter.value}
                  onChange={(e) =>
                    handleInputChange3(e, "nilOrallyAfter", "value")
                  }
                />
              </label>
              <select
                value={startIV.nilOrallyAfter.period}
                onChange={(e) =>
                  handleInputChange3(e, "nilOrallyAfter", "period")
                }
              >
                <option>am</option>
                <option>pm</option>
              </select>
            </div>
          </div>
          <br />

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                5.
              </p>{" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "130px",
                }}
              >
                Start IV with Venflo<span>:</span>
              </span>
            </label>
          </div>

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (a)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                Drip at<span>:</span>
              </span>
            </label>
            <label htmlFor="" className="jyutr">
              <input
                type="number"
                value={startIV.ivDripAt.value}
                onChange={(e) => handleInputChange3(e, "ivDripAt", "value")}
              />
            </label>
            <select
              value={startIV.ivDripAt.period}
              onChange={(e) => handleInputChange3(e, "ivDripAt", "period")}
            >
              <option>am</option>
              <option>pm</option>
            </select>
          </div>

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (b)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                IV Site List<span>:</span>
              </span>
            </label>
            <select
              className="jjklkj1"
              value={startIV.ivSiteList}
              onChange={(e) => handleInputChange3(e, "ivSiteList")}
            >
              <option>Select</option>
              <option>External Jugular</option>
              <option>Subclavian vein</option>
              <option>Femoral vein</option>
              <option>Dorsal Venous Network of Hand</option>
              <option>Radial vein</option>
              <option>Median Cubital vein</option>
              <option>Cephalic vein</option>
              <option>Dorsal Venous Network of Leg</option>
              <option>Saphaneous vein</option>
              <option>Superficial Temporal vein</option>
            </select>
          </div>

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (c)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                Location<span>:</span>
              </span>
            </label>
            <select
              className="jjklkj1"
              value={startIV.location}
              onChange={(e) => handleInputChange3(e, "location")}
            >
              <option>Select</option>
              <option>Left</option>
              <option>Right</option>
            </select>
          </div>

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>6.</p> Preanaesthetic medication / Anticoagulants / Other Medicines
              </label>
             
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue1}
              onChange={(e)=>{setInputValuesIns({
                textareaValue1:e.target.value
              })}}
            ></textarea>
          </div>

         

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>7.</p> Send all Records & Reports with the patient to the Operation Room
              </label>
             
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue2}
              onChange={(e)=>{setInputValuesIns({
                textareaValue2:e.target.value
              })}}
            ></textarea>
          </div>

          {isPrintButtonVisible && (
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={Submitalldata}>
                Print
              </button>
            </div>
          )}
        </div>
        
      ) : (
        <>
          <PrintContent
            ref={componentRef}
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
            }}
          

            //   willReadFrequently={true}
          >
          <div className="Print_ot_all_div" id="reactprintcontent">

<div className="new-patient-registration-form ">
              <div>
                <div className="paymt-fr-mnth-slp">
                  <div className="logo-pay-slp">
                    <img src={clinicLogo} alt="" />
                  </div>
                  <div>
                    <h2>
                      {clinicName} ({location})
                    </h2>
                  </div>
                </div>

                <h4
                  style={{
                    color: "var(--labelcolor)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "start",
                    padding: "10px",
                  }}
                >
                  Nurse
                </h4>
              </div>

              <div className="dctr_info_up_head Print_ot_all_div_second2">
                <div className="RegisFormcon ">
                  <div className="dctr_info_up_head22">
                    {workbenchformData.PatientPhoto ? (
                      <img
                        src={workbenchformData.PatientPhoto}
                        alt="Patient Photo"
                      />
                    ) : (
                      <img src={bgImg2} alt="Default Patient Photo" />
                    )}
                    <label>Profile</label>
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Patient Name <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.firstName +
                        " " +
                        workbenchformData.lastName}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Patient ID <span>:</span>
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.PatientID}{" "}
                    </span>
                  </div>

                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Age <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.Age}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Gender <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.Gender}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Primary Doctor <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.DoctorName}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Location <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {workbenchformData.Location}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="Supplier_Master_Container">
            <div className="Print_ot_all_div_rfve">
          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "10px",
            }}
          >
            Preoperative Intructions 
          </h4>

          <div
            className="wdqqwqxxz"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label>
              <p style={{ marginRight: "10px" }}>1.</p> (a) Prepare the
              following area (mark the area) :
            </label>
            <div className="image-container">
              <img
                src={OTpic}
                alt="PHOTO"
                className="image_ANNNNT"
                ref={imageRef}
              />
              <div className="annotation-overlay" onClick={handleAnnotation}>
                {/* Render annotations as div elements */}
                {annotations.map((annotation, index) => (
                  <div
                    key={index}
                    className="annotation"
                    style={{
                      left: `${annotation.x}%`,
                      top: `${annotation.y}%`,
                    }}
                  />
                ))}
              </div>
              {/* Buttons for resetting annotation and navigating to next annotation */}
              <div className="annotation-buttons">
                <button onClick={handleResetAnnotation}>
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            </div>
          </div>

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (b)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "280px",
                }}
              >
                Scalp Hair to he covered braided / clipped<span>:</span>
              </span>
            </label>

            <div className="dccffcfc5">
              <label htmlFor="ScalpHair">
                <input
                  type="checkbox"
                  id="ScalpHair"
                  name="ScalpHair"
                  checked={inputValuesIns.ScalpHair}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>
          <br />

          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (c)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "280px",
                }}
              >
                {" "}
                Nails to be cleaned,clipped<span>:</span>
              </span>
            </label>

            <div className="dccffcfc5">
              <label htmlFor="Nails">
                <input
                  type="checkbox"
                  id="Nails"
                  name="Nails"
                  checked={inputValuesIns.Nails}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>2.</p>Give mouth wash
              </label>
              <label htmlFor="Givemouth" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Givemouth"
                  name="Givemouth"
                  checked={inputValuesIns.Givemouth}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Vaginal douche</label>
              <label htmlFor="Vaginal" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Vaginal"
                  name="Vaginal"
                  checked={inputValuesIns.Vaginal}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Bowel wash</label>
              <label htmlFor="Bowel" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Bowel"
                  name="Bowel"
                  checked={inputValuesIns.Bowel}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>Enema </label>
              <label htmlFor="Enema" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="Enema"
                  name="Enema"
                  checked={inputValuesIns.Enema}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          </div>
          <br />
          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue}
              onChange={handleTextareaChange}
            ></textarea>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>3.</p> Pass urinary catheter
              </label>
              <label htmlFor="urinaryCatheter" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="urinaryCatheter"
                  name="urinaryCatheter"
                  checked={selectedOption.selectedOption === "urinaryCatheter"}
                  onChange={() => handleCheckboxChange2("urinaryCatheter")}
                />
              </label>
            </div>

            <div className="wdqqwqxxz">
              <label>nasogastric tube</label>
              <label htmlFor="nasogastricTube" className="qwdw33wew2sd">
                <input
                  type="checkbox"
                  id="nasogastricTube"
                  name="nasogastricTube"
                  checked={selectedOption.selectedOption === "nasogastricTube"}
                  onChange={() => handleCheckboxChange2("nasogastricTube")}
                />
              </label>
            </div>
          </div>
          <br />
          <div className="wdqqwqxxz">
            <textarea
              value={selectedOption.textareaValue}
              onChange={handleTextareaChange2}
            ></textarea>
          </div>

          <br />

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p
                  style={{
                    marginRight: "10px",
                    width: "10px !important",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  4.
                </p>
                <span
                  className="ddddd445"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    width: "140px",
                  }}
                >
                  Nil orally after <span>:</span>
                </span>
              </label>
              <label htmlFor="" className="jyutr">
                <input
                  type="number"
                  value={startIV.nilOrallyAfter.value}
                  onChange={(e) =>
                    handleInputChange3(e, "nilOrallyAfter", "value")
                  }
                />
              </label>
              <select
                value={startIV.nilOrallyAfter.period}
                onChange={(e) =>
                  handleInputChange3(e, "nilOrallyAfter", "period")
                }
              >
                <option>am</option>
                <option>pm</option>
              </select>
            </div>
          </div>
          <br />

          <div className="cccccccbbn">
          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                5.
              </p>{" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "130px",
                }}
              >
                Start IV with Venflo<span>:</span>
              </span>
            </label>
          </div>
          </div>
          <br />

          <div className="cccccccbbn">
          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (a)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                Drip at<span>:</span>
              </span>
            </label>
            <label htmlFor="" className="jyutr">
              <input
                type="number"
                value={startIV.ivDripAt.value}
                onChange={(e) => handleInputChange3(e, "ivDripAt", "value")}
              />
            </label>
            <select
              value={startIV.ivDripAt.period}
              onChange={(e) => handleInputChange3(e, "ivDripAt", "period")}
            >
              <option>am</option>
              <option>pm</option>
            </select>
          </div>
          </div>
          <br />

          <div className="cccccccbbn">
          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (b)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                IV Site List<span>:</span>
              </span>
            </label>
            <select
              className="jjklkj1"
              value={startIV.ivSiteList}
              onChange={(e) => handleInputChange3(e, "ivSiteList")}
            >
              <option>Select</option>
              <option>External Jugular</option>
              <option>Subclavian vein</option>
              <option>Femoral vein</option>
              <option>Dorsal Venous Network of Hand</option>
              <option>Radial vein</option>
              <option>Median Cubital vein</option>
              <option>Cephalic vein</option>
              <option>Dorsal Venous Network of Leg</option>
              <option>Saphaneous vein</option>
              <option>Superficial Temporal vein</option>
            </select>
          </div>
          </div>
          <br />

          <div className="cccccccbbn">
          <div className="wdqqwqxxz">
            <label>
              <p
                style={{
                  marginRight: "10px",
                  width: "10px !important",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                (c)
              </p>
              <span
                className="ddddd445"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "140px",
                }}
              >
                Location<span>:</span>
              </span>
            </label>
            <select
              className="jjklkj1"
              value={startIV.location}
              onChange={(e) => handleInputChange3(e, "location")}
            >
              <option>Select</option>
              <option>Left</option>
              <option>Right</option>
            </select>
          </div>
          </div>

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>6.</p> Preanaesthetic medication / Anticoagulants / Other Medicines
              </label>
             
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue1}
              onChange={(e)=>{setInputValuesIns({
                textareaValue1:e.target.value
              })}}
            ></textarea>
          </div>

         

          <div className="cccccccbbn">
            <div className="wdqqwqxxz">
              <label>
                <p style={{ marginRight: "10px" }}>7.</p> Send all Records & Reports with the patient to the Operation Room
              </label>
             
            </div>
          </div>

          <div className="wdqqwqxxz">
            <textarea
              value={inputValuesIns.textareaValue2}
              onChange={(e)=>{setInputValuesIns({
                textareaValue2:e.target.value
              })}}
            ></textarea>
          </div>


         
        </div>
           </div> </div>
          </PrintContent>

          {/* Display PDF if generated */}
        </>
      )}
    </>
  );
}

export default IpPreoperativeIns;
