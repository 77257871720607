import React, { useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import {toast} from "react-toastify";
import { useSelector } from "react-redux";

function TreatmentMaster() {

  const userRecord = useSelector((state) => state.userRecord?.UserData);


  console.log('userRecord',userRecord)

  const [TreatmentName, setTreatmentName] = useState("");

  const [TreatmentCode, setTreatmentCode] = useState("");

  const [Treatmentlist, setTreatmentlist] = React.useState([]);



  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Treatmentlist.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  

  React.useEffect(() => {
    fetchTreatmentlist();
  }, []);


  const fetchTreatmentlist = () => {
    axios
      .get("https://vesoftometic.co.in/usercontrol/get_SpecialitiesWithTreatment_data")
      .then((response) => {
        const data = response.data;
        console.log(data);
        setTreatmentlist(data);
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  };
 

 

  const handleTreatmentSub = (e) => {
    e.preventDefault();

    if (TreatmentName !== "") {

      let params={
        TreatmentName:TreatmentName,
        TreatmentCode:TreatmentCode,
        User:userRecord.username,
        Location:userRecord.location,
      }

      axios
        .post("https://vesoftometic.co.in/usercontrol/insertTreatment",params)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }
          setTreatmentName("");
          setTreatmentCode('');
          fetchTreatmentlist();
        })
        .catch((error) => {
          console.error("Error:", error);
          errmsg("Error occurred while inserting role.");
        });
    } else {
      userwarn("Please enter the role name and select the Specialities");
    }
  };

  
  const handleSpecialitiesEditClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const role_id = data.Treatment_id;
      Update_Specialitiesstatus_fun(role_id, status);
    } else {
      const status = "Inactive";
      const role_id = data.Treatment_id;
      Update_Specialitiesstatus_fun(role_id, status);
    }
  };

  const Update_Specialitiesstatus_fun = (role_id, status) => {
    console.log("Updating status:", status);
    console.log("Role ID:", role_id);

    axios
      .post("https://vesoftometic.co.in/usercontrol/update_status_for_SpecialitiesWithTreatment", {status,role_id})
      .then((res) => {
        console.log(res.data);
        fetchTreatmentlist();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };



  

 

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  

  // -----------------------------------
 
  
  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Treatment Master</h4>
        </div>

        <div className="ShiftClosing_Container">
            <h2 style={{ textAlign: "center" }}>Treatments</h2>

            <div className="con_1 ">
            <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Treatment Code<span>:</span>
                </label>
                <input
                  type="text"
                  name="TreatmentCode"
                  value={TreatmentCode}
                  onChange={(e) => setTreatmentCode(e.target.value.toUpperCase())}
                  placeholder="Enter Treatment Code"
                  autoComplete="off"
                />
              </div>

              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Treatment Name<span>:</span>
                </label>
                <input
                  type="text"
                  name="TreatmentName"
                  value={TreatmentName}
                  onChange={(e) => setTreatmentName(e.target.value.toUpperCase())}
                  placeholder="Enter Treatment Name"
                  autoComplete="off"
                />
              </div>
              <button className="btn_1" onClick={handleTreatmentSub}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Treatment List</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Treatment Code</th>
                      <th>Treatment Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {currentItems.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Treatment_id}</td>
                        <td>{row.TreatmentCode}</td>
                        <td>{row.Treatment_name}</td>
                        <td>
                          <button
                            onClick={() => handleSpecialitiesEditClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <br/>
              <div>
              <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
              <span>Page {currentPage}</span>
              <button onClick={nextPage} disabled={indexOfLastItem >= Treatmentlist.length}>Next</button>
            </div>
            </div>           
          
        </div>

      </div>
    </>
  );
}

export default TreatmentMaster;
