import React, { useState } from 'react'

const IcuLabTest = () => {
    const [labtestdata,setlabtestdata]=useState([])

    const handleCheckboxChange = (testName) => {
        if (labtestdata.includes(testName)) {
          // If it is, remove it
          setlabtestdata((prevList) => prevList.filter((name) => name !== testName));
        } else {
          // If it's not, add it
          setlabtestdata((prevList) => [...prevList, testName]);
        }
      };
      const handlesavelab=()=>{

      }
      const handleShowPreviewlab=()=>{
        
      }
  return (

    <>
      <div className="Supplier_Master_Container">
      <div className="Add_items_Purchase_Master">
          <span>Add Test Name</span>
        </div>
        <div className="Check_box_testname">
          <div className="Check_box_testname_sub">
            <h3>HEAMATOLOGY</h3>
              <div className="Check_box_testname_sub_con">
               <label>Complete Blood Count <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Complete Blood Count')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Bleeding Time <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Bleeding Time')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Clotting Time <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Clotting Time')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Prothrombin Time <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Prothrombin Time')}/>
              </div>
          </div>
          <div className="Check_box_testname_sub">
            <h3>SEROLOGY</h3>
            <div className="Check_box_testname_sub_con">
               <label>HBSAg<span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('HBSAg')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>HIV <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('HIV')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>HCV <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('HCV')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>HbA1c <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('HbA1c')}/>
              </div>
          </div>
          <div className="Check_box_testname_sub">
            <h3>BIO CHEMISTRY</h3>
            <div className="Check_box_testname_sub_con">
               <label>Blood Glucose</label>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>FBS<span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('FBS')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>PPBS <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('PPBS')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>RBS <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('RBS')}/>
              </div>
          </div>
          <div className="Check_box_testname_sub">
            <h3>PRIMARY INVESTIGATION</h3>
            <div className="Check_box_testname_sub_con">
               <label>Thyroid Function Test</label>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>T3<span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('T3')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>T4 <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('T4')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>TSH <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('TSH')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>HB <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('HB')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Vitamin D <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Vitamin D')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Vitamin B12 <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Vitamin B12')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Ferritin <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Ferritin')}/>
              </div>
          </div>
          <div className="Check_box_testname_sub">
            <h3>OTHERS</h3>
            <div className="Check_box_testname_sub_con">
               <label>ECG<span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('ECG')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>BP<span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('BP')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>USG of Abdomen & Pelvis <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('USG of Abdomen & Pelvis')}/>
              </div>
              <div className="Check_box_testname_sub_con">
               <label>Prostate Specific Antigen Test <span>:</span></label>
                <input type="checkbox"  onChange={()=>handleCheckboxChange('Prostate Specific Antigen Test')}/>
              </div>
          </div>
        </div>
        {
            labtestdata.length!==0&&
            <>
            <div className="Selected-table-container RateCard_table">
              <br />

              <br />
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">S No</th>
                    <th id="vital_Twidth">Name</th>
                  </tr>
                </thead>
                <tbody>
                  {labtestdata &&
                    labtestdata.map((ele, ind) => (
                      <tr key={ind}>
                        <td>{ind+1}</td>
                        <td>{ele}</td>
                        
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="Savebtn_class">
              <button className="btncon_add prs-ad-sub-btn" onClick={handlesavelab}>Save</button>
              <button className="btncon_add prs-ad-sub-btn" onClick={handleShowPreviewlab}>Preview</button>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default IcuLabTest;