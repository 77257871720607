import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jsPDF from "jspdf";
import logo from "../../assets/logo.png";
import { useReactToPrint } from "react-to-print";
import { format } from 'date-fns';

const IpBilling = () => {
    const IpBillingData = useSelector(state => state.InPatients?.IpBillingData)
    const navigate = useNavigate()
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const [Data, setData] = useState(null);
    const [IsPrintButtonVisible, setIsPrintButtonVisible] = useState(true)
    const [ClinicDetials, setClinicDetials] = useState({
        ClinicLogo: null,
        ClinicName: "",
        ClinicGST: "",
        ClinicAddress: "",
        ClinicCity: "",
        ClinicState: "",
        ClinicCode: "",
        ClinicMobileNo: "",
        ClinicLandLineNo: "",
        ClinicMailID: "",
        InvoiceNo: "",
    });
    const [BillFormData, setBillFormData] = useState({
        PatientID: "",
        PatientName: "",
        PhoneNumber: "",
        RatecardType: "",
        ClientName: "",
        InsuranceName: "",
        Age: "",
        Gender: "",
        City: "",
        State: "",
        Street: "",
        Pincode: "",
    });
    const Billing_date = format(new Date(), 'yyyy-MM-dd')

    useEffect(() => {
        if (Object.keys(IpBillingData).length === 0) {
            navigate('/Home/IP_Billing_List')
        } else {
            setClinicDetials({
                ClinicLogo: logo,
                ClinicName: "Blossom",
                ClinicGST: "GSTIN6382683682",
                ClinicAddress: "Vadapalani",
                ClinicCity: "Chennai",
                ClinicState: "TamilNadu",
                ClinicCode: "6000018",
                ClinicMobileNo: "6356484934",
                ClinicLandLineNo: "004-64670-36737",
                ClinicMailID: "blossom@gmail.com",
                InvoiceNo: IpBillingData.MRNNumber,
            })
            setBillFormData({
                PatientID: IpBillingData.id,
                PatientName: IpBillingData.PatientName,
                PhoneNumber: IpBillingData.PhoneNumber,
                Age: IpBillingData.Age,
                Gender: IpBillingData.Gender,
                City: IpBillingData.City,
                State: IpBillingData.State,
                Street: IpBillingData.Street,
                Pincode: IpBillingData.Pincode,

            })
            const billdata = [{
                "id":'BLO24000001',
                'BILL SUMMARY': [
                    { PrimaryCode: '001', Particulars: 'Rooms & Nursing Charges', Amount: '22670.00' },
                    { PrimaryCode: '002', Particulars: 'ICU Charges', Amount: '2670.00' },
                    { PrimaryCode: '003', Particulars: 'OT Charges', Amount: '3070.00' },
                    { PrimaryCode: '004', Particulars: 'Medicine & Consumables', Amount: '5820.50' },
                    { PrimaryCode: '005', Particulars: 'Proffessional Fee', Amount: '1250.00' },
                    { PrimaryCode: '006', Particulars: 'Investigation Charges', Amount: '2520.0' },
                    { PrimaryCode: '007', Particulars: 'Ambulance Charges', Amount: '1670.00' },
                    { PrimaryCode: '008', Particulars: 'Miscellaneous Charges', Amount: '670.00' },
                ],
                "DETAILED BREAKUP": [
                    { Code: '001', DateTime:'07-05-2024 10:30 AM',Particulars: 'Rooms & Nursing Charges',SubParticulars: 'Bed Charges - GENERAL', Rate:'1000',Unit:'14',Amount: '14000.00' },
                    { Code: '002', DateTime:'07-05-2024 10:30 AM',Particulars: 'Rooms & Nursing Charges',SubParticulars: 'Bed Charges - AC', Rate:'2000',Unit:'3',Amount: '6000.00' },
                ],

            }]
            const filldata =billdata?.find((f)=>f.id===IpBillingData.id)
            if(filldata){
                const {id,...rest}= filldata
                setData(rest ||null)
            }else{
                setData(null)
            }
            
            
        }

    }, [IpBillingData]);

const calculattotalbill=(params)=>{
    const totalPaidAmount = params.reduce(
        (total, ele) => +total + +ele.Amount,
        0
      );
    let amount = parseFloat(totalPaidAmount).toFixed(2)
      return amount;
}
const calculatediscountamount=(params)=>{
    const totalPaidAmount = params.reduce(
        (total, ele) => +total + +ele.Amount,
        0
      );
    let amount = parseFloat((parseFloat(totalPaidAmount) * +IpBillingData?.Discount || 0 )/100).toFixed(2)
    return amount;
}
    const handlePrint = useReactToPrint({
        content: () => document.getElementById("reactprintcontent"),
        onBeforePrint: () => {
            setIsPrintButtonVisible(false);
            console.log("Before");
        },
        onAfterPrint: async () => {
            console.log("After");
            const printdata = document.getElementById("reactprintcontent");
            console.log("printdata", printdata);

            try {
                if (printdata) {
                    const contentWidth = printdata.offsetWidth;
                    const padding = 20; // Adjust the padding as needed
                    const pdfWidth = contentWidth + 2 * padding; // Add padding to width
                    const pdfHeight = contentWidth * 1.5; // Add padding to height
                    const pdf = new jsPDF({
                        unit: "px",
                        format: [pdfWidth, pdfHeight],
                    });
                    pdf.html(printdata, {
                        x: padding, // Set x-coordinate for content
                        y: padding, // Set y-coordinate for content
                        callback: () => {
                            const generatedPdfBlob = pdf.output("blob");
                            console.log("generatedPdfBlob", generatedPdfBlob);
                            const newFormData = new FormData();
                            // Append other fields to the FormData object
                            newFormData.append(
                                "DefuldInvoicenumber",
                                ClinicDetials.InvoiceNo
                            );
                            newFormData.append("PatientId", BillFormData.PatientID);
                            newFormData.append("PatientName", BillFormData.PatientName);
                            newFormData.append("Billing_date", Billing_date);
                            newFormData.append("user_name", userRecord?.username);
                            newFormData.append("location", userRecord?.location);
                            newFormData.append("BillType", "General");
                            // Append the PDF blob
                            newFormData.append("pdfData", generatedPdfBlob);

                        },
                    });
                } else {
                    throw new Error("Unable to get the target element");
                }
            } catch (error) {
                console.error("Error generating PDF:", error);
            }
        },
    });
    const formatLabel = (label) => {
        // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
                .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
        } else {
            return label;
        }
    };
    return (
        <>
            <div className="billing-invoice" id="reactprintcontent" style={{ border: '0px' }}>
                <div className="New_billlling_invoice_head">
                    <div className="new_billing_logo_con">
                        <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                    </div>
                    <div className="new_billing_address_1 ">
                        <span>{ClinicDetials.ClinicName}</span>
                        <div>
                            <span>{ClinicDetials.ClinicAddress},</span>
                            <span>
                                {ClinicDetials.ClinicCity +
                                    "," +
                                    ClinicDetials.ClinicState +
                                    "," +
                                    ClinicDetials.ClinicCode}
                            </span>
                        </div>
                        <div>
                            <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                            <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                            <span>{ClinicDetials.ClinicMailID}</span>
                        </div>
                    </div>
                </div>
                <div
                    className="Register_btn_con"
                    style={{ color: "var(--ProjectColor)", fontWeight: 600 }}
                >
                    Billing Invoice
                </div>
                <div className="new_billing_address">
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>
                                Patient Name <span>:</span>
                            </label>
                            <span>{BillFormData.PatientName}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Patient ID <span>:</span>
                            </label>
                            <span>{BillFormData.PatientID}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Age <span>:</span>
                            </label>
                            <span>{BillFormData.Age}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Gender <span>:</span>
                            </label>
                            <span>{BillFormData.Gender}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Address <span>:</span>
                            </label>
                            <span>{BillFormData.City}</span>
                        </div>
                    </div>
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>
                                Invoice No <span>:</span>
                            </label>
                            <span>{ClinicDetials.InvoiceNo}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                GSTIN No <span>:</span>
                            </label>
                            <span>{ClinicDetials.ClinicGST}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Physician Name <span>:</span>
                            </label>
                            <span>{IpBillingData?.PrimaryDoctor}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                Date <span>:</span>
                            </label>
                            <span>{Billing_date}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>
                                {" "}
                                Patient Mobile No <span>:</span>
                            </label>
                            <span>{BillFormData.PhoneNumber}</span>
                        </div>
                    </div>
                </div>

                {Data&&
                    Object.keys(Data).map((rowdata, indxx) => (
                        <React.Fragment key={indxx}>
                            <br />
                            <div style={{ textAlign: 'center', color: 'grey', fontWeight: 600 }}>{rowdata}</div>
                            <div className="new_billing_invoice_detials">
                                <table>
                                    <thead>
                                        <tr style={{ backgroundColor: 'var(--ProjectColor)' }}>
                                            {
                                                Object.keys(Data[rowdata][0]).map((tablehead, tindx) => (
                                                    <td key={tindx}>{formatLabel(tablehead)}</td>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            Data[rowdata].map((tablehead, tindx) => (
                                                <tr key={tindx}>
                                                    {console.log(tablehead, '----')}
                                                    {Object.values(tablehead).map((cell, cellIndex) => (
                                                        <td key={cellIndex}>{cell}</td>
                                                    ))}
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                                {rowdata==='BILL SUMMARY'&&
                                    <div className=" total_con_bil_summacreatepanna">
                                    <div className="total_con_bill">
                                        <div  className='summacreatepanna'>
                                            <label>
                                                Total Bill Amount <span>:</span>
                                            </label>
                                            <span style={{ color: "grey" }}>
                                                {calculattotalbill(Data[rowdata])}
                                            </span>
                                        </div>
                                        <div className='summacreatepanna'>
                                            <label>
                                                Discount  Amount <span>:</span>
                                            </label>
                                            <span style={{ color: "grey" }}>
                                                {calculatediscountamount(Data[rowdata])}
                                            </span>
                                        </div>
                                        <div className='summacreatepanna'>
                                            <label>
                                                Amount Payable <span>:</span>
                                            </label>
                                            <span style={{ color: "grey" }}>
                                                {(calculattotalbill(Data[rowdata]) - calculatediscountamount(Data[rowdata])).toFixed(2)}
                                            </span>
                                        </div>
                                        <div className='summacreatepanna'>
                                            <label>
                                                Amount Paid <span>:</span>
                                            </label>
                                            <span style={{ color: "grey" }}>
                                                {(calculattotalbill(Data[rowdata]) - calculatediscountamount(Data[rowdata])).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </React.Fragment>
                    ))
                }
                {/* <div style={{ textAlign: 'center', color: 'grey', fontWeight: 600 }}>Bill Summary</div>
                <div className="new_billing_invoice_detials">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Service Type</th>
                                <th>Service Name</th>
                                <th>Unit</th>
                                <th>Amount</th>
                                <th>Discount</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>


                            <tr >
                                <td>{index + 1}</td>
                                <td>{row.ServiceType}</td>
                                <td>{row.ServiceName}</td>
                                <td>{row.Session}</td>
                                <td>{row.Charge}</td>
                                <td>{row.Discount}</td>
                                <td>{row.Total}</td>
                            </tr>


                        </tbody>
                    </table>
                </div> */}

                <div
                    className="new_billing_invoice_detials "
                    style={{ paddingBottom: "10px", height: "auto" }}
                >
                    {/* <div className="invoice_detials_total_1 neww_invoicedetials">
                        <div className="total_con_bill">
                            <div className="bill_body">
                                <label>
                                    Items <span>:</span>
                                </label>
                                <span>{initialState.totalItems}</span>
                            </div>
                            <div className="bill_body">
                                <label>
                                    Unit <span>:</span>
                                </label>
                                <span>{initialState.totalUnits}</span>
                            </div>
                            <div className="bill_body">
                                <label>
                                    Amount <span>:</span>
                                </label>
                                <span>{initialState.totalAmount}</span>
                            </div>
                        </div>
                        <div className="total_con_bill">
                            <div className="bill_body">
                                <label>
                                    Billed By <span>:</span>
                                </label>
                                <span>{userRecord.username}</span>
                            </div>
                            <div className="bill_body">
                                <label>
                                    SGST <span>:</span>
                                </label>
                                <span>{initialState.totalGstamount / 2} </span>
                            </div>
                            <div className="bill_body">
                                <label>
                                    CGST <span>:</span>
                                </label>
                                <span>{initialState.totalGstamount / 2} </span>
                            </div>

                            {billAmount.map((row, index) => (
                                <div key={index} className="bill_body">
                                  
                                    <label>
                                        {row.Billpay_method}
                                        <span>:</span>
                                    </label>
                                    <span>{row.paidamount}</span>
                                </div>
                            ))}

                            <div className="bill_body">
                                <label>
                                    Net Amount <span>:</span>
                                </label>
                                <span>{initialState.totalNetAmount}</span>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="invoice_detials_total_1 neww_invoicedetials">
                        <div className="total_con_bill">
                            <div
                                className="bill_body"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "20px",
                                }}
                            >
                                <label>
                                    {" "}
                                    Amount In Words<span>:</span>
                                </label>
                                <span style={{ color: "grey" }}>
                                    {numberToWords(+initialState.totalNetAmount)}{" "}
                                </span>
                            </div>
                        </div>
                    </div> */}

                    <div className="signature-section909">
                        <p className="disclaimer23">
                            This page is created automatically without a signature.
                        </p>
                    </div>
                </div>
            </div>
            <div className="Billing_btn">
                <button className="btn_1 " onClick={handlePrint}>
                  Print
                </button>
              </div>
        </>
    )
}

export default IpBilling;
