import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMtpClaimNonClaim() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [checkboxValues, setCheckboxValues] = useState({
    PatientGender: '',
    RelativeGender: '',
  })


  const [ConcernformData, setFormData] = useState({
    
    PatientName: "",
    PatientRelatives1: "",
    PatientRelatives2: "",
    Relationship: "",
    ClaimOrNot: "",
    DetailsOfClaim: "",
    CompanyName: "",
    CompanyType: "",
    SignLhti: "",
    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);




  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
  
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked ? value : ''
      }));
    } else {
      setFormData({
        ...ConcernformData,
        [name]: value
      });
    }
  };

  


  // const handleChange = (e) => {
  //   const { name,type,checked, value } = e.target;
  //   setFormData({
  //     ...ConcernformData,
  //     [name]: value,
  //   });

  //   if(type === 'checkbox' ) 
  //     {
  //       setFormData((prev) => ({
  //         ...prev,
  //         [name]:checked? 'Yes': ''
  //       }))
  //     }

  //     setCheckboxValues(prevState => ({
  //       ...prevState,
  //       [name]: value
  //     }));


  //     // if (type === 'checkbox') {
  //     //   setFormData((prev) => ({
  //     //     ...prev,
  //     //     [name]: checked ? value : ''
  //     //   }));
  //     // } else {
  //     //   setFormData({
  //     //     ...ConcernformData,
  //     //     [name]: value
  //     //   });
  //     // }
      


  // };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('PatientName', ConcernformData.PatientName);
              formData.append('PatientRelatives1', ConcernformData.PatientRelatives1);
              formData.append('ClaimOrNot', ConcernformData.ClaimOrNot);
              formData.append('DetailsOfClaim', ConcernformData.DetailsOfClaim);
              formData.append('CompanyName', ConcernformData.CompanyName);
              formData.append('CompanyType', ConcernformData.CompanyType);
              formData.append('PatientRelatives2', ConcernformData.PatientRelatives2);
              formData.append('Relationship', ConcernformData.Relationship);
              formData.append('SignLhti', ConcernformData.SignLhti);
             
            //  formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MtpClaimOrNot`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >
         
         
        <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
           
           <p>Swasti Medical Foundation's</p>
         </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>Chirayu Hospital</h1>
           
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
           
            <p>Salvi Stop, Ratnagiri</p>
          </div>
          
         

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>Consent for Claim / Non claim</h3>
          </div>

         
          
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

           
           

        </div>
        
      


   

       <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
               

                <td>
                <div className="jee1 ">
                    <label> पेशंटचे नाव  <span>:</span></label>
                    <input type="text"
                    name="PatientName"
                    value={ConcernformData.PatientName}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="jee1">
                    <label>पेशंटच्या नातेवाईकांचे नाव  <span>:</span></label>
                    <input type="text"
                    name="PatientRelatives1"
                    value={ConcernformData.PatientRelatives1}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>
                  

                  <div className="jee1">
                    <label>
                      Claim आहे की नाही ? <span>:</span>
                    </label>
                    <div className="RegisForm_1_consent_consent_radiooo_head35r">
                      <div className="RegisForm_1_consent_consent_radiooo female4d">
                        <input
                          className="consent_consent_radiooo_inputt"
                          type="radio"
                          id="claimYes"
                          name="ClaimOrNot"
                          value="Yes"
                          onChange={handleChange}
                        />
                        <label htmlFor="claimYes"> होय </label>
                      </div>
                      <div className="RegisForm_1_consent_consent_radiooo female4eed">
                        <input
                          className="consent_consent_radiooo_inputt"
                          type="radio"
                          id="claimNo"
                          name="ClaimOrNot"
                          value="No"
                          onChange={handleChange}
                        />
                        <label htmlFor="claimNo"> नाही </label>
                      </div>
                    </div>
                  </div>

                  
                 {/* <div className="">
                    <label> Claim आहे की नाही ? <span>:</span></label>
                    <input 
                      type="checkbox"
                      name="ClaimOrNot"
                      value="Yes"
                      checked={ConcernformData.ClaimOrNot === "Yes"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> होय /</label>
                    <input 
                      type="checkbox"
                      name="ClaimOrNot"
                      value="No"
                      checked={ConcernformData.ClaimOrNot === "No"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> नाही</label>
                  </div> */}

                  <div className="jee1">
                    <label> Claim असल्यास details <span>:</span></label>
                    <textarea
                        name="DetailsOfClaim"
                        value={ConcernformData.DetailsOfClaim}
                        onChange={handleChange}
                        required
                      />
                  </div>

                  <div className="jee1">
                      <label> कंपनीचे नाव <span>:</span></label>
                      <textarea
                        name="CompanyName"
                        value={ConcernformData.CompanyName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  {/* <div className="jee1">
                    <label>कंपनी प्रकार : <span>:</span></label>
                    <input type="text"
                    name="RelationshipWithPatient"
                    value={ConcernformData.RelationshipWithPatient}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}

                  
                  <div className="jee1">
                    <label>
                    कंपनी प्रकार <span>:</span>
                    </label>
                    <div className="RegisForm_1_consent_consent_radiooo_head35r">
                      <div className="RegisForm_1_consent_consent_radiooo female4d">
                        <input
                          className="consent_consent_radiooo_inputt"
                          type="radio"
                          id="government"
                          name="CompanyType"
                          value="Government"
                          onChange={handleChange}
                        />
                        <label htmlFor="claimYes"> सरकार </label>
                      </div>
                      <div className="RegisForm_1_consent_consent_radiooo female4eed">
                        <input
                          className="consent_consent_radiooo_inputt"
                          type="radio"
                          id="private"
                          name="CompanyType"
                          value="Private"
                          onChange={handleChange}
                        />
                        <label htmlFor="claimNo"> खाजगी </label>
                      </div>
                    </div>
                  </div>


                </td>
              </tr>



              


            </tbody>
          </table>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            सूचना - पेशंटचा क्लेम असल्यास सदर claim च्या file processing रु.500/- मात्र आकारण्यात येतील.<br/>
            <br/>
           
            जर अँडमीशनच्यावेळी क्लेम नाही हे सांगितले तर त्या पेशंटचा क्लेम डॉक्युमेंट्स पूर्ण करून देण्याची जबाबदारी हॉस्पीटल प्रशासनाची राहणार नाही याची नोंद घ्यावी.
               
              
               
            </p>
          </div>
        </div>

        
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
              
            नातेवाईकांचे नाव:
                <input
                type="text"
                name="PatientRelatives2"
                value={ConcernformData.PatientRelatives2}
                onChange={handleChange}
                style={{width: '100px'}}
                required
                
                />
                <br/>
                नातेः
                <input
                type="text"
                name="Relationship"
                value={ConcernformData.Relationship}
                onChange={handleChange}
                style={{width: '100px'}}
                required
                />
                <br/>

                सही / डाव्या हाताचा अंगठाः
                <input
                type="text"
                name="SignLhti"
                value={ConcernformData.SignLhti}
                onChange={handleChange}
                style={{width: '100px'}}
                required
                />
               
            </p>
          </div>

        </div>

    


        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMtpClaimNonClaim;
