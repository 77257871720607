import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../SupplierMaster/SupplierMaster.css";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function CentralStockList() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("MedicalConsumable");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [statustype, setstatustype] = useState("NotExpired");

  const [locationOptions, setlocationOptions] = useState([]);

  const [Rowdata, setRowdata] = useState([]);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/getlocationdata")
      .then((response) => {
        const data = response.data;
        if (data) {
          const updatedData = data.filter(
            (p) => p.status === "Active" && p.location_name !== "ALL"
          );
          console.log(updatedData);
          setlocationOptions([...updatedData]);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/SupplierMaster/Expiry_Soon_stocks_medical")
      .then((response) => {
        const data = response.data;
        if (data.length !== 0) {
          const rows = data.map((p) => {
            return `Item: ${p.ItemName}, BatchNo: ${p.BatchNo}, Pack_type: ${p.Pack_type}`;
          });

          console.log(rows);
          alert(
            `These Medicines are Expiring within the next 6 months :\n \t${rows.join(
              "\n"
            )}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/SupplierMaster/Expired_stocks_medical")
      .then((response) => {
        const data = response.data;
        if (data.length !== 0) {
          const rows = data.map((p) => {
            return `Item: ${p.ItemName}, BatchNo: ${p.BatchNo}, Pack_type: ${p.Pack_type}`;
          });

          console.log(rows);
          alert(`These Medicines are Expired :\n \t${rows.join("\n")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (Productype !== "") {
      if (
        Productype === "MedicalConsumable" ||
        Productype === "MedicalNonConsumable" ||
        Productype === "NonMedicalConsumable" ||
        Productype === "NonMedicalNonConsumable" ||
        Productype === "Surgical"
      ) {
        axios
          .get(
            `http://127.0.0.1:8000/SupplierMaster/get_central_Store_Stock_medinfo?Status=${statustype}&Productype=${Productype}`
          )
          .then((response) => {
            const data = response.data;
            console.log(data);
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      } else {
        axios
          .get(
            `http://127.0.0.1:8000/SupplierMaster/get_central_Store_Stock_nonmedinfo?producttype=${Productype}`
          )
          .then((response) => {
            const data = response.data;
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      }
    }
  }, [Productype, statustype]);

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    console.log(params.row);
    const { Item_Code, Pack_type } = params.row;
    axios
      .get(
        `http://127.0.0.1:8000/SupplierMaster/get_Stock_medinfo_by_name?Pack_type=${Pack_type}&Item_Code=${Item_Code}&Status=${statustype}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        setSelectedShow(true);
        setRowdata([
          ...data.map((row, ind) => ({
            id: ind + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.Item_Name.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  // Define the columns dynamically
  const dynamicColumns = () => {
    if (
      Productype === "MedicalConsumable" ||
      Productype === "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 120 },
        { field: "Item_Name", headerName: "Item Name", width: 120 },
        { field: "Generic_Name", headerName: "Generic Name", width: 120 },
        { field: "Strength", headerName: "Strength", width: 100 },
        { field: "UOM", headerName: "UOM", width: 50 },
        { field: "Pack_type", headerName: "Pack Type", width: 100 },
        { field: "CentralStore", headerName: "Central Store", width: 100 },
        ...locationOptions.map((p) => ({
          field: p.location_name,
          headerName: p.location_name,
          width: 100,
        })),
        {
          field: "View",
          headerName: "View",
          width: 70,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params)}
                title="View"
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      Productype === "NonMedicalConsumable" ||
      Productype === "NonMedicalNonConsumable" ||
      Productype === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 120 },
        { field: "Item_Name", headerName: "Item Name", width: 120 },
        { field: "Generic_Name", headerName: "Generic Name", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 100 },
        { field: "CentralStore", headerName: "Central Store", width: 100 },
        ...locationOptions.map((p) => ({
          field: p.location_name,
          headerName: p.location_name,
          width: 100,
        })),
        {
          field: "View",
          headerName: "View",
          width: 70,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params)}
                title="View"
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 120 },
        { field: "Item_Name", headerName: "Item Name", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "CentralStore", headerName: "Central Store", width: 120 },
        ...locationOptions.map((p) => ({
          field: p.location_name,
          headerName: p.location_name,
          width: 120,
        })),
      ];
    }
  };

  const dynamicColumns1 = () => {
    if (
      Productype === "MedicalConsumable" ||
      Productype === "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 150 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "Batch_No", headerName: "Batch No ", width: 120 },
        { field: "HSN_Code", headerName: "HSN Code ", width: 120 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 120,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 120 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Selleing Price ",
          width: 120,
        },
        { field: "CentralStore", headerName: "Central Store", width: 120 },
        ...locationOptions.map((p) => ({
          field: p.location_name,
          headerName: p.location_name,
          width: 120,
        })),
      ];
    }else if (
      Productype === "NonMedicalConsumable" ||
      Productype === "NonMedicalNonConsumable" ||
      Productype === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 150 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "Batch_No", headerName: "Batch No ", width: 120 },
        { field: "HSN_Code", headerName: "HSN Code ", width: 120 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 120,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 120 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Selleing Price ",
          width: 120,
        },
        { field: "CentralStore", headerName: "Central Store", width: 120 },
        ...locationOptions.map((p) => ({
          field: p.location_name,
          headerName: p.location_name,
          width: 120,
        })),
      ];
    }
  };

  return (
    <>
    <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>Central Store Stock List</h4>
          <div className="doctor_select_1 selt-dctr-nse vcxw2er">
            <label htmlFor="Calender"> Product Type :</label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
              <option value="">Select</option>
              <option value="MedicalConsumable">Medical Consumable</option>
              <option value="MedicalNonConsumable">
                Medical Non-Consumable
              </option>
              <option value="NonMedicalConsumable">
                Non MedicalConsumable
              </option>
              <option value="NonMedicalNonConsumable">
                Non MedicalNon-Consumable
              </option>
              <option value="Surgical">Surgical</option>
              <option value="Assets">Assets</option>
              <option value="Stationary">Stationary</option>
              <option value="Non_Stationary">Non Stationary</option>
            </select>
          </div>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Item Name <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Item Name"
            />
          </div>

          {(Productype === "MedicalConsumable" ||
            Productype === "MedicalNonConsumable" ||
            Productype === "NonMedicalConsumable" ||
            Productype === "NonMedicalNonConsumable" ||
            Productype === "Surgical") && (

              <div className="inp_1">
              <label htmlFor="input">Status <span>:</span></label>
              <select
                value={statustype}
                onChange={(e) => setstatustype(e.target.value)}
              >
                <option value="NotExpired">Not-Expired</option>
                <option value="ExpirySoon">Expires Soon</option>
                <option value="Expired">Expired</option>
                {/* <option value='Returned'>Purchase Return</option> */}
              </select>
            </div>
          )}
        </div>
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}

          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype} Stock List</h3>
                  </div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={dynamicColumns1()} // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
