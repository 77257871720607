import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import "./Assesment.css";

function Assesment() {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const create = userRecord?.username;
  const Location = userRecord?.location;
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [AssessmentFormData, setAssessmentFormData] = useState({
    Allergies: "",
    PainScore: "",
    lmp: "",
    CheifComplaints: "",
    ProvisionalDiagnosis: "",
    PsycologicalAssessment: [],
    CurrentMedications: "",
    PastMedical: "",
    Physical: "",
    Suggestion: "",
  });

  const [selectedAssessment, setSelectedAssessment] = useState([]);

  const cleardata = () => {
    setAssessmentFormData({
      Allergies: "",
      PainScore: "",
      lmp: "",
      CheifComplaints: "",
      ProvisionalDiagnosis: "",
      PsycologicalAssessment: [],
      CurrentMedications: "",
      PastMedical: "",
      Physical: "",
      Suggestion: "",
    });
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssessmentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [checkboxValues, setCheckboxValues] = useState({
    smoking: false,
    ethanol: false,
    drugAdvise: false,
    bowelBladder: false,
    vegNonVeg: false,
  });


  const fetchData = useCallback(() => {
    const dataToFetch = {
      PatientID: formData.PatientID,
      appointment_patientregisterID: formData.SerialNo,
      visitNo: formData.visitNo,
    };

    Axios.get(
      `${UrlLink}doctorsworkbench/get_Assessment?PatientID=${dataToFetch.PatientID}&location=${Location}`
    )
      .then((response) => {
        if (response.data) {
          setSelectedAssessment(response.data);
        } else {
          setSelectedAssessment([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [formData, Location]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setAssessmentFormData((prevData) => ({
      ...prevData,
      [name]: checked ? value : "", // Clear the value if unchecked
    }));
  };

  // const handleCheckboxChangeSmoking = (event) => {
  //   const { name, checked } = event.target;
  //   setCheckboxValues({ ...checkboxValues, [name]: checked });
  // };

  const handleCheckboxChangeSmoking = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  
  // Modify other handleCheckboxChange functions similarly for other checkboxes
  
  // Inside your insert_Assessment function, filter out the selected checkbox values
  
  
  
  // const selectedCheckboxValues = Object.entries(checkboxValues)
  //   .filter(([key, value]) => value)
  //   .map(([key, value]) => key);
  


  // Join the selected checkbox values to form a string
  
  
  // const checkbox_values_str = selectedCheckboxValues.join(',');
  
  // Now you can use checkbox_values_str in your insert query
  


  const handleAdd = () => {
    const newAssessmentData = {
      ...AssessmentFormData,
      ...checkboxValues,
      PatientID: formData.PatientID,
      appointment_patientregisterID: formData.SerialNo,
      visitNo: formData.visitNo,
      location: Location,
      appointmentDate: appointmentDate,
      createdBy: create,
    };

    Axios.post(
      `${UrlLink}doctorsworkbench/insert_Assessment`,
      newAssessmentData
    )
      .then((response) => {
        console.log(response.data);
        successMsg("Saved Successfully");
        fetchData();
        cleardata();
        setCheckboxValues({
          smoking: false,
          ethanol: false,
          drugAdvise: false,
          bowelBladder: false,
          vegNonVeg: false,
        });
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
        errmsg("Error saving data.");
      });
  };

  const successMsg = (msg) => {
    toast.success(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };



  return (
    
  <>
  <Helmet>
    <title>Assessment</title>
  </Helmet>

  <div className="new-patient-registration-form">
    <div className="RegisFormcon">
      <div className="RegisForm_1">
        <label htmlFor="allergies">Allergies <span>:</span></label>
        <input
          type="text"
          id="allergies"
          name="Allergies"
          onChange={handleChange}
          value={AssessmentFormData.Allergies}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="PainScore">Pain Score <span>:</span></label>
        <input
          type="text"
          id="PainScore"
          name="PainScore"
          onChange={handleChange}
          value={AssessmentFormData.PainScore}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="LMP">LMP<span>:</span></label>
        <input
          type="date"
          id="LMP"
          name="lmp"
          onChange={handleChange}
          value={AssessmentFormData.lmp}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="CheifComplaints">Cheif Complaints <span>:</span></label>
        <input
          type="text"
          id="CheifComplaints"
          name="CheifComplaints"
          onChange={handleChange}
          value={AssessmentFormData.CheifComplaints}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="ProvisionalDiagnosis">Provisional Diagnosis <span>:</span></label>
        <input
          type="text"
          id="ProvisionalDiagnosis"
          name="ProvisionalDiagnosis"
          onChange={handleChange}
          value={AssessmentFormData.ProvisionalDiagnosis}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="PsycologicalAssessment">Psychological Assessment <span>:</span></label>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: '5px', justifyContent: 'center', textAlign: 'center', width: '135px', rowGap: '3px' }}>
        <label style={{ display: 'flex', width: '60px', gap: '5px' }} htmlFor="normal">
            <input
              type="checkbox"
              name="PsycologicalAssessment"
              value="Normal"
              onChange={handleCheckboxChange}
              checked={AssessmentFormData.PsycologicalAssessment.includes("Normal")}
            />
            Normal
          </label>
          <label style={{ display: 'flex', width: '60px', gap: '5px' }} htmlFor="normal">
            <input
              type="checkbox"
              name="PsycologicalAssessment"
              value="Anxious"
              onChange={handleCheckboxChange}
              checked={AssessmentFormData.PsycologicalAssessment.includes("Anxious")}
            />
            Anxious
          </label>
          <label style={{ display: 'flex', width: '60px', gap: '5px' }} htmlFor="normal">
            <input
              type="checkbox"
              name="PsycologicalAssessment"
              value="Depression"
              onChange={handleCheckboxChange}
              checked={AssessmentFormData.PsycologicalAssessment.includes("Depression")}
            />
            Depression
          </label>
        </div>
      </div>

      <div className="RegisForm_1">
        <label htmlFor="CurrentMedications">Current Medications <span>:</span></label>
        <input
          type="text"
          id="CurrentMedications"
          name="CurrentMedications"
          onChange={handleChange}
          value={AssessmentFormData.CurrentMedications}
          required
        />
      </div>

      
      

          </div>
          
          <br />
          <div className="RegisFormcon">
              <div className="RegisForm_1">
              
                <label htmlFor="PastMedical">Past Medical / Surgical Illness<span>:</span></label>
                <input type="text" 
                id="PastMedical"
                name="PastMedical"
                  onChange={handleChange} 
                  value={AssessmentFormData.PastMedical} required />

            </div>
            <br />
            <div className="RegisForm_1">
              <div className="assmet_cbg">
                            <div className="ass_chdgtt">
                                <div className="ass_pocd">
                                    <label htmlFor="smoking">
                                        <p> Smoking</p> :
                                    </label>
                                </div>
                                <div className="wdvw_yd">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="smoking"
                                            name="smoking"
                                            checked={checkboxValues.smoking}
                                            onChange={handleCheckboxChangeSmoking}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="ass_chdgtt">
                            <div className="ass_pocd">
                                    <label htmlFor="ethanol">
                                        <p> Ethanol</p> <span>:</span>
                                    </label>
                                </div>
                                <div className="wdvw_yd">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="ethanol"
                                            name="ethanol"
                                            checked={checkboxValues.ethanol}
                                            onChange={handleCheckboxChangeSmoking}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="ass_chdgtt">
                            <div className="ass_pocd">
                                    <label htmlFor="drugAdvise">
                                        <p> Drug Advise</p> <span>:</span>
                                    </label>
                                </div>
                                <div className="wdvw_yd">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="drugAdvise"
                                            name="drugAdvise"
                                            checked={checkboxValues.drugAdvise}
                                            onChange={handleCheckboxChangeSmoking}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="ass_chdgtt">
                            <div className="ass_pocd">
                                    <label htmlFor="bowelBladder">
                                        <p> Bowel / Bladder</p> <span>:</span>
                                    </label>
                                </div>
                                <div className="wdvw_yd">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="bowelBladder"
                                            name="bowelBladder"
                                            checked={checkboxValues.bowelBladder}
                                            onChange={handleCheckboxChangeSmoking}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="ass_chdgtt">
                            <div className="ass_pocd">
                                    <label htmlFor="vegNonVeg">
                                        <p> Veg / non-veg</p> <span>:</span>
                                    </label>
                                </div>
                                <div className="wdvw_yd">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="vegNonVeg"
                                            name="vegNonVeg"
                                            checked={checkboxValues.vegNonVeg}
                                            onChange={handleCheckboxChangeSmoking}
                                        />
                                    </label>
                                </div>
                            </div>
                    </div>
                    </div>
                    </div>
   
                    <br />


  <div className="RegisFormcon">

      <div className="RegisForm_1">
        <label htmlFor="Physical">Significant Physical Findings<span>:</span></label>
        <input
          type="text"
          id="Physical"
          name="Physical"
          onChange={handleChange}
          value={AssessmentFormData.Physical}
          required
        />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="Suggestion">Suggestion <span>:</span></label>
        <input
          type="text"
          id="Suggestion"
          name="Suggestion"
          onChange={handleChange}
          value={AssessmentFormData.Suggestion}
          required
        />
      </div>
    </div>

    <div className="Register_btn_con">
      <button className="RegisterForm_1_btns" onClick={handleAdd}>Add</button>
    </div>

    <div className="for">
      <div className="custom-header">
        <h4>Assessment</h4>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="vital_Twidth">Allergies</th>
              <th id="vital_Twidth">PainScore</th>
              <th id="vital_Twidth">LMP</th>
              <th id="vital_Twidth">CheifComplaints</th>
              <th id="vital_Twidth">Physical</th>
              <th id="vital_Twidth">PsycologicalAssessment</th>
              <th id="vital_Twidth">CurrentMedications</th>
              <th id="vital_Twidth">PastMedical</th>
              <th id="vital_Twidth">Health Lifestyle Risk</th>
              <th id="vital_Twidth">ProvisionalDiagnosis</th>
              <th id="vital_Twidth">Suggestion</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(selectedAssessment) && selectedAssessment.length > 0 ? (
              selectedAssessment.map((assessment, index) => (
                <tr key={index}>
                  <td>{assessment.Allergies}</td>
                  <td>{assessment.PainScore}</td>
                  <td>{assessment.lmp}</td>
                  <td>{assessment.CheifComplaints}</td>
                  <td>{assessment.Physical}</td>
                  <td>{assessment.PsycologicalAssessment}</td>
                  <td>{assessment.CurrentMedications}</td>
                  <td>{assessment.PastMedical}</td>
                  <td>{assessment.checkbox_values_str}</td>
                  <td>{assessment.ProvisionalDiagnosis}</td>
                  <td>{assessment.Suggestion}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  </div>
</>


  );
  
}

export default Assesment;
