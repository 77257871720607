import React, { useState,useEffect } from "react";
import "../Pettycash/Pettycash.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import { alignProperty } from "@mui/material/styles/cssUtils";
import EditIcon from '@mui/icons-material/Edit';
function RoleManagement() {
  const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const [roleName, setRoleName] = useState("");
  const [bedCount, setBedCount] = useState("");
  const [locationName, setLocationName] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentrole, setDepartmentRole] = useState("");
  const [Specialities, setSpecialities] = useState("");
 const [religionid, setreligionid] = useState("");
 const [religion, setReligion] = useState("");
  const [religionData, setReligionData] = useState([]);
  const [religionEdit, setReligionEdit] = useState(false);


  const [roleData, setRoleData] = React.useState([]);
  const [locationData, setLocationData] = React.useState([]);
  const [departmentData, setDepartmentData] = React.useState([]);
  const [locationpharmacy, setlocationpharmacy] = React.useState([]);
  const [SpecialitiesData, setSpecialitiesData] = React.useState([]);

  const [location, setlocation] = useState("");
  const [Pharmacy, setPharmacy] = useState("");
  console.log(location);
  console.log(Pharmacy);
  // ----------------------
  const [routeno, setRouteNo] = useState({
    tehsil_id: '',
    routeno: '',
    routename:'',
    tehsilname: '',
    villagename:'',
    Location: '',
    Status: 'Active'
  });
  const [IsrouteEdit, setIsRouteEdit] = useState(false)
  const [Isrouteget, setIsRouteget] = useState(false)

  const [tehsildata, setTehsilData] = useState([])
  // ----------
  // tehsildata=================================


  const handleSubmittehsil = () => {
    console.log(routeno);
    if (
        routeno.tehsilname &&
        routeno.routeno &&
        routeno.Location &&
        routeno.villagename &&
        routeno.routename
    ) {
        const existdata = tehsildata.find((f) => {
            return (
                f.route_name !== routeno.routename &&
                f.routeno === routeno.routeno &&
                f.Location === routeno.Location
            );
        });
        const existdata1 = tehsildata.find((f) => {
          return (
              f.route_name === routeno.routename &&
              f.routeno === routeno.routeno &&
              f.Location === routeno.Location &&
              f.tehsilname === routeno.tehsilname &&
              f.village_name === routeno.villagename 
          );
      });
       
        if (existdata) {
            userwarn(`The Route Name,Route No Already Exist in the ${routeno.Location}`);
        }else if(existdata1){
          userwarn(`The village Name,Thesil Name  Already Exist in the ${routeno.Location}`);
        } else {
            // Proceed with insertion
            axios
              .post(`http://127.0.0.1:8000/usercontrol/insert_tehsil`, routeno)
              .then((response) => {
                console.log(response);
                if (response.data.message) {
                  successMsg(response.data.message);
                } else {
                  userwarn(response.data.Exists);
                }
                setRouteNo({
                  tehsil_id: "",
                  routeno: "",
                  routename: "",
                  tehsilname: "",
                  villagename: "",
                  Location: "",
                  Status: "Active",
                });
                setIsRouteEdit(false);
                setIsRouteget(!Isrouteget);
              })
              .catch((error) => {
                console.log(error);
              });
        }
    } else {
        userwarn("Please Fill All Fields");
    }
};

  
  const handleEdittehsil = (row) => {
    // Set the route number state with the row data

    setIsRouteEdit(true);
    // Scroll to the input element
    const inputElement = document.getElementById("tehsilname");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    // Update the state variables with the edited values
    setRouteNo((prev) => ({
      ...prev,
      tehsil_id: row.tehsil_id,
      routename:row.route_name,
      tehsilname: row.tehsilname,
      villagename:row.village_name,
      routeno: row.routeno,
      Location:row.Location,
      Status: row.Status
    }));
  }



  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/usercontrol/get_route_full_data`)
      .then((response) => {
        console.log("routedata",response.data)
        setTehsilData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [Isrouteget])

  const handlerouteClick = (row) => {

    const data = {
      tehsil_id: row.tehsil_id,
      tehsilname: row.tehsilname,
      routename:row.route_name,
      villagename:row.village_name,
      routeno: row.routeno,
      Status: row.Status === 'Active' ? 'Inactive' : 'Active',
    };

    axios.post(`http://127.0.0.1:8000/usercontrol/insert_tehsil`, data)
      .then((response) => {
        console.log(response);
        setIsRouteget(!Isrouteget);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    // Fetch role data from the backend and update the state
    fetchRoleData();
    fetchLocationData();
    fetchDepartmentData();
    // fetchdepartmentforrole();
    fetchlocationpharmacyData();
    fetchReligionData();
  }, []);

  const fetchRoleData = () => {
    // Use Axios to make a GET request
    axios
      .get("http://127.0.0.1:8000/usercontrol/getRoleData")
      .then((response) => {
        const data = response.data;
        console.log(data);
        setRoleData(data);
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  };
  // const fetchdepartmentforrole = () => {
  //   // Use Axios to make a GET request
  //   axios.get('http://127.0.0.1:8000/usercontrol/getDepartment')
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data)
  //       setDepartmentRole(data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching role data:', error);
  //     });
  // };

  const fetchLocationData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/getlocationdata")
      .then((response) => {
        const data = response.data;
        console.log(data);

        setLocationData(data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const fetchDepartmentData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/getDepartment")
      .then((response) => {
        const data = response.data;
        console.log(data);

        setDepartmentData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
  };

  const fetchlocationpharmacyData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/get_pharmacy_bylocation")
      .then((response) => {
        const data = response.data;
        console.log(data);

        setlocationpharmacy(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
  };

  const handleSubmitOne = (e) => {
    e.preventDefault();

    const uppercaseRoleName = roleName.toUpperCase();

    if (uppercaseRoleName !== "" && departmentrole !== "select") {
      // Create a FormData object with the role name
      const formData = new FormData();
      formData.append("roleName", uppercaseRoleName);
      formData.append("department", departmentrole);

      // Use Axios to make a POST request
      axios
        .post("http://127.0.0.1:8000/usercontrol/insertRole", formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } 

          // Clear the form field after successful submission
          setRoleName("");
          setDepartmentRole("select");

          // Fetch the updated role data
          fetchRoleData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userwarn("Please enter the role name and select the department");
    }
  };
  const handleEditClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const role_id = data.role_id;
      Update_status_fun(role_id, status);
    } else {
      const status = "Inactive";
      const role_id = data.role_id;
      Update_status_fun(role_id, status);
    }
  };

  const Update_status_fun = (role_id, status) => {
    console.log("Updating status:", status);
    console.log("Role ID:", role_id);

    axios
      .post("http://127.0.0.1:8000/usercontrol/statusupdate", {
        status,
        role_id,
      })
      .then((res) => {
        console.log(res.data);
        fetchRoleData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitTwo = (e) => {
    e.preventDefault();
    const uppercaseLocationName = locationName.toUpperCase();

    if (uppercaseLocationName !== "" && bedCount !== "") {
      
      const formData = new FormData();
      formData.append("locationname", uppercaseLocationName);
      formData.append("bedCount", bedCount);

      axios
        .post("http://127.0.0.1:8000/usercontrol/insertlocation", formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else if (response.data && response.data.warn) {
            userwarn(response.data.warn);
          }
          // Clear the form field after successful submission
          setLocationName("");
          setBedCount("");

          // Fetch the updated location data
          fetchLocationData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userwarn("please enter the location name");
    }
  };

  const handleLocClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const location_id = data.location_id;
      Update_loc_fun(location_id, status);
    } else {
      const status = "Inactive";
      const location_id = data.location_id;
      Update_loc_fun(location_id, status);
    }
  };

  const Update_loc_fun = (location_id, status) => {
    console.log(status);
    axios
      .post("http://127.0.0.1:8000/usercontrol/statusupdatelocation", {
        status,
        location_id,
      })
      .then((res) => {
        console.log(res.data);
        fetchLocationData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitlocation = (e) => {
    e.preventDefault();
    const uppercaselocationName = location.toUpperCase();
    const upperpharmacyname = Pharmacy.toUpperCase();

    if (uppercaselocationName !== "") {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append("location", uppercaselocationName);
      formData.append("pharmacy", upperpharmacyname);

      axios
        .post("http://127.0.0.1:8000/usercontrol/insertpharmacy", formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          }
          // Clear the form field after successful submission
          setPharmacy("");
          setlocation('')

          // Fetch the updated location data
          fetchlocationpharmacyData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userwarn("please Enter Location name");
    }
  };

  const handleSubmitThree = (e) => {
    e.preventDefault();
    const uppercaseDepartmentName = department.toUpperCase();

    if (uppercaseDepartmentName !== "") {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append("department", uppercaseDepartmentName);

      axios
        .post("http://127.0.0.1:8000/usercontrol/insertdepartment", formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          }
          // Clear the form field after successful submission
          setDepartment("");

          // Fetch the updated location data
          fetchDepartmentData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userwarn("please Enter Department name");
    }
  };

  const handleDeptClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const dept_id = data.dept_id;
      Update_dep_fun(dept_id, status);
    } else {
      const status = "Inactive";
      const dept_id = data.dept_id;
      Update_dep_fun(dept_id, status);
    }
  };

  const Update_dep_fun = (dept_id, status) => {
    console.log(status);
    axios
      .post("http://127.0.0.1:8000/usercontrol/statusupdatefor_dept", {
        status,
        dept_id,
      })
      .then((res) => {
        console.log(res.data);
        fetchLocationData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handlepharmacyClick = (data) => {
    console.log(data);
    if (data.Status === "Inactive") {
      const status = "Active";
      const location_id = data.location_id;
      update_pharmacy_status(location_id, status);
    } else {
      const status = "Inactive";
      const location_id = data.location_id;
      update_pharmacy_status(location_id, status);
    }
  };

  const update_pharmacy_status = (location_id, status) => {
    console.log(status);
    axios
      .post(
        `http://127.0.0.1:8000/usercontrol/update_pharmacy_status?status=${status}&location_id=${location_id}`
      )
      .then((res) => {
        console.log(res.data);
        fetchlocationpharmacyData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Use fetch to send the formData to your server
      fetch("http://127.0.0.1:8000/doctorsworkbench/Diagnosis_csvto_sql_link", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          successMsg("File processed successfully");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userwarn("Please select a file first");
    }
  };

  React.useEffect(() => {
    fetchSpecialitiesData();
  }, []);

  
  const fetchSpecialitiesData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/get_Specialities_data")
      .then((response) => {
        const data = response.data;
        console.log(data);

        setSpecialitiesData(data);
      })
      .catch((error) => {
        console.error("Error fetching Specialities data:", error);
      });
  };


  const handleSpecialitiesClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const dept_id = data.Specialities_id;
      Update_Specialities_fun(dept_id, status);
    } else {
      const status = "Inactive";
      const dept_id = data.Specialities_id;
      Update_Specialities_fun(dept_id, status);
    }
  };

  const Update_Specialities_fun = (dept_id, status) => {
    console.log('cvcv',dept_id,status);
    axios
      .post("http://127.0.0.1:8000/usercontrol/update_status_for_Specialities", {
        status,
        dept_id,
      })
      .then((res) => {
        console.log(res.data);
        successMsg("Status Update Successfully");
        fetchSpecialitiesData()
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitSpecialities = (e) => {
    e.preventDefault();

    if (Specialities !== "") {
      axios
        .post("http://127.0.0.1:8000/usercontrol/insertSpecialities", {Specialities})
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } 
          // Clear the form field after successful submission
          setSpecialities("");

          // Fetch the updated location data
          fetchSpecialitiesData();
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    } else {
      userwarn("please Enter Specialities name");
    }
  };
  const handleReligionSubmit = () => {
    console.log(religion);

    const data = new FormData();

    data.append("religion", religion.trim());
    data.append("religionid", religionid || null);

    if (religion === "") {
      // Assuming routeno is the required field
      userwarn("Please Fill The Religion Name");
    } else {
      axios
        .post(`http://127.0.0.1:8000/usercontrol/insert_religion`, data)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            successMsg(response.data.message);
            setreligionid("null");
            fetchReligionData();
          } else {
            userwarn(response.data.Exists);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setReligion(""); // Assuming you want to clear the routeno after submission
    setReligionEdit(false);
  };

  const fetchReligionData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/getReligion")
      .then((response) => {
        const data = response.data;
        console.log("religion", response);

        setReligionData(data);
      })
      .catch((error) => {
        console.error("Error Religion data:", error);
      });
  };
  const handleReligionEdit = (row) => {
    console.log("row", row);
    setReligionEdit(true);
    const inputReligion = document.getElementById("religion");
    if (inputReligion) {
      inputReligion.scrollIntoView({ behavior: "smooth", block: "start" });
      // setReligion((prev)=>({
      //   ...prev,
      //   religion_id:row.religion_id,
      //   religion:row.religion,
      // }));
      setreligionid(row.religion_id);
      setReligion(row.religion);
    }
  };
  

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>User Management</h4>
        </div>

        <div className="ShiftClosing_Container">
          <div className="">
          
              <h2 style={{ textAlign: "center" }}>Location</h2>

              <div className="con_1 ">
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                    Location Name :
                  </label>
                  <input
                    type="text"
                    id="FirstName"
                    name="locationName"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value.toUpperCase().trim())}
                    placeholder="Enter Location Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                    Bed Count :
                  </label>
                  <input
                    type="number"
                    id="FirstName"
                    name="bedCount"
                    value={bedCount}
                    onKeyDown={blockInvalidChar}
                    onChange={(e) => setBedCount(e.target.value)}
                    placeholder="Enter Bed Count"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitTwo}>
                  <AddIcon />
                </button>
              </div>

              <div
                style={{ width: "100%", display: "grid", placeItems: "center" }}
              >
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Location Name</th>
                        <th>Bed Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.location_id}</td>
                          <td>{row.location_name}</td>
                          <td>{row.BedCounts}</td>
                          {/* <td>

                            <button onClick={() => handleLocClick(row)}
                              className='Addnamebtn_pt2'>{row.status}</button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
            </div>
        <br />

        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Pharmacy By Location</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Location <span>:</span>
                </label>
                <select
                  name="location"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {locationData.filter((f)=>f.location_name!=='ALL').map((department) => (
                    <option
                      key={department.location_id}
                      value={department.location_name}
                    >
                      {department.location_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Pharmacy <span>:</span>
                </label>
                <input
                  type="text"
                  id="Pharmacy"
                  name="Pharmacy"
                  value={Pharmacy}
                  onChange={(e) => setPharmacy(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Pharmacy Name"
                />
              </div>
              <button className="btn_1" onClick={handleSubmitlocation}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Location</th>
                      <th>Pharmacy</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationpharmacy.map((row, index) => (
                      <tr key={index}>
                        <td>{row.location_id}</td>
                        <td>{row.location_name}</td>
                        <td>{row.Sublocation_name}</td>
                        {/* <td>{row.Status}</td> */}
                        <td>
                          <button
                            onClick={() => handlepharmacyClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.Status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Department</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="FirstName" style={{ whiteSpace: "nowrap" }}>
                  Department Name <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Department Name"
                />
              </div>
              <button className="btn_1" onClick={handleSubmitThree}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Department</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {departmentData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Dept_id}</td>
                        <td>{row.Department_name}</td>
                        <td>
                          <button
                            onClick={() => handleDeptClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Designation</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Department <span>:</span>
                </label>
                <select
                  name="department"
                  value={departmentrole}
                  onChange={(e) => setDepartmentRole(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {departmentData.map((department) => (
                    <option
                      key={department.Dept_id}
                      value={department.Department_name}
                    >
                      {department.Department_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Designation <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Designation Name"
                />
              </div>
              <button className="btn_1" onClick={handleSubmitOne}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Department</th>
                      <th>Designation</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.role_id}</td>
                        <td>{row.Department}</td>
                        <td>{row.role_name}</td>
                        <td>
                          <button
                            onClick={() => handleEditClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />

            

            <div className="RegisForm_1">
              <label>
                {" "}
                Upload CSV File <span>:</span>{" "}
              </label>
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                id="Servicechoose"
                name="filechooser"
                required
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label
                htmlFor="Servicechoose"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
              <button className="RegisterForm_1_btns choose_file_update" 
              onClick={handleFileUpload}>
                Upload
              </button>
            </div>
          </div>
        </div>
          <div className="ShiftClosing_Container">
          <div className='FirstpartOFExpensesMaster'>
            <h2 style={{ textAlign: 'center' }}>Route</h2>

            <div className="con_1 ">

              <div className="inp_1">
                <label htmlFor="routeno" style={{ whiteSpace: "nowrap" }}>Route No :</label>
                <input
                  type="text"
                  id="routeno"
                  name="routeno"
                  value={routeno.routeno}
                  onChange={(e) => setRouteNo((prev) => ({
                    ...prev,
                    routeno: e.target.value.trim()
                  }))}
                  placeholder="Enter Route Number"
                  style={{ width: "150px" }}
                />
              </div>
               <div className="inp_1">
                <label htmlFor="tehsilname" style={{ whiteSpace: "nowrap" }}>Route Name :</label>
                <input
                  type="text"
                  id="routename"
                  name="routename"
                  value={routeno.routename}
                  onChange={(e) => setRouteNo((prev) => ({
                    ...prev,
                    routename: e.target.value.toUpperCase().trim()
                  }))}
                  placeholder="Enter Route Name"
                  style={{ width: "150px" }}
                />
              </div> 
               <div className="inp_1">
                <label htmlFor="tehsilname" style={{ whiteSpace: "nowrap" }}>Thasil Name :</label>
                <input
                  type="text"
                  id="tehsilname"
                  name="tehsilname"
                  value={routeno.tehsilname}
                  onChange={(e) => setRouteNo((prev) => ({
                    ...prev,
                    tehsilname: e.target.value.toUpperCase().trim()
                  }))}
                  placeholder="Enter Tehsil Name"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="villagename" style={{ whiteSpace: "nowrap" }}>Village Name :</label>
                <input
                  type="text"
                  id="villagename"
                  name="villagename"
                  value={routeno.villagename}
                  onChange={(e) => setRouteNo((prev) => ({
                    ...prev,
                    villagename: e.target.value.toUpperCase().trim()
                  }))}
                  placeholder="Enter Village Name"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="routeno" style={{ whiteSpace: "nowrap" }}>Location :</label>
                
                <select
                  id="Location"
                  name="Location"
                  value={routeno.Location}
                  onChange={(e) => setRouteNo((prev) => ({
                    ...prev,
                    Location: e.target.value
                  }))}
                >
                  <option value=''>Select</option>
                  {locationData.filter((f)=>f.location_name!=='ALL' ).map((r,indxx)=>(
                    <option key={indxx} value={r.location_name}>{r.location_name}</option>
                  ))}
                </select>
              </div>
              <button className="btn_1" onClick={handleSubmittehsil}>
                {IsrouteEdit ? "Update" : "Add"}
              </button>
            </div>


            <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th >S.No</th>
                      <th >Route Number</th>
                      <th >Route Name</th>
                      <th>Thasil Name</th>
                      <th>Village Name</th>
                      <th >Location</th>
                      <th >Edit</th>
                      <th >Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tehsildata?.map((row, index) => (
                      <tr key={index}>
                        {/* {console.log(row)} */}
                        <td>{row.tehsil_id}</td>
                        <td>{row.routeno}</td>
                       
                        <td>{row.route_name}
                        </td>
                        <td>{row.tehsilname}</td>
                        <td>{row.village_name}</td>
                        <td>{row.Location}</td>
                        <td>

                          <button className="cell_btn" onClick={() => handleEdittehsil(row)}
                          ><EditIcon /></button>
                        </td>
                        <td>

                          <button onClick={() => handlerouteClick(row)}
                            className='Addnamebtn_pt2'>{row.Status}</button>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>

            </div>

          </div>
            </div>          
        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Specialities</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="FirstName" style={{ whiteSpace: "nowrap" }}>
                Specialities Name <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="Specialities"
                  value={Specialities}
                  onChange={(e) => setSpecialities(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Specialities Name"
                />
              </div>
              <button className="btn_1" onClick={handleSubmitSpecialities}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Specialities</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SpecialitiesData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Specialities_id}</td>
                        <td>{row.Specialities_name}</td>
                        <td>
                          <button
                            onClick={() => handleSpecialitiesClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Religion</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="routeno" style={{ whiteSpace: "nowrap" }}>
                  Religion Name :
                </label>
                <input
                  type="text"
                  id="religion"
                  name="religion"
                  value={religion}
                  onChange={(e) => setReligion(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Religion Name"
                  style={{ width: "150px" }}
                />
              </div>

              <button className="btn_1" onClick={handleReligionSubmit}>
                {religionEdit ? "Update" : "Add"}
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Religion Name</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {religionData.length > 0 &&
                      religionData.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{row.religion}</td>
                          <td>
                            <button
                              className="cell_btn"
                              onClick={() => handleReligionEdit(row)}
                            >
                              <EditIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Designation</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Department :
                </label>
                <select
                  name="department"
                  value={departmentrole}
                  onChange={(e) => setDepartmentRole(e.target.value.toUpperCase().trim())}
                  className="deprtsele"
                  required
                >
                  <option value="select">Select Department</option>
                  {departmentData.map((department) => (
                    <option
                      key={department.Dept_id}
                      value={department.Department_name}
                    >
                      {department.Department_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Designation :
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value.toUpperCase().trim())}
                  placeholder="Enter Designation Name"
                  style={{ width: "150px" }}
                />
              </div>
              <button className="btn_1" onClick={handleSubmitOne}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Department</th>
                      <th>Designation</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.role_id}</td>
                        <td>{row.Department}</td>
                        <td>{row.role_name}</td>
                        <td>
                          <button
                            onClick={() => handleEditClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}

export default RoleManagement;
