import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import Webcam from "react-webcam";

// import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

// import "./LabMaster.css";

const LabReportFN = () => {
  const formData = useSelector((state) => state.userRecord?.labformdata);
  console.log(formData);

  const nursedata = useSelector((state) => state.userRecord?.formData);
  console.log(nursedata)

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  // const navigate = useNavigate();

  const [defaultDate, setDefaultDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  // const formData = useSelector(state => state.userRecord?.formData);

  const lab_Patient_ID = formData?.PatientID || nursedata.PatientID;

  const visitNo = formData?.visitNo || nursedata.visitNo;



  console.log("visitNo:", visitNo);

  // const [showCamera, setShowCamera] = useState(false);

  const [capturedImageSec, setCapturedImageSec] = useState(null);

  const [Selected_File, setSelected_File] = useState(null);

  // const [isImageCaptured, setIsImageCaptured] = useState(false);

  // const webcamRef = useRef(null);

  const [Temp_testnamelist, setTemp_testnamelist] = useState([]);

  const [Request_date, setRequest_date] = useState("");

  console.log(Selected_File);

 
  

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/labtest/get_Passiont_lab_detailes?patientid=${lab_Patient_ID}&visitNo=${visitNo}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log("sss", response.data[0].Temp_testnamelist); // Modified this line
        let A_data = response.data[0].Date; // Modified this line
        let B_data = response.data[0].Temp_testnamelist; // Modified this line
        setRequest_date(A_data);
        setTemp_testnamelist(B_data);
      })
      .catch((err) => console.log(err));
  }, [lab_Patient_ID]);


  // const handleOpenCamera = () => {
  //   setShowCamera(true);
  // };

  // const handleCloseCamera = () => {
  //   setShowCamera(false);
  // };

  // const handleRecaptureImageSec = () => {
  //   setCapturedImageSec(null);
  //   setIsImageCaptured(false);
  // };

  const handleInputFileChange = (e) => {
    // Handle file input change if needed
    const file = e.target.files[0];
    setSelected_File(file)   
  };

  // const capturedImageSecnd = () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   const blob = dataURItoBlob(imageSrc);
  //   setCapturedImageSec(blob);
  //   setIsImageCaptured(true);
  // };

  // Convert a data URI to a Blob
  // const dataURItoBlob = (dataURI) => {
  //   const byteString = atob(dataURI.split(",")[1]);
  //   const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ab], { type: mimeString });
  // };

  // const handleHideCamera = () => {
  //   setShowCamera(false);
  // };

  const test_name_submit = () => {
    const url = "https://vesoftometic.co.in/labtest/update_lab_reportdocument";
    const formData = new FormData();
    formData.append("lab_Patient_ID", lab_Patient_ID);
    formData.append("Date", defaultDate);
    formData.append("visitNo", visitNo);
    formData.append(
      "Lab_image_document", Selected_File
    );

    console.log(capturedImageSec);

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);
        successMsg(response.data.message);
        setTemp_testnamelist([]);
        // setIsImageCaptured(null);
        // setCapturedImageSec(null);
        setSelected_File(null);
        setDefaultDate("");
        setRequest_date("");
        // navigate('/Lab-QueueList')
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occured");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="form-container22">
        <div className="header-patient33">
          <h3>Lab Service</h3>
        </div>

        <div className="form-row55">
          <div className="form_row_66 lab-undr">
            <label htmlFor="lastName">Patient ID :</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Enter ID"
              required
              value={lab_Patient_ID || nursedata.PatientID}
              readOnly
            />
          </div>
          <div className="form_row_66 lab-undr">
            <label htmlFor="firstName">Patient Name :</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter patient Name"
              value={formData?.patientname || nursedata.PatientName}
              required
              readOnly
            />
          </div>

          <div className="form_row_66 lab-undr">
            <label htmlFor="Doctor_name">Doctor Name :</label>
            <input
              type="text"
              id="Doctor_name"
              name="Doctor_name"
              placeholder="Enter Doctor name"
              value={formData?.DoctorName || nursedata.DoctorName}
              required
              readOnly
            />
          </div>
        </div>

        <div className="form-row55" style={{ marginBottom: "0px !important" }}>
          <div className="form_row_66 lab-undr">
            <label htmlFor="lastName">Request Date :</label>
            <input type="Date" readOnly value={Request_date} required />
          </div>

          <div className="form_row_66 lab-undr">
            <label htmlFor="lastName">Update Date :</label>
            <input
              type="Date"
              id="lastName"
              name="lastName"
              placeholder="Enter Last Name"
              value={defaultDate}
              onChange={(e) => setDefaultDate(e.target.value)}
              required
            />
          </div>

          <div className="form_row_66 lab-undr formrow_chse_pic">
            <label htmlFor="patientPhoto">Lab Report:</label>
            <div className="file-input-444">
              <div className="input-container-99">
                <input
                  type="file"
                  id="fileChooser1"
                  name="fileChooser1"
                  accept="image/pdf" // Corrected accept attribute
                  required
                  onChange={handleInputFileChange}
                />
                <label htmlFor="fileChooser1" className="choose-file-button-55">
                  Choose File
                </label>
              </div>
              {/* <span>or</span>
              <div className="input-container-99">
                <button
                  onClick={handleOpenCamera}
                  className="choose-file-button-55"
                >
                  Take Pic
                </button>

                {showCamera && (
                  <div
                    className="showcamera_takepic"
                    onClick={handleHideCamera}
                  >
                    <div
                      className="showcamera_1_takepic1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isImageCaptured ? ( // Display the captured image
                        <img
                          src={URL.createObjectURL(capturedImageSec)}
                          alt="captured"
                          className="captured-image11"
                        />
                      ) : (
                        <div className="camera-container">
                          <div className="web_head">
                            <h3>Image</h3>
                          </div>
                          <br></br>
                          <div className="RotateButton_canva">
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={facingMode === "user"}
                              className="web_cam"
                              videoConstraints={videoConstraints}
                            />
                            {devices.includes(IsmobileorNot) && (
                              <button onClick={switchCamera}>
                                <CameraswitchIcon />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="web_btn">
                        {isImageCaptured ? ( // Render the Recapture button if an image is captured
                          <button
                            onClick={handleRecaptureImageSec}
                            className="btncon_add"
                          >
                            Recapture
                          </button>
                        ) : (
                          <button
                            onClick={capturedImageSecnd}
                            className="btncon_add"
                          >
                            Capture
                          </button>
                        )}
                        <button
                          onClick={handleCloseCamera}
                          className="btncon_add"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>

        {Temp_testnamelist.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: "10px" }}>
            <h3>Test List</h3>
            <div className="Selected-table-container RateCard_table">
              <br />

              <br />
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Temp_testnamelist &&
                    Temp_testnamelist.map((ele, ind) => (
                      <tr key={"mytbl" + ind}>
                        <td>{ele.Test_Name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            className="btncon_add prs-ad-sub-btn"
            onClick={test_name_submit}
          >
            Save
          </button>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};
export default LabReportFN;
