import React, { useState, useEffect, useCallback } from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';


// const yourStyles = {
//   position: 'absolute',
//   inset: '100px',
//   border: '1px solid rgb(204, 204, 204)',
//   background: 'rgb(97 90 90 / 75%)',
//   overflow: 'auto',
//   borderRadius: '4px',
//   outline: 'none',
//   padding: '0px'
// }

const LeaveMangement = () => {

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  const userRecord = useSelector(state => state.userRecord?.UserData)


  // const [searchFormdata, setSearchFormData]=useState({
  //   employeename:'',
  //   designation :'',
  //   date:''
  // })
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchQuery1, setSearchQuery1] = useState('');
  // const [searchQuerypre, setSearchQuerypre] = useState('')
  // const [searchQuerypre1, setSearchQuerypre1] = useState('')

  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  // const [filteredRows, setFilteredRows] = useState([]);
  // const [filteredRows1, setFilteredRows1] = useState([]);
  console.log('userdata', userRecord);
  const [openModal, setOpenModal] = useState(false);
  // const [openImageView, setopenImageView] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const urllink = useSelector((state) => state.userRecord?.UrlLink);





  // const [rolename, setRolename] = useState([])
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${urllink}HRmanagement/getRole_all`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       setRolename(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });


  // }, [userRecord?.location]);





  const fetchLeaveData = useCallback(() => {
    fetch(`${urllink}HRmanagement/get_leave_register_submit?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.designation,
            leaveType: userdata.LeaveType,
            fromdate: userdata.FromDate,
            todate: userdata.ToDate,
            days: userdata.DaysCount,
            reason: userdata.Reason,
            medicalcertificate: userdata.Medical_certificate,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus
          }));
          setRows(Records);
          console.log('record', Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, userRecord])

  const fetchPermissionsData = useCallback(() => {
    fetch(`${urllink}HRmanagement/get_for_permission_submit?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)

        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromtime: userdata.fromtime,
            designation: userdata.designation,
            totime: userdata.totime,
            reason: userdata.Reason,
            hours: userdata.hours,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus
          }));
          setRows1(Records);
          console.log(Records)
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, userRecord]);

  React.useEffect(() => {
    fetchLeaveData();
    fetchPermissionsData();
  }, [fetchLeaveData, fetchPermissionsData]);


  const handleVisibilityClick = (medicalcertificate) => {
    setModalContent(medicalcertificate);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setModalContent('');
  };
  // handlesubmit for data get



  // useEffect(() => {
  //   const lowerCaseQuery = searchQuery.toLowerCase();
  //   const lowerCaseQuery1 = searchQuery1.toLowerCase();

  //   const filterRow = (row) => {
  //     const lowerCaseEmployeeName = row.employeename.toLowerCase();
  //     const lowerCaseDesignation = row.designation.toLowerCase();
  //     const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
  //     const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

  //     if (!searchQuery && !searchQuery1) {
  //       // If both search queries are empty, do not filter out any data
  //       return true;
  //     }

  //     return nameMatches && designationMatches;
  //   };

  //   const filteredData = rows.filter(filterRow);


  //   setFilteredRows(filteredData);

  // }, [searchQuery, rows, rows1, searchQuery1]);



  // useEffect(() => {
  //   const lowerCaseQuerypre = searchQuerypre.toLowerCase();
  //   const lowerCaseQuerypre1 = searchQuerypre1.toLowerCase();

  //   const filterRow = (row) => {
  //     const lowerCaseEmployeeName = row.employeename.toLowerCase();
  //     const lowerCaseDesignation = row.designation.toLowerCase();
  //     const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuerypre);
  //     const designationMatches = lowerCaseDesignation.includes(lowerCaseQuerypre1);

  //     if (!searchQuerypre && !searchQuerypre1) {
  //       // If both search queries are empty, do not filter out any data
  //       return true;
  //     }

  //     return nameMatches && designationMatches;
  //   };


  //   const filteredData1 = rows1.filter(filterRow);


  //   setFilteredRows1(filteredData1);
  // }, [searchQuerypre, rows1, searchQuerypre1]);









  return (
    <div className='appointment'>
      <div className='h_head'>
        <h4>Leave & Permission Management</h4>
      </div>
      <div className="Add_items_Purchase_Master">
        <span>Employees Leave Consumed Details</span>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Leave Type</th>
              <th id="slectbill_ins">From Date</th>
              <th id="slectbill_ins">To Date</th>
              <th id="slectbill_ins">Days</th>
              <th id="slectbill_ins">Reason</th>
              <th id="slectbill_ins">Status</th>
              <th id="slectbill_ins">View</th>

            </tr>
          </thead>
          <tbody>
            {rows.map((leave, index) => (
              <tr key={index}>
                <td>{leave.employeeid}</td>
                <td>{leave.employeename}</td>
                <td>{leave.leaveType}</td>
                <td>{leave.fromdate}</td>
                <td>{leave.todate}</td>
                <td>{leave.days}</td>
                <td>{leave.reason}</td>
                <td>{leave.status}</td>
                <td>
                  {leave.leaveType === 'sick' ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleVisibilityClick(leave.medicalcertificate)}
                      startIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                  ) : (
                    <span>None</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <br />

      <div className="Add_items_Purchase_Master">
        <span>Employees Permission Consumed Details</span>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Leave Type</th>
              <th id="slectbill_ins">From Time</th>
              <th id="slectbill_ins">To Time</th>
              <th id="slectbill_ins">Hours</th>
              <th id="slectbill_ins">Reason</th>
              <th id="slectbill_ins">Status</th>
              <th id="slectbill_ins">Reject Reason</th>

            </tr>
          </thead>
          <tbody>
            {/* {console.log(filteredRows1)} */}
            {rows1.map((leave, index) => (
              <tr key={index}>
                <td>{leave.employeeid}</td>
                <td>{leave.employeename}</td>
                <td>{leave.leaveType}</td>
                <td>{leave.fromtime}</td>
                <td>{leave.totime}</td>
                <td>{leave.hours}</td>
                <td>{leave.reason}</td>
                <td>{leave.status}</td>
                <td>{leave.rejectstatus}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>



      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
            <div className="pdf_img_show">
              {modalContent && modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
                <iframe
                  title="PDF Viewer"
                  src={modalContent}
                  style={{
                    width: "100%",
                    height: "435px",
                    border: "1px solid rgba(0, 0, 0, 0.5)",
                  }}
                />
              ) : (
                <img
                  src={modalContent}
                  alt="LabImage"
                  style={{
                    width: "80%",
                    height: "75%",
                    marginTop: "20px",
                  }}
                />
              )}
              <div className="jhuhhjh">
                <Button
                  style={{ color: "white" }}
                  className="clse_pdf_img"
                  onClick={closeModal}
                >
                  <HighlightOffIcon
                    style={{
                      fontSize: "40px",
                      backgroundColor: "hsl(33, 100%, 50%)",
                      borderRadius: "40px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default LeaveMangement;
