

import React, { useState, useEffect } from 'react';
// import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';

import axios from 'axios';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const LeaveConsume = ({ userRecord }) => {


  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [leaveStatusrows, setLeaveStatusRows] = useState([]);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(`${urllink}HRmanagement/get_all_leave_register_by_employeeid?EmployeeID=${employeeid}&location=${userRecord.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            EmployeeID: userdata.EmployeeID,
            EmployeeName: userdata.EmployeeName,
            LeaveType: userdata.LeaveType,
            FromDate: userdata.FromDate,
            ToDate: userdata.ToDate,
            days: userdata.days,
            Reason: userdata.Reason,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus,
          }));
          setLeaveStatusRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);







  return (
    <div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Leave Type</th>
              <th id="slectbill_ins">From Date</th>
              <th id="slectbill_ins">To Date</th>
              <th id="slectbill_ins">Days</th>
              <th id="slectbill_ins">Reason</th>
              <th id="slectbill_ins">Status</th>
              <th id="slectbill_ins">Reject Reason</th>
            </tr>
          </thead>
          <tbody>
            {leaveStatusrows.length > 0 && leaveStatusrows.map((leave, index) => (
              <tr key={index}>
                <td>{leave.EmployeeID}</td>
                <td>{leave.EmployeeName}</td>
                <td>{leave.LeaveType}</td>
                <td>{leave.FromDate}</td>
                <td>{leave.ToDate}</td>
                <td>{leave.days}</td>
                <td>{leave.Reason}</td>
                <td>{leave.status}</td>
                <td>{leave.rejectstatus}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>

    </div>
  );
};

export default LeaveConsume;
