
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

function AntibioticMaster() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [antibioticData, setAntibioticData] = useState([]);
  const [antibioticGroupCode, setAntibioticGroupCode] = useState('');
  const [antibioticDes, setAntibioticDes] = useState('');
  const [antibioticCode, setAntibioticCode] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);

  const handleSubmitAntibiotic = async () => {
    if (!antibioticGroupCode.trim() || !antibioticCode.trim() || !antibioticDes.trim()) {
      alert(' AntibioticGroupCode,AntibioticCode and AntibioticDes are required.');
      return; // Exit the function early if validation fails
    }
    try {

      const response = await axios.post(`${urllink}usercontrol/insertantibioticdata`, {
        antibioticGroupCode,
        antibioticCode,
        antibioticDes
      });

      console.log(response.data);
      setAntibioticGroupCode('');
      setAntibioticCode('');
      setAntibioticDes('');
      fetchAntibioticData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchAntibioticData = () => {
    axios.get(`${urllink}usercontrol/getantibioticdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setAntibioticData(data);
      })
      .catch((error) => {
        console.error('Error fetching antibioticgroup data:', error);
        setAntibioticData([]); // Reset data in case of an error
      });
  };

  const handleEdit = (row) => {
    console.log(row)
    setAntibioticGroupCode(row.anti_biotic_group_code);
    setAntibioticCode(row.anti_biotic_code);
    setAntibioticDes(row.anti_biotic_des);
    setIsEditMode(true);
    setSelectedMethodId(row.anti_biotic_group_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(`${urllink}usercontrol/updateantibioticmaster`, {
        method_id: selectedMethodId,
        method_name: antibioticGroupCode,
        method_code:antibioticCode,
        method_des: antibioticDes
      });

      console.log(response.data);
      setAntibioticGroupCode('');
      setAntibioticCode('');
      setAntibioticDes('');
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchAntibioticData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    fetchAntibioticData();
  }, []);

  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Antibiotic Master</h3>
          </div>
        </div>
        <div className="ShiftClosing_Container">
          <div className='FirstpartOFExpensesMaster'>
            <h2 style={{ textAlign: 'center' }}>Antibiotic Master</h2>

            <div className="con_1">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Antibiotic Group Code:</label>
                <input
                  type="text"
                  id="antibioticGroupCode"
                  name="antibioticGroupCode"
                  value={antibioticGroupCode}
                  onChange={(e) => setAntibioticGroupCode(e.target.value)}
                  placeholder="Enter Antibiotic Group Code"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Antibiotic Code:</label>
                <input
                  type="text"
                  id="antibioticCode"
                  name="antibioticCode"
                  value={antibioticCode}
                  onChange={(e) => setAntibioticCode(e.target.value)}
                  placeholder="Enter Antibiotic Code"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Antibiotic Description:</label>
                <input
                  type="text"
                  id="antibioticDes"
                  name="antibioticDes"
                  value={antibioticDes}
                  onChange={(e) => setAntibioticDes(e.target.value)}
                  placeholder="Enter Antibiotic Description"
                  style={{ width: "150px" }}
                />
              </div>
              <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitAntibiotic}>
                {isEditMode ? 'Update' : <AddIcon />}
              </button>
            </div>

            <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
              <h4>Table</h4>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Antibiotic Group Code</th>
                      <th>Antibiotic Code</th>
                      <th>Antibiotic Description</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(antibioticData) && antibioticData.length > 0 ? (
                      antibioticData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.anti_biotic_group_id}</td>
                          <td>{row.anti_biotic_group_code}</td>
                          <td>{row.anti_biotic_code}</td>
                          <td>{row.anti_biotic_des}</td>
                          <td><button onClick={() => handleEdit(row)}><EditIcon /></button></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AntibioticMaster;

