import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {  TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from 'react-router-dom'
import { colors } from '@mui/material';
import { red } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { TbDelta } from "react-icons/tb";
import { LineChart } from '@mui/x-charts/LineChart';

function Reportverify({ userRecord, isSidebarOpen }) {
  const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [visitids, setvisitids] = useState([])

  const [value, setvalue] = useState([])
  const [openModel, setOpenModel]= useState(false)
  const [visitid, setvisitid] = useState()
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log('capturedatas', capturedatas)

  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const dispatchvalue = useDispatch()

  const navigate = useNavigate()


  useEffect(() => {
    axios.get(`${urllink}Billing/getcompletedsample?Billinginvoice=${capturedatas.Billing_Invoice}`)
      .then((response) => {
        console.log(response.data)
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testcode: item.Test_Code,
          testname: item.Test_Name,
          testnames: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.PanicLow,
          panichigh: item.PanicHigh,
          patient_name:capturedatas.Patient_Name,
          Billing_Invoice: capturedatas.Billing_Invoice,
          barcodedata : capturedatas.Barcode_id,
          patient_id : capturedatas.Patient_Id,
          uom : item.UOM,
          capturevalue : '',
          medicalremark: '',
          technicalremark: ''

          
          // You don't need to explicitly include the 'actions' field here
        }));
        setFilteredRows(data);
        // setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas.Billing_Invoice]);


  const hasRecollect = testDetails.some(detail => detail.panichigh <= detail.Capture_Value);
  



  const handleStatusChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handlemedicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].medicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };
  
  const handletecnicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].technicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };

  console.log(filteredRows)

  useEffect(()=>{
    axios.get(`${urllink}Billing/getforvisitid?patientphn=${capturedatas.Phone}&patientname=${capturedatas.Patient_Name}`)
        .then((response) => {
          console.log(response.data)
          const data = response.data.nextVisitId
          setvisitid(data)
        })
        .catch((error) => {
          console.log(error)
        })
  })
  
  useEffect(() => {
  
    axios.get(`${urllink}Phelobotomist/get_record_from_lab_techy?Billinginvoice=${capturedatas.Billing_Invoice}&Visitid=${capturedatas.Visit_Id}`)
      .then((response) => {
        console.log(response.data)
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          // testcode: item.Test_Code,
          testname: item.Test,
          // testnames: item.Test_Name,
          Capture_Value: item.Capture_Value,
          Status: item.Status,
          Sample_Specimen: item.Sample_Specimen,
          Patient_Name : capturedatas.Patient_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.Panic_Low,
          panichigh: item.Panic_High,
          Medical_Remark: item.Medical_Remark,
          Technical_Remark: item.Technical_Remark,
          patient_id : capturedatas.Patient_Id,
          Billing_Invoice: item.Billing_invoice,
          visitid : capturedatas.Visit_Id,
          status : ''

          // You don't need to explicitly include the 'actions' field here
        }));
        // setFilteredRows(data);
        setTestDetails(data)
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas]);

  const handleverifydata = () => {
    // Separate the testDetails into groups based on their status
    const recollectTests = testDetails.filter(detail => detail.status === 'Recollect');
    const retestTests = testDetails.filter(detail => detail.status === 'Retest');
    const verifiedTests = testDetails.filter(detail => detail.status === 'Verified');
  
    // Define a function for handling API calls (for demonstration)
    const performApiCall = (url, data) => {
      return axios.post(url, data)
        .then(response => {
          console.log(response);
          alert(response.data.message);
          navigate('/Home/VerifyReportEntry')
        })
        .catch(error => {
          console.error(error);
        });
    };
  

    console.log(retestTests);

    // Process Recollect Tests
    if (recollectTests.length > 0) {
      performApiCall(`${urllink}Phelobotomist/seniorsampleaprove`, recollectTests );
    }
  
    // Process Retest Tests
    if (retestTests.length > 0) {
      performApiCall(`${urllink}Phelobotomist/updateretestforsenior`, retestTests );
    }
  
    // Process Verified Tests
    if (verifiedTests.length > 0) {
      performApiCall( `${urllink}Phelobotomist/insertseniorverifyreport`, verifiedTests );
    }
  
    // Optional: Navigation or state updates after all operations
    // You may need to adjust based on your application's flow
    // dispatchvalue({ "type": "Navigationlab", value: "Reportentry" });
  };
  
  

  const handletrendview =(testname)=>{
    axios.get(`${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`)
    .then((response)=>{
      console.log(response.data)
      const value = response.data.data
      const visit =response.data.visit
      setvalue(value)
      setvisitids(visit)
      setOpenModel(true)
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  



  return (
    <>
      <div className="samplecapture_component">
        <div className="samplecapture_table_for_phelobotomist">
          <table className='table_for_plebotomist_sample'>
            <thead>
              <tr>
                <th>Test Name</th>
                <th>Specimen</th>
                <th>Capture Value</th>
                <th>Reference Range</th>
                <th>Indicator</th>
                <th>Remarks
                  <table className='remarktable'>
                    <tr>
                      <th>Technical</th>
                      <th>Medical</th>
                    </tr>
                  </table>
                </th>
                <th>
                    Status
                </th>
                <th>
                    Verification
                </th>
                {/* {
                    !hasRecollect?(
                    <th>Reflex</th>
                    ):null
                 }
                */}
                <th>
                    Trends
                </th>
                
              </tr>
            </thead>

            <tbody>
              {testDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.testname}</td>
                  <td>
                    {detail.Sample_Specimen}
                  </td>
                  <td>
                    {detail.Capture_Value}
                  </td>
                  <td>
                    {detail.paniclow}-{detail.panichigh}{detail.uom}
                  </td>
                  <td>
                  {Number(detail.Capture_Value)>=( detail.panichigh)? (<span className='indicator'><TiArrowUpThick style={{color: red}}/></span>): Number(detail.capturevalue)<=(detail.paniclow)?(<span className='indicatorlow'> <TiArrowDownThick /></span>): <p>Normal</p>}
                  </td>
                  <td>
                  <table className='remarktable'>
                      <tr>
                        <td>
                          {detail.Technical_Remark}
                        </td>
                        <td>
                        {detail.Medical_Remark}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    {detail.Status}
                  </td>
                  <td>
                  <select
                      name="status"
                      id="status"
                      className='Capture_Status_select'
                      value={detail.status}
                      onChange={(e) => handleStatusChange(index, e.target.value, )}
                    >
                      <option value="">Select Status</option>
                      <option value="Verified">Verified</option>
                      <option value="Retest">Retest</option>
                      <option value="Recollect">Recollect</option>
                      
                    </select>
                  </td>
                  <td>
                  <span className='trends' onClick={()=>handletrendview(detail.testname)}><TbDelta/></span>
                  </td>
                 {/* {
                    !(detail.panichigh)<=(detail.Capture_Value)?(
                    <td>
                        <select
                      name="status"
                      id="status"
                      className='Capture_Status_select'
                      value={detail.status}
                      onChange={(e) => handleRemarksChange(index, e.target.value)}
                    >
                      <option value="">Select Reflex</option>
                      <option value="Approved">Test1</option>
                      <option value="Recollect">test2</option>
                    </select>
                    </td>
                    ):null
                 } */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleverifydata}>
            Save
          </button>
        </div>
      </div>

      
      {openModel && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModel(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                <div className="">
                <LineChart
      xAxis={[{ data: [...value] }]}
      xAxisLabel= 'Value'
      yAxisLabel = 'Visits'
      series={[
        {
          data: [...visitids],
        },
      ]}
      width={500}
      height={300}
    />
                </div>
       
                <div className="Register_btn_con regster_btn_contsai">
                
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModel(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
    </>
  );
}

export default Reportverify;



