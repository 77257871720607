import React, { useEffect, useState } from "react";
import "./TestMaster.css";
import axios from "axios";
import { useSelector } from "react-redux";

function TestMaster() {
  const [highlight, setHighlight] = useState(false);
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  // const [radio, setRadio] = useState();
  const [roleData, setRoleData] = useState([]);
  const [testDesData, setTestDesData] = useState([]);
  const [containerdata, setContainerData] = useState([]);
  const [methoddata, setMethodData] = useState([]);
  const [unitdata, setUnitData] = useState([]);
  const [specimendata, setSpecimenData] = useState([]);
  const [subDep,setSetDep] = useState([]);

  const testdata = useSelector((state) => state.userRecord?.TestMasterData);

  const [testMasterData, setTestMasterData] = useState({
    testName: "",
    testCode: "",
    department: "",
    subdepartment: "",
    header: "",
    displayText: "",
    billingName: "",
    container: "",
    specimen: "",
    method: "",
    gender: "",
    inputType: "",
    decimalPlaces: "",
    inputPatternType: "",
    testCategory: "",
    logicalCategory: "",
    capturedUnit: "",
    uom: "",
    reportType: "",
    testInstruction: "",
    loincCode: "",
    highlight: "No",
    allowDiscount: "No",
    orderable: "No",
    showGraph: "No",
    active: "No",
    stat: "No",
    nonReportable: "No",
    calculatedTest: "No",
    isOutsourced: "No",
    minimumtime: "",
    Emergencytime: "",
    timeperiod: "",
    isEditMode: "false",
    formula : "",
    paraone : "",
    paratwo : ""
  });

  useEffect(() => {
    if (testdata) {
      setTestMasterData((prevData) => ({
        ...prevData,
        testName: testdata.Test_Name,
        testCode: testdata.Test_Code,
        department: testdata.Department,
        subdepartment: testdata.Sub_Department,
        header: testdata.Header,
        displayText: testdata.Display_Text,
        billingName: testdata.Billing_Name,
        container: testdata.Container_Name,
        specimen: testdata.Specimen_Name,
        method: testdata.Method_Name,
        gender: testdata.Gender,
        inputType: testdata.Input_Type,
        decimalPlaces: testdata.Decimal_Places,
        inputPatternType: testdata.Input_Pattern_Type,
        testCategory: testdata.Test_Category,
        logicalCategory: testdata.Logical_Category,
        capturedUnit: testdata.Captured_Unit,
        uom: testdata.UOM,
        reportType: testdata.Report_Type,
        testInstruction: testdata.Test_Instructions,
        loincCode: testdata.Loinc_Code,
        highlight: testdata.Highlight,
        allowDiscount: testdata.Allow_Discount,
        orderable: testdata.Orderable,
        showGraph: testdata.Show_Graph,
        active: testdata.Active_Status,
        stat: testdata.STAT,
        nonReportable: testdata.Non_Reportable,
        calculatedTest: testdata.Calculated_Test,
        isOutsourced: testdata.Outsourced,
        minimumtime: testdata.Processingtime,
        Emergencytime: testdata.emergency,
        timeperiod: testdata.processperiod,
        isEditMode: testdata.isEditMode,
        test_id: testdata.id,
      }));
    }
  }, [testdata]);

  console.log("testMasterData:", testMasterData);
  console.log("agesetupdata:", testdata);
  const [hide, setHide] = useState(false);
  const [value, setValue] = useState("1");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTestMasterData({
      ...testMasterData,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    });
  };

  const handleBoxChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/getdepartmentdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setRoleData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
    axios
    .get(`${urllink}mainddepartment/getsubdepartment`)
    .then((response) => {
      const data = response.data;
      console.log("data", data);

      setSetDep(data);
    })
    .catch((error) => {
      console.error("Error fetching SubDepartment data:", error);
    });

      

    axios
      .get(`${urllink}mainddepartment/getcontainer`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setContainerData(data);
      })
      .catch((error) => {
        console.error("Error fetching container data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getmethod`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setMethodData(data);
      })
      .catch((error) => {
        console.error("Error fetching method data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getunit`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setUnitData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });

    axios
      .get(`${urllink}mainddepartment/getspecimen`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setSpecimenData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, []);

  const handleSubmittestData = () => {
    // Assuming all fields are required and should not be empty
    // Adjust the condition based on actual requirements
    const allFieldsFilled = Object.values(testMasterData).every(
      (value) => value == ""
    );

    if (allFieldsFilled) {
      alert("Please fill out all fields.");
      return; // Stop the submission since not all fields are filled
    } else {
      // If all fields are filled, proceed with the submission
      axios
        .post(
          `${urllink}usercontrol/inserttestmaster`,
          testMasterData
        )
        .then((response) => {
          console.log(response);
          setHide(true);
          setTestMasterData({
            testName: "",
            testCode: "",
            department: "",
            subdepartment: "",
            header: "",
            displayText: "",
            billingName: "",
            container: "",
            specimen: "",
            method: "",
            gender: "",
            inputType: "",
            decimalPlaces: "",
            inputPatternType: "",
            testCategory: "",
            logicalCategory: "",
            capturedUnit: "",
            uom: "",
            reportType: "",
            testInstruction: "",
            loincCode: "",
            highlight: "No",
            allowDiscount: "No",
            orderable: "No",
            showGraph: "No",
            active: "No",
            stat: "No",
            nonReportable: "No",
            calculatedTest: "No",
            isOutsourced: "No",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    fetchTestDescriptionData();
  }, []);
  const fetchTestDescriptionData = () => {
    axios
      .get("${urllink}usercontrol/gettestdescriptiondata")
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setTestDesData(data);
      })
      .catch((error) => {
        console.error("Error fetching testdescription data:", error);
      });
  };
  const handleTestdata = (testData) => {
    const updatedata = {
      ...testMasterData,
    };
    console.log(updatedata);
    axios
      .post(
        "${urllink}usercontrol/update_test_description_data",
        updatedata
      )
      .then((response) => {
        console.log(response.data);
        setTestMasterData({
          testName: "",
          testCode: "",
          department: "",
          subdepartment: "",
          header: "",
          displayText: "",
          billingName: "",
          container: "",
          specimen: "",
          method: "",
          gender: "",
          inputType: "",
          decimalPlaces: "",
          inputPatternType: "",
          testCategory: "",
          logicalCategory: "",
          capturedUnit: "",
          uom: "",
          reportType: "",
          testInstruction: "",
          loincCode: "",
          highlight: "No",
          allowDiscount: "No",
          orderable: "No",
          showGraph: "No",
          active: "No",
          stat: "No",
          nonReportable: "No",
          calculatedTest: "No",
          isOutsourced: "No",
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Test Master</h4>
      </div>

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="testName">
            Test Name<span>:</span>
          </label>
          <input
            type="text"
            id="testName"
            name="testName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter Test Name"
            value={testMasterData.testName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="testCode">
            Test Code<span>:</span>
          </label>
          <input
            type="text"
            id="testCode"
            name="testCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter Test Code"
            value={testMasterData.testCode}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="department">
            Department<span>:</span>
          </label>
          <select
            id="department"
            name="department"
            value={testMasterData.department}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            {roleData.map((role, index) => (
              <option key={index} value={role.department_name}>
                {role.department_name}
              </option>
            ))}
          </select>
        </div>
 
        <div className="RegisForm_1">
          <label htmlFor="department">
            Sub Department<span>:</span>
          </label>
          <select
            id="subdepartment"
            name="subdepartment"
            value={testMasterData.subdepartment}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            {subDep.map((role, index) => (
              <option key={index} value={role.subdepartment_name}>
                {role.subdepartment_name}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="header">
            Header<span>:</span>
          </label>
          <input
            type="text"
            id="header"
            name="header"
            pattern="[A-Za-z ]+"
            onChange={handleInputChange}
            value={testMasterData.header}
            title="Only letters and spaces are allowed"
            // placeholder="Enter Header"
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="displayText">
            Display Text<span>:</span>
          </label>
          <input
            type="text"
            id="displayText"
            onChange={handleInputChange}
            name="displayText"
            pattern="[A-Za-z ]+"
            value={testMasterData.displayText}
            title="Only letters and spaces are allowed"
            // placeholder="Enter Display Text"
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="billingName">
            Billing Name<span>:</span>
          </label>
          <input
            type="text"
            id="billingName"
            onChange={handleInputChange}
            name="billingName"
            value={testMasterData.billingName}
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter Billing Name"
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="container">
            Container<span>:</span>
          </label>
          <select
            id="container"
            onChange={handleInputChange}
            name="container"
            value={testMasterData.container}
          >
            <option value="">Select</option>
            {containerdata.map((row, index) => (
              <option key={index} value={row.container_name}>
                {row.container_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="specimen">
            Specimen<span>:</span>
          </label>
          <select
            id="specimen"
            value={testMasterData.specimen}
            onChange={handleInputChange}
            name="specimen"
          >
            <option value="">Select</option>
            {specimendata.map((row, index) => (
              <option key={index} value={row.specimen_name}>
                {row.specimen_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="method">
            Method<span>:</span>
          </label>
          <select
            id="method"
            onChange={handleInputChange}
            value={testMasterData.method}
            name="method"
          >
            <option value="">Select</option>
            {methoddata.map((row, index) => (
              <option key={index} value={row.method_name}>
                {row.method_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="gender">
            Gender<span>:</span>
          </label>
          <select
            id="gender"
            onChange={handleInputChange}
            name="gender"
            value={testMasterData.gender}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Both">Both</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="inputType">
            Input Type<span>:</span>
          </label>
          <select
            id="inputType"
            onChange={handleInputChange}
            name="inputType"
            value={testMasterData.inputType}
          >
            <option value="">Select</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="decimalPlaces">
            Decimal Places<span>:</span>
          </label>
          <input
            type="number"
            id="decimalPlaces"
            onChange={handleInputChange}
            name="decimalPlaces"
            // placeholder="Enter Decimal Places"
            value={testMasterData.decimalPlaces}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="inputPatternType">
            Minimum Processing Time<span>:</span>
          </label>
          <input
            type="number"
            id="minimumtime"
            name="minimumtime"
            // placeholder="Enter Minimum Processing Time"
            // className="new_clinic_form_inp"
            required
            value={testMasterData.minimumtime}
            onChange={handleInputChange}
          />
        </div>


        <div className="RegisForm_1">
          <label htmlFor="Emergencytime">
            Emergency Processing Time<span>:</span>
          </label>
          <input
            type="number"
            id="Emergencytime"
            name="Emergencytime"
            // placeholder="Enter Emergency Processing Time"
            // className="new_clinic_form_inp"
            required
            value={testMasterData.Emergencytime}
            onChange={handleInputChange}
          />
        </div>


         <div className="RegisForm_1">
          <label htmlFor="logicalCategory" >Time Period<span>:</span></label>
          <select id="timeperiod" onChange={handleInputChange} name="timeperiod" value={testMasterData.timeperiod}>

              <option value="">Select</option>
              <option value="Hours">Hours</option>
              <option value="Minute">Minute</option>
          </select>
        </div> 

       

        
         <div className="RegisForm_1">
          <label htmlFor="inputPatternType">
            Input Pattern Type<span>:</span>
          </label>
          <select
            id="inputPatternType"
            onChange={handleInputChange}
            name="inputPatternType"
            value={testMasterData.inputPatternType}
          >
            <option value="">Select</option>
            <option value="Numeric">Numeric</option>
            <option value="AlphaNumeric">Alpha Numeric</option>
            <option value="Symbol">Symbol</option>
          </select>
        </div> 

        <div className="RegisForm_1">
          <label htmlFor="testCategory">
            Test Category<span>:</span>
          </label>
          <select
            id="testCategory"
            onChange={handleInputChange}
            name="testCategory"
            value={testMasterData.testCategory}
          >
            <option value="">Select</option>
            <option value="Special">Special</option>
            <option value="STAT">STAT</option>
            <option value="General">General</option>
            <option value="Routine">Routine</option>
          </select>
        </div>
       

         <div className="RegisForm_1">
          <label htmlFor="logicalCategory">
            Logical Category<span>:</span>
          </label>
          <select
            id="logicalCategory"
            onChange={handleInputChange}
            name="logicalCategory"
            value={testMasterData.logicalCategory}
          >
            <option value="">Select</option>
            <option value="Lab">Lab</option>
            <option value="Imaging">Imaging</option>
            <option value="UltraSound">UltraSound</option>
            <option value="Xray">X-Ray</option>
            <option value="Bone">Bone</option>
            <option value="Blood">Blood</option>
          </select>
        </div>
      
          <div className="RegisForm_1">
          <label htmlFor="capturedUnit">
            Captured Unit<span>:</span>
          </label>
          <input
            type="text"
            id="capturedUnit"
            name="capturedUnit"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter Captured Unit"
            onChange={handleInputChange}
            value={testMasterData.capturedUnit}
            required
          />
        </div>
          <div className="RegisForm_1">
          <label htmlFor="uom">
            UOM<span>:</span>
          </label>
          <select
            id="uom"
            onChange={handleInputChange}
            name="uom"
            value={testMasterData.uom}
          >
            <option value="">Select</option>
            {unitdata.map((row, index) => (
              <option key={index} value={row.unit_name}>
                {row.unit_name}
              </option>
            ))}
          </select>
        </div>

      

       
          <div className="RegisForm_1">
          <label htmlFor="reportType">
            Report<span>:</span>
          </label>
          <select
            id="reportType"
            onChange={handleInputChange}
            name="reportType"
            value={testMasterData.reportType}
          >
            <option value="">Select</option>
            <option value=" Cloud-Based"> Cloud-Based</option>
            <option value="ms">Ms.</option>
            <option value="mrs">Mrs.</option>
          </select>
        </div>
         <div className="RegisForm_1">
          <label htmlFor="testInstruction">
            Test Instruction<span>:</span>
          </label>
          <textarea
            id="testInstruction"
            onChange={handleInputChange}
            name="testInstruction"
            value={testMasterData.testInstruction}
          ></textarea>
        </div>

       
       
         <div className="RegisForm_1">
          <label htmlFor="loincCode">
            Loinc Code<span>:</span>
          </label>
          <input
            type="text"
            id="loincCode"
            onChange={handleInputChange}
            value={testMasterData.loincCode}
            name="loincCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // placeholder="Enter Loinc Code"
            required
          />
        </div>

        
         <div className="RegisForm_1">
          <label htmlFor="highlight">
            Highlight<span>:</span>
          </label>
          <input
            type="checkbox"
            id="highlight"
            className="custom-highlight-toggle"
            checked={highlight}
            value={testMasterData.highlight}
            onChange={() => setHighlight(!highlight)}
          />
        </div>

       
        <div className="RegisForm_1">
          <label htmlFor="allowDiscount">
            Allow Discount<span>:</span>
          </label>
          <input
            type="checkbox"
            id="allowDiscount"
            onChange={handleInputChange}
            className="chk_box_23"
            name="allowDiscount"
            value={testMasterData.allowDiscount}
          />
        </div>
         <div className="RegisForm_1">
          <label htmlFor="orderable">
            Orderable<span>:</span>
          </label>
          <input
            type="checkbox"
            id="orderable"
            onChange={handleInputChange}
            className="chk_box_23"
            name="orderable"
            value={testMasterData.orderable}
          />
        </div>
       

         <div className="RegisForm_1">
          <label htmlFor="showGraph">
            Show Graph<span>:</span>
          </label>
          <input
            type="checkbox"
            id="showGraph"
            onChange={handleInputChange}
            className="chk_box_23"
            name="showGraph"
            value={testMasterData.showGraph}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="stat">
            STAT<span>:</span>
          </label>
          <input
            type="checkbox"
            id="stat"
            onChange={handleInputChange}
            className="chk_box_23"
            name="stat"
            value={testMasterData.stat}
          />
        </div>
       
        <div className="RegisForm_1">
          <label htmlFor="active">
            Active<span>:</span>
          </label>
          <input
            type="checkbox"
            id="active"
            onChange={handleInputChange}
            className="chk_box_23"
            name="active"
            value={testMasterData.active}
          />
        </div>

      <div className="RegisForm_1">
          <label htmlFor="nonReportable">
            Non-reportable<span>:</span>
          </label>
          <input
            type="checkbox"
            id="nonReportable"
            onChange={handleInputChange}
            className="chk_box_23"
            name="nonReportable"
            value={testMasterData.nonReportable}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="isOutsourced">
            Is Outsourced<span>:</span>
          </label>
          <input
            type="checkbox"
            id="isOutsourced"
            onChange={handleInputChange}
            className="chk_box_23"
            name="isOutsourced"
            value={testMasterData.isOutsourced}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="calculatedTest">
            Calculated Test<span>:</span>
          </label>
          <input
            type="checkbox"
            id="calculatedTest"
            onChange={handleInputChange}
            className="chk_box_23"
            name="calculatedTest"
            value={testMasterData.calculatedTest}
          />
        </div>
      
      {testMasterData.calculatedTest==="Yes" ?
      <>
         <div className="RegisForm_1">
         <label htmlFor="calculatedTest">
           Calculated Formula<span>:</span>
         </label>
         <input
           type="text"
           id="formula"
           onChange={handleInputChange}
          //  className="chk_box_23"
           name="formula"
           value={testMasterData.formula}
          //  value={testMasterData.calculatedTest} 

         />
       </div>
       <div className="RegisForm_1">
       <label htmlFor="calculatedTest">
         Parameter one<span>:</span>
       </label>
       <input
         type="text"
         id="formula"
         onChange={handleInputChange}
        //  className="chk_box_23"
         name="paraone"
         value={testMasterData.paraone}
        //  value={testMasterData.calculatedTest} 

       />
     </div>
     <div className="RegisForm_1">
       <label htmlFor="calculatedTest">
         Parameter two<span>:</span>
       </label>
       <input
         type="text"
         id="formula"
         onChange={handleInputChange}
        //  className="chk_box_23"
         name="paratwo"
         value={testMasterData.paratwo}
        //  value={testMasterData.calculatedTest} 

       />
     </div>
     </>
       : null }



      </div>

     
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            onClick={
              testMasterData.isEditMode ? handleTestdata : handleSubmittestData
            }
          >
            {testMasterData.isEditMode ? "Update" : "Submit"}
          </button>
        </div>

    </div>
  );
}

export default TestMaster;
