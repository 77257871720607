import React, { useState, useEffect } from "react";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

import bgImg2 from "../../assets/bgImg2.jpg";
import OtDoctorPre from "./OtDoctorPre";
import OtDoctorIntra from "./OtDoctorIntra";
import OtDoctorPost from "./OtDoctorPost";
import OtLabour from "./OtLabour";
import OtCataract from "./OtCataract";
import OtDcr from "./OtDcr";
import Otterigium from "./Otterigium";
import { useSelector } from "react-redux";


function OtDoctorNavigation() {
  const [activeTab, setActiveTab] = useState("LabourDeliveryRecord");
  const [isToggled, setIsToggled] = useState(false);
  const OTDoctorNavigationData = useSelector((state) => state.userRecord?.OTDoctornavigationData);
  console.log(OTDoctorNavigationData)

  const toggle = () => setIsToggled(!isToggled);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  return (
    <>
      <br />
      <div className="new-patient-registration-form">

        <div className="dctr_info_up_head">
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
              <img src={OTDoctorNavigationData?.Patient_Photo} alt="Default Patient Photo" />
              <label>Profile</label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData?.Patient_Name}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData?.PatientID}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData.Age}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Blood Group <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData.BloodGroup}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData.Gender}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Primary Doctor <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {OTDoctorNavigationData.DoctorName}{" "}
              </span>
            </div>
          </div>
        </div>
        <br />


        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("OtDoctorPre")}>
                Pre-Operative Checklist
              </button>
              |
              <button onClick={() => handleTabChange("OtDoctorIntra")}>
                Intra-Operative Checklist
              </button>
              |
              <button onClick={() => handleTabChange("OtDoctorPost")}>
                Post-Operative Checklist
              </button>
              |
              <button onClick={() => handleTabChange("LabourDeliveryRecord")}>
                Labour & Delivery Record
              </button>
              |
              <button onClick={() => handleTabChange("OtCataract")}>
                Cataract
              </button>
              |
              <button onClick={() => handleTabChange("OtDcr")}>
                DCR
              </button>
              |
              <button onClick={() => handleTabChange("Otterigium")}>
                Terigium
              </button>
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("OtDoctorPre")}>
                      Pre-Operative Checklist
                    </button>
                    |
                    <button onClick={() => handleTabChange("OtDoctorIntra")}>
                      Intra-Operative Checklist
                    </button>
                    |
                    <button onClick={() => handleTabChange("OtDoctorPost")}>
                      Post-Operative Checklist
                    </button>
                    |
                    <button onClick={() => handleTabChange("LabourDeliveryRecord")}>
                      Labour & Delivery Record
                    </button>
                    |
                    <button onClick={() => handleTabChange("OtCataract")}>
                      Cataract
                    </button>
                    |
                    <button onClick={() => handleTabChange("OtDcr")}>
                      DCR
                    </button>
                    |
                    <button onClick={() => handleTabChange("Otterigium")}>
                      Terigium
                    </button>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "OtDoctorPre" && <OtDoctorPre />}
      {activeTab === "OtDoctorIntra" && <OtDoctorIntra />}
      {activeTab === "OtDoctorPost" && <OtDoctorPost />}
      {activeTab === "LabourDeliveryRecord" && <OtLabour />}
      {activeTab === "OtCataract" && <OtCataract />}

      {activeTab === "OtDcr" && <OtDcr />}
      {activeTab === "Otterigium" && <Otterigium />}
    </>
  );
}

export default OtDoctorNavigation;
