import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from '@mui/material/Button';
import "../IPNurseflow/IpNurseVitals.css";
import EditIcon from '@mui/icons-material/Edit';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function FrequencyMaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

  const [page, setPage] = useState(0);
  const [summa, setSumma] = useState([]);
  const [getdatastate, setGetDataState] = useState(false);
  const [editrow,seteditrow]=useState(null)
  const [VitalFormData, setVitalFormData] = useState({
    FrequencyType: "",
    Frequency: "",
    FrequencyTime: "",
  });

  
  const pageSize = 10;
  const totalPages = Math.ceil(summa.length / 10);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVitalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+"].includes(e.key) && e.preventDefault();

    const timeOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const [selectedTimes, setSelectedTimes] = useState([]);

  const toggleTimeSelection = (time) => {
    
    if (!VitalFormData.Frequency) return;
    const maxSelections = VitalFormData.Frequency.split("-").reduce(
      (acc, val) => acc + parseInt(val),
      0
    );

    if (selectedTimes.includes(time)) {
      const timeData = selectedTimes.filter((t) => t !== time);
      setSelectedTimes(timeData);
      setVitalFormData((prev) => ({
        ...prev,
        FrequencyTime: timeData.sort((a, b) => +a - +b).join(","),
      }));
    } else {
      if (selectedTimes.length < maxSelections) {
        const timeData = [...selectedTimes, time];
        setSelectedTimes(timeData);
        setVitalFormData((prev) => ({
          ...prev,
          FrequencyTime: timeData.sort((a, b) => +a - +b).join(","),
        }));
      }
    }
  };
  const handleeditrow=(params)=>{
    const dataaa= params.row
    setSelectedTimes([])
    setVitalFormData({
      FrequencyType:dataaa.FrequencyType ,
    Frequency: dataaa.Frequency ,
    FrequencyTime:dataaa.FrequencyTime  ,
    })
    seteditrow(dataaa.id)
    const splitedtime=dataaa.FrequencyTime.split(',')
  
    setSelectedTimes(splitedtime)
  }

  const handleeditstatus=(params)=>{
    
    const AllSendData = {
      FrequencyType:params.row.FrequencyType ,
      Frequency: params.row.Frequency ,
      FrequencyTime:params.row.FrequencyTime  ,
      Frequency_Id:params.row.id,
      Status:params.row.Status==='Active'?'InActive':'Active',
      Location: userRecord?.location,
      CapturedBy: userRecord?.username,
    };
    axios
      .post(
        `${UrlLink}ipregistration/update_frequency_masters`,
        AllSendData
      )
      .then((response) => {
        if(response.data?.message){
          alert(response.data?.message)
        }
        cleardata();
        setSelectedTimes([])
        setGetDataState(!getdatastate);
        seteditrow(null)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const formatLabel = (label) => {
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    } else {
      return label;
    }
  };
   const getTextWidth = (text) => {
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    const width = dummyElement.offsetWidth;

    document.body.removeChild(dummyElement);

    return width;
  };

  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...Object.keys(VitalFormData).map((labelname) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 70,
      };
    }),
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className='cell_btn'
           onClick={() => handleeditstatus(params)}
           >
            {params.value}
          </Button>
          </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <Button className='cell_btn'
           onClick={() => handleeditrow(params)}
           >
            <EditIcon  />
          </Button>
          </>
      ),
    },
  ];


  const cleardata = () => {
    setVitalFormData({
      FrequencyType: "",
      Frequency: "",
      FrequencyTime: "",
    });
  };
useEffect(()=>{
  axios.get(`${UrlLink}ipregistration/get_frequency_masters`)
  .then((response)=>{
    const data =response.data.map((p,index)=>({
      ...p,
      id:p.Frequency_Id
    }))
    setSumma(data)
  })
  .catch((error)=>{
    console.log(error)
  })
},[getdatastate])


  const PostVitalData = () => {
    const requiredFields = ["FrequencyType", "Frequency", "FrequencyTime"];
    const existing = requiredFields.filter((field) => !VitalFormData[field]);

    if (existing.length > 0) {
      alert("Please fill empty fields: " + existing.join(","));
    } else {

      const maxSelections = VitalFormData.Frequency.split("-").reduce(
        (acc, val) => acc + parseInt(val),
        0
      );
      const maxtime=VitalFormData.FrequencyTime.split(',').length
      if(maxSelections > maxtime){
        alert(`The selected frequency is ${maxSelections} but the selected time is ${maxtime}`)
      }else{
      
      const AllSendData = {
        ...VitalFormData,
        Frequency_Id:editrow,
        Status:'Active',
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `${UrlLink}ipregistration/${editrow?'update_frequency_masters':'insert_frequency_masters'}`,
          AllSendData
        )
        .then((response) => {
          if(response.data?.message){
            alert(response.data?.message)
          }
          cleardata();
          setSelectedTimes([])
          setGetDataState(!getdatastate);
          seteditrow(null)
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
  };

 

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="Total_input_container">
          {Object.keys(VitalFormData).map((labelname, index) => (
            <div className="inp_container_all_intakeoutput" key={index}>
              <label>
                {formatLabel(labelname)} <span>:</span>
              </label>
              {labelname === "FrequencyType" ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="BeforeFood">BeforeFood</option>
                  <option value="AfterFood">AfterFood</option>
                </select>
              ) : (
                <>
                  <input
                    type="text"
                    name={labelname}
                    readOnly={labelname === "FrequencyTime"}
                    // list={labelname === "Frequency" ? "frequencyList" : ""}
                    onKeyDown={blockInvalidChar}
                    value={VitalFormData[labelname]}
                    onChange={handleInputChange}
                  />
                  {/* {labelname === "Frequency" && (
                    <datalist id="frequencyList">
                      <option value="1-0-1"/>
                    </datalist>
                  )} */}
                </>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <span style={{ color: "grey", fontSize: "16px", fontWeight: "600" }}>
            Time Frequency
          </span>
          <div className="Timeselectorr">
            {timeOptions.map((time) => (
              
              <span
                key={time}
                className={
                  selectedTimes.includes(''+time) ? "Timeselected" : "Timeenabled"
                }
                onClick={() => toggleTimeSelection(""+time)}
              >
                {time}
              </span>
            ))}
          </div>
        </div>
        <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <button className="btn-add" onClick={PostVitalData}>
            {editrow?'Update':'Add'}
          </button>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)}
                columns={dynamicColumns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {summa.length > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {summa.length === 0 && (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
