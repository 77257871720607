import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
// import "../ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import '../../Emergency/Jeeva.css'


export default function EmergencyTriageClassification() {
    const dispatchvalue = useDispatch();
    const urllink = useSelector(state => state.userRecord?.UrlLink);

    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector(
        (state) => state.InPatients?.IpNurseQueSelectedRow
    );

    console.log("qqqqqqqqqqqqqqqqqqqqqqqqq", IpNurseQueSelectedRow);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [page, setPage] = useState(0);

    const [successMessage, setSuccessMessage] = useState("");
    const [summa, setSumma] = useState([]);
    const [renderFlag, setRenderFlag] = useState(false); // Define renderFlag state



    const [label, setLabel] = useState('')

    const [postdata, setPostdata] = useState('')



    //   const TriageFormData ={
    //     Resuscitation: "",
    //     Emergent: "",
    //     Urgent: "",
    //     LessUrgent: "",
    //     NonUrgent: "",
    //   };

    const [TriageFormData, setTriageFormData] = useState({

        Date: "",
        Time: "",
    });


    const [checkboxvalues, setCheckboxvalues] = useState([
        { Resuscitation: false, content: 'Threat to Life/Limb: Dr & Nurse Assessment- Immediate. Cardiac and respiratory arrest, major trauma, active seizure, shock, status asthmaticus' },
        { Emergent: false, content: 'Potential threat to Life, Limb, or function: Dr<15min, Nurse- Immediate. Decreased level of consciousness, severe respiratory distress, chest pain with cardiac suspicion, drug overdose, severe abdominal pain, GI Bleed with abnormal vital signs, chemical exposure to eye ' },
        { Urgent: false, content: 'Condition with significant distress: Dr<30 min, Nurse<20 min. Head injury without decrease of LOC but with vomiting, mild to moderate respiratory distress, Not a active GI Bleed, Acute psychosis' },
        { LessUrgent: false, content: 'Conditions with mild to moderate discomfort: Dr<1 hr, Nurse<1hr. Head injury-alert & no vomiting, chest-no distress, no cardiac suspicion,  depression with no suicidal attempt' },
        { NonUrgent: false, content: 'Conditions cannot be delayed, no distress: Dr & Nurse<2hr. Minor trauma, sore throat with temperature<39' },
    ])

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const [selectedCheckbox, setSelectedCheckbox] = useState(null);

    const [responseData, setResponseData] = useState([]);

    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    useEffect(() => {
        // Perform GET request to fetch selected checkbox data from the server

        if (IpNurseQueSelectedRow?.Booking_Id) {
            axios.get("https://vesoftometic.co.in/EmergencyManagement/get_Triage_data", {
                params: { Booking_Id: IpNurseQueSelectedRow?.Booking_Id, Location: userRecord?.location },
            })
                .then(response => {
                    setResponseData(response.data);
                    console.log(response.data, 'uuuuuuuuuuuuuuuuuuuuuuuuuu')
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }

    }, [IpNurseQueSelectedRow]); // Empty dependency array to execute only once when the component mounts


    //   useEffect(() => {
    //     if (IpNurseQueSelectedRow?.Booking_Id) {
    //       axios
    //         .get(
    //           `${urllink}EmergencyManagement/get_Emergency_History?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
    //         )
    //         .then((response) => {
    //           const data = response.data[0];
    //           console.log("Fetched surgical history data:", data.AllergiesInfo);

    //           setTriageFormData((prev)=>({
    //             ...prev,
    //             Date: data?.Date || '',
    //             Time: data?.Time || '',
    //             Allergies: data?.Allergies || "", 
    //             AllergiesInfo: data?.AllergiesInfo || '',
    //             Occupation: data?.Occupation || "",
    //             InformantReliability: data?.InformantReliability || "",
    //             CheifComplains: data?.CheifComplains || "",
    //             PresentIllness: data?.PresentIllness || "",
    //             Pain: data?.Pain || "",
    //             Site: data?.Site || "",
    //             Duration: data?.Duration || "",
    //             Score: data?.Score || "",
    //             Frequency: data?.Frequency || "",
    //             Character: data?.Character || "",
    //             AggravatingRelievingFactors: data?.AggravatingRelievingFactors || "",
    //             PastMedicalHistory: data?.PastMedicalHistory || "",
    //             SurgicalHistory: data?.SurgicalHistory || "",
    //             Menarche: data?.Menarche || "",
    //             Cycle: data?.Cycle || "",
    //             lmp: data?.lmp || "",
    //             Contraception: data?.Contraception || "",
    //             MaritalHistory: data?.MaritalHistory || "",
    //             pain: data?.pain || "",
    //             VaginalDischarge: data?.VaginalDischarge || "",
    //             ObstetricHistory: data?.ObstetricHistory || "",
    //             Tobacco: data?.Tobacco || "",
    //             tobaccoInfo: data?.tobaccoInfo || "",
    //             Alcohol: data?.Alcohol || "",
    //             alcoholInfo: data?.alcoholInfo || "",
    //             Drugs: data?.Drugs || "",
    //             drugsInfo: data?.drugsInfo || "",
    //             FamilyHistory: data?.FamilyHistory || "",
    //             SocioEconomicHistory: data?.SocioEconomicHistory || "",
    //             AbusePotential: data?.AbusePotential || "",
    //             AbusePotentialInfo: data?.AbusePotentialInfo || "",
    //           }))

    //           // Set checkbox values based on fetched data
    //           setCheckboxValues({
    //             Tobacco: data?.Tobacco ,
    //             Alcohol: data?.Alcohol ,
    //             Drugs: data?.Drugs ,
    //             AbusePotential: data?.AbusePotential ,
    //             Pain: data?.Pain ,
    //             // Set other fields similarly
    //           });
    //         })
    //         .catch((error) => {
    //           console.error("Error fetching surgical history:", error);
    //         });
    //     }
    //   }, [IpNurseQueSelectedRow, urllink, renderFlag]);


    const successMsg = (message) => {
        toast.success(`${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            containerId: "toast-container-over-header",
            style: { marginTop: "50px" },
        });
    };
    const userwarn = (warningMessage) => {
        toast.warn(`${warningMessage}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };

    const formatLabel = (label) => {
        // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
                .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
        } else {
            return label;
        }
    };
    // Define the columns dynamically


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        // Update TriageFormData state
        setTriageFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    //   const handleCheckboxChange = (event) => {
    //     const { name, value, checked } = event.target;
    //     setCheckboxvalues((prevData) => ({
    //       ...prevData,
    //       [name]: checked ? value : "", // Clear the value if unchecked
    //     }));
    //   };

    // const handleCheckboxChange = (event, index) => {
    //     const { name, checked } = event.target;

    //     // Uncheck all checkboxes
    //     const updatedCheckboxes = checkboxvalues.map((checkbox, i) => ({
    //         ...checkbox,
    //         [Object.keys(checkbox)[0]]: index === i && checked ? true : false,
    //     }));

    //     setCheckboxvalues(updatedCheckboxes);
    // };


    // const handleCheckboxChange = (event, index) => {
    //     const { name, checked } = event.target;

    //     // Update checkbox values
    //     const updatedCheckboxes = checkboxvalues.map((checkbox, i) => ({
    //         ...checkbox,
    //         [name]: index === i && checked ? true : false,
    //     }));

    //     // Update TriageFormData
    //     const updatedFormData = {
    //         ...TriageFormData,
    //         [name]: checked ? "checked" : "", // You can adjust this to your requirements
    //     };

    //     setCheckboxvalues(updatedCheckboxes);
    //     setTriageFormData(updatedFormData);
    // };


    // const handleCheckboxChange = (event, index) => {
    //     const { name, checked } = event.target;

    //     // Update checkbox values
    //     const updatedCheckboxes = checkboxvalues.map((checkbox, i) => ({
    //         ...checkbox,
    //         [name]: index === i && checked ? true : false,
    //     }));

    //     // Find the content associated with the clicked checkbox
    //     const content = updatedCheckboxes[index].content;

    //     // Update the state
    //     setCheckboxvalues(updatedCheckboxes);

    //     // Save the related content if checkbox is checked
    //     if (checked) {
    //         // Here you can save the content however you want, for example:
    //         setPostdata(content);
    //     } else {
    //         // If the checkbox is unchecked, clear the saved content
    //         setPostdata('');
    //     }
    // };



    const handleCheckboxChange = (event, index) => {
        const { name, checked } = event.target;

        // Update checkbox values
        const updatedCheckboxes = checkboxvalues.map((checkbox, i) => ({
            ...checkbox,
            [name]: index === i && checked,
        }));

        // Find the content associated with the clicked checkbox
        const content = updatedCheckboxes[index].content;

        // Update the state
        setCheckboxvalues(updatedCheckboxes);

        // Save the related content if checkbox is checked
        if (checked) {
            // Here you can save the content however you want, for example:
            setPostdata(content);
            setLabel(name);

            // const selected = checkboxvalues.filter(checkbox => checkbox[Object.keys(checkbox)[0]]);
            // setSelectedCheckboxes(selected);



            // Uncheck all other checkboxes except the clicked one
            const updatedCheckboxesSingle = updatedCheckboxes.map((checkbox, i) => ({
                ...checkbox,
                [Object.keys(checkbox)[0]]: index === i,
            }));
            setCheckboxvalues(updatedCheckboxesSingle);
        } else {
            // If the checkbox is unchecked, clear the saved content
            setPostdata('');
        }
    };



    //   const handleAdd = () => {
    //     // const requiredfields = [
    //     //     "Resuscitation",
    //     //     "Emergent",
    //     //     "Urgent",
    //     //     "LessUrgent",
    //     //     "NonUrgent",
    //     //     "Date",
    //     //     "Time",

    //     // ];


    //     const selected = checkboxvalues.filter(checkbox => checkbox[Object.keys(checkbox)[0]]);
    //     setSelectedCheckboxes(selected);

    //     // const existing = requiredfields.filter((field) => !TriageFormData[field]);


    //       const Allsenddata = {
    //         ...TriageFormData,
    //         ...checkboxvalues,
    //         postdata:postdata,
    //         Booking_Id: IpNurseQueSelectedRow.Booking_Id,
    //         Patient_Name: IpNurseQueSelectedRow.PatientName,
    //         Location: userRecord?.location,
    //         CapturedBy: userRecord?.username,
    //       };
    //       axios
    //         .post(
    //           `https://vesoftometic.co.in/EmergencyManagement/insert_Triage`,
    //           Allsenddata
    //         )
    //         .then((response) => {
    //           console.log(response);

    //           setSuccessMessage("Data saved successfully");
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });

    //   };




    // const handleCheckboxChange = (event, index) => {
    //     const { name, checked } = event.target;

    //     // Update checkbox values
    //     const updatedCheckboxes = checkboxvalues.map((checkbox, i) => ({
    //         ...checkbox,
    //         [name]: index === i && checked,
    //     }));

    //     // Find the content associated with the clicked checkbox
    //     const content = updatedCheckboxes[index].content;

    //     // Update the state
    //     setCheckboxvalues(updatedCheckboxes);

    //     // Save the related content if checkbox is checked
    //     if (checked) {
    //         // Here you can save the content however you want, for example:
    //         setPostdata(content);
    //         setLabel(name);
    //     } else {
    //         // If the checkbox is unchecked, clear the saved content
    //         setPostdata('');
    //     }
    // };

    // const handleAdd = () => {
    //     const selected = checkboxvalues.filter(checkbox => checkbox[Object.keys(checkbox)[0]]);
    //     setSelectedCheckboxes(selected);

    //     // Prepare data for submission
    //     const postData = {
    //         ...TriageFormData, // Form data
    //         selectedCheckboxes: selected.map(checkbox => ({
    //             checkbox: Object.keys(checkbox)[0],
    //             content: checkbox.content
    //         })), // Selected checkboxes
    //         Booking_Id: IpNurseQueSelectedRow?.Booking_Id,
    //         Patient_Name: IpNurseQueSelectedRow?.PatientName,
    //         Location: userRecord?.location,
    //         CapturedBy: userRecord?.username,
    //     };

    //     // Send data to backend
    //     axios.post(
    //         `https://vesoftometic.co.in/EmergencyManagement/insert_Triage`,
    //         postData
    //     ).then((response) => {
    //         console.log(response);
    //         setSuccessMessage("Data saved successfully");
    //     }).catch((error) => {
    //         console.error(error);
    //     });
    // };

    console.log(responseData, 'responseDataaaaaaaaaaaaa')

    const handleAdd = () => {
        // Find the selected checkbox

        const requiredfields = [

            "Date",
            "Time",

        ];

        const selected = checkboxvalues.filter(checkbox => checkbox[Object.keys(checkbox)[0]]);
        setSelectedCheckboxes(selected);

        const selectedCheckbox = checkboxvalues.find(checkbox => Object.values(checkbox)[0]);

        if (!selectedCheckbox) {
            // If no checkbox is selected, return an error
            return userwarn('Please select a checkbox');
        }

        // Prepare data for submission
        const postData = {

            ...selectedCheckbox, // Add the selected checkbox and its content
            ...TriageFormData,
            postdata: postdata,
            label: label,
            Booking_Id: IpNurseQueSelectedRow?.Booking_Id,
            Patient_Name: IpNurseQueSelectedRow?.PatientName,
            Location: userRecord?.location,
            CapturedBy: userRecord?.username,
        };

        // Send data to backend
        axios.post(
            `https://vesoftometic.co.in/EmergencyManagement/insert_Triage`,
            postData
        ).then((response) => {
            console.log(response);
            setSuccessMessage("Data saved successfully");
        }).catch((error) => {
            console.error(error);
        });
    };



    console.log(postdata, "jjjjjjjjjjjjjjj")
    console.log(label, "rrrrrrrrrrrrrrrrrrrrr")
    console.log(TriageFormData, "kkkkkkkkkkkkkkkk")
    return (

        <>

            <div className="new-patient-registration-form">
                <div className="RegisFormcon">
                    <div className="RegisForm_1">
                        <label htmlFor="Date"> Date:</label>
                        <input
                            type="date"
                            id="Date"
                            name="Date"
                            value={TriageFormData.Date}
                            onChange={handleInputChange}
                        />

                        <label htmlFor="Time">Time:</label>
                        <input
                            type="time"
                            id="Time"
                            name="Time"
                            value={TriageFormData.Time}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <br />

                <div className="RegisFormcon">
                    <div className="RegisFormcon">
                        <h3>Triage Classification</h3><br />
                    </div>
                </div>
                <br />
                {
                    checkboxvalues.map((checkbox, index) => (
                        <>
                        <div style={{width:'50%',display:'flex',justifyContent:'space-around',gap:'5px'}} key={index}>
                            <label  htmlFor={Object.keys(checkbox)[0]} style={{width:'100px',display:'flex',gap:'5px'}}>
                                <input
                                    type="checkbox"
                                    name={Object.keys(checkbox)[0]}
                                    checked={checkbox[Object.keys(checkbox)[0]]}
                                    onChange={(e) => handleCheckboxChange(e, index)}
                                /> {Object.keys(checkbox)[0]}
                            </label>
                            <div >

                                <p style={{width:'300px'}}><b>{checkbox[Object.keys(checkbox)[1]].split(':')[0]}:</b>{checkbox[Object.keys(checkbox)[1]].split(':')[1]}</p>
                            </div>


                        </div>
                        <br/>
                        </>

                    ))
                }
                <div className="Register_btn_con">
                    <button className="RegisterForm_1_btns" onClick={handleAdd}>Add</button>
                </div>






            </div>





            <div className="Selected-table-container">

                <table className="selected-medicine-table2">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Triage Checkbox</th>
                            <th>Content</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseData?.map((data, index) => (
                            <tr key={index}>
                                <td>{data.Date}</td>
                                <td>{data.Time}</td>
                                <td>{data.label}</td>
                                <td>{data.postdata}</td>

                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </>


    );

}
