import * as React from 'react';




function handleFilterClick() {
  // Logic to handle filtering based on the selected range
  // You can access the selected range using document.getElementById('selectRange').value
  const selectedRange = document.getElementById('selectRange').value;
  // Perform filtering operations based on the selected range
  // ...
}

export default function PathValidatedQueue() {
  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Re-Schedule-Queue</h4>
      </div>
      <div className='RegisFormcon'>

        <div className="RegisForm_1">
          <label htmlFor="selectRange" >Select Range<span>:</span></label>
          <input type="text" id="selectRange" name="selectRange" pattern="[A-Za-z ]+" title="Only letters and spaces are allowed"
            placeholder='Enter your Range' required />

          <button type="button" className="RegisterForm_1_btns" onClick={handleFilterClick}>Filter</button>
        </div>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Test ID</th>
              <th>Patient Name</th>
              <th>Test Count</th>
              <th>Schedule Process</th>
              <th>Process Remarks</th>
              <th>Schedule By</th>

            </tr>
          </thead>
          <tbody>
            {/* {selectedValues.map((values, index) => ( */}
            <tr>

              <td>data</td>
              <td>data</td>
              <td>data</td>
              <td>data</td>
              <td>data</td>
              <td>data</td>

            </tr>
            {/* ))} */}
          </tbody>


        </table>
      </div>

    </div>
  );
}