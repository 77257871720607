import React, { useState, useEffect } from "react";
// import "./MedicalHistoryForm.css";
import Logo from "../../../src/assets/logo.png";
import axios from "axios";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";

function MedicalHistoryForm2() {
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [preview, setpreview] = useState(false);
  const [urinaryArray, setUrinaryArray] = useState([]);
  const [SelectedMusculoskeletal, SetSelectedMusculoskeletal] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const [gynecologicArray, setGynecologicArray] = useState([]);
  const [menArray, setMenArray] = useState([]);

  const [selectedEyeSystem, SetSelectedEyeSystem] = useState([]);
  const [selectedCardioSystem, setSelectedCardioSystem] = useState([]);
  const [selectedRespiratorySystem, setSelectedRespiratorySystem] = useState([]);
  const [selectedNeurologicalSystem, setNeurologicalSystem] = useState([]);
  const [selectedPsychiatric, setSelectedPsychiatric] = useState([]);
  const [selectedMenSystem, setSelectedMenSystem] = useState([]);
  const [suregicalHistory, setSurgicalHistory] = useState([]);
  const [selectedAllergic, setSelectedAllergic] = useState([]);
  const [selectedBreast, setSelectedBreast] = useState([]);
  const [selectedEnmt, setSelectedEnmt] = useState([]);
  const [selectedHematologic, setSelectedHematologic] = useState([]);
  const [selectedDigestive, setSelectedDigestive] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [howmuch, setHowmuch] = useState("");
  const [lastperiod, setLastperiod] = useState("");
  const [selectedSkinSystem, setSelectedSkinSystem] = useState([]);
  const [selectedWomenInfo, setSelectedWomenInfo] = useState([]);
  const [selectedEndocrineSystem, setSelectedEndocrineSystem] = useState([]);
  const [printMode, setPrintMode] = useState(false);

  const [SurgicalHistory1, setSurgicalHistory1] = useState({
    // Surgical History
    AdrenalGlandSurgery: false,
    Appendectomy: false,
    BariatricSurgery: false,
    BladderSurgery: false,
    BreastSurgery: false,
    CesareanSection: false,
    Cholecystectomy: false,
    ColonSurgery: false,
    CoronaryArteryBypassGraft: false,
    EsophagusSurgery: false,
    GastricBypassSurgery: false,
    HemorrhoidSurgery: false,
    HerniaRepair: false,
    Hysterectomy: false,
    KidneySurgery: false,
    NeckSurgery: false,
    ProstateSurgery: false,
    SmallIntestineSurgery: false,
    SpineSurgery: false,
    StomachSurgery: false,
    ThyroidSurgery: false,
    // NoneOfTheAbove:false,
  });


  const [other, setOther] = useState(false);
  const [otherCheckbox, setOtherCheckbox] = useState('');

  const handleSurgicalHistoryChange = (key) => {
    setSurgicalHistory1((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setOther(false); // Uncheck "Others" checkbox if any surgical history checkbox is checked
  };


  const handleOthersChange = (event) => {
    const isChecked = event.target.checked;
    setOther(isChecked);
    if (isChecked) {
      // Set all surgicalHistory values to false
      const updatedSurgicalHistory = Object.fromEntries(
        Object.entries(SurgicalHistory1).map(([key]) => [key, false])
      );
      setSurgicalHistory1(updatedSurgicalHistory);
      
    }
    setOtherCheckbox(''); // Clear the "Others" textarea when checkbox is unchecked
  };



  const [patientInfo2, setPatientInfo2] = useState({
    Listnamesdates: "",
    
  });

  const handleSkinReview = (condition) => {
    if (condition === "NothinginthisSkin") {
      const updatedSkin = { ...skin, [condition]: !skin[condition] };
      setSkin(updatedSkin);

      // If "Nothing in this group" is checked, uncheck all other checkboxes
      if (updatedSkin[condition]) {
        const updatedSkinWithoutOthers = { ...updatedSkin };
        Object.keys(updatedSkinWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedSkinWithoutOthers[key] = false;
          });
        setSkin(updatedSkinWithoutOthers);
        setSelectedSkinSystem([]);
      }
    } else {
      const updatedSkin = { ...skin, [condition]: !skin[condition] };
      setSkin(updatedSkin);

      // If "Nothing in this Skin" is checked, uncheck it
      if (updatedSkin.NothinginthisSkin) {
        const updatedSkinWithoutNothing = {
          ...updatedSkin,
          NothinginthisSkin: false,
        };
        setSkin(updatedSkinWithoutNothing);
        setSelectedSkinSystem((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisSkin")
        );
      } else {
        setSelectedSkinSystem((prevSelected) =>
          updatedSkin[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("selectedskin", selectedSkinSystem);
  console.log("menarray", menArray);

  // const handleSurgicalHistory = (condition) => {
  //   const updatedSurgicalHistory = {
  //     ...SurgicalHistory1,
  //     [condition]: !SurgicalHistory1[condition],
  //   };
  //   setSurgicalHistory1(updatedSurgicalHistory);

  //   // Update the selectedSkinSystem array
  //   if (!updatedSurgicalHistory[condition]) {
  //     setSurgicalHistory((prevSelected) =>
  //       prevSelected.filter((item) => item !== condition)
  //     );
  //   } else {
  //     setSurgicalHistory((prevSelected) => [...prevSelected, condition]);
  //   }
  // };


  console.log("selectedsurgicalhistory", suregicalHistory);
  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    date: new Date().toLocaleDateString(),
    gender: "",
    reason: "",
    Description: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const [others, setOthers] = useState({
  //   reason: "",
  // });

  // const handleInputChangeOthers = (e) => {
  //   const { name, value } = e.target;
  //   setOthers((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange12 = (e) => {
    const { name, value } = e.target;
    setPatientInfo2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [womenInfo2, setWomenInfo2] = useState({
    numberofPregnancies: "",
    numberofDeliveries: "",
    Vaginal: "",
    csections: "",
    Miscarriages: "",
    abortions: "",
  });

  useEffect(() => {
    const updatedInfoArray = selectedwomenInfo2History(womenInfo2);
    setSelectedWomenInfo(updatedInfoArray);
  }, [womenInfo2]);

  const handleInputboxChange3 = (e) => {
    const { name, value } = e.target;
    setWomenInfo2((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const [womenMen, setWomenMen] = useState({
    Monthlyselfexam: false,
    Yearlyphysicianexam: false,
    Lastmammogram: false,

    YearlyGYNexam: false,
    YearlyPAPexam: false,
    Lastmammogram2: false,

    Highsunexposure: false,
    Yearlyskinexam: false,
  });

  const [men, setMen] = useState({
    Yearlyrectalexam: false,
    YearlyPSAbloodtest: false,
    Yearlyrectalexam2: false,
    Yearlystooltestforblood: false,
    Dateoflastcolonoscopy: "",
  });

console.log(men,'mennnnnnnnnnnnnnnn')

  const [mentext,setMenText] = useState({
    Dateoflastcolonoscopy: "",
  });

  console.log(mentext,'mentexttttttttttttttt')

  
  // const [patientInfo2, setPatientInfo2] = useState({
  //   Listnamesdates: "",
    
  // });

  const handleChangeMen = (fieldName, value, isCheckbox) => {
    const updatedMen = {
      ...men,
      [fieldName]: isCheckbox ? value : value.trim(),
    };
    setMen(updatedMen);
    let updatedMenSystem = { ...men };
  
    if (fieldName === "Yearlystooltestforblood" && isCheckbox && value) {
      const newValue = `Yearlystooltestforblood - Dateoflastcolonoscopy: ${men.Dateoflastcolonoscopy}`;
      setMenArray((prevArray) => [...prevArray, newValue]);
    } else if (fieldName === "Yearlystooltestforblood" && isCheckbox && !value) {
      setMenArray((prevArray) =>
        prevArray.filter(
          (item) =>
            !item.startsWith("Yearlystooltestforblood - Dateoflastcolonoscopy")
        )
      );
    } else if (fieldName === "Dateoflastcolonoscopy") {
      setMen((prevState) => ({
        ...prevState,
        Dateoflastcolonoscopy: value,
      }));
  
      if (men.Yearlystooltestforblood) {
        const weightlossIndex = menArray.findIndex((item) =>
          item.startsWith("Yearlystooltestforblood - Dateoflastcolonoscopy")
        );
        if (weightlossIndex !== -1) {
          const newGeneralArray = [...menArray];
          newGeneralArray[weightlossIndex] =`Yearlystooltestforblood - Dateoflastcolonoscopy: ${value}`;
          setMenArray(newGeneralArray);
        }
      }
    }
  

    if (fieldName === "Yearlystooltestforblood") {
      if (!isCheckbox) {
        updatedMenSystem.Dateoflastcolonoscopy = value.trim();
      } else {
        if (!value) {
          updatedMenSystem.Dateoflastcolonoscopy = "";
        }
        if (!value) {
          setMenArray((prevArray) =>
            prevArray.filter(
              (item) =>
                !item.startsWith(
                  "Yearlystooltestforblood - Dateoflastcolonoscopy"
                )
            )
          );
        }
      }
    } else if (fieldName !== "Numberoftimespernight") {
      updatedMenSystem[fieldName] = isCheckbox ? value : value.trim();
    }
  
    setMen(updatedMenSystem);
    setMen(updatedMenSystem);
    if (isCheckbox && value && fieldName !== "Yearlystooltestforblood") {
      setMenArray((prevArray) => [...prevArray, `${fieldName}`]);
    } else if (isCheckbox && !value) {
      setMenArray((prevArray) =>
        prevArray.filter((item) => !item.startsWith(`${fieldName}:`))
      );
    }
  };

  const handleInputChange44 = (fieldName, e) => {
    // Check if e and e.target are defined before destructuring
    if (e && e.target) {
      const { type, checked, value } = e.target;
      console.log(fieldName,checked,value, type,'.........................................')
      if (type === "checkbox") {
        handleChangeMen(fieldName, checked, true); // If it's a checkbox, call handleChange with the checked value
      } else {
        handleChangeMen(fieldName, value, false); // If it's an input field, call handleChange with the input value
      }
    }
  };

  
  console.log("Selected Men System:", menArray);
  console.log("mens", men);
  

  


  console.log("Selected Men System:", menArray);
  console.log("mens", men);

  const handleCheckboxChangeWomenMen = (name) => {
    setWomenMen((prevWomenMen) => ({
      ...prevWomenMen,
      [name]: !prevWomenMen[name],
    }));
  };

  //...............jeeva

  const handleCheckboxChangeMen = (name) => {
    setMen((prevMen) => ({
      ...prevMen,
      [name]: !prevMen[name],
    }));
  };


  const handleInputChangeMen = (event) => {
    const { name, value } = event.target;
    setMenText((prevMen) => ({
      ...prevMen,
      [name]: value,
    }));
  };



  // const handleCheckboxChangeMen1 = (name) => {
  //   setMenText((prevMen1,value) => ({
  //     ...prevMen1,
  //     [name]: value,
  //   }));
  // };

   //.................jeeva

  const [reviewSystem, setReviewSystem] = useState({
    Nothinginthisgroup: false,
    Weightloss: false,
    Howmuch: howmuch,
    LossofAppetite: false,
    Fever: false,
    Chills: false,
    NightSweats: false,
    FaintingSpells: false,
  });

  const [reviewEyes, setReviewEyes] = useState({
    Nothinginthiseye: false,
    EyesDiseaseOrInjury: false,
    WearGlassesOrContacts: false,
    BlurredOrDoubleVision: false,
  });

  const [cardiovascular, setCardiovascular] = useState({
    Nothinginthiscardio: false,
    ChestPain: false,
    Palpitations: false,
    HeartValveProblems: false,
    CalfPainwithWalking: false,
    LegSwelling: false,
  });

  const [respiratory, setRespiratory] = useState({
    NothinginthisRespiratory: false,
    ChronicCough: false,
    CoughingUpBlood: false,
    ShortofBreathWithActivity: false,
    ShortofBreathlyingFlat: false,
    Wheezing: false,
    Asthma: false,
    Bronchitis: false,
    Pneumonia: false,
  });
  const [musculoskeletal, setMusculoskeletal] = useState({
    NothinginthisMusculoskeletal: false,
    JointPain: false,
    Rthritis: false,
    BackPain: false,
    MuscleWeakness: false,
    LegPainWithWalking: false,
    LegPainAtRest: false,
    BrokenBones: false,
  });

  const [urinary, setUrinary] = useState({
    Nothinginthisurinary: false,
    BurningWithUrination: false,
    WeakUrineStream: false,
    BloodInUrine: false,
    GasOrStoolInUrine: false,
    PoorControlLeakage: false,
    KidneyStones: false,
    ProstateProblems: false,
    TesticularMass: false,
    GetUpAtNightToUrinate: false,
    Numberoftimespernight: "",
  });
  console.log("urinary", urinary);


  const handleChange12 = (fieldName, value, isCheckbox) => {
    const updatedUrinary = {
      ...urinary,
      [fieldName]: isCheckbox ? value : value.trim(),
    };
    setUrinary(updatedUrinary);
    let updatedUrinarySystem = { ...urinary };
  
    if (fieldName === "GetUpAtNightToUrinate" && isCheckbox && value) {
      const newValue = `GetUpAtNightToUrinate - Numberoftimespernight: ${urinary.Numberoftimespernight}`;
      setUrinaryArray((prevArray) => [...prevArray, newValue]);
    } else if (fieldName === "GetUpAtNightToUrinate" && isCheckbox && !value) {
      setUrinaryArray((prevArray) =>
        prevArray.filter(
          (item) =>
            !item.startsWith("GetUpAtNightToUrinate - Numberoftimespernight")
        )
      );
    } else if (fieldName === "Numberoftimespernight") {
      setUrinary((prevState) => ({
        ...prevState,
        Numberoftimespernight: value,
      }));
  
      if (urinary.GetUpAtNightToUrinate) {
        const weightlossIndex = urinaryArray.findIndex((item) =>
          item.startsWith("GetUpAtNightToUrinate - Numberoftimespernight")
        );
        if (weightlossIndex !== -1) {
          const newGeneralArray = [...urinaryArray];
          newGeneralArray[weightlossIndex] = `GetUpAtNightToUrinate - Numberoftimespernight: ${value}`;
          setUrinaryArray(newGeneralArray);
        }
      }
    }
  
    if (fieldName === "Nothinginthisurinary") {
      updatedUrinarySystem = {
        ...Object.keys(updatedUrinarySystem).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        Nothinginthisurinary: true,
        Numberoftimespernight: "", // Reset the Howmuch field
      };
      setUrinaryArray([]);
    } else {
      updatedUrinarySystem[fieldName] = isCheckbox ? value : value.trim();
    }
    if (fieldName === "GetUpAtNightToUrinate") {
      if (!isCheckbox) {
        updatedUrinarySystem.Numberoftimespernight = value.trim();
      } else {
        if (!value) {
          updatedUrinarySystem.Numberoftimespernight = "";
        }
        if (!value) {
          setUrinaryArray((prevArray) =>
            prevArray.filter(
              (item) =>
                !item.startsWith(
                  "GetUpAtNightToUrinate - Numberoftimespernight"
                )
            )
          );
        }
      }
    } else if (fieldName !== "Numberoftimespernight") {
      updatedUrinarySystem[fieldName] = isCheckbox ? value : value.trim();
    }
  
    setUrinary(updatedUrinarySystem);
    setUrinary(updatedUrinarySystem);
    if (isCheckbox && value && fieldName !== "GetUpAtNightToUrinate") {
      setUrinaryArray((prevArray) => [...prevArray, `${fieldName}`]);
    } else if (isCheckbox && !value) {
      setUrinaryArray((prevArray) =>
        prevArray.filter((item) => !item.startsWith(`${fieldName}:`))
      );
    }
  };
  
  const handleChange21 = (fieldName, e) => {
    // Check if e and e.target are defined before destructuring
    if (e && e.target) {
      const { type, checked, value } = e.target;

      if (type === "checkbox") {
        handleChange12(fieldName, checked, true); // If it's a checkbox, call handleChange with the checked value
      } else {
        handleChange12(fieldName, value, false); // If it's an input field, call handleChange with the input value
      }
    }
  };

  console.log("urinaryarray", urinaryArray);

  const [neurological, setNeurological] = useState({
    NothinginthisNeurological: false,
    FrequentHeadaches: false,
    Migraines: false,
    Weakness: false,
    Seizures: false,
    Stroke: false,
    Paralysis: false,
    DecreasedSensation: false,
    DifficultyWithSpeech: false,
    Dizziness: false,
  });

  const [psychiatric, setPsychiatric] = useState({
    NothinginthisPsychiatric: false,
    Anxiety: false,
    Depression: false,
    MoodDepression: false,
    PhobiasOrFears: false,
    PanicAttacks: false,
    SuicideThoughtsOrAttempts: false,
  });

  const [skin, setSkin] = useState({
    NothinginthisSkin: false,
    Rash: false,
    SkinInfections: false,
    UlcersOrSores: false,
    YellowingOfTheSkin: false,
    EczemaPsoriasis: false,
    PyodermaganGrenosumOrErythemaNodosum: false,
  });
  console.log("selectedSkinSystem", selectedSkinSystem);

  const [endocrine, setEndocrine] = useState({
    NothinginthisEndocrine: false,
    HeatOrColdIntoleranceash: false,
    ExcessiveThirst: false,
    ExcessiveUrination: false,
    ExcessiveSweating: false,
  });
  const [allergic, setAllergic] = useState({
    Nothinginthisallergic: false,
    HivInfection: false,
    Hepatitis: false,
    ImuneDeficiency: false,
    AntibioticsNeededForDentalWork: false,
  });
  const [breast, setBreast] = useState({
    Nothinginthisbreast: false,
    BreastLump: false,
    BreastPain: false,
    NippleDischarge: false,
  });
  const handleCheckBreastReview = (condition) => {
    const updatedBreast = { ...breast, [condition]: !breast[condition] };
    setBreast(updatedBreast);

    if (condition === "Nothinginthisbreast") {
      // If "Nothing in this group" is checked, uncheck all other checkboxes
      const updatedBreastWithoutOthers = { ...updatedBreast };
      Object.keys(updatedBreastWithoutOthers)
        .filter((key) => key !== condition)
        .forEach((key) => {
          updatedBreastWithoutOthers[key] = false;
        });
      setBreast(updatedBreastWithoutOthers);
      setSelectedBreast([]);
    } else {
      // If "Nothing in this breast" is checked, uncheck it
      if (updatedBreast.Nothinginthisbreast) {
        const updatedBreastWithoutNothing = {
          ...updatedBreast,
          Nothinginthisbreast: false,
        };
        setBreast(updatedBreastWithoutNothing);
        setSelectedBreast((prevSelected) =>
          prevSelected.filter((item) => item !== "Nothinginthisbreast")
        );
      } else {
        setSelectedBreast((prevSelected) =>
          updatedBreast[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };
  console.log("selectedBreast", selectedBreast);

  const [gynecologic, setGynecologic] = useState({
    NothinginthisGynecologic: false,
    IrregularPeriods: false,
    LastPeriod: lastperiod,
    AbnormalVaginalDischarge: false,
  });

  const [enmt, setEnmt] = useState({
    NothinginthisGroup: false,
    HearingLoss: false,
    EaracheInfection: false,
    RingingInEars: false,
    NoseBleeds: false,
    BleedingGums: false,
    MouthSores: false,
    SoreThroat: false,
    RecentVoiceChange: false,
    RunnyNoseCold: false,
    SinusProblem: false,
    NeckStiffinessPain: false,
    EnlargedNeckGlandsMasses: false,
  });
  const [digestive, setDigestive] = useState({
    Nothinginthisdigestive: false,
    LossOfAppetite: false,
    DifficultySwallowing: false,
    EarlySatiety: false,
    Heartburn: false,
    Nausea: false,
    Vomiting: false,
    Diarrhea: false,
    Constipation: false,
    BloodInStool: false,
    DarkTarryStools: false,
    AbdominalPain: false,
    PainfulBowelMovements: false,
    PoorControlOfBMsurgency: false,
  });
  const [hematologic, setHematologic] = useState({
    Nothinginthishematologic: false,
    priorBloodTransfusion: false,
    EasyBleedingOrBruising: false,
    LowRedBloodCellCount: false,
    LowWhiteBloodCellCount: false,
    prolongedBleeding: false,
    Swollenglands: false,
    BloodClots: false,
    UseOfBloodThinners: false,
    swollenLymphNodes: false,
  });

 
  // const handleOthersChange = (event) => {
  //   setOtherCheckbox(event.target.value);
   
  // };

  
  const handleCheckHematologicReview = (condition) => {
    const updatedHematologic = {
      ...hematologic,
      [condition]: !hematologic[condition],
    };
    setHematologic(updatedHematologic);

    if (condition === "Nothinginthishematologic") {
      const updatedHematologicWithoutOthers = { ...updatedHematologic };
      Object.keys(updatedHematologicWithoutOthers)
        .filter((key) => key !== condition)
        .forEach((key) => {
          updatedHematologicWithoutOthers[key] = false;
        });
      setHematologic(updatedHematologicWithoutOthers);
      setSelectedHematologic([]);
    } else {
      const updatedHematologicWithoutNothing = {
        ...updatedHematologic,
        Nothinginthishematologic: false,
      };
      setHematologic(updatedHematologicWithoutNothing);

      setSelectedHematologic((prevSelected) =>
        updatedHematologic[condition]
          ? [...prevSelected, condition]
          : prevSelected.filter((item) => item !== condition)
      );
    }
  };
  console.log("selectedHematologic", selectedHematologic);

  const handleCheckDigestiveReview = (condition) => {
    const updatedDigestive = {
      ...digestive,
      [condition]: !digestive[condition],
    };
    setDigestive(updatedDigestive);

    if (condition === "Nothinginthisdigestive") {
      const updatedDigestiveWithoutOthers = { ...updatedDigestive };
      Object.keys(updatedDigestiveWithoutOthers)
        .filter((key) => key !== condition)
        .forEach((key) => {
          updatedDigestiveWithoutOthers[key] = false;
        });
      setDigestive(updatedDigestiveWithoutOthers);
      setSelectedDigestive([]);
    } else {
      if (updatedDigestive.Nothinginthisdigestive) {
        const updatedDigestiveWithoutNothing = {
          ...updatedDigestive,
          Nothinginthisdigestive: false,
        };
        setDigestive(updatedDigestiveWithoutNothing);
        setSelectedDigestive((prevSelected) =>
          prevSelected.filter((item) => item !== "Nothinginthisdigestive")
        );
      } else {
        setSelectedDigestive((prevSelected) =>
          updatedDigestive[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("selectedDigestive", selectedDigestive);

  const handleCheckboxENMTReview = (condition) => {
    const updatedENMT = { ...enmt, [condition]: !enmt[condition] };
    setEnmt(updatedENMT);

    if (condition === "NothinginthisGroup") {
      // If "Nothing in this group" is checked, uncheck all other checkboxes
      const updatedENMTWithoutOthers = { ...updatedENMT };
      Object.keys(updatedENMTWithoutOthers)
        .filter((key) => key !== condition)
        .forEach((key) => {
          updatedENMTWithoutOthers[key] = false;
        });
      setEnmt(updatedENMTWithoutOthers);
      setSelectedEnmt([]);
    } else {
      // If "Nothing in this group" is unchecked, check all other checkboxes
      if (updatedENMT.NothinginthisGroup) {
        const updatedENMTWithoutNothing = {
          ...updatedENMT,
          NothinginthisGroup: false,
        };
        setEnmt(updatedENMTWithoutNothing);
        setSelectedEnmt((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisGroup")
        );
      } else {
        setSelectedEnmt((prevSelected) =>
          updatedENMT[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };
  console.log("selectedEnmt", selectedEnmt);

  const handleChangeGynecologic = (fieldName, value, isCheckbox) => {
    // Update the gynecologic state
    setGynecologic((prevState) => ({
      ...prevState,
      [fieldName]: isCheckbox ? value : value.trim(),
    }));

    // Perform operations on the gynecologicArray based on the field name
    let updatedGynecologicArray = [...gynecologicArray];

    if (fieldName === "IrregularPeriods") {
      if (isCheckbox) {
        if (!value) {
          // If IrregularPeriods checkbox is unchecked, remove corresponding LastPeriod entry from the array
          updatedGynecologicArray = updatedGynecologicArray.filter(
            (item) => !item.startsWith("IrregularPeriods - LastPeriod")
          );

          // Set LastPeriod to empty string when IrregularPeriods is unchecked
          setGynecologic((prevState) => ({
            ...prevState,
            LastPeriod: "",
          }));
        } else {
          // If IrregularPeriods checkbox is checked, include LastPeriod value in the array
          updatedGynecologicArray.push(
            `IrregularPeriods - LastPeriod: ${gynecologic.LastPeriod}`
          );
        }
      }
    } else if (fieldName === "NothinginthisGynecologic" && value) {
      // If NothinginthisGynecologic checkbox is checked, reset the array and related fields
      updatedGynecologicArray = [];
      setGynecologic((prevState) => ({
        ...prevState,
        IrregularPeriods: false,
        LastPeriod: "",
        AbnormalVaginalDischarge: false,
      }));
    } else if (fieldName === "LastPeriod") {
      if (gynecologic.IrregularPeriods) {
        // If IrregularPeriods checkbox is checked, update the corresponding entry in the array with new LastPeriod value
        const index = updatedGynecologicArray.findIndex((item) =>
          item.startsWith("IrregularPeriods - LastPeriod")
        );
        if (index !== -1) {
          updatedGynecologicArray[
            index
          ] = `IrregularPeriods - LastPeriod: ${value}`;
        }
      }
    } else if (fieldName === "AbnormalVaginalDischarge") {
      // Update the gynecologicArray with the field name only if it's checked
      if (isCheckbox && value) {
        updatedGynecologicArray.push(fieldName);
      } else {
        // If AbnormalVaginalDischarge checkbox is unchecked, remove it from the array
        updatedGynecologicArray = updatedGynecologicArray.filter(
          (item) => item !== fieldName
        );
      }
    } else {
      updatedGynecologicArray[fieldName] = isCheckbox
        ? value.toString()
        : value.trim();
    }

    // Update the gynecologicArray state
    setGynecologicArray(updatedGynecologicArray);
  };

  const handleInputChange33 = (e, fieldName) => {
    if (e && e.target) {
      const { type, checked, value } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;

      handleChangeGynecologic(fieldName, fieldValue, type === "checkbox");
    }
  };

  console.log("gynecologicArray", gynecologicArray);

  console.log("gynecologicArray", gynecologicArray);
  const handleCheckboxAllergicReview = (condition) => {
    if (condition === "Nothinginthisallergic") {
      const updatedAllergic = {
        ...allergic,
        [condition]: !allergic[condition],
      };
      setAllergic(updatedAllergic);

      // If "Nothing in this group" is checked, uncheck all other checkboxes
      if (updatedAllergic[condition]) {
        const updatedAllergicWithoutOthers = { ...updatedAllergic };
        Object.keys(updatedAllergicWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedAllergicWithoutOthers[key] = false;
          });
        setAllergic(updatedAllergicWithoutOthers);
        setSelectedAllergic([]);
      }
    } else {
      const updatedAllergic = {
        ...allergic,
        [condition]: !allergic[condition],
      };
      setAllergic(updatedAllergic);

      // If "Nothing in this allergic" is checked, uncheck it
      if (updatedAllergic.Nothinginthisallergic) {
        const updatedAllergicWithoutNothing = {
          ...updatedAllergic,
          Nothinginthisallergic: false,
        };
        setAllergic(updatedAllergicWithoutNothing);
        setSelectedAllergic((prevSelected) =>
          prevSelected.filter((item) => item !== "Nothinginthisallergic")
        );
      } else {
        setSelectedAllergic((prevSelected) =>
          updatedAllergic[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };
  console.log("selectedAllergic", selectedAllergic);

  const handleCheckboxCardioReview = (condition) => {
    if (condition === "Nothinginthiscardio") {
      const updatedCardio = {
        ...cardiovascular,
        [condition]: !cardiovascular[condition],
      };
      setCardiovascular(updatedCardio);
      if (updatedCardio[condition]) {
        setSelectedCardioSystem([]);
        // Uncheck all other checkboxes
        const updatedCardioWithoutOthers = { ...updatedCardio };
        Object.keys(updatedCardioWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedCardioWithoutOthers[key] = false;
          });
        setCardiovascular(updatedCardioWithoutOthers);
      }
    } else {
      const updatedCardio = {
        ...cardiovascular,
        [condition]: !cardiovascular[condition],
      };
      setCardiovascular(updatedCardio);
      setSelectedCardioSystem((prevSelected) =>
        updatedCardio[condition]
          ? [...prevSelected, condition]
          : prevSelected.filter((item) => item !== condition)
      );

      // If "Nothing in this group" is checked, uncheck it
      if (updatedCardio.Nothinginthiscardio) {
        const updatedCardioWithoutNothing = {
          ...updatedCardio,
          Nothinginthiscardio: false,
        };
        setCardiovascular(updatedCardioWithoutNothing);
        setSelectedCardioSystem((prevSelected) =>
          prevSelected.filter((item) => item !== "Nothinginthiscardio")
        );
      }
    }
  };

  console.log(reviewSystem.Nothinginthisgroup);

  const handleChange = (fieldName, value, isCheckbox) => {
    const updatedReview = {
      ...reviewSystem,
      [fieldName]: isCheckbox ? value : value.trim(),
    };
    setReviewSystem(updatedReview);
    let updatedReviewSystem1 = { ...reviewSystem };

    if (fieldName === "Weightloss" && isCheckbox && value) {
      // If "Weightloss" checkbox is checked, include Howmuch value in the general array
      const newValue = `Weightloss - Howmuch: ${reviewSystem.Howmuch}`;
      setGeneralArray((prevArray) => [...prevArray, newValue]);
    } else if (fieldName === "Weightloss" && isCheckbox && !value) {
      // If "Weightloss" checkbox is unchecked, remove the corresponding entry from the general array
      setGeneralArray((prevArray) =>
        prevArray.filter((item) => !item.startsWith("Weightloss - Howmuch"))
      );
    } else if (fieldName === "Howmuch") {
      // If the field is Howmuch, update its value in the reviewSystem state
      setReviewSystem((prevState) => ({
        ...prevState,
        Howmuch: value,
      }));

      // Check if Weightloss is checked, then update the corresponding entry in the general array
      if (reviewSystem.Weightloss) {
        const weightlossIndex = generalArray.findIndex((item) =>
          item.startsWith("Weightloss - Howmuch")
        );
        if (weightlossIndex !== -1) {
          const newGeneralArray = [...generalArray];
          newGeneralArray[weightlossIndex] = `Weightloss - Howmuch: ${value}`;
          setGeneralArray(newGeneralArray);
        }
      }
    }

    if (fieldName === "Nothinginthisgroup") {
      updatedReviewSystem1 = {
        ...Object.keys(updatedReviewSystem1).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        Nothinginthisgroup: true,
        Howmuch: "",
      };
      setGeneralArray([]);
    } else {
      updatedReviewSystem1[fieldName] = isCheckbox ? value : value.trim();
    }
    if (fieldName === "Weightloss") {
      if (!isCheckbox) {
        updatedReviewSystem1.Howmuch = value.trim();
      } else {
        if (!value) {
          // If "Weightloss" checkbox is unchecked, clear Howmuch value
          updatedReviewSystem1.Howmuch = "";
        }
        // Handle generalArray based on "Weightloss" checkbox
        if (!value) {
          // If "Weightloss" checkbox is unchecked, remove corresponding entry from generalArray
          setGeneralArray((prevArray) =>
            prevArray.filter((item) => !item.startsWith("Weightloss - Howmuch"))
          );
        }
      }
    } else if (fieldName !== "Howmuch") {
      updatedReviewSystem1[fieldName] = isCheckbox ? value : value.trim();
    }

    setReviewSystem(updatedReviewSystem1);
    setReviewSystem(updatedReviewSystem1);
    if (isCheckbox && value && fieldName !== "Weightloss") {
      setGeneralArray((prevArray) => [...prevArray, `${fieldName}`]);
    } else if (isCheckbox && !value) {
      setGeneralArray((prevArray) =>
        prevArray.filter((item) => !item.startsWith(`${fieldName}:`))
      );
    }
  };

  // Function to handle input changes
  const handleInputChange23 = (fieldName, e) => {
    const { type, checked, value } = e.target;

    if (type === "checkbox") {
      handleChange(fieldName, checked, true); // If it's a checkbox, call handleChange with the checked value
    } else {
      handleChange(fieldName, value, false); // If it's an input field, call handleChange with the input value
    }
  };

  console.log("selectedcardiovascular", selectedCardioSystem);
  const handleCheckboxEyeReview = (condition) => {
    if (condition === "Nothinginthiseye") {
      const updatedEye = {
        ...reviewEyes,
        [condition]: !reviewEyes[condition],
      };
      setReviewEyes(updatedEye);
      if (updatedEye[condition]) {
        // Uncheck all other checkboxes
        const updatedEyeWithoutOthers = { ...updatedEye };
        Object.keys(updatedEyeWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedEyeWithoutOthers[key] = false;
          });
        setReviewEyes(updatedEyeWithoutOthers);
        // Clear the selectedEyeSystem array
        SetSelectedEyeSystem([]);
      }
    } else {
      const updatedEye = {
        ...reviewEyes,
        [condition]: !reviewEyes[condition],
      };
      setReviewEyes(updatedEye);
      // If "Nothing in this eye" is checked, uncheck it
      if (updatedEye.Nothinginthiseye) {
        const updatedEyeWithoutNothing = {
          ...updatedEye,
          Nothinginthiseye: false,
        };
        setReviewEyes(updatedEyeWithoutNothing);
        SetSelectedEyeSystem((prevSelected) =>
          prevSelected.filter((item) => item !== "Nothinginthiseye")
        );
      } else {
        SetSelectedEyeSystem((prevSelected) =>
          updatedEye[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("SelectedEyeSystem", selectedEyeSystem);

  console.log("generalarray", generalArray);

  const handleCheckRespiratoryReview = (condition) => {
    if (condition === "NothinginthisRespiratory") {
      const updatedRespiratory = {
        ...respiratory,
        [condition]: !respiratory[condition],
      };
      setRespiratory(updatedRespiratory);
      if (updatedRespiratory[condition]) {
        // Uncheck all other checkboxes
        const updatedRespiratoryWithoutOthers = {};
        Object.keys(updatedRespiratory).forEach((key) => {
          updatedRespiratoryWithoutOthers[key] =
            key === condition ? true : false;
        });
        setRespiratory(updatedRespiratoryWithoutOthers);
        // Clear the selectedRespiratorySystem array
        setSelectedRespiratorySystem([]);
      }
    } else {
      const updatedRespiratory = {
        ...respiratory,
        [condition]: !respiratory[condition],
      };
      setRespiratory(updatedRespiratory);
      setSelectedRespiratorySystem((prevSelected) =>
        updatedRespiratory[condition]
          ? [...prevSelected, condition]
          : prevSelected.filter((item) => item !== condition)
      );
    }
  };

  console.log("selectedrespiratory", selectedRespiratorySystem);

  const handleMusculoskeletalReview = (condition) => {
    if (condition === "NothinginthisMusculoskeletal") {
      const updatedMusculoskeletal = {
        ...musculoskeletal,
        [condition]: !musculoskeletal[condition],
      };
      setMusculoskeletal(updatedMusculoskeletal);

      if (updatedMusculoskeletal[condition]) {
        // Uncheck all other checkboxes
        const updatedMusculoskeletalWithoutOthers = {
          ...updatedMusculoskeletal,
        };
        Object.keys(updatedMusculoskeletalWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedMusculoskeletalWithoutOthers[key] = false;
          });
        setMusculoskeletal(updatedMusculoskeletalWithoutOthers);
        // Clear the selectedMusculoskeletalSystem array
        SetSelectedMusculoskeletal([]);
      }
    } else {
      const updatedMusculoskeletal = {
        ...musculoskeletal,
        [condition]: !musculoskeletal[condition],
      };
      setMusculoskeletal(updatedMusculoskeletal);
      // If "Nothing in this Musculoskeletal" is checked, uncheck it
      if (updatedMusculoskeletal.NothinginthisMusculoskeletal) {
        const updatedMusculoskeletalWithoutNothing = {
          ...updatedMusculoskeletal,
          NothinginthisMusculoskeletal: false,
        };
        setMusculoskeletal(updatedMusculoskeletalWithoutNothing);
        SetSelectedMusculoskeletal((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisMusculoskeletal")
        );
      } else {
        SetSelectedMusculoskeletal((prevSelected) =>
          updatedMusculoskeletal[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("SelectedmusculSystem", SelectedMusculoskeletal);

  const handleNeurologicalReview = (condition) => {
    if (condition === "NothinginthisNeurological") {
      const updatedNeurological = {
        ...neurological,
        [condition]: !neurological[condition],
      };
      setNeurological(updatedNeurological);

      if (updatedNeurological[condition]) {
        // Uncheck all other checkboxes
        const updatedNeurologicalWithoutOthers = { ...updatedNeurological };
        Object.keys(updatedNeurologicalWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedNeurologicalWithoutOthers[key] = false;
          });
        setNeurological(updatedNeurologicalWithoutOthers);
        // Clear the selectedNeurologicalSystem array
        setNeurologicalSystem([]);
      }
    } else {
      const updatedNeurological = {
        ...neurological,
        [condition]: !neurological[condition],
      };
      setNeurological(updatedNeurological);
      // If "Nothing in this neurological" is checked, uncheck it
      if (updatedNeurological.NothinginthisNeurological) {
        const updatedNeurologicalWithoutNothing = {
          ...updatedNeurological,
          NothinginthisNeurological: false,
        };
        setNeurological(updatedNeurologicalWithoutNothing);
        setNeurologicalSystem((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisNeurological")
        );
      } else {
        setNeurologicalSystem((prevSelected) =>
          updatedNeurological[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("SelectedNeurological", selectedNeurologicalSystem);

  const handlePsychiatricReview = (condition) => {
    if (condition === "NothinginthisPsychiatric") {
      const updatedPsychiatric = {
        ...psychiatric,
        [condition]: !psychiatric[condition],
      };
      setPsychiatric(updatedPsychiatric);
      if (updatedPsychiatric[condition]) {
        // Uncheck all other checkboxes
        const updatedPsychiatricWithoutOthers = { ...updatedPsychiatric };
        Object.keys(updatedPsychiatricWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedPsychiatricWithoutOthers[key] = false;
          });
        setPsychiatric(updatedPsychiatricWithoutOthers);
        // Clear the selected psychiatric conditions array
        setSelectedPsychiatric([]);
      }
    } else {
      const updatedPsychiatric = {
        ...psychiatric,
        [condition]: !psychiatric[condition],
      };
      setPsychiatric(updatedPsychiatric);
      // If "Nothing in this psychiatric condition" is checked, uncheck it
      if (updatedPsychiatric.NothinginthisPsychiatric) {
        const updatedPsychiatricWithoutNothing = {
          ...updatedPsychiatric,
          NothinginthisPsychiatric: false,
        };
        setPsychiatric(updatedPsychiatricWithoutNothing);
        setSelectedPsychiatric((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisPsychiatric")
        );
      } else {
        setSelectedPsychiatric((prevSelected) =>
          updatedPsychiatric[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };

  console.log("Selectedpsychiatric", selectedPsychiatric);

  const handleEndocrineReview = (condition) => {
    if (condition === "NothinginthisEndocrine") {
      const updatedEndocrine = {
        ...endocrine,
        [condition]: !endocrine[condition],
      };
      setEndocrine(updatedEndocrine);

      // If "Nothing in this group" is checked, uncheck all other checkboxes
      if (updatedEndocrine[condition]) {
        const updatedEndocrineWithoutOthers = { ...updatedEndocrine };
        Object.keys(updatedEndocrineWithoutOthers)
          .filter((key) => key !== condition)
          .forEach((key) => {
            updatedEndocrineWithoutOthers[key] = false;
          });
        setEndocrine(updatedEndocrineWithoutOthers);
        setSelectedEndocrineSystem([]);
      }
    } else {
      const updatedEndocrine = {
        ...endocrine,
        [condition]: !endocrine[condition],
      };
      setEndocrine(updatedEndocrine);

      // If "Nothing in this Endocrine" is checked, uncheck it
      if (updatedEndocrine.NothinginthisEndocrine) {
        const updatedEndocrineWithoutNothing = {
          ...updatedEndocrine,
          NothinginthisEndocrine: false,
        };
        setEndocrine(updatedEndocrineWithoutNothing);
        setSelectedEndocrineSystem((prevSelected) =>
          prevSelected.filter((item) => item !== "NothinginthisEndocrine")
        );
      } else {
        setSelectedEndocrineSystem((prevSelected) =>
          updatedEndocrine[condition]
            ? [...prevSelected, condition]
            : prevSelected.filter((item) => item !== condition)
        );
      }
    }
  };
  console.log("selectedEndocrineSystem", selectedEndocrineSystem);

  const getSelectedwomenMenSystem = (womenMen) => {
    const selectedwomenMenHistory = [];
    Object.keys(womenMen).forEach((key) => {
      if (womenMen[key]) {
        selectedwomenMenHistory.push(key);
      }
    });
    return selectedwomenMenHistory;
  };
  const selectedwomenMenSystem = getSelectedwomenMenSystem(womenMen);
  console.log(selectedwomenMenSystem);
  const selectedwomenInfo2History = (womenInfo2) => {
    const selectedwomenInfo2History = [];
    Object.keys(womenInfo2).forEach((key) => {
      if (womenInfo2[key]) {
        selectedwomenInfo2History.push(`${key}: ${womenInfo2[key]}`);
      }
    });
    return selectedwomenInfo2History;
  };
  const selectedwomenInfo2History1 = selectedwomenInfo2History(womenInfo2);
  console.log("selectedwomenInfo2History1", selectedwomenInfo2History1);

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };

  const [getdatastate, setgetdatastate] = useState(false);


  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(`https://vesoftometic.co.in/IcuManagement/get_SurgicalHistory_Form?Booking_Id=${IpNurseQueSelectedRow.Booking_Id}`)
        .then((response) => {
          const data = response.data[0];
          console.log("Fetched medical history data:", data);

          const updatedFormData = {
            SurgicalHistoryCheckbox: data.SurgicalHistoryCheckbox || "", 
            SurgicalHistoryOthers: data.SurgicalHistoryOthers || "",
            ListnamesAnddates: data.ListnamesAnddates || "",
            womenMenCheckbox: data.womenMenCheckbox || "",
            menCheckbox: data.menCheckbox || "",
            Dateoflastcolonoscopy: data.Dateoflastcolonoscopy || "",
          };

          // if (data.SurgicalHistoryOthers === '') {
          //   const parsedCheckboxData1 = data.SurgicalHistoryCheckbox.split(",").reduce((acc, cur) => {
          //     acc[cur] = true;
          //     return acc;
          //   }, {});
          //   setSurgicalHistory1(parsedCheckboxData1);
          // } else {
          //   setOther(true);
          //   setOtherCheckbox(data.SurgicalHistoryOthers);
          // }

          // const parsedCheckboxData4 = data.SurgicalHistoryCheckbox.split(",").reduce((acc, cur) => {
          //   acc[cur] = true;
          //   return acc;
          // }, {});
          // setSurgicalHistory1(parsedCheckboxData4);



          // if (data.SurgicalHistoryCheckbox) {
          //   const parsedCheckboxData1 = data.SurgicalHistoryCheckbox.split(",").reduce((acc, cur) => {
          //     acc[cur] = true;
          //     return acc;
          //   }, {});
          //   setSurgicalHistory1(parsedCheckboxData1);
          // }

          // if (data.SurgicalHistoryOthers) {
          //   setOther(true);
          //   setOtherCheckbox(data.SurgicalHistoryOthers);
          // }


          if (updatedFormData.SurgicalHistoryOthers === '') {
            const parsedCheckboxData1 = updatedFormData.SurgicalHistoryCheckbox.split(",").reduce((acc, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
            setSurgicalHistory1(parsedCheckboxData1);
          } else {
            setOther(true);
            setOtherCheckbox(updatedFormData.SurgicalHistoryOthers);
          }

          const parsedCheckboxData2 = data.womenMenCheckbox.split(",").reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
          setWomenMen(parsedCheckboxData2);

          const parsedCheckboxData3 = data.menCheckbox.split(",").reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
          setMen({ ...men, ...parsedCheckboxData3});

          setPatientInfo2({ Listnamesdates: data.ListnamesAnddates });
          
          setMenText({ Dateoflastcolonoscopy: data.Dateoflastcolonoscopy  });
          console.log(updatedFormData,'updatedFormData');
        })
        .catch((error) => {
          console.error('Error fetching surgical history data:', error);
        });
    }
  }, [IpNurseQueSelectedRow, getdatastate]);


  const handlePrint = useReactToPrint({
    onBeforePrint: () => {
      console.log("Before");
      setOpenModal(false);
      const selectedCheckboxes = [];

      // Function to add selected items to the array for printing
      const addToSelected = (data) => {
        if (Array.isArray(data)) {
          // If data is an array, concatenate it with selectedCheckboxes
          selectedCheckboxes.push(...data);
        } else if (typeof data === "object") {
          // If data is an object, iterate through its keys and add selected ones
          Object.keys(data).forEach((key) => {
            if (key !== "Nothinginthisgroup" && data[key] !== false) {
              if (key === "Weightloss") {
                selectedCheckboxes.push(
                  `${key},Howmuch -${data["Howmuch"]} kgs`
                );
              } else {
                selectedCheckboxes.push(key);
              }
            }
          });
        } else if (typeof data === "string") {
          // If data is a string, add it directly to selectedCheckboxes
          selectedCheckboxes.push(data);
        }
      };

      // Add all sections and selected items for printing

      // addToSelected(urinary);

      // addToSelected(gynecologic);

      // addToSelected(hematologic);
      addToSelected(womenMen);
      addToSelected(womenInfo2);
      // setGeneralArray([]);
      // setUniqueValues(new Set());
      // setGynecologicArray([]);

      // Add the "Skin" section if there are selected skin systems
      if (selectedSkinSystem.length > 0) {
        addToSelected(skin);
      }
      if (generalArray.length > 0) {
        addToSelected(reviewSystem);
      }
      if (selectedEyeSystem.length > 0) {
        addToSelected(reviewEyes);
      }
      if (suregicalHistory.length > 0) {
        addToSelected(SurgicalHistory1);
      }
      if (selectedCardioSystem.length > 0) {
        addToSelected(cardiovascular);
      }
      if (selectedRespiratorySystem.length > 0) {
        addToSelected(respiratory);
      }
      if (generalArray.length > 0) {
        addToSelected(reviewSystem);
      }
      if (SelectedMusculoskeletal.length > 0) {
        addToSelected(musculoskeletal);
      }
      if (selectedNeurologicalSystem.length > 0) {
        addToSelected(neurological);
      }
      if (selectedPsychiatric.length > 0) {
        addToSelected(psychiatric);
      }
      if (selectedAllergic.length > 0) {
        addToSelected(allergic);
      }
      if (selectedBreast.length > 0) {
        addToSelected(breast);
      }
      if (selectedEnmt.length > 0) {
        addToSelected(enmt);
      }
      if (selectedDigestive.length > 0) {
        addToSelected(digestive);
      }
      if (selectedHematologic.length > 0) {
        addToSelected(hematologic);
      }
      if (gynecologicArray.length > 0) {
        addToSelected(gynecologic);
      }
      if (urinaryArray.length > 0) {
        addToSelected(urinary);
      }

      console.log("selectedcheckboxes", selectedCheckboxes);
    },

    content: () => document.getElementById("reactprintcontent"),
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 0.5; // Add padding to height
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });

          pdf.html(printdata, {
            x: padding,
            y: padding,
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              
              console.log("generatedPdfBlob", generatedPdfBlob);
              const data = new FormData();
              data.append('generatedPdfBlob', generatedPdfBlob);
              data.append('PatientId',IpNurseQueSelectedRow.PatientId);
              data.append('PatientName',IpNurseQueSelectedRow.PatientName);
              // data.append('Admitdate',IpNurseQueSelectedRow.Admitdate);
              // data.append('PrimaryDoctor',IpNurseQueSelectedRow.PrimaryDoctor);
              data.append('Booking_Id',IpNurseQueSelectedRow.Booking_Id);
              data.append('CreatedBy',userRecord?.username);
              data.append('Location',userRecord?.location);
              data.append('ListnamesAnddates',patientInfo2.Listnamesdates);
              data.append('Dateoflastcolonoscopy',mentext.Dateoflastcolonoscopy);
              data.append('Type','Surgical');
              data.append('SurgicalHistoryCheckbox', Object.keys(SurgicalHistory1).filter(p => SurgicalHistory1[p]).join(','));
              data.append('womenMenCheckbox', Object.keys(womenMen).filter(p => womenMen[p]).join(','));
              data.append('menCheckbox', Object.keys(men).filter(p => men[p]).join(','));

              if (other) {
                data.append('SurgicalHistoryOthers', otherCheckbox);
              }
              // Example Axios POST request to send the generated PDF to the server
              axios.post(`https://vesoftometic.co.in/IcuManagement/insert_Surgical_History`, data)
                    .then(response => {
                      // Handle successful response if needed
                      console.log('Print request sent successfully');
                    })
                    .catch(error => {
                      // Handle error if needed
                      console.error('Error sending print request:', error);
                    });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
        setpreview(false);
        setOpenModal(true);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const handlePrintSave = () => {
    setOpenModal(false);
    setPrintMode(true); // Set print mode to true before printing
    setTimeout(() => {
      handlePrint();
      setPrintMode(false); // Reset print mode after printing
    }, 3500);
  };

  return (
    <body>
      
      <div className="Medical_History_container" id="reactprintcontent">
        

       
      
        <div className="form-section5">
          <div className=" dkwjd">
            <h3>Personal Surgical History </h3>
          </div>
          <div className="form-section5">
            <div className=" dkwjd">
              
            </div>
          </div>
          <br></br>
          {openModal ? (
            <div className="div_ckkkbox_head">
              {Object.keys(SurgicalHistory1).map((labelname, indx) => (
                <React.Fragment key={labelname}>
                  {indx % 7 === 0 && (
                    <div className="div_ckkck_box">
                      {Object.keys(SurgicalHistory1)
                        .slice(indx, indx + 7)
                        .map((key) => (
                          <label key={key} className="checkbox-label">
                            <input
                              type="checkbox"
                              id={key}
                              className="checkbox-input"
                              checked={SurgicalHistory1[key]}
                              onChange={() => handleSurgicalHistoryChange(key)}
                              
                            />
                            {formatLabel(key)}
                          </label>
                        ))}
                    </div>
                  )}
                </React.Fragment>
                
              ))}
             
            </div>
          ) : (
            <div>
              {SurgicalHistory1.length > 0 ? (
                <div>
                  <h5>Personal Surgical History</h5>
                  <br />
                  <ul>
                    {SurgicalHistory1.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>No surgical history </div>
              )}
            </div>
          )}
        </div>
        {openModal ? (
          <div className="checkbox-label ">
                    <label key="other"  className="checkbox-label1 " >
                            <input
                                type="checkbox"
                                id="other"
                                className="checkbox-input"
                                checked={other}
                                onChange={handleOthersChange}
                                // onChange={(e) => setOther(e.target.checked)}
                            />
                            <span style={{ marginRight: '8px' }}>Others</span>
                            
                    </label>
                    {other && (
                            <textarea
                                id="others-textarea"
                                value={otherCheckbox}
                                onChange={(e) => setOtherCheckbox(e.target.value)}
                                // onChange={handleOthersChange}
                                placeholder="Please specify..."
                                style={{ marginLeft: '8px' }}
                                className="textarea-wide1"
                            />
                            )}

              </div>
        ):null}
              

        <br></br>

        <div className="text_fr_surgcl_0">
          {openModal &&
            (patientInfo.gender.toLowerCase() === "female" ||
              patientInfo.gender.toLowerCase() === "others") && (
              <div className="text_fr_surgcl_lbld">
                <div className="iqw7">
                  <label> Women - </label>
                </div>
                <div className="oii8u">
                  <div className="sdxsxc343">
                    <label>Number of pregnancies:</label>
                    <input
                      type="text"
                      name="numberofPregnancies"
                      value={womenInfo2.numberofPregnancies}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                  <div className="sdxsxc343">
                    <label>Number of deliveries:</label>
                    <input
                      type="text"
                      name="numberofDeliveries"
                      value={womenInfo2.numberofDeliveries}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                  <div className="sdxsxc343">
                    <label>Vaginal:</label>
                    <input
                      type="text"
                      name="Vaginal"
                      value={womenInfo2.Vaginal}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                  <div className="sdxsxc343">
                    <label>C-sections:</label>
                    <input
                      type="text"
                      name="csections"
                      value={womenInfo2.csections}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                  <div className="sdxsxc343">
                    <label>Miscarriages:</label>
                    <input
                      type="text"
                      name="Miscarriages"
                      value={womenInfo2.Miscarriages}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                  <div className="sdxsxc343">
                    <label>VIPs (abortions):</label>
                    <input
                      type="text"
                      name="abortions"
                      value={womenInfo2.abortions}
                      onChange={handleInputboxChange3}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>

        <br></br>
        <br></br>

        <div className="txtare_div_surgical_head">
          <div className="txtare_div_surgical">
            <label> Names and Date of Surgeries :</label>
            <textarea
              name="Listnamesdates"
              value={patientInfo2.Listnamesdates}
              onChange={handleInputChange12}
            ></textarea>
          </div>

         
        </div>
        <br></br>
        <div>
  <div className="form-section5">
    <div className="dkwjd">
      <h3>Cancer Health Habits</h3>
    </div>
    <br />

    <div className="health-habits-container">
      <div className="women-section">
        {printMode ? (
          <div className="print-page">
            <div className="Medical_History_container" id="reactprintcontent">
              <div className="women_head_men">
                <div className="div_ckkkbox_head women_chk_box">
                  <h5 className="aaa">Women</h5>
                  <br />
                  <div className="w99jdid_head">
                    <div className="div_ckkck_box w99jdid">
                      <div className="weedcvt65_head">
                        <label>Breast :</label>
                      </div>
                      <div className="weedcvt65">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Monthlyselfexam"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Monthlyselfexam")
                            }
                          />
                          Monthly self-exam
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Yearlyphysicianexam"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Yearlyphysicianexam")
                            }
                          />
                          Yearly physician-exam
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Lastmammogram"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Lastmammogram")
                            }
                          />
                          Last mammogram
                        </label>
                      </div>
                    </div>
                    <div className="div_ckkck_box w99jdid">
                      <div className="weedcvt65_head">
                        <label>GYN :</label>
                      </div>
                      <div className="weedcvt65">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["YearlyGYNexam"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("YearlyGYNexam")
                            }
                          />
                          Yearly GYN exam
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["YearlyPAPexam"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("YearlyPAPexam")
                            }
                          />
                          Yearly PAP exam
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Lastmammogram2"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Lastmammogram2")
                            }
                          />
                          Last mammogram
                        </label>
                      </div>
                    </div>
                    <div className="div_ckkck_box w99jdid">
                      <div className="weedcvt65_head">
                        <label>Skin :</label>
                      </div>
                      <div className="weedcvt65">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Highsunexposure"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Highsunexposure")
                            }
                          />
                          High sun exposure
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={womenMen["Yearlyskinexam"]}
                            onChange={() =>
                              handleCheckboxChangeWomenMen("Yearlyskinexam")
                            }
                          />
                          Yearly skin exam
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text_fr_surgcl_0">
            <div className="women_head_men">
              <div className="div_ckkkbox_head women_chk_box">
                <h5 className="aaa">Women</h5>
                <br />
                <div className="w99jdid_head">
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Breast :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Monthlyselfexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Monthlyselfexam")
                          }
                        />
                        Monthly self-exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyphysicianexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyphysicianexam")
                          }
                        />
                        Yearly physician-exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Lastmammogram"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Lastmammogram")
                          }
                        />
                        Last mammogram
                      </label>
                    </div>
                  </div>
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>GYN :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["YearlyGYNexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("YearlyGYNexam")
                          }
                        />
                        Yearly GYN exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["YearlyPAPexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("YearlyPAPexam")
                          }
                        />
                        Yearly PAP exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Lastmammogram2"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Lastmammogram2")
                          }
                        />
                        Last mammogram
                      </label>
                    </div>
                  </div>
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Skin :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Highsunexposure"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Highsunexposure")
                          }
                        />
                        High sun exposure
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyskinexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyskinexam")
                          }
                        />
                        Yearly skin exam
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="men-section">
        {printMode ? (
          <div className="print-page">
            <div className="Medical_History_container" id="reactprintcontent">
              <div className="women_head_men">
                <div className="div_ckkkbox_head women_chk_box">
                  <h5 className="aaa">Men</h5>
                  <br />
                  <div className="w99jdid_head">
                    <div className="div_ckkck_box w99jdid">
                      <div className="weedcvt65_head">
                        <label>Prostate :</label>
                      </div>
                      <div className="weedcvt65">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="Yearlyrectalexam"
                            checked={men["Yearlyrectalexam"]}
                            onChange={(e) => handleCheckboxChangeMen("Yearlyrectalexam", e)}
                          />
                          Yearly rectal exam
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="YearlyPSAbloodtest"
                            checked={men["YearlyPSAbloodtest"]}
                            onChange={(e) => handleCheckboxChangeMen("YearlyPSAbloodtest", e)}
                          />
                          Yearly PSA blood test
                        </label>
                      </div>
                    </div>
                    <div className="div_ckkck_box w99jdid">
                      <div className="weedcvt65_head">
                        <label>Colon :</label>
                      </div>
                      <div className="weedcvt65">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="Yearlyrectalexam2"
                            checked={men["Yearlyrectalexam2"]}
                            onChange={(e) => handleCheckboxChangeMen("Yearlyrectalexam2", e)}
                          />
                          Yearly rectal exam
                        </label>
                        <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              name="Yearlystooltestforblood"
                              checked={men.Yearlystooltestforblood}
                              onChange={(e) =>
                                handleCheckboxChangeMen(
                                  "Yearlystooltestforblood", e
                                )
                              }
                            />
                            Yearly stool test for blood
                          </label>
                        <label className="checkbox-label">
                          Date of last colonoscopy :
                          <input
                            type="text"
                            className="checkbox-input iojiu7"
                            name="Dateoflastcolonoscopy"
                            readOnly={!men.Yearlystooltestforblood}
                            value={men.Dateoflastcolonoscopy}
                            onChange={(e) => handleCheckboxChangeMen("Dateoflastcolonoscopy", e)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text_fr_surgcl_0">
              {/* //...............jeeva */}
            <div className="women_head_men">
              <div className="div_ckkkbox_head women_chk_box">
                <h5 className="aaa">Men</h5>
                <br />
                <div className="w99jdid_head">
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Prostate :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          name="Yearlyrectalexam"
                          checked={men["Yearlyrectalexam"]}
                          onChange={(e) => handleCheckboxChangeMen("Yearlyrectalexam", e)}
                        />
                        Yearly rectal exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          name="YearlyPSAbloodtest"
                          checked={men["YearlyPSAbloodtest"]}
                          onChange={(e) => handleCheckboxChangeMen("YearlyPSAbloodtest", e)}
                        />
                        Yearly PSA blood test
                      </label>
                    </div>
                  </div>
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Colon :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          name="Yearlyrectalexam2"
                          checked={men["Yearlyrectalexam2"]}
                          onChange={(e) => handleCheckboxChangeMen("Yearlyrectalexam2", e)}
                        />
                        Yearly rectal exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          name="Yearlystooltestforblood"
                          checked={men.Yearlystooltestforblood}
                          onChange={(e) => handleCheckboxChangeMen("Yearlystooltestforblood", e)}
                        />
                        Yearly stool test for blood
                      </label>
                      <label className="checkbox-label">
                        Date of last colonoscopy :
                        <input
                          type="text"
                          className="checkbox-input iojiu7"
                          name="Dateoflastcolonoscopy"
                          // readOnly={men.Dateoflastcolonoscopy}
                          value={mentext.Dateoflastcolonoscopy}
                          onChange={handleInputChangeMen}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>

{/* //...............jeeva */}



        <br></br>

        {/* <div className="form-section5">
          <div className="dkwjd">
            <h3>Review of Systems </h3>
          </div>
          <div className="form-section5">
            <div className=" dkwjd">
              <h5>
                {" "}
                Do you currently have any of the following symptoms or
                conditions (Check if yes)
              </h5>
            </div>
          </div>
          <br></br>

          <div className="div_ckkkbox_head">
            {openModal ? (
              <div className="div_ckkck_box">
                <div className="ijenjd4">
                  <label>General:</label>
                  <label className="checkbox-label jkb6">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={reviewSystem.Nothinginthisgroup}
                      name="Nothinginthisgroup"
                      onChange={(e) =>
                        handleInputChange23("Nothinginthisgroup", e)
                      }
                    />
                    Nothing in this group
                  </label>
                </div>
                <br />
                <div className="div_ckkkbox_head">
                  {Object.keys(reviewSystem)
                    .filter(
                      (key) => key !== "Nothinginthisgroup" && key !== "Howmuch"
                    )
                    .map((key, indx) => (
                      <React.Fragment key={key}>
                        {indx % 10 === 0 && (
                          <div className="div_ckkck_box">
                            {Object.keys(reviewSystem)
                              .filter(
                                (k) =>
                                  k !== "Nothinginthisgroup" && k !== "Howmuch"
                              )
                              .slice(indx, indx + 10)
                              .map((subKey) => (
                                <label key={subKey} className="checkbox-label">
                                  {subKey === "Weightloss" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        id={subKey}
                                        className="checkbox-input"
                                        checked={reviewSystem[subKey]}
                                        name={subKey}
                                        onChange={(e) =>
                                          handleInputChange23(subKey, e)
                                        }
                                        disabled={
                                          reviewSystem.Nothinginthisgroup
                                        }
                                      />
                                      Weightloss - Howmuch
                                      <input
                                        type="text"
                                        className="checkbox-input iojiu7"
                                        value={reviewSystem.Howmuch}
                                        readOnly={!reviewSystem.Weightloss}
                                        name="Howmuch"
                                        onChange={(e) =>
                                          handleInputChange23("Howmuch", e)
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        id={subKey}
                                        className="checkbox-input"
                                        checked={reviewSystem[subKey]}
                                        name={subKey}
                                        onChange={(e) =>
                                          handleInputChange23(subKey, e)
                                        }
                                        disabled={
                                          reviewSystem.Nothinginthisgroup
                                        }
                                      />
                                      {formatLabel(subKey)}
                                    </>
                                  )}
                                </label>
                              ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                {reviewSystem.Nothinginthisgroup === false &&
                  generalArray.length > 0 && (
                    <div>
                      <h5>General</h5>
                      <br />
                      {generalArray.map((data, index) => (
                        <ul key={index}>
                          <li>{data}</li>
                        </ul>
                      ))}
                    </div>
                  )}
                {reviewSystem.Nothinginthisgroup === true &&
                  generalArray.length === 0 && (
                    <div>
                      <h5>General</h5>
                      <br />
                      <ul>
                        <li>Nill</li>
                      </ul>
                    </div>
                  )}
              </div>
            )}

            {openModal ? (
              <div className="div_ckkck_box">
                <div className="ijenjd4">
                  <label>Eyes :</label>
                  <label className="checkbox-label jkb6">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={reviewEyes.Nothinginthiseye}
                      onChange={() =>
                        handleCheckboxEyeReview("Nothinginthiseye")
                      }
                    />
                    Nothing in this group
                  </label>
                </div>
                <br />
                <div className="div_ckkkbox_head">
                  {Object.keys(reviewEyes)
                    .filter((q) => q !== "Nothinginthiseye")
                    .map((labelname1, indx) => (
                      <React.Fragment key={labelname1}>
                        {indx % 3 === 0 && (
                          <div className="div_ckkck_box">
                            {Object.keys(reviewEyes)
                              .filter((q) => q !== "Nothinginthiseye")
                              .slice(indx, indx + 3)
                              .map((key) => (
                                <label key={key} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    id={key}
                                    className="checkbox-input"
                                    checked={reviewEyes[key]}
                                    onChange={() =>
                                      handleCheckboxEyeReview(key)
                                    }
                                    disabled={reviewEyes.Nothinginthiseye}
                                  />
                                  {formatLabel(key)}
                                </label>
                              ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                {reviewEyes.Nothinginthiseye === false &&
                  selectedEyeSystem.length > 0 && (
                    <div>
                      <h5>Eyes</h5>
                      <br />
                      {selectedEyeSystem.map((data, index) => (
                        <ul key={index}>
                          <li>{data}</li>
                        </ul>
                      ))}
                    </div>
                  )}
                {reviewEyes.Nothinginthiseye === true &&
                  selectedEyeSystem.length === 0 && (
                    <div>
                      <h5>Eyes</h5>
                      <br />
                      <ul>
                        <li>Nill</li>
                      </ul>
                    </div>
                  )}
              </div>
            )}

            {openModal ? (
              <div className="div_ckkck_box">
                <div className="ijenjd4">
                  <label>Cardiovascular : </label>
                  <label className="checkbox-label jkb6">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={cardiovascular.Nothinginthiscardio}
                      onChange={() =>
                        handleCheckboxCardioReview("Nothinginthiscardio")
                      }
                    />
                    Nothing in this group
                  </label>
                </div>
                <br />
                <div className="div_ckkkbox_head">
                  {Object.keys(cardiovascular)
                    .filter((s) => s !== "Nothinginthiscardio")
                    .map((labelname2, indx) => (
                      <React.Fragment key={labelname2}>
                        {indx % 5 === 0 && (
                          <div className="div_ckkck_box">
                            {Object.keys(cardiovascular)
                              .filter((s) => s !== "Nothinginthiscardio")
                              .slice(indx, indx + 5)
                              .map((key) => (
                                <label key={key} className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    id={key}
                                    className="checkbox-input"
                                    checked={cardiovascular[key]}
                                    onChange={() =>
                                      handleCheckboxCardioReview(key)
                                    }
                                    disabled={
                                      cardiovascular.Nothinginthiscardio
                                    }
                                  />
                                  {formatLabel(key)}
                                </label>
                              ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                {cardiovascular.Nothinginthiscardio === false &&
                  selectedCardioSystem.length > 0 && (
                    <div>
                      <h5>Cardiovascular</h5>
                      <br />
                      {selectedCardioSystem.map((data, index) => (
                        <ul key={index}>
                          <li>{data}</li>
                        </ul>
                      ))}
                    </div>
                  )}
                {cardiovascular.Nothinginthiscardio === true &&
                  selectedCardioSystem.length === 0 && (
                    <div>
                      <h5>Cardiovascular</h5>
                      <br />
                      <ul>
                        <li>Nill</li>
                      </ul>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div> */}
        <br></br>

        {/* <div className="div_ckkkbox_head">
          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Respiratory :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={respiratory.NothinginthisRespiratory}
                    onChange={() =>
                      handleCheckRespiratoryReview("NothinginthisRespiratory")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(respiratory)
                  .filter((a) => a !== "NothinginthisRespiratory")
                  .map((labelname4, indx) => (
                    <React.Fragment key={labelname4}>
                      {indx % 8 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(respiratory)
                            .filter((p) => p !== "NothinginthisRespiratory")
                            .slice(indx, indx + 8)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={respiratory[key]}
                                  onChange={() =>
                                    handleCheckRespiratoryReview(key)
                                  }
                                  disabled={
                                    respiratory.NothinginthisRespiratory
                                  }
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {respiratory.NothinginthisRespiratory === false &&
                selectedRespiratorySystem.length > 0 && (
                  <div>
                    <h5>Respiratory</h5>
                    <br />
                    {selectedRespiratorySystem.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {respiratory.NothinginthisRespiratory === true &&
                selectedRespiratorySystem.length === 0 && (
                  <div>
                    <h5>Respiratory</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Musculoskeletal:</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={musculoskeletal.NothinginthisMusculoskeletal}
                    onChange={() =>
                      handleMusculoskeletalReview(
                        "NothinginthisMusculoskeletal"
                      )
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />

              <div className="div_ckkkbox_head">
                {Object.keys(musculoskeletal)
                  .filter((q) => q !== "NothinginthisMusculoskeletal")
                  .map((labelname1, indx) => (
                    <React.Fragment key={labelname1}>
                      {indx % 7 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(musculoskeletal)
                            .filter((q) => q !== "NothinginthisMusculoskeletal")
                            .slice(indx, indx + 7)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={musculoskeletal[key]}
                                  onChange={() =>
                                    handleMusculoskeletalReview(key)
                                  }
                                  disabled={
                                    musculoskeletal.NothinginthisMusculoskeletal
                                  }
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {musculoskeletal.NothinginthisMusculoskeletal === false &&
                selectedEyeSystem.length > 0 && (
                  <div>
                    <h5>Musculoskeletal</h5>
                    <br />
                    {SelectedMusculoskeletal.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {musculoskeletal.NothinginthisMusculoskeletal === true &&
                SelectedMusculoskeletal.length === 0 && (
                  <div>
                    <h5>Musculoskeletal</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Neurological : </label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={neurological.NothinginthisNeurological}
                    onChange={() =>
                      handleNeurologicalReview("NothinginthisNeurological")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(neurological)
                  .filter((t) => t !== "NothinginthisNeurological")
                  .map((labelname6, indx) => (
                    <React.Fragment key={labelname6}>
                      {indx % 9 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(neurological)
                            .filter((t) => t !== "NothinginthisNeurological")
                            .slice(indx, indx + 9)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={neurological[key]}
                                  onChange={() => handleNeurologicalReview(key)}
                                  disabled={
                                    neurological.NothinginthisNeurological
                                  }
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {neurological.NothinginthisNeurological === false &&
                selectedNeurologicalSystem.length > 0 && (
                  <div>
                    <h5>Neurological</h5>
                    <br />
                    {selectedNeurologicalSystem.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {neurological.NothinginthisNeurological === true &&
                selectedEyeSystem.length === 0 && (
                  <div>
                    <h5>Neurological</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div> */}

        <br></br>
{/* 
        <div className="div_ckkkbox_head">
          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Psychiatric :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={psychiatric.NothinginthisPsychiatric}
                    onChange={() =>
                      handlePsychiatricReview("NothinginthisPsychiatric")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(psychiatric)
                  .filter((key) => key !== "NothinginthisPsychiatric")
                  .map((Key, indx) => (
                    <React.Fragment key={Key}>
                      {indx % 6 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(psychiatric)
                            .filter((key) => key !== "NothinginthisPsychiatric")
                            .slice(indx, indx + 6)
                            .map((subKey) => (
                              <label key={subKey} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={subKey}
                                  className="checkbox-input"
                                  checked={psychiatric[subKey]}
                                  onChange={() =>
                                    handlePsychiatricReview(subKey)
                                  }
                                  disabled={
                                    psychiatric.NothinginthisPsychiatric
                                  }
                                />
                                {formatLabel(subKey)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {psychiatric.NothinginthisPsychiatric === false &&
                selectedPsychiatric.length > 0 && (
                  <div>
                    <h5>Psychiatric</h5>
                    <br />
                    {selectedPsychiatric.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {psychiatric.NothinginthisPsychiatric === true &&
                selectedPsychiatric.length === 0 && (
                  <div>
                    <h5>Psychiatric</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Skin :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={skin.NothinginthisSkin}
                    onChange={() => handleSkinReview("NothinginthisSkin")}
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(skin)
                  .filter((key) => key !== "NothinginthisSkin")
                  .map((subKey, indx) => (
                    <React.Fragment key={subKey}>
                      {indx % 6 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(skin)
                            .filter((key) => key !== "NothinginthisSkin")
                            .slice(indx, indx + 6)
                            .map((innerSubKey) => (
                              <label
                                key={innerSubKey}
                                className="checkbox-label"
                              >
                                <>
                                  <input
                                    type="checkbox"
                                    id={innerSubKey}
                                    className="checkbox-input"
                                    checked={skin[innerSubKey]}
                                    onChange={() =>
                                      handleSkinReview(innerSubKey)
                                    }
                                    disabled={skin.NothinginthisSkin}
                                  />
                                  {formatLabel(innerSubKey)}
                                </>
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {skin.NothinginthisSkin === false &&
                selectedSkinSystem.length > 0 && (
                  <div>
                    <h5>Skin</h5>
                    <br />
                    {selectedSkinSystem.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {skin.NothinginthisSkin === true &&
                selectedSkinSystem.length === 0 && (
                  <div>
                    <h5>Skin</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Endocrine : </label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={endocrine.NothinginthisEndocrine}
                    onChange={() =>
                      handleEndocrineReview("NothinginthisEndocrine")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(endocrine)
                  .filter((r) => r !== "NothinginthisEndocrine")
                  .map((labelname8, indx) => (
                    <React.Fragment key={labelname8}>
                      {indx % 4 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(endocrine)
                            .filter((r) => r !== "NothinginthisEndocrine")
                            .slice(indx, indx + 4)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={endocrine[key]}
                                  onChange={() => handleEndocrineReview(key)}
                                  disabled={endocrine.NothinginthisEndocrine}
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {endocrine.NothinginthisEndocrine === false &&
                selectedEndocrineSystem.length > 0 && (
                  <div>
                    <h5>Endrocine</h5>
                    <br />
                    {selectedEndocrineSystem.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {endocrine.NothinginthisEndocrine === true &&
                selectedEndocrineSystem.length === 0 && (
                  <div>
                    <h5>Endrocine</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div> */}

        {/* <div className="div_ckkkbox_head">
          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Allergic, Immunologic :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={allergic.Nothinginthisallergic}
                    onChange={() =>
                      handleCheckboxAllergicReview("Nothinginthisallergic")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(allergic)
                  .filter((c) => c !== "Nothinginthisallergic")
                  .map((labelname7, indx) => (
                    <React.Fragment key={labelname7}>
                      {indx % 4 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(allergic)
                            .filter((c) => c !== "Nothinginthisallergic")
                            .slice(indx, indx + 6)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={allergic[key]}
                                  onChange={() =>
                                    handleCheckboxAllergicReview(key)
                                  }
                                  disabled={allergic.Nothinginthisallergic}
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {allergic.Nothinginthisallergic === false &&
                selectedAllergic.length > 0 && (
                  <div>
                    <h5>Alleric,Immunologic</h5>
                    <br />
                    {selectedAllergic.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {allergic.Nothinginthisallergic === true &&
                selectedAllergic.length === 0 && (
                  <div>
                    <h5>Alleric,Immunologic</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Breast: :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={breast.Nothinginthisbreast}
                    onChange={() =>
                      handleCheckBreastReview("Nothinginthisbreast")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(breast)
                  .filter((d) => d !== "Nothinginthisbreast")
                  .map((labelname8, indx) => (
                    <React.Fragment key={labelname8}>
                      {indx % 3 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(breast)
                            .filter((d) => d !== "Nothinginthisbreast")
                            .slice(indx, indx + 3)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={breast[key]}
                                  onChange={() => handleCheckBreastReview(key)}
                                  disabled={breast.Nothinginthisbreast}
                                />
                                {formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {breast.Nothinginthisbreast === false &&
                selectedBreast.length > 0 && (
                  <div>
                    <h5>Breast</h5>
                    <br />
                    {selectedBreast.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {breast.Nothinginthisbreast === true &&
                selectedBreast.length === 0 && (
                  <div>
                    <h5>Breast</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Gynecologic (female) : </label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={gynecologic.NothinginthisGynecologic}
                    onChange={(e) =>
                      handleInputChange33(e, "NothinginthisGynecologic")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(gynecologic)
                  .filter(
                    (key) =>
                      key !== "NothinginthisGynecologic" && key !== "LastPeriod"
                  )
                  .map((key, indx) => (
                    <React.Fragment key={key}>
                      {indx % 7 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(gynecologic)
                            .filter(
                              (k) =>
                                k !== "NothinginthisGynecologic" &&
                                k !== "LastPeriod"
                            )
                            .slice(indx, indx + 7)
                            .map((subKey) => (
                              <label key={subKey} className="checkbox-label">
                                {subKey === "IrregularPeriods" ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={subKey}
                                      className="checkbox-input"
                                      checked={gynecologic[subKey]}
                                      onChange={(e) =>
                                        handleInputChange33(e, subKey)
                                      }
                                      disabled={
                                        gynecologic.NothinginthisGynecologic
                                      }
                                    />
                                    IrregularPeriods - LastPeriod
                                    <input
                                      type="text"
                                      className="checkbox-input iojiu7"
                                      value={gynecologic.LastPeriod}
                                      name="LastPeriod"
                                      readOnly={!gynecologic.IrregularPeriods}
                                      onChange={(e) =>
                                        handleInputChange33(e, "LastPeriod")
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={subKey}
                                      className="checkbox-input"
                                      checked={gynecologic[subKey]}
                                      onChange={(e) =>
                                        handleInputChange33(e, subKey)
                                      }
                                      disabled={
                                        gynecologic.NothinginthisGynecologic
                                      }
                                    />
                                    {formatLabel(subKey)}
                                  </>
                                )}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {gynecologic.NothinginthisGynecologic === false &&
                gynecologicArray.length > 0 && (
                  <div>
                    <h5>Gynecologic (female)</h5>
                    <br />
                    {gynecologicArray.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {gynecologic.NothinginthisGynecologic === true &&
                gynecologicArray.length === 0 && (
                  <div>
                    <h5>Gynecologic (female)</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div> */}

        <br></br>
{/* 
        <div className="div_ckkkbox_head">
          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Ear, Nose, Mouth, Throat:</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={enmt.NothinginthisGroup}
                    onChange={() =>
                      handleCheckboxENMTReview("NothinginthisGroup")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(enmt)
                  .filter((c) => c !== "NothinginthisGroup")
                  .map((labelname7, indx) => (
                    <React.Fragment key={labelname7}>
                      {indx % 12 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(enmt)
                            .filter((c) => c !== "NothinginthisGroup")
                            .slice(indx, indx + 12)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={enmt[key]}
                                  onChange={() => handleCheckboxENMTReview(key)}
                                  disabled={enmt.NothinginthisGroup}
                                />
                                {key === "EaracheInfection"
                                  ? "Earache / Infection"
                                  : key === "RunnyNoseCold"
                                  ? "Runny nose / Cold"
                                  : key === "NeckStiffinessPain"
                                  ? "Neck stiffness / Pain"
                                  : key === "EnlargedNeckGlandsMasses"
                                  ? "Enlarged neck glands / Masses"
                                  : formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {enmt.NothinginthisGroup === false && selectedEnmt.length > 0 && (
                <div>
                  <h5>Ear, Nose, Mouth, Throat</h5>
                  <br />
                  {selectedEnmt.map((data, index) => (
                    <ul key={index}>
                      <li>{data}</li>
                    </ul>
                  ))}
                </div>
              )}
              {enmt.NothinginthisGroup === true &&
                selectedEnmt.length === 0 && (
                  <div>
                    <h5>Ear, Nose, Mouth, Throat</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Digestive :</label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={digestive.Nothinginthisdigestive}
                    onChange={() =>
                      handleCheckDigestiveReview("Nothinginthisdigestive")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(digestive)
                  .filter((d) => d !== "Nothinginthisdigestive")
                  .map((labelname8, indx) => (
                    <React.Fragment key={labelname8}>
                      {indx % 13 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(digestive)
                            .filter((d) => d !== "Nothinginthisdigestive")
                            .slice(indx, indx + 13)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={digestive[key]}
                                  onChange={() =>
                                    handleCheckDigestiveReview(key)
                                  }
                                  disabled={digestive.Nothinginthisdigestive}
                                />
                                {key === "EarlySatiety"
                                  ? "Early satiety (fill up easy)"
                                  : key === "DarkTarryStools"
                                  ? "Dark, tarry stools"
                                  : key === "PoorControlBMUrgency"
                                  ? "Poor control of BMs, urgency"
                                  : formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {digestive.Nothinginthisdigestive === false &&
                selectedDigestive.length > 0 && (
                  <div>
                    <h5>Digestive</h5>
                    <br />
                    {selectedDigestive.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {digestive.Nothinginthisdigestive === true &&
                selectedDigestive.length === 0 && (
                  <div>
                    <h5>Digestive</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}

          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Hematologic, Lymphatic: </label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={hematologic.Nothinginthishematologic}
                    onChange={() =>
                      handleCheckHematologicReview("Nothinginthishematologic")
                    }
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(hematologic)
                  .filter((c) => c !== "Nothinginthishematologic")
                  .map((labelname7, indx) => (
                    <React.Fragment key={labelname7}>
                      {indx % 10 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(hematologic)
                            .filter((c) => c !== "Nothinginthishematologic")
                            .slice(indx, indx + 10)
                            .map((key) => (
                              <label key={key} className="checkbox-label">
                                <input
                                  type="checkbox"
                                  id={key}
                                  className="checkbox-input"
                                  checked={hematologic[key]}
                                  onChange={() =>
                                    handleCheckHematologicReview(key)
                                  }
                                  disabled={
                                    hematologic.Nothinginthishematologic
                                  }
                                />
                                {key === "LowRedBloodCellCount"
                                  ? "Low red blood cell count (anemia)"
                                  : key === "prolongedBleeding"
                                  ? "Prolonged bleeding with cuts, surgery"
                                  : formatLabel(key)}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {hematologic.Nothinginthishematologic === false &&
                selectedHematologic.length > 0 && (
                  <div>
                    <h5>Hematologic, Lymphatic</h5>
                    <br />
                    {selectedHematologic.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {hematologic.Nothinginthishematologic === true &&
                selectedHematologic.length === 0 && (
                  <div>
                    <h5>Hematologic, Lymphatic</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div> */}

        <br></br>
        {/* <div className="div_ckkkbox_head">
          {openModal ? (
            <div className="div_ckkck_box">
              <div className="ijenjd4">
                <label>Urinary : </label>
                <label className="checkbox-label jkb6">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={urinary.Nothinginthisurinary}
                    name="Nothinginthisurinary"
                    onChange={(e) => handleChange21("Nothinginthisurinary", e)}
                  />
                  Nothing in this group
                </label>
              </div>
              <br />
              <div className="div_ckkkbox_head">
                {Object.keys(urinary)
                  .filter(
                    (key) =>
                      key !== "Nothinginthisurinary" &&
                      key !== "Numberoftimespernight"
                  )
                  .map((key, indx) => (
                    <React.Fragment key={key}>
                      {indx % 10 === 0 && (
                        <div className="div_ckkck_box">
                          {Object.keys(urinary)
                            .filter(
                              (k) =>
                                k !== "Nothinginthisurinary" &&
                                k !== "Numberoftimespernight"
                            )
                            .slice(indx, indx + 10)
                            .map((subKey) => (
                              <label key={subKey} className="checkbox-label">
                                {subKey === "GetUpAtNightToUrinate" ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={subKey}
                                      className="checkbox-input"
                                      name={subKey}
                                      checked={urinary[subKey]}
                                      onChange={(e) =>
                                        handleChange21(subKey, e)
                                      }
                                      disabled={urinary.Nothinginthisurinary}
                                    />
                                    Get up at night to urinate - Number of times
                                    per night
                                    <input
                                      type="text"
                                      className="checkbox-input iojiu7"
                                      value={urinary.Numberoftimespernight}
                                      name="Numberoftimespernight"
                                      readOnly={!urinary.GetUpAtNightToUrinate}
                                      onChange={(e) =>
                                        handleChange21(
                                          "Numberoftimespernight",
                                          e
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={subKey}
                                      className="checkbox-input"
                                      checked={urinary[subKey]}
                                      name={subKey}
                                      onChange={(e) =>
                                        handleChange21(subKey, e)
                                      }
                                      disabled={urinary.Nothinginthisurinary}
                                    />
                                    {formatLabel(subKey)}
                                  </>
                                )}
                              </label>
                            ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              {urinary.Nothinginthisurinary === false &&
                urinaryArray.length > 0 && (
                  <div>
                    <h5>Urinary</h5>
                    <br />
                    {urinaryArray.map((data, index) => (
                      <ul key={index}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                )}
              {urinary.Nothinginthisurinary === true &&
                urinaryArray.length === 0 && (
                  <div>
                    <h5>Urinary</h5>
                    <br />
                    <ul>
                      <li>Nill</li>
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div> */}
      </div>

      <br></br>
      
      <br></br>
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns print-button3"
          onClick={handlePrintSave}
        >
          Print
        </button>
      </div>
      {/* </div> */}
    </body>
  );
}
export default MedicalHistoryForm2;
