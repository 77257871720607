import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const EmergencyPatientHemodynamicsMaster = () => {
  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const [CategoryName, setCategoryName] = useState({
    
    Category: '',
    Status: 'Active'
  });

  const [Category, setCategory] = useState([])
  
  const [IsCategoryEdit, setIsCategoryEdit] = useState(false)
  const [SubCategory, setSubCategory] = useState({
    
    Date: "",
    Time: "",
    Category: "",
    SubCategory: "",
    Status: "",
    
  })
  const [SubCategoryType, setSubCategoryType] = useState([])
 

  // useEffect(() => {
  //   axios.get('http://127.0.0.1:5000/usercontrol/getlocationdata')
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data)

  //       setLocationData(data.filter((p) => p.status === 'Active'));
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching location data:', error);
  //     });
  // }, [])


  //insert category




  const handleEditCategory = (row) => {

    setCategoryName({ ...row })
    setIsCategoryEdit(true)
    const inputElement = document.getElementById("Category");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }


  const handleCategoryType = () => {
    console.log('yyy',CategoryName)

    
    if (CategoryName.Category) {


      const data= {
        ...CategoryName,
        
        Createby:userRecord.username
      };


      
        // If IsCategoryEdit is true, it means we are updating an existing category
        axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_EmergencyCategory`, data)
          .then((response) => {
            console.log(response);
            if (response.data.message) {
              successMsg(response.data.message);
            } else {
              userwarn(response.data.Exists);
            }
            setCategoryName({
              Category: '',
              Status: 'Active'
            });
            setIsCategoryEdit(false);
            getCategoryType();
          })
          .catch((error) => {
            console.log(error);
          });
      
    } else {
      userwarn('Please Fill the Category');
    }
  };


  const getCategoryType = useCallback(() => {
    axios.get(`https://vesoftometic.co.in/EmergencyManagement/get_EmergencyCategory_Type`)
      .then((response) => {
        console.log(response)
        setCategory(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    getCategoryType()
  }, [getCategoryType])



  const handleCategoryClick = (row) => {
    const data = {
      CategoryId: row.id,
      Category: row.Category,
      Createby:userRecord.username,
    Status: row.Status === 'Active' ? 'Inactive' : 'Active'
    }

     console.log('jjjjjjj',data)

    axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_EmergencyCategory`, data)
      .then((response) => {
        console.log(response)
       getCategoryType();
        
      })
      .catch((error) => {
        console.log(error)
      });
  };



  // Sub-Category =========================================================

  const handleEditSubCategory = (row) => {

    setSubCategory({ ...row })
    setIsCategoryEdit(true)
    const inputElement = document.getElementById("SubCategory");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  const handleSubCategory = () => {
    if (SubCategory.Category && SubCategory.SubCategory && SubCategory.Status ) {

      let data = {
        ...SubCategory,
        
        Createby:userRecord.username
      }
      axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_EmergencySubCategory_Type`, data)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setSubCategory({
            Category: '',
            SubCategory: '',
            Status: '',
            
            
          })
          setIsCategoryEdit(false);
          getSubCategoryType()
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill all the Fields');
    }
  };


  const getSubCategoryType = useCallback(() => {
    axios.get(`https://vesoftometic.co.in/EmergencyManagement/get_EmergencySubCategory_type`)
      .then((response) => {
        console.log(response)
        setSubCategoryType(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])
  useEffect(() => {
    getSubCategoryType()

  }, [getSubCategoryType])


 

  // const handleSubCategoryClick = (row) => {
  //   const data = {
  //     ...row,
  //         }
  //   axios.post(`https://vesoftometic.co.in/usercontrol/insert_SubCategory_Type`, data)
  //     .then((response) => {
  //       console.log(response)
  //       getSubCategoryType()()
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  return (
    <>
      <div className='appointment'>
        <div className='h_head'>
          <h3>Emergency Hemodynamics Masters</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardType'>
          <div className="RegisFormcon " >
            <div className="RegisForm_1">
              <label> Category <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Category'
                name='Category'
                required
                value={CategoryName.Category}
                onChange={(e) => setCategoryName((prev) => ({ ...prev, Category: e.target.value.toUpperCase() }))}
              />
            </div>



          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleCategoryType}>{IsCategoryEdit ? "Update" : "Add"}</button>
          </div>
          <div className="RateCard_table">
            <div className="RateCard_table_1">
              <table>
                <thead>
                  <tr>
                    <th>S_No</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Category.map((client, index) => (
                    <tr key={index}>
                      <td>{client.id}</td>
                      <td>{client.Category}</td>
                      <td>
                        <button onClick={() => handleCategoryClick(client)} className='Addnamebtn_pt2'>
                          {client.Status}
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handleEditCategory(client)} className='ratecarededitbutton'>
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardRoom'>
          <div className="RegisFormcon " >
            <div className="RegisForm_1">
              <label> Category <span>:</span> </label>

              <select
                name='Category'
                required
                value={SubCategory.Category}
                onChange={(e) => setSubCategory((prev) => ({ ...prev, Category: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  Array.from(new Set(Category.map(p => p.Category))).map((Category, index) => (
                    <option key={index} value={Category}>{Category}</option>
                  ))
                }
              </select>
            </div>
            <div className="RegisForm_1">
              <label>Sub-Category <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Sub-Category'
                name='SubCategory'
                required
                value={SubCategory.SubCategory}
                onChange={(e) => setSubCategory((prev) => ({ ...prev, SubCategory: e.target.value.toUpperCase() }))}
              />
            </div>
            <div className="RegisForm_1">
              <label> Status <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Status'
                name='Status'
                required
                value={SubCategory.Status}
                onChange={(e) => setSubCategory((prev) => ({ ...prev, Status: e.target.value }))}
              />
            </div>


          </div>
          {/* <div className="RegisFormcon ">
            <div className="RegisForm_1">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={SubCategory.Location}
                onChange={(e) => setSubCategory((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>



          </div> */}
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleSubCategory}>{IsCategoryEdit ? "Update" : "Add"}</button>
          </div>
          <div className="RateCard_table">
            <div className="RateCard_table_1">
              <table>
                <thead>
                  <tr>
                    <th>S_No</th>
                    <th>Category</th>
                    <th>Sub-Category</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {SubCategoryType.map((client, index) => (
                    <tr key={index}>
                      <td>{client.id}</td>
                      <td>{client.Category}</td>
                      <td>{client.SubCategory}</td>
                      <td>{client.Status}</td>
                      
                      <td>
                        <button onClick={() => handleEditSubCategory(client)} className='ratecarededitbutton'>
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

       
      </div>
      <ToastContainer />
    </>
  )


  

}
export default EmergencyPatientHemodynamicsMaster;