import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from 'react-redux';
import Canva from '../../TreatmentRecords/Canva';
import "../../TreatmentRecords/TreatmentComponent.css";

function IcuTreatment() {


    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const isnewSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  const create = userRecord?.username;
  const Location = userRecord?.location;

  const [selectedFile, setSelectedFile] = useState(null);

  const [diagnosis_list, setdiagnosis_list] = useState([])
  const [diagnosis_list_1, setdiagnosis_list_1] = useState([])




  

  const [showcamera, setshowcamera] = useState(false);
  const [orignalImagecanva, setoriginalImagecanva] = useState(null);
  const [mergedImagecanva, setmergedImagecanva] = useState(null);
  const [Complaint, setComplaint] = useState('');

  const [historyValue, setHistoryValue] = useState('');
  const [examinationValue, setExaminationValue] = useState('');
  const [diagnosisValue, setDiagnosisValue] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');

  const [ICD_Code, setICD_Code] = useState('');
  const [ICD_Name, setICD_Name] = useState('');//diagnosis_list
  const [Description, setDescription] = useState('');//diagnosis_list



  useEffect(() => {
    if (ICD_Code) {
      Axios.get(`https://vesoftometic.co.in/doctorsworkbench/Diagnosis_list/${ICD_Code}`)
        .then((response) => {
          console.log("fetched", response.data.data);
          const data = response.data.data //remove dublicate write loop
          if (data) {
            setdiagnosis_list(data)
          }
        })
        .catch((error) => {
          console.error("Error fetching treatment data:", error);
        });
    }

  }, [ICD_Code]);

  useEffect(() => {
    // Check if ICD_Name is available
    if (ICD_Name) {
      Axios.get(`https://vesoftometic.co.in/doctorsworkbench/Diagnosis_list_ICD_Name/${ICD_Name}`)
        .then((response) => {
          console.log("Fetched data:", response.data.data);
          const data = response.data.data;
          if (data) {
            setdiagnosis_list_1(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching treatment data:", error);
        });
    }

  }, [ICD_Name]);


  useEffect(() => {
    if (diagnosis_list.length === 1) {
      const newdata = diagnosis_list.find((p) => p.ICD_10_Code === ICD_Code)
      if (newdata) {
        setICD_Name(
          newdata.Diagnosis
        )
      } else {
        setICD_Name('')
      }
    }
  }, [diagnosis_list.length, ICD_Code, diagnosis_list])

  useEffect(() => {
    if (diagnosis_list_1.length === 1) {
      const newdata = diagnosis_list_1.find((p) => p.Diagnosis === ICD_Name)
      if (newdata) {
        setICD_Code(
          newdata.ICD_10_Code
        )
      } else {
        setICD_Code('')
      }
    }

  }, [diagnosis_list_1.length, ICD_Name, diagnosis_list_1])



  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setAppointmentDate(currentDate);

    // const constraints = {
    //   appointmentDate: appointmentDate,
    //   PatientID: formData.PatientID,
    // AppointmentID: formData.SerialNo,
    // visitNo: formData.visitNo,
    // };

    const patientid = IpNurseQueSelectedRow?.PatientId
   

    Axios.get(`https://vesoftometic.co.in/doctorsworkbench/get_treatment?patientid=${patientid}&location=${Location}`)
      .then((response) => {
        console.log("fetched", response.data);

        if (response.data && response.data.length > 0) {
          const matchedData = response.data.find(item => item.PatientID === IpNurseQueSelectedRow?.PatientId);
          console.log(matchedData)
          if (matchedData) {
            
            setICD_Code(matchedData.ICD_10_Code);
            setICD_Name(matchedData.ICD_Diagnosis);
            setDescription(matchedData.Description);
            setComplaint(matchedData.Complaint);
            setHistoryValue(matchedData.History);
            setExaminationValue(matchedData.Examination);
            setDiagnosisValue(matchedData.Diagnosis);
            setoriginalImagecanva(matchedData.OriginalImageURL);
            setoriginalImagecanva(`data:image/jpeg;base64,${(matchedData.Original_Image)}`);
            setmergedImagecanva(`data:image/jpeg;base64,${(matchedData.Anotated_Medical_Image)}`);
          } else {
            console.log("No data found for PatientID:", IpNurseQueSelectedRow?.PatientId);
          }

        } else {
          console.log("Error or empty response:", response);

        }


      })
      .catch((error) => {
        console.error(error);
      });
  }, [appointmentDate, IpNurseQueSelectedRow]);





  // Convert a data URI to a Blob



  const handleShowcamera = () => {
    setshowcamera(true);
  };
  const handlehidecamera = () => {
    setshowcamera(false);
  };



  const handleSave = () => {


    // Log Blobs for verification
    const MY_datatosend = {
      Diagnosis_Name: ICD_Name,
      ICD_10_Code: ICD_Code,
      history: historyValue,
      Description: Description,
      Complaint: Complaint,
      examination: examinationValue,
      diagnosis: diagnosisValue,
      PatientID: IpNurseQueSelectedRow?.PatientId,
      appointmentDate: appointmentDate,
      createdBy: create,
      location: Location,
      original_image: dataURItoBlob(orignalImagecanva),
      annotated_medical_image: dataURItoBlob(mergedImagecanva),
    }

    const BackformData = new FormData();

    for (const key in MY_datatosend) {
      if (MY_datatosend.hasOwnProperty(key)) {
        BackformData.append(key, MY_datatosend[key]);
      }
    }



    const url = 'https://vesoftometic.co.in/IcuManagement/insert_treatment';


    Axios.post(url, BackformData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log(response.data)
        successMsg('Saved Succefully')
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occured")
      });
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };





  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Use fetch to send the formData to your server
      fetch('https://vesoftometic.co.in/doctorsworkbench/Diagnosis_csvto_sql_link', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          successMsg('File processed successfully')
        })
        .catch((error) => {
          console.error('Error:', error);

          errmsg('An error occurred while processing the file')
        });
    } else {

      warnmsg('Please select a file first')
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const warnmsg = (warnmessage) => {
    toast.warn(`${warnmessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  }

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  }


  return (
    <>

      <div className="treatment_total_container">

        <div style={{ display: "flex", flexDirection: "column" }}>

        </div>



        <div className="treatment_container">
          <div className="treatcon_1">
            <div className="treatcon_body">
            <div className="form_row_66 upload-file-trtmt formrow_chse_pic">

            <label htmlFor="idFile" className="lable_text">
            Upload File :
            </label>
            <div className="file-input-444">
            <div className="input-container-99">
                <input type="file" id="fileChooser2" name="fileChooser2" onChange={handleFileChange}
                style={{ display: 'none' }} required />

                <label htmlFor="fileChooser2" className="choose-file-button-55 choselabelclr">
                Choose File
                </label>
            </div>
            <span> and </span>
            <div className="input-container-99">
                <button className="choose-file-button-55" onClick={handleFileUpload} >
                Upload
                </button>
            </div>
            </div>
            </div>   

            <div className="treatcon_body_1">
                <label htmlFor="history">ICD 10 Code<span>:</span></label>
                <input
                  id='ICD_10_Code'
                  name='ICD_10_Code'
                  list='ICD_10_Codes'
                  className='trt_input-dlist'
                  autoComplete='off'
                  value={ICD_Code}
                  onChange={(e) => {
                    setICD_Code(e.target.value);
                    setdiagnosis_list([])
                  }}
                ></input>
                {/* {console.log(diagnosis_list.map((p)=>p.ICD_10_Code))} */}
                {diagnosis_list.length > 0 && (
                  <datalist id="ICD_10_Codes">
                    {diagnosis_list.map((item) => (
                      <option key={item.S_No} value={item.ICD_10_Code} />
                    ))}
                  </datalist>
                )}

              </div>

              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="history">Description<span>:</span></label>
                <textarea
                  id="history"
                  name="history"
                  value={Description}
                  cols="25"
                  rows="3"
                  onChange={(e) => setDescription(e.target.value)}

                ></textarea>
              </div>


    
            <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="history">Complaint <span>:</span></label>
                <textarea
                  id="Complaint"
                  name="Complaint"
                  cols="25"
                  rows="3"
                  value={IpNurseQueSelectedRow.Complaint}
                  onChange={(e) => setComplaint(e.target.value)}
                ></textarea>
              </div>


              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="history">History <span>:</span></label>
                <textarea
                  id="history"
                  name="history"
                  cols="25"
                  rows="3"
                  value={historyValue}
                  onChange={(e) => setHistoryValue(e.target.value)}
                ></textarea>
              </div>
              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="examination">Examination <span>:</span></label>
                <textarea
                  id="examination"
                  name="examination"
                  cols="25"
                  rows="3"
                  value={examinationValue}
                  onChange={(e) => setExaminationValue(e.target.value)}
                ></textarea>
              </div>
              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="diagnosis">Diagnosis <span>:</span></label>
                <textarea
                  id="diagnosis"
                  name="diagnosis"
                  cols="25"
                  rows="3"
                  value={diagnosisValue}
                  onChange={(e) => setDiagnosisValue(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>

          {orignalImagecanva && (
            <div className="treatcon_2">
              <div className="treatcon_image">
                <div className="treatcon_image_1">
                  <img src={orignalImagecanva} alt="OriginalImage" onError={(e) => console.error('Error loading image:', e)} />
                </div>
                <div className="treatcon_label">
                  <label htmlFor="name">Original Image</label>
                </div>
              </div>
              <div className="treatcon_image anoted_img">
                <div className="treatcon_image_1">
                  <img src={mergedImagecanva} alt="Anotatedimage" onError={(e) => console.error('Error loading image:', e)} />
                </div>
                <div className="treatcon_label anot-withhh">
                  <label htmlFor="name">Anotated Medical Image</label>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
      {/* <br /> */}
      <div className="treatment_buttons">
        <button className='RegisterForm_1_btns' onClick={handleSave}>save</button>
        <button className='RegisterForm_1_btns' onClick={handleShowcamera}>capture</button>
      </div>
      {showcamera && (
        <div className={isnewSidebarOpen ? "sideopen_showcamera" : "showcamera"} onClick={handlehidecamera}>
          <div className={isnewSidebarOpen ? "sideopen_showcamera_1" : "showcamera_1"} onClick={(e) => e.stopPropagation()}>
            <Canva setoriginalImagecanva={setoriginalImagecanva} setmergedImagecanva={setmergedImagecanva} setshowcamera={setshowcamera} />
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default IcuTreatment;