import React, { useState } from 'react';
// import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { alignProperty } from '@mui/material/styles/cssUtils';
import './Department.css'

import { useSelector } from 'react-redux';


function Department() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  
  const[departmentrole,setDepartmentRole]=useState([])


  const [roleData, setRoleData] = React.useState([]);
  const [mainDepartmentName, setMainDepartmentName] = useState('');
  const [mainDepartmentCode, setMainDepartmentCode] = useState('');
  const [subdepartment, setSubDepartment] = useState('')
  const [subdepartmentCode, setSubDepartmentCode] = useState('')
  const [selectdept,setSelectdept] = useState('')
  
  
  const handleSubmitMainDepartment = async () => {
    if (!mainDepartmentName.trim() || !mainDepartmentCode.trim()) {
      alert('Both mainDepartmentName and mainDepartmentCode are required.');
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(`${urllink}mainddepartment/insertdepartment`, {
        mainDepartmentName,
        mainDepartmentCode,
      });

      // Handle the response as needed
      console.log(response.data);
      
      // Optionally, you can reset the state after a successful submission
      setMainDepartmentName('');
      setMainDepartmentCode('');
      fetchDepartmentData();
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error scenarios
    }
  };
  
  const handleSubmitSubDepartment = async () => {
    if (!subdepartment.trim() || !subdepartmentCode.trim() || !selectdept.trim()) {
      alert(' subdepartment and subdepartmentCode and selectdept are required.');
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
       const response = await axios.post(`${urllink}mainddepartment/insertsubdepartment`, {
        subdepartment,
        subdepartmentCode,     
        selectdept
      });

      // Handle the response as needed
      console.log(response.data);
      setSelectdept('');
      setSubDepartment('');
      setSubDepartmentCode('')
      fetchSubDepartmentData();
     }
      catch (error) {
      console.error('An error occurred:', error);
      // Handle error scenarios
    }
  };
  
  React.useEffect(() => {
    fetchSubDepartmentData();
    fetchDepartmentData();
   
    
   
  }, []);
  
  const fetchDepartmentData = () => {
    axios.get(`${urllink}mainddepartment/getdepartmentdata`)
      .then((response) => {
        const data = response.data;
        console.log("data",data)

        setRoleData(data)
      })
      .catch((error) => {
        console.error('Error fetching Department data:', error);
      });
  };
  console.log('selectdept', selectdept)

  const fetchSubDepartmentData = () => {
    axios.get(`${urllink}mainddepartment/getsubdepartment`)
      .then((response) => {
        console.log(response)
        const data = response.data;
        console.log("data",data)

        setDepartmentRole(data)
      })
      .catch((error) => {
        console.error('Error fetching SubDepartment data:', error);
      });
  };
  // console.log('selectdept', selectdept)










  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>User Management</h3>
          </div>

          <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Main Department</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department :</label>
                 
                  <input type="text"
                    id="MainDepartmentName"
                    name="MainDepartmentName"
                    value={mainDepartmentName}
                    onChange={(e) => setMainDepartmentName(e.target.value)}
                    // name="MainDepartmentName"
                    // value={mainDepartment.mainDepartmentName}
                    // onChange={(e) => setMainDepartment({ ...mainDepartment, mainDepartmentName: e.target.value })}
                    placeholder="Main Department Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}> Department Code :</label>
                  <input
                    type="text"
                    id="MainDepartmentCode"
                    name="MainDepartmentCode"
                    value={mainDepartmentCode}
                    onChange={(e) => setMainDepartmentCode(e.target.value)}
                    // id="MainDepartmentCode"
                    // name="MainDepartmentCode"
                    // value={mainDepartment.mainDepartmentCode}
                    // onChange={(e) => setMainDepartment({ ...mainDepartment, mainDepartmentCode: e.target.value })}
                    placeholder="Main Department Code"
                    // style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitMainDepartment}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Department</th>
                        <th >Department Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.department_id}</td>
                          <td>{row.department_name}</td>
                          <td>

                            {row.department_code}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div>


          
        </div>
        <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Department</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Main Department :</label>
                  <select
                  name="department"
                  value={selectdept}
                  onChange={(e) => setSelectdept(e.target.value)}
                  className='deprtsele'
                  required
                >
                  <option value="select">Select Department</option>
                 {roleData.map((row,index)=>(
                  <option key={row.department_id} value={row.department_name} >{row.department_name}</option>
                  ))}
                </select>

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={subdepartment}
                    onChange={(e) => setSubDepartment(e.target.value)}
                    placeholder="Enter Department Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={subdepartmentCode}
                    onChange={(e) => setSubDepartmentCode(e.target.value)}
                    placeholder="Enter Department Code"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitSubDepartment}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Main Department</th>
                        <th >Department</th>
                        <th>Department Code</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                    {departmentrole.map((row, index) => (
                        <tr key={index}>
                          <td>{row.sub_department_id}</td>
                          <td>{row.main_department_name}</td>
                          <td>

                            {row.subdepartment_name}


                          </td>
                          <td>
                            {row.subdepartment_code}</td>

                        </tr>
                      ))}
                    </tbody>
                    
                  </table>

                </div>

              </div>

            </div>  
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Department;
