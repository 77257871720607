import React, { useState, useEffect } from 'react';
import './Organization.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
function Commercial() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  // Initialize state for CPT and CPRT
  const [cpt, setCpt] = useState();
  const [cprt, setCprt] = useState();

  const [testname,setTestName] = useState()
  // Handle changes in CPT input
  const handleCptChange = (event) => {
    setCpt(event.target.value);
  };

  // Handle changes in CPRT input
  const handleCprtChange = (event) => {
    setCprt(event.target.value);
  };

  

  useEffect(()=>{
    axios.get(`${urllink}usercontrol/gettestname`)
    .then((response)=>{
        console.log(response.data)
        const data= response.data.test_name
        setTestName(data);
    })
    .catch((error)=>{
        console.log(error)
    })
},[])

  const handlesubmit =()=>{
    const postdata={
      costpertest: cpt,
      costperreportabletest: cprt,
      testname: testname
    }

    if(!cpt || !cprt){
      alert("Please Enter All data")
    }
    else{
      axios.post(`${urllink}usercontrol/inserttestcost`,postdata)
      .then((response)=>{
        console.log(response)
        setCprt(''); 
        setCpt('');
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  };

  return (
    <div className="appointment">

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="productName" >
              CPT<span>:</span>
            </label>
            <input
              type="text"
              id="productName"
              name="productName"
              placeholder="Enter CPT"
              value={cpt}
              onChange={handleCptChange} // Handle changes in CPT input
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="productType" >
              CPRT<span>:</span>
            </label>
            <input
              type="text"
              id="productType"
              name="productType"
              placeholder="Enter CPRT"
              value={cprt}
              onChange={handleCprtChange} // Handle changes in CPRT input
              required
            />
          </div>
        </div>
        <div className='Register_btn_con'>
        <button className="RegisterForm_1_btns" onClick={handlesubmit}>Submit</button>
      </div>
    
    </div>
  );
}

export default Commercial;

