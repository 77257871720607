import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";







const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
export default function InsuranceMasterList() {

    const dispatchvalue = useDispatch()

    const [SelectFormdata,setSelectFormdata]=useState('Insurance')

    const [SelectStatus,setSelectStatus]=useState('Active')


    const userRecord = useSelector(state => state.userRecord?.UserData)


    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    // const [searchQuery, setSearchQuery] = useState('');
    // const [summa, setsumma] = useState([])

    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    

    console.log('vcvc',userRecord)

    const yourStyles={
        position: 'absolute',
        inset: '100px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(97 90 90 / 75%)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px'
      }
  

      useEffect(() => {
        if (SelectFormdata === 'Insurance') {
          axios
            .get(`https://vesoftometic.co.in/Insurance/get_Insurance_Master_data`)
            .then((response) => {
              console.log(response.data);
              const filteredData = response.data.filter((item) => item.Status === SelectStatus);
              const data = filteredData.map((p, index) => ({
                id: index + 1,
                ...p
              }));
              console.log('data', data);
              setFilteredRows(data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (SelectFormdata === 'Client') {
          axios
            .get(`https://vesoftometic.co.in/Insurance/get_Client_Master_data`)
            .then((response) => {
              console.log(response.data);
              const filteredData = response.data.filter((item) => item.Status === SelectStatus);
              const data = filteredData.map((p, index) => ({
                id: index + 1,
                ...p
              }));
              console.log('data', data);
              setFilteredRows(data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, [SelectFormdata, SelectStatus]);
      
    
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const ind = params.row
        const index={
          ...ind,
          SelectFormdata:SelectFormdata
        }
        console.log('index:',index) 
        dispatchvalue({ type:'InsuranceMaster', value: index })

        navigate('/Home/InsuranceMaster')

        
    };

    // useEffect(() => {
        // console.log('ss', summa);
    
        // const lowerCaseQuery = searchQuery.toLowerCase();
        // const filteredData = summa.filter((row) => {
        //     const lowerCaseSupplierName = row.InsuranceName.toLowerCase(); 
        //     const lowerCaseId = row.InsuranceCode.toString().toLowerCase(); // Convert S_No to string
        //     const lowerCaseType = row.Type.toLowerCase(); 
        //     const lowerCasePhoneNo = row.PhoneNumber.toLowerCase(); 
    
        //     return (
        //         lowerCaseSupplierName.includes(lowerCaseQuery) ||
        //         lowerCaseId.includes(lowerCaseQuery) || // Use lowerCaseId here
        //         lowerCaseType.includes(lowerCaseQuery) ||
        //         lowerCasePhoneNo.includes(lowerCaseQuery) 
        //     );
        // });
    
        // setFilteredRows(filteredData);
    // }, [searchQuery, summa]);
    



    const handleRequestForm = () => {

        navigate('/Home/InsuranceMaster')
        dispatchvalue({ type:'InsuranceMaster', value: {} })


    }

    const handleVisibilityClick = (ConsentForm) => {
        console.log('ConsentForm', ConsentForm);
    
        if (ConsentForm === 'data:image/jpg;base64,' && 'data:application/octet-stream;base64') {
            alert('No Data');
        } else {
            setModalContent(ConsentForm);
            setModalIsOpen(true);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent('');
      };
    
   

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'InsuranceCode', headerName: 'Insurance Id', width: 100 },
        { field: 'InsuranceName', headerName: 'Insurance Name', width: 150 },
        { field: 'Type', headerName: 'Type', width: 150 },
        { field: 'TPAName', headerName: 'TPA Name', width: 150},
        { field: 'PolicyType', headerName: 'PolicyType', width: 150 },
        { field: 'PayerZone', headerName: 'PayerZone', width: 180 },
        { field: 'PayerMemberId', headerName: 'Payer Member Id', width: 180 },
        { field: 'ContactPerson', headerName: 'Contact Person', width: 180 },
        { field: 'PhoneNumber', headerName: 'Phone Number', width: 180 },
        { field: 'MailId', headerName: 'Mail Id', width: 180 },
        { field: 'Status', headerName: 'Status', width: 180 },
        {
            field: "TreatmentList",
            headerName: "Treatment List",
            width: 150,
            renderCell: (params) => (
              <>
                <Button
                  className="cell_btn"
                  onClick={() => handleVisibilityClick(params.value)}
                >
                  <VisibilityIcon />
                </Button>
              </>
            ),
          },{
            field: "OtherDocuments",
            headerName: "Other Documents",
            width: 150,
            renderCell: (params) => (
              <>
                <Button
                  className="cell_btn"
                  onClick={() => handleVisibilityClick(params.value)}
                >
                  <VisibilityIcon />
                </Button>
              </>
            ),
          },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <EditIcon/>
                    </Button>
                </>
            ),
        },
    ];

  // -------------client

  const dynamicColumns1 = [
    { field: 'ClientCode', headerName: 'Client Id', width: 100 },
    { field: 'ClientName', headerName: 'Client Name', width: 150 },
    { field: 'ContactPerson', headerName: 'Contact Person', width: 180 },
    { field: 'PhoneNumber', headerName: 'Phone Number', width: 180 },
    { field: 'MailId', headerName: 'Mail Id', width: 180 },
    { field: 'Status', headerName: 'Status', width: 180 },
    {
        field: "TreatmentList",
        headerName: "Treatment List",
        width: 150,
        renderCell: (params) => (
          <>
            <Button
              className="cell_btn"
              onClick={() => handleVisibilityClick(params.value)}
            >
              <VisibilityIcon />
            </Button>
          </>
        ),
      },{
        field: "OtherDocuments",
        headerName: "Other Documents",
        width: 150,
        renderCell: (params) => (
          <>
            <Button
              className="cell_btn"
              onClick={() => handleVisibilityClick(params.value)}
            >
              <VisibilityIcon />
            </Button>
          </>
        ),
      },
    {
        field: 'Action',
        headerName: 'Action',
        width: 120,
        renderCell: (params) => (
            <>
                <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                    <EditIcon/>
                </Button>
            </>
        ),
    },
];

    // const handleSearchChange = (event) => {
    //     setSearchQuery(event.target.value);
    // };



    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="Insurance_dash_hending">
                    <h3>Insurance / Client Master List</h3>
                </div>

                <div className="serch_dash_insur" style={{width:'65%'}}>
                
                <div className="Total_input_container">
                  <div className="inp_container_all_intakeoutput">
                  <label >
                      Type <span>:</span>
                  </label>
                  <select
                    name='SelectFormdata'
                    value={SelectFormdata}
                    onChange={(e)=>setSelectFormdata(e.target.value)}
                  >
                    <option value="Insurance">Insurance</option>
                    <option value="Client">Client</option>
                  </select>
                  </div>
                  <div className="inp_container_all_intakeoutput">
                  <label >
                      Status <span>:</span>
                  </label>
                  <select
                    name='SelectStatus'
                    value={SelectStatus}
                    onChange={(e)=>setSelectStatus(e.target.value)}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  </div>
                </div>
              
                <p onClick={handleRequestForm}>
                <button className="add_new_auth_wuith"><AddIcon /> New </button>
                </p>
                </div>
               
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={SelectFormdata === 'Insurance'?dynamicColumns:dynamicColumns1} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "var(--ProjectColor)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>
        </>
    );
}
