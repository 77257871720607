import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
  faUsers, faHeadset,faHandsBound, faStethoscope, faClipboard, faFileMedical, faMoneyBillAlt, faRightFromBracket, faPenNib, faLayerGroup, faUserDoctor, faPersonShelter, faShop, faPeopleArrows,
  faUserNurse, faSackDollar, faUserNinja, faUserTag,faHandHoldingDroplet, faChartBar, faAngleDown, faBuildingColumns, faStore, faSuitcaseMedical, faThumbsUp, faIndent, faRegistered, faHeartPulse
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

function Sidebar() {



  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [mainAccess, setMainAccess] = useState([]);
  const [subAccess, setSubAccess] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();


  const location = useLocation();

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  useEffect(() => {
    dispatchvalue({ type: 'isSidebarOpen', value: false })
   
    const userRecord = localStorage.getItem("token")
    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      const setAccess1 = (decodedTokenData && decodedTokenData.Access_one.split(',').map(item => item.trim())) || [];
      const setAccess2 = (decodedTokenData && decodedTokenData.Access_two.split(',').map(item => item.trim())) || [];

      setMainAccess(setAccess1);
      setSubAccess(setAccess2);

    };
  }, [location.pathname]);

//   if (setAccess1.includes("FrontOffice")) {
//     navigate("/Home/Appointment-Calendar"); // Navigate to the default page for FrontOffice
//   }
// }

  // console.log(subAccess);
  // console.log(mainAccess)
  useEffect(() => {
    // Disable text selection on the entire document
    const disableTextSelection = () => {
      document.body.style.userSelect = 'none';
      document.body.style.MozUserSelect = 'none';
      document.body.style.msUserSelect = 'none';
    };
    disableTextSelection();
    // Enable text selection when the component is unmounted
    return () => {
      document.body.style.userSelect = 'auto';
      document.body.style.MozUserSelect = 'auto';
      document.body.style.msUserSelect = 'auto';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect runs once when the component mounts


  const handleSubMenuClick = (subMenu) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);

  };


  const handleLogoutClick = () => {
    navigate('/')
    localStorage.clear()
  };






  return (
    <nav id="inventory_sidebar" className={isSidebarOpen ? 'inventory_sidebar_open' : ''} ref={sidebarRef}>

      <div className="inv_sidebar_header" onMouseEnter={() => dispatchvalue({ type: 'isSidebarOpen', value: true })} onMouseLeave={() => dispatchvalue({ type: 'isSidebarOpen', value: false })}>
        <div className="inv_components_sidebar">

          {mainAccess.includes("ClinicMetrics") && <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} id='font_111'>
            <FontAwesomeIcon icon={faStethoscope} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" onClick={(() => { navigate("/Home/ClinicMetrics") })}>Clinic Metrics</span>}
            {/* {isSidebarOpen && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`arrow-icon ${openSubMenu === 'subsidebarmenu' ? 'arrow-rotate' : ''}`}
              />
            )} */}
          </div>}

          {mainAccess.includes("FrontOffice") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUsers} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Front Office</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">
              {subAccess.includes("ARFO1") && <div onClick={(() => { navigate("/Home/Appoinment-RequestList") })}> {isSidebarOpen && <span className="icon-name" >Appoinment Request</span>}</div>}
              {subAccess.includes("BCFO2") && <div onClick={(() => { navigate("/Home/IP-Registration_New") })}> {isSidebarOpen && <span className="icon-name" >Registration</span>}</div>}
              {subAccess.includes("BCFO2") && <div onClick={(() => { navigate("/Home/Appoinment-RegisterList") })}> {isSidebarOpen && <span className="icon-name" >Appointment Register List</span>}</div>}
              {subAccess.includes("IPALO7") &&<div onClick={(() => { navigate("/Home/IpRegistrationlist") })}>{isSidebarOpen && <span className="icon-name" >IP Admission List</span>}</div>}
              {subAccess.includes("FLFO3") && <div onClick={(() => { navigate("/Home/Followuplist") })}>{isSidebarOpen && <span className="icon-name" >Follow-up List</span>}</div>}
              {subAccess.includes("CFFO4") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Consent Forms</span>}</div>}
              {subAccess.includes("LRFO5") && <div onClick={(() => { navigate("/Home/Lab-QueueList") })}>{isSidebarOpen && <span className="icon-name" >Lab Report</span>}</div>}
              {subAccess.includes("PLUFO6") && <div onClick={(() => { navigate("/Home/Patient-List-update") })}>{isSidebarOpen && <span className="icon-name" >Patient Management</span>}</div>}
              {subAccess.includes("BDR09") && <div onClick={(() => { navigate("/Home/BirthDeathRegisterList") })}>{isSidebarOpen && <span className="icon-name" >Birth Death Register List</span>}</div>}  
            </div>}
          </div>}

          {mainAccess.includes("RoomManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu47')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faPersonShelter} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Room Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu47' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu47' && <div className="subSidebarmenuhover">
            {subAccess.includes("RMRM") && <div onClick={(() => { navigate("/Home/Room-Management") })}> {isSidebarOpen && <span className="icon-name" >Room Management</span>}</div>}
 
            </div>}
</div>}


          {mainAccess.includes("Nurse") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu2')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNurse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Nurse</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu2' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu2' && <div className="subSidebarmenuhover">
              {subAccess.includes("PQ3N1") && <div onClick={(() => { navigate("/Home/Treament-QueueList3") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
              {subAccess.includes("IPQ3N2") && <div onClick={(() => { navigate("/Home/IpNurseQuelist") })}>{isSidebarOpen && <span className="icon-name" >In Patient Que List</span>}</div>}

            </div>}
          </div>}

          {mainAccess.includes("DoctorWorkbench") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Doctor's WorkBench</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover">
              
              {subAccess.includes("PQDW1") && <div onClick={(() => { navigate("/Home/Treament-QueueList") })}> {isSidebarOpen && <span className="icon-name" >OP Queue List </span>}</div>}
              {subAccess.includes("IPQDW2") && <div onClick={(() => { navigate("/Home/IpDocQuelist") })}>{isSidebarOpen && <span className="icon-name" >In Patient Que List</span>}</div>}

            </div>}
          </div>}


          {mainAccess.includes("Counselor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faHeadset} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Counselor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover">
              {subAccess.includes("CAC") && <div onClick={(() => { navigate("/Home/Appointment-Calendar") })}> {isSidebarOpen && <span className="icon-name" >Appointment Calendar </span>}</div>}
              {subAccess.includes("CPQ") && <div onClick={(() => { navigate("/Home/Treament-CounselorQueueList") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
              {subAccess.includes("CQL") && <div onClick={(() => { navigate("/Home/Treament-CounselorFullList") })}> {isSidebarOpen && <span className="icon-name" >Counselor Queue List</span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("Therapist") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu5')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faClipboard} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Therapist</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu5' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu5' && <div className="subSidebarmenuhover">
              {subAccess.includes("TQL01") && <div onClick={(() => { navigate("/Home/Treament-QueueList2") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}

            </div>}
          </div>}


          

          
{/* {mainAccess.includes("OTManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu23')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faHeartPulse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">OT Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu23' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu23' && <div className="subSidebarmenuhover">
              {subAccess.includes("OTTB") && <div onClick={(() => { navigate("/Home/OT-Management") })}>{isSidebarOpen && <span className="icon-name" >Theatre Booking</span>} </div>}
               {subAccess.includes("OTQL") && <div onClick={(() => { navigate("/Home/OT-QueList") })}> {isSidebarOpen && <span className="icon-name" >OT Queue List </span>} </div>} 
               {subAccess.includes("OTCF") && <div onClick={(() => { navigate("/Home/OT-ConcentQueList") })}> {isSidebarOpen && <span className="icon-name" >Consent Form </span>}</div>}
               {subAccess.includes("OTDQL") && <div onClick={(() => { navigate("/Home/OT-DoctorQueList") })}> {isSidebarOpen && <span className="icon-name" >Doctor OueueList </span>}</div>}
               {subAccess.includes("OTDN") && <div onClick={(() => { navigate("/Home/OT-DoctorListNaviagtion") })}> {isSidebarOpen && <span className="icon-name" >OT Doctor </span>}</div>}

               {subAccess.includes("OTAQL") && <div onClick={(() => { navigate("/Home/OT-AnaesthesiaQueList") })}> {isSidebarOpen && <span className="icon-name" >Anaesthesia OueueList </span>}</div>}
               {subAccess.includes("OTAN") && <div onClick={(() => { navigate("/Home/OT-Anaesthesia") })}> {isSidebarOpen && <span className="icon-name" >OT Anaesthesia </span>}</div>}

               {subAccess.includes("OTNQL") && <div onClick={(() => { navigate("/Home/OT-NurseQueList") })}> {isSidebarOpen && <span className="icon-name" > Nurse OueueList </span>}</div>}
               {subAccess.includes("OTNN") && <div onClick={(() => { navigate("/Home/OT-NurseeListNaviagtion") })}> {isSidebarOpen && <span className="icon-name" >OT Nurse </span>}</div>}
               {subAccess.includes("OTBM") && <div onClick={(() => { navigate("/Home/OT-Biomedical") })}> {isSidebarOpen && <span className="icon-name" >Ot Biomedical </span>}</div>}
            </div>}
       </div>} */}

{!mainAccess.includes("OTmanagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu23')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faHeartPulse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">OT Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu23' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu23' && <div className="subSidebarmenuhover">
              {subAccess.includes("OTTB") && <div onClick={(() => { navigate("/Home/OT-Management") })}>{isSidebarOpen && <span className="icon-name" >Theatre Booking</span>} </div>}
               {subAccess.includes("OTQL") && <div onClick={(() => { navigate("/Home/OT-QueList") })}> {isSidebarOpen && <span className="icon-name" >OT Queue List </span>} </div>} 
               {subAccess.includes("OTCF") && <div onClick={(() => { navigate("/Home/OT-ConcentQueList") })}> {isSidebarOpen && <span className="icon-name" >Consent Form </span>}</div>}
               {subAccess.includes("OTDQL") && <div onClick={(() => { navigate("/Home/OT-DoctorQueList") })}> {isSidebarOpen && <span className="icon-name" >Doctor OueueList </span>}</div>}
               {subAccess.includes("OTDN") && <div onClick={(() => { navigate("/Home/OT-DoctorListNaviagtion") })}> {isSidebarOpen && <span className="icon-name" >OT Doctor </span>}</div>}

               {subAccess.includes("OTAQL") && <div onClick={(() => { navigate("/Home/OT-AnaesthesiaQueList") })}> {isSidebarOpen && <span className="icon-name" >Anaesthesia OueueList </span>}</div>}
               {subAccess.includes("OTAN") && <div onClick={(() => { navigate("/Home/OT-AnaesthesiaListNaviagtion") })}> {isSidebarOpen && <span className="icon-name" >OT Anaesthesia </span>}</div>}

               {subAccess.includes("OTNQL") && <div onClick={(() => { navigate("/Home/OT-NurseQueList") })}> {isSidebarOpen && <span className="icon-name" > Nurse OueueList </span>}</div>}
               {subAccess.includes("OTNN") && <div onClick={(() => { navigate("/Home/OT-NurseeListNaviagtion") })}> {isSidebarOpen && <span className="icon-name" >OT Nurse </span>}</div>}
               {subAccess.includes("OTBM") && <div onClick={(() => { navigate("/Home/OT-Biomedical") })}> {isSidebarOpen && <span className="icon-name" >Ot Biomedical </span>}</div>}
            </div>}
            </div>}


          {mainAccess.includes("VisitingDoctor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu11')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNinja} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Visiting Doctor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu11' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu11' && <div className="subSidebarmenuhover">


              {subAccess.includes("VDP1") && <div onClick={(() => { navigate("/Home/Patient-Visiting-Doctor") })}> {isSidebarOpen && <span className="icon-name" >Visiting Doctor Patients </span>}</div>}
            </div>}
          </div>}


          
          {mainAccess.includes("Insurance") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu22')}>
          <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <FontAwesomeIcon icon={faHandsBound} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name">Insurance</span>}
            {isSidebarOpen && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`arrow-icon ${openSubMenu === 'subsidebarmenu22' ? 'arrow-rotate' : ''}`}
              />
            )}
          </div>
          {isSidebarOpen && openSubMenu === 'subsidebarmenu22' && <div className="subSidebarmenuhover">
          {subAccess.includes("IMLS1") &&<div onClick={(() => { navigate("/Home/InsuranceMasterList") })}>{isSidebarOpen && <span className="icon-name" >Insurance / Client Master List</span>}</div>}
             {subAccess.includes("PAF02") && <div onClick={(() => { navigate("/Home/Insurance-Form") })}>{isSidebarOpen && <span className="icon-name" >Pre-Auth Form</span>}</div>}
             {subAccess.includes("PAD03") &&<div onClick={(() => { navigate("/Home/Insurance-Dashboard") })}>{isSidebarOpen && <span className="icon-name" >Pre-Auth Dashboard</span>}</div>}
             {subAccess.includes("CFI04") && <div onClick={(() => { navigate("/Home/Claims-Form") })}>{isSidebarOpen && <span className="icon-name" >Claims Form</span>}</div>}
             {subAccess.includes("CDI05") && <div onClick={(() => { navigate("/Home/Claims-Dashboard") })}>{isSidebarOpen && <span className="icon-name" >Claims Dashboard</span>}</div>}
           
          </div>}
        </div>}

          {mainAccess.includes("Pharmacy") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu89')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faFileMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Pharmacy</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu89' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu89' && <div className="subSidebarmenuhover">
              {subAccess.includes("PBPH1") && <div onClick={(() => { navigate("/Home/Pharmacy_Billing_List") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Billing</span>}</div>}
              {/* {subAccess.includes("PBPH1") && <div onClick={(() => { navigate("/Home/IP_Pharmacy_List") })}> {isSidebarOpen && <span className="icon-name" >Ip Pharmacy List</span>}</div>} */}
              {subAccess.includes("PBPH1") && <div onClick={(() => { navigate("/Home/IP_Pharmacy_Billing") })}> {isSidebarOpen && <span className="icon-name" >Ip Pharmacy Billing</span>}</div>}
              
              
              {/* <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div> */}
              {subAccess.includes("BCPH2") && <div onClick={(() => { navigate("/Home/Pharmacy_Billcancelation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation/Refund</span>}</div>}
              {/* {subAccess.includes("BillingHistory") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>} */}

            </div>}
          </div>}


          {mainAccess.includes("Cashier") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu9')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Cashier</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu9' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu9' && <div className="subSidebarmenuhover">
              {subAccess.includes("BCH1") && <div onClick={(() => { navigate("/Home/Billing-Invoice") })}> {isSidebarOpen && <span className="icon-name" >Billing </span>}</div>}

              {subAccess.includes("DHCH2") && <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div>}
              {subAccess.includes("BCCH3") && <div onClick={(() => { navigate("/Home/Bill-Cancellation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation</span>}</div>}
              {subAccess.includes("BHC2") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>}


            </div>}
          </div>}

          {mainAccess.includes("PettyCash") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu8")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Petty Cash</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu8" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("EMPC1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Expenses-Master");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Expense Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("CEPC2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Cash Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DEPC3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Digital-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Digital Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("ERPC4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Expenses Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("HOSPC5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Hand-Over-Summary");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">HandOver Summary</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DRPC6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Book-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DCPC7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Closing");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

{mainAccess.includes("InventoryMasters") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu17')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faSuitcaseMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory Master</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu17' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu17' && <div className="subSidebarmenuhover">

              {subAccess.includes("SLIM1") && <div onClick={(() => { navigate("/Home/Supplier_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Master </span>}</div>}
              {subAccess.includes("PMIM2") && <div onClick={(() => { navigate("/Home/Pharmacy_MasterList") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Master </span>}</div>}
              {subAccess.includes("GMIM3") && <div onClick={(() => { navigate("/Home/General_MasterList") })}> {isSidebarOpen && <span className="icon-name" >General Master </span>}</div>}


            </div>}
          </div>}

          {mainAccess.includes("Inventory") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu15')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faLayerGroup} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu15' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu15' && <div className="subSidebarmenuhover">
              {subAccess.includes("IRLIV1") && <div onClick={(() => { navigate("/Home/Indent_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Raise </span>}</div>}
              {subAccess.includes("PIRECL") && <div onClick={(() => { navigate("/Home/Pharmacy_Indent_Issue_list") })}> {isSidebarOpen && <span className="icon-name">Pharmacy Indent Issue</span>} </div>}
              {subAccess.includes("IRLIV2") && <div onClick={(() => { navigate("/Home/Indent_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve </span>}</div>}
              {subAccess.includes("IRLIV3") && <div onClick={(() => { navigate("/Home/Indent_Return_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Return</span>}</div>}

              {subAccess.includes("IMLIV4") && <div onClick={(() => { navigate("/Home/IndentMovementlist") })}> {isSidebarOpen && <span className="icon-name" >Indent Move </span>}</div>}

              {subAccess.includes("QRLIV5") && <div onClick={(() => { navigate("/Home/Quick_Stock_Recieve_List") })}> {isSidebarOpen && <span className="icon-name" >Quick Stock Recieve</span>}</div>}

              {subAccess.includes("LSLIV6") && <div onClick={(() => { navigate("/Home/LocationStock_List") })}> {isSidebarOpen && <span className="icon-name" >Location Stock List </span>}</div>}
              {subAccess.includes("PLMIV7") && <div onClick={(() => { navigate("/Home/PurchaseReturn_Loc_Master") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}
              {subAccess.includes("SPCS6") && <div onClick={(() => { navigate("/Home/Supplier-Stock-Manager") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}


            </div>}
          </div>}


          {mainAccess.includes("SubCentral") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu22")}>
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faShop}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">Pharmacy Store</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${
                      openSubMenu === "subsidebarmenu22" ? "arrow-rotate" : ""
                    }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu22" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("PIRL") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Pharmacy_Indent_Raise_list");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Pharmacy IndentRaise</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("PIREL") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Pharmacy_Indent_Recieve_list");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">
                          
                          IndentReceive
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
    )}


          {mainAccess.includes("CentralStore") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu16')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faStore} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Central Store</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu16' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu16' && <div className="subSidebarmenuhover">
            {subAccess.includes("QRMLIV5") && <div onClick={(() => { navigate("/Home/QuickStockMasterCentrallist") })}> {isSidebarOpen && <span className="icon-name" > Quick Stock Recieve</span>}</div>}
              {subAccess.includes("PMCS1") && <div onClick={(() => { navigate("/Home/Purchase_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Purchase Master </span>}</div>}
              {subAccess.includes("PRLCS2") && <div onClick={(() => { navigate("/Home/Purchase_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >GRN Recieve</span>}</div>}
              {subAccess.includes("IICD3") && <div onClick={(() => { navigate("/Home/Indent_Issue_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue </span>}</div>}

              {subAccess.includes("PRMCS4") && <div onClick={(() => { navigate("/Home/PurchaseReturn_Master") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}
              {subAccess.includes("CSLCS5") && <div onClick={(() => { navigate("/Home/CentralStock_List") })}> {isSidebarOpen && <span className="icon-name" >Central Stock List </span>}</div>}
              {subAccess.includes("SPCSM6") && <div onClick={(() => { navigate("/Home/Supplier-Stock-Manager") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("CentralStoreApprove") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu18')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faThumbsUp} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">CentralStore Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu18' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu18' && <div className="subSidebarmenuhover">

              {subAccess.includes("GACA1") && <div onClick={(() => { navigate("/Home/GRN_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >GRN Approve </span>}</div>}
              {subAccess.includes("LGACA1") && <div onClick={(() => { navigate("/Home/LocationGRN_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Loc GRN Approve </span>}</div>}
              {subAccess.includes("PACA2") && <div onClick={(() => { navigate("/Home/PurchaseApprove_List") })}> {isSidebarOpen && <span className="icon-name" >Purchase Approve </span>}</div>}
              {subAccess.includes("IACA3") && <div onClick={(() => { navigate("/Home/IndentIssue_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue Approve </span>}</div>}
              {subAccess.includes("IRACA4") && <div onClick={(() => { navigate("/Home/IndentReturnApprove") })}> {isSidebarOpen && <span className="icon-name" >Indent Return Approve </span>}</div>}

            </div>}
          </div>}

          {mainAccess.includes("IndentApprove") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu19')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faIndent} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Indent Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu19' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu19' && <div className="subSidebarmenuhover">

              {subAccess.includes("IAIA1") && <div onClick={(() => { navigate("/Home/Indent_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Approve </span>}</div>}
              {subAccess.includes("IRIA2") && <div onClick={(() => { navigate("/Home/IndentRecieve_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve Approve </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("Reports") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu20')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faRegistered} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Reports</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu20' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu20' && <div className="subSidebarmenuhover">

              {subAccess.includes("SPLR1") && <div onClick={(() => { navigate("/Home/Supplier_Pay_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Pay List </span>}</div>}
              {subAccess.includes("PRR2") && <div onClick={(() => { navigate("/Home/PurchaseRegister") })}> {isSidebarOpen && <span className="icon-name" >Purchase Register </span>}</div>}
              {subAccess.includes("SRR3") && <div onClick={(() => { navigate("/Home/SalesRegister") })}> {isSidebarOpen && <span className="icon-name" >Sales Register </span>}</div>}
              {subAccess.includes("HRR4") && <div onClick={(() => { navigate("/Home/HSNReport") })}> {isSidebarOpen && <span className="icon-name" >HSN Report</span>}</div>}
              {subAccess.includes("DRR5") && <div onClick={(() => { navigate("/Home/DueReport") })}> {isSidebarOpen && <span className="icon-name" >Due Report</span>}</div>}


            </div>}
          </div>}


        {mainAccess.includes("HRManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu10')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faPenNib} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">HR Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu10' ? 'arrow-rotate' : ''}`}
                />
              )}

            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu10' && <div className="subSidebarmenuhover">
              {subAccess.includes("ERHR1") && <div onClick={(() => { navigate("/Home/Employee-Register"); dispatchvalue({ type: 'foremployeeedit', value: [] }) })}>{isSidebarOpen && <span className="icon-name" >Employee Register</span>}</div>}
              {subAccess.includes("ELHR2") && <div onClick={(() => { navigate("/Home/Employee-List") })}>{isSidebarOpen && <span className="icon-name" >Employee List</span>}</div>}
              {subAccess.includes("AHR3") && <div onClick={(() => { navigate("/Home/Employee-Attendance") })}>{isSidebarOpen && <span className="icon-name" >Attendance</span>}</div>}
              {subAccess.includes("LAHR4") && <div onClick={(() => { navigate("/Home/Employee-LeaveApproval") })}> {isSidebarOpen && <span className="icon-name" >Leave Approval </span>}</div>}
              {subAccess.includes("AAHR5") && <div onClick={(() => { navigate("/Home/Employee-AdvanceApproval") })}> {isSidebarOpen && <span className="icon-name" >Advance Approval </span>}</div>}
              {subAccess.includes("PAHR6") && <div onClick={(() => { navigate("/Home/Employee-Performance") })}> {isSidebarOpen && <span className="icon-name" >Performance Appraisal </span>}</div>}
              {subAccess.includes("PMHR7") && <div onClick={(() => { navigate("/Home/Employee-PerformanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Performance Management </span>}</div>}
              {subAccess.includes("LMHR8") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("AMHR9") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {subAccess.includes("PRHR10") && <div onClick={(() => { navigate("/Home/Employee-PayRoll") })}> {isSidebarOpen && <span className="icon-name" >Pay Roll </span>}</div>}
              {subAccess.includes("DM11") && <div onClick={(() => { navigate("/Home/Duty-Management") })}> {isSidebarOpen && <span className="icon-name" >Duty Management</span>}</div>}
              

            </div>}
          </div>
}         

          
{mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu12')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Employee Request</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu12' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu12' && <div className="subSidebarmenuhover">

              {subAccess.includes("LMER1") && <div onClick={(() => { navigate("/Home/Navigation-leave") })}> {isSidebarOpen && <span className="icon-name" >Leave Management</span>}</div>}
              {subAccess.includes("AMER2") && <div onClick={(() => { navigate("/Home/Navigation-Advance") })}>{isSidebarOpen && <span className="icon-name" >Advance Management</span>}</div>}
              {subAccess.includes("SMER3") && <div onClick={(() => { navigate("/Home/Shift-Details") })}>{isSidebarOpen && <span className="icon-name" >Shift Management</span>}</div>}
              {subAccess.includes("PVER4") && <div onClick={(() => { navigate("/Home/Employee-PaySlip-View") })}>{isSidebarOpen && <span className="icon-name" >Pay Slip Download</span>}</div>}


            </div>}
          </div>}

        


          {mainAccess.includes("UserControl") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu40')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faChartBar} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">User Control</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu40' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu40' && <div className="subSidebarmenuhover">
              {subAccess.includes("RMUC1") && <div onClick={(() => { navigate("/Home/Role-Management") })}>{isSidebarOpen && <span className="icon-name" >Role Management </span>}</div>}
              {subAccess.includes("AOD11") && <div onClick={(() => { navigate("/Home/admindocumentslist") })}>{isSidebarOpen && <span className="icon-name" >Admin Office Documents</span>}</div>}
              {subAccess.includes("EQUC2") && <div onClick={(() => { navigate("/Home/EmployeeQue-List") })}> {isSidebarOpen && <span className="icon-name" >Employee Queue List</span>}</div>}
              {subAccess.includes("URUC3") && <div onClick={(() => { navigate("/Home/Register-User"); dispatchvalue({ type: 'foredituserregisteremployeedata', value: [] }); dispatchvalue({ type: "foruserregisteremployeedata", value: [] }) })}> {isSidebarOpen && <span className="icon-name" > User Register</span>}</div>}
              {subAccess.includes("RCUC4") && <div onClick={(() => { navigate("/Home/RateCardMasterAll") })}> {isSidebarOpen && <span className="icon-name" >Ratecard Charges </span>}</div>}
              {subAccess.includes("VDRUC5") && <div onClick={(() => { navigate("/Home/Visiting-Doctor-Register") })}> {isSidebarOpen && <span className="icon-name" >Visiting Doctor Register </span>}</div>}
              {subAccess.includes("ASUC6") && <div onClick={(() => { navigate("/Home/Account Settings") })}> {isSidebarOpen && <span className="icon-name" >Account Settings </span>}</div>}
              {subAccess.includes("CDUC7") && <div onClick={(() => { navigate("/Home/Clinic Details") })}> {isSidebarOpen && <span className="icon-name" >Clinic Details </span>}</div>}
              {subAccess.includes("ULUC8") && <div onClick={(() => { navigate("/Home/User-List") })}> {isSidebarOpen && <span className="icon-name" >User List </span>}</div>}
              {subAccess.includes("LMUC9") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("AMUC10") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {subAccess.includes("VDBUC11") && <div onClick={(() => { navigate("/Home/Visit-Doctor-Billing-List") })}> {isSidebarOpen && <span className="icon-name" >Visit Doctor Billing List</span>}</div>}
              {subAccess.includes("RM") && <div onClick={(() => { navigate("/Home/Room_Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master </span>}</div>}
              {subAccess.includes("RMC") && <div onClick={(() => { navigate("/Home/Room_Create_Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master Create </span>}</div>}
              {subAccess.includes("IPFM") && <div onClick={(() => { navigate("/Home/frequencymaster") })}>{isSidebarOpen && <span className="icon-name" >Frequency Master </span>}</div>}
              {/* {subAccess.includes("IPRC") && <div onClick={(() => { navigate("/Home/IPratecard") })}>{isSidebarOpen && <span className="icon-name" >IP Ratecard </span>}</div>} */}
              {subAccess.includes("RDM") && <div onClick={(() => { navigate("/Home/Referral_Doctor") })}>{isSidebarOpen && <span className="icon-name" >Referral Doctor </span>}</div>}
              {subAccess.includes("TMUS3") && <div onClick={(() => { navigate("/Home/TreatmentMaster") })}>{isSidebarOpen && <span className="icon-name" >Treatment Master </span>}</div>}
              {subAccess.includes("OTSM") && <div onClick={(() => { navigate("/Home/OT-Surgery-Master") })}>{isSidebarOpen && <span className="icon-name" >OT SurgeryMaster</span>}</div>}
            </div>}
          </div>
          }





          {mainAccess.includes("Accounts") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu14")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Accounts</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu14" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>

              {isSidebarOpen && openSubMenu === "subsidebarmenu14" && (

                <div className="subSidebarmenuhover">
                  {subAccess.includes("AGLAC1") && (
                    <div onClick={() => { navigate("/Home/AccountGroupList"); }}>
                      {isSidebarOpen && (
                        <span className="icon-name">Account Group List</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("LEAC2") && (
                    <div onClick={() => { navigate("/Home/LedgerList"); }}>
                      {isSidebarOpen && (
                        <span className="icon-name">Ledger Entry</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("CVAC3") && (
                    <div onClick={() => { navigate("/Home/ContraVoucherList"); }}>
                      {isSidebarOpen && <span className="icon-name">Contra Voucher</span>}
                    </div>
                  )}

                  {subAccess.includes("PVAC4") && (
                    <div onClick={() => { navigate("/Home/PaymentVoucherList"); }}>
                      {isSidebarOpen && <span className="icon-name">Payment Voucher</span>}
                    </div>
                  )}

                  {subAccess.includes("RVAC5") && <div onClick={(() => { navigate("/Home/ReceiptVoucherList") })}>{isSidebarOpen && <span className="icon-name" >Receipt Voucher</span>}</div>}
                  {subAccess.includes("JEAC6") && <div onClick={(() => { navigate("/Home/JournalentryList") })}>{isSidebarOpen && <span className="icon-name" >Journal Entry</span>}</div>}
                  {subAccess.includes("DBAC7") && <div onClick={(() => { navigate("/Home/DayBook") })}>{isSidebarOpen && <span className="icon-name" >Day Book</span>}</div>}
                  {subAccess.includes("TBAC8") && <div onClick={(() => { navigate("/Home/TrialBalance") })}>{isSidebarOpen && <span className="icon-name" >Trial Balance</span>}</div>}
                  {subAccess.includes("PLAC9") && <div onClick={(() => { navigate("/Home/ProfitandLoss") })}>{isSidebarOpen && <span className="icon-name" >Profit & Loss</span>}</div>}
                  {subAccess.includes("CBAC10") && <div onClick={(() => { navigate("/Home/CashbookandBankbook") })}>{isSidebarOpen && <span className="icon-name" >Cashbook & Bankbook</span>}</div>}
                  {subAccess.includes("BSAC11") && <div onClick={(() => { navigate("/Home/BalanceSheet") })}>{isSidebarOpen && <span className="icon-name" >Balance Sheet</span>}</div>}
                </div>
              )}
            </div>
          )}


          <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} onClick={handleLogoutClick} id='font_111'>
            <FontAwesomeIcon icon={faRightFromBracket} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" >Logout</span>}
          </div>

        </div>
      </div>
    </nav >
  );
};

export default Sidebar;



