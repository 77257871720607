import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function Journalentry() {
  const cu_date = format(new Date(), "yyyy-MM-dd");

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [isedit, setisedit] = useState(false);

  const [Paymentlist, setPaymentlist] = useState([]);

  const [ExpensLiblitydata, SetExpensLiblitydata] = useState([]);

  // console.log('Paymentlist',Paymentlist)
  // console.log(userRecord)

  const [PaymentformState, setPaymentformState] = useState({
    VoucherNo: "",
    VoucherDate: cu_date,
    VoucherNarration: "",
    DrAccount: "",
    DrAccountUnderGroup: "",
    DrAccountOpnBalance: "",
    DrAccountCurBalance: "",
    DrAccountBalanceType: "",
    DebitAmount: "",
  });

  console.log("PaymentformState", PaymentformState);

  const [CreditAccountstate, setCreditAccountstate] = useState({
    CrAccount: "",
    CrAccountCurBalance: "",
    CrAccountOpnBalance: "",
    CrAccountBalanceType: "",
    CreditAmount: "",
  });

  const [MultiplePaymentdata, setMultiplePaymentdata] = useState({
    PaymentType: "",
    PaymentAmount: "",
    Remarks: "",
  });

  useEffect(() => {
    getContraNum();
  }, []);

  const getContraNum = () => {
    axios
      .get(
        `https://vesoftometic.co.in/FinanceMagement/get_Journal_Voucher_invoice_no`
      )
      .then((response) => {
        const data = response.data.nextContraInvoiceNo;
        console.log("invoice", data);

        setPaymentformState((prev) => ({
          ...prev,
          VoucherNo: data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Allleddetailes();
  }, []);

  const Allleddetailes = () => {
    axios
      .get(
        `https://vesoftometic.co.in/FinanceMagement/get_Ledger_for_PaymentVocher_Cur_balance`
      )
      .then((response) => {
        const data = response.data.LedgerEntries;
        console.log("invoice", data);
        SetExpensLiblitydata(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PaymentstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "DrAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Ledger_Name === value);

      if (findData) {
        setPaymentformState((prev) => ({
          ...prev,
          [name]: value,
          DrAccountOpnBalance: findData.Current_Balance,
          DrAccountCurBalance: findData.Current_Balance,
          DrAccountBalanceType: findData.Depit_or_Credit,
          DrAccountUnderGroup: findData.Group_Name,
        }));
      } else {
        setPaymentformState((prev) => ({
          ...prev,
          [name]: value,
          DrAccountOpnBalance: "",
          DrAccountCurBalance: "",
          DrAccountBalanceType: "",
          DrAccountUnderGroup: "",
        }));
      }
    } else {
      setPaymentformState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const CreditAccountstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "CrAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Ledger_Name === value);

      if (findData) {
        setCreditAccountstate((prev) => ({
          ...prev,
          [name]: value,
          CrAccountOpnBalance: findData.Current_Balance,
          CrAccountCurBalance: findData.Current_Balance,
          CrAccountBalanceType: findData.Depit_or_Credit,
        }));
      } else {
        setCreditAccountstate((prev) => ({
          ...prev,
          [name]: value,
          CrAccountCurBalance: "",
          CrAccountOpnBalance: "",
          CrAccountBalanceType: "",
          CreditAmount: "",
        }));
      }
    } else {
      setCreditAccountstate((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (CreditAccountstate.CreditAmount !== "") {
      const CrdAmo =
        +CreditAccountstate.CrAccountOpnBalance +
        +CreditAccountstate.CreditAmount;

      setCreditAccountstate((prev) => ({
        ...prev,
        CrAccountCurBalance: CrdAmo,
      }));
    } else {
      setCreditAccountstate((prev) => ({
        ...prev,
        CrAccountCurBalance: +CreditAccountstate.CrAccountOpnBalance,
      }));
    }
  }, [CreditAccountstate.CreditAmount]);

  useEffect(() => {
    if (PaymentformState.DebitAmount !== "") {
      const balAmo =
        +PaymentformState.DrAccountOpnBalance + +PaymentformState.DebitAmount;

      if (balAmo) {
        setPaymentformState((prev) => ({
          ...prev,
          DrAccountCurBalance: balAmo,
        }));
      } else {
        setPaymentformState((prev) => ({
          ...prev,
          DrAccountCurBalance: PaymentformState.DrAccountOpnBalance,
        }));
      }
    }
  }, [PaymentformState.DebitAmount]);

  useEffect(() => {
    if (Paymentlist.length !== 0) {
      const Amounttotal = Paymentlist.reduce(
        (total, ele) => total + +ele.CreditAmount,
        0
      );

      // console.log("Total Amount:", Amounttotal);

      setPaymentformState((prev) => ({
        ...prev,
        DebitAmount: Amounttotal,
      }));
    } else {
      setPaymentformState((prev) => ({
        ...prev,
        DebitAmount: 0,
      }));
    }
  }, [Paymentlist]);

  const ClearContradata = () => {
    setPaymentformState({
      VoucherNo: "",
      VoucherDate: cu_date,
      VoucherNarration: "",
      DrAccount: "",
      DrAccountUnderGroup: "",
      DrAccountOpnBalance: "",
      DrAccountCurBalance: "",
      DrAccountBalanceType: "",
      DebitAmount: "",
    });
  };

  const handlecleardata = () => {
    setCreditAccountstate({
      CrAccount: "",
      CrAccountCurBalance: "",
      CrAccountOpnBalance: "",
      CrAccountBalanceType: "",
      CreditAmount: "",
    });
  };

  const clearmultiplpaydata = () => {
    setMultiplePaymentdata({
      PaymentType: "",
      PaymentAmount: "",
      Remarks: "",
    });
  };

  const add_data = () => {
    console.log(CreditAccountstate);
    const requiredfields = [
      "CrAccount",
      "CrAccountCurBalance",
      "CrAccountBalanceType",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !CreditAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.find(
        (ele) =>
          ele.CrAccount.toLowerCase() ===
          CreditAccountstate.CrAccount.toLowerCase()
      );

      if (CheckName) {
        alert("CrAccount Already Exists");
      } else {
        setPaymentlist((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            ...CreditAccountstate,
          },
        ]);

        handlecleardata();
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Editdataform = (row) => {
    // console.log('row',row)
    if (row) {
      let paydatas = row.MulitPayment;
      setCreditAccountstate((prev) => ({
        ...prev,
        id: row.id,
        CrAccount: row.CrAccount,
        CrAccountCurBalance: row.CrAccountCurBalance,
        CrAccountOpnBalance: row.CrAccountOpnBalance,
        CrAccountBalanceType: row.CrAccountBalanceType,
        CreditAmount: row.CreditAmount,
      }));
    }
  };

  const Deletedataform = (row) => {
    setPaymentlist((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const update_data = () => {
    const requiredfields = [
      "CrAccount",
      "CrAccountCurBalance",
      "CrAccountBalanceType",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !CreditAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.filter(
        (ele) => ele.id !== CreditAccountstate.id
      ).find(
        (ele) =>
          ele.CrAccount.toLowerCase() ===
          CreditAccountstate.CrAccount.toLowerCase()
      );

      if (CheckName) {
        alert("CrAccount Already Exists");
      } else {
        setPaymentlist((prevList) => {
          const updatedList = prevList.filter(
            (ele) => ele.CrAccount !== CreditAccountstate.CrAccount
          );
          return [...updatedList, { ...CreditAccountstate }];
        });

        handlecleardata();
        setisedit(false);
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Postalldata = () => {
    const requiredfields = [
      "VoucherNo",
      "VoucherDate",
      "VoucherNarration",
      "DrAccount",
      "DrAccountCurBalance",
      "DrAccountBalanceType",
      "DebitAmount",
    ];

    const existing = requiredfields.filter((field) => !PaymentformState[field]);

    if (existing.length === 0) {
      let Insertdata = {
        PaymentformState: PaymentformState,
        Paymentlist: Paymentlist,
        Location: userRecord.location,
        CreatedBy: userRecord.username,
      };

      console.log("Insertdata", Insertdata);

      axios
        .post(
          "https://vesoftometic.co.in/FinanceMagement/insert_Journal_Voucher_Entry_details",
          Insertdata
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.Message);
          ClearContradata();
          setPaymentlist([]);
          getContraNum();
          Allleddetailes();
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Journal Voucher</h4>
      </div>

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label>
            Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            required
            readOnly
            value={PaymentformState.VoucherNo}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label>
            Voucher Date <span>:</span>
          </label>
          <input
            type="date"
            name="VoucherDate"
            required
            value={PaymentformState.VoucherDate}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label>
            Short Narration <span>:</span>
          </label>
          <textarea
            type="text"
            name="VoucherNarration"
            required
            value={PaymentformState.VoucherNarration}
            onChange={PaymentstateFun}
          />
        </div>
     

      <div className="RegisForm_1">
        <label>
          Dr Account <span>:</span>
        </label>
        <input
          type="text"
          name="DrAccount"
          list="DrAC_list"
          required
          value={PaymentformState.DrAccount}
          onChange={PaymentstateFun}
        />
        <datalist id="DrAC_list">
          {ExpensLiblitydata.map((item, index) => (
            <option key={index} value={item.Ledger_Name}></option>
          ))}
        </datalist>
      </div>

      <div className="RegisForm_1">
        <label>
          Cur Balance<span>:</span>
        </label>
        <input
          type="text"
          name="DrAccountCurBalance"
          required
          readOnly
          value={PaymentformState.DrAccountCurBalance}
          onChange={PaymentstateFun}
        />
      </div>

      <div className="RegisForm_1">
        <label>
          Dr/Cr<span>:</span>
        </label>
        <input
          type="text"
          name="DrAccountBalanceType"
          required
          readOnly
          value={PaymentformState.DrAccountBalanceType}
          onChange={PaymentstateFun}
        />
      </div>

      <div className="RegisForm_1">
        <label>
          Debit <span>:</span>
        </label>
        <input
          type="text"
          name="DebitAmount"
          required
          readOnly
          value={PaymentformState.DebitAmount}
          onChange={PaymentstateFun}
        />
      </div>

      <div className="RegisForm_1">
        <label>
          Cr Account <span>:</span>
        </label>
        <input
          type="text"
          name="CrAccount"
          list="CrAC_list"
     
          required
          value={CreditAccountstate.CrAccount}
          onChange={CreditAccountstateFun}
        />
        <datalist id="CrAC_list">
          {ExpensLiblitydata.filter(
            (item) => item.Ledger_Name !== PaymentformState.DrAccount
          ).map((item, index) => (
            <option key={index} value={item.Ledger_Name}></option>
          ))}
        </datalist>
      </div>

      <div className="RegisForm_1">
        <label>
          Cur Balance<span>:</span>
        </label>
        <input
          type="text"
          name="CrAccountCurBalance"
          placeholder="Enter Cur Balance"
          required
          readOnly
          value={CreditAccountstate.CrAccountCurBalance}
          onChange={CreditAccountstateFun}
        />
      </div>

      <div className="RegisForm_1">
        <label>
          Dr/Cr<span>:</span>
        </label>
        <input
          type="text"
          name="CrAccountBalanceType"
  
          required
          readOnly
          value={CreditAccountstate.CrAccountBalanceType}
          onChange={CreditAccountstateFun}
        />
      </div>

      <div className="RegisForm_1">
        <label>
          Credit <span>:</span>
        </label>
        <input
          type="number"
          name="CreditAmount"
    
          required
          value={CreditAccountstate.CreditAmount}
          onChange={CreditAccountstateFun}
        />
      </div>

     
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={isedit ? update_data : add_data}
        >
          {isedit ? "Update" : "Add"}
        </button>
      </div>
      
        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Particulars</th>
                  <th>Current Balance</th>
                  <th>Debit</th>
                  <th>CreditAmount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Paymentlist.map((row, index) => (
                  <tr key={index}>
                    <td>{row.id}</td>
                    <td>{row.CrAccount}</td>
                    <td>{row.CrAccountCurBalance}</td>
                    <td>{row.CrAccountBalanceType}</td>
                    <td>{row.CreditAmount}</td>
                    <td>
                      <EditIcon
                        onClick={() => {
                          Editdataform(row);
                          setisedit(true);
                        }}
                      />

                      <DeleteIcon
                        onClick={() => {
                          Deletedataform(row);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="for_total9">
          <div className="for_total9_xyz">
              Total
            </div>
            <span>:</span>
            <div className="for_total9_xyz">
              {PaymentformState.DebitAmount || 0}
            </div>
          </div>
    

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          // onClick={isedit?update_data:add_data}
          onClick={Postalldata}
        >
          {/* {isedit?'Update':'Submit'} */}
          Submit
        </button>
      </div>
    </div>
  );
}

export default Journalentry;
