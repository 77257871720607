import React, { useState, useEffect, useRef } from "react";
import "./Commonregister.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears, format } from "date-fns";
import femalpatient from "../../assets/femalpatient.png";
import malepatient from "../../assets/malepatient.png";

const OpRegistertration = () => {
    const successMsg = (message) => {
        toast.success(`${message}`, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            containerId: "toast-container-over-header",
            style: { marginTop: "50px" },
        });
    };
    const userwarn = (warningMessage) => {
        toast.warn(`${warningMessage}`, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };
    const errmsg = (errormsg) => {
        toast.error(`${errormsg}`, {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };

    const dispatchvalue = useDispatch();
    const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();



    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

    const selectedRequestList = useSelector(
        (state) => state.userRecord?.selectedRequestList
    );
    const data1 = selectedRequestList;
    console.log(data1)
    const [doctorNames, setDoctorNames] = useState([]);
    const navigate = useNavigate();

    const [InsuranceName, setInsuranceName] = useState([]);
    const [ClientName, setClinentName] = useState([]);
    const [RouteNo, setRoute_No] = useState([]);
    const [RouteName, setRouteName] = useState([]);
    const [ThasilName, setThasilName] = useState([]);
    const [VillageName, setVillageName] = useState([]);
    const [ReferType, setReferType] = useState([]);
    const [ReferName, setReferName] = useState([]);
    const [FormDataOpRegister, setFormDataOpRegister] = useState({
        PatientID: "",
        FirstName: "",
        MiddleName: "",
        SurName: "",
        PhoneNo: "",
        FamilyPhoneNo: "",
        Title: "",
        Gender: "",
        DOB: "",
        Age: "",
        BloodGroup: "",
        Religion: "",
        Email: "",
        Nationality: "",
        UniqueIdType: "",
        UniqueIdNo: "",
        VisitPurpose: "",
        Specialization: "",
        DoctorName: "",
        AppointmentDate: "",
        AppointmentId: "",
        VisitId: "",
        AppointmentSlot: "",
        DoorNo:'',
        Street:'',
        Area:'',
        City:'',
        State:'',
        Country:'',
        Pincode:'',
        FamilyHead: "Yes",
        FamilyHeadName: "",
        NextToKin: "",
        Relation: "",
        RelativePhoneNo: "",
        RelativeAdderess: "",
        RouteNo: "",
        RouteName: "",
        TeshilName: "",
        VillageName: "",
        RefferType: "",
        RefferedBy: "",
        Complaint: "",
        PatientType: "General",
        PatientCategory: "General",
        RateCardType: "General",
        ClientName: "",
        InsuranceName: "",
        InsuranceNo: "",
        PatientPhoto: "",
        ColorFlag: "",
        ConsultancyFee: '',
        GstCharge: '',
        Status: "pending",
        Location:'',
        CreatedBy:''

    });

    const [PatientListSearch, setPatientListSearch] = useState([]);
    const [FilteredFormdata, setFilteredFormdata] = useState([]);
    const [datafromrequest, setdatafromrequest] = useState(null);
    console.log(datafromrequest)

    const [SpecialitiesData, setSpecialitiesData] = useState([])
    const [ReligionData, setReligionData] = useState([]);

    const resetFormDataOpRegister = () => {
       setFormDataOpRegister((prev)=>({
        ...prev,
        PatientID: "",
        FirstName: "",
        MiddleName: "",
        SurName: "",
        PhoneNo: "",
        FamilyPhoneNo: "",
        Title: "",
        Gender: "",
        DOB: "",
        Age: "",
        BloodGroup: "",
        Religion: "",
        Email: "",
        Nationality: "",
        UniqueIdType: "",
        UniqueIdNo: "",
        VisitPurpose: "",
        Specialization: "",
        DoctorName: "",
        AppointmentDate: "",
        AppointmentId: "",
        VisitId: "",
        AppointmentSlot: "",
        DoorNo:'',
        Street:'',
        Area:'',
        City:'',
        State:'',
        Country:'',
        Pincode:'',
        FamilyHead: "Yes",
        FamilyHeadName: "",
        NextToKin: "",
        Relation: "",
        RelativePhoneNo: "",
        RelativeAdderess: "",
        RouteNo: "",
        RouteName: "",
        TeshilName: "",
        VillageName: "",
        RefferType: "",
        RefferedBy: "",
        Complaint: "",
        PatientType: "General",
        PatientCategory: "General",
        RateCardType: "General",
        ClientName: "",
        InsuranceName: "",
        InsuranceNo: "",
        PatientPhoto: "",
        ColorFlag: "",
        ConsultancyFee: '',
        GstCharge: '',
        Status: "pending",
       }))
    };

    const handleFormChangeOp = (event) => {
        const { name, value } = event.target;

        if (name === "DOB") {
            const newDate = new Date();

            const oldDate = new Date(value);
            const age = differenceInYears(newDate, oldDate);
            setFormDataOpRegister((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                Age: age,
            }));
        } else if (name === "Gender") {
            let defaultPhotoFile = null;

            const defaultPhotoPath = value === "Male" ? malepatient : femalpatient;

            fetch(defaultPhotoPath)
                .then((response) => response.blob()) // Convert response to blob
                .then((blob) => {
                    // Create a File object from the blob
                    defaultPhotoFile = new File([blob], `${value}_captured_image.jpg`, {
                        type: "image/jpeg",
                    });

                    // Update formData with the File object
                    setFormDataOpRegister((prevFormData) => ({
                        ...prevFormData,
                        [name]: value,
                        PatientPhoto: defaultPhotoFile,
                    }));
                })
                .catch((error) => { });
        } else if (name === "PhoneNo" || name === 'FamilyPhoneNo') {
            const newval = value.length;
            if (newval <= 10) {
                setFormDataOpRegister((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                }));
            } else {
                alert("Phone No must contain 10 digits");
            }
        } else {
            setFormDataOpRegister((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    // useEffect(() => {
    //     console.log('selectedRequestList', selectedRequestList)
    //     if (selectedRequestList === null) {
    //         axios
    //             .get(`${UrlLink}appointmentmanagement/get_patient_Id`)
    //             .then((response) => {
    //                 if (response.data.error) {
    //                     userwarn(response.data.error)
    //                 } else {
    //                     setFormDataOpRegister((prev) => ({
    //                         ...prev,
    //                         PatientID: response.data.nextpatientid,
    //                     }));
    //                 }

    //             })
    //             .catch((error) => { });
    //     }
    // }, [selectedRequestList]); // Add data1 to the dependency array

    // ------------
   

    console.log('FormDataOpRegister', FormDataOpRegister);

    useEffect(() => {
        console.log(selectedRequestList)
        if (selectedRequestList === null && FormDataOpRegister?.PatientID) {
            axios
                .get(
                    `${UrlLink}appointmentmanagement/get_visitid_by_patientid?patientid=${FormDataOpRegister.PatientID}`
                )
                .then((response) => {
                    console.log(response)
                    if (response.data.length > 0) {
                        // Check if the response data array is not empty
                        const visitData = response.data[0];
                        console.log(visitData, '-=-=--=-=');
                        setFormDataOpRegister((prev) => ({
                            ...prev,
                            VisitId: visitData.visitid, // Access the visitid field from the visitData object
                        }));
                    } else {
                    }
                })
                .catch((error) => { });
        } else {
            setFormDataOpRegister((prev) => ({
                ...prev,
                VisitId: '', // Access the visitid field from the visitData object
            }));
        }
    }, [selectedRequestList, FormDataOpRegister.PatientID]);

    useEffect(() => {
        console.log('data1',data1)
        if (data1) {
            if (data1.selectedid) {
                axios
                    .get(
                        `${UrlLink}appointmentmanagement/check_patient_id?patient_id=${data1.patientid}&location=${userRecord?.location}`
                    )
                    .then((response) => {
                        const visit_id = response.data.visitid;
                        if (!isNaN(visit_id)) {
                            setFormDataOpRegister((prev) => ({
                                ...prev,
                                VisitId: visit_id,
                            }));
                        } else {
                            userwarn("Register");
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    });

                axios
                    .get(
                        `${UrlLink}appointmentmanagement/get_appointment_patientrequest?app_req_id=${data1.selectedid}&location=${userRecord?.location}`
                    )
                    .then((response) => {
                        const data2 = response.data;

                        // Assuming data2 is an array of appointments
                        if (data2 && data2.length > 0) {
                            const firstAppointment = data2[0];
                            console.log('firstAppointmen',firstAppointment)
                            setdatafromrequest(firstAppointment);
                            axios
                                .get(`${UrlLink}appointmentmanagement/get_Doctor_Slot`, {
                                    params: {
                                        doctorName: firstAppointment.DoctorName,
                                        appointmentDate: firstAppointment.AppointmentDate,
                                        location: userRecord?.location,
                                    },
                                })
                                .then((response) => {
                                    // Handle the successful response

                                    const data = response.data.nextSlot;
                                    setFormDataOpRegister((prev) => ({
                                        ...prev,
                                        PatientID: data1.patientid,
                                        AppointmentId: firstAppointment.AppointmentID,
                                        Title: firstAppointment.Title,
                                        FirstName: firstAppointment.FirstName,
                                        MiddleName: firstAppointment.MiddleName,
                                        SurName: firstAppointment.LastName,
                                        PhoneNo: firstAppointment.PhoneNumber,
                                        Email: firstAppointment.Email,
                                        VisitPurpose: firstAppointment.AppointmentPurpose,
                                        AppointmentDate: format(
                                            new Date(firstAppointment.AppointmentDate),
                                            "yyyy-MM-dd"
                                        ),
                                        AppointmentSlot: data,
                                        DoctorName: firstAppointment.DoctorName,
                                        Status: firstAppointment?.Status,
                                    }));
                                })
                                .catch((error) => {
                                    // Handle errors during the request
                                });
                        }
                    })
                    .catch((error) => { });

                // axios
                //     .get(
                //         `${UrlLink}patientmanagement/getpatientpersonalbyid?patientid=${data1.patientid}&visitid=${FormDataOpRegister.VisitId}`
                //     )
                //     .then((response) => {
                //         const data = response.data[0];
                //         setFormDataOpRegister((prev) => ({
                //             ...prev,
                //             Gender: data?.Gender,
                //             DOB: data?.DOB,
                //             Age: data?.Age, // Corrected from data.Nationality to data.Age
                //             PatientType: data?.PatientType,
                //             PatientCategory: data?.PatientCategory,
                //             RateCardType: data?.RateCardType,
                //             ClientName: data?.ClientName,
                //             InsuranceName: data?.InsuranceName,
                //             InsuranceNo: data?.InsuranceNo,
                //             Complaint: data?.complaint,
                //         }));
                //     });
            }
        }
    }, [data1, userRecord, selectedRequestList, FormDataOpRegister.VisitId]);

    useEffect(() => {
        console.log("DoctorName:", FormDataOpRegister.DoctorName);
        console.log("RateCardType:", FormDataOpRegister.RateCardType);
        console.log("Specialization:", FormDataOpRegister.Specialization);
        console.log("ClientName:", FormDataOpRegister.ClientName);
        console.log("InsuranceName:", FormDataOpRegister.InsuranceName);
        console.log("Location:", userRecord?.location);
        
        if (
            (FormDataOpRegister.DoctorName &&
                FormDataOpRegister.Specialization &&
                FormDataOpRegister.RateCardType &&
                userRecord?.location) 
        ) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_doctor_service_charge?ServiceType=Doctor&ServiceName=${FormDataOpRegister.DoctorName}&specialties=${FormDataOpRegister.Specialization}&PatientCategory=${FormDataOpRegister.RateCardType}&location=${userRecord?.location}&PatientCategoryName=${FormDataOpRegister.RateCardType === 'Client' ? FormDataOpRegister.ClientName : FormDataOpRegister.InsuranceName}`
                )
                .then((response) => {
                    const data = response.data;
                    console.log('data', data);
                    if (data.Charge) {
                        setFormDataOpRegister((prev) => ({
                            ...prev,
                            ConsultancyFee: parseFloat(data.Charge),
                            GstCharge: parseFloat(data.GST),
                        }));
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [
        FormDataOpRegister.DoctorName,
        FormDataOpRegister.RateCardType,
        FormDataOpRegister.Specialization,
        FormDataOpRegister.ClientName,
        FormDataOpRegister.InsuranceName,
        userRecord?.location,
        UrlLink
    ]);
    
    useEffect(() => {
        setFormDataOpRegister((prev) => ({
            ...prev,
            Location: userRecord?.location,
            CreatedBy: userRecord?.username,
            AppointmentDate: format(new Date(), 'yyyy-MM-dd')
        }))
        axios
            .get(`${UrlLink}usercontrol/get_Specialities_data`)
            .then((response) => {
                const data = response.data;
                console.log(data);

                setSpecialitiesData(data.filter((p) => p.status === 'Active').map((p) => ({
                    ...p,
                    Specialities_name: p.Specialities_name.trim()
                })));
            })
            .catch((error) => {
                console.error("Error fetching Specialities data:", error);
            });

        axios
            .get(
                `${UrlLink}usercontrol/get_Insurance_client_Names?location=${userRecord?.location}`
            )
            .then((response) => {
                const data = response.data;
                if (data) {
                    if (data.ClientName) {
                        setClinentName(data.ClientName || []);
                    }
                    if (data.InsuranceName) {
                        setInsuranceName(data.InsuranceName || []);
                    }
                }
            })
            .catch((error) => { });
        axios
            .get(`${UrlLink}usercontrol/getReligion`)
            .then((response) => {
                const data = response.data;
                console.log("religion", response);

                setReligionData(data);
            })
            .catch((error) => {
                console.error("Error Religion data:", error);
            });

    }, [userRecord?.location, UrlLink]);



    // -------------------
    useEffect(() => {
        if (userRecord?.location) {
            axios
                .get(`${UrlLink}usercontrol/get_route?location=${userRecord?.location}`)
                .then((response) => {
                    console.log("routeno", response.data);
                    setRoute_No(response.data);
                })

                .catch((error) => {
                    console.log(error);
                });
        }
        setFormDataOpRegister((prev)=>({
            ...prev,
            Location:userRecord?.location,
            CreatedBy:userRecord?.username
        }))
    }, [userRecord?.location]);

    useEffect(() => {
        if (userRecord?.location && FormDataOpRegister.RouteNo) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_route_name?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}`
                )
                .then((response) => {
                    console.log("routename", response.data);
                    setRouteName(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userRecord?.location, FormDataOpRegister.RouteNo]);
    useEffect(() => {
        if (userRecord?.location && FormDataOpRegister.RouteNo) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_route_name?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}`
                )
                .then((response) => {
                    console.log("routename", response.data);
                    setRouteName(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userRecord?.location, FormDataOpRegister.RouteNo]);
    useEffect(() => {
        if (userRecord?.location && FormDataOpRegister.RouteNo && FormDataOpRegister.RouteName) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_tehsilby_route?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}&routename=${FormDataOpRegister.RouteName}`
                )
                .then((response) => {
                    console.log("thasilname", response.data);
                    setThasilName(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userRecord?.location, FormDataOpRegister.RouteNo, FormDataOpRegister.RouteName]);

    useEffect(() => {
        if (
            userRecord?.location &&
            FormDataOpRegister.RouteNo &&
            FormDataOpRegister.RouteName &&
            FormDataOpRegister.TeshilName
        ) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_village_route?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}&routename=${FormDataOpRegister.RouteName}&thasilname=${FormDataOpRegister.TeshilName}`
                )
                .then((response) => {
                    console.log("villagename", response.data);
                    setVillageName(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [
        userRecord?.location,
        FormDataOpRegister.RouteNo,
        FormDataOpRegister.RouteName,
        FormDataOpRegister.TeshilName,
    ]);

    useEffect(() => {
        if (
            userRecord?.location &&
            FormDataOpRegister.RouteNo &&
            FormDataOpRegister.RouteName &&
            FormDataOpRegister.TeshilName &&
            FormDataOpRegister.VillageName
        ) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_referal_Type?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}&routename=${FormDataOpRegister.RouteName}&thasilname=${FormDataOpRegister.TeshilName}&villagename=${FormDataOpRegister.VillageName}`
                )
                .then((response) => {
                    console.log("setReferType", response.data);
                    setReferType(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [
        userRecord?.location,
        FormDataOpRegister.RouteNo,
        FormDataOpRegister.RouteName,
        FormDataOpRegister.TeshilName,
        FormDataOpRegister.VillageName
    ]);


    useEffect(() => {
        if (
            userRecord?.location &&
            FormDataOpRegister.RouteNo &&
            FormDataOpRegister.RouteName &&
            FormDataOpRegister.TeshilName &&
            FormDataOpRegister.VillageName &&
            FormDataOpRegister.RefferType
        ) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_refferedby_name?location=${userRecord?.location}&routerno=${FormDataOpRegister.RouteNo}&routename=${FormDataOpRegister.RouteName}&thasilname=${FormDataOpRegister.TeshilName}&villagename=${FormDataOpRegister.VillageName}&refertype=${FormDataOpRegister.RefferType}`
                )
                .then((response) => {
                    console.log("setReferName", response.data);
                    setReferName(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [
        userRecord?.location,
        FormDataOpRegister.RouteNo,
        FormDataOpRegister.RouteName,
        FormDataOpRegister.TeshilName,
        FormDataOpRegister.VillageName,
        FormDataOpRegister.RefferType
    ]);


    useEffect(() => {
        if (FormDataOpRegister.Specialization) {
            axios
                .get(
                    `${UrlLink}usercontrol/get_doctor_info?location=${userRecord?.location}&Specialization=${FormDataOpRegister.Specialization.trim()}`
                )
                .then((response) => {
                    const data = response.data;
                    setDoctorNames(data || []);
                })
                .catch((error) => { });
        }

    }, [FormDataOpRegister.Specialization, userRecord?.location])

    useEffect(() => {
        if (FormDataOpRegister.DoctorName && FormDataOpRegister.AppointmentDate && FormDataOpRegister.Specialization) {
            // Log a message (optional)

            // Make an axios GET request to the specified endpoint
            axios
                .get(`${UrlLink}appointmentmanagement/get_Doctor_Slot`, {
                    params: {
                        doctorName: FormDataOpRegister.DoctorName,
                        appointmentDate: FormDataOpRegister.AppointmentDate,
                        Specialization: FormDataOpRegister.Specialization,
                        location: userRecord?.location,
                    },
                })
                .then((response) => {
                    // Handle the successful response

                    const data = response.data.nextSlot;
                    setFormDataOpRegister((prev) => ({
                        ...prev,
                        AppointmentSlot: data,
                    })); // Log the data (optional)
                })
                .catch((error) => {
                    // Handle errors during the request
                    console.error("Error fetching Doctor Slot:", error);
                });
        }
    }, [FormDataOpRegister.DoctorName, FormDataOpRegister.AppointmentDate, FormDataOpRegister.Specialization]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        console.log('FormDataOpRegister', FormDataOpRegister)
        const formData1 = new FormData();

        // Append data to FormData object
        Object.keys(FormDataOpRegister).forEach((key) => {
            formData1.append(key, FormDataOpRegister[key]);
            formData1.append("Created_by", userRecord?.username);
            formData1.append('ServiceType', "DoctorConsultation")
            formData1.append('Sessions', 0)
            formData1.append('PaidSessions', 0)
            formData1.append('RemainingSessions', 0)
            formData1.append('Amount', parseFloat(FormDataOpRegister.ConsultancyFee))
            formData1.append('appointmentDate', FormDataOpRegister.AppointmentDate)
            formData1.append('DoctorName', FormDataOpRegister.DoctorName)
            formData1.append('Discount', 0)
            formData1.append('Gstcharge', +FormDataOpRegister.GstCharge)
            formData1.append('TotalAmount', parseFloat(FormDataOpRegister.ConsultancyFee))
            formData1.append('Status', FormDataOpRegister.ConsultancyFee == 0 ? "Paid" : "Pending")
            formData1.append('location', userRecord?.location)
            formData1.append('PatientName', FormDataOpRegister.FirstName +
                " " +
                FormDataOpRegister.MiddleName +
                " " +
                FormDataOpRegister.SurName,)
        });

        console.table("formdata : ", FormDataOpRegister);

        const BillingData = [
            {
                PatientID: FormDataOpRegister?.PatientID,
                PatientName:
                    FormDataOpRegister.FirstName +
                    " " +
                    FormDataOpRegister.MiddleName +
                    " " +
                    FormDataOpRegister.SurName,
                VisitID: parseInt(FormDataOpRegister.VisitId),
                ServiceType: "DoctorConsultation",
                Sessions: 0,
                PaidSessions: 0,
                RemainingSessions: 0,
                Amount: parseFloat(FormDataOpRegister.ConsultancyFee),
                appointmentDate: FormDataOpRegister.AppointmentDate,
                DoctorName: FormDataOpRegister.DoctorName,
                Discount: 0,
                Gstcharge: +FormDataOpRegister.GstCharge,
                TotalAmount: parseFloat(FormDataOpRegister.ConsultancyFee),
                Status: FormDataOpRegister.ConsultancyFee == 0 ? "Paid" : "Pending",
                location: userRecord?.location,
            },
        ];
        const existing = FilteredFormdata.filter((p) => !['PatientID','UniqueIdType','UniqueIdNo','GstCharge'].includes(p)).filter((field) => !FormDataOpRegister[field])

        if (existing.length === 0) {

            axios
                .post(
                    `${UrlLink}appointmentmanagement/insert_appointment_register`,
                    formData1
                )
                .then((response) => {
                    if (response.data.message === "Success") {
                        axios
                            .post(`${UrlLink}GeneralBilling/insertGeneral_Billing_Data`, BillingData)
                            .then((response) => {
                                successMsg("Saved Successfully");
                                navigate("/Home/Appoinment-RegisterList");
                            })
                            .catch((error) => { console.log(error) });

                    }
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        } else {
            userwarn(`Please fill  all the Required Fields : ${existing.join(' , ')}`)
        }
    };



    useEffect(() => {
        const appointmentDate = FormDataOpRegister.AppointmentDate;

        if (!!appointmentDate) {
            axios
                .get(
                    `${UrlLink}appointmentmanagement/get_appointmentid?appointmentDate=${appointmentDate}`
                )
                .then((response) => {
                    const data = response.data[0].Next_appointmentid;
                    if (!!data) {
                        setFormDataOpRegister((prevFormData) => ({
                            ...prevFormData,
                            AppointmentId: response.data[0].Next_appointmentid,
                        }));
                    } else {
                        console.error(
                            "Invalid response structure or missing Next_appointmentid property."
                        );
                        setFormDataOpRegister((prevFormData) => ({
                            ...prevFormData,
                            AppointmentId: '',
                        }));
                    }
                })
                .catch((error) => { });
        }
    }, [FormDataOpRegister.AppointmentDate, selectedRequestList]);


    // useEffect(() => {
    //     if (PatientListSearch.length > 0 && FormDataOpRegister.PatientID) {
    //         console.log('----00000');
    //         const rowdata = PatientListSearch.find((p) => {
    //             return (p.PatientID === FormDataOpRegister.PatientID)
    //         })
    //         if (rowdata) {
    //             setFormDataOpRegister((prevFormData) => ({
    //                 ...prevFormData,
    //                 PatientID: rowdata?.PatientID,
    //                 PhoneNo: rowdata?.PhoneNo,
    //                 FamilyPhoneNo: rowdata?.FamilyPhoneNo,
    //                 Title: rowdata?.Title,
    //                 FirstName: rowdata?.FirstName,
    //                 MiddleName: rowdata?.MiddleName,
    //                 SurName: rowdata?.SurName,
    //                 Gender: rowdata?.Gender,
    //                 DOB: rowdata?.DOB,
    //                 Age: rowdata?.Age,
    //                 BloodGroup: "",
    //                 Religion: "",
    //                 Email: rowdata?.Email,
    //                 Nationality: rowdata?.Nationality,
    //                 UniqueIdType: rowdata?.UniqueIdType,
    //                 UniqueIdNo: rowdata?.UniqueIdNo,
    //                 RouteNo: rowdata?.RouteNo,
    //                 RouteName: rowdata?.RouteName,
    //                 TeshilName: rowdata?.TeshilName,
    //                 VillageName: rowdata?.VillageName,
    //                 RefferType: rowdata?.RefferType,
    //                 RefferedBy: rowdata?.RefferedBy,
    //                 PatientType: rowdata?.PatientType,
    //                 PatientCategory: rowdata?.PatientCategory,
    //                 RateCardType: rowdata?.RateCardType,
    //                 ClientName: rowdata?.ClientName,
    //                 InsuranceName: rowdata?.InsuranceName,
    //                 InsuranceNo: rowdata?.InsuranceNo,
    //                 ColorFlag: rowdata?.ColorFlag,
    //             }));
    //         } else {
    //             setFormDataOpRegister((prev) => ({
    //                 ...prev,
    //                 PhoneNo: "",
    //                 FamilyPhoneNo: "",
    //                 AppointmentId: "",
    //                 VisitId: "",
    //                 Title: "",
    //                 FirstName: "",
    //                 MiddleName: "",
    //                 SurName: "",
    //                 Gender: "",
    //                 DOB: "",
    //                 Age: "",
    //                 BloodGroup: "",
    //                 Religion: "",
    //                 Email: "",
    //                 Nationality: "",
    //                 UniqueIdType: "",
    //                 UniqueIdNo: "",
    //                 VisitPurpose: "",
    //                 Specialization: "",
    //                 DoctorName: "",
    //                 AppointmentDate: "",
    //                 AppointmentSlot: "",
    //                 RouteNo: "",
    //                 RouteName: "",
    //                 TeshilName: "",
    //                 VillageName: "",
    //                 RefferType: "",
    //                 RefferedBy: "",
    //                 Complaint: "",
    //                 PatientType: "General",
    //                 PatientCategory: "General",
    //                 RateCardType: "General",
    //                 ClientName: "",
    //                 InsuranceName: "",
    //                 InsuranceNo: "",
    //                 PatientPhoto: "",
    //                 ColorFlag: "",
    //                 ConsultancyFee: "",
    //                 GstCharge: "",
    //                 Status: "pending",
    //                 Location: userRecord?.location,
    //             }))
    //         }
    //     }


    // }, [FormDataOpRegister.PatientID, PatientListSearch])

    const handleSearch = () => {
        const patientID = FormDataOpRegister.PatientID || '';
        const phone = FormDataOpRegister.PhoneNo || '';
        const Familyphone = FormDataOpRegister.FamilyPhoneNo || '';

        if (patientID || phone || Familyphone) {
            axios
                .get(
                    `${UrlLink}appointmentmanagement/get_for_directappointmentreg?selected_patientid=${patientID}&phonenumber=${phone}&Familyphone=${Familyphone}`
                )
                .then((response) => {
                    console.log('response', response.data);
                    if (Array.isArray(response.data) && response.data !== null) {
                        setPatientListSearch(response.data)
                        setFormDataOpRegister((prev) => ({
                            ...prev,
                            AppointmentId: "",
                            VisitId: "",
                            Title: "",
                            FirstName: "",
                            MiddleName: "",
                            SurName: "",
                            Gender: "",
                            DOB: "",
                            Age: "",
                            BloodGroup: "",
                            Religion: "",
                            Email: "",
                            Nationality: "",
                            UniqueIdType: "",
                            UniqueIdNo: "",
                            VisitPurpose: "",
                            Specialization: "",
                            DoctorName: "",
                            AppointmentDate: "",
                            AppointmentSlot: "",
                            RouteNo: "",
                            RouteName: "",
                            TeshilName: "",
                            VillageName: "",
                            RefferType: "",
                            RefferedBy: "",
                            Complaint: "",
                            PatientType: "General",
                            PatientCategory: "General",
                            RateCardType: "General",
                            ClientName: "",
                            InsuranceName: "",
                            InsuranceNo: "",
                            PatientPhoto: "",
                            ColorFlag: "",
                            ConsultancyFee: "",
                            GstCharge: "",
                            Status: "pending",
                            Location: userRecord?.location,
                        }))
                        console.log('----');
                    } else if (response.data !== null) {
                        setPatientListSearch([])
                        console.log(response.data, '----')
                        const rowdata = response.data
                        setFormDataOpRegister((prevFormData) => ({
                            ...prevFormData,
                            PatientID: rowdata?.PatientID,
                            PhoneNo: rowdata?.PhoneNo,
                            FamilyPhoneNo: rowdata?.FamilyPhoneNo,
                            Title: rowdata?.Title,
                            FirstName: rowdata?.FirstName,
                            MiddleName: rowdata?.MiddleName,
                            SurName: rowdata?.SurName,
                            Gender: rowdata?.Gender,
                            DOB: rowdata?.DOB,
                            Age: rowdata?.Age,
                            Email: rowdata?.Email,
                            Nationality: rowdata?.Nationality,
                            UniqueIdType: rowdata?.UniqueIdType,
                            UniqueIdNo: rowdata?.UniqueIdNo,
                            RouteNo: rowdata?.RouteNo,
                            RouteName: rowdata?.RouteName,
                            TeshilName: rowdata?.TeshilName,
                            VillageName: rowdata?.VillageName,
                            RefferType: rowdata?.RefferType,
                            RefferedBy: rowdata?.RefferedBy,
                            PatientType: rowdata?.PatientType,
                            PatientCategory: rowdata?.PatientCategory,
                            RateCardType: rowdata?.RateCardType,
                            ClientName: rowdata?.ClientName,
                            InsuranceName: rowdata?.InsuranceName,
                            InsuranceNo: rowdata?.InsuranceNo,
                            ColorFlag: rowdata?.ColorFlag,
                        }));

                    } else {
                        setPatientListSearch([])
                        if (response.data === null) {
                            setFormDataOpRegister({
                                PatientID: "",
                                PhoneNo: "",
                                FamilyPhoneNo: "",
                                AppointmentId: "",
                                VisitId: "",
                                Title: "",
                                FirstName: "",
                                MiddleName: "",
                                SurName: "",
                                Gender: "",
                                DOB: "",
                                Age: "",
                                BloodGroup: "",
                                Religion: "",
                                Email: "",
                                Nationality: "",
                                UniqueIdType: "",
                                UniqueIdNo: "",
                                VisitPurpose: "",
                                Specialization: "",
                                DoctorName: "",
                                AppointmentDate: "",
                                AppointmentSlot: "",
                                RouteNo: "",
                                RouteName: "",
                                TeshilName: "",
                                VillageName: "",
                                RefferType: "",
                                RefferedBy: "",
                                Complaint: "",
                                PatientType: "General",
                                PatientCategory: "General",
                                RateCardType: "General",
                                ClientName: "",
                                InsuranceName: "",
                                InsuranceNo: "",
                                PatientPhoto: "",
                                ColorFlag: "",
                                ConsultancyFee: "",
                                GstCharge: "",
                                Status: "pending",
                                Location: userRecord?.location,
                            })
                            setPatientListSearch([])
                            userwarn("Patient ID is not avaiable! Please Register a New Patient");
                        }
                    }
                    // if (response.data === "") {
                    //   userwarn("Patient ID is not avaiable! Please enter a new Register");

                    //   // navigate('/Register-Patient2');
                    // } else {
                    //   setFormDataOpRegister((prevFormData) => ({
                    //     ...prevFormData,
                    //     VisitId: response.data[0].visitid,
                    //     Title: response.data[1].Title,
                    //     FirstName: response.data[1].firstName,
                    //     MiddleName: response.data[1].lastName,
                    //     SurName: response.data[1].lastName,
                    //     PhoneNo: response.data[1].phone,
                    //     Email: response.data[1].email,
                    //     PatientID: response.data[1].PatientID,
                    //   }));

                    //   axios
                    //     .get(
                    //       `${UrlLink}patientmanagement/getpatientpersonalbyid?patientid=${patientID}`
                    //     )
                    //     .then((response) => {
                    //       const data = response.data[0];

                    //       setFormDataOpRegister((prev) => ({
                    //         ...prev,
                    //         Gender: data?.Gender,
                    //         DOB: data?.DOB,
                    //         Age: data?.Age, // Corrected from data.Nationality to data.Age
                    //         PatientType: data?.PatientType,
                    //         PatientCategory: data?.PatientCategory,
                    //         RateCardType: data?.RateCardType,
                    //         ClientName: data?.ClientName,
                    //         InsuranceName: data?.InsuranceName,
                    //         InsuranceNo: data?.InsuranceNo,
                    //       }));
                    //     });
                    // }
                })
                .catch((error) => { });
        } else {
            userwarn("plesae fill patient id or phone number");
        }
    };

    const handleCancel = () => {
        // Reset the form data before navigating
        resetFormDataOpRegister();

        // Navigate to RegisterList
        navigate("/Home/Appoinment-RegisterList");
    };

    // ----------------------------
    const updateColumnGap = (containerWidth) => {
        const items = document.querySelectorAll(".RegisForm_1");
        let totalWidth = 0;
        let currentRowItemsCount = 0;

        items.forEach((item) => {
            const itemStyles = getComputedStyle(item);
            const itemWidth =
                item.offsetWidth +
                parseFloat(itemStyles.marginLeft) +
                parseFloat(itemStyles.marginRight);

            if (totalWidth + itemWidth <= containerWidth) {
                totalWidth += itemWidth;
                currentRowItemsCount++;
            }
        });

        const remainingGap = containerWidth - totalWidth;
        const gapBetweenItems = Math.ceil(remainingGap / currentRowItemsCount);
        const container = document.getElementById("RegisFormcon_11");
        container.style.columnGap = `${gapBetweenItems}px`;
    };

    useEffect(() => {
        const handleResize = () => {
            const container = document.getElementById("RegisFormcon_11");
            if (container) {
                const containerWidth = container.offsetWidth;
                updateColumnGap(containerWidth);
            }
        };

        // Initial call
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateColumnGap]);



    const formatLabel = (label) => {
        // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
                .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
        } else {
            return label;
        }
    };

    useEffect(() => {
        let fdata = Object.keys(FormDataOpRegister).filter((f) => !['Location', 'CreatedBy', 'PatientPhoto', 'InsuranceName', 'InsuranceNo', 'ClientName', 'Status', 'FamilyHeadName'].includes(f))
        if (FormDataOpRegister.PatientCategory === 'Insurance') {
            const categoryIndex = fdata.indexOf('PatientCategory');
            fdata.splice(categoryIndex + 1, 0, 'InsuranceName', 'InsuranceNo');
        }
        if (FormDataOpRegister.PatientCategory === 'Client') {
            const categoryIndex = fdata.indexOf('PatientCategory');
            fdata.splice(categoryIndex + 1, 0, 'ClientName');
        }
        if (FormDataOpRegister.FamilyHead === 'No') {
            const categoryIndex = fdata.indexOf('FamilyHead');
            fdata.splice(categoryIndex + 1, 0, 'FamilyHeadName');
        }
        setFilteredFormdata(fdata)
    }, [FormDataOpRegister.PatientCategory, FormDataOpRegister.FamilyHead])



    return (
        <>




            <div className="RegisFormcon" id="RegisFormcon_11">
                {FilteredFormdata.map((fieldName, index) => (
                    <div
                        className={
                            ["PatientID", "PhoneNo", "FamilyPhoneNo"].includes(fieldName)
                                ? "RegisForm_1 input-with-icon"
                                : "RegisForm_1"
                        }
                        key={index}
                    >
                        <label htmlFor={fieldName}>
                            {formatLabel(fieldName)}

                            <span>:</span>
                        </label>
                        {fieldName === "Title" ||
                            fieldName === "Gender" ||
                            fieldName === "Nationality" ||
                            fieldName === "UniqueIdType" ||
                            fieldName === "VisitPurpose" ||
                            fieldName === "PatientType" ||
                            fieldName === "PatientCategory" ||
                            fieldName === "RefferType" ||
                            fieldName === "RateCardType" ||
                            fieldName === "ColorFlag" ||
                            fieldName === "DoctorName" ||
                            fieldName === "Religion" ||
                            fieldName === "BloodGroup" ||
                            fieldName === "Specialization" ? (
                            fieldName === "ColorFlag" ? (
                                <div className="select-container_ioy">
                                    <select
                                        id={fieldName}
                                        name={fieldName}
                                        value={FormDataOpRegister[fieldName]}
                                        onChange={handleFormChangeOp}
                                        // readOnly={datafromrequest}
                                        required={

                                            fieldName === "ColorFlag"
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="gold">VIP</option>
                                        <option value="purple">Client</option>
                                        <option value="blue">Insurance</option>
                                        <option value="grey">MLC</option>
                                        <option value="green">Donations</option>
                                        <option value="cyan">Discount</option>
                                        {FormDataOpRegister.VisitId > 5 ? (
                                            <>
                                                <option value="orange">Loyal Patient</option>
                                                <option value="pink">Loyal Family</option>
                                            </>
                                        ) : null}

                                        <option value="red">Ruckus-behavioral infraction</option>
                                    </select>
                                    {FormDataOpRegister.ColorFlag && (
                                        <span
                                            className="square-flag"
                                            style={{ backgroundColor: FormDataOpRegister.ColorFlag }}
                                        ></span>
                                    )}
                                </div>
                            ) : (
                                <select
                                    id={fieldName}
                                    name={fieldName}
                                    value={FormDataOpRegister[fieldName]}
                                    onChange={handleFormChangeOp}
                                    // readOnly={fieldName === 'Title'}
                                    required={
                                        fieldName === "Title" ||
                                        fieldName === "Gender" ||
                                        fieldName === "Nationality" ||
                                        fieldName === "UniqueIdType" ||
                                        fieldName === "VisitPurpose" ||
                                        fieldName === "PatientType" ||
                                        fieldName === "PatientCategory" ||
                                        fieldName === "RefferType" ||
                                        fieldName === "RateCardType" ||
                                        fieldName === "DoctorName" ||
                                        fieldName === "Religion" ||
                                        fieldName === "BloodGroup" ||
                                        fieldName === "Specialization"
                                    }
                                >
                                    <option value="">Select</option>
                                    {fieldName === "Nationality" && (
                                        <>
                                            <option value="Indian">Indian</option>
                                            <option value="International">International</option>
                                        </>
                                    )}
                                    {fieldName === "Religion" && (
                                        ReligionData.map((p, indx) => (
                                            <option key={indx} value={p.religion}>{p.religion}</option>
                                        ))
                                    )}
                                    {fieldName === "BloodGroup" && (
                                        <>

                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                        </>
                                    )}
                                    {fieldName === "Title" && (
                                        <>
                                            <option value="Mr">Mr.</option>
                                            <option value="Ms">Ms.</option>
                                            <option value="Mrs">Mrs.</option>
                                            <option value="Others">Others.</option>
                                        </>
                                    )}
                                    {fieldName === "Gender" && (
                                        <>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="TransGender">TransGender</option>
                                        </>
                                    )}
                                    {fieldName === "UniqueIdType" &&
                                        FormDataOpRegister.Nationality === "Indian" && (
                                            <>
                                                <option value="Aadhar">Aadhar</option>
                                                <option value="VoterId">Voter Id</option>
                                                <option value="SmartCard">SmartCard</option>
                                            </>
                                        )}
                                    {fieldName === "UniqueIdType" &&
                                        FormDataOpRegister.Nationality === "International" && (
                                            <>
                                                <option value="DrivingLicence">Driving Licence</option>
                                                <option value="Passport">Passport</option>
                                            </>
                                        )}

                                    {fieldName === "VisitPurpose" && (
                                        <>
                                            <option value="NewConsultation">New Consultation</option>
                                            <option value="FollowUp">Follow Up</option>
                                        </>
                                    )}
                                    {fieldName === "PatientType" && (
                                        <>
                                            <option value="General">General</option>
                                            <option value="VIP">VIP</option>
                                        </>
                                    )}
                                    {fieldName === "PatientCategory" && (
                                        <>
                                            <option value="General">General</option>
                                            <option value="Client">Client</option>
                                            <option value="Insurance">Insurance</option>
                                        </>
                                    )}
                                    {fieldName === "RateCardType" && (
                                        <>
                                            <option value="General">General</option>
                                            <option value="Special">Special</option>
                                            <option value="VIP">VIP</option>
                                            <option value="Client">Client</option>
                                            <option value="Insurance">Insurance</option>
                                        </>
                                    )}
                                    {fieldName === "Specialization" && (
                                        <>
                                            {SpecialitiesData.map((p, indx) => (
                                                <option value={p.Specialities_name} key={indx}>{p.Specialities_name}</option>
                                            ))
                                            }
                                        </>
                                    )}
                                    {fieldName === "DoctorName" && (
                                        <>
                                            {doctorNames.map((p, indx) => (
                                                <option value={p.EmployeeName} key={indx}>{p.EmployeeName}</option>
                                            ))
                                            }
                                        </>
                                    )}

                                    {fieldName === "RefferType" && (
                                        ReferType.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}

                                </select>
                            )
                        ) : (
                            <>
                                {["PatientID", "PhoneNo", "FamilyPhoneNo", "RouteNo", "RouteName", "TeshilName", "VillageName", "RefferedBy", 'ClientName', 'InsuranceName','RelativePhoneNo'].includes(fieldName) && (
                                    fieldName === 'PhoneNo' || fieldName === 'FamilyPhoneNo' ||fieldName==='RelativePhoneNo'? (
                                        <>
                                            <input
                                                type="number"
                                                id={fieldName}
                                                list={`${fieldName}_iddd`}
                                                name={fieldName}
                                                onKeyDown={blockInvalidChar}
                                                value={FormDataOpRegister[fieldName]}
                                                // readOnly={datafromrequest}
                                                onChange={handleFormChangeOp}
                                                required={["PhoneNo", "FamilyPhoneNo"].includes(fieldName)
                                                }
                                            />
                                        </>
                                    ) : (
                                        <input
                                            type="text"
                                            id={fieldName}
                                            list={`${fieldName}_iddd`}
                                            name={fieldName}
                                            value={FormDataOpRegister[fieldName]}
                                            onChange={handleFormChangeOp}
                                            required={["RouteNo", "RouteName", "TeshilName", "VillageName", "RefferedBy", 'ClientName', 'InsuranceName'].includes(fieldName)
                                            }
                                        />
                                    )


                                )}

                                <datalist id={`${fieldName}_iddd`}>
                                    <option value="">Select</option>

                                    {fieldName === "RouteNo" && (
                                        RouteNo.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}
                                    {fieldName === "RouteName" && (
                                        RouteName.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}
                                    {fieldName === "TeshilName" && (
                                        ThasilName.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}
                                    {fieldName === "VillageName" && (
                                        VillageName.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}
                                    {fieldName === "RefferedBy" && (
                                        ReferName.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        )))}
                                    {fieldName === "ClientName" && (
                                        ClientName.map((name, index) => (
                                            <option key={index} value={name.Name}>
                                                {`${name.Code}`}
                                            </option>
                                        )))}
                                    {fieldName === "InsuranceName" && (
                                        InsuranceName.map((name, index) => (
                                            <option key={index} value={name.Name}>
                                                {`${name.Code}`}
                                            </option>
                                        )))}
                                    {fieldName === "PatientID" && (
                                        PatientListSearch.map((name, index) => (
                                            <option key={index} value={name.PatientID}>
                                                {`PhoneNo :${name.PhoneNo}`}<br />
                                                {`PatientName: ${name.FirstName} ${name.MiddleName} ${name.SurName}`}
                                            </option>
                                        )))}
                                    {fieldName === "PhoneNo" && (
                                        PatientListSearch.map((name, index) => (
                                            <option key={index} value={name.PhoneNo}>
                                                {`PatientID :${name.PatientID}`}
                                                {`PatientName :${name.FirstName} ${name.MiddleName} ${name.SurName}`}
                                            </option>
                                        )))}
                                </datalist>


                                {fieldName !== "RouteNo" &&
                                    fieldName !== "RouteName" &&
                                    fieldName !== "RefferedBy" &&
                                    fieldName !== "TeshilName" &&
                                    fieldName !== "VillageName" &&
                                    fieldName !== "ClientName" &&
                                    fieldName !== "InsuranceName" &&
                                    fieldName !== "PatientID" &&
                                    fieldName !== "PhoneNo" &&
                                    fieldName !== "FamilyPhoneNo" &&
                                    fieldName !== "RelativePhoneNo" &&
                                    (fieldName === "Complaint" ||fieldName==='RelativeAdderess'? (
                                        <textarea
                                            id="Complaint"
                                            name={fieldName}
                                            value={FormDataOpRegister[fieldName]}
                                            cols="25"
                                            rows="3"
                                            onChange={handleFormChangeOp}
                                        />
                                    ) : 
                                    fieldName === 'FamilyHead' ?
                                        (<div style={{ display: 'flex', justifyContent: 'space-between', width: '150px' }}>
                                            <label style={{ width: '60px' }}>
                                                <input
                                                    id='MLCYes'
                                                    type="radio"
                                                    name="FamilyHead"
                                                    value="Yes"
                                                    checked={FormDataOpRegister.FamilyHead === 'Yes'}
                                                    onChange={(e) => setFormDataOpRegister(prevState => ({
                                                        ...prevState,
                                                        FamilyHead: 'Yes'
                                                    }))}
                                                />
                                                Yes
                                            </label>
                                            <label style={{ width: '60px' }}>
                                                <input
                                                    id='MLCNo'
                                                    type="radio"
                                                    name="FamilyHead"
                                                    value="No"

                                                    checked={FormDataOpRegister.FamilyHead === 'No'}
                                                    onChange={(e) => setFormDataOpRegister(prevState => ({
                                                        ...prevState,
                                                        FamilyHead: 'No'
                                                    }))}
                                                />
                                                No
                                            </label>
                                        </div>) :
                                        fieldName==='Pincode'?
                                        (
                                            <input
                                                type="number"
                                                id={fieldName}
                                                name={fieldName}
                                                onKeyDown={blockInvalidChar}
                                                value={FormDataOpRegister[fieldName]}
                                                onChange={handleFormChangeOp}
                                                required={["PhoneNo", "FamilyPhoneNo"].includes(fieldName)
                                                }
                                            />
                                        ): (
                                            <input
                                                type={
                                                    fieldName === "DOB" || fieldName === "AppointmentDate"
                                                        ? "date"
                                                        : "text"
                                                }
                                                id={fieldName}
                                                name={fieldName}
                                                value={FormDataOpRegister[fieldName]}
                                                readOnly={["FirstName", "MiddleName", "SurName", "DoctorName", "Email"].includes(fieldName) ? datafromrequest : ["VisitId", "Status", "ConsultancyFee", "GstCharge", "AppointmentId", 'AppointmentSlot'].includes(fieldName) && true}
                                                onChange={handleFormChangeOp}
                                                required={
                                                    fieldName === "DOB" || fieldName === "AppointmentDate"
                                                }
                                            />
                                        ))}

                            </>
                        )}

                        {["PatientID", "PhoneNo", "FamilyPhoneNo"].includes(fieldName) && (
                            <button className="searching_input_icon3" onClick={handleSearch}>
                                <SearchIcon />
                            </button>
                        )}
                    </div>
                ))}
            </div>

            <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleFormSubmit}>
                    Register
                </button>
                <button className="RegisterForm_1_btns" onClick={handleCancel}>
                    Cancel
                </button>
            </div>

            <ToastContainer />

        </>
    );
};

export default OpRegistertration;
