import React, { useState } from 'react';
// import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { alignProperty } from '@mui/material/styles/cssUtils';
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from 'react-redux';

function Specimen() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const[specimenData,setSpecimenData]=useState([])
  const [specimen,setSpecimen] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [specimenCode,setSpecimenCode] = useState('');
  const [selectedid, setSelectedid ] = useState(null);
  const handleSubmitSpecimen = async () => {
    if (!specimen.trim() || !specimenCode.trim()) {
      alert('Both specimen Code Name and specimen are required.');
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
       const response = await axios.post(`${urllink}mainddepartment/insertspecimen`, {
        specimen,
        specimenCode,
      });
  
      // Handle the response as needed
      console.log(response.data);
      setSpecimen('');
      setSpecimenCode('')
      // Optionally, you can reset the state after a successful submission
     
      fetchSpecimenData();
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error scenarios
    }
  };
  React.useEffect(() => {
    fetchSpecimenData();
   
    
   
  }, []);
  const fetchSpecimenData = () => {
    axios.get(`${urllink}mainddepartment/getspecimen`)
      .then((response) => {
        const data = response.data;
        console.log("data",data)
  
        setSpecimenData(data)
      })
      .catch((error) => {
        console.error('Error fetching unit data:', error);
      });
  };
  const handleEdit = (row) => {
    setSpecimen(row.specimen_name);
    setSpecimenCode(row.specimen_code)
    setIsEditMode(true);
    setSelectedid(row.specimen_id)// Assuming `method_id` is the identifier
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(`${urllink}mainddepartment/updatespecimen`, {
        method_id: selectedid,
        method_name: specimen,
        method_code: specimenCode,
      });
      console.log(response.data);
      setSpecimen('');
    setSpecimenCode('')
    setIsEditMode(false);
    setSelectedid('')
    fetchSpecimenData()
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };



  

  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Specimen Management</h3>
          </div>
        </div>
        <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Specimen</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Specimen Name :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={specimen}
                    onChange={(e) => setSpecimen(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Specimen Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={specimenCode}
                    onChange={(e) => setSpecimenCode(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitSpecimen}>
  {isEditMode ? 'Update' : <AddIcon />}
</button>

         
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Specimen Name</th>
                        <th >Specimen Code</th>
                        <th>Edit</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                    {specimenData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.specimen_id}</td>
                          <td>{row.specimen_name}</td>
                          <td>

                            {row.specimen_code}
                          </td>
                          <td>
                             <button onClick={() => handleEdit(row)}><EditIcon/></button>
                          </td>

                        </tr>
                      ))}
                     
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div>
        {/* <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Container</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Container Name :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={container}
                    onChange={(e) => setContainer(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Container Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={containerCode}
                    onChange={(e) => setContainerCode(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" >
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Container Name</th>
                        <th >Container Code</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                     
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div> */}
      </div>
    </>
  );
}

export default Specimen;
