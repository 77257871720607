import React, { useState, useEffect } from "react";
import "./GroupMaster.css";
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#54d854bf",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  #54d854bf !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },  
  });
  
function GroupMasterList(){
    const urllink=useSelector(state=>state.userRecord?.UrlLink)

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryCode, setSearchQueryCode] = useState('');
    const [page, setPage] = useState(0);
    const [value, setValue] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedrow,setSelectedrow] =useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const pageSize = 10;
     const dispatchvalue=useDispatch();
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const showdown = filteredRows.length;
  
    const handleSearchChangeCode = (event) => {
        setSearchQueryCode(event.target.value);
    };
    useEffect(() => {
        axios.get(`${urllink}usercontrol/getgroupmasterdata`)
            .then((response) => {
                const data = response.data.map((row) => ({
                    id: row.group_master_id,
                    ...row,
                }));
                setValue(data);
            })
            .catch((error) => {
                console.error('Error fetching test description data:', error);
            });
    }, []);
    const dynamicColumns = [
        { field: 'group_master_id', headerName: 'S.No', width: 140 },
        { field: 'group_code', headerName: 'Group Code', width: 200 },
        { field: 'group_name', headerName: 'Group Name', width: 150 },
        { field: 'display_name', headerName: 'Display Name', width: 150 },
        { field: 'billing_code', headerName: 'Billing Code', width: 150 },
        { field: 'billing_name', headerName: 'Billing Name', width: 150 },
        { field: 'gender', headerName: 'Gender', width: 150 },
        { field: 'report', headerName: 'Report Type', width: 150 },
        {field:'department_name',headerName:'Department Name',width:150},
        { field: 'test_category', headerName: 'Test Category', width: 150 },
        { field: 'logical_category', headerName: 'Logical Category', width: 150 },
        { field: 'authorized_user', headerName: 'Auto Authorized User', width: 150 },
        { field: 'lonic_code', headerName: 'Lonic Code', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={()=>handleEditClick(params)}>
                
        <EditIcon />

                    </Button>
                </>
            ),
        },
    ];
    const handleEditClick = (params) => {
        console.log(params);
        
        setSelectedrow((updatedRow) => {
            // Set isEditMode to true and include the original row
            const updatedRowWithEditMode = { ...params.row, isEditMode: true };
            
            // Log the updatedRow
            console.log(updatedRowWithEditMode);
    
            // Dispatch the updatedRow
            dispatchvalue({ type: 'GroupMaster', value: updatedRowWithEditMode });
            console.log("dispatch",dispatchvalue)
    
            // Navigate to the Test Master component
            navigate('/GroupMaster');
    
            // Return the updatedRow
            return updatedRowWithEditMode;
        });
    };
  
      

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = value.filter((row) => {  
            const lowerCaseSupplierName = row.group_name ? row.group_name.toLowerCase() : '';
            return lowerCaseSupplierName.includes(lowerCaseQuery);
        });
        setFilteredRows(filteredData);
    }, [searchQuery, value]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    return(
        <>
         <div className="appointment">
         <div className="h_head">
                    <h3>GroupMaster List</h3>
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Group Name :</label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the GroupName' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Group Code :</label>
                        <input type="text" value={searchQueryCode} onChange={handleSearchChangeCode} placeholder='Enter the GroupCode' />
                    </div>
                    <button className='btn_1' type='submit'>
                        <SearchIcon />
                    </button>
                </div>

                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>

         </div>

        </>
    );
}
export default GroupMasterList;