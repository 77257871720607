import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../src/App.css';
import LoginPage from './LoginPage/LoginPage';
import HomePage from './HomePage/HomePage';
import RoleManagement from './UserControls/RoleManagement';
import UserRegister from './UserControls/UserRegister';
import UserList from './UserControls/UserList';
import PatientQueueList from './TreatmentRecords/PatientQueueList';
import RequestList from './AppointmentManagement/RequestList';
import RegisterList from './AppointmentManagement/RegisterList';
import Register from './AppointmentManagement/Register';
import Profile from './Profile/Profile';
import PasswordChange from './PasswordChange/PasswordChange';
import AccountSettings from './UserControls/Account Settings';
import ClinicDetails from './UserControls/ClinicDetails';
import Navigation from './TreatmentRecords/Navigation';
import BillingHistory from './Billing/BillingHistory';
import ClinicMetrics from './ClinicMetrics/ClinicMetrics';
import PatientProfile from './PatientManagement/PatientProfile';
import AppoinmentCalendar from './AppointmentManagement/AppointmentCalendar';
import TreatmentPro from './UserControls/TreatmentPro';
import Preview from './TreatmentRecords/Preview';
import SlotManagement from './UserControls/SlotManagement';
import PatientList from './TreatmentRecords/PatientList';
import PatientQueueList2 from './Therophist/PatientQueueList2';
import Navigation2 from './Therophist/Navigation2';
import Vital2 from './Therophist/Vital2';
import RegistrationForm from './RegistrationForm/Registration';
import PatientList2 from './Therophist/PatientList2';
import NewProcedure from './Therophist/NewProcedure';
import CounselorQueueList from './Counselor/CounselorQueueList';
import CounselorProcedure from './Counselor/CounselorProcedure';
import CounselorFullList from './Counselor/CounselorFullList';
import ExpensesMaster from './Pettycash/ExpensesMaster';
import CashExpenses from './Pettycash/CashExpenses';
import CashExpenseReport from './Pettycash/CashExpenseReport';
import HandOverSummary from './Pettycash/HandOverSummary';
import DayBookReport from './Pettycash/DayBookReport';
import DayClosing from './Pettycash/DayClosing';
import DueHistoryList from './Billing/Duehistory/DueHistoryList';
import GeneralBillingQueList from './Billing/OverallBilling/GeneralBillingQueList';
import GeneralBilling from './Billing/OverallBilling/GeneralBilling';
import BillCancelation from './Billing/BillCancelation';
import Concern from './AppointmentManagement/ConcernForms/Concern';
import Concernlist from './AppointmentManagement/ConcernForms/Concernlist';
import RateCardMaster from './UserControls/RateCardMaster/RateCardMaster';
import LabQueueList from './AppointmentManagement/LabQueueList';
import LabReportFN from './AppointmentManagement/LabReportFN';
import PatientQueueList3 from './Nurse/PatientQueueList3';
import Navigation3 from './Nurse/Navigation3';
import OverallDayreport from './Pettycash/OverallDayreport';
import Walkinbilling from './Pharmacy/Walkinbilling';
import PharmacyBillingLIst from './Pharmacy/PharmacyBillingLIst';
import PharmacyBilling from './Pharmacy/PharmacyBilling';
import DeuHistory from './Billing/Duehistory/DeuHistory';
import PharmacyBillcancelation from './Pharmacy/Pharmacy_Billcancelation';
import EmployeeRegister from './HR Management/EmployeeRegister';
import EmployeeList from './HR Management/EmployeeList';
import EmployeeProfile from './HR Management/EmployeeProfile';
import EmployeeAttendance from './HR Management/Attendance';
import EmployeeCalendar from './HR Management/EmployeeCalendar';
import EmployeeQueList from './UserControls/EmployeeQueList';
import VisitingDoctorBilling from './UserControls/VisitDoctorBilling';
import LeaveMangement from './HR Management/LeaveMangement';
import PayRoll from './HR Management/PayRoll';
import PaySlip from './HR Management/PaySlip';
import VisitingDoctor from './VisitingDoctor/VisitingDoctor';
import PatientVisitingDoctor from './VisitingDoctor/PatientVisitingDoctor';
import PerformanceAppraisal from './HR Management/Performance';
import PerformanceManagement from './HR Management/PerformanceManagement';
import AddvaceApproval from './HR Management/AdvanceApproval';
import AddvaceManagement from './HR Management/AdvanceManagement';
import LeaveNavigation from './EmployeeRequest/LeaveNavigation';
import AdvanceNavigation from './EmployeeRequest/AdvanceNavigation';
import LeaveApproval from './HR Management/LeaveApproval';
import SupplierStock from './Stock/SupplierPay/SupplierStock';
import SupplierDetails from './Stock/SupplierPay/SupplierDetails';
import SupplierList from './Stock/SupplierMaster/SupplierList';
import SupplierMaster from './Stock/SupplierMaster/SupplierMaster';
import ProductList from './Stock/ProductMaster/ProductList';
import ProductMaster from './Stock/ProductMaster/ProductMaster';
import PurchaseRaiseList from './Stock/PurchaseRaiseMaster/PurchaseRaiseList';
import PurchaseRaiseMaster from './Stock/PurchaseRaiseMaster/PurchaseRaiseMaster';
import PurchaseRecieceList from './Stock/PurchaseRecieveMaster/PurchaseRecieveList';
import PurchaseRecieveMaster from './Stock/PurchaseRecieveMaster/PurchaseRecieveMaster';
import IndentRaiseMaster from './Stock/IndentRaise/IndentRaiseMaster';
import IndentRaiseList from './Stock/IndentRaise/IndentRaiseList';
import IndentRecieveList from './Stock/IndentRecieve/IndentRecieveList';
import IndentRecieveMaster from './Stock/IndentRecieve/IndentRecieveMaster';
import IndentIssueMaster from './Stock/IndentIssue/IndentIssueMaster';
import IndentIssueList from './Stock/IndentIssue/IndentIssueList';
import IndentApprove from './Stock/ApprovePages/IndentApprove';
import IndenIssueApprove from './Stock/ApprovePages/IndenIssueApprove';
import IndentReturnList from './Stock/IndentReturn/IndentReturnList';
import IndentReturnMaster from './Stock/IndentReturn/IndentReturnMaster';
import PurchaseApprove from './Stock/ApprovePages/PurchaseApprove';
import GrnApprove from './Stock/ApprovePages/GrnApprove';
import PurchaseReturnMaster from './Stock/PurchaseReturn/PurchaseReturnMaster';
import CentralStockList from './Stock/CentralStock/CentralStockList';
import LocationStock from './Stock/CentralStock/LocationStock';
import QuickStockListLoc from './Stock/QuickStockRecieve/QuickStockListLoc';
import QuickStockMasterLoc from './Stock/QuickStockRecieve/QuickStockMasterLoc';
import IndentRecieveApprove from './Stock/ApprovePages/IndentRecieveApprove';
// import Accounts from './Accounts/Accounts';
import RequestToHR from './HR Management/RequestToHR';
import RequestToHrList from './HR Management/RequestToHrList';
import ConsultancyTypeUpList from './HR Management/ConsultancyTypeUpList';
import ConsultantcyMaster from './HR Management/ConsultantcyMaster';
import MailToConsultancy from './HR Management/MailToConsultancy';
import MailFromConsultancy from './HR Management/MailFromConsultancy';
import ShortlistedResumelist from './HR Management/ShortlistedResumelist';

// import PatientView from './patientView';
import Patientview from './PatientView'
import NotFound from './404Page';
import PrivacyPolicy from './Footer/PrivacyPolicy';
import TermsOfUse from './Footer/TermsOfUse';
import { useDispatch, useSelector } from 'react-redux';
import PatientListupdate from './AppointmentManagement/patientlistupdate';
import Expenses from './Pettycash/Expenses';
import DigitalExpenses from './Pettycash/DigitalExpense';
import QuickStockMasterCentral from './Stock/QuickStockRecieve/QuickStockMasterCentral';
import SuplierPaidlist from './Stock/SupplierPay/SuplierPaidlist';
import IndentMovementmaster from './Stock/IndentMovement/IndentMovementmaster';
import IndentMovementlist from './Stock/IndentMovement/IndentMovementlist';
import IndentReturnApprove from './Stock/ApprovePages/IndentReturnApprove';
import PurchaseReturnLocMaster from './Stock/PurchaseReturn/PurchaseReturnLocMaster';


import PurchaseRegister from './Finance/PurchaseRegister';
import AccountGroupMaster from './Finance/AccountGroupMaster';
import SalesRegister from './Finance/SalesRegister';
import Journalentry from './Finance/Journalentry';
import JournalentryList from './Finance/JournalentryList';
import LedgerList from './Finance/LedgerList';
import BalanceSheet from  './Finance/BalanceSheet'
import AccountGroupList from  './Finance/AccountGroupList'
import Accounts from './Finance/Accounts'
import ContraVoucher from './Finance/ContraVoucher'
import ContraVoucherList from './Finance/ContraVoucherList';

import PaymentVoucherList from './Finance/PaymentVoucherList';
import ReceiptVoucher from './Finance/ReceiptVoucher'
import ReceiptVoucherList from './Finance/ReceiptVoucherList'
import DayBook from './Finance/DayBook'
import TrialBalance from './Finance/TrialBalance'
import ProfitandLoss from './Finance/ProfitandLoss'
import CashbookandBankbook from './Finance/CashbookandBankbook'
// import PharmachyMasterList from './HR Management/Stock/PharmachyMaster/PharmachyMasterList';
// import PharmachyMaster from './HR Management/Stock/PharmachyMaster/PharmachyMaster';
// import GeneralMaster from './HR Management/Stock/GeneralMaster/GeneralMaster';
// import GeneralMasterList from './HR Management/Stock/GeneralMaster/GeneralMasterList';
import HSNReport from './Finance/HSNReport';
import DueReport from './Finance/DueReport';


import PatientView from './PatientView';
import VisitingDoctorBillingList from './UserControls/VisitDoctorBillingList';
import Followuplist from './AppointmentManagement/Followuplist';
import DutyManagement from './HR Management/DutyManagement';
import PharmachyMasterList from './Stock/PharmachyMaster/PharmachyMasterList';
import PharmachyMaster from './Stock/PharmachyMaster/PharmachyMaster';
import GeneralMasterList from './Stock/GeneralMaster/GeneralMasterList';
import GeneralMaster from './Stock/GeneralMaster/GeneralMaster';
import PaymentVoucher from './Finance/PaymentVoucher';


import RoomMaster from './IpManagement/RoomCreate/RoomMaster';
import RoomMasterCreate from './IpManagement/RoomCreate/RoomMasterCreate';
import NurseQueList from './IpManagement/IPNurseflow/NurseQueList';
import IpNurseWorkbench from './IpManagement/IPNurseflow/IpNurseWorkbench';
import IpDoctorQueList from './IpManagement/IpDoctorflow/IpDoctorQueList';
import IpDoctorWorkbench from './IpManagement/IpDoctorflow/IpDoctorWorkbench';
import IpRegistrationlist from './IpManagement/IPRegistration/IpRegistrationlist';
import RoomsAvail from './IpManagement/RoomCreate/RoomsAvail';
import TheatreBooking from './IpManagement/Operationtheatre/TheatreBooking';
import IPrateCardMaster from './UserControls/Ipratecard';
import ShiftDetails from './EmployeeRequest/ShiftDetails';
import FrequencyMaster from './IpManagement/Frequencymaster/FrequencyMaster';
import Referaldoctor from './UserControls/Referaldoctor';



import CommonRegistration from './IpManagement/IPRegistration/CommonRegistration'


import Insurance from "./Insurance/Insurance";
import InsuranceDashboard from "./Insurance/InsuranceDashboard";
import Claims from "./Insurance/Claims/Claims";
import ClaimsDashboard from "./Insurance/Claims/ClaimsDashboard";
import Reconcilition from "./Insurance/Claims/Reconcilition";
import InsuranceMainpage from "./Insurance/PreAuth/InsuranceMainpage";
import InsuranceMaster from "./Insurance/InsuranceMaster";
import InsuranceMasterList from "./Insurance/InsuranceMasterList";



import TreatmentMaster from "./UserControls/TreatmentMaster/TreatmentMaster";
import RateCardList from "./UserControls/RateCardMaster/RateCardList";
import IpBillingList from './IpManagement/IpBilling/IpBillingList';
import IpBilling from './IpManagement/IpBilling/IpBilling';

import IcuNurseWorkbench from './ICU Management/ICUNurseflow/IcuNurseWorkbench';
import IcuDoctorWorkbench from './ICU Management/ICUDoctorflow/IcuDoctorWorkbench';
import IcuSurgicalHistory from './ICU Management/ICUDoctorflow/IcuSurgicalHistory';
import ConsentFormCreate from './ICU Management/ICUDoctorflow/ConsentFormCreate';
import IcuTreatment from './ICU Management/ICUDoctorflow/IcuTreatment';
import News from './ICU Management/ICUNurseflow/News';
import EmergencyNurseWorkbench from './Emergency/EmergencyNurseflow/EmergencyNurseWorkbench';
import EmergencyPatientHemodynamicsMaster from './Emergency/EmergencyNurseflow/EmergencyPatientHemodynamicsMaster';
import EmergencyDocWorkbench from './Emergency/EmergencyDoctorflow/EmergencyDocWorkbench';
import EmergencyNurseVitals from './Emergency/EmergencyNurseflow/EmergencyNurseVitals';
import JobRequirement from './HR Management/JobRequiremet';
import HrDashboard from './HR Management/HrDashboard';
import OTSurgeryMaster from './UserControls/OTSurgeryMaster';

import OtDoctorPre from './OtManagement/OtDoctor/OtDoctorPre';
import OtDoctorQueueList from './OtManagement/OtDoctor/OtDoctorQueueList';
import OtNurseQueList from './OtManagement/OtNurse/OtNurseQueList';
import OtNurseeNavigation from './OtManagement/OtNurse/OtNurseeNavigation';
import OTAanaesthesiaCapture from './OtManagement/OTAnaesthesia/OTAnaesthesiaCapture';
import OtAnaesthesiaQueueList from './OtManagement/OTAnaesthesia/OtAnaesthesiaQueueList';
import OtAnaesthesiaPre from './OtManagement/OTAnaesthesia/OtAnaesthesiaPre';
import OtRequest from './OtManagement/OtRequest';
import OtQuelist from './OtManagement/OtQuelist';
import OtConsentForm from './OtManagement/OtConsentForm';
import OtDoctorNavigation from './OtManagement/OtDoctor/OtDoctorNavigation';
import OtNursePre from './OtManagement/OtNurse/OtNursePre';
import OtAnaesthesiaNavigation from './OtManagement/OTAnaesthesia/OtAnaesthesiaNavigation';



// lims


import GroupMaster from './Masters/GroupMaster';
import Units from './Masters/Unit';
import Container from './Masters/Container';
import Specimen from './Masters/Specimen';
import Methods from './Masters/Methods';
import PatientGroupName from './Masters/PatientGroupName';
import OrganismMaster from './Masters/OrganismMaster';
import GroupMasterList from './Masters/GroupMasterList';
import ExternalLab from './Masters/ExternalLab';
import ExternalLabList from './Masters/ExternalLabList';
import Equipments from './Masters/Equipments';
import Department from './Masters/Department';
import AssetType from './Masters/AssetType';
import AntibioticGroup from './Masters/AntibioticGroup';
import AntibioticMaster from './Masters/AntibioticMaster';
import TestMasterNavigation from './Masters/TestMasterNavigation';
import Billing from './Receptionist/Billing';
import PatientData from './Receptionist/PatientData';
import PatientListforLab from './Receptionist/PatientList';
import PatientRequestforLab from './Receptionist/PatientRequest';
import MasterList from './Masters/MasterList';
import PaymentHistory from './Receptionist/PaymentHistory';
import Navigationple from './Phelobotomist/Navigationple';
import SampleList from './Phelobotomist/SampleList';
import TransactionHistory from './Phelobotomist/TransactionHistory';
import CaptureSample from './Phelobotomist/CaptureSample';
import Analyzereport from './LabTechnician/AnalyzeReportEntry';
import NavigationLab from './LabTechnician/NavigationLab';
import ValidatedQueue from './LabTechnician/ValidatedQueue';
import Verifyreport from './Sr.LabTechnician/VerifyLabEntry';
import Navigationseior from './Sr.LabTechnician/Navigationsenior';
import Approvereport from './Pathologist/Authorize-Approval';
import Navigationpath from './Pathologist/Navigationpath';
import PathValidatedQueue from './Pathologist/PathValidatedQueue';
import SrValidatedQueue from './Sr.LabTechnician/SrValidatedQueue';
import DispatchList from './Dispatch/DispatchTransactionHistory';
import Reports from './Report/Reports';
import IpRegisterEdit from './IpManagement/IPRegistration/IpRegisterEdit';

import IpPharmacy from './Pharmacy/IpPharmacy';
import IpPharmacyBilling from './Pharmacy/IpPharmacyBilling';




const App = () => {


  const [userRecord, setUserRecord] = useState(null);

  // const [isSidebarOpen, setSidebarOpen] = useState(false);
  // // const [isModalOpen, setModalOpen] = useState(false);
  // const [toggled, setToggled] = useState(false);
  // const [selectedRequestList, setselectedRequestList] = useState([]);
  // const [SelectedMedicine, setSelectedMedicine] = useState('');
  // const [forpatientdata, setforPatientData] = useState([]);
  // const [Selected_Patient_Pharmacy, setSelected_Patient_Pharmacy] = useState([])
  // const [labformdata, setlabformdata] = useState([]);
  // const [foremployeedata, setforemployeedata] = useState([]);
  // const [foruserregisteremployeedata, setforuserregisteremployeedata] = useState([]);
  // const [Data, setData] = useState([])
  // const [selectedRowData, setSelectedRowData] = useState(null);
  // const [foremployeeedit, setforemployeeedit] = useState([]);
  // const [employeedata, setemployeedata] = useState([]);
  // const [forpatienteedit, setforpatienteedit] = useState([])
  // const [foredituserregisteremployeedata,setforedituserregisteremployeedata]=useState([])
  // const [employeeIdGet , setEmployeeIdGet] =useState ([]);

  // //for calender
  // const [SelectedPatientCalender, setSelectedPatientCalender] = useState('')
  // const [registerdata, setregisterdata] = useState([]);
  // //  for stock
  // const [Supplierpay, setSupplierpay] = useState(null);
  // const [selectedSupplierIndex, setSelectedSupplierIndex] = useState(null);
  // const [selectedProductIndex, setSelectedProductIndex] = useState({});
  // const [PurchaseInvoiceGRN, setPurchaseInvoiceGRN] = useState({});
  // const [IndentIssueListData, setIndentIssueListData] = useState(null);
  // const [IndentRecieveListData, setIndentRecieveListData] = useState(null);
  // const [ExpiryProductsData, setExpiryProductsData] = useState(null);
  // const [GRNedit, setGRNedit] = useState({});
  // const [IndentReturnListData, setIndentReturnListData] = useState({})


  const navigate = useNavigate();
  const location = useLocation();

  const dispatchvalue = useDispatch();

  const  isModalOpen = useSelector(state=>state.userRecord?.isModalOpen )

  const getuserrecord = useCallback(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(storedToken);
      setUserRecord(decodedTokenData)
      dispatchvalue({ type: 'UserData', value: decodedTokenData })
    } else {
      if (location.pathname !== '/') {
        navigate('/')
      }
    }
  }, [navigate, location.pathname])



  useEffect(() => {
    const loc = location.pathname
    if (loc === '/') {
      localStorage.clear()
    }
    getuserrecord();
  }, [location, location.pathname, navigate, getuserrecord])

  


  useEffect(() => {
    const sessionTimeout = 30 * 60 * 1000; // 3 minutes in milliseconds
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        navigate('/');
      }, sessionTimeout);
    };

    // Add event listeners to reset the timeout on user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    resetTimeout(); // Initial setup

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [navigate]);


  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('autocomplete', 'off');
      input.addEventListener('copy', (event) => {
        event.preventDefault();
      });
    });
  }, []);

  // Function to open the modal
  const openModal = () => {
    dispatchvalue({type:'isModalOpen',value:true})
  };

  // Function to close the modal
  const closeModal = () => {
    dispatchvalue({type:'isModalOpen',value:false})
  };

  return (
    <>
      <Routes>
        <Route path='/Home' element={<HomePage  />}>
          {/* Header */}
          <Route path="Profile" element={ <Profile />} />
          <Route path="PasswordChange" element={ <PasswordChange  />} />

          {/* Patient Management */}
          <Route path="PatientProfile" element={<PatientProfile  />} />


          {/* Dashboard */}
          <Route path="ClinicMetrics" element={<ClinicMetrics  />} />


          {/* Front Office */}
          <Route path="Register-Patient2" element={<RegistrationForm />} />
          <Route path="Appoinment-RequestList" element={<RequestList />} />
          <Route path="Register" element={<Register  />} />
          <Route path="Appoinment-RegisterList" element={<RegisterList   />} />
          <Route path="Appointment-Calendar" element={<AppoinmentCalendar  />} />
          <Route path="Register_concern_List" element={<Concernlist  />} />
          <Route path="Register_concern_Form" element={<Concern />} />
          <Route path="Lab-QueueList" element={<LabQueueList  />} />
          <Route path="Lab-ReportFN" element={<LabReportFN   />} />
          <Route path="Patient-List-update" element={<PatientListupdate  />} />
          <Route path="Followuplist" element={<Followuplist  />} />

     
        

          {/* Nurse */}
          <Route path="Treament-QueueList3" element={<PatientQueueList3  />} />
          <Route path="Navigation3" element={<Navigation3  />} />

          {/* IP Management */}


          <Route path="IP_Billing_List" element={<IpBillingList/>} />
          <Route path="IP_Billing" element={<IpBilling/>} />
          <Route path="IP_Pharmacy_List" element={<IpPharmacy/>} />
          <Route path="IP_Pharmacy_Billing" element={<IpPharmacyBilling/>} />



          <Route path="IP-Registration_New" element={<CommonRegistration/>} />
          <Route path="IP-Registration" element={<IpRegisterEdit/>} />
          <Route path="OT-Management"element={<TheatreBooking />}/>
          <Route path="Room_Master" element={<RoomMaster />}/>
          <Route path="Room-Management"element={<RoomsAvail />}/>          
          <Route path="IpRegistrationlist" element={<IpRegistrationlist  />} />
          <Route path="IpNurseQuelist" element={<NurseQueList/>} />
          <Route path="IpNurseWorkBench" element={<IpNurseWorkbench/>} />
          <Route path="IpDocQuelist" element={<IpDoctorQueList/>} />
          <Route path="IpDocWorkBench" element={<IpDoctorWorkbench/>}/>
          {/* <Route path="IPratecard" element={<IPrateCardMaster/>}/> */}
          <Route path="Room_Create_Master" element={<RoomMasterCreate/>}/>
          <Route path="frequencymaster" element={<FrequencyMaster/>}/>

          {/* Stock */}

          {/* supplier Master */}

          <Route path='Supplier_List' element={<SupplierList  />} />
          <Route path='Supplier_Master' element={<SupplierMaster  />} />
          {/*  Product Master */}
          <Route path='Product_List' element={<ProductList  />} />
          <Route path='Product_Master' element={<ProductMaster  />} />


      {/* Pahrmachy Master */}
      <Route path='Pharmacy_MasterList' element={<PharmachyMasterList  />} />
          <Route path='Pharmacy_Master' element={<PharmachyMaster  />} />

          {/* General Master */}

          <Route path='General_MasterList' element={<GeneralMasterList />} />
          <Route path='General_Master' element={<GeneralMaster  />} />



          {/* purchase raise master */}
          <Route path='Purchase_Raise_list' element={<PurchaseRaiseList  />} />
          <Route path='Purchase_Raise_Master' element={<PurchaseRaiseMaster  />} />
          {/* purchase recieve master */}
          <Route path='Purchase_Recieve_list' element={<PurchaseRecieceList  />} />
          <Route path='Purchase_Recieve_Master' element={<PurchaseRecieveMaster  />} />
          {/* purchase Indent Raise master */}
          <Route path='Indent_Raise_list' element={<IndentRaiseList  />} />
          <Route path='Indent_Raise_Master' element={<IndentRaiseMaster  />} />
          {/* purchase Indent Recieve master */}
          <Route path='Indent_Recieve_list' element={<IndentRecieveList  />} />
          <Route path='Indent_Recieve_Master' element={<IndentRecieveMaster  />} />
          {/* purchase Indent Issue master */}
          <Route path='Indent_Issue_list' element={<IndentIssueList  />} />
          <Route path='Indent_Issue_Master' element={<IndentIssueMaster  />} />


          <Route path='Indent_Return_list' element={<IndentReturnList  />} />
          <Route path='Indent_Return_Master' element={<IndentReturnMaster  />} />
          <Route path="IndentMovementmaster" element={<IndentMovementmaster  />} />
          <Route path="IndentMovementlist" element={<IndentMovementlist  />} />

          {/* indent Approve */}
          <Route path='Indent_appprove_List' element={<IndentApprove  />} />
          <Route path='IndentIssue_appprove_List' element={<IndenIssueApprove />} />
          <Route path='PurchaseApprove_List' element={<PurchaseApprove  />} />
          <Route path='IndentRecieve_appprove_List' element={<IndentRecieveApprove  />} />
          <Route path='GRN_appprove_List' element={<GrnApprove  />} />
          <Route path='IndentReturnApprove' element={<IndentReturnApprove />} />

          {/* expiry products */}
          <Route path='PurchaseReturn_Loc_Master' element={<PurchaseReturnLocMaster />} />
          <Route path='PurchaseReturn_Master' element={<PurchaseReturnMaster />} />
          {/* stock list */}
          <Route path='CentralStock_List' element={<CentralStockList />} />
          <Route path='LocationStock_List' element={<LocationStock  />} />
          {/* QuickStock Location */}
          <Route path='Quick_Stock_Recieve_List' element={<QuickStockListLoc  />} />
          <Route path='Quick_Stock_Recieve' element={<QuickStockMasterLoc  />} />
          <Route path='Quick_Stock_Recieve_Central' element={<QuickStockMasterCentral  />} />
          {/* Supplier Pay */}
          <Route path='Supplier-Stock-Manager' element={<SupplierStock  />} />
          <Route path='Supplier-Stock-Details' element={<SupplierDetails  />} />
          <Route path='Supplier_Pay_List' element={<SuplierPaidlist />} />



          {/*------------------------------ Finance -------------------------*/}
          <Route path='PurchaseRegister' element={<PurchaseRegister/>} />
          <Route path='SalesRegister' element={<SalesRegister/>} />
          <Route path='Journalentry' element={<Journalentry/>} />
          <Route path='LedgerList' element={<LedgerList/>} />
          <Route path="Add_Accounts" element={<Accounts />} />
          <Route path="BalanceSheet" element={<BalanceSheet />} />
          <Route path="AccountGroupList" element={<AccountGroupList />} />
          <Route path='ContraVoucher' element={<ContraVoucher />}/>
          <Route path='ContraVoucherList' element={<ContraVoucherList />}/>
          <Route path='PaymentVoucher' element={<PaymentVoucher />}/>
          <Route path='PaymentVoucherList' element={<PaymentVoucherList />}/>
          <Route path='ReceiptVoucher' element={<ReceiptVoucher />}/>
          <Route path='ReceiptVoucherList' element={<ReceiptVoucherList />}/>
          <Route path='JournalentryList' element={<JournalentryList />}/>
          <Route path='DayBook' element={<DayBook />}/>
          <Route path='TrialBalance' element={<TrialBalance />}/>
          <Route path='ProfitandLoss' element={<ProfitandLoss />}/>
          <Route path='CashbookandBankbook' element={<CashbookandBankbook />}/>
          <Route path='AccountGroupMaster' element={<AccountGroupMaster />}/>
          <Route path='HSNReport' element={<HSNReport/>}/>
          <Route path='DueReport' element={<DueReport/>}/> 

          

          {/* Doctor work bench */}
          <Route path="Treament-QueueList" element={<PatientQueueList  />} />

          <Route path="Navigation" element={<Navigation  />} />
          <Route path="Patient-List" element={<PatientList  />} />
          <Route path="vitalForm2" element={<Vital2  />} />   {/*Pending formData*/}

          <Route path="PatientView" element={<PatientView  />} />

          <Route path="Preview" element={<Preview  />} /> {/*Pending formData*/}

          {/* <Route path="vitalForm3" element={<Vital3 userRecord={userRecord} />} /> */}




          {/* Theraphist */}
          <Route path="Treament-QueueList2" element={<PatientQueueList2  />} />
          <Route path="Navigation2" element={<Navigation2  />} />
          <Route path="Patient-List2" element={<PatientList2 />} />
          <Route path="Treament-NewProcedure" element={<NewProcedure  />} />


          {/* Counselor */}
          <Route path="Treament-CounselorQueueList" element={<CounselorQueueList  />} />
          <Route path="Treament-CounselorProcedure" element={<CounselorProcedure  />} />
          <Route path="Treament-CounselorFullList" element={<CounselorFullList />} />


          {/* Billing */}
          <Route path="Billing-History" element={<BillingHistory  />} />
          <Route path="Deu-History" element={<DeuHistory  />} />
          <Route path="Deu-History-List" element={<DueHistoryList  />} />
          {/* <Route path="Deu-Close" element={<DueHistory />} /> */}
          <Route path="Billing-Invoice" element={<GeneralBillingQueList />} />
          <Route path="Billing-Invoice-All" element={<GeneralBilling  />} />
          <Route path="Bill-Cancellation" element={<BillCancelation  />} />

          <Route path="Pharmacy_Billing_List" element={<PharmacyBillingLIst  />} />
          <Route path="Pharmacy-Billing" element={<PharmacyBilling  />} />
          <Route path="Pharmacy-Walkinbilling" element={<Walkinbilling  />} />

          <Route path="Pharmacy_Billcancelation" element={<PharmacyBillcancelation  />} />
          <Route path="Bill-Report" element={<OverallDayreport />} />



          {/* ICU Management */}
          {/* <Route path="IcuNursequelist" element={<IcuNurseQueList/>} /> */}
          <Route path="IcuNurseWorkBench" element={<IcuNurseWorkbench/>} />
          {/* <Route path="IcuDocQuelist" element={<IcuDoctorQueList/>} /> */}
          <Route path="IcuDocWorkBench" element={<IcuDoctorWorkbench/>}/>
          <Route path="IcuSurgicalHistory" element={<IcuSurgicalHistory/>}/>
          <Route path="ConsentFormCreate" element={<ConsentFormCreate/>}/>
          <Route path="Treatment" element={<IcuTreatment/>}/>
          <Route path="News" element={<News/>}/>

          {/* Emergency */}
         {/* <Route path="EmergencyNurseQueList" element={<EmergencyNurseQueList />} />
         <Route path="EmergencyDoctorQueList" element={<EmergencyDoctorQueList />} /> */}
         <Route path="EmergencyNurseVitals" element={<EmergencyNurseVitals/>} />
         <Route path="EmergencyNurseWorkbench" element={<EmergencyNurseWorkbench/>}/>
         {/* Emergency */}
         <Route path="EmergencyPatientHemodynamicsMaster" element={<EmergencyPatientHemodynamicsMaster />} />
         <Route path="EmergencyDocWorkbench" element={<EmergencyDocWorkbench/>}/>
          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />


          {/* OT_Management      */}
          {/* <Route path="OT_Room_Master" element={<OtRoomMaster />}/> */}
          <Route path="OT-Management"element={<OtRequest />}/>
          <Route path="OT-QueList"element={<OtQuelist />}/>

          <Route path="OT-ConcentQueList"element={<OtConsentForm />}/>

          <Route path="OT-DoctorQueList"element={<OtDoctorQueueList/>}/>
          <Route path="OT-DoctorListNaviagtion"element={<OtDoctorNavigation />}/>
          <Route path="OT-DoctorListPre"element={<OtDoctorPre />}/>

          <Route path="OT-NurseListPre"element={<OtNursePre />}/>
          <Route path="OT-NurseQueList"element={<OtNurseQueList />}/>
          <Route path="OT-NurseeListNaviagtion"element={<OtNurseeNavigation />}/>
       

          <Route path="OT-AnaesthesiaListPre"element={<OtAnaesthesiaPre />}/>
          <Route path="OT-AnaesthesiaQueList"element={<OtAnaesthesiaQueueList />}/>
          <Route path="OT-AnaesthesiaListNaviagtion"element={<OtAnaesthesiaNavigation />}/>
          <Route path="OT-AnaesthesiaCapture"element={<OTAanaesthesiaCapture   />}/>



          {/* Petty Cash */}
          <Route path="Expenses-Master" element={<ExpensesMaster  />} />
          <Route path="Cash-Expenses" element={<CashExpenses  />} />
          <Route path="Digital-Expenses" element={<DigitalExpenses />} />
          <Route path="Cash-Expenses-Report" element={<CashExpenseReport  />} />
          <Route path="Hand-Over-Summary" element={<HandOverSummary  />} />
          <Route path="Day-Book-Report" element={<DayBookReport  />} />
          <Route path="Day-Closing" element={<DayClosing  />} />

          {/* HR management */}
         <Route path="Employee-Register" element={<EmployeeRegister />} />
          <Route path="Employee-List" element={<EmployeeList  />} />
          <Route path="Employee-Profile" element={<EmployeeProfile  />} />
          <Route path="Employee-Attendance" element={<EmployeeAttendance  />} />
          <Route path="Employee-Performance" element={<PerformanceAppraisal  />} />
          <Route path="Employee-PerformanceManagement" element={<PerformanceManagement  />} />
          <Route path="Employee-AdvanceApproval" element={<AddvaceApproval  />} />
          <Route path="Employee-LeaveApproval" element={<LeaveApproval  />} />
          <Route path="Employee-LeaveManage" element={<LeaveMangement  />} />
          <Route path="Employee-AdvanceManagement" element={<AddvaceManagement />} />
          <Route path="Shift-Details" element={<ShiftDetails />}/>
          <Route path="Employee-AttendanceCalendar" element={<EmployeeCalendar  />} />
          <Route path="Employee-PayRoll" element={<PayRoll  />} />
          <Route path="Employee-PaySlip" element={<PaySlip  />} />
          <Route path="Duty-Management" element={<DutyManagement  />} />
          <Route path="Job-Requirements" element={<JobRequirement />} />
          <Route path='Consultancy-Mater' element={<ConsultantcyMaster />} />

          <Route path='Consultancy-Typeup-List' element={<ConsultancyTypeUpList/>}/>
          <Route path="RequestTo-HrManagement" element={<RequestToHR />} />
          <Route path="RequestTo-HrManagementList" element={<RequestToHrList />} />
          <Route path="Mail-To-Consultancy" element={<MailToConsultancy />} />
          <Route path="MailFrom-Consultancy" element={<MailFromConsultancy />} />
          <Route path="Shortlisted-Resume-list" element={<ShortlistedResumelist />} />
          <Route path="Hr-DashBoard" element={<HrDashboard/>}/>

            {/* Insurance */}
            <Route path="Insurance-Form" element={<Insurance />} />
          <Route path="Insurance-Dashboard" element={<InsuranceDashboard />} />
          <Route path="InsuranceMaster" element={<InsuranceMaster />} />
          <Route path="InsuranceMasterList" element={<InsuranceMasterList />} />
          <Route path="Claims-Form" element={<Claims />} />
          <Route path="Claims-Dashboard" element={<ClaimsDashboard />} />
          <Route path="Claims-Reconciliation" element={<Reconcilition />} />
          <Route path="InsuranceMainpage" element={<InsuranceMainpage />} />



          
          



          {/* Employee Request */}
          <Route path="Navigation-leave" element={<LeaveNavigation  />} />
          <Route path="Navigation-Advance" element={<AdvanceNavigation  />} />
          <Route path="Shift-Details" element={<ShiftDetails/>}/>

          {/* Visiting Doctor */}
          <Route path="Patient-Visiting-Doctor" element={<PatientVisitingDoctor  />} />
          <Route path="Visiting-Doctor" element={<VisitingDoctor  />} />

          {/* User Control */}
          <Route path="Role-Management" element={<RoleManagement />} />
          <Route path="Register-User" element={<UserRegister  />} />
          <Route path="User-List" element={<UserList  />} />
          <Route path="Account Settings" element={<AccountSettings  />} />
          <Route path="Clinic Details"element={<ClinicDetails  />} />
          {/* <Route path="Doctor Consulation Charges" element={<DoctorCharges closeModal={closeModal} openModal={openModal} isModalOpen={isModalOpen} isSidebarOpen={isSidebarOpen} userRecord={userRecord} />} /> */}
          <Route path="EmployeeQue-List" element={ <EmployeeQueList /> } />
          {/* <Route path="Employee-LeaveManagement" element={<LeaveMangement userRecord={userRecord} setSelectedRowData={setSelectedRowData} isSidebarOpen={isSidebarOpen} />} /> */}
         <Route  path="RateCardMasterAll"  element={<RateCardMaster/>}/>
         <Route  path="RateCardMasterlist"  element={<RateCardList/>}/>
         <Route path="Visit-Doctor-Billing-List" element={<VisitingDoctorBillingList />} />
         <Route path="Visit-Doctor-Billing" element={<VisitingDoctorBilling />} />
         <Route path="Referral_Doctor" element={<Referaldoctor/>}/>
         <Route path="TreatmentMaster" element={<TreatmentMaster />} />
         <Route path="OT-Surgery-Master" element={<OTSurgeryMaster />} />


         {/* lims */}
         
         {/* Masters */}
         <Route path="GroupMaster" element={<GroupMaster />} />
         <Route path="GroupMasterList" element={<GroupMasterList />} />
         <Route path="Units" element={<Units />} />
         <Route path="Container" element={<Container />} />
         <Route path="Specimen" element={<Specimen />} />
         <Route path="Methods" element={<Methods />} />
         <Route path="Patient-group" element={<PatientGroupName />} />
         <Route path="OrganismMaster" element={<OrganismMaster />} />
         {/* <Route path="Methods" element={<Methods />} /> */}
         <Route path="ExternalLabmaster" element={<ExternalLab />} />
         <Route path="ExternalLabmasterList" element={<ExternalLabList />} />
         <Route path="EquipmentsMaster" element={<Equipments />} />
         <Route path="DepartmentsMasters" element={<Department />} />
         <Route path="AssetTypemaster" element={<AssetType />} />
         <Route path="AntibioticGroupmaster" element={<AntibioticGroup />} />
         <Route path="Antibioticmaster" element={<AntibioticMaster />} />
         <Route path="TestMastersNavigation" element={<TestMasterNavigation />} />
         <Route path="TestmasterList" element={<MasterList />} />

         {/* Receptionist */}
         <Route path="ReceptionistBilling" element={<Billing />} />
         {/* <Route path="PatientData" element={<PatientData />} /> */}
         <Route path="Patientlistforlab" element={<PatientListforLab />} />
         <Route path="PatientRequestlistforlab" element={<PatientRequestforLab />} />
         <Route path="PaymentHistroy" element={<PaymentHistory />} />


        {/* Phelobotomist */}
        <Route path="CaptureSamplelist" element={<CaptureSample />} />
        <Route path="PhelobotomistCapture" element={<Navigationple />} />
        <Route path="SamplelistData" element={<SampleList />} />
        <Route path="TransactionHistory" element={<TransactionHistory />} />
        
        {/* Lab Technicians */} 

        <Route path="AnalayseReportEntry" element={<Analyzereport />} />
        <Route path="NavigationLabtechnician" element={<NavigationLab />} />
        <Route path="ValidatedQue" element={<ValidatedQueue />} />

        {/* Sr. LabTechnician */}
        <Route path="VerifyReportEntry" element={<Verifyreport />} />
        <Route path="NavigationSenior" element={<Navigationseior />} />
        <Route path="SrLabValidatedQue" element={<SrValidatedQueue />} />
        
        
        {/* Pathologist */}
        <Route path="AuthorizedReportEntry" element={<Approvereport />} />
        <Route path="NavigationPathalogist" element={<Navigationpath />} />
        <Route path="PathValidatedQue" element={<PathValidatedQueue />} />
        
        {/* Dispatch */}
        <Route path="DispatchEntryList" element={<DispatchList />} />
        <Route path="CompleteReports" element={<Reports />} />



          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />

          {/* NotFound */}
          <Route path="*" element={<NotFound />} />

        </Route>
        <Route path='/' element={<LoginPage />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App;