import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaPlus, FaTrash } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Webcam from "react-webcam";
import Button from "@mui/material/Button";
import DrawIcon from "@mui/icons-material/Draw";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LineWeightRoundedIcon from "@mui/icons-material/LineWeightRounded";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import { BsEraserFill } from "react-icons/bs";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import SaveIcon from "@mui/icons-material/Save";
import ReactModal from "react-modal";

const Treatments = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: "Center",
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
              {
                textAlign: "center",
                display: "flex !important",
                justifyContent: "center !important",
              },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
  });

  const [treatmentData, setTreatmentData] = useState([]);
  const [thickopen, setthickopen] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [treatmentsubData, setTreatmentSubData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showTextarea, setShowTextarea] = useState(true);
  const [isSaveButton, setIsSaveButton] = useState(true);
  const webcamRef1 = useRef(null);
  const [facingMode, setFacingMode] = useState("user");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = treatmentData.length;
  const [colour, setColour] = useState("red");
  const [linewidth, setLineWidth] = useState(2);
  const drawingPadRef = useRef(null);
  const webcamRef = useRef(null);
  const [clropen, setclropen] = useState(false);
  const colorOptions = ["red", "blue", "black"];
  const [PDFImages, setPDFImages] = useState([]);
  const [PDFcreated, setPDFcreated] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent2, setModalContent2] = useState("");

  const totalPages = Math.ceil(treatmentData.length / 10);
  useEffect(() => {
    axios
      .get(`${UrlLink}patientmanagement/detect_device`)
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const videoConstraints = {
    facingMode: facingMode,
  };
  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  const createdby = userRecord?.username;
  const PatientId = IpNurseQueSelectedRow?.PatientId;

  const [icdDiagnosis, SeticdDiagnosis] = useState("");
  const [Diagnosis, SetDiagnosis] = useState("");
  const [icdTreatment, SeticdTreatment] = useState("");
  const [Treatment, SetTreatment] = useState("");
  const [Remarks, SetRemarks] = useState("");
  const [history, SetHistory] = useState("");
  const [lines, setLines] = useState([]);
  const [examination, SetExamination] = useState("");
  const [presentComp, SetPresentComp] = useState("");
  const [file, setFile] = useState(null);
  const [table1, settable1] = useState([]);
  const [table2, settable2] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [isImageCaptured1, setIsImageCaptured1] = useState(false);
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);
  const [formData3, setFormData3] = useState({
    CapturedImage1: null,

    CapturedFile1: null,
  });
  useEffect(() => {
    Modal.setAppElement("#root"); // Set the app element to the root of your application
  }, []);
  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };
  const saveMergedImage = () => {
    if (capturedImage || lines.length > 0) {
      html2canvas(drawingPadRef.current, { useCORS: true }).then((canvas) => {
        canvas.toBlob((blob) => {
          setPDFImages((prevImages) => [...prevImages, blob]);
          retakeImage();
        }, "image/jpeg");
      });
    }
  };

  const clearlines = () => {
    setLines([]);
  };

  const saveImages = () => {
    saveMergedImage();
  };
  const handleMouseUp = () => {
    setIsDrawing(false);
  };
  const retakeImage = () => {
    setCapturedImage(null);
    setLines([]);
  };
  const handleWidth = (widthValue) => {
    setLineWidth(widthValue);
  };

  const handleColor = (color) => {
    setColour(color);
  };

  const handleWebSidebar1 = () => {
    setthickopen(!thickopen);
  };

  const handleWebSidebar = () => {
    setclropen(!clropen);
  };
  const captureImage = () => {
    retakeImage();
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };
  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDrawing(true);
    setLines([...lines, { points: [], colour, linewidth }]);
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;

    const newLines = [...lines];
    const currentLine = newLines[newLines.length - 1];

    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const rect = drawingPadRef.current.getBoundingClientRect();

    currentLine.points.push({
      x: clientX - rect.left,
      y: clientY - rect.top,
    });
    setLines(newLines);
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const handleChangeFile = (e) => {
    const fileObject = e.target.files[0];
    setFile(fileObject);
  };
  const handleRecaptureImage1 = () => {
    // setCapturedImage(null);
    setFormData3((prev) => ({
      ...prev,
      CapturedImage1: null,
    }));
    setIsImageCaptured1(false);
  };
  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    setFormData3({
      ...formData3,
      CapturedImage1: imageSrc
        ? dataURItoBlob(imageSrc, "captured_image.jpg")
        : null,
      CapturedFile1: null,
    });
    setIsImageCaptured1(true);
  };
  const handleInputFileChange = (event, inputName) => {
    const file = event.target.files[0];

    if (inputName === "CapturedFile1") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage1: null,
      });
    } else if (inputName === "CapturedFile2") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage2: null,
      });
    } else if (inputName === "CapturedFile3") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage3: null,
      });
    }
  };

  const handlePlusClick = () => {
    if (icdDiagnosis == "" && Diagnosis == "") {
      userwarn("Please enter icdDiagnosis and Diagnosis .");
      return;
    }
    // Add the selected diagnosis and icdDiagnosis to the list
    settable1((prev) => [
      ...prev,
      { icdDiagnosis: icdDiagnosis, Diagnosis: Diagnosis },
    ]);
    // Clear the input fields after adding
    SeticdDiagnosis("");
    SetDiagnosis("");
  };

  const handleDelete = (index) => {
    // Remove the entry at the specified index from the list
    const updatedTable1 = [...table1];
    updatedTable1.splice(index, 1);
    settable1(updatedTable1);
  };

  const handlePlusClick1 = () => {
    if (icdTreatment == "" && Treatment == "") {
      userwarn("Please enter icdTreatment and Treatment .");
      return;
    }

    settable2((prev) => [
      ...prev,
      { icdTreatment: icdTreatment, Treatment: Treatment },
    ]);

    SeticdTreatment("");
    SetTreatment("");
  };

  const handleDelete1 = (index) => {
    // Remove the entry at the specified index from the list
    const updatedTable2 = [...table2];
    updatedTable2.splice(index, 1);
    settable2(updatedTable2);
  };

  const handlePrintSave = (e) => {
    if (PDFImages.length > 0) {
      const doc = new jsPDF();
      const promises = PDFImages.map((blob, index) => {
        return new Promise((resolve, reject) => {
          if (blob instanceof Blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const imageUrl = reader.result;
              const image = new Image();
              image.onload = () => {
                const width =
                  image.width *
                  (doc.internal.pageSize.getWidth() / image.width);
                const height =
                  image.height *
                  (doc.internal.pageSize.getHeight() / image.height - 0.015);
                if (index !== 0) {
                  doc.addPage();
                }
                doc.addImage(imageUrl, "JPEG", 5, 2, width, height);
                resolve();
              };
              image.onerror = (error) => {
                console.error(`Error loading image at index ${index}:`, error);
                reject(error);
              };
              image.src = imageUrl;
            };
            reader.onerror = (error) => {
              console.error(`Error reading blob at index ${index}:`, error);
              reject(error);
            };
            reader.readAsDataURL(blob);
          } else {
            console.error(`Invalid image data at index ${index}`);
            reject(new Error(`Invalid image data at index ${index}`));
          }
        });
      });

      Promise.all(promises)
        .then(() => {
          const PDFcreatedfile = doc.output("blob");
          sendDataToServer(PDFcreatedfile);
        })
        .catch((error) => {
          console.error("Error creating PDF:", error);
        });
    } else {
      sendDataToServer(file);
    }
  };

  const sendDataToServer = (file) => {
    const formdata = new FormData();
    formdata.append("file", file);

    formdata.append("patientId", IpNurseQueSelectedRow?.PatientId);
    formdata.append("bookingId", IpNurseQueSelectedRow?.Booking_Id);
    formdata.append("Remarks", Remarks);
    formdata.append("history", history);
    formdata.append("examination", examination);
    formdata.append("presentComp", presentComp);
    formdata.append("createdby", createdby);
    formdata.append("Diagnosis", JSON.stringify(table1));
    formdata.append("Treatment", JSON.stringify(table2));

    axios
      .post(`${UrlLink}ipregistration/insert_treatments`, formdata)
      .then((response) => {
        resetFormFields();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const resetFormFields = () => {
    setFile("");
    settable1("");
    settable2("");
    SetRemarks("");
    SetHistory("");
    SetExamination("");
    SetDiagnosis("");
    SetPresentComp("");
    SetTreatment("");
    SeticdDiagnosis("");
    SeticdTreatment("");
    setPDFcreated(null);
  };

  useEffect(() => {
    axios
      .get(
        `${UrlLink}ipregistration/get_treatments_data?patientid=${PatientId}`
      )
      .then((response) => {
        const data = response.data;

        setTreatmentData([
          ...data.map((row, index) => ({
            id: row.S_NO,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [PatientId]);

  const [file1, setfile1] = useState(null);
  const handleViewIconClick = (params) => {
    setEditMode(true);
    setShowTextarea(!showTextarea);
    setIsSaveButton(false);

    const sno = params.row.S_NO;
    SetExamination(params.row.Examination);
    SetHistory(params.row.History);
    SetPresentComp(params.row.Present_Complaints);
    SetRemarks(params.row.Remarks);
    setfile1(params.row.Treatment_file);

    axios
      .get(
        `${UrlLink}ipregistration/get_treatments_sub_data?patientid=${PatientId}&sno=${sno}`
      )
      .then((response) => {
        const data = response.data;

        settable2(data.Treatment);
        settable1(data.Diagnosis);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dynamicColumns = [
    { field: "id", headerName: "S.NO", width: 100 },
    { field: "Examination", headerName: "Examination", width: 120 },
    { field: "History", headerName: "History", width: 120 },
    {
      field: "Present_Complaints",
      headerName: "Present Complaints",
      width: 150,
    },
    { field: "Remarks", headerName: "Remarks", width: 120 },
    { field: "Captured_Date", headerName: "Captured Date", width: 120 },
    { field: "Captured_Time", headerName: "Captured Time", width: 120 },
    {
      field: "Action",
      headerName: "view",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn">
            <VisibilityIcon
              onClick={() => {
                handleViewIconClick(params);
                const modalElement = document.getElementById(
                  "new-patient-registration-form"
                );
                if (modalElement) {
                  modalElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            />
          </Button>
        </>
      ),
    },
  ];
  const handleNewButtonClick = () => {
    setEditMode(false);
    setShowTextarea(true);
    setIsSaveButton(true);
    setFile("");
    settable1("");
    settable2("");
    SetRemarks("");
    SetHistory("");
    SetExamination("");
    SetDiagnosis("");
    SetPresentComp("");
    SetTreatment("");
    SeticdDiagnosis("");
    SeticdTreatment("");
  };

  const handleVisibilityClick = (file1) => {
    if (!file1) {
      alert("No data found.");
    } else {
      setModalIsOpen(true);

      setModalContent2(file1);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalIsOpen(false);
    setModalContent("");
  };

  const handleclick = () => {
    setOpenModal(true);
  };

  return (
    <div
      className="new-patient-registration-form"
      id="new-patient-registration-form"
    >
      <div className="RegisFormcon" id="RegisFormcon">
        {/* <button onClick={handleclick}>hai</button> */}
        <div className="RegisForm_1">
          <label htmlFor="newPulse">
            History <span>:</span>
          </label>
          <textarea
            type="text"
            id="history"
            name="history"
            onChange={(e) => SetHistory(e.target.value)}
            value={history}
            readOnly={editMode}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="newPulse">
            Examination <span>:</span>
          </label>
          <textarea
            type="text"
            id="examination"
            name="examination"
            onChange={(e) => SetExamination(e.target.value)}
            value={examination}
            readOnly={editMode}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="newPulse">
            Present Complaints <span>:</span>
          </label>
          <textarea
            type="text"
            id="presentComp"
            name="presentComp"
            onChange={(e) => SetPresentComp(e.target.value)}
            value={presentComp}
            required
            readOnly={editMode}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="newPulse">
            Remarks<span>:</span>
          </label>
          <textarea
            id="Remarks"
            name="Remarks"
            className="textareasumma"
            value={Remarks}
            onChange={(e) => SetRemarks(e.target.value)}
            readOnly={editMode}
          />
        </div>
        <br />
        <br />
        <div className="RegisForm_1" id="RegisFormsumma">
          <label>
            Choose Documents<span>:</span>
          </label>
          {!editMode && (
            <div className="RegisterForm_2">
              <input
                type="file"
                id="CapturedFile123"
                name="CapturedFile123"
                accept="image/*,.pdf"
                className="hiden-nochse-file"
                onChange={handleChangeFile}
                required
                readOnly={editMode}
              />
              <label
                htmlFor="CapturedFile123"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
            </div>
          )}

          {!editMode && <span>or </span>}
          {!editMode && (
            <div className="web_btn">
              <button
                onClick={handleclick}
                className="RegisterForm_1_btns choose_file_update"
              >
                Capture image
              </button>
            </div>
          )}

          {editMode && (
            <button
              className="RegisterForm_1_btns print-button3"
              id="view"
              onClick={() => {
                handleVisibilityClick(file1);
              }}
            >
              View
            </button>
          )}
        </div>
      </div>

      <br />
      <div className="BodyofExpensesMaster">
        <div className="FirstpartOFExpensesMaster">
          <h3>Diagnosis Department</h3>
          <br />
          {!editMode && (
            <div className="RegisFormcon" style={{ justifyContent: "center" }}>
              <div className="RegisForm_1">
                <label htmlFor="input">
                  ICD Diagnosis Code <span>:</span>
                </label>
                <input
                  type="text"
                  id="icdDiagnosis"
                  name="icdDiagnosis"
                  value={icdDiagnosis}
                  disabled={editMode}
                  onChange={(e) => SeticdDiagnosis(e.target.value)}
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="input">
                  Diagnosis <span>:</span>
                </label>
                <input
                  type="text"
                  id="Diagnosis"
                  name="Diagnosis"
                  value={Diagnosis}
                  onChange={(e) => SetDiagnosis(e.target.value)}
                />
              </div>

              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns"
                  onClick={handlePlusClick}
                >
                  <AddIcon />
                </button>
              </div>
            </div>
          )}
          <br />
          {table1.length > 0 && (
            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              {!editMode && <h4>Table</h4>}

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S No</th>
                      <th>ICD Diagnosis</th>
                      <th>Diagnosis</th>
                      {!editMode && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {table1.map((treatmentdata, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{treatmentdata.icdDiagnosis}</td>
                        <td>{treatmentdata.Diagnosis}</td>
                        {!editMode && (
                          <td>
                            <FaTrash onClick={() => handleDelete(index)} />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div className="FirstpartOFExpensesMaster">
          <h3>Treatment Department</h3>
          <br />
          {!editMode && (
            <div className="RegisFormcon" style={{ justifyContent: "center" }}>
              <div className="RegisForm_1">
                <label htmlFor="newPulse">
                  ICD Treatment <span>:</span>
                </label>
                <input
                  type="text"
                  id="icdTreatment"
                  name="icdTreatment"
                  onChange={(e) => SeticdTreatment(e.target.value)}
                  value={icdTreatment}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="newPulse">
                  Treatment <span>:</span>
                </label>
                <input
                  type="text"
                  id="Treatment"
                  name="Treatment"
                  onChange={(e) => SetTreatment(e.target.value)}
                  value={Treatment}
                  required
                />
              </div>
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns"
                  onClick={handlePlusClick1}
                >
                  <AddIcon />
                </button>
              </div>
            </div>
          )}
          <br />
          {table2.length > 0 && (
            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              {!editMode && <h4>Table</h4>}

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>ICD Treatment</th>
                      <th>Treatment</th>
                      {!editMode && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {table2.map((treatmentdata1, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{treatmentdata1.icdTreatment}</td>
                        <td>{treatmentdata1.Treatment}</td>
                        {!editMode && (
                          <td>
                            <FaTrash onClick={() => handleDelete1(index)} />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="Register_btn_con">
        {isSaveButton ? (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handlePrintSave}
          >
            Save
          </button>
        ) : (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handleNewButtonClick}
          >
            Clear
          </button>
        )}
      </div>
      <ToastContainer />
      <div className="IP_grid">
        <ThemeProvider theme={theme}>
          <div className="IP_grid_1">
            <DataGrid
              rows={treatmentData.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" Ip_data_grid"
            />
            {showdown > 0 && treatmentData.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && treatmentData.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            {capturedImage ? (
              <div className="camera-container">
                <div className="web_camera">
                  <div
                    className="web_camera_svg"
                    ref={drawingPadRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUp}
                  >
                    <img src={capturedImage} alt="background" />
                    <svg>
                      {lines.map((line, index) => (
                        <polyline
                          key={index}
                          points={line.points
                            .map((point) => `${point.x},${point.y}`)
                            .join(" ")}
                          stroke={line.colour}
                          strokeWidth={line.linewidth}
                          fill="none"
                        />
                      ))}
                    </svg>
                  </div>
                  <div className="web_sidebar">
                    <div
                      className="w_sidebar1"
                      title="Save"
                      onClick={saveImages}
                    >
                      <SaveIcon className="w_side_icon" />
                    </div>
                    <div
                      className="w_sidebar1"
                      title="Retake"
                      onClick={retakeImage}
                    >
                      <PhotoCameraIcon className="w_side_icon" />
                    </div>
                    <div
                      className="w_sidebar1"
                      title="Clear"
                      onClick={clearlines}
                    >
                      <BsEraserFill className="w_side_icon" />
                    </div>
                    <div
                      className="w_sidebar1 width_side width_side_incr"
                      title="Color"
                      onClick={handleWebSidebar}
                    >
                      <ColorLensRoundedIcon className="w_side_icon" />
                      {clropen && (
                        <div className="color_options">
                          {colorOptions.map((color) => (
                            <div className="clr_opt" title={color}>
                              <DrawIcon
                                key={color}
                                className="color_option"
                                style={{ color }}
                                onClick={() => handleColor(color)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      className="w_sidebar1 width_side"
                      title="Line Width"
                      onClick={handleWebSidebar1}
                    >
                      <LineWeightRoundedIcon className="w_side_icon" />
                      {thickopen && (
                        <div className="thick_options">
                          <div className="clr_opt" title="small">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="24"
                              viewBox="0 0 24 24"
                              className="font_size_1"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1px"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              onClick={() => handleWidth(3)}
                            >
                              <line x1="3" y1="12" x2="21" y2="12" />
                            </svg>
                          </div>
                          <div className="clr_opt" title="medium">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="24"
                              viewBox="0 0 24 24"
                              className="font_size_1"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2.5px"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              onClick={() => handleWidth(3)}
                            >
                              <line x1="3" y1="12" x2="21" y2="12" />
                            </svg>
                          </div>
                          <div className="clr_opt" title="large">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="24"
                              viewBox="0 0 24 24"
                              className="font_size_1"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="5px"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              onClick={() => handleWidth(3)}
                            >
                              <line x1="3" y1="12" x2="21" y2="12" />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="camera-container">
                <div className="RotateButton_canva">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    mirrored={facingMode === "user"}
                    className="web_cam"
                    videoConstraints={videoConstraints}
                  />
                  {devices.includes(IsmobileorNot) && (
                    <div className="web_cam__1">
                      <button onClick={switchCamera}>
                        <CameraswitchIcon />
                      </button>
                    </div>
                  )}
                </div>
                <div className="web_btn">
                  <button onClick={captureImage} className="btncon_add">
                    Capture
                  </button>
                </div>
              </div>
            )}

            <button
              className="RegisterForm_1_btns"
              onClick={() => setOpenModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{ content: { ...yourStyles } }}
      >
        <div className="pdf_img_show">
          {modalContent2
            .toLowerCase()
            .startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent2}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          ) : (
            <img
              src={modalContent2}
              alt="Concern Form"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Treatments;
