import React, { useEffect } from 'react'
import { useState } from 'react';
// import '../OverallBilling/GeneralBilling.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useSelector } from 'react-redux';
const DeuHistory = () => {

    const userRecord=useSelector(state=>state.userRecord?.UserData)
    const navigate = useNavigate();

    const [SelectedDoctorName, setSelectedDoctorName] = useState({
        ServiceType: '',
        ServiceName: '',
        Session: 1,
        Amount: 0,
        Discount: 0,
        Gstchargep: 0,
        Total: 0,
    });

    const [FormData, setFormData] = useState({
        PatientID: '',
        PatientName: '',
        PhoneNumber: '',
        RatecardType: '',
        ClientName: '',
        InsuranceName: '',
        Age: '',
        City: '',
        State: '',
        Street: '',
        Pincode: '',
    })
    const [DoctorName, setDoctorName] = useState([])
    const [Dname, setDname] = useState('');
    const [discounttype, setDiscounttype] = useState('');
    // const [paidamount, setPaidamount] = useState()
    // const [Billpay_Account, setBillpay_Account]=useState('')
    // const [isedit, setIsedit] = useState(false);
    // const [IsIndex, setIsIndex] = useState(null)

    // const [doctorOptions, setDoctorOptions] = useState([]);

    const [Data, setData] = useState([]);
    const [totalPaidAmount, settotalPaidAmount] = useState(0)
    const [isEdit, setIsEdit] = useState(null);
    const [formAmount, setFormAmount] = useState({
        paidamount: '',
        Billpay_method: ''
    });

    // const [DataIndex, setDataIndex] = useState(null);
    const [billAmount, setBillAmount] = useState([])
    const [Billing_date, setBilling_date] = useState('');
    // const [Billpay_method, setBillpay_method] = useState('');
    const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
    const [initialState, setinitialState] = useState({
        totalItems: 0,
        totalAmount: 0,
        totalDiscount: 0,
        totalUnits: 0,
        totalGstamount: 0,
        totalNetAmount: 0,
    });
    const [ClinicDetials, setClinicDetials] = useState({
        ClinicLogo: null,
        ClinicName: '',
        ClinicGST: '',
        ClinicAddress: '',
        ClinicCity: '',
        ClinicState: '',
        ClinicCode: '', 
        ClinicMobileNo: '',
        ClinicLandLineNo: '',
        ClinicMailID: '',
        InvoiceNo: ''
    });

    let SelectedPateintBilling = JSON.parse(localStorage.getItem('SelectedPateintBillingDue'))
    // localStorage.clear();
    const numberToWords = (number) => {
        let num = parseInt(number.toString().split('.')[0])
        if (num === 0) {
            return 'Zero Rupees Only';
        }

        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
        const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const convert = (num) => {
            if (num <= 10 && num !== 0) return units[num];
            if (num < 20) return teens[num - 11];
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
            if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + convert(num % 100) : '');
            if (num < 100000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' and ' + convert(num % 1000) : '');
            if (num < 10000000) return convert(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 !== 0 ? ' and ' + convert(num % 100000) : '');

        };

        return convert(num) + ' Rupees  Only';
    };


    
    useEffect(() => {

        const totalPaidAmount = billAmount.reduce((total, ele) => +total + +ele.paidamount, 0);
        settotalPaidAmount(totalPaidAmount)
      }, [billAmount, billAmount.length])



    const handleChange = (e) => {

        // Calculate the total paid amount
    
        const { name, value } = e.target; // Destructuring name and value from event target
       const total_netamount = initialState.totalNetAmount;
        if (+totalPaidAmount !== +total_netamount) {
          if (name == 'Billpay_method') {
            setFormAmount(prevState => ({
              ...prevState, // Spread the previous state
              [name]: value // Update the specific field based on the input's name attribute
            }));
          } else {
    
            if (billAmount.length > 0) {
              const amttt = parseFloat(total_netamount) - totalPaidAmount
              if (+amttt >= +value) {
                setFormAmount(prevState => ({
                  ...prevState, // Spread the previous state
                  [name]: value // Update the specific field based on the input's name attribute
                }));
              } else {
                alert(`enter the Correct value blow the Net Amount ${amttt}`)
                setFormAmount(prevState => ({
                  ...prevState, // Spread the previous state
                  [name]: '' // Update the specific field based on the input's name attribute
                }));
              }
            } else {
              if (+total_netamount >= +value) {
                setFormAmount(prevState => ({
                  ...prevState, // Spread the previous state
                  [name]: value // Update the specific field based on the input's name attribute
                }));
              } else {
                alert(`enter the Correct value blow the Net Amount ${total_netamount}`)
                setFormAmount(prevState => ({
                  ...prevState, // Spread the previous state
                  [name]: '' // Update the specific field based on the input's name attribute
                }));
              }
            }
    
          }
        } else {
          alert('No Balance Amount')
        }
    
    
    
    
      };


    const calculation = () => {
        // Initialize variables to store the accumulated values
        let totalUnits = 0;
        let totalAmount = 0;
        let totalDiscount = 0;
        let totalNetAmount = 0;
        let totalGstamount = 0;
        let totalItems = 0;

        // Iterate through the Data array using forEach
        Data.forEach((item) => {
            const units = item.Session || 0;
            totalUnits += units;
            totalAmount += +item.TotalAmount || 0;
            totalDiscount += +item.Discount || 0;
            totalGstamount += (+item.Total * +item.Gstchargep) / 100 || 0;
            totalNetAmount += (+item.Total) || 0;
            totalItems += 1;
        });

        // Create a new state object with the accumulated values
        const newState = {
            totalUnits,
            totalAmount,
            totalDiscount,
            totalNetAmount,
            totalGstamount,
            totalItems,
        };


        setinitialState({
            totalItems: newState.totalItems,
            totalUnits: newState.totalUnits,
            totalDiscount: newState.totalDiscount,
            totalGstamount: newState.totalGstamount,
            totalAmount: newState.totalAmount.toFixed(2),
            totalNetAmount: newState.totalNetAmount.toFixed(2),
        });
    }


    useEffect(() => {
        let totalUnits = 0;
        let totalAmount = 0;
        let totalDiscount = 0;
        let totalGstamount = 0;
        let totalNetAmount = 0;
        let totalItems = 0;

        // Iterate through the Data array using forEach
        Data.forEach((item) => {
            const units = item.Session || 0;

            totalUnits += units;
            totalAmount += +item.TotalAmount || 0;
            totalDiscount += +item.Discount || 0;
            totalGstamount += (+item.Total * +item.Gstchargep) / 100 || 0;
            totalNetAmount += +item.Total || 0;
            totalItems += 1;
        });

        // Create a new state object with the accumulated values
        const newState = {
            totalUnits,
            totalAmount,
            totalDiscount,
            totalGstamount,
            totalNetAmount,
            totalItems,
        };

        //    console.log('totalGstamount',totalGstcharge)
        setinitialState({
            totalItems: newState.totalItems,
            totalUnits: newState.totalUnits,
            totalDiscount: newState.totalDiscount,
            totalGstamount: newState.totalGstamount,
            totalAmount: newState.totalAmount.toFixed(2),
            totalNetAmount: newState.totalNetAmount.toFixed(2),
        });

    }, [Data.length, Data]);


    

    const handleAdd = () => {



        const req = [
          'Billpay_method',
          'paidamount'
        ]
        const missing = req.filter((row) => !formAmount[row])
        if (missing.length === 0) {
          const exist = billAmount.find((p) => p.Billpay_method === formAmount.Billpay_method)
          if (!exist) {
            setBillAmount((prev) => [...prev, formAmount]);
            setFormAmount({
              paidamount: '',
              Billpay_method: ''
            })
          } else {
            alert('The Payment Method already exist')
          }
        } else {
          alert(`enter the required fields : ${missing.join(',')}`)
        }
    
      };
    
      const handleEdit = (index) => {
        setIsEdit(index);
        const item = billAmount[index];
        setFormAmount({
          ...item
        });
      };
    
    
    
    
      const handleUpdate = () => {
        const req = [
          'Billpay_method',
          'paidamount'
        ]
        const missing = req.filter((row) => !formAmount[row])
        if (missing.length === 0) {
          const data = [...billAmount]
          data[isEdit] = formAmount
    
    
    
          setBillAmount(data);
          setFormAmount({
            paidamount: '',
            Billpay_method: ''
          })
          setIsEdit(null);
    
        } else {
          alert(`enter the required fields : ${missing.join(',')}`)
        }
    
      };
    
    




    const handleAmountSubmit = () => {

        const apiUrl = 'https://vesoftometic.co.in/GeneralBilling/overall_amount_table';
        const submissionData = {
          InvoiceNo: ClinicDetials.InvoiceNo, // Assuming ClinicDetails is accessible
          payments: billAmount, // Submitting the entire array
          location: userRecord?.location // Assuming userRecord is accessible
        };
    
        console.log('submissionData', submissionData);
    
        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionData),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            alert('Added Successfully');
        
            // setBillAmount([]);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

    // useEffect(() => {
    //     axios.get(`https://vesoftometic.co.in/GeneralBilling/get_overall_amount_table?InvoiceNo=${ClinicDetials.InvoiceNo}&location=${userRecord.location}`)
    //         .then((responce) => {
    //             console.log(responce)
    //             const data = responce.data
    //             setBillAmount(data)
    //         })
    //         .catch((error) => {
    //             console.log('errors', error)
    //         })
    // }, [ClinicDetials.InvoiceNo, paidamount, userRecord.location])

    console.log('ClinicDetials.InvoiceNo', billAmount);



    // const handleBillpaySessionChange = (index, value, rowsession) => {
    //     const newSession = rowsession.split(',')[1].split(' ')[4]

    //     if (+value <= +newSession) {
    //         const updatedData = [...Data];
    //         const data = updatedData[index]
    //         let amount = 0;
    //         let S_Amount = data.Charge;

    //         amount += S_Amount * +value

    //         updatedData[index].Session = +value;



    //         updatedData[index].Gstamount = amount * updatedData[index].Gstchargep / 100;

    //         updatedData[index].Total = amount + updatedData[index].Gstamount;

    //         console.log('S_Amount', S_Amount);
    //         setData(updatedData); // Assuming you have a state variable for Data
    //         calculation();
    //     } else {
    //         alert(`The Given Session is ${newSession} So Please Enter ${newSession} Units or below`)
    //     }

    // };
    const handleBillpayDiscount = (index, value, rowsession, discounttype) => {
        // Check if discount type is not selected
        if (!discounttype) {
            alert("Please select a payment type.");
            return; // Exit the function early if no discount type is selected
        }
        console.log(Data)

       
        const newSession = +rowsession;
        const updatedData = [...Data]; // Assuming Data is your state variable that holds the rows
        const data = updatedData[index];
        console.log('index',data);
        let S_Amount = data.TotalAmount;
        let discountValue = +value;
        let gstamount = 0;
    
        gstamount = (S_Amount * data.Gstchargep) / 100;
    
        if (discounttype === 'percentage') {
            discountValue = (S_Amount * +value) / 100;
        }
    
        // Ensure that the discount does not exceed the session amount
        if (discountValue <= newSession) {
            data.Discount = discountValue;
            const Total = S_Amount - discountValue;
            data.Charge = Total;
            data.Gstamount = (Total * data.Gstchargep) / 100;
            data.Total = Total + data.Gstamount;
    
            setData(updatedData); // Update your state variable assuming setData is your state updater function
            calculation(); // Recalculate totals or similar, assuming calculation is a function you've defined for recalculations
        } else {
            alert(`The Given Charge is ${newSession}. So, please enter a discount of ${newSession} or below.`);
        }
    };


    useEffect(() => {
        const currentDate = new Date();
        setBilling_date(format(currentDate, 'dd-MM-yyyy'))
        // console.log(SelectedPateintBilling.PatientID)
        axios.get(`https://vesoftometic.co.in/Bill_Cancellation/getallDueVisits/${SelectedPateintBilling.VisitID}/${SelectedPateintBilling.PatientID}`)
            .then((response) => {
                const Data = response.data;
                console.log(Data);

                // Assuming row.ServiceType can be "Procedure" or other values
                const newData = Data.map((row) => {
                    let charge, total,Gstchargep;

                    if (row.ServiceType === "Procedure") {
                        axios.get(`https://vesoftometic.co.in/doctorsworkbench/get_doctorname?patientid=${+SelectedPateintBilling.PatientID}&visitid=${+SelectedPateintBilling.VisitID}`)
                            .then((response) => {
                                console.log(response.data, '-----------------')
                                if (response.data.length !== 0) {
                                    const data = response.data[0].DoctorName
                                    console.log(data)
                                    setDoctorName((prev) => {
                                        // Check if the data (name) already exists in the array
                                        const isNameExists = prev.some((item) => item === data);

                                        if (!isNameExists) {
                                            // Add the data if it doesn't already exist
                                            return [...prev, data];
                                        }

                                        // Return the previous state unchanged if the data already exists
                                        return prev;
                                    });
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        // Handle Procedure specific calculations
                        const sessions = +row.RemainingSessions || 1; // Use a default of 1 if Sessions is not a valid number

                        const newamount = (+row.TotalAmount - +row.Discount) / sessions;
                        charge = newamount.toFixed(2)
                        const newtotal = +charge * row.RemainingSessions;
                        total = newtotal.toFixed(2)
                    } else {
                        // Handle other ServiceType specific calculations
                        charge = +row.Amount - +row.Discount;
                        total = +row.Amount * 1;
                        Gstchargep = +row.GstCharge;
                        setDoctorName((prev) => {
                            // Check if the data (name) already exists in the array
                            const isNameExists = prev.some((item) => item === row.DoctorName);

                            if (!isNameExists) {
                                // Add the data if it doesn't already exist
                                return [...prev, row.DoctorName];
                            }

                            // Return the previous state unchanged if the data already exists
                            return prev;
                        });
                        // Additional check to avoid division by zero
                        if (+row.Session === 0) {
                            charge = 0;
                            total = 0;
                        }
                    }

                    return {
                        ServiceType: row.ServiceType,
                        ServiceName: row.ServiceType !== "Procedure" ? row.DoctorName : row.ProcedureName + ', ( Session - ' + row.RemainingSessions + " )",
                        TotalAmount: +row.TotalAmount,
                        TherapistName: row.ServiceType === "Procedure" ? row.DoctorName : '',
                        Discount: +row.Discount,
                        Gstchargep: +row.GstCharge,
                        Charge: isNaN(charge) ? 0 : charge,
                        Session: +row.RemainingSessions || 1, // Use a default of 1 if Session is not a valid number
                        Total: isNaN(total) ? 0 : total,
                    };
                });

                // setDataIndex(Data.length - 1);
                setData(newData);
            })
            .catch((error) => {
                console.log(error);
            });


    axios.get(`https://vesoftometic.co.in/patientmanagement/get_communication_address_billing/${SelectedPateintBilling.PatientID}/${userRecord.location}`)
            .then((response) => {
                const data = response.data[0]
                console.log(data)
                setFormData({
                    PatientID: data.PatientID,
                    PhoneNumber: data.PhoneNumber,
                    RatecardType: data.RatecardType,
                    InsuranceName: data.InsuranceName,
                    ClientName: data.ClientName,
                    PatientName: data.FirstName + ' ' + data.LastName,
                    Age: data.Age,
                    City: data.City,
                    State: data.State,
                    Street: data.Street,
                    Pincode: data.Pincode,
                })
            })
            .catch((error) => {
                console.log(error)
            })
        // axios.get('https://vesoftometic.co.in/usercontrol/get_doctor_info')
        //     .then((response) => {
        //         const fetchedDoctors = response.data.map((doctor, index) => ({
        //             id: index,
        //             name: doctor,
        //         }));
        //         setDoctorOptions(fetchedDoctors);
        //     })
        //     .catch((error) => {
        //         console.error('Error fetching doctor information:', error);
        //     });

        // clinic detials
        axios.get(`https://vesoftometic.co.in/usercontrol/getClinic?location=${userRecord.location}`)
            .then((response) => {
                // console.log(response.data)
                const data = response.data[0]
                console.log(data)
                setClinicDetials((prev) => ({
                    ...prev,
                    ClinicAddress: data?.door_no || '' + ',' + data?.area || '' + "," + data?.street || '',
                    ClinicGST: data?.Gst_no || '',
                    ClinicCity: data?.city || '',
                    ClinicState: data?.state || '',
                    ClinicCode: data?.pincode || '',
                    ClinicMobileNo: data?.phone_no || '',
                    ClinicLandLineNo: data?.landline_no || '',
                    ClinicMailID: data?.email || '',
                }))
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get('https://vesoftometic.co.in/usercontrol/getAccountsetting')
            .then((response) => {
                // console.log(response.data.Data)
                const data = response.data;
                setClinicDetials((prev) => ({
                    ...prev,
                    ClinicName: data.Clinic_Name,
                    ClinicLogo: (`data:image/png;base64,${data.Clinic_Logo}`)
                }))
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get('https://vesoftometic.co.in/GeneralBilling/get_Overall_table_invoice')
            .then((response) => {
                console.log(response.data)
                const data = response.data;
                setClinicDetials((prev) => ({
                    ...prev,
                    InvoiceNo: data.nextInvoiceNumber
                }))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [SelectedPateintBilling.PatientID, SelectedPateintBilling.VisitID])

    useEffect(() => {
        if (Array.isArray(DoctorName)) {
            // console.log(DoctorName)
            const name = DoctorName.map(String).join(',');
            setDname(name);
        } else {
            console.error('DoctorName is not an array');
        }
    }, [DoctorName, DoctorName.length]);
    const handleBillpaySessionChange = (index, value, rowsession) => {
        const newSession = rowsession.split(',')[1].split(' ')[4]

        if (+value <= +newSession) {
            const updatedData = [...Data];
            const data = updatedData[index]

            let S_Amount = data.Charge;

            updatedData[index].Session = +value;

            updatedData[index].Total = S_Amount * + value;


            setData(updatedData); // Assuming you have a state variable for Data
            calculation();
        } else {
            alert(`The Given Session is ${newSession} So Please Enter ${newSession} Units or below`)
        }

    };
    console.log(Data, '====')

    // const handleAddDoctor = () => {

    //     if (SelectedDoctorName.ServiceName !== '' && SelectedDoctorName.ServiceType !== '') {
    //         let ratecardType;
    //         switch (FormData.RatecardType) {
    //             case 'Insurance':
    //                 ratecardType = FormData.InsuranceName;
    //                 axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_Insurance_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                     .then((response) => {
    //                         const data = response.data.data[0];

    //                         const updatedData = {
    //                             ServiceType: SelectedDoctorName.ServiceType,
    //                             ServiceName: SelectedDoctorName.ServiceName,
    //                             TotalAmount: +data.Charge,
    //                             Discount: 0,
    //                             Charge: +data.Charge,
    //                             Session: 1,
    //                             Total: +data.Charge,
    //                         };

    //                         const existingDoctorIndex = Data.some(
    //                             (item) =>
    //                                 item.ServiceType === SelectedDoctorName.ServiceType &&
    //                                 item.ServiceName === SelectedDoctorName.ServiceName
    //                         );

    //                         if (!existingDoctorIndex) {
    //                             if (SelectedDoctorName.ServiceType !== 'Procedure') {
    //                                 setDoctorName((prev) => {
    //                                     // Check if the data (name) already exists in the array
    //                                     const isNameExists = prev.some((item) => item === SelectedDoctorName.ServiceName);

    //                                     if (!isNameExists) {
    //                                         // Add the data if it doesn't already exist
    //                                         return [...prev, SelectedDoctorName.ServiceName];
    //                                     }

    //                                     // Return the previous state unchanged if the data already exists
    //                                     return prev;
    //                                 });
    //                             }


    //                             setData((prev) => [...prev, updatedData]);
    //                             setSelectedDoctorName({
    //                                 ServiceType: '',
    //                                 ServiceName: '',
    //                                 Session: 1,
    //                                 Amount: "",
    //                                 Discount: 0,
    //                                 Total: "",
    //                             });
    //                             setIsedit(false);
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         console.log(error);
    //                     });
    //                 break;
    //             case 'Client':
    //                 ratecardType = FormData.ClientName;
    //                 axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_client_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                     .then((response) => {
    //                         const data = response.data.data[0];

    //                         const updatedData = {
    //                             ServiceType: SelectedDoctorName.ServiceType,
    //                             ServiceName: SelectedDoctorName.ServiceName,
    //                             TotalAmount: +data.Charge,
    //                             Discount: 0,
    //                             Charge: +data.Charge,
    //                             Session: 1,
    //                             Total: +data.Charge,
    //                         };

    //                         const existingDoctorIndex = Data.some(
    //                             (item) =>
    //                                 item.ServiceType === SelectedDoctorName.ServiceType &&
    //                                 item.ServiceName === SelectedDoctorName.ServiceName
    //                         );

    //                         if (!existingDoctorIndex) {
    //                             if (SelectedDoctorName.ServiceType !== 'Procedure') {
    //                                 setDoctorName((prev) => {
    //                                     // Check if the data (name) already exists in the array
    //                                     const isNameExists = prev.some((item) => item === SelectedDoctorName.ServiceName);

    //                                     if (!isNameExists) {
    //                                         // Add the data if it doesn't already exist
    //                                         return [...prev, SelectedDoctorName.ServiceName];
    //                                     }

    //                                     // Return the previous state unchanged if the data already exists
    //                                     return prev;
    //                                 });
    //                             }
    //                             setData((prev) => [...prev, updatedData]);
    //                             setSelectedDoctorName({
    //                                 ServiceType: '',
    //                                 ServiceName: '',
    //                                 Session: 1,
    //                                 Amount: "",
    //                                 Discount: 0,
    //                                 Total: "",
    //                             });
    //                             setIsedit(false);
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         console.log(error);
    //                     });
    //                 break;
    //             default:
    //                 ratecardType = FormData.RatecardType;
    //                 axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_Service_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                     .then((response) => {
    //                         const data = response.data.data[0];
    //                         console.log(response)

    //                         const updatedData = {
    //                             ServiceType: SelectedDoctorName.ServiceType,
    //                             ServiceName: SelectedDoctorName.ServiceName,
    //                             TotalAmount: +data.Charge,
    //                             Discount: 0,
    //                             Charge: +data.Charge,
    //                             Session: 1,
    //                             Total: +data.Charge,
    //                         };

    //                         const existingDoctorIndex = Data.some(
    //                             (item) =>
    //                                 item.ServiceType === SelectedDoctorName.ServiceType &&
    //                                 item.ServiceName === SelectedDoctorName.ServiceName
    //                         );

    //                         if (!existingDoctorIndex) {
    //                             if (SelectedDoctorName.ServiceType !== 'Procedure') {
    //                                 setDoctorName((prev) => {
    //                                     // Check if the data (name) already exists in the array
    //                                     const isNameExists = prev.some((item) => item === SelectedDoctorName.ServiceName);

    //                                     if (!isNameExists) {
    //                                         // Add the data if it doesn't already exist
    //                                         return [...prev, SelectedDoctorName.ServiceName];
    //                                     }

    //                                     // Return the previous state unchanged if the data already exists
    //                                     return prev;
    //                                 });
    //                             }
    //                             setData((prev) => [...prev, updatedData]);
    //                             setSelectedDoctorName({
    //                                 ServiceType: '',
    //                                 ServiceName: '',
    //                                 Session: 1,
    //                                 Amount: "",
    //                                 Discount: 0,
    //                                 Total: "",
    //                             });
    //                             setIsedit(false);
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         console.log(error);
    //                     });
    //         }
    //     } else {
    //         alert('Please enter the Service Name  and Service Type');
    //     }



    // };


    // const handleUpdateDoctor = () => {
    //     if (SelectedDoctorName.ServiceName !== '' && SelectedDoctorName.ServiceType !== '') {
    //         let ratecardType;
    //         if (FormData.RatecardType === 'Insurance') {
    //             ratecardType = FormData.InsuranceName;
    //             axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_Insurance_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                 .then((response) => {
    //                     const data = response.data.data[0];

    //                     const updatedData = {
    //                         ServiceType: SelectedDoctorName.ServiceType,
    //                         ServiceName: SelectedDoctorName.ServiceName,
    //                         TotalAmount: +data.Charge,
    //                         Discount: 0,
    //                         Charge: +data.Charge,
    //                         Session: 1,
    //                         Total: +data.Charge,
    //                     };



    //                     setDoctorName((prev) => {
    //                         const updated = [...prev];
    //                         updated[IsIndex] = SelectedDoctorName.ServiceName;
    //                         return updated;
    //                     });

    //                     setData((prev) => {
    //                         const updatedDoctor = [...prev];
    //                         updatedDoctor[IsIndex] = updatedData;
    //                         return updatedDoctor;
    //                     });

    //                     setSelectedDoctorName({
    //                         ServiceType: '',
    //                         ServiceName: '',
    //                         Session: 1,
    //                         Amount: "",
    //                         Discount: 0,
    //                         Total: "",
    //                     });
    //                     setIsedit(false);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //         } else if (FormData.RatecardType === 'Client') {
    //             ratecardType = FormData.ClientName;
    //             axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_cllient_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                 .then((response) => {
    //                     const data = response.data.data[0];

    //                     const updatedData = {
    //                         ServiceType: SelectedDoctorName.ServiceType,
    //                         ServiceName: SelectedDoctorName.ServiceName,
    //                         TotalAmount: +data.Charge,
    //                         Discount: 0,
    //                         Charge: +data.Charge,
    //                         Session: 1,
    //                         Total: +data.Charge,
    //                     };



    //                     setDoctorName((prev) => {
    //                         const updated = [...prev];
    //                         updated[IsIndex] = SelectedDoctorName.ServiceName;
    //                         return updated;
    //                     });

    //                     setData((prev) => {
    //                         const updatedDoctor = [...prev];
    //                         updatedDoctor[IsIndex] = updatedData;
    //                         return updatedDoctor;
    //                     });

    //                     setSelectedDoctorName({
    //                         ServiceType: '',
    //                         ServiceName: '',
    //                         Session: 1,
    //                         Amount: "",
    //                         Discount: 0,
    //                         Total: "",
    //                     });
    //                     setIsedit(false);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //         } else {
    //             ratecardType = FormData.RatecardType;
    //             axios.get(`https://vesoftometic.co.in/usercontrol/get_RateCard_Service_Charge?servicetype=${SelectedDoctorName.ServiceType}&servicename=${SelectedDoctorName.ServiceName}&ratecardtype=${ratecardType}`)
    //                 .then((response) => {
    //                     const data = response.data.data[0];

    //                     const updatedData = {
    //                         ServiceType: SelectedDoctorName.ServiceType,
    //                         ServiceName: SelectedDoctorName.ServiceName,
    //                         TotalAmount: +data.Charge,
    //                         Discount: 0,
    //                         Charge: +data.Charge,
    //                         Session: 1,
    //                         Total: +data.Charge,
    //                     };



    //                     setDoctorName((prev) => {
    //                         const updated = [...prev];
    //                         updated[IsIndex] = SelectedDoctorName.ServiceName;
    //                         return updated;
    //                     });

    //                     setData((prev) => {
    //                         const updatedDoctor = [...prev];
    //                         updatedDoctor[IsIndex] = updatedData;
    //                         return updatedDoctor;
    //                     });

    //                     setSelectedDoctorName({
    //                         ServiceType: '',
    //                         ServiceName: '',
    //                         Session: 1,
    //                         Amount: "",
    //                         Discount: 0,
    //                         Total: "",
    //                     });
    //                     setIsedit(false);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //         }


    //     } else {
    //         alert('Please enter the Service Name  and Service Type');
    //     }
    // };

    // const handleDoctorEdit = (index) => {
    //     const datas = Data[index]
    //     const Data1 = {
    //         ServiceType: datas.ServiceType,
    //         ServiceName: datas.ServiceName
    //     }
    //     setSelectedDoctorName(Data1);
    //     setIsIndex(index);
    //     setIsedit(true)
    // }
    // const handleDeleteDoctor = (index) => {
    //     const updatedDoctorData = Data.filter((item, i) => i !== index);
    //     const updatedDoctorNames = DoctorName.filter((name, i) => i !== index);

    //     setData(updatedDoctorData);
    //     setDoctorName(updatedDoctorNames);
    // };


    const Submitalldata = () => {

        const formdata = {
            DefuldInvoicenumber: ClinicDetials.InvoiceNo,
            Billing_date: Billing_date,
            Doctor_name: Dname,
            PatientId: SelectedPateintBilling.PatientID,
            VisitId: SelectedPateintBilling.VisitID,
            PatientName: FormData.PatientName,
            PatientAge: FormData.Age,
            PatientAddress: FormData.Street,
            BilledBy: userRecord.username,
            location: userRecord.location,
            City: FormData.City,
            State: FormData.State,
            Pincode: FormData.Pincode,
            PhoneNumber: FormData.PhoneNumber,
            items: initialState.totalItems,
            unit: initialState.totalUnits,
            Amount: initialState.totalAmount,
            CashDiscount: initialState.totalDiscount,
            Net_Amount: initialState.totalNetAmount,
            Payment_method: formAmount.Billpay_method,
            Amount_in_Words: numberToWords(Math.round(initialState.totalNetAmount * 100) / 100),
        }
        const Data1 = {
            DefuldInvoicenumber: ClinicDetials.InvoiceNo,
            Billing_itemtable: Data.map((p) => {
                if (p.ServiceType === 'Procedure') {
                    const newName = p.ServiceName.split(',');
                    return {
                        ProcedureName: newName[0],
                        PhysicianName: p.TherapistName,
                        ServiceType: p.ServiceType,
                        Charge: +p.Charge,
                        Unit: p.Session,
                        Amount: +p.TotalAmount,
                        Discount: p.Discount,
                        Total_Amount: p.Total,
                    };
                } else {
                    return {
                        ProcedureName: "Null",
                        PhysicianName: p.ServiceName,
                        ServiceType: 'DoctorConsultation',
                        Charge: +p.Charge,
                        Unit: p.Session,
                        Amount: +p.TotalAmount,
                        Discount: p.Discount,
                        Total_Amount: p.Total,
                    };
                }
            }),
        };
        console.log(Data1)
        const A_datas = Data.filter((p) => p.ServiceType === 'Procedure');

        const B_data = A_datas.map((p) => {
            const NewName = p.ServiceName.split(',');

            return {
                DoctorName: p.TherapistName, // Assuming Dname is defined elsewhere
                PatientID: SelectedPateintBilling.PatientID,
                VisitID: SelectedPateintBilling.VisitID,
                ProcedureName: NewName[0],
                PaidSessions: p.Session
            };
        });

        console.log(B_data)
        // const doctorData = {
        //     PatientID: SelectedPateintBilling.PatientID,
        //     VisitID: SelectedPateintBilling.VisitID,
        //     DoctorName: 'Naresh Trehan',
        //     Status: 'Paid',
        // };




        if (billAmount.length !== 0) {
            console.log(formdata)
            console.log(Data1)
            axios.post('https://vesoftometic.co.in/GeneralBilling/Post_overallBilling_table', formdata)
                .then((response) => {
                    console.log(response)
                    axios.post('https://vesoftometic.co.in/GeneralBilling/Post_overallbilling_Items_table', Data1)
                        .then((response) => {
                            console.log(response)
                            Data.forEach((p) => {
                                if (p.ServiceType === 'Procedure') {
                                    axios.post('https://vesoftometic.co.in/GeneralBilling/update_sessions', B_data)
                                        .then((response) => {
                                            console.log(response)
                                            handleAmountSubmit()
                                            setIsPrintButtonVisible(false);
                                            setTimeout(() => {
                                                window.print();
                                                setIsPrintButtonVisible(true);
                                                navigate('/Billing-Invoice');
                                            }, 1000);
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                } else {
                                    const doctorData1 = {
                                        PatientID: SelectedPateintBilling.PatientID,
                                        VisitID: SelectedPateintBilling.VisitID,
                                        DoctorName: p.ServiceName,
                                        Status: 'Paid',
                                    };
                                    axios.post('https://vesoftometic.co.in/GeneralBilling/update_Consultancy_Status', doctorData1)
                                        .then((response) => {
                                            console.log(response);
                                            handleAmountSubmit()
                                            setIsPrintButtonVisible(false);
                                            setTimeout(() => {
                                                window.print();
                                                setIsPrintButtonVisible(true);
                                                navigate('/Billing-Invoice');
                                            }, 1000);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }

                            })

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            alert('Please select the Payment Method')
        }
    }



    const forPrintData = () => {
        return (
            <div className="billing-invoice">
                <div className="New_billlling_invoice_head">
                    <div className="new_billing_logo_con">
                        <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                    </div>
                    <div className="new_billing_address_1 ">
                        <span>{ClinicDetials.ClinicName}</span>
                        <div>

                            <span >{ClinicDetials.ClinicAddress},</span>
                            <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                        </div>
                        <div>
                            <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicMailID}</span>
                        </div>

                    </div>

                </div>
                <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
                    Billing Invoice
                </div>
                <div className="new_billing_address">
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>Patient Name <span>:</span></label>
                            <span>{FormData.PatientName}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Patient ID <span>:</span></label>
                            <span>{FormData.PatientID}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Age <span>:</span></label>
                            <span>{FormData.Age}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Gender <span>:</span></label>
                            <span>{FormData.Gender}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Address <span>:</span></label>
                            <span>{FormData.City}</span>
                        </div>

                    </div>
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>Invoice No <span>:</span></label>
                            <span>{ClinicDetials.InvoiceNo}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>GSTIN No <span>:</span></label>
                            <span>{ClinicDetials.ClinicGST}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Physician Name <span>:</span></label>
                            <span>{Dname}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Date <span>:</span></label>
                            <span>{Billing_date}</span>
                        </div>
                        <div className="new_billing_div">
                            <label> Patient Mobile No <span>:</span></label>
                            <span>{FormData.PhoneNumber}</span>
                        </div>
                    </div>
                </div>


                <div className="new_billing_invoice_detials">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Service Type</th>
                                <th>Service Name</th>
                                <th>Unit</th>
                                <th>Amount</th>
                                <th>Discount</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>

                            {Data.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.ServiceType}</td>
                                        <td>{row.ServiceName}</td>
                                        <td>{row.Session}</td>
                                        <td>{row.Charge}</td>
                                        <td>{row.Discount}</td>
                                        <td>{row.Total}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: 'auto' }}>

                    <div className="invoice_detials_total_1 neww_invoicedetials">
                        <div className="total_con_bill">
                            <div className="bill_body">
                                <label> Items <span>:</span></label>
                                <span>{initialState.totalItems}</span>
                            </div>
                            <div className="bill_body">
                                <label> Unit <span>:</span></label>
                                <span>{initialState.totalUnits}</span>
                            </div>
                            <div className="bill_body">
                                <label> Amount <span>:</span></label>
                                <span>{initialState.totalAmount}</span>
                            </div>
                            <div className="bill_body">
                                <label> Billed By <span>:</span></label>
                                <span>{userRecord.username}</span>
                            </div>
                        </div>
                        <div className="total_con_bill">

                            <div className="bill_body">
                                <label> SGST <span>:</span></label>
                                <span>{(initialState.totalGstamount)/2} </span>
                            </div>
                            <div className="bill_body">
                                <label> CGST <span>:</span></label>
                                <span>{(initialState.totalGstamount)/2} </span>
                            </div>
                            

                              
                            {billAmount.map((row, index) => (
                            <div key={index} className="bill_body">
                  {/* <div className="item-index">{index + 1}</div> */}
                            <label >{row.Billpay_method}<span>:</span></label>
                            <span  >{row.paidamount}</span>
                            </div>
                            ))}
                          
                            <div className="bill_body">
                                <label>Net Amount <span>:</span></label>
                                <span>{initialState.totalNetAmount}</span>
                            </div>

                        </div>
                    </div>
                    <div className="invoice_detials_total_1 neww_invoicedetials">
                        <div className='total_con_bill'>
                            <div className="bill_body" style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
                                <label> Amount In Words<span>:</span></label>
                                <span style={{ color: 'grey' }}>{numberToWords(+initialState.totalNetAmount)} </span>
                            </div>
                        </div>
                    </div>
                    <div className="signature-section909">
                        <p className='disclaimer23'>
                            This page is created automatically without a signature.
                        </p>
                    </div>
                </div>

            </div>
        );
    }

    return (
        <>
            {isPrintButtonVisible ?
                <div>
                    <Helmet>
                        <title>Billing Invoice</title>
                    </Helmet>
                    <div className="QuickStock_container">

                        <div className="QuickStock_container_header">
                            <h3>
                                <ShoppingCartIcon /> Billing Invoice
                            </h3>
                        </div>

                        <div >

                            <div className='Billing_Invoice_header'>
                                <h1>MANUAL INVOICE</h1>
                            </div>

                            <div className='invoice_firstpart'>
                                <div className="QuickStock_container_form" style={{ justifyContent: "center" }}>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Invoice No:
                                        </label>
                                        <input
                                            type="text"
                                            value={ClinicDetials.InvoiceNo}
                                            name="DefuldInvoicenumber"
                                            readOnly
                                            className="Inventory-2"
                                        />

                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Date:
                                        </label>
                                        <input
                                            type='text'
                                            name="Billing_date"
                                            className="Inventory-2"
                                            value={Billing_date}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Physician Name:
                                        </label>
                                        <input
                                            list="DoctorNames"
                                            name="Doctor_name"
                                            id="browser1"
                                            className="Inventory-2"
                                            value={Dname}
                                            readOnly
                                        // onChange={(e) => handleInputChange('Doctor_name', e.target.value)}
                                        />
                                        <datalist id="DoctorNames">
                                            {/* {Doctorsnames.map((item, index) => (
                                                <option key={index} value={item.name}></option>
                                            ))} */}
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                            <div className='invoice_Secondpart'>

                                <h3 style={{ height: "50px" }}>
                                    Patient Detailes:
                                </h3>


                                <div className="QuickStock_container_form">

                                    <div className="QuickStock_container_div" >
                                        <label className="label_classname" htmlFor="get_PatientId">
                                            Patient Id:
                                        </label>

                                        <input
                                            type="text"
                                            Value={FormData.PatientID}
                                            readOnly
                                            name="PatientId"
                                            className="Inventory-2"
                                            list="get_PatientId"
                                        // onChange={(e) => handleInputChange('PatientId', e.target.value)}
                                        />

                                        <datalist id="get_PatientId">
                                            {/* {Patient_list.map((item, index) => (
                                                <option key={item.PatientID} value={item.PatientID}></option>
                                            ))} */}
                                        </datalist>

                                        {/* <button className='searching_input_icon' onClick={Fetch_ONEPatient_data} >
                                            <SearchIcon />
                                        </button> */}

                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Patient Name:
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter Patient Name"
                                            Value={FormData.PatientName}
                                            readOnly
                                            name="PatientName"
                                            className="Inventory-2"
                                            list="FirstName_PatientId"
                                        // onChange={(e) => handleInputChange('PatientName', e.target.value)}
                                        />
                                        <datalist id="FirstName_PatientId">
                                            {/* {Patient_list.map((item, index) => (
                                                <option key={item.PatientID} value={item.FirstName}>
                                                    {item.FirstName}
                                                </option>
                                            ))} */}
                                        </datalist>
                                        {/* <span className='searching_input_icon' onClick={Fetch_ONEPatient_data} >
                                            <SearchIcon />
                                        </span> */}
                                    </div>


                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Patient Age:
                                        </label>
                                        <input
                                            type="number"
                                            name="PatientAge"
                                            className="Inventory-2"
                                            placeholder="Enter Patient Age"
                                            Value={FormData.Age}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Patient Address:
                                        </label>
                                        <input
                                            name="PatientAddress"
                                            type="text"
                                            className="Inventory-2"
                                            placeholder="Enter Patient Address"
                                            Value={FormData.Street}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            City:
                                        </label>
                                        <input
                                            name="City"
                                            type="text"
                                            className="Inventory-2"
                                            placeholder="Enter City"
                                            Value={FormData.City}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            State:
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter State"
                                            className="Inventory-2"
                                            Value={FormData.State}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Pincode:
                                        </label>
                                        <input
                                            type="number"
                                            placeholder="Enter Pincode"
                                            className="Inventory-2"
                                            Value={FormData.Pincode}
                                            readOnly
                                        />
                                    </div>

                                    <div className="QuickStock_container_div">
                                        <label className="label_classname" htmlFor="itemCode">
                                            Phone Number:
                                        </label>
                                        <input
                                            type="number"
                                            className="Inventory-2"
                                            list="Phone_Number"
                                            Value={FormData.PhoneNumber}
                                            readOnly
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className='invoice_Secondpart'>

                                {/* <div className="custom-header">
                                    <h4>Doctor Consultancy</h4>
                                </div> */}
                                {/* <div className="con_1 ">

                                    <div className="inp_1">
                                        <label htmlFor="Title">Doctor Name <span>:</span></label>
                                        <select
                                            name="DoctorName"
                                            value={SelectedDoctorName?.ServiceName || ''}
                                            onChange={(e) => {
                                                setSelectedDoctorName((prev) => ({
                                                    ...prev,
                                                    ServiceName: e.target.value,
                                                    ServiceType: 'DoctorConsultation'
                                                }));
                                            }}
                                        >
                                            <option value="">Select Doctor</option>
                                            {doctorOptions.map((doctor) => (
                                                <option key={doctor.id} value={doctor.name}>
                                                    {doctor.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button className="RegisterForm_1_btns" onClick={isedit ? handleUpdateDoctor : handleAddDoctor}>
                                        {isedit ? 'Update' : 'Add'}
                                    </button>

                                </div> */}
                                {/* <div className="RegisFormcon RegisFormcon1">
                                    <div className="RegisForm_1">
                                        <label>Service Type<span>:</span> </label>
                                        <select
                                            name="ServiceType"
                                            value={SelectedDoctorName?.ServiceType || ''}
                                            onChange={(e) => {
                                                setSelectedDoctorName({
                                                    ServiceName: '',
                                                    ServiceType: e.target.value,
                                                });
                                            }}

                                        >
                                            <option value="">select</option>
                                            <option value="DoctorConsultation">Doctor Consultation</option>
                                            <option value="Procedure">Procedure</option>
                                        </select>
                                    </div>
                                    {SelectedDoctorName?.ServiceType === 'Procedure' ? (
                                        <div className="RegisForm_1">
                                            <label> Procedure Name<span>:</span> </label>
                                            <input
                                                type="text"
                                                placeholder="Enter the General Charge"
                                                name="ProcedureName"
                                                value={SelectedDoctorName?.ServiceName || ''}
                                                onChange={(e) => {
                                                    setSelectedDoctorName((prev) => ({
                                                        ...prev,
                                                        ServiceName: e.target.value,
                                                    }));
                                                }}
                                                required
                                            />
                                        </div>
                                    ) : (
                                        <div className="RegisForm_1">
                                            <label htmlFor="Title">Doctor Name <span>:</span></label>
                                            <select
                                                name="DoctorName"
                                                value={SelectedDoctorName?.ServiceName || ''}
                                                onChange={(e) => {
                                                    setSelectedDoctorName((prev) => ({
                                                        ...prev,
                                                        ServiceName: e.target.value,
                                                    }));
                                                }}
                                            >
                                                <option value="">Select Doctor</option>
                                                {doctorOptions.map((doctor) => (
                                                    <option key={doctor.id} value={doctor.name}>
                                                        {doctor.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    <div className="Register_btn_con">
                                        <button className="RegisterForm_1_btns" onClick={isedit ? handleUpdateDoctor : handleAddDoctor}>
                                            {isedit ? 'Update' : 'Add'}
                                        </button>
                                    </div>
                                </div> */}

                                <div className="invoice-details">
                                    <table>
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>Service Type</th>
                                                <th>Service Name</th>
                                                <th>Total Amount </th>
                                                <th>GST Charge</th>
                                                <th>Discount Type</th>
                                                <th>Discount</th>
                                                <th>Charge </th>
                                                <th>Unit</th>
                                                <th>Net Amount</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                Data.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.ServiceType}</td>
                                                            <td>{row.ServiceName}</td>
                                                            <td>{row.TotalAmount}</td>
                                                            <td>{row.Gstchargep}</td>
                                                            <td>
                                                                <select className="" onChange={(e) => setDiscounttype(e.target.value)}>
                                                                    <option value=''>Select</option>
                                                                    <option value='percentage'>Percentage</option>
                                                                    <option value='amount'>Amount</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    value={row.Discounts}
                                                                    onChange={(e) => handleBillpayDiscount(index, e.target.value, row.TotalAmount, discounttype)}
                                                                />
                                                            </td>
                                                            <td>{row.Charge}</td>
                                                            {row.ServiceType === 'Procedure' ?
                                                                <td>
                                                                    <input type="number" value={row.Session}
                                                                        onChange={(e) => handleBillpaySessionChange(index, e.target.value, row.ServiceName)}
                                                                    />
                                                                </td>
                                                                :
                                                                <td>{row.Session}</td>
                                                            }
                                                            <td>{row.Total}</td>

                                                        </tr>
                                                    )
                                                })
                                            }


                                        </tbody>
                                    </table>


                                </div>
                                <div className="summary-container">
                                    <div className='itm-flx-wth'>
                                        <div className='clm-itm-stl'>
                                            <label>
                                                ITEMS:
                                            </label>
                                            <input value={initialState.totalItems} readOnly />
                                        </div>
                                        <div className='clm-itm-stl'>
                                            <label>
                                                Unit:
                                            </label>
                                            <input value={initialState.totalUnits} readOnly />
                                        </div>


                                        <div className='clm-itm-stl'>
                                            <label>
                                                AMOUNT:
                                            </label>
                                            <input value={initialState.totalAmount} readOnly />
                                        </div>
                                    </div>

                                    <div className='itm-flx-wth'>

                                        <div className='clm-itm-stl'>
                                            <label>
                                                Cash Discount :
                                            </label>
                                            <input value={initialState.totalDiscount} readOnly />
                                        </div>

                                        <div className='clm-itm-stl'>
                                            <label>
                                                Net Amount :
                                            </label>
                                            <input value={initialState.totalNetAmount} readOnly />
                                        </div>

                                    </div>

                                    <div className='pymt-flx-with'>
                                        <div className='itm-flx-wth flx_the_paymtmeth'>
                                            <div className='clm-itm-stl'>
                                                <label>
                                                    Payment Method:
                                                </label>
                                                <select
                                                    name="Billpay_method"
                                                    value={formAmount.Billpay_method}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Card">Card</option>
                                                    <option value="OnlinePayment">Online Payment</option>
                                                    <option value="Cheque">Cheque</option>
                                                </select>
                                            </div>


                                            <div className='clm-itm-stl'>
                                                <label>
                                                    Cash Amount :
                                                </label>
                                                <input
                                                    type='number'
                                                    name='paidamount'
                                                    value={formAmount.paidamount}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>




                                        <div className='pymt-flx-with'>
                                            <div className='clm-itm-stl'>
                                                <button className="btncon_add prs-ad-sub-btn" onClick={isEdit !== null ? handleUpdate : handleAdd}>
                                                    {isEdit !== null ? "Update" : "Add"}
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="invoice-details">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Payment Type</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {billAmount.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.Billpay_method}</td>
                                                        <td>{row.paidamount}</td>
                                                        <td>
                                                            <button onClick={() => handleEdit(row, index)}>
                                                                <EditNoteIcon />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>




                                    <div>
                                        <p>Amount in Words : {''}
                                            <span style={{ color: '#808080b5' }}>{numberToWords(+initialState.totalNetAmount)} </span>
                                        </p>
                                    </div>


                                </div>



                            </div>


                        </div>

                        {
                            <div className="Billing_btn">
                                <button className='btn_1 '
                                    onClick={Submitalldata}
                                >Print</button>
                            </div>

                        }

                    </div>
                </div>
                :
                forPrintData()
            }
        </>
    )
}

export default DeuHistory;