import React, { useState, useEffect } from "react";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { useDispatch, useSelector } from "react-redux";
import IpNurseVitals from "./IpNurseVitals";
import { useNavigate } from "react-router-dom";
import IpIntakeOutput from "./IpIntakeOutput";
import IpDrugAdminister from "./IpDrugAdminister";
import LabReport from "../../TreatmentRecords/LabReport";
import IpLabTest from "./IpLabTest";
import IpLeninChange from "./IpLeninChange";
import TheatreBooking from "../Operationtheatre/TheatreBooking";
import IpWardChange from "./IpWardChange";
import MedicalHistoryForm2 from "./MedicalHistoryForm2";
import MedicalHistoryForm from "./MedicalHistoryForm";
import femalpatient from "../../assets/femalpatient.png";
import malepatient from "../../assets/malepatient.png";
import IpNurseDrugRequest from "./IpNurseDrugRequest";
import Treatments from "./Treatments";
import ProgressNotes from "./ProgressNotes";
import axios from "axios";

function IpNurseWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("vitalForm");
    const [isToggled, setIsToggled] = useState(false);
    const [Patientphoto, setPatientphoto] = useState(null);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/IpNurseQuelist')
        } else {


            axios.get(`${UrlLink}ipregistration/get_patient_photo?Bookingid=${IpNurseQueSelectedRow?.BookingId}`)
                .then((res) => {
                    console.log(res);
                    setPatientphoto(res.data)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">


                <br />
                <div className="dctr_info_up_head">
                    <div className="RegisFormcon_imgcon ">
                        <div className="dctr_info_up_head22">
                            {Patientphoto && Patientphoto?.Photo ? (
                                <img src={Patientphoto.Photo} alt="Patient Photo" />
                            ) : (
                                <img src={Patientphoto?.Gender === 'Female' ? femalpatient : malepatient} alt="Default Patient Photo" />
                            )}

                            <label>Profile</label>
                        </div>
                    </div>

                    <div className="RegisFormcon">
                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                Patient Name / ID <span>:</span>{" "}
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.PatientName || '-'} / ${IpNurseQueSelectedRow?.PatientId}`}
                            </span>
                        </div>

                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                Bed No / MRN No <span>:</span>{" "}
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.BookingId}`}
                            </span>
                        </div>
                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                POA <span>:</span>
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.AdmissionPurpose}`}
                            </span>
                        </div>
                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                DOA <span>:</span>{" "}
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.AdmitDate}`}
                            </span>
                        </div>

                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                Primary Doctor <span>:</span>{" "}
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.PrimaryDoctor || '-'}`}
                            </span>
                        </div>
                        <div className="RegisForm_1 ">
                            <label htmlFor="FirstName">
                                Primary Symptoms<span>:</span>{" "}
                            </label>

                            <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                                {`${IpNurseQueSelectedRow?.PrimaryDisease || '-'}`}
                            </span>
                        </div>
                    </div>
                </div>
                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'MedicalHistory' || activeTab === 'SurgicalHistory' ? "white" : '' }}>Medical Records</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                                        Medical History
                                    </button>
                                    <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                        Surgical History
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button
                                onClick={() => handleTabChange("ProgressNotes")}
                                style={{ color: activeTab === "ProgressNotes" ? "white" : "" }}
                            >
                                Progress Notes
                            </button>
                            |
                            <button
                                onClick={() => handleTabChange("Treatments")}
                                style={{ color: activeTab === "Treatments" ? "white" : "" }}
                            >
                                Treatments
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                        Lenin Change
                                    </button>
                                    <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                        OT Booking
                                    </button>
                                    <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                        Ward/Bed Change
                                    </button>
                                    {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                    <button onClick={() => handleTabChange("DrugRequest")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Drug Request
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                                        <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                            Vital Form
                                        </button>
                                        |
                                        <div class="Lab_dropdown">
                                            <button class="Lab_button" style={{ color: activeTab === 'MedicalHistory' || activeTab === 'SurgicalHistory' ? "white" : '' }}>Medical Records</button>
                                            <div class="Lab_dropdown_content">
                                                <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                                                    Medical History
                                                </button>
                                                <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                                    Surgical History
                                                </button>
                                            </div>
                                        </div>
                                        |
                                        <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                            Intake/OutPut
                                        </button>
                                        |
                                        <button
                                            onClick={() => handleTabChange("ProgressNotes")}
                                            style={{ color: activeTab === "ProgressNotes" ? "white" : "" }}
                                        >
                                            Progress Notes
                                        </button>
                                        |
                                        <button
                                            onClick={() => handleTabChange("Treatments")}
                                            style={{ color: activeTab === "Treatments" ? "white" : "" }}
                                        >
                                            Treatments
                                        </button>
                                        |
                                        <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                            Drug Administration
                                        </button>
                                        |
                                        <div class="Lab_dropdown">
                                            <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                            <div class="Lab_dropdown_content">
                                                <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                                    Lenin Change
                                                </button>
                                                <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                                    OT Booking
                                                </button>
                                                <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                                    Ward/Bed Change
                                                </button>
                                                {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                                <button onClick={() => handleTabChange("DrugRequest")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                                    Drug Request
                                                </button>
                                            </div>
                                        </div>
                                        |
                                        <div class="Lab_dropdown">
                                            <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                            <div class="Lab_dropdown_content">
                                                <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                                    Lab Test
                                                </button>
                                                <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                                    Lab Test Preview
                                                </button>
                                            </div>
                                        </div>

                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <IpNurseVitals />}
            {activeTab === "MedicalHistory" && <MedicalHistoryForm />}
            {activeTab === "SurgicalHistory" && <MedicalHistoryForm2 />}
            {activeTab === "Intake/OutPut" && <IpIntakeOutput />}
            {activeTab === "DrugAdministration" && <IpDrugAdminister />}
            {activeTab === "Lab" && <IpLabTest />}
            {activeTab === "LabReport" && <LabReport />}
            {activeTab === "Lenin" && <IpLeninChange />}
            {activeTab === "OT_Booking" && <TheatreBooking />}
            {activeTab === "Ward/Bed" && <IpWardChange />}
            {activeTab === "DrugRequest" && <IpNurseDrugRequest />}
            {activeTab === "ProgressNotes" && <ProgressNotes />}
            {activeTab === "Treatments" && <Treatments />}
        </>
    );
}

export default IpNurseWorkbench;
