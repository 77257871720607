import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EmergencyNurseVitals from "./EmergencyNurseVitals";
import EmergencyDiagnosisTreatment from "./EmergencyDiagnosisTreatment";
import EmergencyIntakeOutput from "./EmergencyIntakeOutput";
import EmergencyLabTest from "./EmergencyLabTest";
import LabReport from "../../TreatmentRecords/LabReport";
import EmergencyConcernForm from "./EmergencyConcernForm";
import EmergencyVentilatorSettings from "./EmergencyVentilatorSettings";
import EmergencyBloodGas from "./EmergencyBloodGas";
import EmergencyBloodLines from "./EmergencyBloodLines";
import EmergencyUrinaryCathetor from "./EmergencyUrinaryCathetor";
import EmergencyBloodTransfusedRecord from "./EmergencyBloodTransfusedRecord";
import EmergencyPatientCare from "./EmergencyPatientCare";
import EmergencyHemodynamics from "./EmergencyHemodynamics";
import EmergencySpecialDevice from "./EmergencySpecialDevice";
import EmergencyNurseProgressNotes from "./EmergencyNurseProgressNotes";
import EmergencyTreatment from "./EmergencyTreatment";
// import EmergencyProgressNotes from "./EmergencyProgressNotes";




function EmergencyNurseWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("vitalForm");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/EmergencyNurseQueList')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.Booking_Id}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTreatment")} style={{ color: activeTab === 'EmergencyTreatment' ? "white" : '' }}>
                            Treatment / Diagnosis
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button onClick={() => handleTabChange("EmergencyDiagnosisTreatment")} style={{ color: activeTab === 'EmergencyDiagnosisTreatment' ? "white" : '' }}>
                                        Diagnosis/Treatment
                                    </button>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyIntakeOutput")} style={{ color: activeTab === 'EmergencyIntakeOutput' ? "white" : '' }}>
                                Intake/OutPut/Balance
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                   
                                    <button onClick={() => handleTabChange("BloodGas")} style={{ color: activeTab === 'BloodGas' ? "white" : '' }}>
                                        Blood Gas
                                    </button>
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyConcernForm")} style={{ color: activeTab === 'EmergencyConcernForm' ? "white" : '' }}>
                            Consent Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("VentilatorSettings")} style={{ color: activeTab === 'VentilatorSettings' ? "white" : '' }}>
                                        Ventilator Settings
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'IntensivePatientMonitoring' || activeTab === 'IntensivePatientMonitoring' ? "white" : '' }}>Intensive Patient Monitoring</button>
                                <div class="Lab_dropdown_content">
                                   
                                <button onClick={() => handleTabChange("BloodLines")} style={{ color: activeTab === 'BloodLines' ? "white" : '' }}>
                                        Blood Lines
                                </button>
                                
                                <button onClick={() => handleTabChange("UrinaryCathetor")} style={{ color: activeTab === 'UrinaryCathetor' ? "white" : '' }}>
                                Urinary Catheter
                                </button>
                                
                                <button onClick={() => handleTabChange("BloodTransfusionRecord")} style={{ color: activeTab === 'BloodTransfusionRecord' ? "white" : '' }}>
                                Blood Transfusion Record
                                </button>
                            
                            </div>
                           
                            </div>

                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'GeneralPatientCare' || activeTab === 'GeneralPatientCare' ? "white" : '' }}>General Patient Care</button>
                                <div class="Lab_dropdown_content">
                                   
                                    <button onClick={() => handleTabChange("PatientCare")} style={{ color: activeTab === 'PatientCare' ? "white" : '' }}>
                                    Patient Care
                                    </button>
                                    <button onClick={() => handleTabChange("Hemodynamics")} style={{ color: activeTab === 'Hemodynamics' ? "white" : '' }}>
                                    Patient Hemodynamics
                                    </button>
                                    
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencySpecialDevice")} style={{ color: activeTab === 'EmergencySpecialDevice' ? "white" : '' }}>
                            Special Devices
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyNurseProgressNotes")} style={{ color: activeTab === 'EmergencyNurseProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            
                            

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                                    <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTreatment")} style={{ color: activeTab === 'EmergencyTreatment' ? "white" : '' }}>
                            Treatment / Diagnosis
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button onClick={() => handleTabChange("EmergencyDiagnosisTreatment")} style={{ color: activeTab === 'EmergencyDiagnosisTreatment' ? "white" : '' }}>
                                Diagnosis/Treatment
                            </button>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyIntakeOutput")} style={{ color: activeTab === 'EmergencyIntakeOutput' ? "white" : '' }}>
                                Intake/OutPut/Balance
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("BloodGas")} style={{ color: activeTab === 'BloodGas' ? "white" : '' }}>
                                        Blood Gas
                                    </button> 
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyConcernForm")} style={{ color: activeTab === 'EmergencyConcernForm' ? "white" : '' }}>
                            Consent Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("VentilatorSettings")} style={{ color: activeTab === 'VentilatorSettings' ? "white" : '' }}>
                                        Ventilator Settings
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button class="Lab_button" style={{ color: activeTab === 'IntensivePatientMonitoring' || activeTab === 'IntensivePatientMonitoring' ? "white" : '' }}>Intensive Patient Monitoring</button>
                                <div class="Lab_dropdown_content">
                                   
                                <button onClick={() => handleTabChange("BloodLines")} style={{ color: activeTab === 'BloodLines' ? "white" : '' }}>
                                        Blood Lines
                                </button>
                                
                                <button onClick={() => handleTabChange("UrinaryCathetor")} style={{ color: activeTab === 'UrinaryCathetor' ? "white" : '' }}>
                                Urinary Catheter
                                </button>
                                
                                <button onClick={() => handleTabChange("BloodTransfusionRecord")} style={{ color: activeTab === 'BloodTransfusionRecord' ? "white" : '' }}>
                                Blood Transfusion Record
                                </button>
                            
                            </div>
                          
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'GeneralPatientCare' || activeTab === 'GeneralPatientCare' ? "white" : '' }}>General Patient Care</button>
                                <div class="Lab_dropdown_content">
                                
                                    <button onClick={() => handleTabChange("PatientCare")} style={{ color: activeTab === 'PatientCare' ? "white" : '' }}>
                                    Patient Care
                                    </button>
                                    <button onClick={() => handleTabChange("Hemodynamics")} style={{ color: activeTab === 'Hemodynamics' ? "white" : '' }}>
                                    Patient Hemodynamics
                                    </button>
                            
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencySpecialDevice")} style={{ color: activeTab === 'EmergencySpecialDevice' ? "white" : '' }}>
                            Special Devices
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyNurseProgressNotes")} style={{ color: activeTab === 'EmergencyNurseProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            

                        </h2>
                        
                        
                        
                    </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <EmergencyNurseVitals/>}
            {activeTab === "EmergencyDiagnosisTreatment" && <EmergencyDiagnosisTreatment/>}
            {activeTab === "EmergencyIntakeOutput" && <EmergencyIntakeOutput/>}
            {activeTab === "Lab" && <EmergencyLabTest/>}
            {activeTab === "LabReport" && <LabReport/>}
            {activeTab === "EmergencyConcernForm" && <EmergencyConcernForm/>}
            {activeTab === "VentilatorSettings" && <EmergencyVentilatorSettings/>}
            {activeTab === "BloodGas" && <EmergencyBloodGas/>}
            {activeTab === "BloodLines" && <EmergencyBloodLines/>}
            {activeTab === "UrinaryCathetor" && <EmergencyUrinaryCathetor/>}
            {activeTab === "BloodTransfusionRecord" && <EmergencyBloodTransfusedRecord/>}
            {activeTab === "PatientCare" && <EmergencyPatientCare/>}
            {activeTab === "Hemodynamics" && <EmergencyHemodynamics/>}
            {activeTab === "EmergencySpecialDevice" && <EmergencySpecialDevice/>}
            {activeTab === "EmergencyNurseProgressNotes" && <EmergencyNurseProgressNotes/>}
            {activeTab === "EmergencyTreatment" && <EmergencyTreatment/>}


           
            {console.log(activeTab)}
        </>
    );
}

export default EmergencyNurseWorkbench;

