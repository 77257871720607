import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./ReqRegis.css";
import { Helmet } from "react-helmet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears, format } from "date-fns";

// import { format, parse } from 'date-fns';

function Register() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const selectedRequestList = useSelector(
    (state) => state.userRecord?.selectedRequestList
  );
  console.log("selectedRequestList :", selectedRequestList);
  const data1 = selectedRequestList;
  const [nextpatientid, setNextpatientid] = useState(null);
  const [nextvisitid, setNextvisitid] = useState([]);
  console.log('hhhh', data1);
  console.log("userRecord", userRecord);
  const [doctorNames, setDoctorNames] = useState([]);
  const navigate = useNavigate();
  const [doctorfee, setdoctorfee] = useState("");

  console.log("doctorfee :", doctorfee);
  // const [appointmentid,setappointmentid] = useState('')
  // const [consultancyfee,setConsultancyFees] = useState([])
  const [Ratecard, setRatecard] = useState({
    RatecardType: "",
    InsuranceName: "",
    ClientName: "",
  });

  const create = userRecord?.username;
  const [InsuranceName, setInsuranceName] = useState("");
  const [ClinentName, setClinentName] = useState("");
  const [formData, setFormData] = useState({
    PatientID: "",
    appointmentid: "",
    visitid: "",
    title: "",
    firstName: "",
    lastName: "",
    Gender: "",
    DOB: "",
    Age: "",
    Nationality: "",
    phone: "",
    email: "",
    appointmentType: "",
    appointmentDate: "",
    appointmentSlot: "",
    doctorName: "",
    consultancyFee: "",
    Gstcharge: "",
    status: "pending",
    location: userRecord?.location,
    complaint: '',
    PatientType: "",
    PatientCategory: "",
    RateCardType: "",
    ClinentName: "",
    InsuranceName: "",
    InsuranceNo: "",

  });

  console.log(formData);


  const [datafromrequest, setdatafromrequest] = useState(null);

  const resetFormData = () => {
    setFormData({
      PatientID: "",
      appointmentid: "",
      visitid: "",
      title: "",
      firstName: "",
      lastName: "",
      Gender: "",
      DOB: "",
      Age: "",
      phone: "",
      email: "",
      appointmentType: "",
      appointmentDate: "",
      appointmentSlot: "",
      doctorName: "",
      consultancyFee: "",
      Gstcharge: "",
      status: "",
      location: userRecord?.location,
      complaint: '',
      PatientType: "",
      PatientCategory: "",
      RateCardType: "",
      ClinentName: "",
      InsuranceName: "",
      InsuranceNo: "",
    });
  };


  const handleFormChange = (event) => {
    const { name, value } = event.target;

    // Update only the PatientID field
    if (name === "PatientID") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else if (name === "DOB") {
      const newDate = new Date();

      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else if (name === "RateCardType") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      // Update other fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // Check and update PhoneNo field
    if (name === "phone") {
      const newval = value.length;
      if (newval <= 10) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Phone No must contain 10 digits");
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (selectedRequestList === null) {
      axios.get('https://vesoftometic.co.in/appointmentmanagement/get_patient_Id')
        .then((response) => {
          console.log(response.data);
          setFormData((prev) => ({
            ...prev,
            PatientID: response.data.nextpatientid
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }, [selectedRequestList]); // Add data1 to the dependency array



  // ------------
  useEffect(() => {
    let ratecardType;
    console.log(formData.PatientCategory)
    console.log(formData.doctorName);
    if (formData.doctorName !== "" && formData.doctorName !== undefined) {
      if (formData.PatientCategory === "Insurance") {
        ratecardType = formData.RateCardType

        axios
          .get(
            `https://vesoftometic.co.in/usercontrol/get_RateCard_Insurance_Charge?servicetype=DoctorConsultation&servicename=${encodeURIComponent(formData.doctorName)}&ratecardtype=${formData.RateCardType}&location=${encodeURIComponent(userRecord?.location)}`
          )
          .then((response) => {
            const data = response.data.data[0];
            if (data.Charge) {
              setFormData((prev) => ({
                ...prev,
                consultancyFee: doctorfee === "True" ? 0 : +data.Charge,
                Gstcharge: doctorfee === "True" ? 0 : +data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (formData.PatientCategory === "Client") {
        ratecardType = formData.RateCardType

        axios
          .get(
            `https://vesoftometic.co.in/usercontrol/get_RateCard_client_Charge?servicetype=DoctorConsultation&servicename=${encodeURIComponent(formData.doctorName)}&ratecardtype=${formData.RateCardType}&location=${encodeURIComponent(userRecord?.location)}`
          )
          .then((response) => {
            const data = response.data.data[0];
            if (data.Charge) {
              setFormData((prev) => ({
                ...prev,
                consultancyFee: doctorfee === "True" ? 0 : +data.Charge,
                Gstcharge: doctorfee === "True" ? 0 : +data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        ratecardType = formData.RateCardType;
        axios
          .get(
            `https://vesoftometic.co.in/usercontrol/get_RateCard_Service_Charge?servicetype=DoctorConsultation&servicename=${encodeURIComponent(formData.doctorName)}&ratecardtype=${ratecardType}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response);
            const data = response.data.data[0];
            console.log("data........", data.Charge);
            if (data.Charge) {
              setFormData((prev) => ({
                ...prev,
                consultancyFee: doctorfee === "True" ? 0 : +data.Charge,
                Gstcharge: doctorfee === "True" ? 0 : +data.GstCharge,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [formData.doctorName, formData.RateCardType]);

  useEffect(() => {
    if (selectedRequestList === null && formData.PatientID) {
      axios.get(`https://vesoftometic.co.in/appointmentmanagement/get_visitid_by_patientid?patientid=${formData.PatientID}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.length > 0) { // Check if the response data array is not empty
            const visitData = response.data[0];
            setFormData((prev) => ({
              ...prev,
              visitid: visitData.visitid, // Access the visitid field from the visitData object
            }));
          } else {
            // Handle the case when no visitid is found
            console.log("No visitid found for the patient.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedRequestList, formData.PatientID]);


  useEffect(() => {



    if (data1) {
      if (data1.selectedid) {
        axios
          .get(
            `https://vesoftometic.co.in/appointmentmanagement/check_patient_id?patient_id=${data1.patientid}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response.data);
            setdoctorfee(response.data.fee);
            const visit_id = response.data.visitid;
            if (!isNaN(visit_id)) {
              setFormData((prev) => ({
                ...prev,
                visitid: visit_id,
              }));
            } else {
              userwarn("Register");
            }
          })
          .catch((error) => {
            console.error("Error searching for patient:", error);
          });

        axios
          .get(
            `https://vesoftometic.co.in/appointmentmanagement/get_appointment_patientrequest?app_req_id=${data1.selectedid}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response.data);
            const data2 = response.data;

            // Assuming data2 is an array of appointments
            if (data2 && data2.length > 0) {
              const firstAppointment = data2[0];
              setdatafromrequest(firstAppointment);
              axios
                .get(
                  `https://vesoftometic.co.in/appointmentmanagement/get_Doctor_Slot`,
                  {
                    params: {
                      doctorName: firstAppointment.doctorName,
                      appointmentDate: firstAppointment.appointmentDate,
                      location: userRecord?.location,
                    },
                  }
                )
                .then((response) => {
                  // Handle the successful response
                  console.log(
                    response.data,
                    "----------------------------------------", format(new Date(firstAppointment.AppointmentDate), 'yyyy-MM-dd')
                  );
                  const data = response.data.nextSlot;
                  setFormData((prev) => ({
                    ...prev,
                    PatientID: data1.patientid || nextpatientid,
                    appointmentid: firstAppointment.AppointmentID,
                    title: firstAppointment.Title,
                    firstName: firstAppointment.FirstName,
                    lastName: firstAppointment.LastName,
                    phone: firstAppointment.PhoneNumber,
                    email: firstAppointment.Email,
                    appointmentType: firstAppointment.AppointmentPurpose,
                    appointmentDate: format(new Date(firstAppointment.AppointmentDate), 'yyyy-MM-dd'),
                    appointmentSlot: data,
                    doctorName: firstAppointment.DoctorName,
                    status: firstAppointment?.Status,
                  }));
                })
                .catch((error) => {
                  // Handle errors during the request
                  console.error("Error fetching Doctor Slot:", error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });


        axios
          .get(
            `https://vesoftometic.co.in/patientmanagement/getpatientpersonalbyid?patientid=${data1.patientid}`
          )
          .then((response) => {
            const data = response.data[0];
            console.log(data, "---------------------------");
            setFormData((prev) => ({
              ...prev,
              Gender: data?.Gender,
              DOB: data?.DOB,
              Age: data?.Age, // Corrected from data.Nationality to data.Age
              Nationality:data?.Nationality,
              PatientType: data?.PatientType,
              PatientCategory: data?.PatientCategory,
              RateCardType: data?.RateCardType,
              ClinentName: data?.ClinentName,
              InsuranceName: data?.InsuranceName,
              InsuranceNo: data?.InsuranceNo,
            }));
          });

      } else {
        // const today = new Date();
        // const year = today.getFullYear();
        // const month = String(today.getMonth() + 1).padStart(2, '0');
        // const day = String(today.getDate()).padStart(2, '0');
        // const formattedDate = `${year}-${month}-${day}`;
        // setFormData((prev) => ({
        //   ...prev,
        //   appointmentDate: formattedDate
        // }));
      }
    }
  }, [data1, userRecord, selectedRequestList]);


  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/usercontrol/get_Insurance_client?location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        if (data) {
          if (data.ClientName) {
            setClinentName(data.ClientName);
          }
          if (data.InsuranceName) {
            setInsuranceName(data.InsuranceName);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/usercontrol/get_doctor_info?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        setDoctorNames(data);
      })
      .catch((error) => {
        console.error("Error fetching doctor names:", error);
      });
  }, [create]);

  // useEffect(() => {
  //   axios
  //     .get('https://vesoftometic.co.in/usercontrol/get_doctor_consultcharge')  //change link here
  //     .then((response) => {
  //       const data = response.data;
  //       setConsultancyFees(data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching Consultancy Fees:', error);
  //     });
  // }, [create]);

  useEffect(() => {
    if (formData.doctorName && formData.appointmentDate) {
      // Log a message (optional)

      // Make an axios GET request to the specified endpoint
      axios
        .get(`https://vesoftometic.co.in/appointmentmanagement/get_Doctor_Slot`, {
          params: {
            doctorName: formData.doctorName,
            appointmentDate: formData.appointmentDate,
            location: userRecord?.location,
          },
        })
        .then((response) => {
          // Handle the successful response
          console.log(
            response.data,
            "----------------------------------------"
          );
          const data = response.data.nextSlot;
          setFormData((prev) => ({
            ...prev,
            appointmentSlot: data,
          })); // Log the data (optional)
        })
        .catch((error) => {
          // Handle errors during the request
          console.error("Error fetching Doctor Slot:", error);
        });
    }
  }, [formData.doctorName, formData.appointmentDate]);


  const handleFormSubmit = (event) => {
    event.preventDefault();

    // if (
    //   selectedRequestList !== null &&
    //   selectedRequestList !== "" &&
    //   !selectedRequestList?.patientid
    // ) {
    //   userwarn("Please register a new patient.");

    //   dispatchvalue({ type: "registerdata", value: { formData } });

    //   setTimeout(() => {
    //     navigate("/Home/Register-Patient2");
    //   }, 1500);

    //   return;
    // }
    // console.table(data1);

    let formDataToSend = {
      ...formData,
      Created_by: create,
    };

    // console.log("tempform data : ", formData);
    console.table("formdata : ", formData);
    // console.table("total data  :", formDataToSend);

    const BillingData = [
      {
        PatientID: formData?.PatientID || nextpatientid,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitid,
        ServiceType: "DoctorConsultation",
        Sessions: 0,
        PaidSessions: 0,
        RemainingSessions: 0,
        Amount: formData.consultancyFee,
        appointmentDate: formData.appointmentDate,
        DoctorName: formData.doctorName,
        Discount: 0,
        Gstcharge: formData.Gstcharge,
        TotalAmount: formData.consultancyFee,
        Status: formData.consultancyFee == 0 ? "Paid" : "Pending",
        location: userRecord?.location,
      },
    ];

    console.log(BillingData);
    axios
      .post(
        "https://vesoftometic.co.in/GeneralBilling/insertGeneral_Billing_Data",
        BillingData
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(
        "https://vesoftometic.co.in/appointmentmanagement/insert_appointment_register",
        formDataToSend
      )
      .then((response) => {
        if (response.data.message === "Success") {
          successMsg("Saved Successfully");
          navigate("/Home/Appoinment-RegisterList");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        errmsg("Error Occured");
      });
  };

  const handlenavigatePatientRegis = () => {
    navigate("/Home/Register-Patient2");
  };

  useEffect(() => {
    const appointmentDate = formData.appointmentDate;
    console.log("^^^^^^^^^^^^^^^^^^^", appointmentDate);

    if (!!appointmentDate) {
      axios
        .get(
          `https://vesoftometic.co.in/appointmentmanagement/get_appointmentid?appointmentDate=${appointmentDate}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data[0].Next_appointmentid;
          if (!!data) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              appointmentid: response.data[0].Next_appointmentid,
            }));
          } else {
            console.error(
              "Invalid response structure or missing Next_appointmentid property."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }, [formData.appointmentDate, selectedRequestList]);

  const handleSearch = () => {
    const patientID = formData.PatientID;
    const phone = formData.phone;
    const appointmentDate = formData.appointmentDate;
    console.log(patientID);
    console.log(appointmentDate);
    if (patientID || phone) {
      axios
        .get(
          `https://vesoftometic.co.in/appointmentmanagement/get_for_directappointmentreg?selected_patientid=${patientID}&phonenumber=${phone}`
        )
        .then((response) => {
          // console.log(response.data)
          if (response.data === "None") {
            userwarn("Patient ID is not avaiable! Please enter a new Register");

            // navigate('/Register-Patient2');
          } else {
            console.log(response.data);
            setdoctorfee(response.data[0].fee);

            setFormData((prevFormData) => ({
              ...prevFormData,
              visitid: response.data[0].visitid,
              title: response.data[1].Title,
              firstName: response.data[1].firstName,
              lastName: response.data[1].lastName,
              phone: response.data[1].phone,
              email: response.data[1].email,
              PatientID: response.data[1].PatientID,
            }));

            axios
          .get(
            `https://vesoftometic.co.in/patientmanagement/getpatientpersonalbyid?patientid=${patientID}`
          )
          .then((response) => {
            const data = response.data[0];
            console.log(data, "---------------------------");
            setFormData((prev) => ({
              ...prev,
              Gender: data?.Gender,
              DOB: data?.DOB,
              Age: data?.Age, // Corrected from data.Nationality to data.Age
              Nationality:data?.Nationality,
              PatientType: data?.PatientType,
              PatientCategory: data?.PatientCategory,
              RateCardType: data?.RateCardType,
              ClinentName: data?.ClinentName,
              InsuranceName: data?.InsuranceName,
              InsuranceNo: data?.InsuranceNo,
            }));
          });


            axios
              .get(
                `https://vesoftometic.co.in/patientmanagement/get_communication_address_billing/${+response.data[1].PatientID}/${userRecord?.location}`
              )
              .then((response) => {
                const data = response.data[0];
                console.log(data, "---------------------------");
                setRatecard({
                  ratecardType: data.RatecardType,
                  InsuranceName: data.InsuranceName,
                  ClientName: data.ClientName,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userwarn("plesae fill patient id or phone number");
    }
  };






  const handleCancel = () => {
    // Reset the form data before navigating
    resetFormData();

    // Navigate to RegisterList
    navigate("/Home/Appoinment-RegisterList");
  };

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (errormsg) => {
    toast.error(`${errormsg}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>

      <div className="patient-registration-form">
        <div className="h_head">
          <h4>Register</h4>
        </div>
        <br />
        <div className="RegisFormcon">

          <div className="RegisForm_1 input-with-icon">
            <label htmlFor="PatientID">
              Patient ID
              <button className="newicon_regis" title="New Register">
                <PersonAddIcon onClick={handlenavigatePatientRegis} />
              </button>
              <span>:</span>{" "}
            </label>
            <input
              type="text"
              name="PatientID"
              value={formData.PatientID}
              onChange={handleFormChange}
              required
            />
            {selectedRequestList === null && ( // Check if selectedRequestList is null before rendering the search button
              <button className="searching_input_icon3" onClick={handleSearch}>
                <SearchIcon />
              </button>
            )}
          </div>

          <div className="RegisForm_1 input-with-icon">
            <label htmlFor="phone">
              {" "}
              Phone Number <span>:</span>{" "}
            </label>
            <input
              type="number"
              id="phone"
              value={formData.phone}
              name="phone"
              // pattern="[0-9]{10}"
              readOnly={datafromrequest}
              onChange={handleFormChange}
              required
            />
            {!selectedRequestList && selectedRequestList === null && (
              <button className="searching_input_icon3" onClick={handleSearch}>
                <SearchIcon />
              </button>
            )}
          </div>
          <div className="RegisForm_1">
            <label htmlFor="AppointmentId">
              Appointment Id<span>:</span>
            </label>
            <input
              type="text"
              id="AppointmentId"
              name="AppointmentId"
              value={formData.appointmentid}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="VisitId">
              Visit Id<span>:</span>
            </label>
            <input
              type="text"
              name="VisitId"
              value={formData.visitid || nextvisitid}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="title">
              Title<span>:</span>
            </label>
            <select
              id="title"
              name="title"
              value={formData.title}
              onChange={handleFormChange}
              readOnly={datafromrequest}
            >
              <option value="">Select</option>
              <option value="Mr">Mr.</option>
              <option value="Ms">Ms.</option>
              <option value="Mrs">Mrs.</option>
              <option value="Others">Others.</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="firstName">
              First Name<span>:</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              readOnly={datafromrequest}
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Last Name<span>:</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              readOnly={datafromrequest}
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Gender <span>:</span>
            </label>

            <select
              name="Gender"
              value={formData.Gender}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label>
              Date Of Birth<span>:</span>
            </label>
            <input
              type="date"
              name="DOB"
              placeholder="Enter Date Of Birth"
              value={formData.DOB}
              onChange={handleFormChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="AppointmentId">
              Age <span>:</span>
            </label>
            <input
              type="text"
              name="Age"
              placeholder="Enter Age"
              value={formData.Age}
              onChange={handleFormChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="email">
              Email<span>:</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              readOnly={datafromrequest}
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Nationality">
              Nationality <span>:</span>
            </label>
            <select
              name="Nationality"
              value={formData.Nationality}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="Indian">Indian</option>
              <option value="International">International</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="appointmentType">
              Visit Purpose<span>:</span>
            </label>
            {/* <input
              type="text"
              id="appointmentType"
              name="appointmentType"
              value={formData.appointmentType}
              placeholder="Enter Appointment Type"
              onChange={handleFormChange}
              required
            /> */}
            <select
              id="appointmentType"
              name="appointmentType"
              onChange={handleFormChange}
              value={formData.appointmentType}
              required
            >
              <option value="">Select</option>
              <option value="New Consultation">New Consultation</option>
              <option value="Followup Consultation">
                Followup Consultation
              </option>
              <option value="Followup Procedure">Followup Procedure</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="appointmentDate">
              Date<span>:</span>
            </label>
            <input
              type="date"
              id="appointmentDate"
              name="appointmentDate"
              value={formData.appointmentDate}
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="slot">
              Slot<span>:</span>
            </label>
            <input
              type="text"
              id="slot"
              name="appointmentSlot"
              value={formData.appointmentSlot}
              readOnly={datafromrequest}
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="doctorName">
              Doctor Name<span>:</span>
            </label>
            <select
              id="doctorName"
              name="doctorName"
              readOnly={datafromrequest}
              value={formData.doctorName}
              onChange={handleFormChange}
            >
              <option>Select Doctor</option>
              {doctorNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="consultancyFee">
              Consultancy Fees<span>:</span>
            </label>
            <input
              type="text"
              id="slot"
              name="consultancyFee"
              readOnly
              value={formData.consultancyFee}
              placeholder="Enter consultancy Fee "
              onChange={handleFormChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="status">
              Status<span>:</span>
            </label>
            <input
              type="text"
              id="status"
              name="status"
              value={formData.status}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="history">
              Complaint<span>:</span>
            </label>
            <textarea
              id="complaint"
              name="complaint"
              value={formData.complaint}
              cols="25"
              rows="3"
              onChange={handleFormChange}
            ></textarea>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="AppointmentId">
              Patient Type <span>:</span>
            </label>
            <select
              name="PatientType"
              value={formData.PatientType}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="General">General</option>
              <option value="VIP">VIP</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label>
              Patient Category<span>:</span>
            </label>

            <select
              name="PatientCategory"
              value={formData.PatientCategory}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="General">General</option>
              <option value="Client">Client</option>
              <option value="Insurance">Insurance</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="RatecardType">Rate Card Type:</label>
            <select
              name="RateCardType"
              value={formData.RateCardType}
              onChange={handleFormChange}
            >
              <option value="">Select</option>
              <option value="General">General</option>
              <option value="Special">Special</option>
              <option value="VIP">VIP</option>
              <option value="Client">Client</option>
              <option value="Insurance">Insurance</option>
            </select>

          </div>
          {formData.PatientCategory !== "Client" &&
            formData.PatientCategory !== "Insurance" && (
              <div className="RegisForm_1" id="hide_div_regg"></div>
            )}
          {formData.PatientCategory === "Client" && (
            <div className="RegisForm_1">
              <label>
                {" "}
                Client Name <span>:</span>{" "}
              </label>

              <select
                name="ClinentName"
                value={formData.ClinentName}
                onChange={handleFormChange}
              >
                <option value="">Select</option>
                {ClinentName.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </select>
            </div>
          )}

          {formData.PatientCategory === "Insurance" && (
            <div className="RegisForm_1">
              <label>
                {" "}
                Insurance Name <span>:</span>{" "}
              </label>

              <select
                name="InsuranceName"
                value={formData.InsuranceName}
                onChange={handleFormChange}
              >
                <option value="">Select</option>
                {InsuranceName.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </select>
            </div>
          )}

          {formData.PatientCategory === "Insurance" && (
            <div className="RegisFormcon  ">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Insurance No <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the InsuranceNo"
                  name="InsuranceNo"
                  value={formData.InsuranceNo}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          )}

        </div>
      </div>
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleFormSubmit}>
          Register
        </button>
        <button className="RegisterForm_1_btns" onClick={handleCancel}>
          Cancel
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default Register;
