

const initstate = {
    InsuranceMasterEdit:{},
    IPPatientDetailes:{},
    InsuranceMaindetailes:{},
    InsurancePageChange:'',
    InsuranceUpdatedata:{},

};

const InsuranceStore = (state = initstate, action) => {

    switch (action.type) {

        case 'InsuranceMaster':
            console.log(action);
            return { ...state, InsuranceMasterEdit: action.value };

        case 'IPPDetailes':
            console.log(action);
            return { ...state, IPPatientDetailes: action.value };
        case 'InsuranceMaindetailes':
            console.log(action);
            return { ...state, InsuranceMaindetailes: action.value };
        case 'InsurancePageChange':
            console.log(action);
            return { ...state, InsurancePageChange: action.value };
        case 'InsuranceUpdatedata':
            console.log(action);
            return { ...state, InsuranceUpdatedata: action.value };

        default:
            return state;
    }
};

export default InsuranceStore;