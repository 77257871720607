import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { differenceInYears } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../src/Insurance/Insurance.css";
import LogoImg from "../Insurance/LogoImg.png";
import LaunchIcon from "@mui/icons-material/Launch";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Select } from "@mui/material";
import { useSelector } from "react-redux";
import { el } from "date-fns/locale";
import axios from "axios";


function Insurance() {


  const userRecord = useSelector((state) => state.userRecord?.UserData);

  // CreatedBy: userRecord.username,
  // Location: userRecord.location,


  console.log('1212',userRecord)

  const IPPatientDetailes = useSelector(
    (state) => state.InsuranceStore?.IPPatientDetailes
  );


  const [value, setValue] = React.useState("1");
  const [expanded, setExpanded] = useState(false);

  const [isCardCollapsed, setIsCardCollapsed] = useState(true);

  const [persionalformData, setpersionalformData] = useState({
    MRN: "",
    PatientName: "",
    Gender: "",
    DOB: "",
    Age: "",
    ContactNumber: "",
    Address: "",
    PinCode: "",
    
  });


  const [InsuranceCompanyformdata,setInsuranceCompanyformdata]=useState({

    InsuranceCompany: "",
    PolicyNumber: "",
    PolicyType: "",
    PayerTPAName: "",
    PayerTPAZone: "",
    PayerTPAMemberID: "",

  })


 

  const [esicCoverage, setEsicCoverage] = useState("No");

  const[ESINumberstate,setESINumberstate]=useState('')


  const [employed, setEmployed] = useState("No");

  const [Corporatedetails,setCorporatedetails]=useState({
    CorporateName: "",
    EmployeeID:"",
  })


  const [MedicalLegalCase, setMedicalLegalCase] = useState("No");

  const [MLCdetails,setMLCdetails]=useState({
    InformedBY:'',
    OfficerName:'',
    Policestation:'',
    FIRNo:'',
    CodeStatus:'',
    TypeOfAdmit:'',
    Admitting_diagnosis:''
  })



  const [formDataTxtarea, setFormDataTxtarea] = useState({
    ComplaintsDuration: "",
    HistoryPresentingIllness: "",
    PastHistory: "",
  });

  

  const [diabetes, setDiabetes] = useState("No");
  const [diabetesDuration, setDiabetesDuration] = useState("");
  const [diabetesMedication, setDiabetesMedication] = useState("");

  const [hyperTension, setHyperTension] = useState("No");
  const [hyperTensionDuration, setHyperTensionDuration] = useState("");
  const [hyperTensionMedication, setHyperTensionMedication] = useState("");

  const [hyperlipiDemias, setHyperlipiDemias] = useState("No");
  const [hyperlipiDemiasDuration, setHyperlipiDemiasDuration] = useState("");
  const [hyperlipiDemiasMedication, setHyperlipiDemiasMedication] =
    useState("");

  const [osteoarthritis, setOsteoarthritis] = useState("No");
  const [osteoarthritisDuration, setOsteoarthritisDuration] = useState("");
  const [osteoarthritisMedication, setOsteoarthritisMedication] = useState("");

  const [bronchialAsthma, setBronchialAsthma] = useState("No");
  const [bronchialAsthmaDuration, setBronchialAsthmaDuration] = useState("");
  const [bronchialAsthmaMedication, setBronchialAsthmaMedication] =
    useState("");

  const [cvd, setCvd] = useState("No");
  const [cvdDuration, setCvdDuration] = useState("");
  const [cvdMedication, setCvdMedication] = useState("");

  const [cva, setCva] = useState("No");
  const [cvaDuration, setCvaDuration] = useState("");
  const [cvaMedication, setCvaMedication] = useState("");

  const [kochs, setKochs] = useState("No");
  const [kochsDuration, setKochsDuration] = useState("");
  const [kochsMedication, setKochsMedication] = useState("");

  const [malignancy, setMalignancy] = useState("No");
  const [malignancyDuration, setMalignancyDuration] = useState("");
  const [malignancyMedication, setMalignancyMedication] = useState("");

  const [alcohol, setAlcohol] = useState("No");
  const [alcoholDuration, setAlcoholDuration] = useState("");
  const [alcoholNotes, setAlcoholNotes] = useState("");

  const [smoking, setSmoking] = useState("No");
  const [smokingDuration, setSmokingDuration] = useState("");
  const [smokingNotes, setSmokingNotes] = useState("");

  const [hiv, setHiv] = useState("No");
  const [hivDuration, setHivDuration] = useState("");
  const [hivNotes, setHivNotes] = useState("");

  const [hbSAg, setHbSAg] = useState("No");
  const [hbSAgDuration, setHbSAgDuration] = useState("");
  const [hbSAgNotes, setHbSAgNotes] = useState("");

  const [hcv, setHCV] = useState("No");
  const [hcvDuration, setHCVDuration] = useState("");
  const [hcvNotes, setHCVNotes] = useState("");

  const [diabetesFamily, setDiabetesFamily] = useState("No");
  const [diabetesRelationship, setDiabetesRelationship] = useState("");
  const [diabetesNotes, setDiabetesNotes] = useState("");

  const [hyperTensionFamily, setHyperTensionFamily] = useState("No");
  const [hyperTensionRelationshipFamily, setHyperTensionRelationshipFamily] = useState("");
  const [hyperTensionNotesFamily, setHyperTensionNotesFamily] = useState("");

  const [kochsFamily, setKochsFamily] = useState("No");
  const [kochsRelationship, setKochsRelationship] = useState("");
  const [kochsNotes, setKochsNotes] = useState("");

  const [geneticDisorderFamily, setGeneticDisorderFamily] = useState("No");
  const [geneticDisoderRelationship, setGeneticDisoderRelationship] = useState("");
  const [geneticDisoderNotes, setGeneticDisoderNotes] = useState("");

  const [medication, setMedication] = useState([
    {
      medicationName: "",
      medicationDuration: "",
    },
  ]);

  const[RelevantClinicalFindings,setRelevantClinicalFindings]=useState('')

  const [rows, setRows] = useState([
    { provisionalDiagnosis: "", icdCode: "", type: "Primary" },
  ]);

  const [rows2, setRows2] = useState([
    { finalDiagnosis: "", icdCode2: "", type2: "Primary" },
  ]);

  

  const [rows3, setRows3] = useState([
    {
      department: "",
      treatingDoctor: "",
      contactNumber3: "",
      registrationNumber: "",
      medicalCouncil: "",
    },
  ]);

  const [checkboxStateTreatment, setCheckboxStateTreatment] = useState({
    icuManagement: false,
    dialysis: false,
    chemotherapy: false,
    radiation: false,
  });

  const [medicalManagement, setMedicalManagement] = useState("No");
  const [TreatmentTxtareaDetails, setTreatmentTxtareaDetails] = useState('');

  const [rows4, setRows4] = useState([
    {
      treatment: "",
      pcsHospitalCode: "",
    },
  ]);

  const [surgicalManagement, setSurgicalManagement] = useState("No");
 
 
  // const [department3, setDepartment3] = useState([
  //   {
  //     departmentSurgical: "",
  //     surgeonSurgical: "",
  //   },
  // ]);

  // const [checkboxStateAnesthesia, setCheckboxStateAnesthesia] = useState({
  //   laAnesthesia: false,
  //   raAnesthesia: false,
  //   saAnesthesia: false,
  //   eaAnesthesia: false,
  //   gaAnesthesia: false,
  // });

  const [type, setType] = useState([]);

  console.log('typewwww',type)

  // const [rows5, setRows5] = useState([
  //   {
  //     procedureSurgical: "",
  //     pcdHospitalCodeSurgical: "",
  //   },
  // ]);


  // const [additionalSurgicalOptions, setAdditionalSurgicalOptions] = useState(
  //   []
  // );

  const [billingInfo, setBillingInfo] = useState({
    dateofAdmission: "",
    typeofAdmission: "",
    durationStayinDays: "",
    roomWardTypeBilling: "",
    dateofSurgery: "",
    dateofDischarge: "",
    DischargeTypeBilling: "",
  });

  const [rows6, setRows6] = useState([
    {
      costOfHospitalization: "",
      totalBillAmount: "",
    },
  ]);

  
  const [rows7, setRows7] = useState([
    {
      Name:'photoIdentityCard',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows8, setRows8] = useState([
    {
      Name:'signedForm',
      Document_file: null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows9, setRows9] = useState([
    { 
      Name:'otherDocument',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  //Enhanced
  const [rows10, setRows10] = useState([
    {
      doctorsLetterFile: "",
      doctorsLetterRemarks: "",
      doctorsLetterDate: "",
      doctorsLetterACK: false,
    },
  ]);

  const [rows11, setRows11] = useState([
    {
      interimBillFile: "",
      interimBillRemarks: "",
      interimBillDate: "",
      interimBillACK: false,
    },
  ]);

  const [rows12, setRows12] = useState([
    {
      enhancedDocument_file: "",
      enhancedDocument_Remarks: "",
      enhancedDocument_Date: "",
      enhancedDocument_Ack: false,
    },
  ]);

  //Discharged

  const [rows13, setRows13] = useState([
    {
      investigationReport: "",
      investigationReportRemarks: "",
      investigationReportDate: "",
      investigationReportACK: false,
    },
  ]);

  const [rows14, setRows14] = useState([
    {
      finalBillFile: "",
      finalBillRemarks: "",
      interimBillDate: "",
      interimBillACK: false,
    },
  ]);

  const [rows15, setRows15] = useState([
    {
      dischargeSummaryFile: "",
      dischargeSummaryRemarks: "",
      dischargeSummaryDate: "",
      dischargeSummaryACK: false,
    },
  ]);

  const [rows16, setRows16] = useState([
    {
      dischargeSummaryDocument_file: "",
      dischargeSummaryDocument_Remarks: "",
      dischargeSummaryDocument_Date: "",
      dischargeSummaryDocument_Ack: false,
    },
  ]);


  

  const [formDataProfile, setFormDataProfile] = useState({
    mrn: "",
    alNumber: "",
    clNumber: "",
    inwardNumber: "",
    requestedAmount: "",
    approvedAmount: "",
  });



  const [Papersstatus, setPapersstatus] = useState("");

  const [Papersstatustype,setPapersstatustype]=useState('')

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(" ");
  //       const data = await response.json();

  //       if (data.length === 0) {
  //         setStatus("discharged");
  //       } else {
  //         setStatus("enhanced");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //       setStatus("discharged");
  //     }
  //   };

  //   fetchData();
  // }, []);
  
// ---------------------------------------------------------------------------------------------------------------------

  const handleChangeProfile = (e) => {
    const { id, value } = e.target;
    setFormDataProfile((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };


  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleCardVisibility = () => {
    setIsCardCollapsed(!isCardCollapsed);
    setIsTabCollapsed(true);
  };

  const [isTabCollapsed, setIsTabCollapsed] = useState(true);
  const toggleTabVisibility = () => {
    setIsTabCollapsed(!isTabCollapsed);
    setIsCardCollapsed(true);
  };

  const handleContentCardClick = () => {
    setIsCardCollapsed(true);
    setIsTabCollapsed(true);
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (name === "ContactNumber") {
      const newval = value.length;
      if (newval <= 10) {
        setpersionalformData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Contact Number must contain 10 digits");
      }
    } else if (name === "DOB") {
      const newDate = new Date();
      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setpersionalformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else {
      setpersionalformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const InsuranceChangefun=(e)=>{

    const { name, value } = e.target;

    setInsuranceCompanyformdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }


  const MLCChangefun=(e)=>{

    const { name, value } = e.target;

    setMLCdetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }


  const CorporateChangefun=(e)=>{

    const { name, value } = e.target;

    setCorporatedetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }

  const handleTextAreaChange = (event) => {
    const { name, value } = event.target;
    setFormDataTxtarea({
      ...formDataTxtarea,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    if(event.target.value ==='No'){

      setEsicCoverage(event.target.value);

      setESINumberstate('')

    }
    else{
      setEsicCoverage(event.target.value);
    }
  };

  const handleCheckboxChange2 = (event) => {
    if(event.target.value ==='No'){
    setEmployed(event.target.value)
    
    setCorporatedetails((prev)=>({
      ...prev,
      CorporateName:'',
      EmployeeID:''
    }))

    }else{
    setEmployed(event.target.value)
    }
  };


  const handleCheckboxChangeLegalCase = (event) => {
    if(event.target.value === 'No'){
     setMedicalLegalCase(event.target.value);

    setMLCdetails((prev)=>({
      ...prev,
      InformedBY:'',
      OfficerName:'',
      Policestation:'',
      FIRNo:'',
      CodeStatus:'',
      TypeOfAdmit:'',
      Admitting_diagnosis:''
    }))

    }else{
      setMedicalLegalCase(event.target.value);
    }
   };

  const handleCheckboxChange3 = (event) => {
    if(event.target.value === 'No'){
      setDiabetesDuration('')
      setDiabetesMedication('')
      setDiabetes(event.target.value);
    }else{
      setDiabetes(event.target.value);
    }
  };

  const handleDurationChange = (event) => {
    setDiabetesDuration(event.target.value);
  };

  const handleMedicationChange = (event) => {
    setDiabetesMedication(event.target.value);
  };

  const handleCheckboxChange4 = (event) => {
    if(event.target.value === 'No'){
    setHyperTension(event.target.value);
    setHyperTensionDuration('')
    setHyperTensionMedication('')
    }else{
    setHyperTension(event.target.value);
    }
  };


  const handleHyperTensionDurationChange = (event) => {
    setHyperTensionDuration(event.target.value);
  };

  const handleHyperTensionMedicationChange = (event) => {
    setHyperTensionMedication(event.target.value);
  };

  const handleCheckboxChange5 = (event) => {
    if(event.target.value === 'No'){

    setHyperlipiDemias(event.target.value);
    setHyperlipiDemiasDuration('')
    setHyperlipiDemiasMedication('')

    }else{
      setHyperlipiDemias(event.target.value);
    }
    
  };



  const handleHyperlipiDemiasDurationChange = (event) => {
    setHyperlipiDemiasDuration(event.target.value);
  };

  const handleHyperlipiDemiasMedicationChange = (event) => {
    setHyperlipiDemiasMedication(event.target.value);
  };

  const handleCheckboxChange6 = (event) => {
   if(event.target.value === 'No'){
    setOsteoarthritis(event.target.value);
    setOsteoarthritisDuration('')
    setOsteoarthritisMedication('')
   }else{
    setOsteoarthritis(event.target.value);
   }
  };

  const handleOsteoarthritisDurationChange = (event) => {
    setOsteoarthritisDuration(event.target.value);
  };

  const handleOsteoarthritisMedicationChange = (event) => {
    setOsteoarthritisMedication(event.target.value);
  };

  const handleCheckboxChange7 = (event) => {
    if(event.target.value === 'No'){
      setBronchialAsthmaDuration('')
      setBronchialAsthmaMedication('')
      setBronchialAsthma(event.target.value);
    }else{
      setBronchialAsthma(event.target.value);
    }
  };
  const handleBronchialAsthmaDurationChange = (event) => {
    setBronchialAsthmaDuration(event.target.value);
  };

  const handleBronchialAsthmaMedicationChange = (event) => {
    setBronchialAsthmaMedication(event.target.value);
  };

  const handleCheckboxChange8 = (event) => {
    if(event.target.value === 'No'){
      setCvdDuration('')
      setCvdMedication('')
      setCvd(event.target.value);
    }else{
      setCvd(event.target.value);
    }
    
  };
  const handleCvdDurationChange = (event) => {
    setCvdDuration(event.target.value);
  };

  const handleCvdMedicationChange = (event) => {
    setCvdMedication(event.target.value);
  };

  const handleCheckboxChange9 = (event) => {
    if(event.target.value === 'No'){
      setCvaDuration('')
      setCvaMedication('')
      setCva(event.target.value);
    }else{
      setCva(event.target.value);      
    }
  };

  const handleCvaDurationChange = (event) => {
    setCvaDuration(event.target.value);
  };

  const handleCvaMedicationChange = (event) => {
    setCvaMedication(event.target.value);
  };

  const handleCheckboxChange10 = (event) => {
    if(event.target.value === 'No'){
      setKochsDuration('')
      setKochsMedication('')
      setKochs(event.target.value);
    }else{
      setKochs(event.target.value);
    }
  };
  const handleKochsDurationChange = (event) => {
    setKochsDuration(event.target.value);
  };

  const handleKochsMedicationChange = (event) => {
    setKochsMedication(event.target.value);
  };

  const handleCheckboxChange11 = (event) => {
    if(event.target.value === 'No'){
      setMalignancyDuration('')
      setMalignancyMedication('')
      setMalignancy(event.target.value);
    }else{
      setMalignancy(event.target.value);
    }
  };
  const handleMalignancyDurationChange = (event) => {
    setMalignancyDuration(event.target.value);
  };

  const handleMalignancyMedicationChange = (event) => {
    setMalignancyMedication(event.target.value);
  };


  const handleCheckboxChangeAlcohol = (event) => {
    if(event.target.value === 'No'){
      setAlcoholDuration('')
      setAlcoholNotes('')
      setAlcohol(event.target.value);
    }else{
      setAlcohol(event.target.value);
    }
  };

  const handleAlcoholDurationChange = (event) => {
    setAlcoholDuration(event.target.value);
  };

  const handleAlcoholNotesChange = (event) => {
    setAlcoholNotes(event.target.value);
  };

  const handleCheckboxChangeSmoking = (event) => {
   if(event.target.value === 'No'){
    setSmokingDuration('')
    setSmokingNotes('')
    setSmoking(event.target.value);
   }else{
    setSmoking(event.target.value);
   }
  };

  const handleSmokingDurationChange = (event) => {
    setSmokingDuration(event.target.value);
  };

  const handleSmokingNotesChange = (event) => {
    setSmokingNotes(event.target.value);
  };

  const handleCheckboxChangeHIV = (event) => {
    if(event.target.value === 'No'){
      setHivDuration('')
      setHivNotes('')
      setHiv(event.target.value);
    }else{
      setHiv(event.target.value);
    }
  };

  const handleHIVDurationChange = (event) => {
    setHivDuration(event.target.value);
  };

  const handleHIVNotesChange = (event) => {
    setHivNotes(event.target.value);
  };

  const handleCheckboxChangeHbSAg = (event) => {
  if(event.target.value === 'No'){
    setHbSAgDuration('')
    setHbSAgNotes('')
    setHbSAg(event.target.value);
  }else{
    setHbSAg(event.target.value);
  }
  };

  const handleHbSAgDurationChange = (event) => {
    setHbSAgDuration(event.target.value);
  };

  const handleHbSAgNotesChange = (event) => {
    setHbSAgNotes(event.target.value);
  };

  const handleCheckboxChangeHCV = (event) => {
    if(event.target.value === 'No'){
      setHCVDuration('')
      setHCVNotes('')
      setHCV(event.target.value);
    }else{
      setHCV(event.target.value);
    }
  };

  const handleHCVDurationChange = (event) => {
    setHCVDuration(event.target.value);
  };

  const handleHCVNotesChange = (event) => {
    setHCVNotes(event.target.value);
  };


  const handleChangeRowMedication = (index, key, value) => {
    const updatedMedication = [...medication]; 
    updatedMedication[index][key] = value;
    setMedication(updatedMedication);
};

  const addRowMedication = () => {
    setMedication((prevRows) => [
      ...prevRows,
      {
        medicationName: "",
        medicationDuration: "",
      },
    ]);
  };
  const removeRowMedication = (index) => {
    setMedication((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const handleCheckboxChangeFamily = (event) => {
    if(event.target.value === 'No'){
      setDiabetesRelationship('')
      setDiabetesNotes('')
      setDiabetesFamily(event.target.value);
    }else{
      setDiabetesFamily(event.target.value);
    }
  };

  const handleRelationshipChangeFamily = (event) => {
    setDiabetesRelationship(event.target.value);
  };

  const handleNotesChangeFamily = (event) => {
    setDiabetesNotes(event.target.value);
  };

  const handleCheckboxChangeFamily2 = (event) => {
    if(event.target.value === 'No'){
      setHyperTensionRelationshipFamily('')
      setHyperTensionNotesFamily('')
      setHyperTensionFamily(event.target.value);
    }else{
      setHyperTensionFamily(event.target.value);
    }
  };
  const handleHyperTensionRelationshipChange = (event) => {
    setHyperTensionRelationshipFamily(event.target.value);
  };

  const handleHyperTensionNotesChange = (event) => {
    setHyperTensionNotesFamily(event.target.value);
  };

  const handleCheckboxChangeFamily3 = (event) => {
    if(event.target.value === 'No'){
      setKochsRelationship('')
      setKochsNotes('')
      setKochsFamily(event.target.value);
    }else{
      setKochsFamily(event.target.value);
    }
  };
  const handleKochsRelationshipChange = (event) => {
    setKochsRelationship(event.target.value);
  };

  const handleKochsNotesChange = (event) => {
    setKochsNotes(event.target.value);
  };

  const handleCheckboxChangeFamily4 = (event) => {
    if(event.target.value === 'No'){
      setGeneticDisoderRelationship('')
      setGeneticDisoderNotes('')
      setGeneticDisorderFamily(event.target.value);
    }else{
      setGeneticDisorderFamily(event.target.value);
    }
  };
  const handleGeneticDisorderRelationshipChange = (event) => {
    setGeneticDisoderRelationship(event.target.value);
  };

  const handleGeneticDisorderNotesChange = (event) => {
    setGeneticDisoderNotes(event.target.value);
  };



 
  const handleTextAreaChange2= (event) => {
    setRelevantClinicalFindings(event.target.value)
  };

  const handleChangeRow = (index, key, value) => {
    const newRows = [...rows];
    newRows[index][key] = value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { provisionalDiagnosis: "", icdCode: "", type: "Primary" },
    ]);
  };

  const removeRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleChangeRow2 = (index, key, value) => {
    const newRows = [...rows2];
    newRows[index][key] = value;
    setRows2(newRows);
  };

  const addRow2 = () => {
    setRows2([
      ...rows2,
      {
        finalDiagnosis: "",
        icdCode2: "",
        type2: "Primary",
      },
    ]);
  };

  const removeRow2 = (index) => {
    const newRows = [...rows2];
    newRows.splice(index, 1);
    setRows2(newRows);
  };

  const handleChangeRow3 = (index, key, value) => {
    const newRows = [...rows3];
    newRows[index][key] = value;
    setRows3(newRows);
  };

  const addRow3 = () => {
    setRows3([
      ...rows3,
      {
        department: "",
        treatingDoctor: "",
        contactNumber3: "",
        registrationNumber: "",
        medicalCouncil: "",
      },
    ]);
  };

  const removeRow3 = (index) => {
    const newRows = [...rows3];
    newRows.splice(index, 1);
    setRows3(newRows);
  };

  const handleCheckboxChangeTreatment = (event) => {
    const { name, checked } = event.target;
    setCheckboxStateTreatment({ ...checkboxStateTreatment, [name]: checked });
  };

  const handleCheckboxChangeMedical = (event) => {
    setMedicalManagement(event.target.value);
  };

  const handleTextAreaChangeDetails = (event) => {
    const { name, value } = event.target;
    setTreatmentTxtareaDetails(event.target.value);
  };

  const handleChangeRow4 = (index, key, value) => {
    const newRows = [...rows4];
    newRows[index][key] = value;
    setRows4(newRows);
  };

  const addRow4 = () => {
    setRows4([
      ...rows4,
      {
        treatment: "",
        pcsHospitalCode: "",
      },
    ]);
  };

  const removeRow4 = (index) => {
    const newRows = [...rows4];
    newRows.splice(index, 1);
    setRows4(newRows);
  };

  const handleCheckboxChangeSurgical = (event) => {
    setSurgicalManagement(event.target.value);
  };

  const handleChangeSurgical = (index, key, value) => {
    const newOptions = [...additionalSurgicalOptions];
    if (newOptions[index]) {
      newOptions[index][key] = value;
      setAdditionalSurgicalOptions(newOptions);
    }
  };

  const handleCheckboxChangeAnesthesia = (event, index) => {
    const { name, checked } = event.target;

    const updatedCheckboxStates = [...checkboxStates];

    updatedCheckboxStates[index] = {
      ...updatedCheckboxStates[index],
      [name]: checked,
    };

    setCheckboxStates(updatedCheckboxStates);
  };

  const handleCheckboxChangeType = (event, index) => {
    const { value } = event.target;

    const updatedTypeStates = [...type];

    updatedTypeStates[index] = value;

    console.log('qqqq',updatedTypeStates)

    setType(updatedTypeStates);
  };

  const [additionalSurgicalOptions, setAdditionalSurgicalOptions] = useState(
    []
  );

  const [checkboxStates, setCheckboxStates] = useState([]);

  const addAdditionalSurgicalOption = () => {
    setAdditionalSurgicalOptions((prevOptions) => [
      ...prevOptions,
      {
        
      },
    ]);

    setCheckboxStates((prevStates) => [
      ...prevStates,
      {
        laAnesthesia: false,
        raAnesthesia: false,
        saAnesthesia: false,
        eaAnesthesia: false,
        gaAnesthesia: false,
      },
    ]);

    setType((prevStates) => [...prevStates,"elective"]);
  };

  const removeAdditionalSurgicalOption = () => {
    if (additionalSurgicalOptions.length > 0) {
      const updatedOptions = [...additionalSurgicalOptions];

      updatedOptions.pop();

      setAdditionalSurgicalOptions(updatedOptions);
    }
  };

  const [procedureTables, setProcedureTables] = useState([
    [
      {
        procedureSurgical: "",
        pcdHospitalCodeSurgical: "",
      },
    ],
  ]);

  const handleChangeRow5 = (tableIndex, rowIndex, key, value) => {
    const newTables = [...procedureTables];
    newTables[tableIndex][rowIndex][key] = value;
    setProcedureTables(newTables);
  };

  const addRow5 = (tableIndex) => {
    const newTables = [...procedureTables];

    if (
      !Array.isArray(newTables[tableIndex]) ||
      newTables[tableIndex].length === 0
    ) {
      newTables[tableIndex] = [
        {
          procedureSurgical: "",
          pcdHospitalCodeSurgical: "",
        },
      ];
    } else {
      newTables[tableIndex] = [
        ...newTables[tableIndex],
        {
          procedureSurgical: "",
          pcdHospitalCodeSurgical: "",
        },
      ];
    }
    setProcedureTables(newTables);
  };

  const removeRow5 = (tableIndex, rowIndex) => {
    const newTables = [...procedureTables];
    newTables[tableIndex].splice(rowIndex, 1);
    setProcedureTables(newTables);
  };

  const handleChangeBillingInfo = (event) => {
    const { name, value } = event.target;
    setBillingInfo({
      ...billingInfo,
      [name]: value,
    });
  };

  const handleChangeRowBillingItem = (index, key, value) => {
    const newRows = [...rows6];
    newRows[index][key] = value;
    setRows6(newRows);
  };

  const addRow6 = () => {
    setRows6([
      ...rows6,
      {
        costOfHospitalization: "",
        totalBillAmount: "",
      },
    ]);
  };

  const removeRow6 = (index) => {
    const newRows = [...rows6];
    newRows.splice(index, 1);
    setRows6(newRows);
  };

  

  const handleChangeRowDocuments = (index, key, value) => {
    const updatedRows = [...rows7];
    updatedRows[index][key] = value;
    setRows7(updatedRows);
  };

  const addRow7 = () => {
    setRows7((prevRows) => [
      ...prevRows,
      {
        Name:'photoIdentityCard',
        Document_file: null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow7 = (index) => {
    setRows7((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const handleChangeRowDocuments2 = (index, key, value) => {
    const updatedRows = [...rows8];
    updatedRows[index][key] = value;
    setRows8(updatedRows);
  };

  const addRow8 = () => {
    setRows8((prevRows) => [
      ...prevRows,
      {
        Name:'signedForm',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack:false,

      },
    ]);
  };
  const removeRow8 = (index) => {
    setRows8((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  const handleChangeRowDocuments3 = (index, key, value) => {
    const updatedRows = [...rows9];
    updatedRows[index][key] = value;
    setRows9(updatedRows);
  };

  const addRow9 = () => {
    setRows9((prevRows) => [
      ...prevRows,
      {
        Name:'otherDocument',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow9 = (index) => {
    setRows9((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  // Enhanced

  const handleChangeRowDocuments4 = (index, key, value) => {
    const updatedRows = [...rows10];
    updatedRows[index][key] = value;
    setRows10(updatedRows);
  };

  const addRow10 = () => {
    setRows10((prevRows) => [
      ...prevRows,
      {
        doctorsLetterFile: "",
        doctorsLetterRemarks: "",
        doctorsLetterDate: "",
        doctorsLetterACK: false,
      },
    ]);
  };
  const removeRow10 = (index) => {
    setRows10((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-------

  const handleChangeRowDocuments5 = (index, key, value) => {
    const updatedRows = [...rows11];
    updatedRows[index][key] = value;
    setRows11(updatedRows);
  };

  const addRow11 = () => {
    setRows11((prevRows) => [
      ...prevRows,
      {
        interimBillFile: "",
        interimBillRemarks: "",
        interimBillDate: "",
        interimBillACK: false,
      },
    ]);
  };
  const removeRow11 = (index) => {
    setRows11((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-----

  const handleChangeRowDocuments6 = (index, key, value) => {
    const updatedRows = [...rows12];
    updatedRows[index][key] = value;
    setRows12(updatedRows);
  };

  const addRow12 = () => {
    setRows12((prevRows) => [
      ...prevRows,
      {
        enhancedDocument_file: "",
        enhancedDocument_Remarks: "",
        enhancedDocument_Date: "",
        enhancedDocument_Ack: false,
      },
    ]);
  };
  const removeRow12 = (index) => {
    setRows12((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //------

  const handleChangeRowDocuments7 = (index, key, value) => {
    const updatedRows = [...rows13];
    updatedRows[index][key] = value;
    setRows13(updatedRows);
  };

  const addRow13 = () => {
    setRows13((prevRows) => [
      ...prevRows,
      {
        investigationReport: "",
        investigationReportRemarks: "",
        investigationReportDate: "",
        investigationReportACK: false,
      },
    ]);
  };
  const removeRow13 = (index) => {
    setRows13((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments8 = (index, key, value) => {
    const updatedRows = [...rows14];
    updatedRows[index][key] = value;
    setRows14(updatedRows);
  };

  const addRow14 = () => {
    setRows14((prevRows) => [
      ...prevRows,
      {
        investigationReport: "",
        investigationReportRemarks: "",
        investigationReportDate: "",
        investigationReportACK: false,
      },
    ]);
  };
  const removeRow14 = (index) => {
    setRows14((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments9 = (index, key, value) => {
    const updatedRows = [...rows15];
    updatedRows[index][key] = value;
    setRows15(updatedRows);
  };

  const addRow15 = () => {
    setRows15((prevRows) => [
      ...prevRows,
      {
        dischargeSummaryFile: "",
        dischargeSummaryRemarks: "",
        dischargeSummaryDate: "",
        dischargeSummaryACK: false,
      },
    ]);
  };
  const removeRow15 = (index) => {
    setRows15((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments10 = (index, key, value) => {
    const updatedRows = [...rows16];
    updatedRows[index][key] = value;
    setRows16(updatedRows);
  };

  const addRow16 = () => {
    setRows16((prevRows) => [
      ...prevRows,
      {
        dischargeSummaryDocument_file: "",
        dischargeSummaryDocument_Remarks: "",
        dischargeSummaryDocument_Date: "",
        dischargeSummaryDocument_Ack: false,
      },
    ]);
  };
  const removeRow16 = (index) => {
    setRows16((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //--- useEffect


  useEffect(()=>{

    if(Object.keys(IPPatientDetailes).length !== 0){

    console.log('IPPatientDetailes',IPPatientDetailes)

    setpersionalformData((prev)=>({
      ...prev,
      MRN: IPPatientDetailes.Booking_Id,
      PatientName: IPPatientDetailes.PatientName,
      Gender: IPPatientDetailes.Gender,
      DOB: IPPatientDetailes.DOB.trim(),
      Age: IPPatientDetailes.Age,
      ContactNumber:IPPatientDetailes.PatientPhoneNo,
      Address:IPPatientDetailes.street+','+IPPatientDetailes.city+','+IPPatientDetailes.state,
      PinCode: IPPatientDetailes.Pincode,

    }))

    setFormDataProfile((prev)=>({
      ...prev,
      mrn:IPPatientDetailes.Booking_Id,
      requestedAmount:IPPatientDetailes.PreAuthAmount,
    }))

    setPapersstatus('DRAFT')
    setPapersstatustype('ORIGINAL')

    if(IPPatientDetailes.AdmissionPurpose === "MedicalLegalCase"){
      setMedicalLegalCase('Yes')
      setMLCdetails((prev)=>({
        ...prev,
        InformedBY: IPPatientDetailes.InformedBY,
        OfficerName:IPPatientDetailes.OfficerName,
        Policestation:IPPatientDetailes.Policestation,
        FIRNo:IPPatientDetailes.FIRNo,
        CodeStatus: IPPatientDetailes.CodeStatus,
        TypeOfAdmit: IPPatientDetailes.Typeofadmit,
        Admitting_diagnosis: IPPatientDetailes.Admitting_diagnosis,
      }))
    

    }
    if(IPPatientDetailes.RatecardType === 'Insurance' && IPPatientDetailes.PatientCategory === 'Insurance' ){

      setInsuranceCompanyformdata((prev)=>({
        ...prev,
        InsuranceCompany:  IPPatientDetailes.InsuranceName,
        PolicyNumber:  IPPatientDetailes.Cardno,
        PolicyType:  IPPatientDetailes.Scheme,
        PayerTPAName: IPPatientDetailes.TPA,
      }))

      }else if(IPPatientDetailes.RatecardType === 'ESI' && IPPatientDetailes.PatientCategory === 'ESI'){
        setEsicCoverage('Yes')
        setEmployed('Yes')
        setESINumberstate(123456789)
        setCorporatedetails((prev)=>({
          ...prev,
          CorporateName: 'KTV FOOD',
          EmployeeID:'EMP1212'
        }))
      }


    }

  },[IPPatientDetailes])


  // console.log('ESINumberstate',ESINumberstate)


  // medical hist

  // console.log('formDataTxtarea',formDataTxtarea)

  // console.log('medical hist 1',diabetes,diabetesDuration,diabetesMedication)

  // console.log('medical hist 2',hyperTension,hyperTensionDuration,hyperTensionMedication)

  // console.log('medical hist 3',hyperlipiDemias,hyperlipiDemiasDuration,hyperlipiDemiasMedication)

  // console.log('medical hist 4',osteoarthritis,osteoarthritisDuration,osteoarthritisMedication)

  // console.log('medical hist 5',bronchialAsthma,bronchialAsthmaDuration,bronchialAsthmaMedication)

  // console.log('medical hist 6',cvd,cvdDuration,cvdMedication)

  // console.log('medical hist 7',cva,cvaDuration,cvaMedication)

  // console.log('medical hist 8',kochs,kochsDuration,kochsMedication)

  // console.log('medical hist 9',malignancy,malignancyDuration,malignancyMedication)

  // console.log('medical hist 10',alcohol,alcoholDuration,alcoholNotes)

  // console.log('medical hist 11',smoking,smokingDuration,smokingNotes)

  // console.log('medical hist 12',hiv,hivDuration,hivNotes)

  // console.log('medical hist 13',hbSAg,hbSAgDuration,hbSAgNotes)

  // console.log('medical hist 14',hcv,hcvDuration,hcvNotes)

  // console.log('medical hist 15',diabetesFamily,diabetesRelationship,diabetesNotes)

  // console.log('medical hist 16',hyperTensionFamily,hyperTensionRelationshipFamily,hyperTensionNotesFamily)

  // console.log('medical hist 17',kochsFamily,kochsRelationship,kochsNotes)

  // console.log('medical hist 18',geneticDisorderFamily,geneticDisoderRelationship,geneticDisoderNotes)


  // console.log('medical hist 19',medication)

  // ---------------------EXamination

  // console.log('22133',RelevantClinicalFindings)


  // ---------------------Diagnosis

   console.log('row1',rows)

  console.log('row2',rows2)





  




 

  // console.log('row3',rows3)


  // const cleardata = () => {
  //   setpersionalformData({
  //     MRN: "",
  //     PatientName: "",
  //     Gender: "",
  //     DOB: "",
  //     Age: "",
  //     ContactNumber: "",
  //     Address: "",
  //     PinCode: "",
  //   });
  //   setInsuranceCompanyformdata({
  //     InsuranceCompany: "",
  //     PolicyNumber: "",
  //     PolicyType: "",
  //     PayerTPAName: "",
  //     PayerTPAZone: "",
  //     PayerTPAMemberID: "",
  //   });
  //   setEsicCoverage("");
  //   setESINumberstate("");
  //   setEmployed("");
  //   setCorporatedetails({
  //     CorporateName:"",
  //     EmployeeID:"",
  //   });
  //   setMedicalLegalCase("");
  //   setMLCdetails({
  //     InformedBY:"",
  //     OfficerName:"",
  //     Policestation:"",
  //     FIRNo:"",
  //     CodeStatus:"",
  //     TypeOfAdmit:"",
  //     Admitting_diagnosis:"",
  //   });
  //   setFormDataTxtarea({
  //     ComplaintsDuration: "",
  //     HistoryPresentingIllness: "",
  //     PastHistory: "",
  //   });
  //   setDiabetes("");
  //   setDiabetesDuration("");
  //   setDiabetesMedication("");
  //   setHyperTension("");
  //   setHyperTensionDuration("");
  //   setHyperTensionMedication("");
  //   setHyperlipiDemias("");
  //   setHyperlipiDemiasDuration("");
  //   setHyperlipiDemiasMedication("");
  //   setOsteoarthritis("");
  //   setOsteoarthritisDuration("");
  //   setOsteoarthritisMedication("");
  //   setBronchialAsthma("");
  //   setBronchialAsthmaDuration("");
  //   setBronchialAsthmaMedication("");
  //   setCvd("");
  //   setCvdDuration("");
  //   setCvdMedication("");
  //   setCva("");
  //   setCvaDuration("");
  //   setCvaMedication("");
  //   setKochs("");
  //   setKochsDuration("");
  //   setKochsMedication("");
  //   setMalignancy("");
  //   setMalignancyDuration("");
  //   setAlcohol("");
  //   setAlcoholDuration("");
  //   setAlcoholNotes("");
  //   setSmoking("");
  //   setSmokingDuration("");
  //   setSmokingNotes("");
  //   setHiv("");
  //   setHivDuration("");
  //   setHivNotes("");
  //   setHivNotes("");
  //   setHbSAgDuration("");
  //   setHbSAgNotes("");
  //   setHCV("");
  //   setHCVDuration("");
  //   setHCVNotes("");
  //   setDiabetesFamily("");
  //   setDiabetesRelationship("");
  //   setDiabetesNotes("");
  //   setHyperTensionFamily("");
  //   setHyperTensionRelationshipFamily("");
  //   setHyperTensionNotesFamily("");
  //   setKochsFamily("");
  //   setKochsRelationship("");
  //   setKochsNotes("");
  //   setGeneticDisorderFamily("");
  //   setGeneticDisoderRelationship("");
  //   setGeneticDisoderNotes("");
  //   setMedication([{
  //     medicationName: "",
  //     medicationDuration: "",
  //   }]);
  //   setRelevantClinicalFindings("");

  // };


  const handleSubmit = () => {
    
    const params={      
      Location:userRecord.location,
      createAt:userRecord.username,
      persionalformData:persionalformData,
      InsuranceCompanyformdata:InsuranceCompanyformdata,
      employed:employed,
      Corporatedetails:Corporatedetails,
      
      MedicalLegalCase:MedicalLegalCase,
      MLCdetails:MLCdetails,
      formDataTxtarea: formDataTxtarea,

      esicCoveragedata:{
        esicCoverage:esicCoverage,
        ESINumberstate:ESINumberstate,

      },
      diabetesdata:{
        diabetes:diabetes,
        diabetesDuration:diabetesDuration,
        diabetesMedication:diabetesMedication,
      },
      hyperTensiondata:{
        hyperTension:hyperTension,
        hyperTensionDuration:hyperTensionDuration,
        hyperTensionMedication:hyperTensionMedication,

      },
      hyperlipiDemiasdata:{
        hyperlipiDemias:hyperlipiDemias,
        hyperlipiDemiasDuration:hyperlipiDemiasDuration,
        hyperlipiDemiasMedication:hyperlipiDemiasMedication,

      },
      osteoarthritisdata:{
        osteoarthritis:osteoarthritis,
        osteoarthritisDuration:osteoarthritisDuration,
        osteoarthritisMedication:osteoarthritisMedication,

      },
      bronchialAsthmadata:{
        bronchialAsthma:bronchialAsthma,
        bronchialAsthmaDuration:bronchialAsthmaDuration,
        bronchialAsthmaMedication:bronchialAsthmaMedication,

      },

      cvddata:{
        cvd:cvd,
        cvdDuration:cvdDuration,
        cvdMedication:cvdMedication,

      },

      cvadata:{
        cva:cva,
        cvaDuration:cvaDuration,
        cvaMedication:cvaMedication,

      },
      kochsdata:{
        kochs:kochs,
        kochsDuration:kochsDuration,
        kochsMedication:kochsMedication,

      },
      malignancydata:{
        malignancy:malignancy,
        malignancyDuration:malignancyDuration,
        malignancyMedication:malignancyMedication,
      },
      alcoholdata:{
        alcohol:alcohol,
        alcoholDuration:alcoholDuration,
        alcoholNotes:alcoholNotes,
      },
      smokingdata:{
        smoking:smoking,
        smokingDuration:smokingDuration,
        smokingNotes:smokingNotes,
      },
      hivdata:{
        hiv:hiv,
        hivDuration:hivDuration,
        hivNotes:hivNotes,
      },
      hbSAgdata:{
        hbSAg:hbSAg,
        hbSAgDuration:hbSAgDuration,
        hbSAgNotes:hbSAgNotes,
      },
      hcvdata:{
        hcv:hcv,
        hcvDuration:hcvDuration,
        hcvNotes:hcvNotes,
      },



      diabetesFamilydata:{
        diabetesFamily:diabetesFamily,
        diabetesRelationship:diabetesRelationship,
        diabetesNotes:diabetesNotes,
      },
      hyperTensionFamilydata:{
        hyperTensionFamily:hyperTensionFamily,
        hyperTensionRelationshipFamily:hyperTensionRelationshipFamily,
        hyperTensionNotesFamily:hyperTensionNotesFamily,
      },
      kochsFamilydata:{
        kochsFamily:kochsFamily,
        kochsRelationship:kochsRelationship,
        kochsNotes:kochsNotes,
      },
      geneticDisorderFamilydata:{
        geneticDisorderFamily:geneticDisorderFamily,
        geneticDisoderRelationship:geneticDisoderRelationship,
        geneticDisoderNotes:geneticDisoderNotes,
      },
      
      medication:medication,

      RelevantClinicalFindingsdata:{
        RelevantClinicalFindings:RelevantClinicalFindings

      },

      provisionaldiagnosis:{
        rows:rows
      },
      finaldiagnosis:{
        rows2:rows2
      },


      billingInfo:billingInfo,

      Billdetaile:{
        rows6:rows6
      },

      TreatmentFisdata:{
      rows3:rows3
      },
      
      TreatmentSecdata:{
      checkboxStateTreatment:checkboxStateTreatment
      },
      medicalManagement:medicalManagement,
      TreatmentTxtareaDetails:TreatmentTxtareaDetails,
      
      TreatmentThidata:{
      rows4:rows4},

      surgicalManagement:surgicalManagement,

      TreatmentFoudata:{
      additionalSurgicalOptions:additionalSurgicalOptions
      },

      TreatmentFivdata:{
      checkboxStates:checkboxStates
      },

      TreatmentSidata:{
      procedureTables:procedureTables
      },
      Treatmenttype:type,
    }


    console.log('1212params',params)

    const formData = new FormData();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        if (typeof value === 'object' && value !== null && !(value instanceof File)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }

    formData.append('rows7', JSON.stringify(rows7));
    formData.append('rows8', JSON.stringify(rows8));
    formData.append('rows9', JSON.stringify(rows9));
    
    // Append Document_file from each row to formData
    const appendFiles = (formData, rows, prefix) => {
        rows.forEach((row, index) => {
            if (row && row.Document_file) {
                formData.append(`${prefix}[${index}][Document_file]`, row.Document_file);
            }
        });
    };
    
    appendFiles(formData, rows7, 'rows7');
    appendFiles(formData, rows8, 'rows8');
    appendFiles(formData, rows9, 'rows9');


    axios.post(`https://vesoftometic.co.in/Insurance/insert_preAuth_form`,formData)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        // cleardata()
    })
    .catch((error) => {
        console.error(error);
    });

  };



  // const handle_save = () => {
  
  //   const params = {
  //     formDataTxtarea: formDataTxtarea,
  //     // Make sure formDataTxtarea is properly defined before using it
  //   };
  
  //   axios.post('https://vesoftometic.co.in/Insurance/insert_Medical_History', params)
  //     .then((response) => {
  //       console.log('Response:', response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  const handleSave = () => {
    console.log("Form data saved.");
  };

  const handleCancel = () => {
    console.log("Changes Cancel.");
  };



 

 



  return (
    <>
      <div className="toggle_head_insuran">
        <button onClick={toggleCardVisibility} className="launch_toggle_isu">
          <LaunchIcon />
        </button>
        <button onClick={toggleTabVisibility} className="launch_toggle_isu">
          <MenuOpenIcon />
        </button>
      </div>
      <div className="dashboard34">
        <div
          className={`card profile-card ${isCardCollapsed ? "collapsed" : ""}`}
        >
          <h3>Status</h3>
          <div className="aproved_div_pro">
            <span>{Papersstatus}</span>
            <p>({Papersstatustype})</p>
          </div>
          <br></br>
          <div className="profile-header">
            <img src={LogoImg} alt="Profile" className="profile-image" />
          </div>
          <br></br>
          <div className="profile_name_insu">
            <span>{persionalformData.PatientName}  </span>
          </div>
          <div className="profile-info-auth">
            <div className="input-group-grp2">
              <label htmlFor="mrn">MRN :</label>
              <input
                type="text"
                id="mrn"
                value={formDataProfile.mrn}
                placeholder="Enter MRN"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="alNumber">AL Number :</label>
              <input
                type="text"
                id="alNumber"
                value={formDataProfile.alNumber}
                placeholder="Enter Al No"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="clNumber">Cl Number :</label>
              <input
                type="text"
                id="clNumber"
                value={formDataProfile.clNumber}
                placeholder="Enter Cl No"
                onChange={handleChangeProfile}
              />
            </div>
            <div className="input-group-grp2">
              <label htmlFor="InwardNumber">Inward Number :</label>
              <input
                type="text"
                id="inwardNumber"
                value={formDataProfile.inwardNumber}
                placeholder="Enter Inward No"
                onChange={handleChangeProfile}
              />
            </div>

            <br></br>
            <hr></hr>
            <div className="amount-section">
              <h4>Amount</h4>
              <br></br>
              <div className="amount-inputs">
                <div className="input-group-amount">
                  <label htmlFor="requestedAmount">
                    Requested <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="requestedAmount"
                    value={formDataProfile.requestedAmount}
                    onChange={handleChangeProfile}
                  />
                </div>
              </div>
              <br></br>
              <div className="amount-inputs">
                <div className="input-group-amount">
                  <label htmlFor="approvedAmount">
                    Approved <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="approvedAmount"
                    value={formDataProfile.approvedAmount}
                    onChange={handleChangeProfile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card content-card ${
            isCardCollapsed && window.innerWidth <= 950
              ? "profile-card-open"
              : ""
          }`}
          onClick={handleContentCardClick}
        >
          <div>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expanded !== "panel2" &&
                expanded !== "panel3" &&
                expanded !== "panel4" &&
                expanded !== "panel5" &&
                expanded !== "panel6" &&
                expanded !== "panel7" &&
                expanded !== "panel8" &&
                expanded !== "panel9" &&
                expanded !== "panel10" &&
                expanded !== "panel11"
              }
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Pre- Auth Form
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="h_heade34">
                      <h3>Patient Info</h3>
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        MRN <span>:</span>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter MRN"
                        name="MRN"
                        value={persionalformData.MRN}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Patient Name <span>:</span>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Patient Name"
                        name="PatientName"
                        value={persionalformData.PatientName}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label htmlFor="Title">
                        Gender <span>:</span>
                      </label>
                      <select
                        name="Gender"
                        value={persionalformData.Gender}
                        onChange={handleInputChange1}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="TransGender">TransGender</option>
                      </select>
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Date Of Birth <span>:</span>{" "}
                      </label>
                      <input
                        type="date"
                        placeholder="Enter Date Of Birth"
                        name="DOB"
                        value={persionalformData.DOB}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Age <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Age"
                        name="Age"
                        value={persionalformData.Age}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>

                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Contact Number <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Contact Number"
                        name="ContactNumber"
                        value={persionalformData.ContactNumber}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        Address <span>:</span>
                      </label>
                      <textarea
                        name="Address"
                        className="txt-ara-lclprntm"
                        placeholder="Enter Address"
                        value={persionalformData.Address}
                        onChange={handleInputChange1}
                        required
                      ></textarea>
                    </div>
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                      <label>
                        {" "}
                        Pin Code <span>:</span>{" "}
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Pin Code"
                        name="PinCode"
                        value={persionalformData.PinCode}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                  </div>

                  <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance  Spl_backcolr_09_bottom">
                      <label>
                        ESI Coverage <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="esicYes"
                            name="ESICoverage"
                            value="Yes"
                            checked={esicCoverage === "Yes"}
                            onChange={handleCheckboxChange}
                          ></input>

                          <label htmlFor="esicYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="esicNo"
                            name="ESICoverage"
                            value="No"
                            checked={esicCoverage === "No"}
                            onChange={handleCheckboxChange}
                          ></input>
                          <label htmlFor="esicNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {esicCoverage === "Yes" && (
                    <>
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            ESI Number <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter ESI Number"
                            name="ESINumber"
                            value={ESINumberstate}
                            onChange={(e)=>{setESINumberstate(e.target.value)}}
                            required
                          />
                        </div>
                        </div>
                    </>
                  )}
                  {esicCoverage === "No" && (
                    <>
                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Insurance Company <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Insurance Company"
                            name="InsuranceCompany"
                            value={InsuranceCompanyformdata.InsuranceCompany}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Policy Number<span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Policy Number"
                            name="PolicyNumber"
                            value={InsuranceCompanyformdata.PolicyNumber}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Policy Type <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Policy Type "
                            name="PolicyType"
                            value={InsuranceCompanyformdata.PolicyType}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                      </div>

                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Name <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            name="PayerTPAName"
                            value={InsuranceCompanyformdata.PayerTPAName}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Zone <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Zone"
                            name="PayerTPAZone"
                            value={InsuranceCompanyformdata.PayerTPAZone}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Payer/TPA Member ID <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Member ID"
                            name="PayerTPAMemberID"
                            value={InsuranceCompanyformdata.PayerTPAMemberID}
                            onChange={InsuranceChangefun}
                            required
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                      <label>
                        Employed ? <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="employedYes"
                            name="Employed"
                            value="Yes"
                            checked={employed === "Yes"}
                            onChange={handleCheckboxChange2}
                          ></input>

                          <label htmlFor="employedYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="employedNo"
                            name="Employed"
                            value="No"
                            checked={employed === "No"}
                            onChange={handleCheckboxChange2}
                          ></input>
                          <label htmlFor="employedNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                 
                  {employed === "Yes" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                        <label>
                          Corporate Name <span>:</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Corporate Name"
                          name="CorporateName"
                          value={Corporatedetails.CorporateName}
                          onChange={CorporateChangefun}
                          required
                        />
                      </div>
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                        <label>
                          Employee ID <span>:</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Employee ID"
                          name="EmployeeID"
                          value={Corporatedetails.EmployeeID}
                          onChange={CorporateChangefun}
                          required
                        />
                      </div>
                    </div>
                  )}

               <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                      <label>
                        Medico-Legal Case <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoYes"
                            name="MedicoLegalCase"
                            value="Yes"
                            checked={MedicalLegalCase === "Yes"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>

                          <label htmlFor="MedicoYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoNo"
                            name="MedicoLegalCase"
                            value="No"
                            checked={MedicalLegalCase === "No"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>
                          <label htmlFor="MedicoNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  { MedicalLegalCase=== "Yes" && (
                    <>
                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Informed By <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Informed By"
                            name="InformedBY"
                            value={MLCdetails.InformedBY}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Officer Name<span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Officer Name"
                            name="OfficerName"
                            value={MLCdetails.OfficerName}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Police Station <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Police Station"
                            name="Policestation"
                            value={MLCdetails.Policestation}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                      </div>

                      <div className="RegisFormcon column_regisFormcon_forinsurance">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            FIR No <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter FIR No"
                            name="FIRNo"
                            value={MLCdetails.FIRNo}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Code Status <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Code Status"
                            name="CodeStatus"
                            value={MLCdetails.CodeStatus}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Type Of Admit <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Type Of Admit"
                            name="TypeOfAdmit"
                            value={MLCdetails.TypeOfAdmit}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Admitting Diagnosis <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Admitting Diagnosis"
                            name="Admitting_diagnosis"
                            value={MLCdetails.Admitting_diagnosis}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                      </div>
                    </>
                  )}

                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Medical History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="medical_hstry_txtars3">
                    <div className="txtars3_wit_hdj">
                      <label>Chief Complaints & Duration</label>
                      <textarea
                        name="ComplaintsDuration"
                        placeholder="Enter Complaints & Duration"
                        value={formDataTxtarea.ComplaintsDuration}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>
                    <div className="txtars3_wit_hdj">
                      <label>History of Presenting Illness</label>
                      <textarea
                        name="HistoryPresentingIllness"
                        placeholder="Enter History of Presenting Illness"
                        value={formDataTxtarea.HistoryPresentingIllness}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>
                    <div className="txtars3_wit_hdj">
                      <label>Past History</label>
                      <textarea
                        name="PastHistory"
                        placeholder="Enter Past History"
                        value={formDataTxtarea.PastHistory}
                        onChange={handleTextAreaChange}
                      ></textarea>
                    </div>
                    <br></br>

                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="HistoryOfChronicIllness">
                              History Of Chronic Illness
                            </th>
                            <th className="wiejdwi8">Duration in Years</th>
                            <th className="wiejdwi8">Medication</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Diabetes <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="diabetesYes"
                                      name="diabetesYes"
                                      value="Yes"
                                      checked={diabetes === "Yes"}
                                      onChange={handleCheckboxChange3}
                                    ></input>

                                    <label htmlFor="diabetesYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="diabetesNo"
                                      name="diabetesNo"
                                      value="No"
                                      checked={diabetes === "No"}
                                      onChange={handleCheckboxChange3}
                                    ></input>
                                    <label htmlFor="diabetesNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {diabetes == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={diabetesDuration}
                                  onChange={handleDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {diabetes == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={diabetesMedication}
                                  onChange={handleMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Hypertension <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionYes"
                                      name="hyperTensionYes"
                                      value="Yes"
                                      checked={hyperTension === "Yes"}
                                      onChange={handleCheckboxChange4}
                                    ></input>

                                    <label htmlFor="hyperTensionYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionNo"
                                      name="hyperTensionNo"
                                      value="No"
                                      checked={hyperTension === "No"}
                                      onChange={handleCheckboxChange4}
                                    ></input>
                                    <label htmlFor="hyperTensionNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {hyperTension == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={hyperTensionDuration}
                                  onChange={handleHyperTensionDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {hyperTension == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={hyperTensionMedication}
                                  onChange={handleHyperTensionMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Hyperlipidemias <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperlipiDemiasYes"
                                      name="hyperlipiDemiasYes"
                                      value="Yes"
                                      checked={hyperlipiDemias === "Yes"}
                                      onChange={handleCheckboxChange5}
                                    ></input>

                                    <label htmlFor="hyperlipiDemiasYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperlipiDemiasNo"
                                      name="hyperlipiDemiasNo"
                                      value="No"
                                      checked={hyperlipiDemias === "No"}
                                      onChange={handleCheckboxChange5}
                                    ></input>
                                    <label htmlFor="hyperlipiDemiasNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {hyperlipiDemias == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={hyperlipiDemiasDuration}
                                  onChange={handleHyperlipiDemiasDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {hyperlipiDemias == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={hyperlipiDemiasMedication}
                                  onChange={
                                    handleHyperlipiDemiasMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Osteoarthritis <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="osteoarthritisYes"
                                      name="osteoarthritisYes"
                                      value="Yes"
                                      checked={osteoarthritis === "Yes"}
                                      onChange={handleCheckboxChange6}
                                    ></input>

                                    <label htmlFor="osteoarthritisYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="osteoarthritisNo"
                                      name="osteoarthritisNo"
                                      value="No"
                                      checked={osteoarthritis === "No"}
                                      onChange={handleCheckboxChange6}
                                    ></input>
                                    <label htmlFor="osteoarthritisNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {osteoarthritis == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={osteoarthritisDuration}
                                  onChange={handleOsteoarthritisDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {osteoarthritis == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={osteoarthritisMedication}
                                  onChange={
                                    handleOsteoarthritisMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Bronchial Asthma <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="bronchialAsthmaYes"
                                      name="bronchialAsthmaYes"
                                      value="Yes"
                                      checked={bronchialAsthma === "Yes"}
                                      onChange={handleCheckboxChange7}
                                    ></input>

                                    <label htmlFor="bronchialAsthmaYes">
                                      Yes
                                    </label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="bronchialAsthmaNo"
                                      name="bronchialAsthmaNo"
                                      value="No"
                                      checked={bronchialAsthma === "No"}
                                      onChange={handleCheckboxChange7}
                                    ></input>
                                    <label htmlFor="bronchialAsthmaNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {bronchialAsthma == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={bronchialAsthmaDuration}
                                  onChange={handleBronchialAsthmaDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {bronchialAsthma == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={bronchialAsthmaMedication}
                                  onChange={
                                    handleBronchialAsthmaMedicationChange
                                  }
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  C.V.D <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvdYes"
                                      name="cvdYes"
                                      value="Yes"
                                      checked={cvd === "Yes"}
                                      onChange={handleCheckboxChange8}
                                    ></input>

                                    <label htmlFor="cvdYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvdNo"
                                      name="cvdNo"
                                      value="No"
                                      checked={cvd === "No"}
                                      onChange={handleCheckboxChange8}
                                    ></input>
                                    <label htmlFor="cvdNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {cvd == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={cvdDuration}
                                  onChange={handleCvdDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {cvd == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={cvdMedication}
                                  onChange={handleCvdMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  C.V.A <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvaYes"
                                      name="cvaYes"
                                      value="Yes"
                                      checked={cva === "Yes"}
                                      onChange={handleCheckboxChange9}
                                    ></input>

                                    <label htmlFor="cvaYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="cvaNo"
                                      name="cvaNo"
                                      value="No"
                                      checked={cva === "No"}
                                      onChange={handleCheckboxChange9}
                                    ></input>
                                    <label htmlFor="cvaNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {cva == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={cvaDuration}
                                  onChange={handleCvaDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {cva == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={cvaMedication}
                                  onChange={handleCvaMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Kochs <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsYes"
                                      name="kochsYes"
                                      value="Yes"
                                      checked={kochs === "Yes"}
                                      onChange={handleCheckboxChange10}
                                    ></input>

                                    <label htmlFor="kochsYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsNo"
                                      name="kochsNo"
                                      value="No"
                                      checked={kochs === "No"}
                                      onChange={handleCheckboxChange10}
                                    ></input>
                                    <label htmlFor="kochsNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {kochs == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={kochsDuration}
                                  onChange={handleKochsDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {kochs == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={kochsMedication}
                                  onChange={handleKochsMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Malignancy <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="malignancyYes"
                                      name="malignancyYes"
                                      value="Yes"
                                      checked={malignancy === "Yes"}
                                      onChange={handleCheckboxChange11}
                                    ></input>

                                    <label htmlFor="malignancyYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="malignancyNo"
                                      name="malignancyNo"
                                      value="No"
                                      checked={malignancy === "No"}
                                      onChange={handleCheckboxChange11}
                                    ></input>
                                    <label htmlFor="malignancyNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {malignancy == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={malignancyDuration}
                                  onChange={handleMalignancyDurationChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {malignancy == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={malignancyMedication}
                                  onChange={handleMalignancyMedicationChange}
                                ></input>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="HistoryOfChronicIllness">
                            Personal History
                          </th>
                          <th className="wiejdwi8">Duration in Years</th>
                          <th className="wiejdwi8">Notes</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                Alcohol <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="alcoholYes"
                                    name="alcoholYes"
                                    value="Yes"
                                    checked={alcohol === "Yes"}
                                    onChange={handleCheckboxChangeAlcohol}
                                  ></input>

                                  <label htmlFor="alcoholYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="alcoholNo"
                                    name="alcoholNo"
                                    value="No"
                                    checked={alcohol === "No"}
                                    onChange={handleCheckboxChangeAlcohol}
                                  ></input>
                                  <label htmlFor="alcoholNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {alcohol == "Yes" && (
                              <input
                                type="text"
                                className="duration_90"
                                value={alcoholDuration}
                                onChange={handleAlcoholDurationChange}
                              ></input>
                            )}
                          </td>
                          <td>
                            {" "}
                            {alcohol == "Yes" && (
                              <input
                                type="text"
                                className="medication_90"
                                value={alcoholNotes}
                                onChange={handleAlcoholNotesChange}
                              ></input>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                Smoking <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="smokingYes"
                                    name="smokingYes"
                                    value="Yes"
                                    checked={smoking === "Yes"}
                                    onChange={handleCheckboxChangeSmoking}
                                  ></input>

                                  <label htmlFor="smokingYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="smokingNo"
                                    name="smokingNo"
                                    value="No"
                                    checked={smoking === "No"}
                                    onChange={handleCheckboxChangeSmoking}
                                  ></input>
                                  <label htmlFor="smokingNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {smoking == "Yes" && (
                              <input
                                type="text"
                                className="duration_90"
                                value={smokingDuration}
                                onChange={handleSmokingDurationChange}
                              ></input>
                            )}
                          </td>
                          <td>
                            {" "}
                            {smoking == "Yes" && (
                              <input
                                type="text"
                                className="medication_90"
                                value={smokingNotes}
                                onChange={handleSmokingNotesChange}
                              ></input>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                HIV <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hivYes"
                                    name="hivYes"
                                    value="Yes"
                                    checked={hiv === "Yes"}
                                    onChange={handleCheckboxChangeHIV}
                                  ></input>

                                  <label htmlFor="hivYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hivNo"
                                    name="hivNo"
                                    value="No"
                                    checked={hiv === "No"}
                                    onChange={handleCheckboxChangeHIV}
                                  ></input>
                                  <label htmlFor="hivNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {hiv == "Yes" && (
                              <input
                                type="text"
                                className="duration_90"
                                value={hivDuration}
                                onChange={handleHIVDurationChange}
                              ></input>
                            )}
                          </td>
                          <td>
                            {" "}
                            {hiv == "Yes" && (
                              <input
                                type="text"
                                className="medication_90"
                                value={hivNotes}
                                onChange={handleHIVNotesChange}
                              ></input>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                HbSAg <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hbSAgYes"
                                    name="hbSAgYes"
                                    value="Yes"
                                    checked={hbSAg === "Yes"}
                                    onChange={handleCheckboxChangeHbSAg}
                                  ></input>

                                  <label htmlFor="hbSAgYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hbSAgNo"
                                    name="hbSAgNo"
                                    value="No"
                                    checked={hbSAg === "No"}
                                    onChange={handleCheckboxChangeHbSAg}
                                  ></input>
                                  <label htmlFor="hbSAgNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {hbSAg == "Yes" && (
                              <input
                                type="text"
                                className="duration_90"
                                value={hbSAgDuration}
                                onChange={handleHbSAgDurationChange}
                              ></input>
                            )}
                          </td>
                          <td>
                            {" "}
                            {hbSAg == "Yes" && (
                              <input
                                type="text"
                                className="medication_90"
                                value={hbSAgNotes}
                                onChange={handleHbSAgNotesChange}
                              ></input>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                HCV <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hcvYes"
                                    name="hcvYes"
                                    value="Yes"
                                    checked={hcv === "Yes"}
                                    onChange={handleCheckboxChangeHCV}
                                  ></input>

                                  <label htmlFor="hcvYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="hcvNo"
                                    name="hcvNo"
                                    value="No"
                                    checked={hcv === "No"}
                                    onChange={handleCheckboxChangeHCV}
                                  ></input>
                                  <label htmlFor="hcvNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {hcv == "Yes" && (
                              <input
                                type="text"
                                className="duration_90"
                                value={hcvDuration}
                                onChange={handleHCVDurationChange}
                              ></input>
                            )}
                          </td>
                          <td>
                            {" "}
                            {hcv == "Yes" && (
                              <input
                                type="text"
                                className="medication_90"
                                value={hcvNotes}
                                onChange={handleHCVNotesChange}
                              ></input>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="HistoryOfChronicIllness">
                            Medication History
                          </th>
                          <th className="wiejdwi8">Duration</th>
                          <th className="add32_Code">
                            <span onClick={addRowMedication}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {console.log(medication,'medication')}
                        {medication?.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                className="Provisional_Diagnosis medication_43"
                                value={row.medicationName}
                                onChange={(e) =>
                                  handleChangeRowMedication(
                                    index,
                                    "medicationName",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="Provisional_Diagnosis medication_43"
                                value={row.medicationDuration}
                                onChange={(e) =>
                                  handleChangeRowMedication(
                                    index,
                                    "medicationDuration",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRowMedication(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="HistoryOfChronicIllness">
                            Family History
                          </th>
                          <th className="wiejdwi8">Relationship</th>
                          <th className="wiejdwi8">Notes</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div className="spac_betwn_hstychronc">
                              <label className="spac_betwn_hstychronc_label">
                                Diabetes <span>:</span>
                              </label>

                              <div className="ewj_i87_head">
                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="diabetesFamilyYes"
                                    name="diabetesFamilyYes"
                                    value="Yes"
                                    checked={diabetesFamily === "Yes"}
                                    onChange={handleCheckboxChangeFamily}
                                  ></input>

                                  <label htmlFor="diabetesFamilyYes">Yes</label>
                                </div>

                                <div className="ewj_i87">
                                  <input
                                    type="radio"
                                    id="diabetesFamilyNo"
                                    name="diabetesFamilyNo"
                                    value="No"
                                    checked={diabetesFamily === "No"}
                                    onChange={handleCheckboxChangeFamily}
                                  ></input>
                                  <label htmlFor="diabetesFamilyNo">No</label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                              {diabetesFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={diabetesRelationship}
                                  onChange={handleRelationshipChangeFamily}
                                ></input>
                              )}
                            </td>
                            <td>
                              {diabetesFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={diabetesNotes}
                                  onChange={handleNotesChangeFamily}
                                ></input>
                              )}
                            </td>
                   
                        </tr>
                        <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Hypertension <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionFamilyYes"
                                      name="hyperTensionFamilyYes"
                                      value="Yes"
                                      checked={hyperTensionFamily === "Yes"}
                                      onChange={handleCheckboxChangeFamily2}
                                    ></input>

                                    <label htmlFor="hyperTensionFamilyYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="hyperTensionFamilyNo"
                                      name="hyperTensionFamilyNo"
                                      value="No"
                                      checked={hyperTensionFamily === "No"}
                                      onChange={handleCheckboxChangeFamily2}
                                    ></input>
                                    <label htmlFor="hyperTensionFamilyNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {hyperTensionFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={hyperTensionRelationshipFamily}
                                  onChange={handleHyperTensionRelationshipChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {hyperTensionFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={hyperTensionNotesFamily}
                                  onChange={handleHyperTensionNotesChange}
                                ></input>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Kochs <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsFamilyYes"
                                      name="kochsFamilyYes"
                                      value="Yes"
                                      checked={kochsFamily === "Yes"}
                                      onChange={handleCheckboxChangeFamily3}
                                    ></input>

                                    <label htmlFor="kochsFamilyYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="kochsFamilyNo"
                                      name="kochsFamilyNo"
                                      value="No"
                                      checked={kochsFamily === "No"}
                                      onChange={handleCheckboxChangeFamily3}
                                    ></input>
                                    <label htmlFor="kochsFamilyNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {kochsFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={kochsRelationship}
                                  onChange={handleKochsRelationshipChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {kochsFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={kochsNotes}
                                  onChange={handleKochsNotesChange}
                                ></input>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="spac_betwn_hstychronc">
                                <label className="spac_betwn_hstychronc_label">
                                  Genetic Disorder <span>:</span>
                                </label>

                                <div className="ewj_i87_head">
                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="geneticDisorderFamilyYes"
                                      name="geneticDisorderFamilyYes"
                                      value="Yes"
                                      checked={geneticDisorderFamily === "Yes"}
                                      onChange={handleCheckboxChangeFamily4}
                                    ></input>

                                    <label htmlFor="geneticDisorderFamilyYes">Yes</label>
                                  </div>

                                  <div className="ewj_i87">
                                    <input
                                      type="radio"
                                      id="geneticDisorderFamilyNo"
                                      name="geneticDisorderFamilyNo"
                                      value="No"
                                      checked={geneticDisorderFamily === "No"}
                                      onChange={handleCheckboxChangeFamily4}
                                    ></input>
                                    <label htmlFor="geneticDisorderFamilyNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {" "}
                              {geneticDisorderFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="duration_90"
                                  value={geneticDisoderRelationship}
                                  onChange={handleGeneticDisorderRelationshipChange}
                                ></input>
                              )}
                            </td>
                            <td>
                              {" "}
                              {geneticDisorderFamily == "Yes" && (
                                <input
                                  type="text"
                                  className="medication_90"
                                  value={geneticDisoderNotes}
                                  onChange={handleGeneticDisorderNotesChange}
                                ></input>
                              )}
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Examination Findings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="medical_hstry_txtars3">
                    <div className="txtars3_wit_hdj">
              a        <label>Relevant Clinical Findings</label>
                      <textarea
                        name="RelevantClinicalFindings"
                        placeholder="Enter Relevant Clinical Findings"
                        value={RelevantClinicalFindings}
                        onChange={handleTextAreaChange2}
                      ></textarea>
                    </div>
                  </div>
                  <br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Diagnosis
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Provisional Diagnosis
                          </th>
                          <th className="ICD_Code">ICD Code</th>
                          <th className="ICD_Code">Type</th>
                          <th className="add32_Code">
                            <span onClick={addRow}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {rows.map((row, index) => (
                          <tr key={index}>
                              <td>
                                  <input
                                      className="Provisional_Diagnosis"
                                      value={row.provisionalDiagnosis}
                                      onChange={(e) =>
                                        handleChangeRow(index,"provisionalDiagnosis",e.target.value)
                                      }
                                  />
                              </td>
                              <td>
                                  <input
                                      className="ICD_Code"
                                      value={row.icdCode}
                                      onChange={(e) =>
                                        handleChangeRow(index,"icdCode",e.target.value)
                                      }
                                  />
                              </td>
                              <td>
                                  <select
                                      className="Type_code"
                                      value={row.type}
                                      onChange={(e) =>
                                        handleChangeRow(index, "type", e.target.value)
                                      }
                                  >
                                      <option value="Primary">Primary</option>
                                      <option value="Secondary">Secondary</option>
                                      <option value="Comorbidity">Comorbidity</option>
                                  </select>
                              </td>
                              <td className="add32_Code">
                                  <span onClick={() => removeRow(index)}>
                                      <RemoveIcon className="add32_Code" />
                                  </span>
                              </td>
                          </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <br></br>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Final Diagnosis
                          </th>
                          <th className="ICD_Code">ICD Code</th>
                          <th className="ICD_Code">Type</th>
                          <th className="add32_Code">
                            <span onClick={addRow2}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows2.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="Provisional_Diagnosis"
                                value={row.finalDiagnosis}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "finalDiagnosis",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="ICD_Code"
                                value={row.icdCode2}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "icdCode2",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <select
                                className="Type_code"
                                value={row.type2}
                                onChange={(e) =>
                                  handleChangeRow2(
                                    index,
                                    "type2",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                                <option value="Comorbidity">Comorbidity</option>
                              </select>
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRow2(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Treatment Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <>
                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="Provisional_Diagnosis">
                              Department
                            </th>
                            <th className="ICD_Code">Treating Doctor</th>
                            <th className="ICD_Code">Contact Number</th>
                            <th className="ICD_Code">Registration Number</th>
                            <th className="ICD_Code">Medical Council</th>
                            <th className="add32_Code">
                              <span onClick={addRow3}>
                                <AddIcon className="add32_Code" />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows3.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <select
                                  className="department_tretmt"
                                  value={row.department}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "department",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Cardiology">Cardiology</option>
                                  <option value="Urology">Urology</option>
                                  <option value="Dermatology">
                                    Dermatology
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="department_tretmt_with"
                                  value={row.treatingDoctor}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "treatingDoctor",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Dr.Vishwa MBBS,MBA">
                                    Dr.Vishwa MBBS,MBA
                                  </option>
                                  <option value="Dr.Tamil B.SC,MS,MBBS">
                                    Dr.Tamil B.SC,MS,MBBS
                                  </option>
                                  <option value="Dr.Rajesh MBBS,BA">
                                    Dr.Rajesh MBBS,BA
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="ICD_Code"
                                  value={row.contactNumber3}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "contactNumber3",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="ICD_Code"
                                  value={row.registrationNumber}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "registrationNumber",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.medicalCouncil}
                                  onChange={(e) =>
                                    handleChangeRow3(
                                      index,
                                      "medicalCouncil",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                <span onClick={() => removeRow3(index)}>
                                  <RemoveIcon className="add32_Code" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br></br>

                    <div className="div_ckkkbox_head">
                      <div className="div_ckkck_box tremt_chkbox_info">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="icuManagement"
                            checked={checkboxStateTreatment.icuManagement}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          ICU Management
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="dialysis"
                            checked={checkboxStateTreatment.dialysis}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Dialysis
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="chemotherapy"
                            checked={checkboxStateTreatment.chemotherapy}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Chemotherapy
                        </label>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            name="radiation"
                            checked={checkboxStateTreatment.radiation}
                            onChange={handleCheckboxChangeTreatment}
                          />
                          Radiation
                        </label>
                      </div>
                    </div>
                    <br></br>

                    <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                        <label className="fe_l5f">
                          Medical Management <span>:</span>
                        </label>

                        <div className="ewj_i87_head">
                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="medicalManagementYes"
                              name="medicalManagement"
                              value="Yes"
                              checked={medicalManagement === "Yes"}
                              onChange={handleCheckboxChangeMedical}
                            ></input>

                            <label htmlFor="medicalManagementYes">Yes</label>
                          </div>

                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="medicalManagementNo"
                              name="medicalManagement"
                              value="No"
                              checked={medicalManagement === "No"}
                              onChange={handleCheckboxChangeMedical}
                            ></input>
                            <label htmlFor="medicalManagementNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {medicalManagement === "Yes" && (
                      <>
                        <div className="medical_hstry_txtars3">
                          <div className="txtars3_wit_hdj">
                            <label>Details</label>
                            <textarea
                              name="details"
                              placeholder="Enter Details"
                              value={TreatmentTxtareaDetails}
                              onChange={handleTextAreaChangeDetails}
                            ></textarea>
                          </div>
                        </div>

                        <>
                          <div className="Selected-table-container">
                            <table className="selected-medicine-table2 _hide_hover_table">
                              <thead className="Spl_backcolr_09">
                                <tr>
                                  <th className="Provisional_Diagnosis">
                                    Treatment
                                  </th>
                                  <th className="ICD_Code">
                                    ICD 10 PCS/Hospital Code
                                  </th>

                                  <th className="add32_Code">
                                    <span onClick={addRow4}>
                                      <AddIcon className="add32_Code" />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {rows4.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <select
                                        className="department_tretmt_with"
                                        value={row.treatment}
                                        onChange={(e) =>
                                          handleChangeRow4(
                                            index,
                                            "treatment",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="Treatment1">
                                          Treatment 1
                                        </option>
                                        <option value="Treatment2">
                                          Treatment 2
                                        </option>
                                        <option value="Treatment3">
                                          Treatment 3
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        className="Provisional_Diagnosis"
                                        value={row.pcsHospitalCode}
                                        onChange={(e) =>
                                          handleChangeRow4(
                                            index,
                                            "pcsHospitalCode",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="add32_Code">
                                      <span onClick={() => removeRow4(index)}>
                                        <RemoveIcon className="add32_Code" />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </>
                    )}
                    <br></br>

                    <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                      <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                        <label className="fe_l5f">
                          Surgical Management <span>:</span>
                        </label>

                        <div className="ewj_i87_head">
                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="surgicalManagementYes"
                              name="surgicalManagement"
                              value="Yes"
                              checked={surgicalManagement === "Yes"}
                              onChange={handleCheckboxChangeSurgical}
                            ></input>

                            <label htmlFor="surgicalManagementYes">Yes</label>
                          </div>

                          <div className="ewj_i87">
                            <input
                              type="radio"
                              id="surgicalManagementNo"
                              name="surgicalManagement"
                              value="No"
                              checked={surgicalManagement === "No"}
                              onChange={handleCheckboxChangeSurgical}
                            ></input>
                            <label htmlFor="surgicalManagementNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  {surgicalManagement === "Yes" &&
                    additionalSurgicalOptions.map((option, index) => (
                      <>
                        {/* {department3.map((department, index) => ( */}
                        <>
                          <div key={index}>
                            <div className="surgical_flex_srtgt">
                              <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                                <label>
                                  Department <span>:</span>{" "}
                                </label>
                                <select
                                  className="department_tretmt"
                                  name="departmentSurgical"
                                  value={option.departmentSurgical}
                                  onChange={(e) =>
                                    handleChangeSurgical(
                                      index,
                                      "departmentSurgical",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Cardiology">Cardiology</option>
                                  <option value="Urology">Urology</option>
                                  <option value="Dermatology">
                                    Dermatology
                                  </option>
                                </select>
                              </div>
                              <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                                <label>
                                  {" "}
                                  Surgeon <span>:</span>{" "}
                                </label>
                                <select
                                  className="department_tretmt"
                                  name="surgeonSurgical"
                                  value={option.surgeonSurgical}
                                  onChange={(e) =>
                                    handleChangeSurgical(
                                      index,
                                      "surgeonSurgical",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Dr.Vishwa MBBS,MBA">
                                    Dr.Vishwa MBBS,MBA
                                  </option>
                                  <option value="Dr.Tamil B.SC,MS,MBBS">
                                    Dr.Tamil B.SC,MS,MBBS
                                  </option>
                                  <option value="Dr.Rajesh MBBS,BA">
                                    Dr.Rajesh MBBS,BA
                                  </option>
                                </select>
                              </div>

                              <div className="div_ckkkbox_head dwcw3wd">
                                <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div efws">
                                  <label
                                    style={{ width: "280px", padding: "5px" }}
                                  >
                                    {" "}
                                    Type of Anesthesia <span>:</span>{" "}
                                  </label>
                                </div>
                                <div className="jkdll_piss_head">
                                  <div className="jkdll_piss">
                                    <label className="checkbox-label">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="laAnesthesia"
                                        checked={
                                          checkboxStates[index].laAnesthesia
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChangeAnesthesia(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      LA
                                    </label>
                                  </div>
                                  <div className="jkdll_piss">
                                    <label className="checkbox-label">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="raAnesthesia"
                                        checked={
                                          checkboxStates[index].raAnesthesia
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChangeAnesthesia(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      RA
                                    </label>
                                  </div>
                                  <div className="jkdll_piss">
                                    <label className="checkbox-label">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="saAnesthesia"
                                        checked={
                                          checkboxStates[index].saAnesthesia
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChangeAnesthesia(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      SA
                                    </label>
                                  </div>
                                  <div className="jkdll_piss">
                                    <label className="checkbox-label">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="eaAnesthesia"
                                        checked={
                                          checkboxStates[index].eaAnesthesia
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChangeAnesthesia(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      EA
                                    </label>
                                  </div>
                                  <div className="jkdll_piss">
                                    <label className="checkbox-label">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="gaAnesthesia"
                                        checked={
                                          checkboxStates[index].gaAnesthesia
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChangeAnesthesia(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      GA
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        {/* // ))} */}

                        <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                          <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                            <label>
                              Type <span>:</span>
                            </label>

                            <div className="ewj_i87_head">
                              <div className="ewj_i87 ewj_i87_secd">
                                <input
                                  type="radio"
                                  id={`emergencyYes${index}`}
                                  name={`Type${index}`}
                                  value="emergency"
                                  checked={type[index] === "emergency"}
                                  onChange={(e) =>
                                    handleCheckboxChangeType(e, index)
                                  }
                                ></input>
                                <label htmlFor={`emergencyYes${index}`}>
                                  Emergency
                                </label>
                              </div>

                              <div className="ewj_i87 ewj_i87_secd">
                                <input
                                  type="radio"
                                  id={`electiveYes${index}`}
                                  name={`Type${index}`}
                                  value="elective"
                                  checked={type[index] === "elective"}
                                  onChange={(e) =>
                                    handleCheckboxChangeType(e, index)
                                  }
                                ></input>
                                <label htmlFor={`electiveYes${index}`}>
                                  Elective
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="Selected-table-container">
                          <table className="selected-medicine-table2 _hide_hover_table">
                            <thead className="Spl_backcolr_09">
                              <tr>
                                <th className="Provisional_Diagnosis">
                                  Procedure
                                </th>
                                <th className="ICD_Code">
                                  ICD 10 PCS / Hospital Code
                                </th>
                                <th className="add32_Code">
                                  <span onClick={() => addRow5(index)}>
                                    {" "}
                                    {/* Pass index to addRow5 */}
                                    <AddIcon className="add32_Code" />
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {procedureTables[index] &&
                                procedureTables[index].map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td>
                                      <input
                                        className="Provisional_Diagnosis"
                                        value={row.procedureSurgical}
                                        onChange={(e) =>
                                          handleChangeRow5(
                                            index,
                                            rowIndex,
                                            "procedureSurgical",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="ICD_Code"
                                        value={row.pcdHospitalCodeSurgical}
                                        onChange={(e) =>
                                          handleChangeRow5(
                                            index,
                                            rowIndex,
                                            "pcdHospitalCodeSurgical",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="add32_Code">
                                      <span
                                        onClick={() =>
                                          removeRow5(index, rowIndex)
                                        }
                                      >
                                        {" "}
                                        {/* Pass index and rowIndex to removeRow5 */}
                                        <RemoveIcon className="add32_Code" />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ))}

                  {surgicalManagement === "Yes" && (
                    <div className="SUGCL_AD_PO0">
                      <div
                        className="add32_Code_POK8"
                        onClick={addAdditionalSurgicalOption}
                      >
                        <AddIcon />
                      </div>

                      {additionalSurgicalOptions.length > 0 && (
                        <div
                          className="add32_Code_POK8"
                          onClick={removeAdditionalSurgicalOption}
                        >
                          <RemoveIcon />
                        </div>
                      )}
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Billing Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <>
                    <div className="gap_biling_ifo">
                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Admission <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofAdmission"
                            value={billingInfo.dateofAdmission}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Type Of Admission <span>:</span>{" "}
                          </label>
                          <select
                            className="department_tretmt"
                            name="typeofAdmission"
                            value={billingInfo.typeofAdmission}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="Type1">Admission Type 1</option>
                            <option value="Type2">Admission Type 2</option>
                            <option value="Type3">Admission Type 3</option>
                          </select>
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Duration(stay in Days) <span>:</span>{" "}
                          </label>
                          <input
                            type="number"
                            className="duration_input_with_clr"
                            name="durationStayinDays"
                            value={billingInfo.durationStayinDays}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                      </div>
                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Room / Ward Type <span>:</span>{" "}
                          </label>
                          <select
                            type="date"
                            className="department_tretmt"
                            name="roomWardTypeBilling"
                            value={billingInfo.roomWardTypeBilling}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="Room1">Room 1</option>
                            <option value="Room2">Room 2</option>
                            <option value="Room3">Room 3</option>
                            <option value="Room4">Room 4</option>
                          </select>
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Surgery <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofSurgery"
                            value={billingInfo.dateofSurgery}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Date Of Discharge <span>:</span>{" "}
                          </label>
                          <input
                            type="date"
                            className="department_tretmt date_backgclr6"
                            name="dateofDischarge"
                            value={billingInfo.dateofDischarge}
                            onChange={handleChangeBillingInfo}
                          />
                        </div>
                      </div>

                      <div className="surgical_flex_srtgt gap_biling_ifo">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                          <label>
                            Discharge Type <span>:</span>{" "}
                          </label>
                          <select
                            type="date"
                            className="department_tretmt"
                            name="DischargeTypeBilling"
                            value={billingInfo.DischargeTypeBilling}
                            onChange={handleChangeBillingInfo}
                          >
                            <option value="">Select</option>
                            <option value="DischargeType1">Discharge Type 1</option>
                            <option value="DischargeType2">Discharge Type 2</option>
                            <option value="DischargeType3">Discharge Type 3</option>
                            <option value="DischargeType4">Discharge Type 4</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2 _hide_hover_table">
                      <thead className="Spl_backcolr_09">
                        <tr>
                          <th className="Provisional_Diagnosis">
                            Billing Item
                          </th>
                          <th className="ICD_Code">Charges</th>

                          <th className="add32_Code">
                            <span onClick={addRow6}>
                              <AddIcon className="add32_Code" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows6.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <div className="bilng_itm_colum8">
                                <label>Expected Cost of Hospitalization*</label>
                                <input
                                  type="text"
                                  className="Provisional_Diagnosis"
                                  value={row.costOfHospitalization}
                                  onChange={(e) =>
                                    handleChangeRowBillingItem(
                                      index,
                                      "costOfHospitalization",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="bilng_itm_colum8">
                                <label>Total Bill Amount*</label>
                                <input
                                  type="number"
                                  className="Provisional_Diagnosis"
                                  value={row.totalBillAmount}
                                  onChange={(e) =>
                                    handleChangeRowBillingItem(
                                      index,
                                      "totalBillAmount",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td className="add32_Code">
                              <span onClick={() => removeRow6(index)}>
                                <RemoveIcon className="add32_Code" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="RegisFormcon column_regisFormcon_forinsurance">
                    <div className="h_heade34">
                      <h3>Pre-Auth Documents</h3>
                    </div>

                    <div className="Selected-table-container">
                      <table className="selected-medicine-table2 _hide_hover_table">
                        <thead className="Spl_backcolr_09">
                          <tr>
                            <th className="Provisional_Diagnosis">File Name</th>
                            <th className="ICD_Code">Remarks</th>
                            <th className="ICD_Code">Submitted Date</th>
                            <th className="ICD_Code">ACK</th>
                            <th className="ICD_Code">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows7.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Photo Identity Card{" "}
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    accept="image/pdf"
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      console.log('1100',file)
                                      handleChangeRowDocuments(index, 'Document_file', file);
                                    }}
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.Document_Remarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "Document_Remarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.Document_Date}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "Document_Date",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.Document_Ack}
                                  onChange={(e) =>
                                    handleChangeRowDocuments(
                                      index,
                                      "Document_Ack",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow7}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow7(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}

                          {rows8.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Signed Form
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    accept="image/pdf"
                                    onChange={(e) =>{
                                      const file = e.target.files[0];
                                      handleChangeRowDocuments2(index, 'Document_file', file);
                                    }}
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.Document_Remarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments2(
                                      index,
                                      "Document_Remarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.Document_Date}
                                  onChange={(e) =>
                                    handleChangeRowDocuments2(
                                      index,
                                      "Document_Date",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.Document_Ack}
                                  onChange={(e) =>
                                    handleChangeRowDocuments2(
                                      index,
                                      "Document_Ack",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow8}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow8(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}

                          {rows9.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <label className="file-labelx">
                                  Other Documents
                                  <input
                                    type="file"
                                    className="file-inputx"
                                    accept="image/pdf"
                                    onChange={(e) =>{
                                      const file = e.target.files[0];
                                      handleChangeRowDocuments3(index, 'Document_file', file);
                                    }}
                                  />
                                  <span className="file-buttonx">
                                    Choose file
                                  </span>
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="ICD_Code"
                                  value={row.Document_Remarks}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "Document_Remarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="medication_90"
                                  value={row.Document_Date}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "Document_Date",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="medication_90"
                                  value={row.Document_Ack}
                                  onChange={(e) =>
                                    handleChangeRowDocuments3(
                                      index,
                                      "Document_Ack",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="add32_Code">
                                {index === 0 ? (
                                  <span
                                    className="add32_Code"
                                    onClick={addRow9}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="add32_Code"
                                    onClick={() => removeRow9(index)}
                                  >
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Enhanced */}
                  {Papersstatus === "enhanced" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="h_heade34">
                        <h3>Pre-Auth Documents</h3>
                      </div>

                      <div className="Selected-table-container">
                        <table className="selected-medicine-table2 _hide_hover_table">
                          <thead className="Spl_backcolr_09">
                            <tr>
                              <th className="Provisional_Diagnosis">
                                File Name
                              </th>
                              <th className="ICD_Code">Remarks</th>
                              <th className="ICD_Code">Submitted Date</th>
                              <th className="ICD_Code">ACK</th>
                              <th className="ICD_Code">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows10.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Doctor's Letter{" "}
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.doctorsLetterFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments4(
                                          index,
                                          "doctorsLetterFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.doctorsLetterRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.doctorsLetterDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.doctorsLetterACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments4(
                                        index,
                                        "doctorsLetterACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow10}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow10(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows11.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Signed Form
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.interimBillFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments5(
                                          index,
                                          "interimBillFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.interimBillRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.interimBillDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.interimBillACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments5(
                                        index,
                                        "interimBillACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow11}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow11(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows12.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Other Documents
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.enhancedDocument_file}
                                      onChange={(e) =>
                                        handleChangeRowDocuments6(
                                          index,
                                          "enhancedDocument_file",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.enhancedDocument_Remarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedDocument_Remarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.enhancedDocument_Date}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedDocument_Date",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.enhancedDocument_Ack}
                                    onChange={(e) =>
                                      handleChangeRowDocuments6(
                                        index,
                                        "enhancedDocument_Ack",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow12}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow12(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {/* Discharged */}

                  {Papersstatus === "Discharged" && (
                    <div className="RegisFormcon column_regisFormcon_forinsurance">
                      <div className="h_heade34">
                        <h3>Pre-Auth Documents</h3>
                      </div>

                      <div className="Selected-table-container">
                        <table className="selected-medicine-table2 _hide_hover_table">
                          <thead className="Spl_backcolr_09">
                            <tr>
                              <th className="Provisional_Diagnosis">
                                File Name
                              </th>
                              <th className="ICD_Code">Remarks</th>
                              <th className="ICD_Code">Submitted Date</th>
                              <th className="ICD_Code">ACK</th>
                              <th className="ICD_Code">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows13.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Investigation Report{" "}
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.investigationReport}
                                      onChange={(e) =>
                                        handleChangeRowDocuments7(
                                          index,
                                          "investigationReport",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.investigationReportRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.investigationReportDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.investigationReportACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments7(
                                        index,
                                        "investigationReportACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow13}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow13(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows14.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Final Bill
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.finalBillFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments8(
                                          index,
                                          "finalBillFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.finalBillRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "finalBillRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.interimBillDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "interimBillDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.interimBillACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments8(
                                        index,
                                        "interimBillACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow14}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow14(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows15.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Discharge Summary
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={row.dischargeSummaryFile}
                                      onChange={(e) =>
                                        handleChangeRowDocuments9(
                                          index,
                                          "dischargeSummaryFile",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={row.dischargeSummaryRemarks}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryRemarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={row.dischargeSummaryDate}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryDate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={row.dischargeSummaryACK}
                                    onChange={(e) =>
                                      handleChangeRowDocuments9(
                                        index,
                                        "dischargeSummaryACK",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow15}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow15(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {rows16.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <label className="file-labelx">
                                    Other Documents
                                    <input
                                      type="file"
                                      className="file-inputx"
                                      value={
                                        row.dischargeSummaryDocument_file
                                      }
                                      onChange={(e) =>
                                        handleChangeRowDocuments10(
                                          index,
                                          "dischargeSummaryDocument_file",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="file-buttonx">
                                      Choose file
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="ICD_Code"
                                    value={
                                      row.dischargeSummaryDocument_Remarks
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryDocument_Remarks",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="medication_90"
                                    value={
                                      row.dischargeSummaryDocument_Date
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryDocument_Date",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="medication_90"
                                    value={
                                      row.dischargeSummaryDocument_Ack
                                    }
                                    onChange={(e) =>
                                      handleChangeRowDocuments10(
                                        index,
                                        "dischargeSummaryDocument_Ack",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="add32_Code">
                                  {index === 0 ? (
                                    <span
                                      className="add32_Code"
                                      onClick={addRow16}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span
                                      className="add32_Code"
                                      onClick={() => removeRow16(index)}
                                    >
                                      -
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Received Documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Past Documents
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Additional Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className={`card Tab-card ${isTabCollapsed ? "collapsed" : ""}`}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeValue}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Inbox" className="ITEM_ONE_INSU_head" value="1" />
                  <Tab
                    label="Sent Items"
                    className="ITEM_ONE_INSU_head"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" className="ITEM_ONE_INSU">
                Item One
              </TabPanel>
              <TabPanel value="2" className="ITEM_ONE_INSU">
                Item Two
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className="Register_btn_con insu_submit_btn">
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="RegisterForm_1_btns insu_submit_btn2"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export default Insurance;
