import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// import './PatientQueueList.css';
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor)!important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PatientQueueList3 = () => {
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const [rows, setRows] = useState([]);
  // const [filteredRows,setFilteredRows]=useState([]);
  const [Doctors, setDoctors] = useState([]);
  const [selectedDoctor, setselectedDoctor] = useState("all");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = rows.length;
  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

  useEffect(() => {
    axios
      .get(
        `${UrlLink}appointmentmanagement/get_appointments?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const filteredData = response.data.filter((userdata) => {
          // Check if the doctor matches the selected doctor or if all doctors are selected
          return (
            selectedDoctor === "all" || userdata.DoctorName === selectedDoctor
          );
        });

        const Records = filteredData.map((userdata,index) => ({
          id: index + 1,
          PatientID: userdata.PatientID,
          visitNo: userdata.VisitID,
          SerialNo: userdata.appointment_patientregisterID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          AppointmentPurpose: userdata.AppointmentPurpose,
          Status: userdata.Status,
          DoctorName: userdata.DoctorName,
          Location: userdata.Location,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [selectedDoctor]); // Add selectedDoctor as a dependency to trigger the effect when the doctor changes

  console.log(rows);
  useEffect(() => {
    axios
      .get(
        `${UrlLink}usercontrol/get_doctor_info?location${userRecord?.location}`
      )
      .then((response) => {
        setDoctors(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRequestProceed = (params) => {
    console.log(params);
    const serialid = params.row.id;
    dispatchvalue({
      type: "formData",
      value: params.row,
    });
    axios
      .post(
        `${UrlLink}doctorsworkbench/update_status_appointment_register?selectedpatienid=${serialid}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          console.log(response.data);
          // Handle success, e.g., navigate to another page
        } else {
          console.error("Error updating record:", response.data.message);
          // Handle error, e.g., show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });

    localStorage.setItem("appointment_patientregisterid", serialid);
    navigate("/Home/Navigation3");
  };

  // useEffect(() => {
  //   // Fetch patient IDs
  //   const fetchPatientIDs = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:8000/doctorsworkbench/get_appoitmentregpatient_ids');
  //       const patientIds = response.data;
  //       console.log(patientIds)
  //       setpatientIDs(patientIds); // Update the state with patient IDs
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   // Call the fetchPatientIDs function
  //   fetchPatientIDs();
  // }, []);

  // useEffect(() => {

  //   // The empty dependency array [] ensures that this effect runs once when the component mounts

  // }, [patientIDs]);

  const [columns] = React.useState([
    { field: "id", headerName: "S.NO", width: 150 },
    { field: "PatientName", headerName: "Patient Name", width: 150 },
    {
      field: "AppointmentPurpose",
      headerName: "Appointment Purpose",
      width: 150,
    },
    { field: "Status", headerName: "Status", width: 150 },
    { field: "Location", headerName: "Branch Location", width: 150 },
    {
      field: "Action ",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    fetch(
      `${UrlLink}appointmentmanagement/get_appointments?location=${userRecord?.location}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (Array.isArray(data)) {
          // Check if data is an array
          const filteredData = data.filter((user) => {
            const lowerCaseFirstName = user.FirstName.toLowerCase();
            const lowerCasePhoneNo = user.PhoneNumber.toString();

            const lowerCaseSearch = PatientFirstName.toLowerCase();
            const lowerCasePhoneSearch = PatientPhoneNo.toLowerCase();

            // Check if the search query matches the start of the name or phone number,
            // and ignore the empty field
            return (
              (PatientFirstName === "" ||
                lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
              (PatientPhoneNo === "" ||
                lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
            );
          });

          if (filteredData.length === 0) {
            alert("No matching appointments found.");
            setRows([]);
          } else {
            const rows = filteredData.map((appointment) => ({
              id: appointment.PatientID, // Use a unique identifier or fallback to index
              PatientName: appointment.FirstName + appointment.LastName,
              AppointmentPurpose: appointment.AppointmentPurpose,
              Status: appointment.Status,
            }));
            setRows(rows);
          }
        } else {
          console.error("Fetched data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  //   const handleSearch = async (e) => {
  //     e.preventDefault();

  //     console.log(PatientFirstName, PatientPhoneNo);

  //     try {
  //       // For first name search
  //       const firstNameResponse = await fetch(${UrlLink}doctorsworkbench/search_appointments?search=${PatientFirstName.toLowerCase()}, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       const firstNameData = await firstNameResponse.json();

  //       // For phone number search
  //       const phoneNoResponse = await fetch(http://localhost:8000/doctorsworkbench/search_appointments?search=${PatientPhoneNo}, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       const phoneNoData = await phoneNoResponse.json();

  //       updateData(firstNameData, phoneNoData);
  //     } catch (error) {
  //       console.error('Error fetching filtered data:', error);
  //     }
  //   };

  //   const updateData = (firstNameData, phoneNoData) => {
  //     if (firstNameData.success && Array.isArray(firstNameData.appointments_data) &&
  //         phoneNoData.success && Array.isArray(phoneNoData.appointments_data)) {

  //       const firstNameRecords = firstNameData.appointments_data.map((userdata) => ({
  //         id: userdata.SerialNo,
  //         First_Name: userdata.First_Name,
  //         PatientName: userdata.First_Name + ' ' + userdata.Last_Name,
  //         Phone_No: userdata.Phone_No,
  //         DoctorName: userdata.DoctorName,
  //         AppointmentType: userdata.AppointmentType,
  //         Status: userdata.Status,
  //       }));

  //       const phoneNoRecords = phoneNoData.appointments_data.map((userdata) => ({
  //         id: userdata.SerialNo,
  //         First_Name: userdata.First_Name,
  //         PatientName: userdata.First_Name + ' ' + userdata.Last_Name,
  //         Phone_No: userdata.Phone_No,
  //         DoctorName: userdata.DoctorName,
  //         AppointmentType: userdata.AppointmentType,
  //         Status: userdata.Status,
  //       }));

  //       const mergedData = [...firstNameRecords, ...phoneNoRecords];

  //       const uniqueData = Array.from(new Set(mergedData.map((item) => item.id))).map((id) => {
  //         return mergedData.find((item) => item.id === id);
  //       });

  //       console.log(uniqueData);

  //       if (uniqueData.length === 0) {
  //         // alert('No matching users found.');
  //       } else {
  //         setRows(uniqueData);
  //       }
  //     } else {
  //       console.error('Fetched data is not as expected.');
  // }
  // };

  const totalPages = Math.ceil(rows.length / 10);

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Patient Queue List</h4>
          <div className="doctor_select_1 selt-dctr-nse">
            <label htmlFor="names">Doctor:</label>
            <select
              name="Doctor Name"
              onChange={(e) => setselectedDoctor(e.target.value)}
              value={selectedDoctor}
            >
              <option value="all">Select</option>
              {Doctors.map((p, index) => (
                <option key={index} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        </div>
        <form onSubmit={handleSearch}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name<span>:</span></label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No <span>:</span></label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon className="srch-clr-chg" />
            </button>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {rows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientQueueList3;
