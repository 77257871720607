import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMtpFormC() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [checkboxValues, setCheckboxValues] = useState({
    PatientGender: '',
    RelativeGender: '',
  })


  const [ConcernformData, setFormData] = useState({
    Name1: "",
    Name2: "",
    Age1: "",
    PermanentAddress1: "",
    Address1: "",
    Name3: "",
    Name4: "",
    Age2: "",
    PermanentAddress2: "",
    Address2: "",
    Name5: "",
    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);




  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
  
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked ? value : ''
      }));
    } else {
      setFormData({
        ...ConcernformData,
        [name]: value
      });
    }
  };

  


  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Name1', ConcernformData.Name1);
              formData.append('Name2', ConcernformData.Name2);
              formData.append('Age1', ConcernformData.Age1);
              formData.append('PermanentAddress1', ConcernformData.PermanentAddress1);
              formData.append('Address1', ConcernformData.Address1);
              formData.append('Name3', ConcernformData.Name3);
              formData.append('Name4', ConcernformData.Name4);
              formData.append('Age2', ConcernformData.Age2);
              formData.append('PermanentAddress2', ConcernformData.PermanentAddress2);
              formData.append('Address2', ConcernformData.Address2);
              formData.append('Name5', ConcernformData.Name5);
              
              // formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MtpForm_C`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >
         
         

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>FORM C</h1>
          </div>

          
         
          
        </div>

    

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '13px', fontWeight: 'bold' }}>
            I 
            <input
                type="text"
                name="Name1"
                value={ConcernformData.Name1}
                onChange={handleChange}
                style={{width: '580px'}}
                required
                
                /><br/>
                <br/>
                
               daughter / wife of
                <input
                type="text"
                name="Name2"
                value={ConcernformData.Name2}
                onChange={handleChange}
                style={{width: '300px'}}
                required
                
                />
                aged about
                <input
                type="text"
                name="Age1"
                value={ConcernformData.Age1}
                onChange={handleChange}
                style={{width: '50px'}}
                required
                />

                years of 
                <input
                type="text"
                name="PermanentAddress1"
                value={ConcernformData.PermanentAddress1}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                />
                 <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
                    <p>(Here State the permanent address)</p>
                </div>
                at present residing at

                <input
                type="text"
                name="Address2"
                value={ConcernformData.Address2}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                />
                do
                <br/>
                <br/> hereby give my consent to termination of my pregnancy at
                CHIRAYU HOSPITAL, Salvi Stop, Ratnagiri

                <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
                    <p>(State the name of place where the pregnancy is to be terminated)</p>
                </div>


                <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            <h3>Place : RATNAGIRI</h3>

           </p>
            </div>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            
            <h3>Date: </h3>

           </p>
            </div>
        </div>   


        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


        </div>


            </p>
          </div>
        </div>
  
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '13px', fontWeight: 'bold' }}>
            <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
                <h3>(To be filled in by guardian where the woman is a mentally ill person or minor)</h3>
            </div>
        
            I 
            <input
                type="text"
                name="Name3"
                value={ConcernformData.Name3}
                onChange={handleChange}
                style={{width: '580px'}}
                required
                
                /><br/>
                <br/>
                
               son / daughter / wife of
                <input
                type="text"
                name="Name4"
                value={ConcernformData.Name4}
                onChange={handleChange}
                style={{width: '270px'}}
                required
                
                />
                aged about
                <input
                type="text"
                name="Age2"
                value={ConcernformData.Age2}
                onChange={handleChange}
                style={{width: '50px'}}
                required
                />

                years of 
                <input
                type="text"
                name="PermanentAddress2"
                value={ConcernformData.PermanentAddress2}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                />
                 <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
                    <p>(Here State the permanent address)</p>
                </div>
                at present residing at

                <input
                type="text"
                name="Address2"
                value={ConcernformData.Address2}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                />
                do
                <br/>
                <br/> hereby give my consent to termination of my pregnancy of my ward 

                <input
                type="text"
                name="Name5"
                value={ConcernformData.Name5}
                onChange={handleChange}
                style={{width: '590px'}}
                required
                />
                <br/>
                <br/>who is a minor / lunatic at

                CHIRAYU HOSPITAL, Salvi Stop, Ratnagiri

               

                <br/>
                <br/>

                <div className=" summadiv">
                    <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
                    <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                        <h3>Place : RATNAGIRI</h3>

                    </p>
                        </div>
                </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
           <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            
            <h3>Date: </h3>

           </p>
            </div>
        </div>   


        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


        </div>


            </p>
          </div>
        </div>

       

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMtpFormC;
