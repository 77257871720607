import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const Referaldoctor = () => {
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  console.log("UrlLink", UrlLink);
  const [data, setdata] = useState([]);
  const [isEdit, setisEdit] = useState(true);
  const [GetData, setGetData] = useState(true);
  const [RouteNo, setRoute_No] = useState([]);
  const [RouteName, setRouteName] = useState([]);
  const [ThasilName, setThasilName] = useState([]);
  const [VillageName, setVillageName] = useState([]);

  const [ReferalType, setReferalType] = useState([]);

  const [locationData, setLocationData] = useState([]);

  const [dataform, setdataform] = useState({
    ReferalId: "",
    Location: "",
    RouteNo: "",
    RouteName: "",
    TeshilName: "",
    VillageName: "",
    ReferalType: "",
    ReferalName: "",
    FirstName: "",
    MiddleName: "",
    SurName: "",
    PhoneNo: "",
    InPatient: "",
    OutPatient: "",
    DayCare: "",
    Laboratory: "",
    Radiology: "",
    PaymentType: "",
    BankName: "",
    AccountNumber: "",
    HolderName: "",
    IFSCCode: "",
    Address: "",
    Status: "Active",
  });
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const [FilteredFormdata, setFilteredFormdata] = useState([]);

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = data.length;
  const totalPages = Math.ceil(data.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  const dynamicColumns = [
    {
      field: "id",
      headerName: "Referal Id",
      width: 100,
    },
    ...Object.keys(dataform)
      .filter((r) => !["ReferalId", "Status"].includes(r))
      .map((labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: labelWidth + 50,
          valueGetter: (params) => {
            const value = params;
            console.log(value)
            return value ? value : "-"; // If value is empty, return "-"
          }, 
        
        };
      }),
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => HandleEditStatus(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];

  const HandleEditStatus = (row) => {
    const data = {
      ...row,
      Status: row.Status === "Active" ? "Inactive" : "Active",
    };
    axios
      .post(`${UrlLink}usercontrol/insert_referaldocotor_master`, data)
      .then((response) => {
        console.log(response);
        setGetData((prevGetData) => !prevGetData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (dataform.Location) {
      axios
        .get(`${UrlLink}usercontrol/get_route?location=${dataform.Location}`)
        .then((response) => {
          console.log("routeno", response.data);
          setRoute_No(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataform.Location]);

  useEffect(() => {
    if (dataform.Location && dataform.RouteNo) {
      axios
        .get(
          `${UrlLink}usercontrol/get_route_name?location=${dataform.Location}&routerno=${dataform.RouteNo}`
        )
        .then((response) => {
          console.log("routename", response.data);
          setRouteName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataform.Location, dataform.RouteNo]);
  useEffect(() => {
    if (dataform.Location && dataform.RouteNo) {
      axios
        .get(
          `${UrlLink}usercontrol/get_route_name?location=${dataform.Location}&routerno=${dataform.RouteNo}`
        )
        .then((response) => {
          console.log("routename", response.data);
          setRouteName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataform.Location, dataform.RouteNo]);
  useEffect(() => {
    if (dataform.Location && dataform.RouteNo && dataform.RouteName) {
      axios
        .get(
          `${UrlLink}usercontrol/get_tehsilby_route?location=${dataform.Location}&routerno=${dataform.RouteNo}&routename=${dataform.RouteName}`
        )
        .then((response) => {
          console.log("thasilname", response.data);
          setThasilName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataform.Location, dataform.RouteNo, dataform.RouteName]);

  useEffect(() => {
    if (
      dataform.Location &&
      dataform.RouteNo &&
      dataform.RouteName &&
      dataform.TeshilName
    ) {
      axios
        .get(
          `${UrlLink}usercontrol/get_village_route?location=${dataform.Location}&routerno=${dataform.RouteNo}&routename=${dataform.RouteName}&thasilname=${dataform.TeshilName}`
        )
        .then((response) => {
          console.log("villagename", response.data);
          setVillageName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    dataform.Location,
    dataform.RouteNo,
    dataform.RouteName,
    dataform.TeshilName,
  ]);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setdataform((prevDataForm) => ({
  //     ...prevDataForm,
  //     [name]: value,
  //   }));
  // };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'Location') {
      setRoute_No([])
      setRouteName([])
      setThasilName([])
      setVillageName([])
      setdataform((prevData) => ({
        ...prevData,
        [name]: value,
        ReferalId: "",
        RouteNo: "",
        RouteName: "",
        TeshilName: "",
        VillageName: "",
        ReferalType: "",
        ReferalName: "",
        FirstName: "",
        MiddleName: "",
        SurName: "",
        PhoneNo: "",
        InPatient: "",
        OutPatient: "",
        DayCare: "",
        Laboratory: "",
        Radiology: "",
        PaymentType: "",
        BankName: "",
        AccountNumber: "",
        HolderName: "",
        IFSCCode: "",
        Address: "",
        Status: "Active",
      }));
    } else if (name === "PhoneNo") {
      if (value.length <= 10) {
        setdataform((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        alert('Phone number must contain exactly 10 digits')
      }
    } else if (name === "AccountNumber") {
      if (value.length <= 20) {
        setdataform((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        alert('Acoount number maximum contain  20 digits')
      }
    } else if ([
      "InPatient",
      "OutPatient",
      "DayCare",
      "Laboratory",
      "Radiology",
    ].includes(name)) {
      if (value < 100) {
        setdataform((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        alert('iti')
      }
    } else if (name === "ReferalType") {
      setdataform((prevData) => ({
        ...prevData,
        [name]: value,
        ReferalName: ''
      }));
    } else if (name === "PaymentType") {
      setdataform((prevData) => ({
        ...prevData,
        [name]: value,
        BankName: '',
        AccountNumber: '',
        HolderName: '',
        IFSCCode: '',
      }));
    } else {
      setdataform((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

  }


  const handleSubmit = () => {
    console.log("FilteredFormdata", FilteredFormdata);
    const existing = FilteredFormdata.filter((field) => !dataform[field]);
    console.log("existing", existing);
    if (existing.length > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      axios
        .post(`${UrlLink}usercontrol/insert_referaldocotor_master`, dataform)
        .then((response) => {
          console.log(response);
          setGetData((prevGetData) => !prevGetData);
          setisEdit(true);
          setRoute_No([])
          setRouteName([])
          setThasilName([])
          setVillageName([])
          setdataform({
            ReferalId: "",
            Location: "",
            RouteNo: "",
            RouteName: "",
            TeshilName: "",
            VillageName: "",
            ReferalType: "",
            ReferalName: "",
            FirstName: "",
            MiddleName: "",
            SurName: "",
            PhoneNo: "",
            InPatient: "",
            OutPatient: "",
            DayCare: "",
            Laboratory: "",
            Radiology: "",
            PaymentType: "",
            BankName: "",
            AccountNumber: "",
            HolderName: "",
            IFSCCode: "",
            Address: "",
            Status: "Active",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${UrlLink}usercontrol/get_referal_data`)
      .then((response) => {
        const data = response.data.data;
        console.log(response);
        setdata(
          data.map((p) => ({
            ...p,
            id: p.ReferalId,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [GetData]);

  const handleEdit = (row) => {
    console.log(row, "0000");
    // Extract the values from the selected row and update dataform state
    setdataform({
      ReferalId: row.ReferalId,
      Location: row.Location,
      RouteNo: row.RouteNo,
      RouteName: row.RouteName,
      TeshilName: row.TeshilName,
      VillageName: row.VillageName,
      ReferalType: row.ReferalType,
      ReferalName: row.ReferalName,
      FirstName: row.FirstName,
      MiddleName: row.MiddleName,
      SurName: row.SurName,
      PhoneNo: row.PhoneNo,
      InPatient: row.InPatient,
      OutPatient: row.OutPatient,
      DayCare: row.DayCare,
      Laboratory: row.Laboratory,
      Radiology: row.Radiology,
      PaymentType: row.PaymentType,
      BankName: row.BankName,
      AccountNumber: row.AccountNumber,
      HolderName: row.HolderName,
      IFSCCode: row.IFSCCode,
      Address: row.Address,
      Status: row.Status,



    });
    // Change the state to enable editing mode
    setisEdit(false);
  };

  useEffect(() => {
    axios
      .get(`${UrlLink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        console.log(data);

        setLocationData(
          data.filter(
            (f) => f.location_name !== "ALL" && f.status !== "Inactive"
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  }, []);

  useEffect(() => {
    let fdata = Object.keys(dataform).filter(
      (f) =>
        ![
          "BankName",
          "AccountNumber",
          "HolderName",
          "IFSCCode",
          "ReferalId",
          "ReferalName",
          "FirstName",
          "MiddleName",
          "SurName",
          "Status",
        ].includes(f)
    );
    if (dataform.PaymentType) {
      if (dataform.PaymentType === "Banking") {
        const categoryIndex = fdata.indexOf("PaymentType");
        fdata.splice(
          categoryIndex + 1,
          0,
          "BankName",
          "AccountNumber",
          "HolderName",
          "IFSCCode"
        );
      }

    }
    if (dataform.ReferalType) {
      if (["Paramedics", "Doctor"].includes(dataform.ReferalType)) {
        const categoryIndex = fdata.indexOf("ReferalType");
        fdata.splice(
          categoryIndex + 1,
          0,
          "FirstName",
          "MiddleName",
          "SurName"
        );
      } else {
        const categoryIndex = fdata.indexOf("ReferalType");
        fdata.splice(categoryIndex + 1, 0, "ReferalName");
      }

    }
    setFilteredFormdata(fdata);
  }, [dataform.PaymentType, dataform.ReferalType]);

  const updateColumnGap = (containerWidth) => {
    const items = document.querySelectorAll(".RegisForm_1");
    let totalWidth = 0;
    let currentRowItemsCount = 0;

    items.forEach((item) => {
      const itemStyles = getComputedStyle(item);
      const itemWidth =
        item.offsetWidth +
        parseFloat(itemStyles.marginLeft) +
        parseFloat(itemStyles.marginRight);

      if (totalWidth + itemWidth <= containerWidth) {
        totalWidth += itemWidth;
        currentRowItemsCount++;
      }
    });

    const remainingGap = containerWidth - totalWidth;
    const gapBetweenItems = Math.ceil(remainingGap / currentRowItemsCount);
    const container = document.getElementById("RegisFormcon_111");
    container.style.columnGap = `${gapBetweenItems}px`;
  };

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById("RegisFormcon_111");
      if (container) {
        const containerWidth = container.offsetWidth;
        updateColumnGap(containerWidth);
      }
    };

    // Initial call
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateColumnGap]);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Referal Doctor Charges</h4>
        </div>
        <br />
        <div className="RegisFormcon" id="RegisFormcon_111">
          {FilteredFormdata.map((field, indxx) => (
            <div className="RegisForm_1" key={indxx}>
              <label htmlFor={field}>
                {formatLabel(field)}
                {[
                  "InPatient",
                  "OutPatient",
                  "DayCare",
                  "Laboratory",
                  "Radiology",
                ].includes(field) && <span>%</span>}
                <span>:</span>
              </label>
              {["Location", "ReferalType", "PaymentType"].includes(field) ? (
                <select
                  id={field}
                  name={field}
                  value={dataform[field]}
                  // onChange={handleInputChange}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (field === "Location") {
                      // Clear dataform values for RouteNo, RouteName, VillageName, and TeshilName
                      setdataform((prevData) => ({
                        ...prevData,
                        RouteNo: "",
                        RouteName: "",
                        VillageName: "",
                        TeshilName: "",
                      }));
                    }
                  }}
                  required={["Location", "ReferalType", "PaymentType"].includes(
                    field
                  )}
                >
                  <option value="">Select</option>
                  {field === "Location" &&
                    locationData.map((r, indxx) => (
                      <option key={indxx} value={r.location_name}>
                        {r.location_name}
                      </option>
                    ))}
                  {field === "ReferalType" &&
                    [
                      "Hospital",
                      "Clinic",
                      "Laboratory",
                      "Paramedics",
                      "Doctor",
                    ].map((r, indxx) => (
                      <option key={indxx} value={r}>
                        {r}
                      </option>
                    ))}

                  {field === "PaymentType" &&
                    ["Cash", "Banking"].map((r, indxx) => (
                      <option key={indxx} value={r}>
                        {r}
                      </option>
                    ))}
                </select>
              ) : [
                "RouteNo",
                "RouteName",
                "TeshilName",
                "VillageName",
              ].includes(field) ? (
                <>
                  <input
                    type="text"
                    id={field}
                    name={field}
                    value={dataform[field]}
                    list={`${field}_1111`}
                    required
                    onChange={handleInputChange}
                  />
                  <datalist id={`${field}_1111`}>
                    {field === "RouteNo" &&
                      RouteNo.map((routenum, index) => (
                        <option key={index} value={routenum}>
                          {routenum}
                        </option>
                      ))}
                    {field === "RouteName" &&
                      RouteName.map((routename, index) => (
                        <option key={index} value={routename}>
                          {routename}
                        </option>
                      ))}
                    {field === "TeshilName" &&
                      ThasilName.map((teshilname, index) => (
                        <option key={index} value={teshilname}>
                          {teshilname}
                        </option>
                      ))}
                    {field === "VillageName" &&
                      VillageName.map((villagename, index) => (
                        <option key={index} value={villagename}>
                          {villagename}
                        </option>
                      ))}
                  </datalist>
                </>
              ) : [
                "InPatient",
                "OutPatient",
                "DayCare",
                "Laboratory",
                "Radiology",
                "PhoneNo",
                "AccountNumber",
              ].includes(field) ? (
                <input
                  type="number"
                  onKeyDown={blockInvalidChar}
                  id={field}
                  name={field}
                  value={dataform[field]}
                  required
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  type="text"
                  id={field}
                  name={field}
                  value={dataform[field]}
                  required
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))}
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSubmit}>
            {isEdit ? "Submit" : "Update"}
          </button>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={data.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && data.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && data.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Referaldoctor;
