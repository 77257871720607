import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import LoupeIcon from '@mui/icons-material/Loupe';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {format} from 'date-fns';








const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

  
export default function PaymentVoucherList() {

    const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)
  
    const cu_date = format(new Date(), 'yyyy-MM-dd');



    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([])

    const [Rowdata, setRowdata] = useState([]);
    const [selectedShow, setSelectedShow] = useState(false);
    const [page1, setPage1] = useState(0);





    const showdown1 = Rowdata.length;
    const pageSize1 = 10;


    const userRecord = useSelector(state => state.userRecord?.UserData)


    console.log('vcvc',userRecord)


    const dynamicColumns1 =[
        { field: 'id', headerName: 'S.No', width: 80 },
        { field: 'Dr_Account', headerName: 'Dr Account', width: 150 },
        { field: 'DrAccountCurBalance', headerName: 'Cur Balance', width: 150 },
        { field: 'DrAccountBalanceType', headerName:'Debit / Credit', width: 150 },
        { field: 'Depit_Amount', headerName: 'Depit Amount', width: 120 }
    ]



    useEffect(() => {

        gettabledata()
       
   },[userRecord]);


   const gettabledata= ()=>{
    axios
    .get(
      `https://vesoftometic.co.in/FinanceMagement/get_Payment_Voucher_Details?location=${userRecord.location}`
    )
    .then((response) => {
    //   console.log(response.data);
      const data = response.data.Voucher_Details
    const Adata =data.map((p,index) => ({
        id:index+1,
        ...p
      }));
      console.log('data',Adata)
      setsumma(Adata);
    })
    .catch((error) => {
      console.log(error);
    });    
   }

    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {


        const resultReject = window.confirm('Are you sure ,you want to Cancel it ?')


        if(resultReject){

        const index = params.row

        console.log('index:',index) 


        let Canceldata = {
            Payment_Voucher_Number:index.Payment_Voucher_Number,
            CancelDate:cu_date,
            CanceledBy:userRecord.username,
            ACname:index.Cr_Account,
            WantplusAmount:index.Credit_Amount,
            items_details:index.items_details

          }


          axios
          .post(
            "https://vesoftometic.co.in/FinanceMagement/Cancel_Payment_Voucher_Entry_details",
            Canceldata
          )
          .then((response) => {
            console.log(response.data);
            gettabledata()
          })
          .catch((error) => {
            console.error(error);
            alert("Failed to add data. Please try again.");
          });
        }

        
    };





    const handleRequestView = (params) => {
        const index = params.row.items_details
        console.log('index:',index)
        let B_data = index.map((p, index) => ({
            id: index + 1,
            ...p
          }))
        setRowdata(B_data)
        setSelectedShow(true);

        
    };

    const handlePageChange1 = (params) => {
        setPage1(params.page);
      };
    const totalPages1 = Math.ceil(Rowdata.length / 10);
   
    
    useEffect(() => {
        // console.log('ss',summa)

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.Cr_Account.toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery,summa]);



    const handleRequestForm = () => {
     navigate('/Home/PaymentVoucher')
    }

    
   

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'id', headerName: 'S.No', width:100 },
        { field: 'Payment_Voucher_Number', headerName: 'Number', width: 150 },
        { field: 'Payment_Voucher_date', headerName: 'Date', width: 150 },
        { field: 'Cr_Account', headerName: 'Cr Account', width: 180 },
        { field: 'Credit_Amount', headerName: 'Credit Amount', width: 150 },
        { field: 'Narration', headerName: 'Narration', width: 150 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
            field: 'View',
            headerName: 'View',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestView(params)}>
                        <VisibilityIcon/>
                    </Button>
                </>
            ),
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <>
                    {params.row.Status ==='Canceled' ? <Button className='cell_btn'>
                        Canceled
                    </Button>
                        :
                    <Button style={{fontSize:'12px'}}  onClick={() => handleRequestEdit(params)}>
                        Cancel
                    </Button>}
                </>
                
            ),
        },
    ];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };



    return (
        <>
            
            <div className="appointment">
                
                <div className="h_head">
                    <h4>Payment Voucher List</h4>
                 </div>
                 <br/>
                <div className="con_1 "> 
                <div className="inp_1">
                <label htmlFor="input">Dr Account <span>:</span></label>
                <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Name' />
                </div>                      

                <button className='btn_1' type='submit' onClick={() => handleRequestForm()} title='New Group Entry'>
                    <LoupeIcon />
                </button>
                </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}

{selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>List</h3>
                </div>
                <div className="con_1 ">

                  

                
                </div>
                <div className='IP_grid'>
                  <ThemeProvider theme={theme}>
                    <div className='IP_grid_1'>
                      <DataGrid
                        rows={Rowdata.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                        columns={dynamicColumns1} // Use dynamic columns here
                        pageSize={10}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        onPageChange={handlePageChange1}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className='data_grid'
                      />
                      {showdown1 > 0 && filteredRows.length > 10 && (
                        <div className='IP_grid_foot'>
                          <button
                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                            disabled={page === 0}
                          >
                            Previous
                          </button>
                          Page {page1 + 1} of {totalPages1}
                          <button
                            onClick={() =>
                              setPage((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                            }
                            disabled={page1 === totalPages1 - 1}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                  {showdown1 !== 0 && filteredRows.length !== 0 ? (
                    ''
                  ) : (
                    <div className='IP_norecords'>
                      <span>No Records Found</span>
                    </div>
                  )}
                </div>
                <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                    }}

                  >
                    close
                  </button>
                </div>
              </div>


            </div>
          </div>
        )}
                </div>
            </div>
        </>
    );
}
