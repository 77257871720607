import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmerConsentAForm() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    Date: "",
    NameOfRelative: "",
    Name: "",
    Address: "",
    RelationshipWithPatient: "",
    Date1: "",
    Time: "",
    SignatureLhti1: "",
    SignatureLhti2: "",
    Location: userRecord.location,
    Patient_Name: IpNurseQueSelectedRow.PatientName,
    bookingid: IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);

      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);

              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Date', ConcernformData.Date);
              formData.append('Date1', ConcernformData.Date1);
              formData.append('Time', ConcernformData.Time);
              formData.append('Name', ConcernformData.Name);
              formData.append('Address', ConcernformData.Address);
              formData.append('NameOfRelative', ConcernformData.NameOfRelative);
              formData.append('RelationshipWithPatient', ConcernformData.RelationshipWithPatient);
              formData.append('SignatureLhti1', ConcernformData.SignatureLhti1);
              formData.append('SignatureLhti2', ConcernformData.SignatureLhti2);
              // formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);

              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConsentAForm`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;

  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };


  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };





  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          // setClinicName(data.clinicName);
          // setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h6>स्वस्ति मेडीकल फाउंडेशन चे</h6>
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>चिरायु</h1><h4 style={{ marginLeft: '6px' }}> हॉस्पिटल</h4>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h6>साळवी स्टॉप, रत्नागिरी. फोन (02352 ) 230565, 230820</h6>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>CONSENT 'A' FORM</h3>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h2>खर्च व बिले चुकती करण्याची संमती</h2>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>(परिशिष्ट 'अ' )</h3>
          </div>
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="Date">
              दिनांक<span>:</span>
            </label>
            <input
              type="text"
              id="Date"
              name="Date"
              value={ConcernformData.Date}
              onChange={handleChange}
              required
            />

          </div>

          {/* <div className="RegisForm_1_consent_consent">
                <label htmlFor="Time">
                वेळ<span>:</span>
                    </label>
                    <input
                      type="time"
                      id="Time"
                      name="Time"
                      value={ConcernformData.Time}
                      onChange={handleChange}
                      required
                    />
                
            </div> */}


        </div>

        {/* <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
              <p style={{margin: '0', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">प्रति,</p>
          </div>
        </div>
        */}

        {/* <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  >
              <p style={{ marginLeft: '25px', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">पोलीस निरीक्षक,<br/>
              रत्नागिरी शहर / ग्रामीण पोलीस ठाणे<br/>
              विषय - MLC नोंद करण्याबाबत.</p>
          </div>
        </div> */}

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
              मला / आम्हांला संभाव्य खर्चाची कल्पना देण्यात आलेली आहे. मी/ आम्ही रुग्णालयात येणारा<br />
              सर्वसाधारण दैनंदिन खर्च त्याचप्रमाणे तपासण्यांचा सर्व उपचार व औषधे यासाठी होणारा खर्च भरण्याचे<br />
              कबूल करतो.
            </p>

          </div>


        </div>

        <br />
        {/* <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold', textAlign: 'left' }}><br />
              रुग्णाचे नाव :<span>-</span>
              <input
                type="text"
                name="Name"
                value={ConcernformData.Name}
                onChange={handleChange}
                required
              /><br />
              नातेवाईकाचे नाव : <span>-</span>
              <input
                type="text"
                name="NameOfRelative"
                value={ConcernformData.NameOfRelative}
                onChange={handleChange}
                required
              /><br />



              पत्ता : <span>-</span>
              <input
                type="text"
                name="Address"
                value={ConcernformData.Address}
                onChange={handleChange}
                required
              /><br />

              सही / अंगठा : <span>-</span>
              <input
                type="text"
                name="SignatureLhti1"
                value={ConcernformData.SignatureLhti1}
                onChange={handleChange}
                required
              /><br />

              रुग्णाशी नाते : <span>-</span>
              <input
                type="text"
                name="RelationshipWithPatient"
                value={ConcernformData.RelationshipWithPatient}
                onChange={handleChange}
                required
              /><br />

              तारीख : <span>-</span>
              <input
                type="text"
                name="Date1"
                value={ConcernformData.Date1}
                onChange={handleChange}
                required
              /><br />

              वेळ : <span>-</span>
              <input
                type="text"
                name="Time"
                value={ConcernformData.Time}
                onChange={handleChange}
                required
              /><br />

              सही / अंगठा :<span>-</span>
              <input
                type="text"
                name="SignatureLhti2"
                value={ConcernformData.SignatureLhti2}
                onChange={handleChange}
                required
              />

            </p>


          </div>


        </div> */}

        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className="shdywy656230">
                    <label> रुग्णाचे नाव <span>:</span></label>
                    <input type="text"
                    name="Name"
                    value={ConcernformData.Name}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                </td>

                <td>
                <div className="shdywy656230">

                    <label> रुग्णाशी नाते <span>:</span></label>
                    <input type="text"
                    name="RelationshipWithPatient"
                    value={ConcernformData.RelationshipWithPatient}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>


         
                  <div className="shdywy656230">
                      <label>  तारीख <span>:</span> </label>
                      <input 
                      type="text"
                      name="Date1"
                      value={ConcernformData.Date1}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div>

                   

               
                  <div className="shdywy656230">

                      <label>   वेळ <span>:</span> </label>
                      <input 
                      type="text"
                      name="Time"
                      value={ConcernformData.Time}
                      onChange={handleChange}
                      required
                      >
                      </input>
                  </div>

                  <div className="shdywy656230">
                    <label> सही / अंगठा <span>:</span></label>
                    <input 
                    type="text"
                    name="SignatureLhti2"
                    value={ConcernformData.SignatureLhti2}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>
                </td>
              </tr>



              <tr>

                <td>
                <div className="shdywy656230">

                    <label>  नातेवाईकाचे नाव <span>:</span></label>
                    <input 
                    type="text"
                    name="NameOfRelative"
                    value={ConcernformData.NameOfRelative}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>


                  {/* <div className="shdywy656230">

                    <label>  पत्ता <span>:</span> </label>
                    <input 
                    type="text"
                    name="Address"
                    value={ConcernformData.Address}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}

                  <div className="shdywy656230">
                      <label> पत्ता : <span>:</span></label>
                      <textarea
                        name="Address"
                        value={ConcernformData.Address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  <div className="shdywy656230">

                    <label> सही / अंगठा <span>:</span></label>
                    <input
                    type="text"
                    name="SignatureLhti1"
                    value={ConcernformData.SignatureLhti1}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>
                </td>

                <td>

                </td>

              </tr>

            </tbody>
          </table>
        </div>


        

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmerConsentAForm;


