import React, { useState } from "react";

function Neurology() {
  const [neurologyData, setNeurologyData] = useState({

    History:"",
    PastInvestigations:"",
    Vision: "",
    Fundus: "",
    Fields: "",
    EOM: "",
    Pupils: "",
    Nerves: "",
    LowerCranialNerves: "",

    SensoryExam: "",
    InvoluntaryMovements: "",
    FN:"",
    Dysdiadoko: "",
    Tandem: "",
    Gait: "",
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setNeurologyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [inputValuesR, setInputValuesR] = useState({
    Shoulder: ["", "", "", ""],
    Hip: ["", "", "", ""],
    Elbow: ["", ""],
    Knee: ["", ""],
    Wrist: ["", ""],
    Hand: ["", ""],
    Ankle: ["", "", "", ""],
  });

  const [inputValuesL, setInputValuesL] = useState({
    Shoulder: ["", "", "", ""],
    Hip: ["", "", "", ""],
    Elbow: ["", ""],
    Knee: ["", ""],
    Wrist: ["", ""],
    Hand: ["", ""],
    Ankle: ["", "", "", ""],
  });

  const handleChangeR = (area, index, event) => {
    const value = event.target.value;
    setInputValuesR((prevState) => ({
      ...prevState,
      [area]: [
        ...prevState[area].slice(0, index),
        value,
        ...prevState[area].slice(index + 1),
      ],
    }));
  };

  const handleChangeL = (area, index, event) => {
    const value = event.target.value;
    setInputValuesL((prevState) => ({
      ...prevState,
      [area]: [
        ...prevState[area].slice(0, index),
        value,
        ...prevState[area].slice(index + 1),
      ],
    }));
  };

  const [inputValuesReflexesR, setInputValuesReflexesR] = useState({
    B1: '',
    T1: '',
    S1: '',
    K1: '',
    A1: '',
    Plantars1: '',
    Abdominals1: '',
    Abdominals2: '',
        Cr1: ''
  });

  const handleChangeReflexesR = (e, key) => {
    const { value } = e.target;
    setInputValuesReflexesR(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const [inputValuesReflexesL, setInputValuesReflexesL] = useState({
    B1: '',
    T1: '',
    S1: '',
    K1: '',
    A1: '',
    Plantars1: '',
    Abdominals1: '',
    Abdominals2: '',
    Cr1: ''
  });

  const handleChangeReflexesL = (e, key) => {
    const { value } = e.target;
    setInputValuesReflexesL(prevState => ({
        ...prevState,
        [key]: value
    }));
  };

  const handleSubmit = () => {
    // Here you can implement the logic to submit the data
    // For example, you can send a POST request to your backend server
    // with the collected data.
    console.log("Neurology data submitted:", neurologyData);
    // Reset state if needed after submission
    setNeurologyData({
      Vision: "",
      Fundus: "",
      Fields: "",
      EOM: "",
      Pupils: "",
      Nerves: "",
      LowerCranialNerves: "",
      SensoryExam: "",
      InvoluntaryMovements: "",
      Dysdiadoko: "",
      Tandem: "",
      Gait: "",
    });
    setInputValuesR({
        Shoulder: ["", "", "", ""],
        Hip: ["", "", "", ""],
        Elbow: ["", ""],
        Knee: ["", ""],
        Wrist: ["", ""],
        Hand: ["", ""],
        Ankle: ["", "", "", ""],
    })

    setInputValuesL({
        Shoulder: ["", "", "", ""],
        Hip: ["", "", "", ""],
        Elbow: ["", ""],
        Knee: ["", ""],
        Wrist: ["", ""],
        Hand: ["", ""],
        Ankle: ["", "", "", ""],
    })

    setInputValuesReflexesR({
        B1: '',
        T1: '',
        S1: '',
        K1: '',
        A1: '',
        Plantars1: '',
        Abdominals1: '',
        Abdominals2: '',
            Cr1: ''
    });

    setInputValuesReflexesL({
        T1: '',
        S1: '',
        K1: '',
        A1: '',
        Plantars1: '',
        Abdominals1: '',
        Abdominals2: '',
        Cr1: ''
    })
    // Reset other state variables if needed
  };


  return (
    <div className="appointment">
      <div className="Otdoctor_intra_Con">
            <div className="Otdoctor_intra_Con_2">
              <label>
                History <span>:</span>
              </label>
              <textarea
                name="History"
                value={neurologyData.History}
                onChange={handleChange1}
              ></textarea>
            </div>
          </div>
          <br />

          <div className="case_sheet_5con">
        <div className="case_sheet_5con_20">
          <label>
            Past Investigations <span>:</span>
          </label>
          <textarea
            id="PastInvestigations"
            name="PastInvestigations"
            value={neurologyData.PastInvestigations}
            onChange={handleChange1}
          ></textarea>
        </div>
        </div>
<br />

<div className="efwewedc_neuro">
        <div className="case_sheet_5con_20 nero20">
          <label>
            Examination <span>:</span>
          </label>
          <textarea
            id="Examination"
            name="Examination"
            value={neurologyData.Examination}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>

        <div className="case_sheet_5con_20 nero20">
          <label>
            General Exam <span>:</span>
          </label>
          <textarea
            id="GeneralExam"
            name="GeneralExam"
            value={neurologyData.GeneralExam}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>

        </div>
        <br />

        <br />

              <h4
        style={{
          color: "var(--labelcolor)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "start",
          padding: "10px",
        }}
      >
        Higher Functions
      </h4>
      <br />

      <div className="efwewedc_neuro">
        <div className="case_sheet_5con_20 nero20">
          <label>
            Vision <span>:</span>
          </label>
          <textarea
            id="Vision"
            name="Vision"
            value={neurologyData.Vision}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            Fundus <span>:</span>
          </label>
          <textarea
            id="Fundus"
            name="Fundus"
            value={neurologyData.Fundus}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            Fields <span>:</span>
          </label>
          <textarea
            id="Fields"
            name="Fields"
            value={neurologyData.Fields}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            EOM <span>:</span>
          </label>
          <textarea
            id="EOM"
            name="EOM"
            value={neurologyData.EOM}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            Pupils <span>:</span>
          </label>
          <textarea
            id="Pupils"
            name="Pupils"
            value={neurologyData.Pupils}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            5,7,8 Nerves <span>:</span>
          </label>
          <textarea
            id="Nerves"
            name="Nerves"
            value={neurologyData.Nerves}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
        <div className="case_sheet_5con_20 nero20">
          <label>
            Lower Cranial Nerves <span>:</span>
          </label>
          <textarea
            id="LowerCranialNerves"
            name="LowerCranialNerves"
            value={neurologyData.LowerCranialNerves}
            onChange={handleChange1}
            className="lifecycle_h3udwh34"
          ></textarea>
        </div>
      </div>

      <br />

      <h4
        style={{
          color: "var(--labelcolor)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "start",
          padding: "10px",
        }}
      >
        Nutrition / Tone
      </h4>

      <br />
      <div className="RegisFormcon" style={{ justifyContent: "center" }}>
        <div className="RegisForm_1">
          <label htmlFor="Power">
            Power <span>:</span>
          </label>
          <input
            type="text"
            id="Power"
            name="Power"
            value={neurologyData.Power}
            onChange={handleChange1}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Neck">
            Neck <span>:</span>
          </label>
          <input
            type="text"
            id="Neck"
            name="Neck"
            value={neurologyData.Neck}
            onChange={handleChange1}
            required
          />
        </div>
      </div>

      <br />

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th></th>
              <th>R</th>

              <th>L</th>

              <th></th>
              <th>R</th>
              <th>L</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <td>Shoulder</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      F <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                    <td>
                      Abd <span>:</span>
                    </td>
                    <td>
                      Add <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Shoulder[0]}
                    onChange={(event) => handleChangeR("Shoulder", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Shoulder[1]}
                    onChange={(event) => handleChangeR("Shoulder", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Shoulder[2]}
                    onChange={(event) => handleChangeR("Shoulder", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Shoulder[3]}
                    onChange={(event) => handleChangeR("Shoulder", 3, event)}
                  />
                </div>
              </td>

              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Shoulder[0]}
                    onChange={(event) => handleChangeL("Shoulder", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Shoulder[1]}
                    onChange={(event) => handleChangeL("Shoulder", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Shoulder[2]}
                    onChange={(event) => handleChangeL("Shoulder", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Shoulder[3]}
                    onChange={(event) => handleChangeL("Shoulder", 3, event)}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <td>Hip</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      F <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                    <td>
                      Abd <span>:</span>
                    </td>
                    <td>
                      Add <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Hip[0]}
                    onChange={(event) => handleChangeR("Hip", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Hip[1]}
                    onChange={(event) => handleChangeR("Hip", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Hip[2]}
                    onChange={(event) => handleChangeR("Hip", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Hip[3]}
                    onChange={(event) => handleChangeR("Hip", 3, event)}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Hip[0]}
                    onChange={(event) => handleChangeL("Hip", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Hip[1]}
                    onChange={(event) => handleChangeL("Hip", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Hip[2]}
                    onChange={(event) => handleChangeL("Hip", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Hip[3]}
                    onChange={(event) => handleChangeL("Hip", 3, event)}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <td>Elbow</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      F <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Elbow[0]}
                    onChange={(event) => handleChangeR("Elbow", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Elbow[1]}
                    onChange={(event) => handleChangeR("Elbow", 1, event)}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Elbow[0]}
                    onChange={(event) => handleChangeL("Elbow", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Elbow[1]}
                    onChange={(event) => handleChangeL("Elbow", 1, event)}
                  />
                </div>
              </td>

              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <td>Knee</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      F <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Knee[0]}
                    onChange={(event) => handleChangeR("Knee", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Knee[1]}
                    onChange={(event) => handleChangeR("Knee", 1, event)}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Knee[0]}
                    onChange={(event) => handleChangeL("Knee", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Knee[1]}
                    onChange={(event) => handleChangeL("Knee", 1, event)}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      rowGap: "25px",
                    }}
                  >
                    <td>Wrist</td>
                    <td>Hand</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      F <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                    <td>
                      I <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Wrist[0]}
                    onChange={(event) => handleChangeR("Wrist", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Wrist[1]}
                    onChange={(event) => handleChangeR("Wrist", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Hand[0]}
                    onChange={(event) => handleChangeR("Hand", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Hand[1]}
                    onChange={(event) => handleChangeR("Hand", 1, event)}
                  />
                </div>
              </td>

              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Wrist[0]}
                    onChange={(event) => handleChangeL("Wrist", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Wrist[1]}
                    onChange={(event) => handleChangeL("Wrist", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Hand[0]}
                    onChange={(event) => handleChangeL("Hand", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Hand[1]}
                    onChange={(event) => handleChangeL("Hand", 1, event)}
                  />
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    textAlign: "start",
                    width: "200px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                      width: "80px",
                      display: "flex",
                      textAlign: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <td>Ankle</td>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="eewujtd_u"
                  >
                    <td>
                      DF <span>:</span>
                    </td>
                    <td>
                      PF <span>:</span>
                    </td>
                    <td>
                      I <span>:</span>
                    </td>
                    <td>
                      E <span>:</span>
                    </td>
                  </div>
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesR.Ankle[0]}
                    onChange={(event) => handleChangeR("Ankle", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Ankle[1]}
                    onChange={(event) => handleChangeR("Ankle", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Ankle[2]}
                    onChange={(event) => handleChangeR("Ankle", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesR.Ankle[3]}
                    onChange={(event) => handleChangeR("Ankle", 3, event)}
                  />
                </div>
              </td>
              <td style={{ width: "150px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="ejd_td_input6"
                >
                  <input
                    type="text"
                    value={inputValuesL.Ankle[0]}
                    onChange={(event) => handleChangeL("Ankle", 0, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Ankle[1]}
                    onChange={(event) => handleChangeL("Ankle", 1, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Ankle[2]}
                    onChange={(event) => handleChangeL("Ankle", 2, event)}
                  />
                  <input
                    type="text"
                    value={inputValuesL.Ankle[3]}
                    onChange={(event) => handleChangeL("Ankle", 3, event)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Reflexes</th>
              <th>B</th>
              <th>T</th>
              <th>S</th>
              <th>K</th>
              <th>A</th>
              <th>Plantars</th>
              <th>Abdominals</th>
              <th>Cr</th>
            </tr>
          </thead>
          <tbody>
      <tr>
            <td>R</td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.B1} onChange={e => handleChangeReflexesR(e, 'B1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.T1} onChange={e => handleChangeReflexesR(e, 'T1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.S1} onChange={e => handleChangeReflexesR(e, 'S1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.K1} onChange={e => handleChangeReflexesR(e, 'K1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.A1} onChange={e => handleChangeReflexesR(e, 'A1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.Plantars1} onChange={e => handleChangeReflexesR(e, 'Plantars1')} />
            </td>
            <td style={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
            <div className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.Abdominals1} onChange={e => handleChangeReflexesR(e, 'Abdominals1')} />
              </div>
              <div className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.Abdominals2} onChange={e => handleChangeReflexesR(e, 'Abdominals2')} />
              </div>
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesR.Cr1} onChange={e => handleChangeReflexesR(e, 'Cr1')} />
            </td>
          </tr>

          <tr>
            <td>L</td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.B1} onChange={e => handleChangeReflexesL(e, 'B1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.T1} onChange={e => handleChangeReflexesL(e, 'T1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.S1} onChange={e => handleChangeReflexesL(e, 'S1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.K1} onChange={e => handleChangeReflexesL(e, 'K1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.A1} onChange={e => handleChangeReflexesL(e, 'A1')} />
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.Plantars1} onChange={e => handleChangeReflexesL(e, 'Plantars1')} />
            </td>
            <td style={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
              <div className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.Abdominals1} onChange={e => handleChangeReflexesL(e, 'Abdominals1')} />
              </div>
              <div className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.Abdominals2} onChange={e => handleChangeReflexesL(e, 'Abdominals2')} />
              </div>
            </td>
            <td className="ejd_td_input6">
              <input type="text" value={inputValuesReflexesL.Cr1} onChange={e => handleChangeReflexesL(e, 'Cr1')} />
            </td>
          </tr>
          </tbody>
        </table>
      </div>


<br />

<div className="efwewedc_neuro">
      <div className="case_sheet_5con_20 nero20">
        <label>
          Sensory Exam <span>:</span>
        </label>
        <textarea
          id="SensoryExam"
          name="SensoryExam"
          value={neurologyData.SensoryExam}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
      <div className="case_sheet_5con_20 nero20">
        <label>
          Involuntary Movements <span>:</span>
        </label>
        <textarea
          id="InvoluntaryMovements"
          name="InvoluntaryMovements"
          value={neurologyData.InvoluntaryMovements}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
     </div>
     <br />
     <br />
     <h4
        style={{
          color: "var(--labelcolor)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "start",
          padding: "10px",
        }}
      >
        Cerebellar Signs
      </h4>

      <br />
      <div className="efwewedc_neuro">
      <div className="case_sheet_5con_20 nero20">
        <label>
          F.N. <span>:</span>
        </label>
        <textarea
          id="FN"
          name="FN"
          value={neurologyData.FN}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
      <div className="case_sheet_5con_20 nero20">
        <label>
          Dysdiadoko <span>:</span>
        </label>
        <textarea
          id="Dysdiadoko"
          name="Dysdiadoko"
          value={neurologyData.Dysdiadoko}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
      <div className="case_sheet_5con_20 nero20">
        <label>
          Tandem <span>:</span>
        </label>
        <textarea
          id="Tandem"
          name="Tandem"
          value={neurologyData.Tandem}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
      <div className="case_sheet_5con_20 nero20">
        <label>
          Gait <span>:</span>
        </label>
        <textarea
          id="Gait"
          name="Gait"
          value={neurologyData.Gait}
          onChange={handleChange1}
          className="lifecycle_h3udwh34"
        ></textarea>
      </div>
    </div>
    <br />


<div className="Register_btn_con">
    <button className="RegisterForm_1_btns" onClick={handleSubmit}>Submit</button>
    </div>
    </div>
  );
}

export default Neurology;


