import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";


export default function IcuSurgicalHistory() {
  const dispatchvalue = useDispatch();
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);


  const [page, setPage] = useState(0);

  const [getdatastate, setgetdatastate] = useState(false);

  const [VitalFormData, setVitalFormData] = useState({
    SurgicalProcedure: "",
    DateOfSurgery: "",
    PostOpDate: "",
    MajorSurgicalEvents: "",
    

  });
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);


    setVitalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


  // useEffect(() => {
  //   const datte = format(new Date(), "yyyy-MM-dd");
  //   setVitalFormData((prev) => ({
  //     ...prev,
  //     CapturedDate: datte,
  //   }));
  // }, []);
  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `${urllink}IcuManagement/get_Surgical_History?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data[0]
          console.log("Fetched surgical history data:", data);
  
          // Ensure that each field has a defined value
          const updatedFormData = {
            SurgicalProcedure: data.Surgical_Procedure || "", // Ensure each field has a defined value
            DateOfSurgery: data.Date_Of_Surgery || "",
            PostOpDate: data.Post_Op_Date || "",
            MajorSurgicalEvents: data.Major_Surgical_Events || "",
            
          };
  
          // Update the form data fields based on the fetched data
          setVitalFormData(updatedFormData);
        })
        .catch((error) => {
          console.error("Error fetching surgical history:", error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id, getdatastate]);
  


  // Define the handleAdd function to handle the "Edit" button click
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically





  // console.log(userRecord,'rrrrrrrr')
  const Post_vital_data = () => {
    const requiredfields = [
      "SurgicalProcedure",
      "DateOfSurgery",
      "PostOpDate",
      "MajorSurgicalEvents",
      

    ];
    const existing = requiredfields.filter((field) => !VitalFormData[field]);

    if (existing.length > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const Allsenddata = {
        ...VitalFormData,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/IcuManagement/insert_Surgical_History`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          VitalFormData()
          successMsg("Data saved successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="RegisFormcon">
          {Object.keys(VitalFormData).map((labelname, index) => (
            <div className="RegisForm_1" key={index}>
              <label>
                {formatLabel(labelname)} <span>:</span>
              </label>
              {labelname === "AnyAdverseReactions" ? (
                <div className="RegisForm_1">

                  <div className="radio_head">
                    <div className="radio">
                      <label htmlFor="yes">
                        <input
                          type="radio"
                          id="yes"
                          name={labelname}
                          value="Yes"
                          className="radio_input"
                          checked={VitalFormData[labelname] === "Yes"}
                          onChange={handleInputChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="radio">
                      <label htmlFor="no">
                        <input
                          type="radio"
                          id="no"
                          name={labelname}
                          value="No"
                          className="radio_input"
                          checked={VitalFormData[labelname] === "No"}
                          onChange={handleInputChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              ) : labelname === "DateOfSurgery" ? (
                <input
                  type="date"
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  type="text"
                  name={labelname}
                  value={VitalFormData[labelname]} // Add this value attribute
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))}

        </div>
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={Post_vital_data}>
            Add
          </button>
        </div>




      </div>
      <ToastContainer />
    </>
  );
}
