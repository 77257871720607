import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});


const RoomMaster = () => {
  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  
  const userRecord = useSelector(state => state.userRecord?.UserData);
  const [LocationData, setLocationData] = useState([]);
  const [WardName, setWardName] = useState({
    WardId: '',
    WardType: '',
    Location: '',
    Status: 'Active'
  });
  const [Wards, setWards] = useState([])
  const [IswardEdit, setIswardEdit] = useState(false)
  const [Iswardget, setIswardget] = useState(false)
  const [WardRoomType, setWardRoomType] = useState({
    RoomId: '',
    WardType: '',
    RoomType: '',
    RoomCharge:'',
    DocCharge:'',
    NurseCharge:'',
    MisleniousCharge:'',
    TotalCharge:'',
    GST: 'Nill',
    Location: '',
    Status: 'Active'
  })

  const [WardRooms, setWardRoom] = useState([])
  const [IsRoomEdit, setIsRoomEdit] = useState(false)
  const [IsRoomGet, setIsRoomGet] = useState(false)
  const [PackageType, setPackageType] = useState({
    PackageId: '',
    WardType: '',
    RoomType: '',
    Days: '',
    Charge: '',
    GST: 'Nill',
    Location: '',
    Status: 'Active',
  })

  const [Packages, setPackages] = useState([])
  const [IsPackageEdit, setIsPackageEdit] = useState(false)
  const [IsPackageGet, setIsPackageGet] = useState(false)


  const [Building, setBuilding] = useState({
    BuildingId: "",
    BuildingName: "",
    Location: "",
    Status: "Active",
  });
  const [BuildingsData, setBuildingsData] = useState([]);
  const [IsBuildingEdit, setIsBuildingEdit] = useState(false);
  const [IsBuildingGet, setIsBuildingGet] = useState(false);

  const [Blocks, setBlocks] = useState({
    BlockId: "",
    BuildingName: "",
    BlockName: "",
    Location: "",
    Status: "Active",
  });

  const [BlocksData, setBlocksData] = useState([]);
  const [IsBlocksEdit, setIsBlocksEdit] = useState(false);
  const [IsBlocksGet, setIsBlocksGet] = useState(false);

  const [Floor, setFloor] = useState({
    FloorId: "",
    BuildingName: "",
    BlockName: "",
    FloorName: "",
    Location: "",
    Status: "Active",
  });

  const [FloorData, setFloorData] = useState([]);
  const [IsFloorEdit, setIsFloorEdit] = useState(false);
  const [IsFloorGet, setIsFloorGet] = useState(false);

  const [Buildingpage, setBuildingpage] = useState(0);
  const [Blockspage, setBlockspage] = useState(0);
  const [Floorpage, setFloorpage] = useState(0);
  const Buildingsshowdown = BuildingsData.length;
  const Blockshowdown = Blocks.length;
  const Floorhowdown = FloorData.length;
  const BuildingstotalPages = Math.ceil(BuildingsData.length / 10);
  const BlockstotalPages = Math.ceil(BlocksData.length / 10);
  const FloortotalPages = Math.ceil(FloorData.length / 10);

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const showdown = Wards.length;
  const showdown1 = WardRooms.length;
  const showdown2 = Packages.length;
  const totalPages = Math.ceil(Wards.length / 10);
  const totalPages1 = Math.ceil(WardRooms.length / 10);
  const totalPages2 = Math.ceil(Packages.length / 10);

  const pageSize = 10;


  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const handlePageChange2 = (params) => {
    setPage2(params.page);
  };
  const handlePageChangeBuilding = (params) => {
    setBuildingpage(params.page);
  };
  const handlePageChangeBlocks = (params) => {
    setBlockspage(params.page);
  };
  const handlePageChangeFloor = (params) => {
    setFloorpage(params.page);
  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };

  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    const width = dummyElement.offsetWidth;

    document.body.removeChild(dummyElement);

    return width;
  }
  const dynamicColumns = [
    { field: "id", headerName: "S_No", width: 100 },
    { field: "WardType", headerName: "Ward Type", width: 150 },
    { field: "Location", headerName: "Location", width: 150 },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handlewardClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditWard(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];
  const dynamicColumns1 = [
    { field: "id", headerName: "S_No", width: 100 },
    ...['WardType', "RoomType", "RoomCharge","DocCharge","NurseCharge","MisleniousCharge","TotalCharge","GST", "Location"].map(
      (labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: labelWidth + 60,
          
        };
      }
    ), {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRoomClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditRoom(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];
  const dynamicColumns2 = [
    { field: "id", headerName: "S_No", width: 100 },
    ...['WardType', "RoomType", "Days", "Charge","GST", "Location"].map(
      (labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: labelWidth + 60,
          
        };
      }
    ), {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handlePackageClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handlePackageEdit(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];


  const BuildingdynamicColumns = [
    { field: "id", headerName: "S_No", width: 100 },
    { field: "BuildingName", headerName: "Building Name", width: 150 },
    { field: "Location", headerName: "Location", width: 150 },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleBuildingClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditBuilding(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];
  const BlockdynamicColumns = [
    { field: "id", headerName: "S_No", width: 100 },
    ...["BuildingName", "BlockName", "Location"].map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 60,
       
      };
    }),
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleBlockClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditBlock(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];
  const FloordynamicColumns = [
    { field: "id", headerName: "S_No", width: 100 },
    ...["BuildingName", "BlockName", "FloorName", "Location"].map(
      (labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: labelWidth + 60,
          
        };
      }
    ),
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleFloorClick(params.row)}
          >
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditFloor(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];



  useEffect(() => {
    axios.get(`https://vesoftometic.co.in/usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        console.log(data)

        setLocationData(data.filter((p) => p.location_name !== 'ALL'));
      })
      .catch((error) => {
        console.error('Error fetching location data:', error);
      });
  }, [])
  // ward ------------
  const handleWardType = () => {
    console.log(WardName)
    if (WardName.WardType && WardName.Location) {
      axios.post(`https://vesoftometic.co.in/usercontrol/insert_Ward_Type`, WardName)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setWardName({
            WardId: '',
            WardType: '',
            Location: '',
            Status: 'Active'
          })
          setIswardEdit(false);
          setIswardget(!Iswardget)
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill the WardType');
    }
  };
  const handleEditWard = (row) => {

    setWardName({ ...row })
    setIswardEdit(true)
    const inputElement = document.getElementById("WardType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  useEffect(() => {
    axios.get(`https://vesoftometic.co.in/usercontrol/get_ward_type`)
      .then((response) => {
        console.log(response)
        setWards(response.data.map((p) => ({
          ...p,
          id: p.WardId
        })))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [Iswardget])

  const handlewardClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`https://vesoftometic.co.in/usercontrol/insert_Ward_Type`, data)
      .then((response) => {
        console.log(response)
        setIswardget(!Iswardget)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Room----------

  const handleRoomType = () => {
    const requiredfields=Object.keys(WardRoomType).filter((p)=>p!=='RoomId'&&p!=='id')
    console.log(requiredfields,'----')
    const exist=requiredfields.filter((field)=>!WardRoomType[field])
    if (exist.length===0) {
      axios.post(`https://vesoftometic.co.in/usercontrol/insert_Room_Type`, WardRoomType)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setWardRoomType({
            RoomId: '',
            WardType: '',
            RoomType: '',
            RoomCharge:'',
            DocCharge:'',
            NurseCharge:'',
            MisleniousCharge:'',
            TotalCharge:'',
            GST: 'Nill',
            Location: '',
            Status: 'Active'
          })
          setIsRoomEdit(false);
          setIsRoomGet(!IsRoomGet)
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn(`Please Fill  the required Fields :${exist.join(',')}`);
    }
  };
  const handleEditRoom = (row) => {

    setWardRoomType({ ...row })
    setIsRoomEdit(true)
    const inputElement = document.getElementById("WardRoom");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  useEffect(() => {
    axios.get(`https://vesoftometic.co.in/usercontrol/get_Room_type`)
      .then((response) => {
        console.log(response)
        setWardRoom(response.data.map((p) => ({
          ...p,
          id: p.RoomId
        })))
      })
      .catch((error) => {
        console.log(error)
      })

  }, [IsRoomGet])

  const handleRoomClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`https://vesoftometic.co.in/usercontrol/insert_Room_Type`, data)
      .then((response) => {
        console.log(response)
        setIsRoomGet(!IsRoomGet)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // package-------------

  const handlePackageType = () => {
    if (PackageType.WardType && PackageType.RoomType && PackageType.Days && PackageType.Charge && PackageType.Location) {
      axios.post(`https://vesoftometic.co.in/usercontrol/insert_Package`, PackageType)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setPackageType({
            PackageId: '',
            WardType: '',
            RoomType: '',
            Days: '',
            Charge: '',
            GST:'Nill',
            Location: '',
            Status: 'Active',
          })
          setIsPackageEdit(false);
          setIsPackageGet(!IsPackageGet)
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill all the Fields');
    }
  };



  const handlePackageEdit = (row) => {

    setPackageType({ ...row })
    setIsPackageEdit(true)
    const inputElement = document.getElementById("PackageType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  useEffect(() => {
    axios.get(`https://vesoftometic.co.in/usercontrol/get_Package_type`)
      .then((response) => {
        console.log(response)
        setPackages(response.data.map((p) => ({
          ...p,
          id: p.PackageId
        })))
      })
      .catch((error) => {
        console.log(error)
      })
    axios.get(`https://vesoftometic.co.in/usercontrol/get_Package_type_id`)
      .then((response) => {
        console.log(response)
        setPackageType((prev) => ({
          ...prev,
          PackageId: response.data.next_id
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [IsPackageGet])

  const handlePackageClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`https://vesoftometic.co.in/usercontrol/insert_Package`, data)
      .then((response) => {
        console.log(response)
        setIsPackageGet(!IsPackageGet)
      })
      .catch((error) => {
        console.log(error)
      })
  }




  
  // Building ------------
  const handleBuilding = () => {
    if (Building.BuildingName && Building.Location) {
      axios
        .post(`https://vesoftometic.co.in/usercontrol/insert_Building`, Building)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            successMsg(response.data.message);
          } else {
            userwarn(response.data.Exists);
          }
          setBuilding({
            BuildingId: "",
            BuildingName: "",
            Loaction: "",
            Status: "Active",
          });
          setIsBuildingEdit(false);
          setIsBuildingGet(!IsBuildingGet);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userwarn("Please Fill the BuildingName and Location");
    }
  };
  const handleBuildingClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === "Active" ? "InActive" : "Active",
    };
    axios
      .post(`https://vesoftometic.co.in/usercontrol/insert_Building`, data)
      .then((response) => {
        console.log(response);
        setIsBuildingGet(!IsBuildingGet);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEditBuilding = (row) => {
    setBuilding({ ...row });
    setIsBuildingEdit(true);
    const inputElement = document.getElementById("BuildingType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/usercontrol/get_Building`)
      .then((response) => {
        console.log(response);
        setBuildingsData(
          response.data.map((p) => ({
            ...p,
            id: p.BuildingId,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IsBuildingGet]);

  //  Blocks

  const handleBlock = () => {
    if (Blocks.BuildingName && Blocks.BlockName && Blocks.Location) {
      axios
        .post(`https://vesoftometic.co.in/usercontrol/insert_Block`, Blocks)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            successMsg(response.data.message);
          } else {
            userwarn(response.data.Exists);
          }
          setBlocks({
            BlockId: "",
            BuildingName: "",
            BlockName: "",
            Location: "",
            Status: "Active",
          });
          setIsBlocksEdit(false);
          setIsBlocksGet(!IsBlocksGet);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userwarn("Please Fill all the Fields");
    }
  };

  const handleBlockClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === "Active" ? "InActive" : "Active",
    };
    axios
      .post(`https://vesoftometic.co.in/usercontrol/insert_Block`, data)
      .then((response) => {
        console.log(response);
        setIsBlocksGet(!IsBlocksGet);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEditBlock = (row) => {
    setBlocks({ ...row });
    setIsBlocksEdit(true);
    const inputElement = document.getElementById("BlocksType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/usercontrol/get_Block`)
      .then((response) => {
        console.log(response);
        setBlocksData(
          response.data.map((p) => ({
            ...p,
            id: p.BlockId,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IsBlocksGet]);

  //  floor

  const handleFloor = () => {
    if (
      Floor.BuildingName &&
      Floor.BlockName &&
      Floor.FloorName &&
      Floor.Location
    ) {
      axios
        .post(`https://vesoftometic.co.in/usercontrol/insert_Floor`, Floor)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            successMsg(response.data.message);
          } else {
            userwarn(response.data.Exists);
          }
          setFloor({
            FloorId: "",
            BuildingName: "",
            BlockName: "",
            FloorName: "",
            Location: "",
            Status: "Active",
          });
          setIsFloorEdit(false);
          setIsFloorGet(!IsFloorGet);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userwarn("Please Fill all the Fields");
    }
  };

  const handleFloorClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === "Active" ? "InActive" : "Active",
    };
    axios
      .post(`https://vesoftometic.co.in/usercontrol/insert_Floor`, data)
      .then((response) => {
        console.log(response);
        setIsFloorGet(!IsFloorGet);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEditFloor = (row) => {
    setBlocks({ ...row });
    setIsBlocksEdit(true);
    const inputElement = document.getElementById("FloorType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/usercontrol/get_Floor`)
      .then((response) => {
        console.log(response);
        setFloorData(
          response.data.map((p) => ({
            ...p,
            id: p.FloorId,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IsFloorGet]);

// ---------
useEffect(() => {
  const { RoomCharge = 0, DocCharge = 0, NurseCharge = 0, MisleniousCharge = 0 } = WardRoomType;
  const totalCharge = parseFloat(RoomCharge) + parseFloat(DocCharge) + parseFloat(NurseCharge) + parseFloat(MisleniousCharge);
  
  setWardRoomType(prev => ({
    ...prev,
    TotalCharge: totalCharge.toFixed(3)
  }));
}, [WardRoomType.RoomCharge, WardRoomType.DocCharge, WardRoomType.NurseCharge, WardRoomType.MisleniousCharge]);

useEffect(()=>{
  if (parseInt(WardRoomType.TotalCharge) > 5000) {
    setWardRoomType((prev) => ({ ...prev, GST: '' }));
  } else {
    setWardRoomType((prev) => ({ ...prev, GST: 'Nill' }));
  }
},[WardRoomType.TotalCharge])

  return (
    <>
      <div className='appointment'>
        <div className='h_head'>
          <h3>Room Masters</h3>
        </div>

        <div className="Add_items_Purchase_Master">
          <span>Building</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          id="BuildingType"
        >
          <div className="Total_input_container_patientData">
            <div className="inp_container_all_intakeoutput">
              <label>
                {" "}
                Location <span>:</span>{" "}
              </label>

              <select
                name="Location"
                required
                value={Building.Location}
                onChange={(e) =>
                  setBuilding((prev) => ({ ...prev, Location: e.target.value }))
                }
              >
                <option value="">Select</option>
                {LocationData.map((p, index) => (
                  <option key={index} value={p.location_name}>
                    {p.location_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Building Name <span>:</span>
              </label>
              <input
                type="text"
                placeholder="Enter the Building Name"
                name="BuildingName"
                required
                value={Building.BuildingName}
                onChange={(e) =>
                  setBuilding((prev) => ({
                    ...prev,
                    BuildingName: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
          </div>
          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleBuilding}>
              {IsBuildingEdit ? "Update" : "Add"}
            </button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={BuildingsData.slice(
                    Buildingpage * pageSize,
                    (Buildingpage + 1) * pageSize
                  )} // Display only the current page's data
                  columns={BuildingdynamicColumns} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChangeBuilding}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {Buildingsshowdown > 0 && BuildingsData.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setBuildingpage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={Buildingpage === 0}
                    >
                      Previous
                    </button>
                    Page {Buildingpage + 1} of {BuildingstotalPages}
                    <button
                      onClick={() =>
                        setBuildingpage((prevPage) =>
                          Math.min(prevPage + 1, BuildingstotalPages - 1)
                        )
                      }
                      disabled={Buildingpage === BuildingstotalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {Buildingsshowdown !== 0 && BuildingsData.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div>
        <div className="Add_items_Purchase_Master">
          <span>Block</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          id="BuildingType"
        >
          <div className="Total_input_container_patientData">
            <div className="inp_container_all_intakeoutput">
              <label>
                {" "}
                Location <span>:</span>{" "}
              </label>

              <select
                name="Location"
                required
                value={Blocks.Location}
                onChange={(e) =>
                  setBlocks((prev) => ({ ...prev,BuildingName:'',BlockName:'', Location: e.target.value }))
                }
              >
                <option value="">Select</option>
                {LocationData.map((p, index) => (
                  <option key={index} value={p.location_name}>
                    {p.location_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                {" "}
                Building Name <span>:</span>{" "}
              </label>
              <select
                name="BuildingName"
                required
                value={Blocks.BuildingName}
                onChange={(e) =>
                  setBlocks((prev) => ({
                    ...prev,
                    BuildingName: e.target.value.toUpperCase(),
                  }))
                }
              >
                <option value="">Select</option>
                {[...BuildingsData.filter((field)=>field.Location===Blocks.Location&& field.Status==='Active').map((p,ind)=>(
                  <option value={p.BuildingName} key={ind}>{p.BuildingName}</option>
                ))]}
              </select>
              
              
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
              Block Name <span>:</span>
              </label>
              <input
                type="text"
                placeholder="Enter the Block Name"
                name="BlockName"
                required
                value={Blocks.BlockName}
                onChange={(e) =>
                  setBlocks((prev) => ({
                    ...prev,
                    BlockName: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
          </div>
          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleBlock}>
              {IsBlocksEdit ? "Update" : "Add"}
            </button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={BlocksData.slice(
                    Blockspage * pageSize,
                    (Blockspage + 1) * pageSize
                  )} // Display only the current page's data
                  columns={BlockdynamicColumns} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChangeBlocks}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {Blockshowdown > 0 && BlocksData.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setBlockspage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={Blockspage === 0}
                    >
                      Previous
                    </button>
                    Page {Blockspage + 1} of {BlockstotalPages}
                    <button
                      onClick={() =>
                        setBlockspage((prevPage) =>
                          Math.min(prevPage + 1, BlockstotalPages - 1)
                        )
                      }
                      disabled={Blockspage === BlockstotalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {Blockshowdown !== 0 && BlocksData.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div>
        <div className="Add_items_Purchase_Master">
          <span>Floor</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          id="BuildingType"
        >
          <div className="Total_input_container_patientData">
            <div className="inp_container_all_intakeoutput">
              <label>
                {" "}
                Location <span>:</span>{" "}
              </label>

              <select
                name="Location"
                required
                value={Floor.Location}
                onChange={(e) =>
                  setFloor((prev) => ({ ...prev, Location: e.target.value }))
                }
              >
                <option value="">Select</option>
                {LocationData.map((p, index) => (
                  <option key={index} value={p.location_name}>
                    {p.location_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                {" "}
                Building Name <span>:</span>{" "}
              </label>
              <select
                name="BuildingName"
                required
                value={Floor.BuildingName}
                onChange={(e) =>
                  setFloor((prev) => ({
                    ...prev,
                    BuildingName: e.target.value,
                  }))
                }
              >
                <option value="">Select</option>
                {[...BuildingsData.filter((field)=>field.Location===Floor.Location&& field.Status==='Active').map((p,ind)=>(
                  <option value={p.BuildingName} key={ind}>{p.BuildingName}</option>
                ))]}
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
              Block Name <span>:</span>
              </label>
              <select
                name="BlockName"
                required
                value={Floor.BlockName}
                onChange={(e) =>
                  setFloor((prev) => ({
                    ...prev,
                    BlockName: e.target.value,
                  }))
                }
              >
                <option value="">Select</option>
                {[...BlocksData.filter((field)=>field.Location===Floor.Location&&field.BuildingName===Floor.BuildingName&& field.Status==='Active').map((p,ind)=>(
                  <option value={p.BlockName} key={ind}>{p.BlockName}</option>
                ))]}
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
              Floor Name <span>:</span>
              </label>
              <input
                type="text"
                placeholder="Enter the Floor Name"
                name="FloorName"
                required
                value={Floor.FloorName}
                onChange={(e) =>
                  setFloor((prev) => ({
                    ...prev,
                    FloorName: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
          </div>
          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleFloor}>
              {IsFloorEdit ? "Update" : "Add"}
            </button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={FloorData.slice(
                    Floorpage * pageSize,
                    (Floorpage + 1) * pageSize
                  )} // Display only the current page's data
                  columns={FloordynamicColumns} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChangeFloor}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {Floorhowdown > 0 && FloorData.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setFloorpage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={Floorpage === 0}
                    >
                      Previous
                    </button>
                    Page {Floorpage + 1} of {FloortotalPages}
                    <button
                      onClick={() =>
                        setFloorpage((prevPage) =>
                          Math.min(prevPage + 1, FloortotalPages - 1)
                        )
                      }
                      disabled={Floorpage === FloortotalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {Floorhowdown !== 0 && FloorData.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div>


        <div className="Add_items_Purchase_Master">
          <span>Wards</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardType'>
          <div className="Total_input_container_patientData" >
            <div className="inp_container_all_intakeoutput">
              <label> Ward Type <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Ward Type'
                name='WardType'
                required
                value={WardName.WardType}
                onChange={(e) => setWardName((prev) => ({ ...prev, WardType: e.target.value.toUpperCase() }))}
              />
            </div>

            <div className="inp_container_all_intakeoutput">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={WardName.Location}
                onChange={(e) => setWardName((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>

          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleWardType}>{IswardEdit ? "Update" : "Add"}</button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={Wards.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && Wards.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page === 0}
                    >
                      Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                      onClick={() =>
                        setPage((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page1 === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown !== 0 && Wards.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div>
        <div className="Add_items_Purchase_Master">
          <span>Rooms</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardRoom'>
          <div className="Total_input_container_patientData " >
            <div className="inp_container_all_intakeoutput">
              <label> Ward Type <span>:</span> </label>

              <select
                name='WardType'
                required
                value={WardRoomType.WardType}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, WardType: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  Array.from(new Set(Wards.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Room Type <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Room Type'
                name='RoomType'
                required
                value={WardRoomType.RoomType}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, RoomType: e.target.value.toUpperCase() }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>Room Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Room Charge'
                name='RoomCharge'
                required
                onKeyDown={blockInvalidChar}
                value={WardRoomType.RoomCharge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, RoomCharge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>Doctor Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Doctor Charge'
                name='DocCharge'
                required
                onKeyDown={blockInvalidChar}
                value={WardRoomType.DocCharge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, DocCharge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Nurse Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Nurse Charge'
                name='NurseCharge'
                required
                onKeyDown={blockInvalidChar}
                value={WardRoomType.NurseCharge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, NurseCharge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Other Charges <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Other Charges'
                name='MisleniousCharge'
                required
                onKeyDown={blockInvalidChar}
                value={WardRoomType.MisleniousCharge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, MisleniousCharge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Total Charges <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Total Charges'
                name='TotalCharge'
                onKeyDown={blockInvalidChar}
                required
                readOnly
                value={WardRoomType.TotalCharge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, TotalCharge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> GST <span>:</span> </label>
              {
                parseInt(WardRoomType.TotalCharge) > 5000 ?
                <select
                name='GST'
                value={WardRoomType.GST}
                readOnly
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, GST: e.target.value }))}
                
              >
                <option value=''>Select</option>
                <option value='18'>18 %</option>
                <option value='12'>12 %</option>
                <option value='9'>9 %</option>
                
              </select>
    :
    <input
                type="text"
                placeholder='Enter the GST'
                name='GST'
                required
                readOnly
                value={WardRoomType.GST}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, TotalCharge: e.target.value }))}
              />
              }
            
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={WardRoomType.Location}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>
          </div>




          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleRoomType}>{IsRoomEdit ? "Update" : "Add"}</button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={WardRooms.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && WardRooms.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages1 - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && WardRooms.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div>
        {/* <div className="Add_items_Purchase_Master">
          <span>Packages</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='PackageType'>
          <div className="Total_input_container_patientData " >

            <div className="inp_container_all_intakeoutput">
              <label> Package Id <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Package Id'
                name='PackageId'
                required
                value={PackageType.PackageId}
                onChange={(e) => setPackageType((prev) => ({ ...prev, PackageId: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Ward Type <span>:</span> </label>

              <select
                name='WardType'
                required
                value={PackageType.WardType}
                onChange={(e) => setPackageType((prev) => ({ ...prev, WardType: e.target.value }))}
              >
                <option value=''>Select</option>

                {
                  Array.from(new Set(WardRooms.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Room Type <span>:</span> </label>

              <select
                name='RoomType'
                required
                value={PackageType.RoomType}
                onChange={(e) => setPackageType((prev) => ({ ...prev, RoomType: e.target.value }))}
              >
                <option value=''>Select</option>


                {
                  Array.from(new Set(WardRooms.filter(p => p.Status === 'Active' && p.WardType === PackageType.WardType).map(p => p.RoomType))).map((RoomType, index) => (
                    <option key={index} value={RoomType}>{RoomType}</option>
                  ))
                }
              </select>
            </div>

            <div className="inp_container_all_intakeoutput">
              <label> Package Days <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Package Days'
                name='Days'
                required
                value={PackageType.Days}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Days: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Charge'
                name='Charge'
                required
                value={PackageType.Charge}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Charge: e.target.value }))}
              />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={PackageType.Location}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>



          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handlePackageType}>{IsPackageEdit ? "Update" : "Add"}</button>
          </div>

          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={Packages.slice(
                    page2 * pageSize,
                    (page2 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns2} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange2}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown2 > 0 && Packages.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage2((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page2 === 0}
                    >
                      Previous
                    </button>
                    Page {page2 + 1} of {totalPages2}
                    <button
                      onClick={() =>
                        setPage2((prevPage) =>
                          Math.min(prevPage + 1, totalPages2 - 1)
                        )
                      }
                      disabled={page2 === totalPages2 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown2 !== 0 && Packages.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        </div> */}
      </div>
      <ToastContainer />
    </>
  )

}
export default RoomMaster;