import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import PastHistory from "./PastHistory";
import Samplecapture from "../LabTechnician/Capturesample";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import {useNavigate} from 'react-router-dom'
import { TbDelta } from "react-icons/tb";
import { useSelector } from 'react-redux';
import Barcode from "react-barcode";
// import Reportcapture from "./ReportCapture";
import Reportverify from "./Reportverify";
function Navigationseior({ userRecord, isSidebarOpen, Data }) {
  console.log(Data);

  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);

  const navigatelab = useSelector((state) => state.userRecord?.navigationlab);
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("Reportentrysenior");
  const [isToggled, setIsToggled] = useState(false);
  const [formData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
  });

  useEffect(()=>{
    console.log(navigatelab)
    if(navigatelab){
      setActiveTab(navigatelab)
    }
    else{
      setActiveTab('Reportentrysenior')
    }
  }, [navigatelab])

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".new-kit")) {
        closeToggle();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });

  return (
    <>
  
      <div className="samplecapture_component">
      <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Result Verify Form</h3>
          </div>
        </div> 
        <div className="patientdetails_lab">
          <div className='patientdata'>
            
            <label className='phelobotomist_head'><span className='logo_profile'><CgProfile /></span>Patient Name<span>:</span></label>
            <span>{capturedatas.Patient_Name}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><SlCalender /></span>Date  <span>:</span></label>
            <span>{capturedatas.UpdatedAt}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><MdPhonelinkRing /></span>Phone <span>:</span></label>
            <span>{capturedatas.Phone}</span>
          </div>

          <div className='patientdata'>
            <Barcode value={capturedatas.Barcode || ''} lineColor="black" height={50} width={1.5} />
          </div>
        </div>
        {/* <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("CaptureApprovesenior")}>
               Capture Sample
              </button>
              |
              <button onClick={() => handleTabChange("Reportentrysenior")}>
                Report Entry
              </button>
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("CaptureApprovesenior")}>
                    Capture Sample
                    </button>
                    |
                    <button onClick={() => handleTabChange("Reportentrysenior")}>
                    Report Entry
                    </button>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>

      {/* {activeTab === "CaptureApprovesenior" && (
        <Samplecapture formData={formData} userRecord={userRecord} />
      )} */}
      {activeTab === "Reportentrysenior" && (
        <Reportverify formData={formData} userRecord={userRecord} />
      )}
      
    </>
  );
}


export default Navigationseior;
