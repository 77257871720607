import { combineReducers } from "redux";

import userRecorddata from "./UserrecordStore";
import FinanceStore from "./FinanceStore";
import IpManagement from "./IpManagement";
import InsuranceStore from "./InsuranceStore";
import RateCardStore from './RateCardStore';

const Rootreducer = combineReducers({
  userRecord: userRecorddata,
  InPatients: IpManagement,
  FinanceStore: FinanceStore,
  InsuranceStore: InsuranceStore,
  RateCardStore:RateCardStore,

});
export default Rootreducer;
