import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";





export default function InsuranceCourierInfo() {

 



  const navigate = useNavigate();
  

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );

  const [CourierDatas,setCourierDatas]=useState({
    CourierName:'',
    TrackingNumber:'',
    DateSend:'',
    DateReceived:'',
  })
  
  const handelCourierDatas =(e)=>{
    const {name,value} =e.target
    setCourierDatas((prev)=>({
        ...prev,
        [name]:value,
    }))
  }

  const Savedata =()=>{

    const params={

        MRN: InsuranceUpdatedata.MRN,
        ContactNumber:InsuranceUpdatedata.ContactNumber,
        Location:userRecord.location,
        createAt:userRecord.username,
        CourierName:CourierDatas.CourierName,
        TrackingNumber:CourierDatas.TrackingNumber,
        DateSend:CourierDatas.DateSend,
        DateReceived:CourierDatas.DateReceived,
  
      }   
  
      axios.post(`https://vesoftometic.co.in/Insurance/insert_Claim_Courier_Information`,params)
      .then((response) => {
          console.log('Form data submitted.',response.data)
      })
      .catch((error) => {
          console.error(error);
      });

  }

  return (
    <>
     <div className="Supplier_Master_Container">
    
               
    <div className="RegisFormcon column_regisFormcon_forinsurance">
   
    <div className="Selected-table-container">
        <table className="selected-medicine-table2 _hide_hover_table">
        <thead className="Spl_backcolr_09">
            <tr>
            <th className="Provisional_Diagnosis">Courier Name</th>
            <th className="ICD_Code">Tracking Number</th>
            <th className="ICD_Code">Date Send</th>
            <th className="ICD_Code">Date Received</th>
            </tr>
        </thead>
        <td>
        <input
        type="text"
        name="CourierName"
        className="medication_90"
        value={CourierDatas.CourierName}
        onChange={(e)=>handelCourierDatas(e)}
        />            
        </td>
        <td>
        <input
        type="text"
        name="TrackingNumber"
        className="medication_90" 
        value={CourierDatas.TrackingNumber}
        onChange={(e)=>handelCourierDatas(e)}

        />  
                        
        </td>
        <td>
        <input
        type="date"
        name="DateSend"
        className="medication_90"
        value={CourierDatas.DateSend}
        onChange={(e)=>handelCourierDatas(e)}

        />                          
        </td>
        <td>
        <input
        type="date"
        name="DateReceived"
        className="medication_90"
        value={CourierDatas.DateReceived}
        onChange={(e)=>handelCourierDatas(e)}

        />                          
        </td>        
        </table>
    </div>
    </div>


      </div>

       
        <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>
        <div className="submit_button_prev_next">
            <button onClick={Savedata} >
                Save                
            </button>
          </div>
        
        </div>
        

        

    </>
  );
}
