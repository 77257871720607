import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
// import "./IcuNurseVitals.css";
import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function EmergencyBloodGas() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  const [page, setPage] = useState(0);
  const [summa, setsumma] = useState([]);
  const [getdatastate, setgetdatastate] = useState(false);

  const [BloodGas, setBloodGas] = useState({
    Time: "",
    Date: "",
    pH: "",
    pCO2: "",
    pO2: "",
    HCO3: "",
    SAT: "",
    BE: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);
    setBloodGas((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `https://vesoftometic.co.in/EmergencyManagement/get_BloodGas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data;
          console.log("222", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching doctor names:", error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id, getdatastate]);

  const pageSize = 10;
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const formatLabel = (label) => {
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    } else {
      return label;
    }
  };

  const cleardata = () => {
    setBloodGas({
      Time: "",
      Date: "",
      pH: "",
      pCO2: "",
      pO2: "",
      HCO3: "",
      SAT: "",
      BE: "",
    });
  };

  const Post_vital_data = () => {
    const requiredfields = [
      "Time",
      "Date",
      "pH",
      "pCO2",
      "pO2",
      "HCO3",
      "SAT",
      "BE",
    ];
    const existing = requiredfields.filter((field) => !BloodGas[field]);

    if (existing.length > 0) {
      alert("please fill empty fields: " + existing.join(","));
    } else {
      const Allsenddata = {
        ...BloodGas,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/EmergencyManagement/insert_BloodGas`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          cleardata();
          setgetdatastate(!getdatastate);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...Object.keys(BloodGas).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 60,
      };
    }),
  ];

  function getTextWidth(text) {
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    const width = dummyElement.offsetWidth;

    document.body.removeChild(dummyElement);

    return width;
  }

  return (
    <>
      <div className="new-patient-registration-form
">
        <div className="RegisFormcon">
          {Object.keys(BloodGas).map((labelname, index) => (
            <div className="RegisForm_1" key={index}>
              <label>
                {labelname === "pCO2" ? (
                  <span>pCO2 (mmHg)</span>
                ) : labelname === "pO2" ? (
                  <span>pO2 (mmHg)</span>
                ) : labelname === "HCO3" ? (
                  <span>HCO3 (mEq/L)</span>
                ) : labelname === "SAT" ? (
                  <span>SAT (%)</span>
                ) : labelname === "BE" ? (
                  <span>BE (mEq/L)</span>
                ) : labelname === "Date" ? (
                    <input
                      type="date"
                      name={labelname}
                      value={BloodGas[labelname]}
                      onChange={handleInputChange}
                    />
                )  : (
                  formatLabel(labelname)
                )}
                <span>:</span>
              </label>

              <input
                type={labelname === "Time" ? "time" : "number"}
                name={labelname}
                onKeyDown={blockInvalidChar}
                value={BloodGas[labelname]}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </div>

        {/* <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <button className="btn-add" onClick={Post_vital_data}>
            Add
          </button>
        </div> */}

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={Post_vital_data}>
            Add
          </button>
        </div>
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)}
                columns={dynamicColumns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
