import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from "axios";




const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});



function ClaimsDashboard() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const navigate = useNavigate();

  const dispatchvalue = useDispatch()

  const [Filterby,setFilterby]=useState('DRAFT')

  const [StatusCount,setStatusCount]=useState({
    DraftCount:'',
    SubmittedCount:'',
    ApprovedCount:'',
    RejectedCount:'',
    DischargeCount:'',
    PartiallyPaidCount:'',
    FullyPaidCount:''
})

const [filteredRows, setFilteredRows] = useState([]);

const [Selectrowdata,setSelectrowdata]=useState([])


const [page, setPage] = useState(0);

const pageSize = 10;
const showdown = Selectrowdata.length;
const totalPages = Math.ceil(Selectrowdata.length / 10);

const handlePageChange = (params) => {
  setPage(params.page);
};



  const [updateformData, setUpdateFormData] = useState({
    courierName: "",
    payerName: "",
    courierDate: "",
    trackingNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({
      ...updateformData,
      [name]: value,
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const handleEditClick = (params) => {
    setOpenModal(true);
  };

  const [activeTab, setActiveTab] = useState("ClaimActionPending");
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };



  const GetDashBorddata=()=>{
    axios
    .get(
      `https://vesoftometic.co.in/Insurance/get_CLAIM_DashBorddata`
    )
    .then((response) => {
      console.log('Claim',response.data);
      const datass=response.data
  
      setFilteredRows([
        ...datass.map((row, ind) => ({
          id: ind + 1,
          ...row,
        })),
      ])
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  useEffect(()=>{
    GetDashBorddata()
  },[])



  useEffect(()=>{
    if(filteredRows.length !== 0 && Filterby!=='' ){
     
      const Selectdata=filteredRows.filter((ele)=>ele.Papersstatus === Filterby )
  
      setSelectrowdata(Selectdata)
    
    }
  
  },[filteredRows,Filterby])


  useEffect(()=>{
    if(filteredRows.length !== 0 ){
     
  
  
      let Selectdata1=filteredRows.filter((ele)=>ele.Papersstatus === 'DRAFT')
      let Selectdata2=filteredRows.filter((ele)=>ele.Papersstatus === 'SUBMITTED')
      let Selectdata3=filteredRows.filter((ele)=>ele.Papersstatus === 'APPROVED')
      let Selectdata4=filteredRows.filter((ele)=>ele.Papersstatus === 'REJECTED')
      let Selectdata5=filteredRows.filter((ele)=>ele.Papersstatustype === "PartiallyPaid")
      let Selectdata6=filteredRows.filter((ele)=>ele.Papersstatustype === "FullyPaid")

  
      setStatusCount((prev)=>({
        ...prev,
        DraftCount:Selectdata1.length || 0,
        SubmittedCount:Selectdata2.length || 0,
        ApprovedCount:Selectdata3.length || 0,
        RejectedCount:Selectdata4.length || 0,
        PartiallyPaidCount:Selectdata5.length || 0,
        FullyPaidCount:Selectdata6.length || 0

      }))
    
    }else{
  
      setStatusCount((prev)=>({
        ...prev,
        DraftCount: 0,
        SubmittedCount: 0,
        ApprovedCount: 0,
        RejectedCount: 0,
        PartiallyPaidCount:0,
        FullyPaidCount:0
      }))
  
    }
  
  },[filteredRows])



  
const handlegotoGRN = (params) => {
  const index = params.row.MRN;
  const index1 = params.row.ContactNumber;
  const index2 = params.row.Papersstatus;



  console.log("index:",index,index1,index2);
  dispatchvalue({
    type: "InsuranceUpdatedata",
    value:{MRN:index,ContactNumber:index1,Papersstatus:index2,GetStatus:'Claim'},
  });
  navigate("/Home/InsuranceMainpage");
};

  
   
  
const columns = [
  { field: "PatientName", headerName: "Patient Name", width: 150 },
  { field: "MRN", headerName: "MRN", width: 120 },
  { field: "CLNumber", headerName: "CL Number", width: 130 },
  { field: "PayerTPAName", headerName: "Payer/TPA", width: 180 },
  {
    field: "PayerTPAMemberID",
    headerName: "Payer/TPA Member ID",
    width: 180,
  },
  { field: "CreatedAt", headerName: "Created Date", width: 150 },

  {
    field: "Edit",
    headerName: "Action",
    width: 180,
    renderCell: (params) => (
      <>
      <Button className="cell_btn" onClick={() => handlegotoGRN(params)} >
        <EditIcon />
      </Button>

      {params.row.Papersstatus === "SUBMITTED" ? <Button className="cell_btn" >
        <CheckCircleOutlineIcon  />
      </Button> :''}

      {params.row.Papersstatus === "SUBMITTED" ? <Button className="cell_btn" >
        <HighlightOffIcon/>
      </Button> :''}

      </>
    ),
  },
];



  return (
    <div className="Insurance_Dashboard_head">
      <div className="Insurance_dash_hending">
        <h4>Claim Dashboard</h4>
      </div>
      <br></br>

      <div className="serch_dash_insur_update_info">
        <button onClick={handleEditClick}>
          <UpgradeIcon />
          Update Courier Info
        </button>
      </div>

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason dcsewde2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Insurance_dash_hending">
              <h4>Update Courier Info</h4>
            </div>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Courier Name<span>:</span>
                </label>
                <select
                  name="courierName"
                  value={updateformData.courierName}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {/* Add options */}
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  Payer / TPA Name<span>:</span>
                </label>
                <select
                  name="payerName"
                  value={updateformData.payerName}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {/* Add options */}
                </select>
              </div>

              <div className="RegisForm_1">
                <label>
                  Courier Date<span>:</span>
                </label>
                <input
                  type="date"
                  name="courierDate"
                  value={updateformData.courierDate}
                  onChange={handleChange}
                ></input>
              </div>

              <div className="RegisForm_1">
                <label>
                  Docket Tracking Number<span>:</span>
                </label>
                <input
                  type="number"
                  name="trackingNumber"
                  value={updateformData.trackingNumber}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="serch_dash_insur">
        <input
          type="text"
          placeholder="Search by: Patient Name, MRN, CL Number, Payer/TPA Member ID"
        ></input>
        <p>
          <SearchIcon />
        </p>
      </div>
      <br></br>

      <div className="new-patient-registration-form1">
        <div className="Insurancenew-navigation">
          <h2>
            <div
              className="insur_box_chnge6" 
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              onClick={() => setFilterby("DRAFT")}
            >
              <button
                style={{
                  backgroundColor: "#ffd966",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Draft
              </button>
              <p style={{ backgroundColor: Filterby === 'DRAFT' ? '#ffd966' :"transparent" }}>{StatusCount.DraftCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
             
              onClick={() => setFilterby("SUBMITTED")}

            >
              <button
                style={{
                  backgroundColor: "#b3e0ff",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Submitted
              </button>
              <p style={{ backgroundColor: Filterby === 'SUBMITTED' ? '#b3e0ff' :"transparent" }}>{StatusCount.SubmittedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              onClick={() => setFilterby("APPROVED")}
            >
              <button
                style={{
                  backgroundColor: "#b3ffb3",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Approved
              </button>
              <p style={{ backgroundColor: Filterby === 'APPROVED' ? '#b3ffb3' :"transparent" }}>{StatusCount.ApprovedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              
              onClick={() => setFilterby("REJECTED")}
            >
              <button
                style={{
                  backgroundColor: "#ffcc99",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Rejected
              </button>
              <p style={{ backgroundColor: Filterby === 'REJECTED' ? '#ffcc99' : "transparent" }}>{StatusCount.RejectedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              
              onClick={() => setFilterby("PartiallyPaid")}
            >
              <button
                style={{
                  backgroundColor: "#ffcccc",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Partially Paid
              </button>
              <p style={{ backgroundColor: Filterby === 'PartiallyPaid' ? '#ffcccc' : "transparent" }}>{StatusCount.PartiallyPaidCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              
              onClick={() => setFilterby("FullyPaid")}
            >
              <button
                style={{
                  backgroundColor: "#4169E1", 
                  width: "100%",
                  padding: "20px",
                }}
              >
                Fully Paid
              </button>
              <p style={{ backgroundColor: Filterby === 'FullyPaid' ? '#4169E1' : "transparent" }}>{StatusCount.FullyPaidCount}</p>
            </div>
          </h2>
        </div>

       
      </div>
      <br></br>
{/* --------------------------------------------------------------------------- */}
      <div className="grid_1">
        <div className="submitted_grid_with">
            <p>{Filterby}</p>
        </div>
      <ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={Selectrowdata.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
          {showdown > 0 && Selectrowdata.length > 10 && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>

      {Selectrowdata.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}
    </div>
    </div>

      
   
  );
}

export default ClaimsDashboard;
