import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { addDays, format } from "date-fns";



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';





const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});


function InsuranceDashboard() {


  const [open, setOpen] = React.useState(false);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  

  const dispatchvalue = useDispatch()

  const navigate = useNavigate();


  const cu_date = format(new Date(), "yyyy-MM-dd");


  const [CheckMRNnumberlist,setCheckMRNnumberlist]=useState([])
  

  const [filteredRows, setFilteredRows] = useState([]);

  const [Selectrowdata,setSelectrowdata]=useState([])

  const [Filterby,setFilterby]=useState('DRAFT')


  const [page, setPage] = useState(0);

  const [ApproveDatastate,setApproveDatastate]=useState({
    MRNNumber:'',
    PatientName:'',
    ALNumber:'',
    RequestedAmount:'',
    ApprovedAmount:'',
    RecivedDocuments:null,
    ChangeStatus:'',
    ChangePaperStatustype:'',
    RejectReason:''
  })


  const [StatusCount,setStatusCount]=useState({
      DraftCount:'',
      SubmittedCount:'',
      ApprovedCount:'',
      RejectedCount:'',
      DischargeCount:''
  })

  const pageSize = 10;
  const showdown = Selectrowdata.length;
  const totalPages = Math.ceil(Selectrowdata.length / 10);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

 

useEffect(()=>{

    axios
    .get(
      `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Form_MRNNumber_Personal_Information`
    )
    .then((response) => {
      console.log('1111',response.data);
      setCheckMRNnumberlist(response.data)
    })
    .catch((error) => {
      console.log(error);
    });


},[])


const GetDashBorddata=()=>{
  axios
  .get(
    `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Form_DashBorddata`
  )
  .then((response) => {
    console.log('KKKK',response.data);
    const datass=response.data

    setFilteredRows([
      ...datass.map((row, ind) => ({
        id: ind + 1,
        ...row,
      })),
    ])
  })
  .catch((error) => {
    console.log(error);
  });
}

useEffect(()=>{
  GetDashBorddata()
},[])



const handlegotoGRN = (params) => {
  const index = params.row.MRN;
  const index1 = params.row.ContactNumber;
  const index2 = params.row.Papersstatus;
  const index3 = params.row.Papersstatustype;


  // console.log("index:", index1);
  dispatchvalue({
    type: "InsuranceUpdatedata",
    value:{MRN:index,ContactNumber:index1,Papersstatus:index2,Papersstatustype:index3,GetStatus:'PreAuth'},
  });
  navigate("/Home/InsuranceMainpage");
};


const handleMakeApproved = (params) => {

  let data = params.row

  console.log('cfvw',data)

  setOpen(true);


  if(data.Papersstatustype === "DISCHARGE" || data.Papersstatustype === "ENHANCED" ){


    let MStatus=data.Papersstatustype === "DISCHARGE"? "DISCHARGED" : data.Papersstatustype

    setApproveDatastate((prev)=>({
      ...prev,
      MRNNumber:data.MRN,
      ALNumber:data.ALNumber,
      PatientName:data.PatientName,
      RequestedAmount:data.RequestedAmount,
      ChangeStatus:'APPROVED',
      ChangePaperStatustype:MStatus,
    }))

  }else{

    setApproveDatastate((prev)=>({
      ...prev,
      MRNNumber:data.MRN,
      PatientName:data.PatientName,
      RequestedAmount:data.RequestedAmount,
      ChangeStatus:'APPROVED',
      ChangePaperStatustype:data.Papersstatustype,
    }))
  }
  
};

const handleMakeReject =(params)=>{

  let data = params.row

  setOpen(true);

  // console.log('data',data)


  if(data.Papersstatustype === "DISCHARGE" || data.Papersstatustype === "ENHANCED" ){

    setApproveDatastate((prev)=>({
      ...prev,
      MRNNumber:data.MRN,
      ALNumber:data.ALNumber,
      PatientName:data.PatientName,
      RequestedAmount:data.RequestedAmount,
      ChangeStatus:'REJECTED',
      ChangePaperStatustype:data.Papersstatustype,
    }))

  }
  else{
  setApproveDatastate((prev)=>({
    ...prev,
    MRNNumber:data.MRN,
    PatientName:data.PatientName,
    RequestedAmount:data.RequestedAmount,
    ChangeStatus:'REJECTED',
    ChangePaperStatustype:data.Papersstatustype
  }))
}

}

const handleFileChange =(event)=>{

  const {name,value}=event.target

  if(name === 'RecivedDocuments'){
    setApproveDatastate((prev)=>({
      ...prev,
      [name]:event.target.files[0]
    }))
  }
  else{
    setApproveDatastate((prev)=>({
      ...prev,
      [name]:value
    }))
  }
}

const ClearApprovedata=()=>{

  setApproveDatastate({
    MRNNumber:'',
    PatientName:'',
    ALNumber:'',
    RequestedAmount:'',
    ApprovedAmount:'',
    RecivedDocuments:null,
    ChangeStatus:'',
    ChangePaperStatustype:'',
    RejectReason:''
  })

}


const handleClose = () => {
  setOpen(false);
  ClearApprovedata()
};

const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const formJson = Object.fromEntries(formData.entries());
  console.log(formJson);
  handleClose();
};





const handleApproved = () => {
  const formData = new FormData();
  if(ApproveDatastate.RecivedDocuments === null){

    alert('Upload Receive Document')
  
   }else{
  if (ApproveDatastate.ChangePaperStatustype === 'DISCHARGED') {
    formData.append('ChangePaperStatustype', ApproveDatastate.ChangePaperStatustype);
  }

  formData.append('MRNNumber', ApproveDatastate.MRNNumber);
  formData.append('PatientName', ApproveDatastate.PatientName);
  formData.append('ALNumber', ApproveDatastate.ALNumber);
  formData.append('ApprovedAmount', ApproveDatastate.ApprovedAmount);
  formData.append('RecivedDocuments', ApproveDatastate.RecivedDocuments);
  formData.append('ChangeStatus', ApproveDatastate.ChangeStatus);
  formData.append('Location', userRecord.location);
  formData.append('created_by', userRecord.username);
  formData.append('cu_date', cu_date);



  axios.post(`https://vesoftometic.co.in/Insurance/Update_Approved_Papersstatus_Insurance`, formData)
    .then((response) => {
      console.log('Form data submitted.', response.data);
      GetDashBorddata()
      ClearApprovedata()

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

const handleRejected =()=>{

  let params ={
    MRNNumber:ApproveDatastate.MRNNumber,
    PatientName:ApproveDatastate.PatientName,
    ALNumber:ApproveDatastate.ALNumber,
    ChangeStatus:ApproveDatastate.ChangeStatus,
    RejectReason:ApproveDatastate.RejectReason,
    Location:userRecord.location,
    created_by:userRecord.username,
    cu_date:cu_date
    
  }

  axios.post(`https://vesoftometic.co.in/Insurance/Update_Reject_Papersstatus_Insurance`,params)
    .then((response) => {
      console.log('Form data submitted.', response.data);
      GetDashBorddata()
      ClearApprovedata()

    })
    .catch((error) => {
      console.error(error);
    });

}


const handleDischarge =()=>{


 if(ApproveDatastate.RecivedDocuments === null){

  alert('Upload Receive Document')

 }else{
  const formData = new FormData();

  formData.append('MRNNumber', ApproveDatastate.MRNNumber);
  formData.append('PatientName', ApproveDatastate.PatientName);
  formData.append('ALNumber', ApproveDatastate.ALNumber);
  formData.append('ApprovedAmount', ApproveDatastate.ApprovedAmount);
  formData.append('RecivedDocuments', ApproveDatastate.RecivedDocuments);
  formData.append('ChangeStatus', ApproveDatastate.ChangeStatus);
  formData.append('Location', userRecord.location);
  formData.append('created_by', userRecord.username);
  formData.append('cu_date', cu_date);



  axios.post(`https://vesoftometic.co.in/Insurance/Update_Approved_Papersstatus_Insurance`, formData)
    .then((response) => {
      console.log('Form data submitted.', response.data);
      GetDashBorddata()
      ClearApprovedata()

    })
    .catch((error) => {
      console.error(error);
    });
 }


}


const columns = [
  { field: "PatientName", headerName: "Patient Name", width: 150 },
  { field: "MRN", headerName: "MRN", width: 120 },
  { field: "ALNumber", headerName: "AL Number", width: 130 },
  { field: "PayerTPAName", headerName: "Payer/TPA", width: 180 },
  {
    field: "PayerTPAMemberID",
    headerName: "Payer/TPA Member ID",
    width: 180,
  },
  { field: "Papersstatustype", headerName: "Request Type", width: 150 },
  { field: "CreatedAt", headerName: "Created Date", width: 150 },

  {
    field: "Edit",
    headerName: "Action",
    width: 180,
    renderCell: (params) => (
      <>
      <Button className="cell_btn" onClick={() => handlegotoGRN(params)}>
        <EditIcon />
      </Button>

      {params.row.Papersstatus === "SUBMITTED" ? <Button className="cell_btn" onClick={() => handleMakeApproved(params)}>
        <CheckCircleOutlineIcon  />
      </Button> :''}

      {params.row.Papersstatus === "SUBMITTED" ? <Button className="cell_btn"  onClick={() => handleMakeReject(params)}>
        <HighlightOffIcon/>
      </Button> :''}

      </>
    ),
  },
];


useEffect(()=>{
  if(filteredRows.length !== 0 && Filterby!=='' ){
   if(Filterby === 'DISCHARGED'){

    const Selectdata=filteredRows.filter((ele)=>ele.Papersstatustype === Filterby)

    setSelectrowdata(Selectdata)

   }else{
    const Selectdata=filteredRows.filter((ele)=>ele.Papersstatus === Filterby && ele.Papersstatustype !== "DISCHARGED")

    setSelectrowdata(Selectdata)
   }
  }

},[filteredRows,Filterby])


useEffect(()=>{
  if(filteredRows.length !== 0 ){
   


    let Selectdata1=filteredRows.filter((ele)=>ele.Papersstatus === 'DRAFT')
    let Selectdata2=filteredRows.filter((ele)=>ele.Papersstatus === 'SUBMITTED')
    let Selectdata3=filteredRows.filter((ele)=>ele.Papersstatus === 'APPROVED' && ele.Papersstatustype !== "DISCHARGED")
    let Selectdata4=filteredRows.filter((ele)=>ele.Papersstatus === 'REJECTED')
    let Selectdata5=filteredRows.filter((ele)=>ele.Papersstatustype === "DISCHARGED")

    setStatusCount((prev)=>({
      ...prev,
      DraftCount:Selectdata1.length || 0,
      SubmittedCount:Selectdata2.length || 0,
      ApprovedCount:Selectdata3.length || 0,
      RejectedCount:Selectdata4.length || 0,
      DischargeCount:Selectdata5.length || 0
    }))
  
  }else{

    setStatusCount((prev)=>({
      ...prev,
      DraftCount: 0,
      SubmittedCount: 0,
      ApprovedCount: 0,
      RejectedCount: 0,
      DischargeCount: 0
    }))

  }

},[filteredRows])

 

  // const handleGotoPreAuth = () => {

  //   navigate('/Home/InsuranceMainpage');

  // }


const handleGotoPreAuth = () => {
  const newApprovedQuantity = prompt('Please Enter Admission Number To Proceed:');

  if (newApprovedQuantity !== null && newApprovedQuantity !== '') {

    const Numberchck = CheckMRNnumberlist.some(ele => ele.MRN.toLowerCase() === newApprovedQuantity.toLowerCase());
      // console.log('Numberchck:',Numberchck)

      if(Numberchck){
        alert('This Number Already Entered')
      }else{
        axios.get(`https://vesoftometic.co.in/Insurance/get_Insurance_registration_data`)
        .then((response) => {
            const data = response.data;
            const Findetruedata = data.find((ele) => ele.Booking_Id.toLowerCase() === newApprovedQuantity.toLowerCase());

            console.log('Findetruedata',Findetruedata)

            if (Findetruedata !== undefined && Object.keys(Findetruedata).length !== 0) {
                const Findid = Findetruedata.Booking_Id;

                axios.get(`https://vesoftometic.co.in/Insurance/get_All_registration_data/${Findid}`)
                    .then((response) => {
                        const data = response.data;
                        if (Object.keys(data).length !== 0) {
                            navigate('/Home/InsuranceMainpage');
                            dispatchvalue({ type: 'IPPDetailes', value: data });
                            dispatchvalue({ type:'InsurancePageChange',value: ""});
                            dispatchvalue({ type:'InsuranceMaindetailes',value:{}});
                            dispatchvalue({ type:'InsuranceUpdatedata',value:{}});



                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }else {
                alert('Please Enter Valid Admission Number');
            }
        })
        .catch((error) => {
            console.log(error);
        });
      }
     
  }else{
      alert('Please Enter Valid Admission Number')  }
};







  return (
    <>
    <div className="Insurance_Dashboard_head">
      <div className="Insurance_dash_hending">
        <h3>Insurance Dashboard</h3>
      </div>
      <br></br>

      <div className="serch_dash_insur">
        <input
          type="text"
          placeholder="Search by: Patient Name, MRN, CL Number, Payer/TPA Member ID"
        ></input>
        <p>
          <SearchIcon />
        </p>
        <p onClick={handleGotoPreAuth}>
          <button className="add_new_auth_wuith"><AddIcon /> New Auth</button>
        </p>
      </div>
      <br></br>

      <div className="new-patient-registration-form1">
        <div className="Insurancenew-navigation">
          <h2>
            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              onClick={() => setFilterby("DRAFT")}
            >
              <button
                style={{
                  backgroundColor: "#ffd966",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Draft
              </button>
              <p style={{ backgroundColor: Filterby === 'DRAFT' ? '#ffd966' :"transparent" }}>{StatusCount.DraftCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
             
              onClick={() => setFilterby("SUBMITTED")}

            >
              <button
                style={{
                  backgroundColor: "#b3e0ff",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Submitted
              </button>
              <p style={{ backgroundColor: Filterby === 'SUBMITTED' ? '#b3e0ff' :"transparent" }}>{StatusCount.SubmittedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              onClick={() => setFilterby("APPROVED")}
            >
              <button
                style={{
                  backgroundColor: "#b3ffb3",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Approved
              </button>
              <p style={{ backgroundColor: Filterby === 'APPROVED' ? '#b3ffb3' :"transparent" }}>{StatusCount.ApprovedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              
              onClick={() => setFilterby("REJECTED")}
            >
              <button
                style={{
                  backgroundColor: "#ffcc99",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Rejected
              </button>
              <p style={{ backgroundColor: Filterby === 'REJECTED' ? '#ffcc99' : "transparent" }}>{StatusCount.RejectedCount}</p>
            </div>

            <div
              className="insur_box_chnge6"
              style={{
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
              }}
              
              onClick={() => setFilterby("DISCHARGED")}
            >
              <button
                style={{
                  backgroundColor: "#ffcccc",
                  width: "100%",
                  padding: "20px",
                }}
              >
                Discharge Approved
              </button>
              <p style={{ backgroundColor: Filterby === 'DISCHARGED' ? '#ffcccc' : "transparent" }}>{StatusCount.DischargeCount}</p>
            </div>
          </h2>
        </div>

       
      </div>
      <br></br>
{/* --------------------------------------------------------------------------- */}
      <div className="grid_1">
        <div className="submitted_grid_with">
            <p>{Filterby}</p>
        </div>
      <ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={Selectrowdata.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
          {showdown > 0 && Selectrowdata.length > 10 && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>

      {Selectrowdata.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}
    </div>
    </div>

    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Enter Approved Datas</DialogTitle>

        <div className="Supplier_Master_Container">
        <div className="Total_input_container">
        
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            MRN Number <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="MRNNumber"
            required
            readOnly
            value={ApproveDatastate.MRNNumber}
          />
        </div>

        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Patient Name <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="PatientName"
            required
            readOnly
            value={ApproveDatastate.PatientName}
          />
        </div>

         <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            AL Number <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="ALNumber"
            required
            value={ApproveDatastate.ALNumber}
            onChange={(e) => handleFileChange(e)}
            readOnly={ApproveDatastate.ChangePaperStatustype === "DISCHARGED" || ApproveDatastate.ChangePaperStatustype === "ENHANCED" }
          />
        </div>

        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Requested Amount <span>:</span>{" "}
          </label>
          <input
            type="number"
            name="RequestedAmount"
            required
            value={ApproveDatastate.RequestedAmount}
            readOnly
          />
        </div>

        {ApproveDatastate.ChangeStatus ==='APPROVED' || ApproveDatastate.ChangeStatus ==='DISCHARGE'? 
        <>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Approved Amount <span>:</span>{" "}
          </label>
          <input
            type="number"
            name="ApprovedAmount"
            required
            value={ApproveDatastate.ApprovedAmount}
            onChange={(e) => handleFileChange(e)}
          />
        </div>

        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
        <label>
            {" "}
            Received Documents <span>:</span>{" "}
          </label>
          <input
            type="file"
            id="RecivedDocuments"
            name="RecivedDocuments"
            accept="image/pdf"
            required
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e)}
          />
          <label htmlFor="RecivedDocuments" className="RegisterForm_1_btns choose_file_update">
            Choose File
          </label>
        </div> 
        </>
        : <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Reason <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="RejectReason"
            required
            value={ApproveDatastate.RejectReason}
            onChange={(e) => handleFileChange(e)}
          />
        </div>}


        </div>
        </div>




        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {ApproveDatastate.ChangeStatus ==='DISCHARGE'?  <Button type="submit" onClick={handleDischarge}>Discharge</Button>  :   ApproveDatastate.ChangeStatus ==='APPROVED'? <Button type="submit" onClick={handleApproved}>Approved</Button>:
          <Button type="submit" onClick={handleRejected}>Rejected</Button>}
        </DialogActions>
      </Dialog>
    </React.Fragment>
    </>
  );
}

export default InsuranceDashboard;
