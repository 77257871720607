import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";




export default function InsurancepatientInfo() {

  
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IPPatientDetailes = useSelector(
    (state) => state.InsuranceStore?.IPPatientDetailes
  );

  const InsuranceMaindetailes = useSelector(
    (state) => state.InsuranceStore?.InsuranceMaindetailes
  );

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );

  
  // console.log("natha", IPPatientDetailes);


  const [persionalformData, setpersionalformData] = useState({
    MRN: "",
    PatientName: "",
    Gender: "",
    DOB: "",
    Age: "",
    ContactNumber: "",
    Address: "",
    PinCode: "",
    
  });

  const [InsuranceCompanyformdata,setInsuranceCompanyformdata]=useState({

    InsuranceCompany: "",
    PolicyNumber: "",
    PolicyType: "",
    PayerTPAName: "",
    PayerTPAZone: "",
    PayerTPAMemberID: "",

  })

  


  const [esicCoverage, setEsicCoverage] = useState("No");

  const[ESINumberstate,setESINumberstate]=useState('')

  const [employed, setEmployed] = useState("No");

  const [Corporatedetails,setCorporatedetails]=useState({
    CorporateName: "",
    EmployeeID:"",
  })

  const [MedicalLegalCase, setMedicalLegalCase] = useState("No");

  const [MLCdetails,setMLCdetails]=useState({
    InformedBY:'',
    OfficerName:'',
    Policestation:'',
    FIRNo:'',
    CodeStatus:'',
    TypeOfAdmit:'',
    Admitting_diagnosis:''
  })

  // ------------------------------------------------------------------

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (name === "ContactNumber") {
      const newval = value.length;
      if (newval <= 10) {
        setpersionalformData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Contact Number must contain 10 digits");
      }
    } else if (name === "DOB") {
      const newDate = new Date();
      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setpersionalformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else {
      setpersionalformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  

  const handleCheckboxChange = (event) => {
    if(event.target.value ==='No'){

      setEsicCoverage(event.target.value);

      setESINumberstate('')

    }
    else{
      setEsicCoverage(event.target.value);
    }
  };


  const InsuranceChangefun=(e)=>{

    const { name, value } = e.target;

    setInsuranceCompanyformdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }


  const handleCheckboxChange2 = (event) => {
    if(event.target.value ==='No'){
    setEmployed(event.target.value)
    
    setCorporatedetails((prev)=>({
      ...prev,
      CorporateName:'',
      EmployeeID:''
    }))

    }else{
    setEmployed(event.target.value)
    }
  };


  
  const CorporateChangefun=(e)=>{

    const { name, value } = e.target;

    setCorporatedetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }


  const handleCheckboxChangeLegalCase = (event) => {
    if(event.target.value === 'No'){
     setMedicalLegalCase(event.target.value);

    setMLCdetails((prev)=>({
      ...prev,
      InformedBY:'',
      OfficerName:'',
      Policestation:'',
      FIRNo:'',
      CodeStatus:'',
      TypeOfAdmit:'',
      Admitting_diagnosis:''
    }))

    }else{
      setMedicalLegalCase(event.target.value);
    }
   };


   const MLCChangefun=(e)=>{

    const { name, value } = e.target;

    setMLCdetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  }


  
  useEffect(()=>{

    if(Object.keys(IPPatientDetailes).length !== 0){

    console.log('IPPatientDetailes',IPPatientDetailes)

    setpersionalformData((prev)=>({
      ...prev,
      MRN: IPPatientDetailes.Booking_Id,
      PatientName: IPPatientDetailes.PatientName,
      Gender: IPPatientDetailes.Gender,
      DOB: IPPatientDetailes.DOB.trim(),
      Age: IPPatientDetailes.Age,
      ContactNumber:IPPatientDetailes.PatientPhoneNo,
      Address:IPPatientDetailes.street+','+IPPatientDetailes.city+','+IPPatientDetailes.state,
      PinCode: IPPatientDetailes.Pincode,

    }))

   

    if(IPPatientDetailes.AdmissionPurpose === "MedicalLegalCase"){
      setMedicalLegalCase('Yes')
      setMLCdetails((prev)=>({
        ...prev,
        InformedBY: IPPatientDetailes.InformedBY,
        OfficerName:IPPatientDetailes.OfficerName,
        Policestation:IPPatientDetailes.Policestation,
        FIRNo:IPPatientDetailes.FIRNo,
        CodeStatus: IPPatientDetailes.CodeStatus,
        TypeOfAdmit: IPPatientDetailes.Typeofadmit,
        Admitting_diagnosis: IPPatientDetailes.Admitting_diagnosis,
      }))
    

    }
    if(IPPatientDetailes.RatecardType === 'Insurance' && IPPatientDetailes.PatientCategory === 'Insurance' ){

      setInsuranceCompanyformdata((prev)=>({
        ...prev,
        InsuranceCompany:  IPPatientDetailes.InsuranceName,
        PolicyNumber:  IPPatientDetailes.Cardno,
        PolicyType:  IPPatientDetailes.Scheme,
        PayerTPAName: IPPatientDetailes.TPA,
      }))

      }else if(IPPatientDetailes.RatecardType === 'ESI' && IPPatientDetailes.PatientCategory === 'ESI'){
        setEsicCoverage('Yes')
        setEmployed('Yes')
        setESINumberstate(123456789)
        setCorporatedetails((prev)=>({
          ...prev,
          CorporateName: 'KTV FOOD',
          EmployeeID:'EMP1212'
        }))
      }


    }

  },[IPPatientDetailes])



  const SavebtnFun =()=>{

    console.log('22525',InsuranceMaindetailes)


    const params={      
      Location:userRecord.location,
      createAt:userRecord.username,
      persionalformData:persionalformData,
      InsuranceCompanyformdata:InsuranceCompanyformdata,
      employed:employed,
      Corporatedetails:Corporatedetails,
      
      MedicalLegalCase:MedicalLegalCase,
      MLCdetails:MLCdetails,
   
      esicCoverage:esicCoverage,
      ESINumberstate:ESINumberstate,

      InsuranceMaindetailes:InsuranceMaindetailes,

      MainPageCompleted:"MainPage1",
      PageCompleted:"MedicalHistory"
    }


    console.log('params',params)


    axios.post(`https://vesoftometic.co.in/Insurance/Post_Pre_Auth_Form_Personal_Information`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        dispatchvalue({type: "InsuranceUpdatedata",value:{MRN:persionalformData.MRN,ContactNumber:persionalformData.ContactNumber}});
        dispatchvalue({type: "InsurancePageChange",value:"MedicalHistory"});

    })
    .catch((error) => {
        console.error(error);
    });
  
  }



  useEffect(()=>{
    if(Object.values(InsuranceUpdatedata).length !== 0){
        // console.log('Vathuruchu',InsuranceUpdatedata)            
     axios.get(
    `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Form_Personal_Information`,{
        params:InsuranceUpdatedata.MRN
    }
    )
    .then((response) => {
    // console.log('vrrrr',response.data);
    
    const data=response.data[0]

    console.log('ssssuuuuccc',data)

      if(Object.keys(data).length !==0){
        
        setpersionalformData((prev)=>({
          ...prev,
          MRN:data.MRN,
          PatientName:data.PatientName,
          Gender:data.Gender,
          DOB:data.DOB,
          Age:data.Age,
          ContactNumber:data.ContactNumber,
          Address:data.Address,
          PinCode:data.PinCode,
        }))
  
        setEsicCoverage(data.esicCoverage)
  
        setESINumberstate(data.ESINumberstate)
  
        setInsuranceCompanyformdata((prev)=>({
          ...prev,
          InsuranceCompany: data.InsuranceCompany,
          PolicyNumber: data.PolicyNumber,
          PolicyType: data.PolicyType,
          PayerTPAName: data.PayerTPAName,
          PayerTPAZone: data.PayerTPAZone,
          PayerTPAMemberID: data.PayerTPAMemberID,
        }))
  
        setEmployed(data.employed)
        setCorporatedetails((prev)=>({
          ...prev,
          CorporateName: data.CorporateName,
          EmployeeID: data.EmployeeID,
        }))
  
        setMedicalLegalCase(data.MedicalLegalCase)
  
        setMLCdetails((prev)=>({
          ...prev,
          InformedBY: data.InformedBY,
          OfficerName: data.OfficerName,
          Policestation: data.Policestation,
          FIRNo: data.FIRNo,
          CodeStatus: data.CodeStatus,
          TypeOfAdmit: data.TypeOfAdmit,
          Admitting_diagnosis: data.Admitting_diagnosis,
        }))
      }

    })
    .catch((error) => {
    console.log(error);
    });

    }
},[InsuranceUpdatedata])




  return (
    <>
     <div className="Supplier_Master_Container">      
      
          <div className="Total_input_container">
                  
            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                MRN <span>:</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter MRN"
                name="MRN"
                value={persionalformData.MRN}
                onChange={handleInputChange1}
                required
              />
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                Patient Name <span>:</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter Patient Name"
                name="PatientName"
                value={persionalformData.PatientName}
                onChange={handleInputChange1}
                required
              />
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label htmlFor="Title">
                Gender <span>:</span>
              </label>
              <select
                name="Gender"
                value={persionalformData.Gender}
                onChange={handleInputChange1}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="TransGender">TransGender</option>
              </select>
            </div>

            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                Date Of Birth <span>:</span>{" "}
              </label>
              <input
                type="date"
                placeholder="Enter Date Of Birth"
                name="DOB"
                value={persionalformData.DOB}
                onChange={handleInputChange1}
                required
              />
            </div>

          </div>

          <div className="Total_input_container">
            
            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                Age <span>:</span>{" "}
              </label>
              <input
                type="number"
                placeholder="Enter Age"
                name="Age"
                value={persionalformData.Age}
                onChange={handleInputChange1}
                required
              />
            </div>

            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                Contact Number <span>:</span>{" "}
              </label>
              <input
                type="number"
                placeholder="Enter Contact Number"
                name="ContactNumber"
                value={persionalformData.ContactNumber}
                onChange={handleInputChange1}
                required
              />
            </div>

            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                Address <span>:</span>
              </label>
              <textarea
                name="Address"
                className="txt-ara-lclprntm"
                placeholder="Enter Address"
                value={persionalformData.Address}
                onChange={handleInputChange1}
                required
              ></textarea>
            </div>

            <div className="RegisForm_1 column_RegisForm_1_forinsurance">
              <label>
                {" "}
                Pin Code <span>:</span>{" "}
              </label>
              <input
                type="number"
                placeholder="Enter Pin Code"
                name="PinCode"
                value={persionalformData.PinCode}
                onChange={handleInputChange1}
                required
              />
            </div>

          </div>

            <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
              <div className="RegisForm_1 column_RegisForm_1_forinsurance  Spl_backcolr_09_bottom">
                <label>
                  ESI Coverage <span>:</span>
                </label>

                <div className="ewj_i87_head">
                  <div className="ewj_i87">
                    <input
                      type="radio"
                      id="esicYes"
                      name="ESICoverage"
                      value="Yes"
                      checked={esicCoverage === "Yes"}
                      onChange={handleCheckboxChange}
                    ></input>

                    <label htmlFor="esicYes">Yes</label>
                  </div>

                  <div className="ewj_i87">
                    <input
                      type="radio"
                      id="esicNo"
                      name="ESICoverage"
                      value="No"
                      checked={esicCoverage === "No"}
                      onChange={handleCheckboxChange}
                    ></input>
                    <label htmlFor="esicNo">No</label>
                  </div>
                </div>
              </div>
            </div>
            {esicCoverage === "Yes" && (
              <>
              <div className="Total_input_container">
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      ESI Number <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter ESI Number"
                      name="ESINumber"
                      value={ESINumberstate}
                      onChange={(e)=>{setESINumberstate(e.target.value)}}
                      required
                    />
                  </div>
                  </div>
              </>
            )}
            {esicCoverage === "No" && (
              <>
                <div className="Total_input_container">
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Insurance Company <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Insurance Company"
                      name="InsuranceCompany"
                      value={InsuranceCompanyformdata.InsuranceCompany}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Policy Number<span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Policy Number"
                      name="PolicyNumber"
                      value={InsuranceCompanyformdata.PolicyNumber}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Policy Type <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Policy Type "
                      name="PolicyType"
                      value={InsuranceCompanyformdata.PolicyType}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>

                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Payer/TPA Name <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="PayerTPAName"
                      value={InsuranceCompanyformdata.PayerTPAName}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>

                </div>

                <div className="Total_input_container">
                  
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Payer/TPA Zone <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Zone"
                      name="PayerTPAZone"
                      value={InsuranceCompanyformdata.PayerTPAZone}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      {" "}
                      Payer/TPA Member ID <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Member ID"
                      name="PayerTPAMemberID"
                      value={InsuranceCompanyformdata.PayerTPAMemberID}
                      onChange={InsuranceChangefun}
                      required
                    />
                  </div>
                </div>
              </>
            )}

              <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                  <label>
                    Employed ? <span>:</span>
                  </label>

                  <div className="ewj_i87_head">
                    <div className="ewj_i87">
                      <input
                        type="radio"
                        id="employedYes"
                        name="Employed"
                        value="Yes"
                        checked={employed === "Yes"}
                        onChange={handleCheckboxChange2}
                      ></input>

                      <label htmlFor="employedYes">Yes</label>
                    </div>

                    <div className="ewj_i87">
                      <input
                        type="radio"
                        id="employedNo"
                        name="Employed"
                        value="No"
                        checked={employed === "No"}
                        onChange={handleCheckboxChange2}
                      ></input>
                      <label htmlFor="employedNo">No</label>
                    </div>
                  </div>
                </div>
              </div>

          
              {employed === "Yes" && (
                <div className="Total_input_container">
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      Corporate Name <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Corporate Name"
                      name="CorporateName"
                      value={Corporatedetails.CorporateName}
                      onChange={CorporateChangefun}
                      required
                    />
                  </div>
                  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                    <label>
                      Employee ID <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Employee ID"
                      name="EmployeeID"
                      value={Corporatedetails.EmployeeID}
                      onChange={CorporateChangefun}
                      required
                    />
                  </div>
                </div>
              )}

               <div className="RegisFormcon column_regisFormcon_forinsurance Spl_backcolr_09">
                    <div className="RegisForm_1 column_RegisForm_1_forinsurance Spl_backcolr_09_bottom">
                      <label>
                        Medico-Legal Case <span>:</span>
                      </label>

                      <div className="ewj_i87_head">
                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoYes"
                            name="MedicoLegalCase"
                            value="Yes"
                            checked={MedicalLegalCase === "Yes"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>

                          <label htmlFor="MedicoYes">Yes</label>
                        </div>

                        <div className="ewj_i87">
                          <input
                            type="radio"
                            id="MedicoNo"
                            name="MedicoLegalCase"
                            value="No"
                            checked={MedicalLegalCase === "No"}
                            onChange={handleCheckboxChangeLegalCase}
                          ></input>
                          <label htmlFor="MedicoNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  { MedicalLegalCase=== "Yes" && (
                    <>
                      <div className="Total_input_container">
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Informed By <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Informed By"
                            name="InformedBY"
                            value={MLCdetails.InformedBY}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Officer Name<span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Officer Name"
                            name="OfficerName"
                            value={MLCdetails.OfficerName}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Police Station <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Police Station"
                            name="Policestation"
                            value={MLCdetails.Policestation}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>

                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            FIR No <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter FIR No"
                            name="FIRNo"
                            value={MLCdetails.FIRNo}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>

                      </div>

                      <div className="Total_input_container">
                       
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Code Status <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Code Status"
                            name="CodeStatus"
                            value={MLCdetails.CodeStatus}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Type Of Admit <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Type Of Admit"
                            name="TypeOfAdmit"
                            value={MLCdetails.TypeOfAdmit}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
                          <label>
                            {" "}
                            Admitting Diagnosis <span>:</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Admitting Diagnosis"
                            name="Admitting_diagnosis"
                            value={MLCdetails.Admitting_diagnosis}
                            onChange={MLCChangefun}
                            required
                          />
                        </div>
                      </div>
                    </>
                  )}
       
      </div>


      <div className="submit_button_prev_next">
      <button onClick={SavebtnFun}>
          Save
      </button>
     </div>
    </>
  );
}
