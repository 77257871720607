import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMlcConsentForm() {

    const successMsg = (Message) => {
        toast.success(`${Message}`, {
            position: "top-center",
            autoClose: 100,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };
    const userwarn = (warningMessage) => {
        toast.warn(`${warningMessage}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };


    const userRecord = useSelector((state) => state.userRecord?.UserData);
    console.log(userRecord,'qqqqqqqqqqqqqqqqq')

    const IpNurseQueSelectedRow = useSelector(
        (state) => state.InPatients?.IpNurseQueSelectedRow
    );
    console.log("nathaaaa", IpNurseQueSelectedRow);

    const [pdfBlob, setPdfBlob] = useState(null);
    const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

    const [ConcernformData, setFormData] = useState({
        Date: "",
        InTime: "",
        OutTime: "",
        Name: "",
        Age: "",
        gender: "",
        phone: "",
        Address: "",
        RelativePerson: "",
        Word1: "",
        Word2: "",
        Word3: "",
        ProvisionalDiagnosis: "",
        MedicalAdvice: "",
        bookingid: IpNurseQueSelectedRow.Booking_Id,
        Location:userRecord.location,
        Patient_Name:IpNurseQueSelectedRow.PatientName,
    });
    console.log('ConcernformDataaaaaaaaaa', ConcernformData)
    const signatureRef = useRef(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...ConcernformData,
            [name]: value,
        });
    };

    const clearSignature = () => {
        signatureRef.current.clear();
    };

    const saveSignature = () => {
        console.log("Signature saved");
    };



     console.log(userRecord?.location,'kjkjk')


    // const handleSave = () => {

    //     const canvasData = signatureRef.current.toDataURL()

    //     // console.log('canvasData',canvasData)

    //     const data = {
    //         ...ConcernformData,
            
    //         canvasData: canvasData,
    //         Createby: userRecord?.username
    //     };
       

    //     // If IsCategoryEdit is true, it means we are updating an existing category
    //     axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MlcConcernForm`, data)
    //         .then((response) => {
    //             console.log(response);
    //             if (response.data.message) {
    //                 successMsg(response.data.message);
    //             } else {
    //                 userwarn(response.data.Exists);
    //             }

    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    // };



    const [isContentReady, setIsContentReady] = useState(false);

    useEffect(() => {
        // Check if the content is ready to be printed
        const printContent = document.getElementById("reactprintcontent");
        console.log(printContent, 'hhhhhhhhhhhh')
        setIsContentReady(!!printContent);
    }, []);

    // const handlePrintAndSave = () => {
    //   // Call the function to save data to the database
    //   handleSave();

    //   // Call the function to trigger printing
    //   // Call the function to trigger printing if content is ready
    //   if (isContentReady) {
    //     handlePrint();
    //   } else {
    //     console.error("Content is not ready for printing");
    //   }
    // };



    const handlePrint = useReactToPrint({
        content: () => document.getElementById("reactprintcontent"),
        onBeforePrint: () => {
            console.log("Before");
            if (!isContentReady) {
                // Content is not ready, prevent printing
                throw new Error("Content is not ready for printing");
            }
        },
        onAfterPrint: async () => {
            setPdfBlob(null);
            console.log("After");
            const printdata = document.getElementById("reactprintcontent");
            console.log("printdata", printdata);

            try {
                if (printdata) {
                    // Get the content height and width
                    const contentWidth = printdata.offsetWidth;
                    const contentHeight = printdata.offsetHeight;
                    const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
                    pdf.html(printdata, {
                        callback: () => {
                            const generatedPdfBlob = pdf.output("blob");
                            // saveOrUploadPdf(generatedPdfBlob);
                            setPdfBlob(generatedPdfBlob);
                            console.log("generatedPdfBlob", generatedPdfBlob);

                            // Construct the request body including previous state
                            const canvasData = signatureRef.current.toDataURL();
                            const formData = new FormData();
                            formData.append('bookingid', ConcernformData.bookingid);
                            formData.append('Location', ConcernformData.Location);
                            formData.append('Patient_Name', ConcernformData.Patient_Name);
                            formData.append('Date', ConcernformData.Date);
                            formData.append('InTime', ConcernformData.InTime);
                            formData.append('OutTime', ConcernformData.OutTime);
                            formData.append('Name', ConcernformData.Name);
                            formData.append('Age', ConcernformData.Age);
                            formData.append('gender', ConcernformData.gender);
                            formData.append('phone', ConcernformData.phone);
                            formData.append('Address', ConcernformData.Address);
                            formData.append('RelativePerson', ConcernformData.RelativePerson);
                            formData.append('Word1', ConcernformData.Word1);
                            formData.append('Word2', ConcernformData.Word2);
                            formData.append('Word3', ConcernformData.Word3);
                            formData.append('ProvisionalDiagnosis', ConcernformData.ProvisionalDiagnosis);
                            formData.append('MedicalAdvice', ConcernformData.MedicalAdvice);
                            formData.append('canvasData', canvasData);
                            formData.append('generatedPdfBlob', generatedPdfBlob);
                            formData.append('Createby', userRecord.username);

                            console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
                            // Send the request
                            axios
                                .post(
                                    `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MlcConcernForm`, formData,
                                    {
                                        headers: {
                                            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                                        },
                                    }
                                )
                                .then((response) => {
                                    console.log(response.data);
                                    setIsPrintButtonVisible(true);
                                    // handleSave();
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        },
                    });
                } else {
                    throw new Error("Unable to get the target element");
                }
            } catch (error) {
                console.error("Error generating PDF:", error);
            }
        },
    });

    // const saveOrUploadPdf = (pdfBlob) => {
    //   // Convert the PDF blob to a Base64 string
    //   const reader = new FileReader();
    //   reader.readAsDataURL(pdfBlob);
    //   reader.onloadend = () => {
    //     const base64Data = reader.result;

    //     // Save the Base64 string to localStorage
    //     localStorage.setItem('pdfData', base64Data);
    //     console.log('PDF saved to localStorage.');
    //   };
    // };


    // const handlePrintAndSave = () => {
    //   try {
    //     // Call the function to trigger printing
    //     handlePrint();

    //     // Call the function to save data to the database
    //     handleSave();
    //   } catch (error) {
    //     console.error("Error while printing:", error);
    //   }
    // };


    // const handlePrint = () => {
    //   window.print();
    // };


    const [clinicName, setClinicName] = useState("");
    const [clinicLogo, setClinicLogo] = useState(null);

    const location = userRecord?.location;




    useEffect(() => {

        axios
            .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    const data = response.data;
                    setClinicName(data.clinicName);
                    setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
                } else {
                    // Handle error if needed
                }
            })
            .catch((error) => console.error("Error fetching data: ", error));
    }, [userRecord]);

    return (
        <>
            <div className="appointment case_sheet_consent " id="reactprintcontent">
                <br />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
                        <h3>MLC Consent Form</h3>
                    </div>
                </div>

                <div className="RegisFormcon_consent_consent">

                    <div className="RegisForm_1_consent_consent">
                        <label htmlFor="Date">
                            Date<span>:</span>
                        </label>
                        <input
                            type="date"
                            id="Date"
                            name="Date"
                            value={ConcernformData.Date}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    <div className="RegisForm_1_consent_consent">
                        <label htmlFor="InTime">
                            In Time<span>:</span>
                        </label>
                        <input
                            type="time"
                            id="InTime"
                            name="InTime"
                            value={ConcernformData.InTime}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    <div className="RegisForm_1_consent_consent">
                        <label htmlFor="OutTime">
                            Out Time<span>:</span>
                        </label>
                        <input
                            type="time"
                            id="OutTime"
                            name="OutTime"
                            value={ConcernformData.OutTime}
                            onChange={handleChange}
                            required
                        />

                    </div>

                </div>





                <div className="RegisFormcon_consent_consent">
                    <div className="RegisForm_1_consent_consent">
                        <label htmlFor="Name">
                            Name <span>:</span>
                        </label>
                        <input
                            type="text"
                            id="Name"
                            name="Name"
                            value={ConcernformData.Name}
                            onChange={handleChange}
                            required
                        // style={{width: '100px'}}
                        />
                    </div>

                    <div className="RegisForm_1_consent_consent">
                        <label htmlFor="Age">
                            Age<span>:</span>
                        </label>
                        <input
                            type="text"
                            id="Age"
                            name="Age"
                            value={ConcernformData.Age}
                            onChange={handleChange}
                            required
                        // style={{width: '100px'}}

                        />
                    </div>
                    <div className="RegisForm_1_consent_consent">
            <label>
              Gender <span>:</span>
            </label>
            <div className="RegisForm_1_consent_consent_radiooo_head35r">
              <div className="RegisForm_1_consent_consent_radiooo female4d">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="male"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                />
                <label htmlFor="male"> Male </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo female4eed">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                />
                <label htmlFor="female"> Female </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo transgender98">
                <input
                  type="radio"
                  id="transgender"
                  name="gender"
                  value="TransGender"
                  className="consent_consent_radiooo_inputt"
                  onChange={handleChange}
                />
                <label htmlFor="transgender"> Transgender </label>
              </div>
            </div>
          </div>

                </div>

                <div className="RegisFormcon_consent_consent" style={{ textAlign: 'left' }}>

                    <div className="RegisForm_1_consent_consent">
                        <label>
                            Address <span>:</span>
                        </label>
                        <textarea
                            name="Address"
                            value={ConcernformData.Address}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="RegisForm_1_consent_consent">
                        <label>
                            Phone <span>:</span>
                        </label>
                        <input
                            type="number"
                            name="phone"
                            pattern="[0-9]*"
                            value={ConcernformData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>

                </div>

                <div className="RegisFormcon_consent_consent">
                    <div className="RegisForm_1_consent_consent" >
                        <label htmlFor="RelativePerson">
                            Responsible / Accompanying Person <span>:</span>
                        </label>
                        <input
                            type="text"
                            id="RelativePerson"
                            name="RelativePerson"
                            value={ConcernformData.RelativePerson}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>




                <p  className="wiiue876"
                    style={{
                        color: "var(--labelcolor)",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        textAlign: "start",
                     
                        padding: "10px",
                        borderRadius: "5px",
                        fontWeight: 'bold',
                        height: "10px",
                       
                    }}>Consent</p>



                <div className="RegisFormcon_consent_consent">
                    <div className="RegisForm_1_consent_consent_ppp " style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
                        <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
                            I <span>
                                <input
                                    type="text"
                                    name="Word1"
                                    value={ConcernformData.Word1}
                                    onChange={handleChange}
                                    required
                                />
                            </span> am willing for Procedure/Examination/Injection/IV fluids/Lab Investigations/Referrals if needed etc. as per need on myself/my
                            <span>
                                <input
                                    type="text"
                                    name="Word2"
                                    value={ConcernformData.Word2}
                                    onChange={handleChange}
                                    required
                                />
                            </span> Mr./Mrs./Miss
                            <span>
                                <input
                                    type="text"
                                    name="Word3"
                                    value={ConcernformData.Word3}
                                    onChange={handleChange}
                                    required
                                />
                            </span> at Chirayu Life Line Pvt.Ltd.Casualty Dept.
                            <br />
                            <br />
                            I have been explained problems, risk & benefits e.g allergic reaction etc. in my language & understood the same.
                        </p>
                    </div>
                </div>



                <br />


                <div className="sigCanvas2_head11 uwytywe6262309 sdsdsxds" >
                    <div className="" >
                        <div>
                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{
                                    width: 190,
                                    height: 100,
                                    className: "sigCanvas2",
                                }}
                            />
                        </div>
                        <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Patient Relative Sign</h5>

                        <div className="Register_btn_con">
                            <button className="RegisterForm_1_btns" onClick={clearSignature}>
                                Clear
                            </button>
                            <button className="RegisterForm_1_btns" onClick={saveSignature}>
                                Save
                            </button>
                        </div>

                    </div>

                    <div className="">
                        <div>
                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{
                                    width: 190,
                                    height: 100,
                                    className: "sigCanvas2",
                                }}
                            />
                        </div>

                        <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>S/N on duty Witness</h5>

                        <div className="Register_btn_con">
                            <button className="RegisterForm_1_btns" onClick={clearSignature}>
                                Clear
                            </button>
                            <button className="RegisterForm_1_btns" onClick={saveSignature}>
                                Save
                            </button>
                        </div>

                    </div>



                    <div className="">
                        <div>
                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{
                                    width: 190,
                                    height: 100,
                                    className: "sigCanvas2",
                                }}
                            />
                        </div>

                        <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Patient Sign</h5>

                        <div className="Register_btn_con">
                            <button className="RegisterForm_1_btns" onClick={clearSignature}>
                                Clear
                            </button>
                            <button className="RegisterForm_1_btns" onClick={saveSignature}>
                                Save
                            </button>
                        </div>

                    </div>
                </div>

             

                <div className="RegisFormcon_consent_consent">
                    <div className="RegisForm_1_consent_consent">
                        <label>
                            Provisional Diagnosis
                            <span>:</span>
                        </label>
                        <textarea
                            type="text"
                            name="ProvisionalDiagnosis"
                            value={ConcernformData.ProvisionalDiagnosis}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="RegisForm_1_consent_consent">
                        <label>
                            History & Medical Advice <span>:</span>
                        </label>
                        <textarea
                            type="text"
                            name="MedicalAdvice"
                            value={ConcernformData.MedicalAdvice}
                            onChange={handleChange}
                            required
                        />
                    </div>

                </div>


                <div className="Register_btn_con">
                    <button
                        className="RegisterForm_1_btns printgr5"
                        onClick={handlePrint}
                    >
                        Print
                    </button>
                </div>
                <br />
            </div>
        </>
    );
}

export default EmergencyMlcConsentForm;
