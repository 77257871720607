import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUser, FaLock, FaEyeSlash } from "react-icons/fa";
import { FaEye, FaLocationDot } from "react-icons/fa6";
import bgImg2 from "../assets/bgImg2.jpg";
import "../LoginPage/LoginPage.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector } from "react-redux";
import { urllinkdata } from "..";

const LoginPage = () => {
  const [ClinicDetails, setClinicDetails] = useState([]);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [locationoptions, setlocationOptions] = useState([]);
  const [patientLogin, setPatientLogin] = useState(false);
  const [type, setType] = useState("Intake");
  const [clearEmployeedata, setClearEmployeeData] = useState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const urllink=useSelector(state=>state.userRecord?.UrlLink)
  console.log('urllinkdata',urllinkdata)
  const handleUsernameChange = (event) => {
    const value = event.target.value.trim();
    setUsername(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value.trim();
    setPassword(value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlelocationchange = (event) => {
    const value = event.target.value.trim();
    setLocation(value);
  };


  useEffect(() => {
    if(urllink){
      axios
      .get(`${UrlLink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicDetails(firstClinic);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
    }
  }, [urllink]);

  useEffect(() => {
    if (!patientLogin) {
      fetchlocationOptions();
    }
  }, [username, patientLogin]);

  const fetchlocationOptions = () => {
    axios
      .get(
        `${UrlLink}usercontrol/getlocationdataforlogin?username=${username}`
      )
      .then((response) => {
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  };

  const handleLoginFormSubmit = (event) => {
    event.preventDefault();
    if (!username.trim() || !password.trim() || (!patientLogin && !location.trim() && !location=== "")) {
      userwarn("Please fill in all fields.");
      return; // Exit early if any field is empty
    }
    axios
      .post(`${UrlLink}loginpage/login` , {
        username,
        password,
        location: patientLogin ? null : location,
      })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          successMsg("Login Successful");
          localStorage.setItem('token', data.token)
          const userRecord = localStorage.getItem('token')
          const decodedToken = (token) => {
            const payloadBase64 = token.split(".")[1];
            const decodedPayload = atob(payloadBase64);
            return JSON.parse(decodedPayload);
          };
          const decodedTokenData = decodedToken(userRecord);
          if (decodedTokenData.Patient_id){
            setTimeout(() => {
              navigate("/Home/PatientView");
            }, 800);
          }else{
            setTimeout(() => {
              navigate("/Home/ClinicMetrics");
            }, 800);
          }
          
        } else {
          userwarn("Incorrect Password or Username.");
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        userwarn("Incorrect Password or Username.");
   });
 };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

 

  // const handlePatientLoginClick = () => {

  // };

  const handleChange = (event) => {
    setType(event.target.value);
    setPatientLogin(!patientLogin);
    // Clear employee data when switching to patient mode
    if (event.target.value === 'Output') {
      setClearEmployeeData(true);
      // Clear username and password fields
      setUsername('');
      setPassword('');
    } else {
      setClearEmployeeData(false);
    }
  };



  return (
    <>
      <section className="register-container">
        <div className="register">
          <div className="col-1">
            <img src={bgImg2} alt="" />
          </div>
          <div className="col-2">
            <form className="flex-col" onSubmit={handleLoginFormSubmit}>
              <div className="login-logo">
                {ClinicDetails.clinicLogo && (
                  <img
                    src={`data:image/*;base64,${ClinicDetails.clinicLogo}`}
                    alt={ClinicDetails.clinicName}
                    className="rounded-logo-img"
                  />
                )}
              </div>

              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  value="Intake"
                  style={{
                    height: '30px !important',
                    width: '130px !important',
                    backgroundColor: type === 'Intake' ? "var(--ProjectColor)" : 'inherit',
                  }}
                  className='togglebutton_container_login'
                >
                  Employee
                </ToggleButton>
                <ToggleButton
                  value="Output"
                  style={{
                    backgroundColor: type === 'Output' ? "var(--ProjectColor)" : 'inherit',
                    height: '30px !important',
                    width: '130px !important',
                  }}
                  className='togglebutton_container_login'
                >
                  Patient
                </ToggleButton>
              </ToggleButtonGroup>

              <div className="input-group">
                <FaUser className="input-icon" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                  required
                  autoFocus
                  value={username}
                />
              </div>

              
              <div className="input-group">
                <FaLock className="input-icon" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  onChange={handlePasswordChange}
                  className="passd321"
                  placeholder="Enter your Password"
                  required
                />
                <span className="toggle-password" onClick={handleTogglePassword}>
                  {showPassword ? <FaEye /> : <FaEyeSlash /> }
                </span>
              </div>



              {patientLogin ? null : (
                <div className="input-group">
                  <FaLocationDot className="input-icon loctn-big" />
                  <select
                    id="location"
                    name="location"
                    onChange={handlelocationchange}
                    required
                  >
                    <option value="">Select</option>
                    {locationoptions.map((loc) => (
                      <option key={loc.location_id} value={loc.location_name}>
                        {loc.location_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}



              <button type="submit" className="login-btn">
                Login
              </button>

              {/* <button className='clr-clck-her4'onClick={handlePatientLoginClick}>
                <h5 className="flx_dued3">
                 <span>Click Here!</span>  If you are{" "}
                 <h4> {patientLogin ? "an Employee" : "a Patient"}</h4>
                </h5>
              </button> */}

            </form>

            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;