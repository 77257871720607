import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMtpConsent() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [checkboxValues, setCheckboxValues] = useState({
    PatientGender: '',
    RelativeGender: '',
  })


  const [ConcernformData, setFormData] = useState({
    
    PatientName: "",
    PatientGender: "",
    PatientAge: "",
    // PatientYrs: "",
    RegNo: "",
    Diagnosis: "",
    NameOfSurgery: "",
    RelativeName: "",
    RelativeGender: "",
    RelativeAge: "",
    // RelativeYrs: "",
    RelativeAddress: "",
    RelationshipWithPatient: "",
    FreeText1: "",

    onMyOwnCheckbox: "No",
    ConsentMySelfCheckbox: "No",

    DoctorName1: "",
    FreeText2: "",
    DoctorName2: "",
    DoctorName3: "",

    AboveTextCheckbox:"No",
    ReadbyMeCheckbox:"No",

    Witness1Sign: "",
    Witness1Name: "",
    Witness1Address: "",
    Witness1Age: "",
    // Witness1Yrs: "",
    Witness1Date: "",

    // Witness2Sign: "",
    // Witness2Name: "",
    // Witness2Address: "",
    // Witness2Age: "",
    // Witness2Yrs: "",
    // Witness2Date: "",

    RelativeSignatureLhti1: "",
    RelativeDate: "",
    RelativeTime: "",

    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);




  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
  
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked ? value : ''
      }));
    } else {
      setFormData({
        ...ConcernformData,
        [name]: value
      });
    }
  };

  


  // const handleChange = (e) => {
  //   const { name,type,checked, value } = e.target;
  //   setFormData({
  //     ...ConcernformData,
  //     [name]: value,
  //   });

  //   if(type === 'checkbox' ) 
  //     {
  //       setFormData((prev) => ({
  //         ...prev,
  //         [name]:checked? 'Yes': ''
  //       }))
  //     }

  //     setCheckboxValues(prevState => ({
  //       ...prevState,
  //       [name]: value
  //     }));


  //     // if (type === 'checkbox') {
  //     //   setFormData((prev) => ({
  //     //     ...prev,
  //     //     [name]: checked ? value : ''
  //     //   }));
  //     // } else {
  //     //   setFormData({
  //     //     ...ConcernformData,
  //     //     [name]: value
  //     //   });
  //     // }
      


  // };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('PatientName', ConcernformData.PatientName);
              formData.append('PatientGender', ConcernformData.PatientGender);
              formData.append('PatientAge', ConcernformData.PatientAge);
              formData.append('RegNo', ConcernformData.RegNo);
              formData.append('Diagnosis', ConcernformData.Diagnosis);
              formData.append('NameOfSurgery', ConcernformData.NameOfSurgery);
              formData.append('RelativeName', ConcernformData.RelativeName);
              formData.append('RelativeGender', ConcernformData.RelativeGender);
              formData.append('RelativeAge', ConcernformData.RelativeAge);
              formData.append('RelativeAddress', ConcernformData.RelativeAddress);
              formData.append('RelationshipWithPatient', ConcernformData.RelationshipWithPatient);
              formData.append('FreeText1', ConcernformData.FreeText1);
              formData.append('onMyOwnCheckbox', ConcernformData.onMyOwnCheckbox);
              formData.append('ConsentMySelfCheckbox', ConcernformData.ConsentMySelfCheckbox);
              formData.append('DoctorName1', ConcernformData.DoctorName1);
              formData.append('FreeText2', ConcernformData.FreeText2);
              formData.append('DoctorName2', ConcernformData.DoctorName2);
              formData.append('DoctorName3', ConcernformData.DoctorName3);
              formData.append('AboveTextCheckbox', ConcernformData.AboveTextCheckbox);
              formData.append('ReadbyMeCheckbox', ConcernformData.ReadbyMeCheckbox);
              formData.append('Witness1Sign', ConcernformData.Witness1Sign);
              formData.append('Witness1Name', ConcernformData.Witness1Name);
              formData.append('Witness1Address', ConcernformData.Witness1Address);
              formData.append('Witness1Age', ConcernformData.Witness1Age);
              formData.append('Witness1Date', ConcernformData.Witness1Date);
            //   formData.append('Witness2Sign', ConcernformData.Witness2Sign);
            //   formData.append('Witness2Name', ConcernformData.Witness2Name);
            //   formData.append('Witness2Address', ConcernformData.Witness2Address);
            //   formData.append('Witness2Age', ConcernformData.Witness2Age);
            //   formData.append('Witness2Date', ConcernformData.Witness2Date);
              formData.append('RelativeSignatureLhti1', ConcernformData.RelativeSignatureLhti1);
              formData.append('RelativeDate', ConcernformData.RelativeDate);
              formData.append('RelativeTime', ConcernformData.RelativeTime);
              // formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MtpConsent`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >
         
         

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>चिरायु</h1><h4 style={{marginLeft: '10px'}}> हॉस्पिटल</h4><h5 style={{marginLeft: '10px'}}> साळवी स्टॉप,रत्नागिरी</h5>
          </div>
          <hr/>
         

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>MTP CONSENT FORM</h3>
          </div>

         
          
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

           
           

        </div>
        
      


   

       <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                   <h3>पेशंटची माहिती</h3>
                </div>
                  <div className="shdywy656230">
                    <label> नांव  <span>:</span></label>
                    <input type="text"
                    name="PatientName"
                    value={ConcernformData.PatientName}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  {/* <div className="shdywy656230">
                    <label> लिंग <span>:</span></label>
                    <input type="text"
                    name="PatientGender"
                    value={ConcernformData.PatientGender}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}

                 <div className="  ">
                    <label> लिंग <span>:</span></label>
                    <span>
                    <input 
                      type="checkbox"
                      name="PatientGender"
                      value="Male"
                      checked={ConcernformData.PatientGender === "Male"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> पुरुष /</label>
                    <input 
                      type="checkbox"
                      name="PatientGender"
                      value="Female"
                      checked={ConcernformData.PatientGender === "Female"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> स्त्री</label>

                    </span>
                   
                  </div>

                  <div className="shdywy656230">
                    <label> वय <span>:</span></label>
                    <input type="text"
                    name="PatientAge"
                    value={ConcernformData.PatientAge}
                    onChange={handleChange}
                    required
                    >
                    </input>वर्षे
                  </div>

                  {/* <div className="shdywy656230">
                    <label> वर्षे <span>:</span></label>
                    <input type="text"
                    name="PatientYrs"
                    value={ConcernformData.PatientYrs}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}

                  <div className="shdywy656230">
                    <label> रजि. नं. <span>:</span></label>
                    <input type="text"
                    name="RegNo"
                    value={ConcernformData.RegNo}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label> रोग निदान <span>:</span></label>
                    <input type="text"
                    name="Diagnosis"
                    value={ConcernformData.Diagnosis}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label>शस्त्रक्रियेचे नांव  <span>:</span></label>
                    <input type="text"
                    name="NameOfSurgery"
                    value={ConcernformData.NameOfSurgery}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                </td>

                <td>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                   <h3>पालकाची माहिती (पेशंट अज्ञान असल्यास)</h3>
                </div>
                <div className="shdywy656230">
                    <label> नांव  <span>:</span></label>
                    <input type="text"
                    name="RelativeName"
                    value={ConcernformData.RelativeName}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  {/* <div className="shdywy656230">
                    <label> लिंग <span>:</span></label>
                    <input type="text"
                    name="RelativeGender"
                    value={ConcernformData.RelativeGender}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}


                 <div className="">
                    <label> लिंग <span>:</span></label>
                    <input 
                      type="checkbox"
                      name="RelativeGender"
                      value="Male"
                      checked={ConcernformData.RelativeGender === "Male"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> पुरुष /</label>
                    <input 
                      type="checkbox"
                      name="RelativeGender"
                      value="Female"
                      checked={ConcernformData.RelativeGender === "Female"} // Assuming PatientGender is a string
                      onChange={handleChange}
                      required
                    />
                    <label> स्त्री</label>
                  </div>

                  <div className="shdywy656230">
                    <label> वय <span>:</span></label>
                    <input type="text"
                    name="RelativeAge"
                    value={ConcernformData.RelativeAge}
                    onChange={handleChange}
                    required
                    >
                    </input>वर्षे
                  </div>


                  <div className="shdywy656230">
                      <label> पत्ता : <span>:</span></label>
                      <textarea
                        name="RelativeAddress"
                        value={ConcernformData.RelativeAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  <div className="shdywy656230">
                    <label> पेशंटशी नाते : <span>:</span></label>
                    <input type="text"
                    name="RelationshipWithPatient"
                    value={ConcernformData.RelationshipWithPatient}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                </td>
              </tr>




            </tbody>
          </table>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            मी
            <input
                type="text"
                name="FreeText1"
                value={ConcernformData.FreeText1}
                onChange={handleChange}
                style={{width: '570px'}}
                required
                
                />
                खालील <br/><br/>सही करणार
                <input 
                type="checkbox"
                name="onMyOwnCheckbox"
                value="Yes"
                checked={ConcernformData.onMyOwnCheckbox === 'Yes'} // Assuming PatientGender is a string
                onChange={handleChange}
                required
              />
                
                माझेवर स्वतःवर /
                <input 
                type="checkbox"
                name="ConsentMySelfCheckbox"
                value="Yes"
                checked={ConcernformData.ConsentMySelfCheckbox === 'Yes'} // Assuming PatientGender is a string
                onChange={handleChange}
                required
              />
                 वरील पेशंटवर गर्भपात शस्त्रक्रिया व भूल इत्यादी करीता खालीलपैकी विशेष कारणांसाठी माझे संपती  पत्र देत आहे.
                  अ) गर्भवतीच्या जीवास धोका, ब) गर्भवतीच्या शारीरिक आरोग्यास <br/> घोर अपाय, क) गर्भवतीच्या मानसिक आरोग्यास घोर 
                  अपाय, ड) बलात्कारामुळे झालेली गर्भधारणा, ३) जन्माला येणाऱ्या अर्भकास बौध्दिक किंवा शारीरिक व्यंग अथवा अपंगत्व 
                  येण्याची दाट शक्यता फ) संतती प्रतिबंधक उपायांच्या विफलतेमुळे झालेली गर्भधारणा <br/>आणि मला असेही समजवण्यात आले
                   आहे की.

               
            </p>
          </div>
        </div>

        
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
              
            १) ही शस्त्रक्रिया व त्यासाठी द्यावी लागणारी भूल यासंबधीची, तसेच त्यांचे संभाव्य दुष्परीणाम, धोके इत्यादींची सविस्तर
             माहीती आणि <br/> या शस्त्रक्रिया - भूल या व्यतिरिक्त इतर उपलब्ध उपचार पध्दती संबधी माहिती मला डॉ.
             <input
                type="text"
                name="DoctorName1"
                value={ConcernformData.DoctorName1}
                onChange={handleChange}
                style={{width: '620px'}}
                required
                
                /><br/>
                <br/>
             यांनी समजावून सांगितली आहे.<br/>
           <br/>
            २)   कोणतेही ऑपरेशन संपूर्णतः सुरक्षित नसते व औषधोपचार / तपासण्या / शस्त्रक्रिया / उपचार पध्दती वा भुलेमुळे जीवाला 
            धोका <br/>वा इजा होण्याची शक्यता सर्वसाधारणपणे निरोगी असण्याऱ्या व्यक्तिससुध्दा असते याची मला स्पष्ट कल्पना दिली गेली आहे. 
             <br/><br/>
            ३)  जादा रक्तस्त्राव, जंतूबाधा, हृदय बंद पडणे व फुफ्फुसात रक्ताची गुठळी अडकणे हे व यासारखे अकल्पित / अकस्मित
             इतरही <br/> काही धोके शस्त्रक्रियेतून वा भूल देण्यातून उद्भवू शकतात याची कल्पना मला डॉक्टरांनी दिली आहे.
             <br/>
             <br/>

            ४)  औषधोपचार / तपासण्या / शस्त्रक्रिया / उपचारपध्दती करताना डॉक्टरांना काही कारणाने शस्त्रक्रिया वा भुलेचे स्वरूप बदलावे <br/>
            लागले तर, तसेच अत्यावश्यक वाटल्यास एखादा अवयव वा भाग काढून टाकावा लागल्यास, अशा बदलास माझी संमती गृहि धरली<br/>
             आहे व तशी मला कल्पना दिली आहे.
               <br/>
               <br/>
            ५)  वरील ऑपरेशन व संबधित भूल यांच्यानंतर क्वचित, इच्छित फायदा होण्याऐवजी अन्य त्रास होऊ शकतो,
              उदा. : १) वंध्यत्व २) ओटीपोटीत सूज येणे. ३) क्वचितप्रसंगी या शस्त्रक्रियेनंतर सुद्धा गर्भाची वाढ चालूच राहणे.
               ४) आतील अवयवांना इजा होणे वा छिद्र <br/> पडणे. ५) इतर :
            <input
                type="text"
                name="FreeText2"
                value={ConcernformData.FreeText2}
                onChange={handleChange}
                style={{width: '580px'}}
                required
                
                />
               <br/>
                <br/>
                पण तो त्रास टाळण्यासाठी व झाल्यास सुधारण्यासाठी आवश्यक ती काळजी<br/>
                <br/>
                डॉक्टर (सर्जन)
                <input
                type="text"
                name="DoctorName2"
                value={ConcernformData.DoctorName2}
                onChange={handleChange}
                style={{width: '470px'}}
                required
                
                />
                <br/>
                <br/>
                व डॉक्टर (भूलतज्ञ)
                <input
                type="text"
                name="DoctorName3"
                value={ConcernformData.DoctorName3}
                onChange={handleChange}
                style={{width: '470px'}}
                required
                
                /><br/><br/>
                आणि जरुर वाटल्यास त्यांनी सूचविलेले इतर डॉक्टर घेतील याचा मला विश्वास आहे व संभाव्य घोक्याची मला कल्पना दिली आहे.<br/>
                <br/>
               
                <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
                    <h3>
                    <input 
                    type="checkbox"
                    name="AboveTextCheckbox"
                    value="Yes"
                    checked={ConcernformData.AboveTextCheckbox === 'Yes'} // Assuming PatientGender is a string
                    onChange={handleChange}
                    required
                    />
                    वरील सर्व मजकूर मी वाचला आहे / 
                    <input 
                    type="checkbox"
                    name="ReadbyMeCheckbox"
                    value="Yes"
                    checked={ConcernformData.ReadbyMeCheckbox === 'Yes'} // Assuming PatientGender is a string
                    onChange={handleChange}
                    required
                    />
                    मला वाचून दाखविण्यात आला आहे.<br/>
                    मला तो समजला आहे व त्यास माझी संपूर्ण मान्यता आहे.</h3>
             </div>




            </p>
            

          </div>


        </div>

        
       <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                   <h3>साक्षीदार</h3>
                </div>
                  <div className="shdywy656230">
                    <label> सही<span>:</span></label>
                    <input type="text"
                    name="Witness1Sign"
                    value={ConcernformData.Witness1Sign}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label> नांव  <span>:</span></label>
                    <input type="text"
                    name="Witness1Name"
                    value={ConcernformData.Witness1Name}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label> पत्ता : <span>:</span></label>
                    <textarea
                      name="Witness1Address"
                      value={ConcernformData.Witness1Address}
                      onChange={handleChange}
                      required
                    />
                  </div>


                  <div className="shdywy656230">
                    <label> वय : <span>:</span></label>
                    <input type="text"
                    name="Witness1Age"
                    value={ConcernformData.Witness1Age}
                    onChange={handleChange}
                    required
                    >
                    </input>वर्षे
                  </div>

                  {/* <div className="shdywy656230">
                    <label> वर्षे : <span>:</span></label>
                    <input type="text"
                    name="Witness1Yrs"
                    value={ConcernformData.Witness1Yrs}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div> */}

                  <div className="shdywy656230">
                    <label> • ता. : <span>:</span></label>
                    <input type="text"
                    name="Witness1Date"
                    value={ConcernformData.Witness1Date}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                </td>

                
                <td>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
                   <h3>पेशंट / मालक</h3>
                </div>
                <div className="shdywy656230">
                    <label>सही आणि / किंवा डाव्या हाताचा अंगठा<span>:</span></label><br/>
                    <br/>
                    <input type="text"
                    name="RelativeSignatureLhti1"
                    value={ConcernformData.RelativeSignatureLhti1}
                    onChange={handleChange}
                    required
                    >
                    </input>
                    </div>

                    <div className="shdywy656230">
                    <label>तारीख <span>:</span></label>
                    <input type="text"
                    name="RelativeDate"
                    value={ConcernformData.RelativeDate}
                    onChange={handleChange}
                    required
                    >
                    </input>
                    </div>

                    <div className="shdywy656230">
                    <label>वेळ <span>:</span></label>
                    <input type="text"
                    name="RelativeTime"
                    value={ConcernformData.RelativeTime}
                    onChange={handleChange}
                    required
                    >
                    </input>
                    </div>


                </td>

              </tr>
              
              
              



{/* 
               <tr>

                <td>
                  <div className="shdywy656230">

                      <label>  सही<span>:</span></label><br/>
                      <input 
                      type="text"
                      name="Witness1Sign"
                      value={ConcernformData.Witness1Sign}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div>


                    <div className="shdywy656230">

                      <label>  पत्ता <span>:</span> </label>
                      <input 
                      type="text"
                      name="Address"
                      value={ConcernformData.Address}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div>

                  
                </td>

                <td>
                  <div className="shdywy656230">

                    <label>  साक्षीदाराचे नाव  <span>:</span> </label>
                    <input 
                    type="text"
                    name="NameOfWitness"
                    value={ConcernformData.NameOfWitness}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">

                    <label> सही / अंगठा <span>:</span> </label>
                    <input 
                    type="text"
                    name="SignatureLhti2"
                    value={ConcernformData.SignatureLhti2}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>


                </td>

              </tr> */}

            </tbody>
          </table>
        </div>



        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMtpConsent;
