import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import IpDocVitals from "./IpDocVitals";
import IpDocIntakeOutput from "./IpDocIntakeOutput";
import IpDocDrugAdminis from "./IpDocDrugAdminis";
import IpLabTest from "../IPNurseflow/IpLabTest";
import IpMedicalRecords from "./IpMedicalRecords";
import IpPreoperativeIns from "./IpPreoperativeIns";
import IpPreoperativeChecklist from "./IpPreoperativeChecklist";


function IpDoctorWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("IpPreoperativeChecklist");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/IpDocQuelist')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.BookingId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                            Medical History
                            </button>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("PreoperativeIntructions")} style={{ color: activeTab === 'PreoperativeIntructions' ? "white" : '' }}>
                                Preoperative Intructions
                            </button>
                            |
                            <button onClick={() => handleTabChange("IpPreoperativeChecklist")} style={{ color: activeTab === 'IpPreoperativeChecklist' ? "white" : '' }}>
                                Preoperative Checklist
                            </button>

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                            Medical History
                            </button>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("PreoperativeIntructions")} style={{ color: activeTab === 'PreoperativeIntructions' ? "white" : '' }}>
                                Preoperative Intructions
                            </button>
                            |
                            <button onClick={() => handleTabChange("IpPreoperativeChecklist")} style={{ color: activeTab === 'IpPreoperativeChecklist' ? "white" : '' }}>
                                Preoperative Checklist
                            </button>

                        </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <IpDocVitals />}
            {activeTab === "MedicalHistory" && <IpMedicalRecords />}
            {activeTab === "Intake/OutPut" && <IpDocIntakeOutput />}
            {activeTab === "DrugAdministration" && <IpDocDrugAdminis />}
            {activeTab === "PreoperativeIntructions" && <IpPreoperativeIns />}
            {activeTab === "Lab" && <IpLabTest />}
            {activeTab === "IpPreoperativeChecklist" && <IpPreoperativeChecklist />}
        </>
    );
}

export default IpDoctorWorkbench;
