import React, { useState, useEffect } from "react";
import "../../TreatmentRecords/Navigation.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InsurancepatientInfo from "./InsurancePatientInfo";
import InsuranceMedicalHistory from "./InsuranceMedicalHistory";
import InsuranceExaminationFinding from "./InsuranceExaminationFinding";
import InsuranceDiagnosis from "./InsuranceDiagnosis";
import InsuranceTreatmentInfo from "./InsuranceTreatmentInfo";
import InsurancePreAuthDocuments from "./InsurancePreAuthDocuments";
import InsuranceBillingInfo from "./InsuranceBillingInfo";
import HistoryIcon from '@mui/icons-material/History';
import '../../Insurance/Insurance.css'
import Tooltip from '@mui/material/Tooltip';
import { addDays, format } from "date-fns";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InsurancePreAuthEnhanceDocument from "./InsurancePreAuthEnhanceDocument";
import InsurancePreAuthDischargeDocument from "./InsurancePreAuthDischargeDocument";
import InsuranceClaimDocuments from "./InsuranceClaimDocuments";
import InsuranceCourierInfo from "./InsuranceCourierInfo";




function InsuranceMainpage() {

  const dispatchvalue = useDispatch()


  const navigate = useNavigate()

  const cu_date = format(new Date(), "yyyy-MM-dd");


  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IPPatientDetailes = useSelector(
    (state) => state.InsuranceStore?.IPPatientDetailes
  );



  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );


  const InsurancePageChange = useSelector(
    (state) => state.InsuranceStore?.InsurancePageChange
  );




 

  const [expanded, setExpanded] = useState("MainPage1");

  const [nestedExpanded, setNestedExpanded] = useState("PatientInfo");


  const [open, setOpen] = React.useState(false);
  const [ViewStatus,setViewStatus]=useState('')



  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedChange = (panel) => (event, isExpanded) => {
    setNestedExpanded(isExpanded ? panel : false);
  };

  const [InsuranceMaindata, setInsuranceMaindata] = useState({
    ScreenStatus:'',
    Papersstatus: "DRAFT",
    Papersstatustype: "ORIGINAL",
    PatientPhoto: null,
    PatientName: '',
    MRN: '',
    ALNumber: '',
    CINumber: '',
    InwardNumber: '',
    Requested: '',
    Approved: '',
    PageCompleted: "PatientInfo",
    PatientResponsibility:'',
    PatientPayable:'',
    SentToClaim:'',
    PatientDiscount:'',
    PreAuthApproved:'',
    TPAApproved:'',
    SettledAmount:''
  })

  const[EnhanceAmount,setEnhanceAmount]=useState('')

  // -------------

  const[DischargerState,setDischargerState]=useState({
    RequestedAmount:'',
    BillNumber:''
  })

  const [ClimeRequest,setClimeRequest]=useState({
    BillNumber:'',
    PatientResponsibility:'',
    PatientDiscount:'',
    PatientPayable:''
  })


  const [AllAmountdata,setAllAmountdata]=useState([]) 



  
 


  useEffect(() => {
    console.log('IPPatientDetailes', IPPatientDetailes)
    // if (Object.keys(IPPatientDetailes).length === 0) {
    //     navigate('/Home/Insurance-Dashboard')
    // }
    // else{
    setInsuranceMaindata((prev) => ({
      ...prev,
      MRN: IPPatientDetailes.Booking_Id,
      Requested: IPPatientDetailes.PreAuthAmount,
      PatientName: IPPatientDetailes.PatientName,
    }))
    // }
  }, [IPPatientDetailes]);




  useEffect(() => {
    if (Object.values(InsuranceMaindata).length !== 0) {
      dispatchvalue({ type: 'InsuranceMaindetailes', value: InsuranceMaindata });
    }
  }, [InsuranceMaindata])



  useEffect(() => {
    if (Object.values(InsuranceUpdatedata).length !== 0) {  
      
      if(InsuranceUpdatedata && InsuranceUpdatedata.GetStatus === 'PreAuth'){
        
        axios.get(
          `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Form_Insurance_Main_Information`, {
          params: InsuranceUpdatedata.MRN
        }
        )
          .then((response) => {
            // console.log('vrrrr',response.data);
  
            const data = response.data[0]
  
            console.log('vvvvvsssssrrr', data)
  
            setInsuranceMaindata((prev) => ({
              ...prev,
              ScreenStatus:'PreAuth',
              Papersstatus: data.Papersstatus,
              Papersstatustype: data.Papersstatustype,
              PatientPhoto: null,
              PatientName: data.PatientName,
              MRN: data.MRN_Number,
              ALNumber: data.ALNumber,
              CINumber: data.CINumber,
              InwardNumber: data.Inward_Number,
              Requested: data.RequestedAmount,
              Approved: data.ApprovedAmount,
              PageCompleted: data.PageCompleted,
            }))
  
            // console.log('DEDEDE',data.MainPageCompleted,data.PageCompleted)
  
            setExpanded(data.MainPageCompleted)
            setNestedExpanded(data.PageCompleted)
          })
          .catch((error) => {
            console.log(error);
          });
      }else if(InsuranceUpdatedata && InsuranceUpdatedata.GetStatus === 'Claim'){

        axios.get(
          `https://vesoftometic.co.in/Insurance/get_Claim_Insurance_Main_Information`, {
          params: InsuranceUpdatedata.MRN
        }
        )
          .then((response) => {
            // console.log('vrrrr',response.data);
  
            const data = response.data[0]
  
            console.log('123456789', data)
  
            setInsuranceMaindata((prev) => ({
              ...prev,
              Papersstatus: data.Papersstatus,
              PatientPhoto: null,
              PatientName: data.PatientName,
              MRN: data.MRN_Number,
              ALNumber: data.ALNumber,
              CINumber: data.CINumber,
              InwardNumber: data.Inward_Number,
              ScreenStatus:"Claim",
              PreAuthApproved:data.PreAuthApproved,
              TPAApproved:data.TPAApprovedAmount,          
              SettledAmount:data.SettledAmount,
            }))
  
            // console.log('DEDEDE',data.MainPageCompleted,data.PageCompleted)
  
            setExpanded(data.MainPageCompleted)
          })
          .catch((error) => {
            console.log(error);
          });

      }
     

    }
  }, [InsuranceUpdatedata, InsurancePageChange])


  
  useEffect(() => {
    if (Object.values(InsurancePageChange).length !== 0) {  

    axios.get(
          `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Form_Insurance_Main_Information`, {
          params: InsuranceUpdatedata.MRN
        }
        )
          .then((response) => { 
            // console.log('vrrrr',response.data);
  
            const data = response.data[0]
  
            setExpanded(data.MainPageCompleted)
            setNestedExpanded(data.PageCompleted)
          })
          .catch((error) => {
            console.log(error);
          });
      } 

  }, [InsurancePageChange])


// ---------------------------claim
 



  const ShowHistoryAmount =()=>{

    axios.get(
      `https://vesoftometic.co.in/Insurance/get_Pre_Auth_All_Amount_data`, {
      params: InsuranceUpdatedata.MRN
    }
    )
      .then((response) => {
        console.log('vrrrr',response.data); 

        let AMdata =response.data.data



        if(AMdata.length !==0){

        console.log('kjkjk',AMdata); 
        setOpen(true);
        setAllAmountdata(AMdata)
        setViewStatus("AmountView")

        }else{
          alert('No History Data Found')
        }
        
        
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const SubmitDocFun =()=>{

    let params={
      Papersstatus:"SUBMITTED",
      MRN: InsuranceUpdatedata.MRN,
      ContactNumber:InsuranceUpdatedata.ContactNumber,
      Location:userRecord.location,
      createAt:userRecord.username,
      cu_date:cu_date
    }

    axios.post(`https://vesoftometic.co.in/Insurance/Update_Papersstatus_Insurance`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)

        navigate("/Home/Insurance-Dashboard");


    })
    .catch((error) => {
        console.error(error);
    });

  }

const handlePageClose =()=>{
  setOpen(false);
  setAllAmountdata([])
}


// ---------------------Reopen

const handleReOpenPreAuth =()=>{

  let params={
    MRNNumber:InsuranceUpdatedata.MRN,
    Papersstatus:"DRAFT"
  }

  axios.post(`https://vesoftometic.co.in/Insurance/Update_Reopen_Insurance`,params)
  .then((response) => {
      console.log('Form data submitted.',response.data)
      navigate("/Home/Insurance-Dashboard");
  })
  .catch((error) => {
      console.error(error);
  });


}


// -----------------Enhance


const handleClose = () => {
  setOpen(false);
  setEnhanceAmount('')
  setViewStatus('')
  setClimeRequest({
    BillNumber:'',
    PatientResponsibility:'',
    PatientDiscount:'',
    PatientPayable:''
  })
  setDischargerState({
    RequestedAmount: '',
    BillNumber: '',
  })
};

const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const formJson = Object.fromEntries(formData.entries());
  console.log(formJson);
  handleClose();
};

const handleEnhanceSave =()=>{
  setOpen(true);
  setViewStatus('ENHANCED')
}

const handleProceed_Enhance =()=>{

  let params={
    MRNNumber:InsuranceUpdatedata.MRN,
    EnhanceAmount:EnhanceAmount  ,
    Papersstatus:"DRAFT",
    Papersstatustype:"ENHANCED",
    Location:userRecord.location,
    createAt:userRecord.username,}

    axios.post(`https://vesoftometic.co.in/Insurance/Update_Enhancestatus_Insurance`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        setEnhanceAmount('')
        navigate("/Home/Insurance-Dashboard");

    })
    .catch((error) => {
        console.error(error);
    });


}


// ------------------Discharge

const handleDischargeSave =()=>{
  setOpen(true);
  setViewStatus('DISCHARGE')
}

const handleDischargerState =(e)=>{
    const {name,value}=e.target

    setDischargerState((prev)=>({
      ...prev,
      [name]:value,
    }))
}


const handleProceed_Discharge =()=>{

  let params={
    MRNNumber:InsuranceUpdatedata.MRN,
    Papersstatus:"DRAFT",
    Papersstatustype:"DISCHARGE",
    Location:userRecord.location,
    createAt:userRecord.username,
    RequestedAmount:DischargerState.RequestedAmount,
    BillNumber:DischargerState.BillNumber
  }

    axios.post(`https://vesoftometic.co.in/Insurance/Update_Dischargestatus_Insurance`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        setEnhanceAmount('')
        setDischargerState({
          RequestedAmount: '',
          BillNumber: '',
        })
        navigate("/Home/Insurance-Dashboard");

    })
    .catch((error) => {
        console.error(error);
    });

}



// ---------------------Clime

const MoveTofileClime =()=>{

  setOpen(true);
  setViewStatus('CLAIM')

}

const handleClaimRequestState=(e)=>{

  const {name,value}=e.target

  setClimeRequest((prev)=>({
      ...prev,
      [name]:value,
    }))

}


const handleProceed_ToClaim =()=>{

  let params={
    MRNNumber:InsuranceUpdatedata.MRN,
    ContactNumber:InsuranceUpdatedata.ContactNumber,
    Location:userRecord.location,
    createAt:userRecord.username,
    BillNumber:ClimeRequest.BillNumber,
    PatientResponsibility:ClimeRequest.PatientResponsibility,
    PatientDiscount:ClimeRequest.PatientDiscount,
    PatientPayable:ClimeRequest.PatientPayable,

  }

  console.log('params',params)

    axios.post(`https://vesoftometic.co.in/Insurance/MoveTo_Claim_Insurance`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)

        setClimeRequest({
          BillNumber:'',
          PatientResponsibility:'',
          PatientDiscount:'',
          PatientPayable:''
        })

        navigate("/Home/Insurance-Dashboard");

    })
    .catch((error) => {
        console.error(error);
    });
}

  return (
    <>
      <div className="new-patient-registration-form">
        <br />
        <div className="dctr_info_up_head" style={{backgroundColor:'var(--selectbackgroundcolor)' ,padding:'25px 10px',borderRadius:'5px'}}>
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
              {InsuranceMaindata.PatientPhoto ? (
                <img src={InsuranceMaindata.PatientPhoto} alt="Patient Photo" />
              ) : (
                <img
                  // src={bgImg2} 
                  alt="Default Patient Photo" />
              )}
              <label>{InsuranceMaindata.Papersstatus}
                <br />
                {InsuranceMaindata.ScreenStatus === 'Claim' ? "" : (InsuranceMaindata.Papersstatustype)}
              </label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.PatientName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                MRN <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.MRN}{" "}
              </span>
            </div>

            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                AL Number <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.ALNumber}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                CL Number <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.CINumber}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Inward Number <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.InwardNumber}{" "}
              </span>
            </div>
          </div>
        {InsuranceMaindata.ScreenStatus === 'Claim' ?   
        <div>
        
        <h3 
        style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center'}}>Amount
        </h3>

        <br />
        <div className="RegisFormcon ">
        <div className="RegisForm_1">
          <label htmlFor="FirstName">
           <h3> Preauth Approved</h3> <span>:</span>{" "}
          </label>

          <span className="dctr_wrbvh_pice" htmlFor="FirstName">
            {InsuranceMaindata.PreAuthApproved}{" "}
        
          </span>  
         
        </div>
        <div className="RegisForm_1 ">
          <label htmlFor="FirstName">
            <h3>TPA Approved</h3> <span>:</span>{" "}
          </label>

          <span className="dctr_wrbvh_pice" htmlFor="FirstName">
            {InsuranceMaindata.TPAApproved}{" "}
          </span>
        </div>

        <div className="RegisForm_1 ">
          <label htmlFor="FirstName">
            <h3>Settled Amount</h3> <span>:</span>{" "}
          </label>

          <span className="dctr_wrbvh_pice" htmlFor="FirstName">
            {InsuranceMaindata.SettledAmount}{" "}
          </span>
        </div>
      </div>
        </div>
       :
        <div>
        
            <h3 
            style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center'}}>Amount
                  <Tooltip title="Show History">
            <span onClick={ShowHistoryAmount} style={{ backgroundColor: 'var(--ProjectColor)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '2px 5px',marginLeft:'5px', cursor: 'pointer'}}>
              <HistoryIcon  />
            </span>
          </Tooltip>
            </h3>
  
            <br />
            <div className="RegisFormcon ">
            <div className="RegisForm_1">
              <label htmlFor="FirstName">
               <h3> Requested</h3> <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.Requested}{" "}
            
              </span>  
             
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                <h3>Approved</h3> <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {InsuranceMaindata.Approved}{" "}
              </span>
            </div>
          </div>
        </div>
        }
        </div>



        <br />




        <div className="new-patient-registration-form1">

          <Accordion
            expanded={
              expanded === 'MainPage1'
            }
            onChange={handleChange("MainPage1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Typography
                sx={{ width: "45%", flexShrink: 0 }}
                id="panel1bh-header"
              >
                Pre- Auth Form
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >

                <Accordion expanded={nestedExpanded === "PatientInfo"} onChange={handleNestedChange("PatientInfo")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                    >
                  
                    <Typography sx={{ width: "45%", flexShrink: 0 , }} id="panel3bh-header">
                      Patient Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsurancepatientInfo />

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={nestedExpanded === "MedicalHistory"} onChange={handleNestedChange("MedicalHistory")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Medical History
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsuranceMedicalHistory />

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={nestedExpanded === "ExaminationFinding"} onChange={handleNestedChange("ExaminationFinding")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Examination Finding
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsuranceExaminationFinding />

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={nestedExpanded === "Diagnosis"} onChange={handleNestedChange("Diagnosis")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Diagnosis
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsuranceDiagnosis />

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={nestedExpanded === "TreatmentInfo"} onChange={handleNestedChange("TreatmentInfo")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Treatment Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsuranceTreatmentInfo />

                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={nestedExpanded === "BillingInfo"} onChange={handleNestedChange("BillingInfo")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Billing Info
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <InsuranceBillingInfo />

                  </AccordionDetails>
                </Accordion>


              </Typography>
            </AccordionDetails>
          </Accordion>







          <Accordion
            expanded={expanded === "MainPage2"}
            onChange={handleChange("MainPage2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel1bh-header"
    
            >
              <Typography
                sx={{ width: "45%", flexShrink: 0 }}
                id="panel1bh-header"
              >
                Documents
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >

                <Accordion expanded={nestedExpanded === "PreAuthDocuments"} onChange={handleNestedChange("PreAuthDocuments")}>
                  
                 {InsuranceMaindata.ScreenStatus==='Claim'?
                 <>
                 <AccordionSummary
                 expandIcon={<ExpandMoreIcon />}
                 aria-controls="panel3bh-content"
                 id="panel3bh-header"
                 style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                 <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Claim Documents
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InsuranceClaimDocuments/>
                  </AccordionDetails>

                 </>
                 :
                  <>
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >

                 <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Pre-Auth Documents
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                  {InsuranceUpdatedata.Papersstatustype === "ORIGINAL" ? <InsurancePreAuthDocuments/> : 
                  InsuranceUpdatedata.Papersstatustype === "ENHANCED" ? <InsurancePreAuthEnhanceDocument/>:
                  InsuranceUpdatedata.Papersstatustype  === "DISCHARGE" || InsuranceUpdatedata.Papersstatustype  === "DISCHARGED" ? 
                  <InsurancePreAuthDischargeDocument/> : <InsurancePreAuthDocuments/>}
                  </AccordionDetails>
                  </>
                  }

                  
                </Accordion>


               {/*   <Accordion expanded={nestedExpanded === "panel10"} onChange={handleNestedChange("panel10")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Recieved Documents
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>



                  </AccordionDetails>
                </Accordion>


               <Accordion expanded={nestedExpanded === "panel11"} onChange={handleNestedChange("panel11")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    style={{backgroundColor:'var(--selectbackgroundcolor)'}}
                  >
                    <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel3bh-header">
                      Claim Documents
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>



                  </AccordionDetails>
                </Accordion> */}


              </Typography>
            </AccordionDetails>
          </Accordion>



          <Accordion
            expanded={expanded === "MainPage3"}
            onChange={handleChange("MainPage3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: "45%", flexShrink: 0 }}
                id="panel1bh-header"
              >
                Courier Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <InsuranceCourierInfo/>
              </Typography>
            </AccordionDetails>
          </Accordion>


         
        </div>
      </div>
      {
          InsuranceUpdatedata.Papersstatustype  === "DISCHARGED" ? 

          <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>

          <div className="submit_button_prev_next">
          <button onClick={MoveTofileClime} style={{width:'auto',paddingLeft:'5px',paddingRight:'5px'}}>
              Forward To Claims                
          </button>
        </div>

        </div>
        :
        <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>
      {
        InsuranceUpdatedata.Papersstatus === 'SUBMITTED' ? 
        <div className="submit_button_prev_next">
        <button style={{width:'auto',paddingLeft:'5px',paddingRight:'5px'}}>
            Submit Document               
        </button> 
        </div>
        :
        InsuranceUpdatedata.Papersstatus === "APPROVED" ?
        <div className="submit_button_prev_next" onClick={handleEnhanceSave}>
            <button>
                Enhance                
            </button>
        </div>
        :
        InsuranceUpdatedata.Papersstatus === "REJECTED" ?
        <div className="submit_button_prev_next">
            <button onClick={handleReOpenPreAuth}>
                ReOpen                
            </button>
        </div>
        :
        <div className="submit_button_prev_next">
            <button onClick={SubmitDocFun}>
                Submit                
            </button>
        </div>
        }

        {
          InsuranceUpdatedata.Papersstatus === "APPROVED" ?
          <div className="submit_button_prev_next" onClick={handleDischargeSave}>
              <button>
                  Discharge                
              </button>
          </div>:''
        }
        </div>
        }

      

      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
      <DialogTitle>{ViewStatus === 'ENHANCED' ? 'Enhancement Request' : ViewStatus === 'CLAIM' ? 'Claim Request' : ViewStatus === 'AmountView' ? "Amount History":'Discharge Request' }</DialogTitle>

        {ViewStatus === 'AmountView' ?
         <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
         {/* <h4>Amount History</h4> */}

         <div className="Supplier_Master_Container">
        <div className="Total_input_container">
           <table className="selected-medicine-table2">
             <thead>
               <tr>
                 <th >S.No</th>
                 <th >Requested Amount</th>
                 <th >Approved Amount</th>
                 <th >Submited Date</th>
                 <th >Approved Date</th>
               </tr>
             </thead>
             <tbody>
               {AllAmountdata.map((client, index) => (
                 <tr key={index}>
                   <td>{client.S_NO}</td>
                   <td>{client.RequestedAmount}</td>
                   <td>{client.ApprovedAmount}</td>
                   <td>{client.SubmitedDate}</td>
                   <td>{client.ApprovedDate}</td>
                 </tr>
               ))}
             </tbody>
           </table>

         </div>

       </div>
       </div>

        :
        <div className="Supplier_Master_Container">
        <div className="Total_input_container">


        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            MRN Number <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="MRNNumber"
            required
            readOnly
            value={InsuranceUpdatedata.MRN || ''}
          />
        </div>

        {ViewStatus === 'ENHANCED' ?  <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Requested Amount <span>:</span>{" "}
          </label>
          <input
            type="number"
            name="EnhanceAmount"
            required
            value={EnhanceAmount}
            onChange={(e) => setEnhanceAmount(e.target.value)}
          />
        </div>
        :
        ViewStatus === 'CLAIM' ? 
        <>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Bill Number <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="BillNumber"
            required
            value={ClimeRequest.BillNumber}
            onChange={(e)=>{handleClaimRequestState(e)}}
          />
        </div>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Patient Responsibility <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="PatientResponsibility"
            required
            value={ClimeRequest.PatientResponsibility}
            onChange={(e)=>{handleClaimRequestState(e)}}
          />
        </div>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Patient Discount <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="PatientDiscount"
            required
            value={ClimeRequest.PatientDiscount}
            onChange={(e)=>{handleClaimRequestState(e)}}
          />
        </div>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Patient Payable <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="PatientPayable"
            required
            value={ClimeRequest.PatientPayable}
            onChange={(e)=>{handleClaimRequestState(e)}}
          />
        </div>
        </>
        :
        <>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Requested Amount <span>:</span>{" "}
          </label>
          <input
            type="number"
            name="RequestedAmount"
            required
            value={DischargerState.RequestedAmount}
            onChange={(e)=>{handleDischargerState(e)}}
          />
        </div>
        <div className="RegisForm_1 column_RegisForm_1_forinsurance">
          <label>
            {" "}
            Bill Number <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="BillNumber"
            required
            value={DischargerState.BillNumber}
            onChange={(e)=>{handleDischargerState(e)}}
          />
        </div>
        </>
        
        }
        </div>
        </div>
        }




        <DialogActions>
          {ViewStatus === 'AmountView' ? <Button onClick={handlePageClose}>Close</Button> : <Button onClick={handleClose}>Cancel</Button>}
          {ViewStatus === 'AmountView' ? '' : ViewStatus === 'ENHANCED' ? <Button type="submit" onClick={handleProceed_Enhance} >Proceed</Button>   :
           ViewStatus === 'CLAIM' ? <Button type="submit"  onClick={handleProceed_ToClaim} >Proceed</Button>:<Button type="submit"  onClick={handleProceed_Discharge} >Proceed</Button>}       
        </DialogActions>
      </Dialog>
    </React.Fragment>
     
    </>
  );
}

export default InsuranceMainpage;

