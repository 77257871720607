import React, { useState, useEffect } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import "./Billing.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
// import "../AppointmentManagement/ReqRegis.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Barcode from "react-barcode";
import { useDispatch } from "react-redux";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { useSelector } from "react-redux";


function Billing({ userRecord }) {


  const [pdfBlob, setPdfBlob] = useState(null);
  const [preview, setpreview] = useState(false);
  const dispatchvalue = useDispatch();

  const [type, setType] = useState("PatientDetailCon");
  const [testname, setTestName] = useState([]);
  const [groupname, setGroupName] = useState([]);
  const [visitid, setvisitid] = useState("");
  const [patientid, setpatientid] = useState("");
  const [testdetails, settestdetails] = useState([]);
  const handleToggleChange = (event) => {
    const newValue = event.target.value;
    setType(newValue);
  };
  const [invoiceno, setInvoiceno] = useState();
  const [barcodedata, setbarcodedata] = useState([]);
  const [barcode, setbarcode] = useState();
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [Data, setData] = useState([]);

  const [patientdetails, setPatientDetails] = useState({
    visitid: "",
    visittype: "",
    title: "",
    patientname: "",
    fathername: "",
    dob: "",
    age: "",
    gender: "",
    maritalstatus: "",
    phlebotomisttype: "",
    phlebotomistname: "",
    Referingdoctor: "",
    Patientgroup: "",
    Doorno: "",
    Address: "",
    District: "",
    City: "",
    State: "",
    PhoneNo: "",
    Alternatephn: "",
    Email: "",
    Dispatchmethod: "",
    testmethod: "",
  });
  const [Dname, setDname] = useState('');
  const [billAmount, setBillAmount] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [Billing_date, setBilling_date] = useState('');
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [formAmount, setFormAmount] = useState({
      paidamount: '',
      Billpay_method: ''
  });

  const [initialState, setinitialState] = useState({
    totalItems: 0,
    totalAmount: 0,
    totalDiscount: 0,
    totalGstamount: 0,
    totalUnits: 0,
    totalNetAmount: 0,
    totalrecived:0
});

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: '',
    ClinicGST: '',
    ClinicAddress: '',
    ClinicCity: '',
    ClinicState: '',
    ClinicCode: '',
    ClinicMobileNo: '',
    ClinicLandLineNo: '',
    ClinicMailID: '',
    InvoiceNo: ''
});
  const [orderdetails, setorderdetails] = useState({
    testname: "",
    grossAmount: 0,
    discountType: "", // "Cash" or "Percentage"
    discountValue: "",
    paymentMethod: "",
    receivedAmount: 0,
    netAmount: 0,
    dueAmount: 0,
  });
  // const [groupdetails, setgroupdetails] = useState({
  //   groupname: "",
  //   groupcode: "",
  //   groupcost: "",
  //   discountType: "", // "Cash" or "Percentage"
  //   discountValue: "",
  //   paymentMethod: "",
  //   receivedAmount: 0,
  //   netAmount: 0,
  //   dueAmount: 0,
  // });

  console.log(dispatchvalue);


  console.log(dispatchvalue);
  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    console.log(name, value);

    setorderdetails((prevState) => {
      let updatedState = { ...prevState, [name]: value };
   

    

      if (
        [
          "discountValue",
          "receivedAmount",
          "grossAmount",
          "discountType",
        ].includes(name)
      ) {
        // Directly pass the updated state to calculateAmounts
        calculateAmounts(updatedState);
      }

      return updatedState;
    });

  };


  const numberToWords = (number) => {
    let num = parseInt(number.toString().split('.')[0])
    if (num === 0) {
        return 'Zero Rupees Only';
    }

    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convert = (num) => {
        if (num <= 10 && num !== 0) return units[num];
        if (num < 20) return teens[num - 11];
        if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
        if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + convert(num % 100) : '');
        if (num < 100000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' and ' + convert(num % 1000) : '');
        if (num < 10000000) return convert(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 !== 0 ? ' and ' + convert(num % 100000) : '');

    };

    return convert(num) + ' Rupees  Only';
};

  console.log("invoiceno", invoiceno);


  const handleSearch = () => {
    const postorderdetails = {
      ...orderdetails,
      invoiceno: invoiceno,
      patientid: patientid,
      barcode: barcode,
      visitid: visitid,
      testmethod: patientdetails.testmethod,
      patientname: patientdetails.patientname,
      Dispatchmethod: patientdetails.Dispatchmethod,
      location: "KOCHI",
    };
  
    if (patientdetails.patientname) {
      if (patientdetails.testmethod === "Individual" || patientdetails.testmethod === "Group") {
        axios
          .get(
            patientdetails.testmethod === "Individual" ?
            `${urllink}usercontrol/getcostpertest?testname=${orderdetails.testname}` :
            `${urllink}usercontrol/getgroupcost?testname=${orderdetails.testname}`
          )
          .then((response) => {
            console.log('response.data', response.data);  // For debugging
  
            let newData = [];
  
            if (
              response.data &&
              !Array.isArray(response.data) &&
              response.data.test_code
            ) {
              newData = [response.data];
            } else if (Array.isArray(response.data)) {
              newData = response.data;
            }
  
            // Conditionally update the testdetails state
            if (patientdetails.testmethod === "Individual") {
              settestdetails((prevData) => [...prevData, ...newData]);
            } else if (patientdetails.testmethod === "Group") {
              // Merge the new data with the existing data in testdetails state
              settestdetails((prevData) => [...prevData, ...newData]);
            }
  
            const totalCost = newData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
  
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: prevState.grossAmount + totalCost,
            }));
  
            calculateAmounts(orderdetails.grossAmount + totalCost);
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      }
    } else {
      alert("Please enter patient details");
    }
    axios.post(
          `${urllink}Billing/inertbillinginfo`,
          postorderdetails
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

  };
  
  console.log('testdetails', testdetails);


  useEffect(() => {
    const currentDate = new Date();
        setBilling_date(format(currentDate, 'dd-MM-yyyy'))
    axios
      .get(`${urllink}usercontrol/getalltestname`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setTestName(data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${urllink}Billing/getinvoice`)
      .then((response) => {
        console.log(response.data);
        const data = response.data.nextInvoiceCode;

        setInvoiceno(data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}Billing/getbarcode`)
      .then((response) => {
        console.log(response.data);
        const data = response.data.nextInvoiceBarcode;
        setbarcode(data);
        // setInvoiceno(data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/getpatientid?patientname=${patientdetails.patientname}&patientphn=${patientdetails.PhoneNo}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.nextPatientId;
          setpatientid(data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${urllink}Billing/getforvisitid?patientphn=${patientdetails.PhoneNo}&patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.nextVisitId;
          setvisitid(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [patientdetails.patientname, patientdetails.PhoneNo]);

  useEffect(() => {
    console.log(orderdetails);
    console.log(invoiceno);
  }, [orderdetails, invoiceno]);

  const calculateAmounts = (currentState) => {
    console.log(`Original Gross Amount: ${currentState.grossAmount}`);

    const parsedGrossAmount = parseFloat(currentState.grossAmount);
    const parsedDiscountValue = parseFloat(currentState.discountValue);
    const parsedReceivedAmount = parseFloat(currentState.receivedAmount);

    let discount = 0;
    if (currentState.discountType === "Cash") {
      discount = parsedDiscountValue;
    } else if (currentState.discountType === "Percentage") {
      discount = (parsedDiscountValue / 100) * parsedGrossAmount;
    }

    const netAmount = parsedGrossAmount - discount;
    const dueAmount = netAmount - parsedReceivedAmount;

    setorderdetails((prevState) => ({
      ...prevState,
      netAmount: netAmount.toFixed(2),
      dueAmount: dueAmount.toFixed(2),
    }));
    setinitialState((preve)=>({
      ...preve,
      totalDiscount: discount.toFixed(2),
      totalNetAmount: netAmount.toFixed(2),
      totalrecived: parsedReceivedAmount

    }))
  };

  // Helper function to calculate age
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    let updatedDetails = {
      ...patientdetails,
      [name]: value,
    };

    if (name === "title") {
      const genderValue =
        value.toLowerCase() === "mr"
          ? "Male"
          : value.toLowerCase() === "mrs" || value.toLowerCase() === "ms"
          ? "Female"
          : "Other";
      updatedDetails = { ...updatedDetails, gender: genderValue };
    }

    // Calculate age when dob is changed
    if (name === "dob") {
      const age = calculateAge(value);
      updatedDetails = { ...updatedDetails, age: age.toString() }; // Assuming age is stored as a string
    }

    setPatientDetails(updatedDetails);
  };

  console.log("barcodedata", barcodedata);


  useEffect(() => {
   
  }, [barcode]); // Depend on barcodedata so it dispatches after barcodedata updates

  console.log(dispatchvalue);



  const handleInputChange12 = (event, newvalue) => {
    setorderdetails({
      ...orderdetails,
      testname: newvalue, // Assuming `value` is a string; adjust accordingly if it's an object
    });
  };

  const handlepatientsearch = () => {
    const patientname = patientdetails.patientname;
    const phonenum = patientdetails.PhoneNo;

    axios
      .get(
        `${urllink}Billing/getforpatientinfo?patientname=${patientname}&phonenum=${phonenum}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data.patient_info;

        // Update state here
        setPatientDetails((prevState) => ({
          ...prevState, // Keep the existing state
          // Update the state with the new data from the backend
          patientname: data.Patient_Id ? patientname : "", // Assuming you want to keep the searched name
          fathername: data.Father_Name || "",
          dob: data.DOB || "",
          age: data.Age.toString() || "", // Making sure age is a string if it's being used in a text input
          gender: data.Gender || "",
          maritalstatus: data.Marital_Status || "",
          Doorno: data.Door_No || "",
          Address: data.Address || "",
          State: data.State || "",
          PhoneNo: phonenum, // Assuming you want to keep the searched phone number
          Alternatephn: data.Alternate_No || "",
          Email: data.Email || "",
          // Add other fields if necessary
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    // Make the POST request to fetch clinic details
    axios.get(`${urllink}usercontrol/getoraganization`)
    .then(response => {
      const record = response.data[0]; // Access the first (and only) object in the array
      setClinicDetials({
        ClinicLogo: null,
    ClinicName: record.Organization,
    ClinicGST:record.Gst_No,
    ClinicAddress:record.Street,
    ClinicCity: record.City,
    ClinicState: record.State,
    ClinicCode: record.Pincode,
    ClinicMobileNo: record.Phone_no,
    ClinicLandLineNo:record.Land_line,
    ClinicMailID: record.Email,
    InvoiceNo: invoiceno
      })
    })
    .catch(error => {
      console.error('Error:', error);
    });
  
}, []);



const handlePrint = useReactToPrint({
  content: () => document.getElementById('reactprintcontent'),
  onBeforePrint: () => {
      console.log('Before');
  },
  onAfterPrint: async () => {
      setPdfBlob(null);
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);
      
      try {
         
          if (printdata) {
              const contentWidth = printdata.offsetWidth;
              const padding = 20; // Adjust the padding as needed
              const pdfWidth = contentWidth + 2 * padding; // Add padding to width
              const pdfHeight = contentWidth * 1.5; // Add padding to height
              const pdf = new jsPDF({
                  unit: 'px',
                  format: [pdfWidth, pdfHeight],
              });
              pdf.html(printdata, {
                  x: padding, // Set x-coordinate for content
                  y: padding, // Set y-coordinate for content
                  callback: () => {
                      const generatedPdfBlob = pdf.output('blob');
                      setPdfBlob(generatedPdfBlob);
                      console.log('generatedPdfBlob',generatedPdfBlob)
                      setIsPrintButtonVisible(true)
                  },
                  
              });
          } else {
              throw new Error('Unable to get the target element');
          }
          setpreview(false)
      } catch (error) {
          console.error('Error generating PDF:', error);
      }
  }
});






  const handleSubmit = () => {
    // Define required fields
    const requiredFields = [
      "visittype",
      "title",
      "patientname",
      "dob",
      "age",
      "gender",
      "PhoneNo",
    ];

    // Check if any of the required fields is empty
    const isFormValid = requiredFields.every(
      (field) => patientdetails[field].trim() !== ""
    );

    if (!isFormValid) {
      // If the form is not valid, alert the user or handle the error as needed
      alert("Please fill all required fields.");
      return; // Stop the function from proceeding
    }

    // If all validations pass, proceed with forming the data object
    const postpersonaldata = {
      visitid: visitid,
      visittype: patientdetails.visittype,
      title: patientdetails.title,
      patientname: patientdetails.patientname,
      fathername: patientdetails.fathername,
      dob: patientdetails.dob,
      age: patientdetails.age,
      gender: patientdetails.gender,
      maritalstatus: patientdetails.maritalstatus,
      Doorno: patientdetails.Doorno,
      Address: patientdetails.Address,
      State: patientdetails.State,
      PhoneNo: patientdetails.PhoneNo,
      Alternatephn: patientdetails.Alternatephn,
      Email: patientdetails.Email,
      phlebotomisttype: patientdetails.phlebotomisttype,
      phlebotomistname: patientdetails.phlebotomistname,
      Referingdoctor: patientdetails.Referingdoctor,
      Patientgroup: patientdetails.Patientgroup,
      Dispatchmethod: patientdetails.Dispatchmethod,
      Location: "KOCHI",
    };

    const postorderdetails = {
      ...orderdetails,
      invoiceno: invoiceno,
      barcode: barcode,
      patientid: patientid,
      visitid: visitid,
      patientname: patientdetails.patientname,
      Dispatchmethod: patientdetails.Dispatchmethod,
      location: "KOCHI",
    };

    const postbillingdetails = {
      ...orderdetails,
      invoiceno: invoiceno,
      barcode: barcode,
      itemcount: testdetails.length,
      patientid: patientid,
      visitid: visitid,
      patientname: patientdetails.patientname,
      Dispatchmethod: patientdetails.Dispatchmethod,
      location: "KOCHI",
    };

    setIsPrintButtonVisible(false);
    handlePrint()

    // Proceed to make the API call
    axios
      .post(
        `${urllink}Billing/inserpatientprofile`,
        postpersonaldata
      )
      .then((response) => {
        console.log(response);
        // Reset the form or handle the successful submission
        // Assuming setPatientDetails is a method to reset your form to initial state
        // setPatientDetails({
        //   visitid: "",
        //   visittype: "",
        //   title: "",
        //   patientname: "",
        //   fathername: "",
        //   dob: "",
        //   age: "",
        //   gender: "",
        //   maritalstatus: "",
        //   phlebotomisttype: "",
        //   phlebotomistname: "",
        //   Referingdoctor: "",
        //   Patientgroup: "",
        //   Doorno: "",
        //   Address: "",
        //   District: "",
        //   City: "",
        //   State: "",
        //   PhoneNo: "",
        //   Alternatephn: "",
        //   Email: "",
        //   Dispatchmethod: "",
        // });
      })

      .catch((error) => {
        console.log(error);
        // Handle the API error
      });

    axios
      .post(`${urllink}Billing/insertvisitid`, postpersonaldata)
      .then((response) => {
        console.log(response);
        // Reset the form or handle the successful submission
        // Assuming setPatientDetails is a method to reset your form to initial state
       
      })

      .catch((error) => {
        console.log(error);
        // Handle the API error
      });

    axios
      .post(
        `${urllink}Billing/insertbillinginvoice`,
        postbillingdetails
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((erorr) => {
        console.log(erorr);
      });

    axios
      .post(
        `${urllink}Billing/insertpaymentdetails`,
        postorderdetails
      )
      .then((response) => {
        console.log(response);
        setIsPrintButtonVisible(false);
        handlePrint()
        setType('PatientDetailCon')
        setPatientDetails({
          visitid: "",
          visittype: "",
          title: "",
          patientname: "",
          fathername: "",
          dob: "",
          age: "",
          gender: "",
          maritalstatus: "",
          phlebotomisttype: "",
          phlebotomistname: "",
          Referingdoctor: "",
          Patientgroup: "",
          Doorno: "",
          Address: "",
          District: "",
          City: "",
          State: "",
          PhoneNo: "",
          Alternatephn: "",
          Email: "",
          Dispatchmethod: "",
        });

        setorderdetails({
          testname: "",
          grossAmount: 0,
          discountType: "",
          discountValue: "",
          paymentMethod: "",
          receivedAmount: "",
          netAmount: 0,
          dueAmount: 0,
        });
        settestdetails([])
        setvisitid('')
      })
      .catch((error) => {
        console.log(error);
      });

    // BarcodeDisplay()

    dispatchvalue({ type: "Barcodedata", value: barcode });
    console.log(dispatchvalue);

    console.log(postpersonaldata); // For debugging
  };

  useEffect(() => {
    fetchTestNames();
  }, []); // Fetch test names once on component mount

  const fetchTestNames = () => {
    axios
      .get(`${urllink}usercontrol/getalltestname`)
      .then((response) => {
        setTestName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBillingModeChange = (event) => {
    const { value } = event.target;
    setPatientDetails({ ...patientdetails, testmethod: value });

    if (value === "Individual") {
      fetchTestNames();
    } else if (value === "Group") {
      // Fetch group test names
      axios
        .get(`${urllink}usercontrol/getallgroupname`)
        .then((response) => {
          console.log(response.data)
          setTestName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };



  


  const forPrintData = () => {
    return (
        <div className="billing-invoice" id="reactprintcontent">
            <div className="New_billlling_invoice_head">
                <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                </div>
                <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>

                        <span >{ClinicDetials.ClinicAddress},</span>
                        <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                    </div>
                    <div>
                        <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                        <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                        <span>{ClinicDetials.ClinicMailID}</span>
                    </div>

                </div>

            </div>
            <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
                Billing Invoice
            </div>
            <div className='Register_btn_con_barcode'>
            <Barcode value={barcode || ''} lineColor="black" height={40} width={1.2} fontSize={12} />
          </div>
           
            <div className="new_billing_address">
                <div className="new_billing_address_2">
                    <div className="new_billing_div">
                        <label>Patient Name <span>:</span></label>
                        <span>{patientdetails.patientname}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Visit ID <span>:</span></label>
                        <span>{visitid}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Age <span>:</span></label>
                        <span>{patientdetails.age}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Gender <span>:</span></label>
                        <span>{patientdetails.gender}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Address <span>:</span></label>
                        <span>{patientdetails.Address}</span>
                    </div>

                </div>
                
                <div className="new_billing_address_2">
                    <div className="new_billing_div">
                        <label>Invoice No <span>:</span></label>
                        <span>{invoiceno}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>GSTIN No <span>:</span></label>
                        <span>{ClinicDetials.ClinicGST}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Physician Name <span>:</span></label>
                        <span>{patientdetails.Referingdoctor}</span>
                    </div>
                    <div className="new_billing_div">
                        <label>Date <span>:</span></label>
                        <span>{Billing_date}</span>
                    </div>
                    <div className="new_billing_div">
                        <label> Patient Mobile No <span>:</span></label>
                        <span>{patientdetails.PhoneNo}</span>
                    </div>
                </div>
            </div>


            <div className="new_billing_invoice_detials">
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Test Method</th>
                            <th>Service Name</th>
                            {/* <th>Unit</th> */}
                            <th>Amount</th>
                            {/* <th>Discount</th>
                            <th>Total</th> */}
                        </tr>
                    </thead>
                    <tbody>

                    {testdetails.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>{" "}
                        <td>{row.test_code}</td>
                        <td>{row.test_name}</td>
                        
                        <td>{row.cost_per_reportable}</td>
                      
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: 'auto' }}>

                <div className="invoice_detials_total_1 neww_invoicedetials">
                    <div className="total_con_bill">
                        <div className="bill_body">
                            <label> Items Count <span>:</span></label>
                            <span>{testdetails.length}</span>
                        </div>
                        {/* <div className="bill_body">
                            <label> Unit <span>:</span></label>
                            <span>{orderdetails.totalUnits}</span>
                        </div> */}
                        <div className="bill_body">
                            <label> Amount <span>:</span></label>
                            <span>{orderdetails.grossAmount}</span>
                        </div>
                        <div className="bill_body">
                            <label> Discount <span>:</span></label>
                            <span>{initialState.totalDiscount}</span>
                        </div>
                        
                    </div>
                    <div className="total_con_bill">
                        <div className="bill_body">
                            <label> Billed By <span>:</span></label>
                            <span>admin</span>
                        </div>
                        

                      
                    
                        <div className="bill_body">
                            <label>Net Amount <span>:</span></label>
                            <span>{orderdetails.netAmount}</span>
                        </div>
                        <div className="bill_body">
                            <label> Payment type <span>:</span></label>
                            <span>{orderdetails.paymentMethod} </span>
                        </div>
                        <div className="bill_body">
                            <label> Recived <span>:</span></label>
                            <span>{(initialState.totalrecived)} </span>
                        </div>


                    </div>

                </div>
                <div className="invoice_detials_total_1 neww_invoicedetials">
                    <div className='total_con_bill'>
                        <div className="bill_body" style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
                            <label> Amount In Words<span>:</span></label>
                            <span style={{ color: 'grey' }}>{numberToWords(+orderdetails.netAmount)} </span>
                        </div>
                    </div>
                </div>

                <div className="signature-section909">
                    <p className='disclaimer23'>
                        This page is created automatically without a signature.
                    </p>
                </div>
            </div>

        </div>
    );
}





  return (

    <>

    {isPrintButtonVisible? (
    <div className="Supplier_Master_form_Container">
      <div className="Supplier_Master_form_Container_Header">
        <h3>Billing </h3>
      </div>
      {/* <BarcodeDisplay patientDetails={patientDetails} barcode={barcode} /> */}

      <div className="Supplier_Master_form_sub_con">
        <div className="Supplier_Master_form_sub_con_div">
          <div className="Supplier_Master_form_sub_con_div_1">
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
            >
              <ToggleButton
                value="PatientDetailCon" // Set the value prop here
                style={{
                  backgroundColor:
                    type === "PatientDetailCon"
                      ? "var(--ProjectColor)"
                      : "inherit",
                }}
                className="Supplier_Master_form_sub_con_div_1_toggle"
              >
                Patient Details
              </ToggleButton>
              <ToggleButton
                value="CommunicationAddressCon" // Set the value prop here
                style={{
                  backgroundColor:
                    type === "CommunicationAddressCon"
                      ? "var(--ProjectColor)"
                      : "inherit",
                }}
                className="Supplier_Master_form_sub_con_div_1_toggle"
              >
                Test Informations
              </ToggleButton>
            </ToggleButtonGroup>

            {type === "PatientDetailCon" ? (
              <div className="Supplier_Master_form_sub_con_div_1">
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="state"
                    >
                      Patient Name:
                    </label>
                    <div className="Supplier_Master_form_Con_div_Row_title_name">
                      <select
                        id="title"
                        name="title"
                        value={patientdetails.title}
                        onChange={handleInputChange}
                        className="Supplier_Master_form_Con_div_Row_inputtiltle"
                      >
                        <option value="">Title</option>
                        <option value="mr">Mr.</option>
                        <option value="ms">Ms.</option>
                        <option value="mrs">Mrs.</option>
                      </select>
                      <input
                        type="text"
                        name="patientname"
                        placeholder="Enter Patient Name"
                        value={patientdetails.patientname} 
                        onChange={handleInputChange}
                        required
                        className="Supplier_Master_form_Con_div_Row_input11"
                        // style={{ width: "180px" }}
                      />
                    </div>
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="street"
                    >
                      PhoneNo(WhatsApp) :
                    </label>
                    <span className="Supplier_Master_form_Con_div_Row_title_name">
                      {" "}
                      <input
                        type="number"
                        name="PhoneNo"
                        placeholder="Enter Phone No"
                        value={patientdetails.PhoneNo}
                        onChange={handleInputChange}
                        required
                        className="Supplier_Master_form_Con_div_Row_input211"
                      />
                       <button
                        className="searching_input_icon_billing"
                        onClick={handlepatientsearch}
                      >
                        <SearchIcon />
                      </button>
                     
                    </span>
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="state"
                    >
                      Visit Type:
                    </label>
                    <select
                      id="visittype"
                      name="visittype"
                      className="Supplier_Master_form_Con_div_Row_input"
                      value={patientdetails.visittype}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="inpatient">Inpatient</option>
                      <option value="outpatient">Outpatient</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="state"
                    >
                      Visit Id:
                    </label>
                    <input
                      type="text"
                      name="visitid"
                      placeholder="Enter Visit Id"
                      value={visitid}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="city"
                    >
                      Father's Name:
                    </label>
                    <input
                      type="text"
                      name="fathername"
                      placeholder="Enter Father's Name"
                      value={patientdetails.fathername}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>

                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="place"
                    >
                      Date Of Birth:
                    </label>
                    <input
                      type="Date"
                      name="dob"
                      value={patientdetails.dob}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="street"
                    >
                      Age:
                    </label>
                    <input
                      type="number"
                      name="age"
                      placeholder="Enter Age"
                      value={patientdetails.age}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>

                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="email"
                    >
                      Gender:
                    </label>
                    <input
                      type="gender"
                      name="gender"
                      placeholder="Enter Gender"
                      value={patientdetails.gender}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>

                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone2"
                    >
                      Marital Status:
                    </label>
                    <select
                      id="phlebotomist"
                      name="maritalstatus"
                      value={patientdetails.maritalstatus}
                      onChange={handleInputChange}
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select</option>
                      <option value="Married">Married</option>
                      <option value="Unmarried">Unmarried</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="country"
                    >
                      Door No :
                    </label>
                    <input
                      type="text"
                      name="Doorno"
                      placeholder="Enter Door No"
                      value={patientdetails.Doorno}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>

                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="state"
                    >
                      Address :
                    </label>
                    <textarea
                      id="address"
                      name="Address"
                      value={patientdetails.Address}
                      onChange={handleInputChange}
                      placeholder="Enter your Address"
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    ></textarea>
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="street"
                    >
                      State :
                    </label>
                    <input
                      type="text"
                      name="State"
                      placeholder="Enter State"
                      value={patientdetails.State}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="street"
                    >
                      Alternate PhoneNo :
                    </label>
                    <input
                      type="text"
                      name="Alternatephn"
                      placeholder="Enter Alternate PhoneNo"
                      value={patientdetails.Alternatephn}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="street"
                    >
                      Email :
                    </label>
                    <input
                      type="email"
                      name="Email"
                      placeholder="Enter Email"
                      value={patientdetails.Email}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="Supplier_Master_form_sub_con_div_1">
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone1"
                    >
                      Phlebotomist Type:
                    </label>
                    <select
                      id="phlebotomist"
                      name="phlebotomisttype"
                      value={patientdetails.phlebotomisttype}
                      onChange={handleInputChange}
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select</option>
                      <option value="in-house">In-House</option>
                      <option value="pbt">Home-collection</option>
                      {/* <option value="others">Others.</option> */}
                    </select>
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone1"
                    >
                      Phlebotomist Name:
                    </label>
                    <select
                      id="phlebotomist"
                      name="phlebotomistname"
                      value={patientdetails.phlebotomistname}
                      onChange={handleInputChange}
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select</option>
                      <option value="in">In-pilo.</option>
                      <option value="pbt">PBT-ONE.</option>
                      <option value="others">Others.</option>
                    </select>
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone2"
                    >
                      Refering Doctor:
                    </label>
                    <input
                      type="text"
                      name="Referingdoctor"
                      placeholder="Enter Reference Doctor"
                      value={patientdetails.Referingdoctor}
                      onChange={handleInputChange}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone2"
                    >
                      Patient Group:
                    </label>
                    <select
                      id="patientGroup"
                      name="Patientgroup"
                      value={patientdetails.Patientgroup}
                      onChange={handleInputChange}
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select </option>
                      <option value="normal">Normal(Disc )%.</option>
                      <option value="vip">Vip(Disc )%.</option>
                      <option value="guest">Guest(Disc )%.</option>
                    </select>
                  </div>
                </div>
                <div className="Supplier_Master_form_Con_Row">
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="phone2"
                    >
                      Dispatch Method:
                    </label>
                    <select
                      id="dispatchMode"
                      name="Dispatchmethod"
                      value={patientdetails.Dispatchmethod}
                      onChange={handleInputChange}
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select</option>
                      <option value="sms">SMS</option>
                      <option value="wp">whatsapp</option>
                      <option value="email">EMAIL</option>
                      <option value="courier">Courier</option>
                    </select>
                  </div>
                </div>
                <ToggleButton
                  value="orderdetails"
                  style={{
                    color: "black",
                    background: "var(--ProjectColor)",
                    font: "14px",
                  }}
                  className="Supplier_Master_form_sub_con_div_1_toggle"
                >
                  Billing Informations
                </ToggleButton>
                <div className="Supplier_Master_form_sub_con_div_1">
                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Billing Mode:
                      </label>
                      <select
                        id="testmethod"
                        name="testmethod"
                        className="Supplier_Master_form_Con_div_Row_input"
                        value={patientdetails.testmethod}
                        onChange={handleBillingModeChange}
                      >
                        <option value="">Select</option>
                        <option value="Individual">Individual</option>
                        <option value="Group">Group</option>
                      </select>
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Test Name:
                      </label>
                      <div className="autocompleted_div" >
                        <Autocomplete
                          freeSolo
                          id="test-name-autocomplete"
                          disableClearable
                          // style={{ width: "220px" }}
                          className="auto_completed_solo"
                          name="testname"
                          onChange={handleInputChange12}
                          value={orderdetails.testname}
                          // className='Supplier_Master_form_Con_div_Row_input'
                          options={testname.map((option) => option)} // Map your testName array to the options prop
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                className:
                                  "Supplier_Master_form_Con_div_Row_input_autocomplete",
                              }}
                            />
                          )}
                        />
                        <span>
                          <AddCardIcon onClick={handleSearch} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="Supplier_Master_form_sub_con_div">
          <div className="Supplier_Master_form_sub_con_div_1">
            <ToggleButton
              value="orderdetails"
              style={{
                color: "black",
                background: "var(--ProjectColor)",
                font: "14px",
              }}
              className="Supplier_Master_form_sub_con_div_1_toggle"
            >
              Order Details
            </ToggleButton>

            <div className="Supplier_Master_form_sub_con_div_1">
              <div
                className="Selected-table-container"
                style={{ marginBottom: "50px" }}
              >
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th> Code</th>
                      <th>Name</th>
                      {/* <th>Ordered Time</th>
                      <th>TAT Time</th> */}
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testdetails.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>{" "}
                        <td>{row.test_code}</td>
                        <td>{row.test_name}</td>
                        {/* <td>Ordered Time </td>{" "}
                        <td>TAT Time </td>{" "} */}
                        <td>{row.cost_per_reportable}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Supplier_Master_form_Con_Row">
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="remarks"
                  >
                    Gross Amount:
                  </label>
                  <input
                    type="text"
                    name="grossAmount"
                    placeholder="Enter Gross Amount"
                    value={orderdetails.grossAmount}
                    onChange={handleInputChange1}
                    required
                    className="Supplier_Master_form_Con_div_Row_input"
                  />
                </div>
              </div>
              <div className="Supplier_Master_form_Con_Row">
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="shortName"
                  >
                    Discount Type:
                  </label>
                  <select
                    id="discountType"
                    name="discountType"
                    value={orderdetails.discountType}
                    onChange={handleInputChange1}
                    className="Supplier_Master_form_Con_div_Row_input"
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="webSite"
                  >
                    Discount:
                  </label>
                  <input
                    type="text"
                    name="discountValue"
                    placeholder="Enter Discount"
                    value={orderdetails.discountValue}
                    onChange={handleInputChange1}
                    required
                    className="Supplier_Master_form_Con_div_Row_input"
                  />
                </div>
              </div>

              <div className="Supplier_Master_form_Con_Row">
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="shortName"
                  >
                    Payment Mode:
                  </label>
                  <select
                    id="dispatchMode"
                    name="paymentMethod"
                    value={orderdetails.paymentMethod}
                    onChange={handleInputChange1}
                    className="Supplier_Master_form_Con_div_Row_input"
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Card">Card</option>
                    <option value="OnlinePayment">Online Payment</option>
                  </select>
                </div>
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="webSite"
                  >
                    Received Amount:
                  </label>
                  <input
                    type="text"
                    name="receivedAmount"
                    placeholder="Enter Received Amount"
                    value={orderdetails.receivedAmount}
                    onChange={handleInputChange1}
                    required
                    className="Supplier_Master_form_Con_div_Row_input"
                  />
                </div>
              </div>

              <div className="Supplier_Master_form_Con_Row">
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="shortName"
                  >
                    Net Amount:
                  </label>
                  <input
                    type="text"
                    name="netAmount"
                    placeholder="Enter Net Amount"
                    value={orderdetails.netAmount}
                    onChange={handleInputChange1}
                    required
                    className="Supplier_Master_form_Con_div_Row_input"
                  />
                </div>
                <div className="Supplier_Master_form_Con_div_Row1">
                  <label
                    className="Supplier_Master_form_Con_div_Row_label"
                    htmlFor="webSite"
                  >
                    Due Amount:
                  </label>
                  <input
                    type="text"
                    name="dueAmount"
                    placeholder="Enter Balance Amount"
                    value={orderdetails.dueAmount}
                    onChange={handleInputChange1}
                    required
                    className="Supplier_Master_form_Con_div_Row_input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Supplier_Master_form_Save">
        <button
          className="Supplier_Master_form_Save_button"
          onClick={handleSubmit}
        >
          Print
        </button>
      </div>
    </div>): forPrintData()}
    </>
  );
}

export default Billing;
