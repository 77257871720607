import React from 'react'

function HrDashboard() {
  return (
    <div className='appointment'>
      <div className='h_head'>
        <h4>HrDashboard</h4>
      </div>
    </div>
  )
}

export default HrDashboard