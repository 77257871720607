import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyConcentForm() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    fullname: "",
    DOB: "",
    gender: "",
    phone: "",
    Address: "",
    doctorName: "",
    conditionExplained: "",
    procedures: "",
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });
  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  const handleSave = () => {

    const canvasData = signatureRef.current.toDataURL()

    // console.log('canvasData',canvasData)

    const data = {
      ...ConcernformData,
      canvasData: canvasData,
      Createby: userRecord.username
    };

    // If IsCategoryEdit is true, it means we are updating an existing category
    axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          successMsg(response.data.message);
        } else {
          userwarn(response.data.Exists);
        }

      })
      .catch((error) => {
        console.log(error);
      });

  };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('fullname', ConcernformData.fullname);
              formData.append('DOB', ConcernformData.DOB);
              formData.append('gender', ConcernformData.gender);
              formData.append('phone', ConcernformData.phone);
              formData.append('Address', ConcernformData.Address);
              formData.append('doctorName', ConcernformData.doctorName);
              formData.append('conditionExplained', ConcernformData.conditionExplained);
              formData.append('procedures', ConcernformData.procedures);
              formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent no-print" id="reactprintcontent">
        <br />
        <div>
          <div className="paymt-fr-mnth-slp">
            <div className="logo-pay-slp">
              <img src={clinicLogo} alt="" />
            </div>
            <div>
              <h2>
                {clinicName} ({location})
              </h2>
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <h4>Consent Form</h4>
          </div>
        </div>
        <h4
          style={{
            color: "var(--labelcolor)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "start",
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            height: "10px",
          }}
        >
          Personal Information
        </h4>



        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent">
            <label htmlFor="fullname">
              Full Name (Capital)<span>:</span>
            </label>
            <input
              type="text"
              id="fullname"
              name="fullname"
              value={ConcernformData.fullname}
              onChange={handleChange}
              required
            />
          </div>

          <div className="RegisForm_1_consent_consent">
            <label>
              {" "}
              Date Of Birth <span>:</span>{" "}
            </label>
            <input
              type="date"
              name="DOB"
              value={ConcernformData.DOB}
              onChange={handleChange}
              required
            />
          </div>

          <div className="RegisForm_1_consent_consent">
            <label>
              Gender <span>:</span>
            </label>
            <div className="RegisForm_1_consent_consent_radiooo_head35r">
              <div className="RegisForm_1_consent_consent_radiooo female4d">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="male"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                />
                <label htmlFor="male"> Male </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo female4eed">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                />
                <label htmlFor="female"> Female </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo transgender98">
                <input
                  type="radio"
                  id="transgender"
                  name="gender"
                  value="TransGender"
                  className="consent_consent_radiooo_inputt"
                  onChange={handleChange}
                />
                <label htmlFor="transgender"> TransGender </label>
              </div>
            </div>
          </div>

          <div className="RegisForm_1_consent_consent">
            <label>
              Phone <span>:</span>
            </label>
            <input
              type="number"
              name="phone"
              pattern="[0-9]*"
              value={ConcernformData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="RegisForm_1_consent_consent">
            <label>
              Address <span>:</span>
            </label>
            <textarea
              name="Address"
              value={ConcernformData.Address}
              onChange={handleChange}
              required
            ></textarea>
          </div>
        </div>

        <br />

        <h4
          style={{
            color: "var(--labelcolor)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "start",
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            height: "10px",
          }}
        >
          Procedure Information
        </h4>


        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent">
            <label>
              Doctor's Name <span>:</span>
            </label>
            <input
              type="text"
              name="doctorName"
              value={ConcernformData.doctorName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="RegisForm_1_consent_consent">
            <label>
              Doctor has Explained me the following condition in my case{" "}
              <span>:</span>
            </label>
            <textarea
              type="text"
              name="conditionExplained"
              value={ConcernformData.conditionExplained}
              onChange={handleChange}
              required
            />
          </div>

          <div className="RegisForm_1_consent_consent">
            <label>
              I understand that the procedure(s) proposed for evaluating and
              treating my condition is / are <span>:</span>
            </label>
            <textarea
              type="text"
              name="procedures"
              value={ConcernformData.procedures}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <br />
        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent_p">
            <p>
              • Just as there may be benefits to the procedure(s) proposed, I
              also understand that medical and surgical procedures involve
              risks. These risks include allergic reaction, bleeding, blood
              clots, infections, adverse side effects of drugs, scarring
              (including keloid scarring - a type of scar which may extend
              beyond the dimensions of the original wound).{" "}
            </p>
            <p>
              • I am aware that in the practice of medicine, other unexpected
              risks or complications not discussed may occur. I also understand
              that during the course of the proposed procedure(s) unforeseen
              conditions may be revealed.
            </p>
            <p>
              • I understand what has been discussed with me as well as the
              contents of this consent form, and have been given the opportunity
              to ask questions and have received satisfactory answers.{" "}
            </p>

            <p>
              Having read this form and talked with the physicians, my signature
              below acknowledges that: I voluntarily give my authorisation and
              consent to the performance of the procedure(s) described above by
              my physician and/or his/her associates assisted by medical centre
              personnel and other trained persons as well as the presence of
              observers.
            </p>
          </div>
        </div>
        <br />

        <div className=" sigCanvas2_head11">
          <div className="RegisForm_1_consent_consent sigCanvas2_head">
            <div>
              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{
                  width: 200,
                  height: 100,
                  className: "sigCanvas2",
                }}
              />
            </div>
            <h5>Patient Signature</h5>

            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={clearSignature}>
                Clear
              </button>
              <button className="RegisterForm_1_btns" onClick={saveSignature}>
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyConcentForm;
