import { React, useState, useRef, useEffect } from "react";
import "./Registration.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Webcam from "react-webcam";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import profileImg from "../assets/profileimg.jpeg";
import { differenceInYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

const RegistrationForm = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const registerdata = useSelector((state) => state.userRecord?.registerdata);

  const forpatienteedit = useSelector(
    (state) => state.userRecord?.forpatienteedit
  );

  // const [FormData1,setFormData1] = useState([])
  // setFormData1(registerdata.formData)

  const navigate = useNavigate();
  // const webcamRef = useRef(null);
  const webcamRef1 = useRef(null);
  const webcamRef2 = useRef(null);
  // const webcamRef3 = useRef(null);
  const [expanded, setExpanded] = useState(false);
  // const [showCamera, setShowCamera] = useState(false);
  const [userRecord, setUserRecord] = useState([]);
  console.log(userRecord);

  useEffect(() => {
    const userRecord = localStorage.getItem("token");

    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      console.log("decodedTokenData", decodedTokenData);
      setUserRecord(decodedTokenData);
    }
  }, []);

  const [showFile, setShowFile] = useState({
    file1: false,
    file2: false,
    file3: false,
  });
  const [InsuranceName, setInsuranceName] = useState("");
  const [ClinentName, setClinentName] = useState("");
  const [isImageCaptured1, setIsImageCaptured1] = useState(false);
  const [isImageCaptured2, setIsImageCaptured2] = useState(false);
  // const [isImageCaptured3, setIsImageCaptured3] = useState(false);

  // for personal info
  const [formData1, setFormData1] = useState({
    PatientID: "",
    Title: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    DOB: "",
    Age: "",
    Nationality: "",
    UniqueIDType: "",
    UniqueID: "",
    PhoneNo: "",
    MobileNo: "",
    BloodGroup: "",
    EmailId: "",
    Organization: "",
    EmployeeID: "",
    Designation: "",
    Place: "",
    PatientType: "",
    PatientCategory: "",
    ClinentName: "",
    InsuranceName: "",
    InsuranceNo: "",
    RateCardType: "",
    location: "",
  });

  // -------------------------------------
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);

  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }

    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;

    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };

  useEffect(() => {
    // Use an axios request within useEffect to avoid infinite rendering
    axios
      .get("https://vesoftometic.co.in/patientmanagement/detect_device")
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  //   --------------------------
  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/usercontrol/get_Insurance_client?location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        if (data) {
          if (data.ClientName) {
            setClinentName(data.ClientName);
          }
          if (data.InsuranceName) {
            setInsuranceName(data.InsuranceName);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(ClinentName);
  console.log(InsuranceName);
  // setFormData1(registerdata)

  console.log(formData1);
  // for emergency
  const [formData2, setFormData2] = useState({
    EmergencyName: "",
    RelationShip: "",
    EmergencyPhoneNo: "",
    location: userRecord.location,
  });
  // for documents
  const [formData3, setFormData3] = useState({
    CapturedImage1: null,
    CapturedImage2: null,
    CapturedFile1: null,
    CapturedFile2: null,
    location: userRecord.location,
  });
  const [formData4, setFormData4] = useState({
    DoorNo: "",
    Street: "",
    Area: "",
    City: "",
    State: "",
    Country: "",
    PinCode: "",
    location: userRecord.location,
  });
  const [formData5, setFormData5] = useState({
    permanentDoorNo: "",
    permanentStreet: "",
    permanentArea: "",
    permanentCity: "",
    permanentState: "",
    permanentCountry: "",
    permanentPinCode: "",
    location: userRecord.location,
  });

  // useEffect(() => {
  //     // Check if registerdata is available
  //     console.log('registerdata',registerdata)
  //     if (registerdata) {
  //         // Update formData1 with values from registerdata
  //         setFormData1({
  //             PatientID: "",
  //     Gender: "",
  //     DOB: "",
  //     Age: "",
  //     Nationality: "",
  //     UniqueIDType: "",
  //     UniqueID: "",
  //     MobileNo: "",
  //     BloodGroup: "",
  //     EmailId: "",
  //     Organization: "",
  //     EmployeeID: "",
  //     Designation: "",
  //     Place: "",
  //     PatientType: "",
  //     PatientCategory: "",
  //     ClinentName: "",
  //     InsuranceName: "",
  //     InsuranceNo: "",
  //     RateCardType: "",
  //             Title: registerdata.formData?.title || "",
  //             FirstName: registerdata.formData?.firstName || "",
  //             LastName: registerdata.formData?.lastName || "",
  //             PhoneNo: registerdata.formData?.phone || "",
  //             EmailId: registerdata.formData?.email || "",
  //             location: userRecord.location,
  //         });
  //         setFormData2({
  //             EmergencyName: "",
  //             RelationShip: "",
  //             EmergencyPhoneNo: "",
  //             location: userRecord.location,
  //         });
  //         setFormData3({
  //             CapturedImage1: null,
  //             CapturedImage2: null,
  //             CapturedFile1: null,
  //             CapturedFile2: null,
  //             location: userRecord.location,
  //         });

  //         setFormData4({
  //             DoorNo: "",
  //             Street: "",
  //             Area: "",
  //             City: "",
  //             State: "",
  //             Country: "",
  //             PinCode: "",
  //             location: userRecord.location,
  //         });
  //         setFormData5({
  //             permanentDoorNo: "",
  //             permanentStreet: "",
  //             permanentArea: "",
  //             permanentCity: "",
  //             permanentState: "",
  //             permanentCountry: "",
  //             permanentPinCode: "",
  //             location: userRecord.location,
  //         });
  //     }else{

  //     }
  // }, [registerdata.formData, registerdata]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;

    if (name === "MobileNo" || name === "PhoneNo") {
      if (name === "PhoneNo") {
        const newval = value.length;
        if (newval <= 10) {
          setFormData1((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          alert("Phone No must contain 10 digits");
        }
      } else if (name === "MobileNo") {
        const newval = value.length;
        if (newval <= 10) {
          setFormData1((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        } else {
          alert("Mobile No must contain 10 digits");
        }
      }
    } else if (name === "DOB") {
      const newDate = new Date();

      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        Age: age,
      }));
    } else {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "EmergencyPhoneNo") {
      const newval = value.length;
      if (newval <= 10) {
        setFormData2((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert("Phone No must contain 10 digits");
      }
    } else {
      setFormData2((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setFormData4((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleInputChange4 = (e) => {
    const { name, value } = e.target;
    setFormData5((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  console.log(formData3);
  // console.table(formData5)

  const handleRecaptureImage1 = () => {
    // setCapturedImage(null);
    setFormData3((prev) => ({
      ...prev,
      CapturedImage1: null,
    }));
    setIsImageCaptured1(false);
  };

  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    setFormData3({
      ...formData3,
      CapturedImage1: imageSrc
        ? dataURItoBlob(imageSrc, "captured_image.jpg")
        : null,
      CapturedFile1: null,
    });
    setIsImageCaptured1(true);
  };
  const handleRecaptureImage2 = () => {
    // setCapturedImage(null);
    setFormData3((prev) => ({
      ...prev,
      CapturedImage2: null,
    }));
    setIsImageCaptured2(false);
  };

  const handleCaptureImage2 = () => {
    const imageSrc = webcamRef2.current.getScreenshot();
    setFormData3({
      ...formData3,
      CapturedImage2: imageSrc
        ? dataURItoBlob(imageSrc, "captured_image.jpg")
        : null,
      CapturedFile2: null,
    });
    setIsImageCaptured2(true);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  //  for file....
  const handleInputFileChange = (event, inputName) => {
    const file = event.target.files[0];

    if (inputName === "CapturedFile1") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage1: null,
      });
    } else if (inputName === "CapturedFile2") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage2: null,
      });
    } else if (inputName === "CapturedFile3") {
      setFormData3({
        ...formData3,
        [inputName]: file,
        CapturedImage3: null,
      });
    }
  };
  // console.log(formData3)

  useEffect(() => {
    if (forpatienteedit && forpatienteedit.length > 0) {
      const updatedFormData = { ...(forpatienteedit[0] || []) };
      const updatedFormdata2 = { ...(forpatienteedit[1] || []) };
      const updatedFormdata4 = { ...(forpatienteedit[3] || []) };
      const updatedFormdata5 = { ...(forpatienteedit[4] || []) };
      // setFormData3(updatedFormdata3);

      // Convert base64 strings to File objects
      console.log(forpatienteedit[2]);
      const capturedImage1 = base64toFile(
        forpatienteedit[2].CapturedImage1,
        "CapturedImage2.jpg",
        "image/jpeg"
      );
      const CapturedFile2 = base64toFile(
        forpatienteedit[2]?.CapturedFile1,
        "CapturedFile2.pdf",
        "application/pdf"
      );

      setFormData3({
        CapturedFile1: capturedImage1,
        CapturedFile2: CapturedFile2,
        // location: userRecord.location,
      });

      updatedFormData.createdby = userRecord.username;
      updatedFormData.branchlocation = userRecord.location;

      setFormData1(updatedFormData);
      setFormData2(updatedFormdata2);
      setFormData4(updatedFormdata4);
      setFormData5(updatedFormdata5);
    } else if (registerdata && registerdata.formData) {
      // Update formData1 with values from registerdata
      setFormData1({
        PatientID: "",
        Gender: "",
        DOB: "",
        Age: "",
        Nationality: "",
        UniqueIDType: "",
        UniqueID: "",
        MobileNo: "",
        BloodGroup: "",
        EmailId: "",
        Organization: "",
        EmployeeID: "",
        Designation: "",
        Place: "",
        PatientType: "",
        PatientCategory: "",
        ClinentName: "",
        InsuranceName: "",
        InsuranceNo: "",
        RateCardType: "",
        Title: registerdata.formData?.title || "",
        FirstName: registerdata.formData?.firstName || "",
        LastName: registerdata.formData?.lastName || "",
        PhoneNo: registerdata.formData?.phone || "",
        EmailId: registerdata.formData?.email || "",
        location: userRecord.location,
      });
      setFormData2({
        EmergencyName: "",
        RelationShip: "",
        EmergencyPhoneNo: "",
        location: userRecord.location,
      });
      setFormData3({
        CapturedImage1: null,
        CapturedImage2: null,
        CapturedFile1: null,
        CapturedFile2: null,
        location: userRecord.location,
      });

      setFormData4({
        DoorNo: "",
        Street: "",
        Area: "",
        City: "",
        State: "",
        Country: "",
        PinCode: "",
        location: userRecord.location,
      });
      setFormData5({
        permanentDoorNo: "",
        permanentStreet: "",
        permanentArea: "",
        permanentCity: "",
        permanentState: "",
        permanentCountry: "",
        permanentPinCode: "",
        location: userRecord.location,
      });
    } else {
      cleardata();
    }
  }, [forpatienteedit, registerdata.formData, registerdata]);

  const buttonText =
    forpatienteedit && forpatienteedit.length > 0 ? "Update" : "Register";

  const cleardata = () => {
    setFormData1({
      Title: "",
      FirstName: "",
      LastName: "",
      Gender: "",
      DOB: "",
      Age: "",
      Nationality: "",
      UniqueIDType: "",
      UniqueID: "",
      PhoneNo: "",
      MobileNo: "",
      BloodGroup: "",
      EmailId: "",
      Organization: "",
      EmployeeID: "",
      Designation: "",
      Place: "",
      PatientType: "",
      PatientCategory: "",
      ClinentName: "",
      InsuranceName: "",
      InsuranceNo: "",
      RateCardType: "",
      location: "",
    });
    setFormData2({
      EmergencyName: "",
      RelationShip: "",
      EmergencyPhoneNo: "",
      location: userRecord.location,
    });
    setFormData3({
      CapturedImage1: null,
      CapturedImage2: null,
      CapturedFile1: null,
      CapturedFile2: null,
      location: userRecord.location,
    });

    setFormData4({
      DoorNo: "",
      Street: "",
      Area: "",
      City: "",
      State: "",
      Country: "",
      PinCode: "",
      location: userRecord.location,
    });
    setFormData5({
      permanentDoorNo: "",
      permanentStreet: "",
      permanentArea: "",
      permanentCity: "",
      permanentState: "",
      permanentCountry: "",
      permanentPinCode: "",
      location: userRecord.location,
    });
  };

  const handlePost = () => {
    if (formData1.Nationality === "International") {
      const newFormData = {
        Title: formData1.Title,
        FirstName: formData1.FirstName,
        LastName: formData1.LastName,
        Gender: formData1.Gender,
        DOB: formData1.DOB,
        Age: formData1.Age,
        Nationality: formData1.Nationality,
        UniqueIDType: formData1.UniqueIDType,
        UniqueID: formData1.UniqueID,
        PhoneNo: +formData1.PhoneNo,
        MobileNo: +formData1.MobileNo,
        BloodGroup: formData1.BloodGroup,
        EmailId: formData1.EmailId,
        Organization: formData1.Organization,
        EmployeeID: formData1.EmployeeID,
        Designation: formData1.Designation,
        Place: formData1.Place,
        PatientType: formData1.PatientType,
        PatientCategory: formData1.PatientCategory,
        ClientName: formData1.ClinentName,
        InsuranceName: formData1.InsuranceName,
        InsuranceNo: formData1.InsuranceNo,
        RateCardType: formData1.RateCardType,
        EmergencyName: formData2.EmergencyName,
        RelationShip: formData2.RelationShip,
        EmergencyPhoneNo: formData2.EmergencyPhoneNo,
        PatientPhoto: formData3.CapturedFile1 || formData3.CapturedImage1,
        UniqueIDFile: formData3.CapturedFile2 || formData3.CapturedImage2,
        // ConcernForm: formData3.CapturedFile3 || formData3.CapturedImage3,
        DoorNo: formData4.DoorNo,
        Street: formData4.Street,
        Area: formData4.Area,
        City: formData4.City,
        State: formData4.State,
        Country: formData4.Country,
        PinCode: formData4.PinCode,
        permanentDoorNo: formData5.permanentDoorNo,
        permanentStreet: formData5.permanentStreet,
        permanentArea: formData5.permanentArea,
        permanentCity: formData5.permanentCity,
        permanentState: formData5.permanentState,
        permanentCountry: formData5.permanentCountry,
        permanentPinCode: formData5.permanentPinCode,
        location: userRecord.location,
        email: userRecord.email,
        createdby: userRecord.username,
      };
      // Define the array of required fields
      const requiredFields = [
        "Title",
        "FirstName",
        "LastName",
        "Gender",
        "Nationality",
        "UniqueIDType",
        "UniqueID",
        "PhoneNo",
        "UniqueIDFile",
        "PatientType",
        "PatientCategory",
        "RateCardType",
      ];
      const index = requiredFields.indexOf("PatientCategory");
      if (index !== -1) {
        if (newFormData.PatientCategory === "Cilent") {
          const newFields = ["ClientName"];
          requiredFields.splice(index + 1, 0, ...newFields);
        }
        if (newFormData.PatientCategory === "Insurance") {
          requiredFields.append("InsuranceName", "InsuranceNo");
          const newFields = ["InsuranceName", "InsuranceNo"];
          requiredFields.splice(index + 1, 0, ...newFields);
        }
      }

      // Find the empty fields
      const emptyFields = requiredFields.filter((field) => !newFormData[field]);

      if (emptyFields.length > 0) {
        warnmessage(
          `The following fields are required: ${emptyFields.join(", ")}`
        );
        return;
      } else {
        const updatedFormdata = new FormData();
        for (const key in newFormData) {
          if (newFormData.hasOwnProperty(key)) {
            updatedFormdata.append(key, newFormData[key]);
          }
        }
        console.table(updatedFormdata);
        axios
          .post(
            `https://vesoftometic.co.in/patientmanagement/insert_international_patient_registerdata`,
            updatedFormdata,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response);
            successMsg("Saved Successfully");
            setTimeout(() => {
              // navigate("/Home/Appoinment-RequestList");
              navigate("/Home/Patient-List-update");
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      const newFormData1 = {
        PatientID: formData1.PatientID || "",
        Title: formData1.Title || "",
        FirstName: formData1.FirstName || "",
        LastName: formData1.LastName || "",
        Gender: formData1.Gender || "",
        DOB: formData1.DOB || "",
        Age: formData1.Age || "",
        Nationality: formData1.Nationality || "",
        UniqueIDType: formData1.UniqueIDType || "",
        UniqueID: formData1.UniqueID || "",
        PhoneNo: formData1.PhoneNo || "",
        MobileNo: formData1.MobileNo || "",
        BloodGroup: formData1.BloodGroup || "",
        EmailId: formData1.EmailId || "",
        Organization: formData1.Organization || "",
        EmployeeID: formData1.EmployeeID || "",
        Designation: formData1.Designation || "",
        Place: formData1.Place || "",
        PatientType: formData1.PatientType || "",
        PatientCategory: formData1.PatientCategory || "",
        ClientName: formData1.ClinentName || "",
        InsuranceName: formData1.InsuranceName || "",
        InsuranceNo: formData1.InsuranceNo || "",
        RateCardType: formData1.RateCardType || "",
        EmergencyName: formData2.EmergencyName || "",
        RelationShip: formData2.RelationShip || "",
        EmergencyPhoneNo: formData2.EmergencyPhoneNo || "",
        PatientPhoto: formData3.CapturedFile1 || formData3.CapturedImage1 || "",
        UniqueIDFile: formData3.CapturedFile2 || formData3.CapturedImage2 || "",
        // ConcernForm: formData3.CapturedFile3 || formData3.CapturedImage3 || '',
        DoorNo: formData4.DoorNo || "",
        Street: formData4.Street || "",
        Area: formData4.Area || "",
        City: formData4.City || "",
        State: formData4.State || "",
        Country: formData4.Country || "",
        PinCode: formData4.PinCode || "",
        location: userRecord.location || "",
        email: userRecord.email || "",
        createdby: userRecord.usernam || "",
      };
      console.log(newFormData1);
      // Define the array of required fields
      const requiredFields = [
        "Title",
        "FirstName",
        "LastName",
        "Gender",
        "Nationality",
        "PhoneNo",
        "PatientType",
        "PatientCategory",
        "RateCardType",
      ];

      const index = requiredFields.indexOf("PatientCategory");
      if (index !== -1) {
        if (newFormData1.PatientCategory === "Cilent") {
          const newFields = ["ClientName"];
          requiredFields.splice(index + 1, 0, ...newFields);
        }
        if (newFormData1.PatientCategory === "Insurance") {
          requiredFields.append("InsuranceName", "InsuranceNo");
          const newFields = ["InsuranceName", "InsuranceNo"];
          requiredFields.splice(index + 1, 0, ...newFields);
        }
      }
      // Find the empty fields
      const emptyFields = requiredFields.filter(
        (field) => !newFormData1[field]
      );

      if (emptyFields.length > 0) {
        warnmessage(
          `The following fields are required: ${emptyFields.join(", ")}`
        );
        return;
      } else {
        const updatedFormdata1 = new FormData();
        console.log(updatedFormdata1, "---");

        for (const key in newFormData1) {
          // console.log(key,":",newFormData1[key])
          if (newFormData1.hasOwnProperty(key)) {
            updatedFormdata1.append(key, newFormData1[key]);
          }
        }
        console.log(updatedFormdata1);
        axios
          .post(
            `https://vesoftometic.co.in/patientmanagement/insert_local_patient_registerdata`,
            updatedFormdata1,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log(response);
            successMsg("Saved Successfully");
            setTimeout(() => {
              // navigate("/Home/Appoinment-RequestList");
              navigate("/Home/Patient-List-update");
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const warnmessage = (warnmsg) => {
    toast.warn(`${warnmsg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  return (
    <div>
      <Accordion
        expanded={
          expanded !== "panel2" &&
          expanded !== "panel3" &&
          expanded !== "panel4" &&
          expanded !== "panel5"
        }
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Personal Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="Title">
                  Title <span>:</span>
                </label>
                <select
                  name="Title"
                  value={formData1.Title}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Baby">Baby</option>
                  <option value="TransGender">TransGender</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  First Name <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the First Name"
                  name="FirstName"
                  value={formData1.FirstName}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Last Name <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Last Name"
                  name="LastName"
                  value={formData1.LastName}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="Title">
                  Gender <span>:</span>
                </label>
                <select
                  name="Gender"
                  value={formData1.Gender}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="TransGender">TransGender</option>
                </select>
              </div>

              {/* <div className="RegisFormcon"> */}
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Date Of Birth <span>:</span>{" "}
                </label>
                <input
                  type="date"
                  // placeholder="Enter the Date Of Birth"
                  name="DOB"
                  value={formData1.DOB}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Age <span>:</span>{" "}
                </label>
                <input
                  type="number"
                  // placeholder="Enter the Age"
                  name="Age"
                  value={formData1.Age}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Blood Group <span>:</span>{" "}
                </label>
                <select
                  id="bloodGroup"
                  name="BloodGroup"
                  value={formData1.BloodGroup}
                  onChange={handleInputChange1}
                  // placeholder="Select Blood Group"
                >
                  <option value="">Select</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label htmlFor="Nationality">
                  Nationality <span>:</span>
                </label>
                <select
                  name="Nationality"
                  value={formData1.Nationality}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="Indian">Indian</option>
                  <option value="International">International</option>
                </select>
              </div>
              {/* </div> */}

              {/* <div className="RegisFormcon"> */}
              <div className="RegisForm_1">
                <label htmlFor="UniqueId">
                  Unique ID Type <span>:</span>
                </label>
                <select
                  name="UniqueIDType"
                  value={formData1.UniqueIDType}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  {formData1.Nationality === "Indian" && (
                    <>
                      <option value="AadharCard">Aadhar Card</option>
                      <option value="VoterID">Voter ID</option>
                    </>
                  )}
                  <option value="Passport">Passport</option>
                  <option value="DrivingLicense">Driving License</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Unique ID <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Unique ID"
                  name="UniqueID"
                  value={formData1.UniqueID}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Phone No <span>:</span>{" "}
                </label>
                <input
                  type="number"
                  // placeholder="Enter the Phone No"
                  name="PhoneNo"
                  value={formData1.PhoneNo}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Alternate Ph.No <span>:</span>{" "}
                </label>
                <input
                  type="number"
                  // placeholder="Enter the Mobile No"
                  name="MobileNo"
                  value={formData1.MobileNo}
                  onChange={handleInputChange1}
                  required
                />
              </div>
              {/* </div> */}

              {/* <div className="RegisFormcon"> */}
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Email ID <span>:</span>{" "}
                </label>
                <input
                  type="email"
                  // placeholder="Enter the Email ID"
                  name="EmailId"
                  value={formData1.EmailId}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Employee Id <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Employee ID"
                  name="EmployeeID"
                  value={formData1.EmployeeID}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Organization <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Organization"
                  name="Organization"
                  value={formData1.Organization}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Designation <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Designation"
                  name="Designation"
                  value={formData1.Designation}
                  onChange={handleInputChange1}
                />
              </div>
              {/* </div> */}
              {/* <div className="RegisFormcon "> */}
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Place <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Place"
                  name="Place"
                  value={formData1.Place}
                  onChange={handleInputChange1}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Patient Type <span>:</span>{" "}
                </label>
                <select
                  name="PatientType"
                  value={formData1.PatientType}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="General">General</option>
                  <option value="VIP">VIP</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Patient Category <span>:</span>{" "}
                </label>
                <select
                  name="PatientCategory"
                  value={formData1.PatientCategory}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="General">General</option>
                  <option value="Client">Client</option>
                  <option value="Insurance">Insurance</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  RateCard Type <span>:</span>{" "}
                </label>
                <select
                  name="RateCardType"
                  value={formData1.RateCardType}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  <option value="General">General</option>
                  <option value="Special">Special</option>
                  <option value="Client">Client</option>
                  <option value="Insurance">Insurance</option>
                  <option value="VIP">VIP</option>
                </select>
              </div>
            </div>
            {/* </div> */}

            {/* <div
              className={
                formData1.PatientCategory !== "General" &&
                  formData1.PatientCategory !== ""
                  ? "RegisFormcon "
                  : "RegisFormcon "
              }
            > */}

            {formData1.PatientCategory !== "Client" &&
              formData1.PatientCategory !== "Insurance" && (
                <div className="RegisForm_1" id="hide_div_regg"></div>
              )}
            {formData1.PatientCategory === "Client" && (
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Client Name <span>:</span>{" "}
                </label>

                <select
                  name="ClinentName"
                  value={formData1.ClinentName}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  {ClinentName.map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {formData1.PatientCategory === "Insurance" && (
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Insurance Name <span>:</span>{" "}
                </label>

                <select
                  name="InsuranceName"
                  value={formData1.InsuranceName}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  {InsuranceName.map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {formData1.PatientCategory === "Insurance" && (
              <div className="RegisFormcon  ">
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Insurance No <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the InsuranceNo"
                    name="InsuranceNo"
                    value={formData1.InsuranceNo}
                    onChange={handleInputChange1}
                  />
                </div>
              </div>
            )}
            {/* </div> */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Emergency Contact{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Name <span>:</span>{" "}
                </label>
                <input
                  type="text"
                  // placeholder="Enter the Name"
                  name="EmergencyName"
                  value={formData2.EmergencyName}
                  onChange={handleInputChange2}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  RelationShip <span>:</span>{" "}
                </label>
                <select
                  name="RelationShip"
                  value={formData2.RelationShip}
                  onChange={handleInputChange2}
                >
                  <option value="">Select</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Phone No <span>:</span>{" "}
                </label>
                <input
                  type="number"
                  // placeholder="Enter the Phone No"
                  name="EmergencyPhoneNo"
                  value={formData2.EmergencyPhoneNo}
                  onChange={handleInputChange2}
                  required
                />
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Documents And Forms
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="UniqueId">
                  Patient Photo <span>:</span>
                </label>
                <div className="file-input-444">
                  <div className="RegisterForm_2">
                    <input
                      type="file"
                      id="CapturedFile1"
                      name="CapturedFile1"
                      accept="image/*"
                      onChange={(e) =>
                        handleInputFileChange(e, "CapturedFile1")
                      }
                      required
                    />
                    <label
                      htmlFor="CapturedFile1"
                      className="RegisterForm_1_btns choose_file_update"
                    >
                      Choose File
                    </label>
                  </div>
                  <span>or </span>
                  <div className="RegisterForm_2">
                    <button
                      onClick={() =>
                        setShowFile({ file1: true, file2: false, file3: false })
                      }
                      className="RegisterForm_1_btns choose_file_update "
                    >
                      Capture pic
                    </button>

                    {showFile.file1 && (
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_takepic"
                            : "showcamera_takepic"
                        }
                        onClick={() =>
                          setShowFile({
                            file1: false,
                            file2: false,
                            file3: false,
                          })
                        }
                      >
                        <div
                          className={
                            isSidebarOpen
                              ? "sideopen_showcamera_1_takepic1"
                              : "showcamera_1_takepic1"
                          }
                          onClick={(e) => e.stopPropagation()}
                        >
                          {formData3.CapturedImage1 ? ( // Display the captured image
                            <img
                              src={URL.createObjectURL(
                                formData3.CapturedImage1
                              )}
                              alt="captured"
                              className="captured-image11"
                            />
                          ) : (
                            <div className="camera-container">
                              <div className="web_head">
                                <h3>Image</h3>
                              </div>
                              <br></br>
                              <div className="RotateButton_canva">
                                <Webcam
                                  audio={false}
                                  ref={webcamRef1}
                                  screenshotFormat="image/jpeg"
                                  mirrored={facingMode === "user"}
                                  className="web_cam"
                                  videoConstraints={videoConstraints}
                                />
                                {devices.includes(IsmobileorNot) && (
                                  <div className="web_cam__1">
                                    <button onClick={switchCamera}>
                                      <CameraswitchIcon />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="web_btn">
                            {isImageCaptured1 ? (
                              <button
                                onClick={handleRecaptureImage1}
                                className="btncon_add"
                              >
                                Recapture
                              </button>
                            ) : (
                              <button
                                onClick={handleCaptureImage1}
                                className="btncon_add"
                              >
                                Capture
                              </button>
                            )}

                            <button
                              onClick={() =>
                                setShowFile({
                                  file1: false,
                                  file2: false,
                                  file3: false,
                                })
                              }
                              className="btncon_add"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Add a default image */}
              {formData3.CapturedImage1 ? null : (
                <img
                  src={profileImg}
                  alt="default"
                  className="default-image"
                  style={{ display: "none" }} // Hide the default image
                />
              )}

              <div className="RegisForm_1">
                <label htmlFor="UniqueId">
                  Unique Id File <span>:</span>
                </label>
                <div className="file-input-444">
                  <div className="RegisterForm_2">
                    <input
                      type="file"
                      id="CapturedFile2"
                      name="CapturedFile2"
                      accept="image/*"
                      onChange={(e) =>
                        handleInputFileChange(e, "CapturedFile2")
                      }
                      required
                    />
                    <label
                      htmlFor="CapturedFile2"
                      className="RegisterForm_1_btns choose_file_update"
                    >
                      Choose File
                    </label>
                  </div>
                  <span>or </span>
                  <div className="RegisterForm_2">
                    <button
                      onClick={() =>
                        setShowFile({ file1: false, file2: true, file3: false })
                      }
                      className="RegisterForm_1_btns choose_file_update"
                    >
                      Capture pic
                    </button>

                    {showFile.file2 && (
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_takepic"
                            : "showcamera_takepic"
                        }
                        onClick={() =>
                          setShowFile({
                            file1: false,
                            file2: false,
                            file3: false,
                          })
                        }
                      >
                        <div
                          className={
                            isSidebarOpen
                              ? "sideopen_showcamera_1_takepic1"
                              : "showcamera_1_takepic1"
                          }
                          onClick={(e) => e.stopPropagation()}
                        >
                          {formData3.CapturedImage2 ? ( // Display the captured image
                            <img
                              src={URL.createObjectURL(
                                formData3.CapturedImage2
                              )}
                              alt="captured"
                              className="captured-image11"
                            />
                          ) : (
                            <div className="camera-container">
                              <div className="web_head">
                                <h3>Image</h3>
                              </div>
                              <br></br>
                              <div className="RotateButton_canva">
                                <Webcam
                                  audio={false}
                                  ref={webcamRef2}
                                  screenshotFormat="image/jpeg"
                                  mirrored={facingMode === "user"}
                                  className="web_cam"
                                  videoConstraints={videoConstraints}
                                />
                                {devices.includes(IsmobileorNot) && (
                                  <div className="web_cam__1">
                                    <button onClick={switchCamera}>
                                      <CameraswitchIcon />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="web_btn">
                            {isImageCaptured2 ? (
                              <button
                                onClick={handleRecaptureImage2}
                                className="btncon_add"
                              >
                                Recapture
                              </button>
                            ) : (
                              <button
                                onClick={handleCaptureImage2}
                                className="btncon_add"
                              >
                                Capture
                              </button>
                            )}

                            <button
                              onClick={() =>
                                setShowFile({
                                  file1: false,
                                  file2: false,
                                  file3: false,
                                })
                              }
                              className="btncon_add"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="RegisFormcon"></div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* for indian */}
      {
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: "33%", flexShrink: 0 }}
              id="panel1bh-header"
            >
              Communication Address
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Door No <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the Door No"
                    name="DoorNo"
                    required
                    value={formData4.DoorNo}
                    onChange={handleInputChange3}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Street <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the Street"
                    name="Street"
                    value={formData4.Street}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Area <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the Area"
                    name="Area"
                    value={formData4.Area}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    City <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the City"
                    name="City"
                    value={formData4.City}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    State <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the State"
                    name="State"
                    value={formData4.State}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Country <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="Enter the Country"
                    name="Country"
                    value={formData4.Country}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Pin Code <span>:</span>{" "}
                  </label>
                  <input
                    type="number"
                    // placeholder="Enter the Pin Code"
                    name="PinCode"
                    value={formData4.PinCode}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
              </div>
              <div className="RegisFormcon">
               
              
              </div>
              <div className="RegisFormcon">
              
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      }
      {/* For International */}
      {formData1.Nationality === "International" &&
        formData1.Nationality !== "Indian" && (
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                id="panel1bh-header"
              >
                Permanent Address
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Door No <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the Door No"
                      name="permanentDoorNo"
                      value={formData5.permanentDoorNo}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Street <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the Street"
                      name="permanentStreet"
                      value={formData5.permanentStreet}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Area <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the Area"
                      name="permanentArea"
                      value={formData5.permanentArea}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      City <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the City"
                      name="permanentCity"
                      value={formData5.permanentCity}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      State <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the State"
                      name="permanentState"
                      value={formData5.permanentState}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Country <span>:</span>{" "}
                    </label>
                    <input
                      type="text"
                      // placeholder="Enter the Country"
                      name="permanentCountry"
                      value={formData5.permanentCountry}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Pin Code <span>:</span>{" "}
                    </label>
                    <input
                      type="number"
                      // placeholder="Enter the Pin Code"
                      name="permanentPinCode"
                      value={formData5.permanentPinCode}
                      onChange={handleInputChange4}
                      required
                    />
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

      <div className="Register_btn_con">
        <button onClick={handlePost} className="RegisterForm_1_btns">
          {/* Register */}
          {buttonText}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};
export default RegistrationForm;