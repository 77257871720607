import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";



export default function InsuranceBillingInfo() {

  
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );


  

  const [billingInfo, setBillingInfo] = useState({
    dateofAdmission:"",
    typeofAdmission: "",
    durationStayinDays: "",
    roomWardTypeBilling: "",
    dateofSurgery: "",
    dateofDischarge: "",
    DischargeTypeBilling: "",
  });

  const [rows6, setRows6] = useState([
    {
      costOfHospitalization: "",
      totalBillAmount: "",
    },
  ]);


//   -----------------------------------------------------


const handleChangeBillingInfo = (event) => {
    const { name, value } = event.target;

    setBillingInfo((prev)=>({
      ...prev,
      [name]: value,
    }))
  };

  const handleChangeRowBillingItem = (index, key, value) => {
    const newRows = [...rows6];
    newRows[index][key] = value;
    setRows6(newRows);
  };

  const addRow6 = () => {
    setRows6([
      ...rows6,
      {
        costOfHospitalization: "",
        totalBillAmount: "",
      },
    ]);
  };

  const removeRow6 = (index) => {
    const newRows = [...rows6];
    newRows.splice(index, 1);
    setRows6(newRows);
  };


 

  const SavebtnFun =()=>{
    

    const params={

      MRN: InsuranceUpdatedata.MRN,
      ContactNumber:InsuranceUpdatedata.ContactNumber,

      Location:userRecord.location,
      createAt:userRecord.username, 

      billingInfo:billingInfo,

      rows6:rows6,

      MainPageCompleted:"MainPage2",

      PageCompleted:"PreAuthDocuments"

    } 


    axios.post(`https://vesoftometic.co.in/Insurance/Post_Pre_Auth_Form_billing_info_details`,params)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        dispatchvalue({type: "InsurancePageChange",value:"PreAuthDocuments"});
    })
    .catch((error) => {
        console.error(error);
    });

  }

 
 useEffect(() => {
    if (Object.values(InsuranceUpdatedata).length !== 0) {

        axios.get(
            `https://vesoftometic.co.in/Insurance/get_Pre_Auth_billing_info_details`, {
            params: InsuranceUpdatedata.MRN
        }
        )
            .then((response) => {
                // console.log('vrrrr',response.data);

                const data = response.data[0]

                // console.log('qqq', data.Billinginfolist)

                if(Object.keys(data).length !== 0){
                  setBillingInfo((prev) => ({
                    ...prev,
                    dateofAdmission: data.dateofAdmission,
                    typeofAdmission: data.typeofAdmission,
                    durationStayinDays: data.durationStayinDays,
                    roomWardTypeBilling: data.roomWardTypeBilling,
                    dateofSurgery: data.dateofSurgery,
                    dateofDischarge: data.dateofDischarge,
                    DischargeTypeBilling: data.DischargeTypeBilling,
                  }));
  
                  setRows6(data.Billinginfolist)
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }
}, [InsuranceUpdatedata])
  

  return (
    <>
     <div className="Supplier_Master_Container">
        <>
        <div className="gap_biling_ifo">
            <div className="surgical_flex_srtgt gap_biling_ifo">
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Date Of Admission <span>:</span>{" "}
                </label>
                <input
                type="date"
                className="department_tretmt date_backgclr6"
                name="dateofAdmission"
                value={billingInfo.dateofAdmission}
                onChange={handleChangeBillingInfo}
                />
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Type Of Admission <span>:</span>{" "}
                </label>
                <select
                className="department_tretmt"
                name="typeofAdmission"
                value={billingInfo.typeofAdmission}
                onChange={handleChangeBillingInfo}
                >
                <option value="">Select</option>
                <option value="Type1">Admission Type 1</option>
                <option value="Type2">Admission Type 2</option>
                <option value="Type3">Admission Type 3</option>
                </select>
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Duration(stay in Days) <span>:</span>{" "}
                </label>
                <input
                type="number"
                className="duration_input_with_clr"
                name="durationStayinDays"
                value={billingInfo.durationStayinDays}
                onChange={handleChangeBillingInfo}
                />
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Room / Ward Type <span>:</span>{" "}
                </label>
                <select
                type="date"
                className="department_tretmt"
                name="roomWardTypeBilling"
                value={billingInfo.roomWardTypeBilling}
                onChange={handleChangeBillingInfo}
                >
                <option value="">Select</option>
                <option value="Room1">Room 1</option>
                <option value="Room2">Room 2</option>
                <option value="Room3">Room 3</option>
                <option value="Room4">Room 4</option>
                </select>
            </div>

            </div>
            <div className="surgical_flex_srtgt gap_biling_ifo">           
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Date Of Surgery <span>:</span>{" "}
                </label>
                <input
                type="date"
                className="department_tretmt date_backgclr6"
                name="dateofSurgery"
                value={billingInfo.dateofSurgery}
                onChange={handleChangeBillingInfo}
                />
            </div>
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Date Of Discharge <span>:</span>{" "}
                </label>
                <input
                type="date"
                className="department_tretmt date_backgclr6"
                name="dateofDischarge"
                value={billingInfo.dateofDischarge}
                onChange={handleChangeBillingInfo}
                />
            </div>
            
            <div className="RegisForm_1 column_RegisForm_1_forinsurance surgical_flex_srtgt_div">
                <label>
                Discharge Type <span>:</span>{" "}
                </label>
                <select
                type="date"
                className="department_tretmt"
                name="DischargeTypeBilling"
                value={billingInfo.DischargeTypeBilling}
                onChange={handleChangeBillingInfo}
                >
                <option value="">Select</option>
                <option value="DischargeType1">Discharge Type 1</option>
                <option value="DischargeType2">Discharge Type 2</option>
                <option value="DischargeType3">Discharge Type 3</option>
                <option value="DischargeType4">Discharge Type 4</option>
                </select>
            </div>

            </div>

        
        </div>
        </>
        <div className="Selected-table-container">
        <table className="selected-medicine-table2 _hide_hover_table">
            <thead className="Spl_backcolr_09">
            <tr>
                <th className="Provisional_Diagnosis">
                Billing Item
                </th>
                <th className="ICD_Code">Charges</th>

                <th className="add32_Code">
                <span onClick={addRow6}>
                    <AddIcon className="add32_Code" />
                </span>
                </th>
            </tr>
            </thead>
            <tbody>
            {rows6.map((row, index) => (
                <tr key={index}>
                <td>
                    <div className="bilng_itm_colum8">
                    <label>Expected Cost of Hospitalization*</label>
                    <input
                        type="text"
                        className="Provisional_Diagnosis"
                        value={row.costOfHospitalization}
                        onChange={(e) =>
                        handleChangeRowBillingItem(
                            index,
                            "costOfHospitalization",
                            e.target.value
                        )
                        }
                    />
                    </div>
                </td>
                <td>
                    <div className="bilng_itm_colum8">
                    <label>Total Bill Amount*</label>
                    <input
                        type="number"
                        className="Provisional_Diagnosis"
                        value={row.totalBillAmount}
                        onChange={(e) =>
                        handleChangeRowBillingItem(
                            index,
                            "totalBillAmount",
                            e.target.value
                        )
                        }
                    />
                    </div>
                </td>
                <td className="add32_Code">
                    <span onClick={() => removeRow6(index)}>
                    <RemoveIcon className="add32_Code" />
                    </span>
                </td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>
               

      </div>

      <div className="submit_button_prev_next">
            <button onClick={SavebtnFun}>
                Save
            </button>
        </div>
    </>
  );
}
