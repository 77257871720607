import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMlcPolice() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    Date: "",
    Time: "",
    Name: "",
    Address: "",
    FreeText: "",
    PoliceStation: "",
    BuckleNo: "",
    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Date', ConcernformData.Date);
              formData.append('Time', ConcernformData.Time);
              formData.append('Name', ConcernformData.Name);
              formData.append('Address', ConcernformData.Address);
              formData.append('FreeText', ConcernformData.FreeText);
              formData.append('PoliceStation', ConcernformData.PoliceStation);
              formData.append('BuckleNo', ConcernformData.BuckleNo);
              formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MlcPoliceConcernForm`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div>
         


          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <h3>MLC Police Form</h3>
          </div>
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

            <div className="RegisForm_1_consent_consent">
                <label htmlFor="Date">
                दिनांक<span>:</span>
                    </label>
                    <input
                      type="date"
                      id="Date"
                      name="Date"
                      value={ConcernformData.Date}
                      onChange={handleChange}
                      required
                    />
                
            </div>
            <div className="RegisForm_1_consent_consent">
                <label htmlFor="Time">
                वेळ<span>:</span>
                    </label>
                    <input
                      type="time"
                      id="Time"
                      name="Time"
                      value={ConcernformData.Time}
                      onChange={handleChange}
                      required
                    />
                
            </div>
            

        </div>
        <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
              <p style={{margin: '0', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">प्रति,</p>
          </div>
        </div>
       

        <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  >
              <p style={{ marginLeft: '25px', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">पोलीस निरीक्षक,<br/>
              रत्नागिरी शहर / ग्रामीण पोलीस ठाणे<br/>
              विषय - MLC नोंद करण्याबाबत.</p>
          </div>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            आपणांस कळविण्यात येते की श्री / श्रीमती
                <input
                type="text"
                name="Name"
                value={ConcernformData.Name}
                onChange={handleChange}
                required
                />
                पु./स्त्री वय वर्षे, राहणार
                <input
                type="text"
                name="Address"
                value={ConcernformData.Address}
                onChange={handleChange}
                required
                />
                यांना वाहन अपघात / पडून दुखापत/विष प्राशन/पाण्यात बुडाले/ मारामारी /
                <input
                type="text"
                name="FreeText"
                value={ConcernformData.FreeText}
                onChange={handleChange}
                required
                />
                यामुळे रुग्णालयात गंभीर / मृत अवस्थेत / दाखल केले आहे.
                <br/>
                <br/>
                कृपया पुढील योग्य ती कार्यवाही त्वरीत करण्यात यावी.

            </p>
            
          </div>

          
        </div>

        <br />
        <div className=" summadiv">
        <div  className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold',textAlign:'left' }}>दाखल केलेल्या<br/>
            पोलीस स्टेशनचा शिक्का <span>-</span> 
               <input
                type="text"
                name="PoliceStation"
                value={ConcernformData.PoliceStation}
                onChange={handleChange}
                required
                /><br/>
            बक्कल क्र <span>-</span> 
            <input
                type="text"
                name="BuckleNo"
                value={ConcernformData.BuckleNo}
                onChange={handleChange}
                required
                /><br/>


                
            सही <span>-</span>  
            <input
                type="text"
                name="Word2"
                value={ConcernformData.Word2}
                onChange={handleChange}
                required
                />
                
          </p>

          
        </div>
        

       </div>
      

        <div className="sigCanvas2_head11" >
            <div className="RegisForm_1_consent_consent sigCanvas2_head" >
                <div>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                    width: 200,
                    height: 100,
                    className: "sigCanvas2",
                    }}
                />
                </div>
                <h5>वैद्यकीय अधिकारी, <br/>
                 चिरायु हॉस्पिटल, रत्नागिरी</h5>

                <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                    Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                    Save
                </button>
                </div>

            </div>


        </div>

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMlcPolice;
