import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import './Report.css'
import axios from 'axios';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { toPng } from 'html-to-image';
function Reports() {

  const [selectedDate, setSelectedDate] = useState('');
  console.log('Selected Date:', selectedDate);
  const [barcode, setbarcode] = useState(false);
  const [summa, setsumma] = useState([])
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: '',
    ClinicGST: '',
    ClinicAddress: '',
    ClinicCity: '',
    ClinicState: '',
    ClinicCode: '',
    ClinicMobileNo: '',
    ClinicLandLineNo: '',
    ClinicMailID: '',
    InvoiceNo: ''
  });

  const [report, setreport] = useState({
    Collected: '',
    Received: '',
    Reported: '',
    Head: "",
    Detail: ""
  })





  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/Phelobotomist/getdataforreport?Billing_Invoice=${capturedatas.Billing_Invoice}`)
      .then((response) => {
        console.log(response);
        const groupsData = response.data;

        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach(group => {
          const analyseResultDate = new Date(group.Updated_At.AnalyseInvestigationResult);
          const analyseSeniorDate = (group.Updated_At.AnalyseInvestigationSenior !== "N/A") ? new Date(group.Updated_At.AnalyseInvestigationSenior) : new Date(0);
          const approvePathologistDate = new Date(group.Updated_At.Approve_Test_Pathologist);

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes();

          if (day.length < 2) day = '0' + day;
          if (month.length < 2) month = '0' + month;
          if (hours.length < 2) hours = '0' + hours;
          if (minutes.length < 2) minutes = '0' + minutes;

          return [day, month, year].join('/') + ' ' + [hours, minutes].join(':');
        };

        setreport(prev => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map(group => {
          return {
            groupName: group.Group_Name,
            tests: group.Tests.map(test => ({
              Test_Name: test.Test_Name,
              CaptureValue: test.CaptureValue,
              From_Value: test.From_Value,
              To_Value: test.To_Value,
              UOM: test.UOM,
              Method_Name: test.Method_Name,
            })),
            interpretation: group.Interpretations ? {
              Head: group.Interpretations.Head,
              Detail: group.Interpretations.Detail,
            } : null,
          };
        });

        setsumma(reportsData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [capturedatas.Billing_Invoice]);
  const [PdfBlob, setPdfBlob] = useState(null)



  useEffect(()=>{
    axios
    .get(
      `http://127.0.0.1:8000/usercontrol/getClinic?location=ALL`
    )
    .then((response) => {
      // console.log(response.data)
      const data = response.data[0];
      console.log(data);
      if (data) {
        setClinicDetials((prev) => ({
          ...prev,
          ClinicAddress: data.door_no + "," + data.area + "," + data.street,
          ClinicGST: data.Gst_no,
          ClinicCity: data.city,
          ClinicState: data.state,
          ClinicCode: data.pincode,
          ClinicMobileNo: data.phone_no,
          ClinicLandLineNo: data.landline_no,
          ClinicMailID: data.email,
        }));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  })


  const handlePrint = useReactToPrint({
    content: () => document.getElementById('reactprintcontent'),
    onBeforePrint: () => {
      console.log('Before');
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);

      try {

        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const contentheight = printdata.offsetHeight;
          const pdfWidth = contentWidth + 2 * 5;
          const pdfHeight = contentheight + 2 * 10;
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [pdfWidth, 1120],
          });
          const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));
          const barcodeImagecon2 = await toPng(printdata.querySelector('#get_imagecontent_2'));
         
          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });
          const barcodeImage2 = await new Promise((resolve, reject) => {
            const barcodeImg2 = new Image();
            barcodeImg2.onload = () => resolve(barcodeImg2);
            barcodeImg2.onerror = reject;
            barcodeImg2.src = barcodeImagecon2;
          });
  
          // Append barcode image to the .Register_btn_con_barcode element
          const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
          barcodeContainer1.innerHTML = ''; // Clear previous content
          barcodeContainer1.appendChild(barcodeImage1);
          // Append barcode image to the .Register_btn_con_barcode element
          const barcodeContainer2 = printdata.querySelector('#get_imagecontent_2');
          barcodeContainer2.innerHTML = ''; // Clear previous content
          barcodeContainer2.appendChild(barcodeImage2);
          pdf.html(printdata, {
            x: 5, // Set x-coordinate for content
            y: 5, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output('blob');
              setPdfBlob(generatedPdfBlob);
              // pdf.save('summa.pdf')
              console.log('generatedPdfBlob', generatedPdfBlob)
            },
          });
        } else {
          throw new Error('Unable to get the target element');
        }

      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  });

  const PdfViewer = ({ pdfBlob }) => {
    // Check if pdfBlob is a Blob object
    if (pdfBlob instanceof Blob) {
      // Convert Blob object to data URL
      const url = URL.createObjectURL(pdfBlob);
  
      // Render the PDF using iframe
      return (
        <iframe
          title="PDF Viewer"
          src={url}
          style={{
            width: "100%",
            height: "435px",
            border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
          }}
        />
      );
    }
  
    // If pdfBlob is not a Blob object, assume it's already a valid data URL and render it directly
    return (
      <img
        src={pdfBlob}
        alt="Concern Form"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "20px",
        }}
      />
    );
  };
  function getInnerHeightOfPDF() {
    const pdfget = new jsPDF({
      orientation: 'portrait', // or 'landscape'
      unit: 'px', // pixels
      format: [595, 842] // A4 size in pixels (width, height)
    });
    const innerHeightInPoints = pdfget.internal.pageSize.getHeight();
    pdfget.deletePage();
    const innerHeightInPixels = innerHeightInPoints * (1 / 72) * 96;
    return innerHeightInPixels - 10;
  }

  const innerHeightOfPDF = getInnerHeightOfPDF();
  return (
    <div className='appointment' id='reactprintcontent'>
      {summa.map((group, index) => (
        <div className='Report_master1' id={`reactprintcontent-${index + 1}`} key={index}>
          <div className="Report_master" style={{ height: innerHeightOfPDF }}>
           
            <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
              Test Report
            </div>


            <div className="new_billing_address new_report_address">
              <div className="new_billing_address_2">
                {index === 0 && <div className='Register_btn_con_barcode'>
                <div id='get_imagecontent_1'>
                  <Barcode
                    value={capturedatas.Barcode || ''}
                    lineColor="black"
                    height={40}
                    width={1.2}
                    displayValue={true} // Hide the barcode value
                    fontSize={12}
                  />
                  </div>
                </div>}
                <div className="new_billing_div">
                  <label>Patient Name <span>:</span></label>
                  <span>{capturedatas.Patient_Name}</span>
                </div>
                <div className="new_billing_div">
                  <label>Visit ID <span>:</span></label>
                  <span>{capturedatas.Visit_Id}</span>
                </div>
                <div className="new_billing_div">
                  <label>Age/Gender <span>:</span></label>
                  <span>{capturedatas.Age}/{capturedatas.Gender}</span>
                </div>
                <div className="new_billing_div">
                  <label>Phone <span>:</span></label>
                  <span>{capturedatas.Phone}</span>
                </div>
                <div className="new_billing_div">
                  <label>Reference Doctor <span>:</span></label>
                  <span>{capturedatas.Refering_Doctor}</span>
                </div>

              </div>

              <div className="new_billing_address_2">
                {index === 0 && <div className='Register_btn_con_barcode'>
                  <div id='get_imagecontent_2'>
                  <Barcode
                    value={capturedatas.Barcode || ''}
                    lineColor="black"
                    height={40}
                    width={1.2}
                    displayValue={true} // Hide the barcode value
                    fontSize={12}
                  />
                  </div>
                </div>}
                <div className="new_billing_div">
                  <label>Invoice No <span>:</span></label>
                  <span>{capturedatas.Billing_Invoice}</span>
                </div>

                <div className="new_billing_div">
                  <label>Collected  <span>:</span></label>
                  <span>{report.Collected}</span>
                </div>
                <div className="new_billing_div">
                  <label>Received <span>:</span></label>
                  <span>{report.Received}</span>
                </div>
                <div className="new_billing_div">
                  <label>Reported <span>:</span></label>
                  <span>{report.Reported}</span>
                </div>
                <div className="new_billing_div">
                  <label> Barcode<span>:</span></label>
                  <span>{capturedatas.Barcode}</span>
                </div>
              </div>
            </div>

            <div className="Inpterpretation_head">
              <p>{group.groupName}</p>
            </div>
            <div className="new_billing_Report_detials">
  <table className="report_table">
    <thead > 
      <tr>
        <th >Test Name</th>
        <th >Result</th>
        <th >Reference</th>
        <th >Unit</th>
        <th >Method</th>
      </tr>
    </thead>
    <tbody>
      {group.tests.map((test, index) => (
        <tr key={index}>
          <td >{test.Test_Name}</td>
          <td >{test.CaptureValue}</td>
          <td >{`${test.From_Value} - ${test.To_Value} `}</td>
          <td >{test.UOM}</td>
          <td >{test.Method_Name}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


              
            <div className="interpretation_container">
                <div className="Inpterpretation_head">
                  <p>{group.interpretation.Head}</p>
                </div>
                <div className="Interpretation_Detail">
                  <p>{group.interpretation.Detail}</p>
                </div>
              </div>
            {/* </div> */}


            <div className="report_qr bottomcode">
              <div className="Register_btn_con_Doc_sign">
                <p>Doctor Sign</p>
              </div>

              {index === 0 && <div className='Register_btn_con_QRcode'>
                <QRCode
                  value="http//localhost:3000/DispatchResult"
                  size={106}
                  level="H"
                  bgColor="#ffffff"
                  fgColor="#000000"
                />
              </div>}

            </div>


          </div>

        </div>
      ))}

      <div className='Register_btn_con'>
        <button onClick={handlePrint} className='RegisterForm_1_btns'>save</button>
      </div>
      {PdfBlob && <PdfViewer pdfBlob={PdfBlob}/>}
    </div>

  );
}

export default Reports;

