
import React, { useState, useEffect } from 'react';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});


const PermissionStatus = ({ isSidebarOpen, userRecord }) => {
  console.log('userdata', userRecord);

  const [rows, setRows] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);



  // second datagrid2 for permission approval

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(`${urllink}HRmanagement/get_permission_register_recent?EmployeeID=${employeeid}&location=${userRecord.location}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            EmployeeID: userdata.EmployeeID,
            EmployeeName: userdata.EmployeeName,
            LeaveType: userdata.LeaveType,
            PermissionDate: userdata.PermissionDate,
            fromtime: userdata.fromtime,
            totime: userdata.totime,
            hours: userdata.hours,
            Reason: userdata.Reason,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus
          }));
          setRows(Records);
          console.log('record', Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Leave Type</th>
              <th id="slectbill_ins">Date</th>
              <th id="slectbill_ins">From Time</th>
              <th id="slectbill_ins">To Time</th>
              <th id="slectbill_ins">Hours</th>
              <th id="slectbill_ins">Reason</th>
              <th id="slectbill_ins">Status</th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 && rows.map((leave, index) => (
              <tr key={index}>
                <td>{leave.EmployeeID}</td>
                <td>{leave.EmployeeName}</td>
                <td>{leave.LeaveType}</td>
                <td>{leave.PermissionDate}</td>
                <td>{leave.fromtime}</td>
                <td>{leave.totime}</td>
                <td>{leave.hours}</td>
                <td>{leave.Reason}</td>
                <td>{leave.status}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
};

export default PermissionStatus;
