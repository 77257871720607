const initstate = {
    selectRoom: null,
    showroom:false,
    IpAdmissionEdit:{},
    IpNurseQueSelectedRow:{},
    IpBillingData:{}
};

const IpManagement = (state = initstate, action) => {

    switch (action.type) {

        case 'selectRoom':
            return { ...state, selectRoom: action.value };
        case 'showroom':
            return { ...state, showroom: action.value };
        case 'IpAdmissionEdit':
            return { ...state, IpAdmissionEdit: action.value };
        case 'IpNurseQueSelectedRow':
            return { ...state, IpNurseQueSelectedRow: action.value };
    
        case 'IpBillingData':
            return { ...state, IpBillingData: action.value };
    
        default:
            return state;
    }
};

export default IpManagement;