import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import EmergencyDocVitals from "./EmergencyDocVitals";
import EmergencyHistory from "./EmergencyHistory";
import EmergencyExamination from "./EmergencyExamination";
import EmergencyConcentForm from "./EmergencyConcentForm";
import EmergencyLabTest from "./EmergencyLabTest";
import EmergencyLabReport from "./EmergencyLabReport";
import EmergencyTriageClassification from "./EmergencyTriageClassification";
import EmergencyDocProgressNotes from "./EmergencyDocProgressNotes";
import EmergencyTreatment from "./EmergencyTreatment";
import EmergencyTransfertoAndReason from "./EmergencyTransfertoAndReason";
import EmergencyMlcPolice from "./EmergencyMlcPolice";
import EmergencyMlcConsentForm from "./EmergencyMlcConsentForm";
import EmergencyMlcRegister from "./EmergencyMlcRegister";
import EmerJwelleryConsent from "./EmerJwelleryConsent";
import EmerConsentDForm from "./EmerConsentDForm";
import EmerConsentAForm from "./EmerConsentAForm";
import EmerHospitalInformation from "./EmerHospitalInformation";
import EmerConsent from "./EmerConsent";
import EmerIpdAdmissionConsent from "./EmerIpdAdmissionConsent";

// MTP
import EmergencyMtpClaimNonClaim from "./EmergencyMtpClaimNonClaim";
import EmergencyMtpFormI from "./EmergencyMtpFormI";
import EmergencyMtpFormC from "./EmergencyMtpFormC";
import EmergencyMtpConsent from "./EmergencyMtpConsent";



function EmergencyDocWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("EmergencyDocVitals");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/EmergencyDocWorkBench')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.Booking_Id}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("EmergencyDocVitals")} style={{ color: activeTab === 'EmergencyDocVitals' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTriageClassification")} style={{ color: activeTab === 'EmergencyTriageClassification' ? "white" : '' }}>
                            Triage Classification
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyHistory")} style={{ color: activeTab === 'EmergencyHistory' ? "white" : '' }}>
                            History
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyExamination")} style={{ color: activeTab === 'EmergencyExamination' ? "white" : '' }}>
                            Examination
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTreatment")} style={{ color: activeTab === 'EmergencyTreatment' ? "white" : '' }}>
                            Treatment / Diagnosis
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyLabTest")} style={{ color: activeTab === 'EmergencyLabTest' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyLabReport")} style={{ color: activeTab === 'EmergencyLabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmergencyMlcPolice' || activeTab === 'EmergencyMlcConsentForm' || activeTab === 'EmergencyMlcRegister' ? "white" : '' }}>MLC</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyMlcRegister")} style={{ color: activeTab === 'EmergencyMlcRegister' ? "white" : '' }}>
                                        MLC Register Format
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMlcPolice")} style={{ color: activeTab === 'EmergencyMlcPolice' ? "white" : '' }}>
                                        MLC (Consent Form) For Police Station
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMlcConsentForm")} style={{ color: activeTab === 'EmergencyMlcConsentForm' ? "white" : '' }}>
                                        MLC Consent Form
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmergencyMtpClaimNonClaim' || activeTab === 'EmergencyMtpFormI' || activeTab === 'EmergencyMtpFormC' || activeTab === 'EmergencyMtpConsent' ? "white" : '' }}>MTP</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyMtpClaimNonClaim")} style={{ color: activeTab === 'EmergencyMtpClaimNonClaim' ? "white" : '' }}>
                                       Consent For Claim / Non Claim
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpFormI")} style={{ color: activeTab === 'EmergencyMtpFormI' ? "white" : '' }}>
                                        FORM I
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpFormC")} style={{ color: activeTab === 'EmergencyMtpFormC' ? "white" : '' }}>
                                        FORM C
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpConsent")} style={{ color: activeTab === 'EmergencyMtpConsent' ? "white" : '' }}>
                                        MTP CONSENT
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmerJwelleryConsent' || activeTab === 'EmerConsent' || activeTab === 'EmerIpdAdmissionConsent' || activeTab === 'EmerConsentAForm' || activeTab === 'EmerConsentDForm' || activeTab === 'EmerHospitalInformation' ? "white" : '' }}>Consent Forms</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmerJwelleryConsent")} style={{ color: activeTab === 'EmerJwelleryConsent' ? "white" : '' }}>
                                    Jwellery Consent
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsent")} style={{ color: activeTab === 'EmerConsent' ? "white" : '' }}>
                                    Consent
                                    </button>
                                    <button onClick={() => handleTabChange("EmerIpdAdmissionConsent")} style={{ color: activeTab === 'EmerIpdAdmissionConsent' ? "white" : '' }}>
                                    Ipd Admission
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsentAForm")} style={{ color: activeTab === 'EmerConsentAForm' ? "white" : '' }}>
                                    Consent 'A' Form
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsentDForm")} style={{ color: activeTab === 'EmerConsentDForm' ? "white" : '' }}>
                                    Consent 'D' Form
                                    </button>
                                    <button onClick={() => handleTabChange("EmerHospitalInformation")} style={{ color: activeTab === 'EmerHospitalInformation' ? "white" : '' }}>
                                    Hospital Information
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyConcentForm")} style={{ color: activeTab === 'EmergencyConcentForm' ? "white" : '' }}>
                            Emergency ConcentForm
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyDocProgressNotes")} style={{ color: activeTab === 'EmergencyDocProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTransfertoAndReason")} style={{ color: activeTab === 'EmergencyTransfertoAndReason' ? "white" : '' }}>
                            Discharge Request
                            </button>

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                            <button onClick={() => handleTabChange("EmergencyDocVitals")} style={{ color: activeTab === 'EmergencyDocVitals' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTriageClassification")} style={{ color: activeTab === 'EmergencyTriageClassification' ? "white" : '' }}>
                            Triage Classification
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyHistory")} style={{ color: activeTab === 'EmergencyHistory' ? "white" : '' }}>
                            History
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyExamination")} style={{ color: activeTab === 'EmergencyExamination' ? "white" : '' }}>
                            Examination
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTreatment")} style={{ color: activeTab === 'EmergencyTreatment' ? "white" : '' }}>
                            Treatment / Diagnosis
                            </button>
                            |
                    
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            
                            
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyLabTest")} style={{ color: activeTab === 'EmergencyLabTest' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyLabReport")} style={{ color: activeTab === 'EmergencyLabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmergencyMlcPolice' || activeTab === 'EmergencyMlcConsentForm' || activeTab === 'EmergencyMlcRegister' ? "white" : '' }}>MLC</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyMlcRegister")} style={{ color: activeTab === 'EmergencyMlcRegister' ? "white" : '' }}>
                                        MLC Register Format
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMlcPolice")} style={{ color: activeTab === 'EmergencyMlcPolice' ? "white" : '' }}>
                                        MLC (Consent Form) For Police Station
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMlcConsentForm")} style={{ color: activeTab === 'EmergencyMlcConsentForm' ? "white" : '' }}>
                                        MLC Consent Form
                                    </button>
                                </div>
                            </div>
                            |
                            
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmergencyMtpClaimNonClaim' || activeTab === 'EmergencyMtpFormI' || activeTab === 'EmergencyMtpFormC' || activeTab === 'EmergencyMtpConsent' ? "white" : '' }}>MTP</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmergencyMtpClaimNonClaim")} style={{ color: activeTab === 'EmergencyMtpClaimNonClaim' ? "white" : '' }}>
                                       Consent For Claim / Non Claim
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpFormI")} style={{ color: activeTab === 'EmergencyMtpFormI' ? "white" : '' }}>
                                        FORM I
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpFormC")} style={{ color: activeTab === 'EmergencyMtpFormC' ? "white" : '' }}>
                                        FORM C
                                    </button>
                                    <button onClick={() => handleTabChange("EmergencyMtpConsent")} style={{ color: activeTab === 'EmergencyMtpConsent' ? "white" : '' }}>
                                        MTP CONSENT
                                    </button>
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'EmerJwelleryConsent' || activeTab === 'EmerConsent' || activeTab === 'EmerIpdAdmissionConsent' || activeTab === 'EmerConsentAForm' || activeTab === 'EmerConsentDForm' || activeTab === 'EmerHospitalInformation' ? "white" : '' }}>Consent Forms</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("EmerJwelleryConsent")} style={{ color: activeTab === 'EmerJwelleryConsent' ? "white" : '' }}>
                                    Jwellery Consent
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsent")} style={{ color: activeTab === 'EmerConsent' ? "white" : '' }}>
                                    Consent
                                    </button>
                                    <button onClick={() => handleTabChange("EmerIpdAdmissionConsent")} style={{ color: activeTab === 'EmerIpdAdmissionConsent' ? "white" : '' }}>
                                    Ipd Admission
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsentAForm")} style={{ color: activeTab === 'EmerConsentAForm' ? "white" : '' }}>
                                    Consent 'A' Form
                                    </button>
                                    <button onClick={() => handleTabChange("EmerConsentDForm")} style={{ color: activeTab === 'EmerConsentDForm' ? "white" : '' }}>
                                    Consent 'D' Form
                                    </button>
                                    <button onClick={() => handleTabChange("EmerHospitalInformation")} style={{ color: activeTab === 'EmerHospitalInformation' ? "white" : '' }}>
                                    Hospital Information
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("EmergencyConcentForm")} style={{ color: activeTab === 'EmergencyConcentForm' ? "white" : '' }}>
                            Emergency ConcentForm
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyDocProgressNotes")} style={{ color: activeTab === 'EmergencyDocProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>
                            |
                            <button onClick={() => handleTabChange("EmergencyTransfertoAndReason")} style={{ color: activeTab === 'EmergencyTransfertoAndReason' ? "white" : '' }}>
                            Discharge Request
                            </button>


                        </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "EmergencyDocVitals" && <EmergencyDocVitals />}
            {activeTab === "EmergencyHistory" && <EmergencyHistory/>}
            {activeTab === "EmergencyExamination" && <EmergencyExamination/>}
            {activeTab === "EmergencyConcentForm" && <EmergencyConcentForm/>}
            {activeTab === "EmergencyLabTest" && <EmergencyLabTest/>}
            {activeTab === "EmergencyLabReport" && <EmergencyLabReport/>}
            {activeTab === "EmergencyTriageClassification" && <EmergencyTriageClassification/>}
            {activeTab === "EmergencyDocProgressNotes" && <EmergencyDocProgressNotes/>}
            {activeTab === "EmergencyTreatment" && <EmergencyTreatment/>}
            {activeTab === "EmergencyTransfertoAndReason" && <EmergencyTransfertoAndReason/>}
            
            {/* MLC */}
            {activeTab === "EmergencyMlcPolice" && <EmergencyMlcPolice/>}
            {activeTab === "EmergencyMlcConsentForm" && <EmergencyMlcConsentForm/>}
            {activeTab === "EmergencyMlcRegister" && <EmergencyMlcRegister/>}
            {activeTab === "EmerJwelleryConsent" && <EmerJwelleryConsent/>}
            {activeTab === "EmerConsentDForm" && <EmerConsentDForm/>}
            {activeTab === "EmerConsentAForm" && <EmerConsentAForm/>}
            {activeTab === "EmerHospitalInformation" && <EmerHospitalInformation/>}
            {activeTab === "EmerConsent" && <EmerConsent/>}
            {activeTab === "EmerIpdAdmissionConsent" && <EmerIpdAdmissionConsent/>}
            {/* MTP */}
            {activeTab === "EmergencyMtpClaimNonClaim" && <EmergencyMtpClaimNonClaim/>}
            {activeTab === "EmergencyMtpFormI" && <EmergencyMtpFormI/>}
            {activeTab === "EmergencyMtpFormC" && <EmergencyMtpFormC/>}
            {activeTab === "EmergencyMtpConsent" && <EmergencyMtpConsent/>}
            
        </>
    );
}

export default EmergencyDocWorkbench;

