import React, { useEffect, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import './Ratecardneww.css'
import { useNavigate } from "react-router-dom";



function RateCardMaster() {

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const RateCardMasterEditdata = useSelector((state) => state.RateCardStore?.RateCardEditdata);

  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const [SelectFormOption, setSelectFormOption] = useState('services')

  const [Services_data, setServices_data] = useState([])

  const [ServiceFormdata, setServiceFormdata] = useState({
    ServiceId: '',
    ServiceName: '',
    IsTaxAppay: 'No',
    GST: '',
    Status: 'Active',
  })
  const navigate = useNavigate()

  const [DoctorsServicedata, setDoctorsServicedata] = useState({
    specialties: '',
    DoctorId: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    IsTaxAppay: 'No',
    GST: '',
    Status: 'Active',
  })


  const [SpecialitiesData, setSpecialitiesData] = useState([])
  const [doctorNames, setDoctorNames] = useState([]);

  useEffect(() => {
    if (Object.keys(RateCardMasterEditdata).length === 0) {
      axios
        .get(
          `${UrlLink}usercontrol/get_map_Services_Mother_data`
        )
        .then((response) => {
          const Sdata = response.data
          console.log('999', Sdata)
          setServices_data(Sdata)

        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [])


  useEffect(() => {
    GetInvoice()
  }, [])

  const GetInvoice = () => {
    axios
      .get(
        `${UrlLink}usercontrol/get_ServicesCode`
      )
      .then((response) => {
        const Id = response.data
        setServiceFormdata((prev) => ({
          ...prev,
          ServiceId: Id
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ServiceName') {
      setServiceFormdata((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    } else if (name === 'IsTaxAppay' && value === 'No') {
      setServiceFormdata((prevFormData) => ({
        ...prevFormData,
        GST: '',
        [name]: value,
      }));
    } else {
      setServiceFormdata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

  };


  const handleDocFormChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ServiceName') {
      setDoctorsServicedata((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    } else if (name === 'IsTaxAppay' && value === 'No') {
      setDoctorsServicedata((prevFormData) => ({
        ...prevFormData,
        GST: '',
        [name]: value,
      }));
    } else {
      setDoctorsServicedata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

  };

  // -------------------------------------------


  useEffect(() => {
    if (Object.keys(RateCardMasterEditdata).length !== 0 && RateCardMasterEditdata.SelectFormdata === "services") {

      console.log('Rate', RateCardMasterEditdata.Service_id)

      setSelectFormOption(RateCardMasterEditdata.SelectFormdata)

      axios
        .get(`${UrlLink}usercontrol/get_Services_data`, {
          params: RateCardMasterEditdata.Service_id
        })
        .then((response) => {
          // console.log('llll',response.data);
          const data1 = response.data.data1[0]
          const data2 = response.data.data2

          setServiceFormdata(data1);
          setServices_data(data2)
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (Object.keys(RateCardMasterEditdata).length !== 0 && RateCardMasterEditdata.SelectFormdata === "Doctors") {

      console.log('DoctorsRate', RateCardMasterEditdata.DoctorId)

      setSelectFormOption(RateCardMasterEditdata.SelectFormdata)


      axios
        .get(`${UrlLink}usercontrol/get_DoctorsRate_data`, {
          params: RateCardMasterEditdata.DoctorId
        })
        .then((response) => {
          // console.log('llll',response.data);
          const data1 = response.data.data1[0]
          const data2 = response.data.data2

          setDoctorsServicedata(data1);
          setServices_data(data2)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [RateCardMasterEditdata])




  const handleServicelist = () => {
    if (SelectFormOption === 'services') {
      const requiredfields = ['ServiceId', 'ServiceName', 'IsTaxAppay', 'Status'];

      if (ServiceFormdata.IsTaxAppay === 'Yes') {
        requiredfields.push('GST')
      }


      const emptyFields = requiredfields.filter(field => !ServiceFormdata[field]);

      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {

        const allDataFilled = Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany =>
              Object.keys(Services_data[rateCardType][visitType][insuranceCompany]).every(data => {
                const value = Services_data[rateCardType][visitType][insuranceCompany][data];
                if (!Array.isArray(value) && typeof value !== 'object') {
                  return value !== '';
                } else if (Array.isArray(value)) {
                  return value.every(room =>
                    Object.values(room).every(val => val !== '')
                  );
                } else {
                  return Object.values(value).every(val => val !== '');
                }
              })
            )
          )
        ) && Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany => {
              const data = Services_data[rateCardType][visitType][insuranceCompany];
              if (!Array.isArray(data) && typeof data !== 'object') {
                return data !== '';
              } else if (Array.isArray(data)) {
                return data.every(room =>
                  Object.values(room).every(val => val !== '')
                );
              } else {
                return Object.values(data).every(val => val !== '');
              }
            })
          )
        );


        if (allDataFilled) {
          let params = {
            ...ServiceFormdata,
            User: userRecord.username,
            Location: userRecord.location,
            Services_data: Services_data
          }


          console.log('params', params)

          axios
            .post(`${UrlLink}usercontrol/insertServices`, params)
            .then((response) => {
              // Handle the response as needed
              console.log(response.data);

              if (response.data && response.data.message) {
                successMsg(response.data.message);
                navigate('Home/RateCardListAll')
              } else {
                errmsg(response.data.error);
              }
              GetInvoice()
            })
            .catch((error) => {
              console.error("Error:", error);
              errmsg("Error occurred while inserting role.");
            });
        }
        else {
          alert("Please fill all data fields.");
        }

      }
    }
    else if (SelectFormOption === 'Doctors') {

      const requiredfields = ['specialties', 'DoctorId', 'FirstName', 'MiddleName', 'LastName', 'IsTaxAppay', 'Status'];

      if (DoctorsServicedata.IsTaxAppay === 'Yes') {
        requiredfields.push('GST')
      }


      const emptyFields = requiredfields.filter(field => !DoctorsServicedata[field]);

      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {


        const allDataFilled = Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany =>
              Object.keys(Services_data[rateCardType][visitType][insuranceCompany]).every(data => {
                const value = Services_data[rateCardType][visitType][insuranceCompany][data];
                if (!Array.isArray(value) && typeof value !== 'object') {
                  return value !== '';
                } else if (Array.isArray(value)) {
                  return value.every(room =>
                    Object.values(room).every(val => val !== '')
                  );
                } else {
                  return Object.values(value).every(val => val !== '');
                }
              })
            )
          )
        ) && Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany => {
              const data = Services_data[rateCardType][visitType][insuranceCompany];
              if (!Array.isArray(data) && typeof data !== 'object') {
                return data !== '';
              } else if (Array.isArray(data)) {
                return data.every(room =>
                  Object.values(room).every(val => val !== '')
                );
              } else {
                return Object.values(data).every(val => val !== '');
              }
            })
          )
        );


        if (allDataFilled) {


          let params = {
            ...DoctorsServicedata,
            User: userRecord.username,
            Location: userRecord.location,
            Services_data: Services_data
          }


          console.log('params', params)

          axios
            .post(`${UrlLink}usercontrol/insertDoctorsRatecard`, params)
            .then((response) => {
              // Handle the response as needed
              console.log(response.data);

              if (response.data && response.data.message) {
                successMsg(response.data.message);
              } else {
                errmsg(response.data.error);
              }
              GetInvoice()
            })
            .catch((error) => {
              console.error("Error:", error);
              errmsg("Error occurred while inserting role.");
            });
        }
        else {
          alert("Please fill all data fields.");
        }

      }

    }

  };


  const handleUpdateService = () => {
    if (SelectFormOption === 'services') {
      const requiredfields = ['ServiceId', 'ServiceName', 'IsTaxAppay', 'Status'];

      if (ServiceFormdata.IsTaxAppay === 'Yes') {
        requiredfields.push('GST')
      }


      const emptyFields = requiredfields.filter(field => !ServiceFormdata[field]);

      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {



        const allDataFilled = Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany =>
              Object.keys(Services_data[rateCardType][visitType][insuranceCompany]).every(data => {
                const value = Services_data[rateCardType][visitType][insuranceCompany][data];
                if (!Array.isArray(value) && typeof value !== 'object') {
                  return value !== '';
                } else if (Array.isArray(value)) {
                  return value.every(room =>
                    Object.values(room).every(val => val !== '')
                  );
                } else {
                  return Object.values(value).every(val => val !== '');
                }
              })
            )
          )
        ) && Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany => {
              const data = Services_data[rateCardType][visitType][insuranceCompany];
              if (!Array.isArray(data) && typeof data !== 'object') {
                return data !== '';
              } else if (Array.isArray(data)) {
                return data.every(room =>
                  Object.values(room).every(val => val !== '')
                );
              } else {
                return Object.values(data).every(val => val !== '');
              }
            })
          )
        );




        if (allDataFilled) {
          let params = {
            ...ServiceFormdata,
            User: userRecord.username,
            Location: userRecord.location,
            Services_data: Services_data
          }


          console.log('params', params)

          axios
            .post(`${UrlLink}usercontrol/update_status_for_Services_data`, params)
            .then((response) => {
              // Handle the response as needed
              console.log(response.data);

              if (response.data && response.data.message) {
                successMsg(response.data.message);
              } else {
                errmsg(response.data.error);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              errmsg("Error occurred while inserting role.");
            });
        } else {
          alert("Please fill all data fields.");
        }
      }



    }
    else if (SelectFormOption === 'Doctors') {

      const requiredfields = ['specialties', 'DoctorId', 'FirstName', 'MiddleName', 'LastName', 'IsTaxAppay', 'Status'];

      if (DoctorsServicedata.IsTaxAppay === 'Yes') {
        requiredfields.push('GST')
      }


      const emptyFields = requiredfields.filter(field => !DoctorsServicedata[field]);

      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {

        const allDataFilled = Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany =>
              Object.keys(Services_data[rateCardType][visitType][insuranceCompany]).every(data => {
                const value = Services_data[rateCardType][visitType][insuranceCompany][data];
                if (!Array.isArray(value) && typeof value !== 'object') {
                  return value !== '';
                } else if (Array.isArray(value)) {
                  return value.every(room =>
                    Object.values(room).every(val => val !== '')
                  );
                } else {
                  return Object.values(value).every(val => val !== '');
                }
              })
            )
          )
        ) && Object.keys(Services_data).every(rateCardType =>
          Object.keys(Services_data[rateCardType]).every(visitType =>
            Object.keys(Services_data[rateCardType][visitType]).every(insuranceCompany => {
              const data = Services_data[rateCardType][visitType][insuranceCompany];
              if (!Array.isArray(data) && typeof data !== 'object') {
                return data !== '';
              } else if (Array.isArray(data)) {
                return data.every(room =>
                  Object.values(room).every(val => val !== '')
                );
              } else {
                return Object.values(data).every(val => val !== '');
              }
            })
          )
        );


        if (allDataFilled) {

          let params = {
            ...DoctorsServicedata,
            User: userRecord.username,
            Location: userRecord.location,
            Services_data: Services_data
          }


          console.log('params', params)

          axios
            .post(`${UrlLink}usercontrol/update_DoctorsRate_data`, params)
            .then((response) => {
              // Handle the response as needed
              console.log(response.data);

              if (response.data && response.data.message) {
                successMsg(response.data.message);
              } else {
                errmsg(response.data.error);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              errmsg("Error occurred while inserting role.");
            });
        } else {
          alert("Please fill all data fields.");
        }
      }

    }

  }




  //   ---------------------------------------------------

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };


  useEffect(() => {
    axios
      .get(`${UrlLink}usercontrol/get_Specialities_data`)
      .then((response) => {
        const data = response.data;
        console.log(data);

        setSpecialitiesData(data.filter((p) => p.status === 'Active'));
      })
      .catch((error) => {
        console.error("Error fetching Specialities data:", error);
      });
  }, [UrlLink])

  useEffect(() => {
    if (DoctorsServicedata.specialties) {
        axios
            .get(
                `${UrlLink}usercontrol/get_doctor_info?location=${'ALL'}&Specialization=${DoctorsServicedata.specialties.trim()}`
            )
            .then((response) => {
                const data = response.data;
                setDoctorNames(data || []);
            })
            .catch((error) => { });
    }

}, [DoctorsServicedata.specialties,userRecord?.location])

useEffect(()=>{
if(DoctorsServicedata.DoctorId && doctorNames){
 const finddata= doctorNames.find((p)=>p.EmployeeID===DoctorsServicedata.DoctorId)
 if(finddata){
  setDoctorsServicedata((prev)=>({
    ...prev,
    FirstName:finddata.EmployeeName.split(' ')[0],
    MiddleName:finddata.EmployeeName.split(' ')[1],
    LastName:finddata.EmployeeName.split(' ')[2],
  }))
 }else{
  setDoctorsServicedata((prev)=>({
    ...prev,
    FirstName:'',
    MiddleName:'',
    LastName:'',
  }))
 }
}
},[DoctorsServicedata.DoctorId])
useEffect(()=>{
  setServiceFormdata({
    ServiceId: '',
    ServiceName: '',
    IsTaxAppay: 'No',
    GST: '',
    Status: 'Active',
  })
  setDoctorsServicedata({
    specialties: '',
    DoctorId: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    IsTaxAppay: 'No',
    GST: '',
    Status: 'Active',
  })
  setDoctorNames([])
  // setSpecialitiesData([])
},[SelectFormOption])

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Rate Card Charges</h4>
        </div>

        <div className="Supplier_Master_Container">


          <div className="RegisFormcon">

            <div className="RegisForm_1">
              <label >
                Type <span>:</span>
              </label>
              <select
                value={SelectFormOption}
                onChange={(e) => setSelectFormOption(e.target.value)}
              >
                <option value="services">services</option>
                <option value="Doctors">Doctors</option>
              </select>

            </div>
            {SelectFormOption === 'services' ?
              Object.keys(ServiceFormdata).map((labelname, index) => (
                <div className="RegisForm_1" key={index}>
                  <label>
                    {formatLabel(labelname)} <span>:</span>
                  </label>

                  {labelname === "Status" ? (
                    <select
                      name={labelname}
                      value={ServiceFormdata[labelname]}
                      onChange={handleFormChange}
                    >
                      <option value="">Select</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  ) : labelname === "GST" ? (
                    <select
                      name={labelname}
                      value={ServiceFormdata[labelname]}
                      onChange={handleFormChange}
                      disabled={ServiceFormdata.IsTaxAppay === 'No'}
                    >
                      <option value="">Select</option>
                      <option value="NonGST">Non-GST</option>
                      <option value="5">5%</option>
                      <option value="12">12%</option>
                      <option value="18">18%</option>
                      <option value="28">28%</option>
                    </select>
                  ) : labelname === "IsTaxAppay" ? (
                    <>
                      <label style={{ width: '60px', marginRight: '10px' }}>
                        <input
                          type="radio"
                          name={labelname}
                          value="Yes"
                          checked={ServiceFormdata[labelname] === "Yes"}
                          onChange={handleFormChange}
                          style={{ width: '18px' }}
                        />
                        Yes
                      </label>
                      <label style={{ width: '60px', marginRight: '10px' }}>
                        <input
                          type="radio"
                          name={labelname}
                          value="No"
                          checked={ServiceFormdata[labelname] === "No"}
                          onChange={handleFormChange}
                          style={{ width: '18px' }}
                        />
                        No
                      </label>
                    </>
                  ) : (
                    <input
                      readOnly={labelname === 'ServiceId'}
                      name={labelname}
                      value={ServiceFormdata[labelname]}
                      onChange={handleFormChange}
                    />
                  )}
                </div>
              ))
              :
              Object.keys(DoctorsServicedata).map((labelname, index) => (
                <div className="RegisForm_1" key={index}>
                  <label>
                    {formatLabel(labelname)} <span>:</span>
                  </label>

                  {labelname === "Status" || labelname === 'specialties' ? (
                    <select
                      name={labelname}
                      value={DoctorsServicedata[labelname]}
                      onChange={handleDocFormChange}
                    >
                      <option value="">Select</option>
                      {labelname === "specialties" && (
                        <>
                          {SpecialitiesData.map((p, indx) => (
                            <option value={p.Specialities_name} key={indx}>{p.Specialities_name}</option>
                          ))
                          }
                        </>
                      )}
                      {labelname==='Status'&&
                      <>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </>
                      }
                    </select>
                  ) : labelname === "GST" ? (
                    <select
                      name={labelname}
                      value={DoctorsServicedata[labelname]}
                      onChange={handleDocFormChange}
                      disabled={DoctorsServicedata.IsTaxAppay === 'No'}
                    >
                      <option value="">Select</option>
                      <option value="NonGST">Non-GST</option>
                      <option value="5">5%</option>
                      <option value="12">12%</option>
                      <option value="18">18%</option>
                      <option value="28">28%</option>
                    </select>
                  ) : labelname === "IsTaxAppay" ? (
                    <>
                      <label style={{ width: '60px', marginRight: '10px' }}>
                        <input
                          type="radio"
                          name={labelname}
                          value="Yes"
                          checked={DoctorsServicedata[labelname] === "Yes"}
                          onChange={handleDocFormChange}
                          style={{ width: '18px' }}
                        />
                        Yes
                      </label>
                      <label style={{ width: '60px', marginRight: '10px' }}>
                        <input
                          type="radio"
                          name={labelname}
                          value="No"
                          checked={DoctorsServicedata[labelname] === "No"}
                          onChange={handleDocFormChange}
                          style={{ width: '18px' }}
                        />
                        No
                      </label>
                    </>
                  ) : ['DoctorId'].includes(labelname)?
                  (
                    <>
                     <input
                      name={labelname}
                      list={`${labelname}_1`}
                      value={DoctorsServicedata[labelname]}
                      onChange={handleDocFormChange}
                    />
                    <datalist id={`${labelname}_1`}>
                      {
                        doctorNames.map((p,indx)=>(
                          <option key={indx} value={p.EmployeeID}>{p.EmployeeName}</option>
                        ))
                      }
                    </datalist>
                    </>
                   
                  )
                  :
                  (
                    <input
                      readOnly={['ServiceId','FirstName','MiddleName','LastName'].includes(labelname)}
                      name={labelname}
                      value={DoctorsServicedata[labelname]}
                      onChange={handleDocFormChange}
                    />
                  )
                }
                </div>
              ))
            }
          </div>

          <div className="Register_btn_con">
            <button
              className="RegisterForm_1_btns"
              onClick={Object.keys(RateCardMasterEditdata).length !== 0 ? handleUpdateService : handleServicelist}
            >
              {Object.keys(RateCardMasterEditdata).length !== 0 ? "Update" : "Add"}
            </button>
          </div>

        </div>

      </div>

      <div className="RateCardtype_array_master">
        {Object.keys(Services_data).map(rateCardType => (
          <div className="RateCardtype_array_master_sub" key={rateCardType}>
            <div className="RateCardtype_array_master_sub_div">
              <label htmlFor="">{rateCardType}</label>
            </div>
            <div className="RateCardtype_array_master_sub_div1">
              {Object.keys(Services_data[rateCardType]).map(visitType => (
                <div className="op_ratecharge_div" key={visitType}>
                  <label htmlFor="" style={{ color: '#ff3d3d' }}>{visitType}</label>

                  <div className="op_ratecharge">
                    {
                      Object.keys(Services_data[rateCardType][visitType]).map(insuranceCompany => (
                        <React.Fragment key={insuranceCompany}>
                          {(!(Array.isArray(Services_data[rateCardType][visitType][insuranceCompany])) && !(typeof Services_data[rateCardType][visitType][insuranceCompany] === 'object')) ?
                            '' : <label style={{ color: '#3d51ff' }}>{insuranceCompany}</label>}

                          {(!(Array.isArray(Services_data[rateCardType][visitType][insuranceCompany])) && !(typeof Services_data[rateCardType][visitType][insuranceCompany] === 'object')) ?
                            (

                              <input
                                type="number"
                                onKeyDown={blockInvalidChar}
                                name={Services_data[rateCardType][visitType][insuranceCompany]}
                                value={Services_data[rateCardType][visitType][insuranceCompany]}
                                onChange={(e) =>
                                  setServices_data((prev) => {
                                    let updated = { ...prev };
                                    updated[rateCardType][visitType][insuranceCompany] = e.target.value;
                                    return updated;
                                  })
                                }
                              />
                            ) :

                            Array.isArray(Services_data[rateCardType][visitType][insuranceCompany]) ? (
                              Services_data[rateCardType][visitType][insuranceCompany].map((room, indd) => (
                                <div className="op_ratecharge_con" key={indd}>
                                  {Object.keys(room).map((roomKey, roomKeyIndex) => (
                                    <React.Fragment key={roomKeyIndex}>
                                      <label htmlFor="">{roomKey}  <span>:</span></label>
                                      <input
                                        type="number"
                                        name={roomKey}
                                        onKeyDown={blockInvalidChar}
                                        value={room[roomKey]}
                                        onChange={(e) =>
                                          setServices_data((prev) => {
                                            let updated = { ...prev };
                                            updated[rateCardType][visitType][insuranceCompany][indd][roomKey] = e.target.value;
                                            return updated;
                                          })
                                        }
                                      />

                                    </React.Fragment>
                                  ))}

                                </div>
                              ))
                            ) : (

                              Object.keys(Services_data[rateCardType][visitType][insuranceCompany]).map((data, indd) => (
                                <div className={Array.isArray(Services_data[rateCardType][visitType][insuranceCompany][data]) ? 'op_ratecharge_con_1' : 'op_ratecharge_con'} key={indd}>
                                  {Array.isArray(Services_data[rateCardType][visitType][insuranceCompany][data]) ? <label htmlFor="" style={{ color: 'blue' }}>{data}</label> : ""}
                                  {Array.isArray(Services_data[rateCardType][visitType][insuranceCompany][data]) ? (
                                    Services_data[rateCardType][visitType][insuranceCompany][data].map((room, indd) => (
                                      Object.keys(room).map((roomKey, roomKeyIndex) => (
                                        <div className="op_ratecharge_con" key={roomKeyIndex}>
                                          <label htmlFor="">{roomKey} <span>:</span></label>
                                          <input
                                            type="number"
                                            onKeyDown={blockInvalidChar}
                                            name={roomKey}
                                            value={room[roomKey]}
                                            onChange={(e) =>
                                              setServices_data((prev) => {
                                                let updated = { ...prev };
                                                updated[rateCardType][visitType][insuranceCompany][data][indd][roomKey] = e.target.value;
                                                return updated;
                                              })
                                            }
                                          />
                                        </div>
                                      ))

                                    ))
                                  )
                                    :
                                    <input
                                      type="number"
                                      onKeyDown={blockInvalidChar}
                                      name={Services_data[rateCardType][visitType][insuranceCompany][data]}
                                      value={Services_data[rateCardType][visitType][insuranceCompany][data]}
                                      onChange={(e) =>
                                        setServices_data((prev) => {
                                          let updated = { ...prev };
                                          updated[rateCardType][visitType][insuranceCompany][data] = e.target.value;
                                          return updated;
                                        })
                                      }
                                    />
                                  }

                                </div>
                              ))


                            )}
                        </React.Fragment>
                      ))
                    }

                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>








    </>
  );
}

export default RateCardMaster;
