import React, { useState, useEffect } from "react";
import "./UserRegister.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

const options = [
  { label: "ClinicMetrics", value: "ClinicMetrics" },
  { label: "FrontOffice", value: "FrontOffice" },
  { label: "Nurse", value: "Nurse" },
  { label: "DoctorWorkbench", value: "DoctorWorkbench" },
  { label: "Counselor", value: "Counselor" },
  { label: "Therapist", value: "Therapist" },
  { label: "Pharmacy", value: "Pharmacy" },
  { label: "Cashier", value: "Cashier" },
  { label: "PettyCash", value: "PettyCash" },
  { label: "StockManagement", value: "StockManagement" },
  { label: "UserControl", value: "UserControl" },
  { label: "HRManagement", value: "HRManagement" },
  { label: "Visiting_Doctor", value: "Visiting_Doctor" },
  { label: "EmployeeRequest", value: "EmployeeRequest" },
];

const suboptions = {
  ClinicMetrics: [{ label: "ClinicMetrics", value: "ClinicMetrics" }],
  FrontOffice: [
    { label: "AppointmentCalendar", value: "AppointmentCalendar" },
    { label: "AppoinmentRequest", value: "AppoinmentRequest" },
    { label: "BookingConfimation", value: "BookingConfimation" },
    { label: "OPRegister", value: "OPRegister" },
    { label: "ConcernForms", value: "ConcernForms" },
    { label: "LabReport", value: "LabReport" },
  ],
  Nurse: [
    { label: "ConcernForms", value: "ConcernForms" },
    { label: "PatientQueueList", value: "PatientQueueList" },
  ],
  DoctorWorkbench: [
    { label: "PatientList", value: "PatientList" },
    { label: "PatientQueueList", value: "PatientQueueList" },
  ],
  Counselor: [
    { label: "PatientList", value: "PatientList" },
    { label: "AppointmentCalendar", value: "AppointmentCalendar" },
    { label: "PatientQueueList", value: "PatientQueueList" },
    { label: "CounselorQueueList", value: "CounselorQueueList" },
  ],
  Therapist: [
    { label: "PatientList", value: "PatientList" },
    { label: "PatientQueueList", value: "PatientQueueList" },
  ],
  Pharmacy: [
    { label: "PharmacyBilling", value: "PharmacyBilling" },
    { label: "BillCancellation/Refund", value: "BillCancellation/Refund" },
    { label: "BillingHistory", value: "BillingHistory" },
    { label: "ShiftClosing", value: "ShiftClosing" },
    { label: "DayClosing", value: "DayClosing" },
    { label: "Report", value: "Report" },
  ],
  Cashier: [
    { label: "Billing", value: "Billing" },
    { label: "DueHistory", value: "DueHistory" },
    { label: "BillCancellation", value: "BillCancellation" },
    { label: "BillingHistory", value: "BillingHistory" },
    { label: "ShiftClosing", value: "ShiftClosing" },
    { label: "DayClosing", value: "DayClosing" },
    { label: "Report", value: "Report" },
  ],
  PettyCash: [
    { label: "ExpenseMaster", value: "ExpenseMaster" },
    { label: "CashExpenses", value: "CashExpenses" },
    { label: "DigitalExpenses", value: "DigitalExpenses" },
    { label: "ExpensesReport", value: "ExpensesReport" },
    { label: "HandOverSummary", value: "HandOverSummary" },
    { label: "DayReport", value: "DayReport" },
  ],
  StockManagement: [
    { label: "QuickStock", value: "QuickStock" },
    { label: "Supplierpay", value: "Supplierpay" },
    { label: "SupplierMaster", value: "SupplierMaster" },
    { label: "ProductMaster", value: "ProductMaster" },
    { label: "PurchaseMaster", value: "PurchaseMaster" },
    { label: "GRN", value: "GRN" },
    { label: "IndentApprove", value: "IndentApprove" },
    { label: "GRNApprove", value: "GRNApprove" },
    { label: "IndentRaise", value: "IndentRaise" },
    { label: "IndentRecieve", value: "IndentRecieve" },
    { label: "IndentIssue", value: "IndentIssue" },
  ],
  UserControl: [
    { label: "RoleManagement", value: "RoleManagement" },
    { label: "EmployeeQueueList", value: "EmployeeQueueList" },
    { label: "UserRegister", value: "UserRegister" },
    { label: "RatecardCharges", value: "RatecardCharges" },
    { label: "AccountSettings", value: "AccountSettings" },
    { label: "ClinicDetails", value: "ClinicDetails" },
    { label: "UserList", value: "UserList" },
    { label: "LeaveManagement", value: "LeaveManagement" },
    { label: "AdvanceManagement", value: "AdvanceManagement" },
    { label: "VisitDoctorBilling", value: "VisitDoctorBilling" },
  ],
  HRManagement: [
    { label: "EmployeeRegister", value: "EmployeeRegister" },
    { label: "EmployeeList", value: "EmployeeList" },
    { label: "Attendance", value: "Attendance" },
    { label: "LeaveApproval", value: "LeaveApproval" },
    { label: "AdvanceApproval", value: "AdvanceApproval" },
    { label: "PerformanceAppraisal", value: "PerformanceAppraisal" },
    { label: "PerformanceManagement", value: "PerformanceManagement" },
    { label: "LeaveManagement", value: "LeaveManagement" },
    { label: "AdvanceManagement", value: "AdvanceManagement" },
    { label: "PayRoll", value: "PayRoll" },
  ],
  VisitingDoctor: [
    { label: "VisitingDoctorPatients", value: "VisitingDoctorPatients" },
  ],
  EmployeeRequest: [
    { label: "LeaveManagement", value: "LeaveManagement" },
    { label: "AdvanceManagement", value: "AdvanceManagement" },
  ],
};

function UserRegister() {
  const foruserregisteremployeedata = useSelector(
    (state) => state.userRecord?.foruserregisteremployeedata
  );

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const foredituserregisteremployeedata = useSelector(
    (state) => state.userRecord?.foredituserregisteremployeedata
  );

  const navigate = useNavigate();

  console.log(userRecord);
  console.log(foruserregisteremployeedata);
  const [roleOptions, setRoleOptions] = useState([]);
  const [locationoptions, setlocationOptions] = useState([]);

  const [formData, setFormData] = useState({
    username: "",
    employeeid: "",
    firstName: "",
    lastName: "",
    Title: "",
    PhoneNo: "",
    email: "",
    password: "",
    roleName: "",
    locationname: "",
    gender: "",
    qualification: "",
  });

  useEffect(() => {
    // Check if foruserregisteremployeedata is not empty
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
      });
    }
  }, [foruserregisteremployeedata]);

  const [selected, setSelected] = useState([]);
  const [availableSubOptions, setAvailableSubOptions] = useState([]);
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);

  useEffect(() => {
    fetchRoleOptions();
    fetchlocationOptions();
    fetchUserList();
  }, []);

  useEffect(() => {
    // Check if foruserregisteremployeedata is not empty
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
        // ... other fields
      });
    }
  }, [foruserregisteremployeedata]);

  useEffect(() => {
    // Check if foredituserregisteremployeedata is not empty
    if (
      foredituserregisteremployeedata &&
      foredituserregisteremployeedata.length > 0
    ) {
      const employeeData = foredituserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
        // ... other fields
      });
    }
  }, [foredituserregisteremployeedata]);

  useEffect(() => {
    let newSubOptions = [];
    if (selected.length === options.length) {
      Object.keys(suboptions).forEach((option) => {
        newSubOptions = [...newSubOptions, ...suboptions[option]];
      });
      console.log("newSubOptions", newSubOptions);
      // setSelectedSubOptions(newSubOptions);
      setAvailableSubOptions(newSubOptions);
    } else {
      selected.forEach((selectedOption) => {
        console.log("label", selectedOption);
        console.log("suboptions", suboptions);
        console.log("suboptionslabel", suboptions[selectedOption.label]);
        if (suboptions[selectedOption.label]) {
          newSubOptions = [
            ...newSubOptions,
            ...suboptions[selectedOption.label],
          ];
        }
      });
      console.log("newSubOptions suboptions", newSubOptions);
      setAvailableSubOptions(newSubOptions);
      // setSelectedSubOptions(newSubOptions);
      // const currentSelectedSubOptions = selectedSubOptions.filter((subOption) =>
      //   newSubOptions.includes(subOption)
      // );
      // setSelectedSubOptions(currentSelectedSubOptions);
    }
  }, [selected, suboptions, options.length]);

  const handleChange = (selected) => {
    setSelected(selected);
    if (selected || !selected.length) {
      setSelectedSubOptions([]);
    }
  };

  const handleSubOptionChange = (selectedSubOptions) => {
    setSelectedSubOptions(selectedSubOptions);
  };

  useEffect(() => {
    // Fetch role options from the backend and update state
    fetchRoleOptions();
    fetchlocationOptions();
    // Log all user data when the page reloads
    fetchUserList();
  }, []);

  const fetchlocationOptions = () => {
    // Fetch role options from the backend
    axios
      .get("https://vesoftometic.co.in/usercontrol/getlocationdata")
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetchUserList()
      .then((usernames) => {
        if (usernames === null) {
          // Handle the case when usernames are null
        } else if (usernames.includes(formData.username)) {
          userwarn();
          setFormData({ ...formData, username: "" });
        } else {
          const datatosend = {
            ...formData,
            selected: selected.map((p) => p.value),
            subselected: selectedSubOptions.map((p) => p.value),
          };
          console.log(datatosend);

          const url = "https://vesoftometic.co.in/usercontrol/insert_userregister";
          axios
            .post(url, datatosend)
            .then((response) => {
              if (response.data.message) {
                successMsg(response.data.message);
                setFormData({
                  username: "",
                  employeeid: "",
                  firstName: "",
                  lastName: "",
                  Title: "",
                  PhoneNo: "",
                  email: "",
                  password: "",
                  roleName: "",
                  locationname: "",
                  gender: "",
                  qualification: "",
                });

                fetchRoleOptions();

                navigate("/Home/Employee-List");
              } else {
                console.log(response.data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        "https://vesoftometic.co.in/usercontrol/userRegistercheck"
      );
      const data = response.data;
      console.log(data);
      return data.usernames; // Return only the 'usernames' array from the response data
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };

  const fetchRoleOptions = () => {
    // Fetch role options from the backend
    axios
      .get("https://vesoftometic.co.in/usercontrol/getRoleData")
      .then((response) => {
        console.log(response);
        setRoleOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching role options:", error);
      });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const fail = () => {
    toast.warn("User Registration Failed", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = () => {
    toast.warn("Username already Exists", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleinpchange = (e) => {
    const { name, value } = e.target;
    if (name === "PhoneNo") {
      if (value.length <= 10) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>User Registration</h4>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="username">
                Employee Id<span>:</span>
              </label>
              <input
                type="text"
                id="employeeid"
                name="employeeid"
                value={formData.employeeid}
                onChange={handleinpchange}

                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="username">
                Username<span>:</span>
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleinpchange}

                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="password">
                Password<span>:</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleinpchange}

                required
              />
            </div>


            <div className="RegisForm_1">
              <label htmlFor="username">
                Title<span>:</span>
              </label>
              <input
                name="Title"
                value={formData.Title}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="firstName">
                First Name<span>:</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"

                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="lastName">
                Last Name<span>:</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"

                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="Dateofbirth">
                Gender<span>:</span>
              </label>
              <input
                type="text"
                value={formData.gender}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="PhoneNo">
                Phone No<span>:</span>
              </label>
              <input
                type="number"
                name="PhoneNo"
                value={formData.PhoneNo}
                onChange={handleinpchange}

                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="Qualification">
                Qualification<span>:</span>
              </label>
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleinpchange}

                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="email">
                Email<span>:</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleinpchange}

                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">
                Location<span>:</span>
              </label>
              <select
                name="locationname"
                value={formData.locationname}
                onChange={handleinpchange}
              >
                {/* Render an option for each location option */}
                {locationoptions.map((location, index) => (
                  <option key={index} value={location.location_name}>
                    {location.location_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">
                Role<span>:</span>
              </label>
              <input
                name="role"
                value={formData.roleName}
                onChange={handleinpchange}
              />
            </div>
          </div>

          <br />
          <div className="RegisFormcon flex_acrss_usr_regis3">
            <div className="flex_acrss_usr_regis3_label">
              <label htmlFor="role">Access<span>:</span></label>
              <MultiSelect
                options={options}
                value={selected}
                onChange={handleChange}
                labelledBy="Select"
                className="customMultiSelect"
              />
            </div>

            <div className="flex_acrss_usr_regis3_label">
              <label htmlFor="role">Access for Sub<span>:</span></label>
              <MultiSelect
                options={availableSubOptions}
                value={selectedSubOptions}
                onChange={handleSubOptionChange}
                labelledBy="Select"
                className="customMultiSelect"
              />
            </div>
            <div className="RegisForm_1" id="hide_div_regg"></div>
          </div>
        </div>

        <div className="Register_btn_con" style={{ marginTop: "20px" }}>
          <button onClick={handleSubmit} className="RegisterForm_1_btns">
            Submit
          </button>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        containerId="toast-container-over-header"
      />
    </>
  );
}

export default UserRegister;
