import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";


import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function OtConsentForm() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const navigate = useNavigate()
  const dispatchvalue = useDispatch();
  const [Consentformdata,setConsentformdata] = useState([])

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);



  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 130 },
    {
      field: "Patient_Photo",
      headerName: "Patient Photo",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value}
          // src={`data:image/jpg;base64, ${params.value}`}
          alt="Employee"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
    },
    {  field: "MRN_NO", headerName: "Booking ID / MRN No", width: 150 },
    { field: "Patient_Name", headerName: "Patient Name", width: 150 },
    { field: "Phone_No", headerName: "Phone No", width: 120 },
    { field: "Gender", headerName: "Gender", width: 120 },
    { field: "DoctorName", headerName: "Primary Doctor", width: 120 },
    { field: "TheatreNo", headerName: "Theatre No", width: 120 },
    { field: "Surgery_Name", headerName: "Surgery Name", width: 120 },
    { field: "Surgery_Date", headerName: "Surgery Date", width: 120 },
    
    {
      field: "View ",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <>
        {params.value ? (
          <Button className="cell_btn" >
            {/* onClick={() => handleList(params)} */}
            <VisibilityIcon />
          </Button>
        ) : (
          <span>Not Uploded</span>
        )}
          
        </>
      ),
    },
    {
      field: "proceed ",
      headerName: "Proceed",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" >
            {/* onClick={() => handleEditList(params)} */}
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);



  useEffect(() => {
    axios.get(`${urllink}OTManagement/get_for_otconsentformqueuelist`)
      .then((response) => {
        console.log(response.data)
        const data = response.data;

        setConsentformdata([
          ...data.map((row, index) => ({
            id: index + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.error(error);
      })
  },[])


  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    console.log(Consentformdata);

    const filteredData = Consentformdata.filter((row) => {
      const lowerCaseSupplierName = row.Patient_Name.toLowerCase();
      const lowerCasePhoneNo = row.Phone_No.toString();
      const lowerCaseQuery = searchQuery.toLowerCase();
      const lowerCaseQuery1 = searchQuery1.toLowerCase();


      const matchesFirstName = lowerCaseSupplierName.includes(lowerCaseQuery);


      const matchesPhoneNo = lowerCasePhoneNo.includes(lowerCaseQuery1);

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, Consentformdata]);



  return (
    <div className="appointment">
      <div className="h_head">
        <h4>OT Consent Form</h4>
      </div>
      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">
            First Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Enter the First Name"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Phone No <span>:</span>
          </label>
          <input
            type="text"
            id="PhoneNo"
            value={searchQuery1}
            //   onChange={handleSearchChange}
            placeholder="Enter the Phone No"
          />
        </div>
      </div>

      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>

        {filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default OtConsentForm;
