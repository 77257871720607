import React, { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TestMaster.css';
import axios from 'axios';
import { useSelector } from 'react-redux';

function AddInterpretation() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [header,setheader] = useState('')
  const [comments,setComments] = useState('')
  const [testname,setTestName] = useState('')
  useEffect(()=>{
    axios.get(`${urllink}usercontrol/gettestname`)
    .then((response)=>{
        console.log(response.data)
        const data= response.data.test_name
        setTestName(data);
    })
    .catch((error)=>{
        console.log(error)
    })
},[])


   const handlesubmit=()=>{
    const postdata = {
      testname: testname,
      header: header,
      comments: comments
    }

    if(!header || !comments){
      alert('please enter all inputs');
    }
    else{
      axios.post(`${urllink}usercontrol/insertinterpretation`,postdata)
      .then((response)=>{
        console.log(response);
        setComments('')
        setheader('')
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    


   }

  return (
<>
      <div className="appointment">
            <div className='RegisFormcon'>
                 <div className="RegisForm_1">
                    <label htmlFor="validation">Header For interpretation <span>:</span></label>
                    <textarea id="remarks" name="remarks" value={header} onChange={(e)=>{setheader(e.target.value)}} className='custom-textarea' ></textarea>
                </div>

                <div className="RegisForm_1">
                    <label htmlFor="remarks">Comments<span>:</span></label>
                    <textarea id="remarks" name="remarks" value={comments} onChange={(e)=>{
                      setComments(e.target.value)
                    }} className='custom-textarea' ></textarea>
                </div>
              
               
             
                
            </div>
            
          
            
          <div className='Register_btn_con'>
            <button className='RegisterForm_1_btns' onClick={handlesubmit} >Submit</button>
          </div>
        </div>
    </>
  )
}

export default AddInterpretation;
