import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});


const PaymentHistory = () => {
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: '',
    ClinicGST: '',
    ClinicAddress: '',
    ClinicCity: '',
    ClinicState: '',
    ClinicCode: '',
    ClinicMobileNo: '',
    ClinicLandLineNo: '',
    ClinicMailID: '',
    InvoiceNo: ''
});


  useEffect(() => {
    // Fetch payment history data from the backend using Axios
    axios.get(`${urllink}Billing/getpatienthistorydata`)
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index +1, // Assuming you want to use Patient_Id as id
          ...row,
        }));
        setFilteredRows(data);
        console.log("Backend Data:", data);
      })
      .catch((error) => {
        console.error('Error fetching payment history data:', error);
      });
  }, []);

  const dynamicColumns = [
       { field: 'BillingAmount_Billing_Invoice', headerName: 'Billing Invoice', width: 130 },

    { field: 'Patient_Name', headerName: 'Patient Name', width: 120 },
    // { field: 'Address', headerName: 'Address', width: 200 },
    { field: 'Phone', headerName: 'Phone', width: 110 },
    { field: 'Refering_Doctor', headerName: 'Reference Doctor', width: 120 },
    { field: 'BillingAmount_Total_Charge', headerName: 'Charge', width: 100 },
    { field: 'BillingAmount_Discount', headerName: 'Discount', width: 80 },
    { field: 'BillingAmount_Item_Count', headerName: 'Test Count', width: 80 },
    { field: 'BillingAmount_Net_Amount', headerName: 'Net Total', width: 80 },
    { field: 'PaymentDetails_Paid_Amount', headerName: 'Received', width: 80 },
    { field: 'PaymentDetails_Remaining_Amount', headerName: 'Duepending', width: 80 },
    { field: 'BillingAmount_Status', headerName: 'Bill Status', width: 130 },
    {
      field: 'PaymentDetails_Billing_Invoice',
      headerName: 'Option',
      width: 100,
      renderCell: (params) => (
        <FontAwesomeIcon icon={faPrint} color="#54d854" size="lg" />
      ),
    },
  ];

   


//   const forPrintData = () => {
//     return (
//         <div className="billing-invoice" id="reactprintcontent">
//             <div className="New_billlling_invoice_head">
//                 <div className="new_billing_logo_con">
//                     <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
//                 </div>
//                 <div className="new_billing_address_1 ">
//                     <span>{ClinicDetials.ClinicName}</span>
//                     <div>

//                         <span >{ClinicDetials.ClinicAddress},</span>
//                         <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
//                     </div>
//                     <div>
//                         <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
//                         <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
//                         <span>{ClinicDetials.ClinicMailID}</span>
//                     </div>

//                 </div>

//             </div>
//             <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
//                 Billing Invoice
//             </div>
//             <div className='Register_btn_con_barcode'>
//             <Barcode value={barcode || ''} lineColor="black" height={40} width={1.2} fontSize={12} />
//           </div>
           
//             <div className="new_billing_address">
//                 <div className="new_billing_address_2">
//                     <div className="new_billing_div">
//                         <label>Patient Name <span>:</span></label>
//                         <span>{patientdetails.patientname}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Visit ID <span>:</span></label>
//                         <span>{visitid}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Age <span>:</span></label>
//                         <span>{patientdetails.age}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Gender <span>:</span></label>
//                         <span>{patientdetails.gender}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Address <span>:</span></label>
//                         <span>{patientdetails.Address}</span>
//                     </div>

//                 </div>
                
//                 <div className="new_billing_address_2">
//                     <div className="new_billing_div">
//                         <label>Invoice No <span>:</span></label>
//                         <span>{invoiceno}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>GSTIN No <span>:</span></label>
//                         <span>{ClinicDetials.ClinicGST}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Physician Name <span>:</span></label>
//                         <span>{patientdetails.Referingdoctor}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label>Date <span>:</span></label>
//                         <span>{Billing_date}</span>
//                     </div>
//                     <div className="new_billing_div">
//                         <label> Patient Mobile No <span>:</span></label>
//                         <span>{patientdetails.PhoneNo}</span>
//                     </div>
//                 </div>
//             </div>


//             <div className="new_billing_invoice_detials">
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>S.No</th>
//                             <th>Test Method</th>
//                             <th>Service Name</th>
//                             {/* <th>Unit</th> */}
//                             <th>Amount</th>
//                             {/* <th>Discount</th>
//                             <th>Total</th> */}
//                         </tr>
//                     </thead>
//                     <tbody>

//                     {testdetails.map((row, index) => (
//                       <tr key={index}>
//                         <td>{index + 1}</td>{" "}
//                         <td>{row.test_code}</td>
//                         <td>{row.test_name}</td>
                        
//                         <td>{row.cost_per_reportable}</td>
                      
//                       </tr>
//                     ))}
//                     </tbody>
//                 </table>
//             </div>
//             <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: 'auto' }}>

//                 <div className="invoice_detials_total_1 neww_invoicedetials">
//                     <div className="total_con_bill">
//                         <div className="bill_body">
//                             <label> Items Count <span>:</span></label>
//                             <span>{testdetails.length}</span>
//                         </div>
//                         {/* <div className="bill_body">
//                             <label> Unit <span>:</span></label>
//                             <span>{orderdetails.totalUnits}</span>
//                         </div> */}
//                         <div className="bill_body">
//                             <label> Amount <span>:</span></label>
//                             <span>{orderdetails.grossAmount}</span>
//                         </div>
//                         <div className="bill_body">
//                             <label> Discount <span>:</span></label>
//                             <span>{initialState.totalDiscount}</span>
//                         </div>
                        
//                     </div>
//                     <div className="total_con_bill">
//                         <div className="bill_body">
//                             <label> Billed By <span>:</span></label>
//                             <span>admin</span>
//                         </div>
                        

                      
                    
//                         <div className="bill_body">
//                             <label>Net Amount <span>:</span></label>
//                             <span>{orderdetails.netAmount}</span>
//                         </div>
//                         <div className="bill_body">
//                             <label> Payment type <span>:</span></label>
//                             <span>{orderdetails.paymentMethod} </span>
//                         </div>
//                         <div className="bill_body">
//                             <label> Recived <span>:</span></label>
//                             <span>{(initialState.totalrecived)} </span>
//                         </div>


//                     </div>

//                 </div>
//                 <div className="invoice_detials_total_1 neww_invoicedetials">
//                     <div className='total_con_bill'>
//                         <div className="bill_body" style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
//                             <label> Amount In Words<span>:</span></label>
//                             <span style={{ color: 'grey' }}>{numberToWords(+orderdetails.netAmount)} </span>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="signature-section909">
//                     <p className='disclaimer23'>
//                         This page is created automatically without a signature.
//                     </p>
//                 </div>
//             </div>

//         </div>
//     );
// }




  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h3>Payment History</h3>
        </div>

        <div className='grid_1'>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns}
              pageSize={pageSize}
              pageSizeOptions={[pageSize]}
              onPageChange={(newPage) => setPage(newPage)}
              hideFooterPagination
              hideFooterSelectedRowCount
              className='data_grid'
            />
            {filteredRows.length > pageSize && (
              <div className='IP_grid_foot'>
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {Math.ceil(filteredRows.length / pageSize)}
                <button
                  onClick={() => setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                  disabled={page === Math.ceil(filteredRows.length / pageSize) - 1}
                >
                  Next
                </button>
              </div>
            )}
          </ThemeProvider>
          {filteredRows.length === 0 && (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentHistory;
