import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";


const AdvanceRequest = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const navigate = useNavigate();
  console.log('userRecord :', userRecord)
  const [openModal, setOpenModal] = useState(false);
  const [advancedetails, setadvancedetails] = useState([])
  const [page, setPage] = useState(0);
  const pageSize = 5;
  const [advanceamountdata, setadvanceamountdata] = useState([]);

  const totalPages = Math.ceil(advanceamountdata.length / pageSize);

  const paginatedData = advanceamountdata.slice(page * pageSize, (page + 1) * pageSize);


  const [formData, setFormData] = useState({
    employeeId: '',
    employeeName: '',
    designation: '',
    reqdate: '',
    reqAmount: '',
    reason: '',
    location: userRecord.location,
    createdby: userRecord.First_Name
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  useEffect(() => {
    const employeeId = userRecord.EmployeeId;

    // Check if employeeId is available (not empty) before making the API request
    if (employeeId) {
      axios.get(`${urllink}HRmanagement/employeedetails_forleave?employeeid=${employeeId}&location=${userRecord.location}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.error) {
            alert(response.data.error)
            setFormData({
              employeeName: '',
              designation: '',
              employeeId: '',
            });
          }
          else {

            setFormData({
              employeeName: response.data?.employeeName || '',
              designation: response.data?.designation || '',
              employeeId: response.data?.employeeId || '',
              location: userRecord.location,
              createdby: userRecord.First_Name
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("EmployeeId must be 9 characters in length");
    }


  }, [formData.employeeId, userRecord]);


  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${urllink}HRmanagement/insert_advance_register`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log('response', response);
        alert(response.data.message);

      })
      .catch((error) => {
        console.error(error);
      });

  }

  console.log(userRecord)
  console.log('formData', formData)




  useEffect(() => {

    axios.get(`${urllink}HRmanagement/get_previousadvanceampunt?EmployeeId=${userRecord?.EmployeeId}`)
      .then((response) => {
        console.log(response.data)
        setadvanceamountdata(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [userRecord?.EmployeeId])


  const handleVisibilityClick = (params) => {
    console.log(params)
    setOpenModal(true)
    axios.get(`${urllink}HRmanagement/get_advancedetails_for_employee?employeeid=${params?.EmployeeID}`)
      .then((res) => {
        console.log(res);
        setadvancedetails(res.data)
      })
      .catch((err) => {
        console.error(err);

      })
  }

  return (

    <div className='appointment'>
      <div className="Add_items_Purchase_Master">
        <span>Prev Advance Details</span>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Request Date</th>
              <th id="slectbill_ins">Advance Amount</th>
              <th id="slectbill_ins">Status</th>
              <th id="slectbill_ins">View Installment Status</th>

            </tr>
          </thead>
          <tbody>
            {advanceamountdata.length > 0 && advanceamountdata.map((leave, index) => (
              <tr key={index}>
                <td>{leave.RequestDate}</td>
                <td>{leave.RequestAmount}</td>
                <td>{leave.Status}</td>
                <td>
                  {leave.Status === 'Approved' ? (
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleVisibilityClick(leave)}
                    startIcon={<VisibilityIcon />}
                  >
                    View
                  </Button>
                  ) : 'None'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      {totalPages > 1 && (
        <div className="grid_foot">
          <button
            onClick={() =>
              setPage((prevPage) => Math.max(prevPage - 1, 0))
            }
            disabled={page === 0}
          >
            Previous
          </button>
          Page {page + 1} of {totalPages}
          <button
            onClick={() =>
              setPage((prevPage) =>
                Math.min(prevPage + 1, totalPages - 1)
              )
            }
            disabled={page === totalPages - 1}
          >
            Next
          </button>
        </div>
      )}
      <br />
      <div className="Add_items_Purchase_Master">
        <span> Advance Request Form</span>
      </div>
      <br />
      <div className="RegisFormcon ">
        <div className='RegisForm_1 '>
          <label htmlFor="employeeId" >Employee ID <span>:</span></label>
          <input type="text" id="employeeId" name="employeeId" onChange={handleChange} value={userRecord.EmployeeId} />
        </div>
        <div className='RegisForm_1 '>
          <label htmlFor="employeeName" >Employee Name <span>:</span></label>
          <input type="text" id="employeeName" name="employeeName" onChange={handleChange} value={formData.employeeName} />
        </div>

        <div className='RegisForm_1 '>
          <label htmlFor="designation" >Designation <span>:</span></label>
          <input type='text' name='designation' value={formData.designation} />
        </div>


        <div className='RegisForm_1 '>
          <label htmlFor="reqdate" >Request Date <span>:</span></label>
          <input type="date" id="reqdate" name="reqdate" onChange={handleChange} />
        </div>


        <div className='RegisForm_1 '>
          <label htmlFor="reqAmount" >Request Amount <span>:</span></label>
          <input type="text" id="reqAmount" name="reqAmount" onChange={handleChange} />
        </div>


        <div className='RegisForm_1 '>
          <label htmlFor="reason" >Reason <span>:</span></label>
          <textarea type="text" id="reason" name="reason" onChange={handleChange} />
        </div>
      </div>

      <div className="Register_btn_con">
        <button className='RegisterForm_1_btns' onClick={handleSubmit}>Submit</button>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwPopupforreason uwagduaguleaveauto foradvanceview" onClick={(e) => e.stopPropagation()}>
            <div className='appointment'>
              <div className='h_head'>
                <h4>Installment Details</h4>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Approved Amount</th>
                      <th id="slectbill_ins">Approved Date</th>
                      <th id="slectbill_ins">No.of Installment</th>
                      <th id="slectbill_ins">Amount Per Month</th>
                      <th id="slectbill_ins">No.of Month Paid</th>
                      <th id="slectbill_ins">Installment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log(advancedetails)}
                    {advancedetails?.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.RequestAmount}</td>
                        <td>{employee.IssuedDate}</td>
                        <td>{employee.RepaymentDue}</td>
                        <td>{employee.AmountDeductPerMonth}</td>
                        <td>{employee.No_of_MonthPaid}</td>
                        <td>{employee.Installment_Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={() => setOpenModal(false)}>
                Close
              </button>
            </div>
            </div>

          </div>
        </div>
      )}
    </div>

  )
}

export default AdvanceRequest;
