import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from 'axios';
import { useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";



// const theme = createTheme({
//   components: {
//     MuiDataGrid: {
//       styleOverrides: {
//         columnHeader: {
//           backgroundColor: "var(--ProjectColor)",
//           textAlign: 'Center',
//         },
//         root: {
//           "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
//             textAlign: 'center',
//             display: 'flex !important',
//             justifyContent: 'center !important'
//           },
//           "& .MuiDataGrid-window": {
//             overflow: "hidden !important",
//           },
//         },
//         cell: {
//           borderTop: "0px !important",
//           borderBottom: "1px solid  var(--ProjectColor) !important",
//           display: 'flex',
//           justifyContent: 'center'
//         },
//       },
//     },
//   },
// });

const AddvaceManagement = () => {

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const [filteredRows, setFilteredRows] = useState([]);
  const [advanceRows, setAdvanceRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [advancedetails, setadvancedetails] = useState([])


  console.log(userRecord);






  const [rolename, setRolename] = useState([])


  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/getRole_all`
      )
      .then((response) => {
        console.log(response.data);
        setRolename(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }, [userRecord?.location]);



  useEffect(() => {
    fetchLeaveData();
  }, [userRecord?.location]);

  const fetchLeaveData = () => {
    fetch(`${urllink}HRmanagement/get_all_advance_register?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          console.log(data)
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.Designation,
            reqdate: userdata.RequestDate,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
            installment: userdata.RepaymentDue,
            status: userdata.Status,
            rejectreason: userdata.RejectReason,
            approvedby: userdata.ApproverName,
            issueddate: userdata.IssuedDate,
            issuedby: userdata.IssueverName,
            Installment_Status: userdata?.Installment_Status
          }));
          setAdvanceRows(Records);

          console.log(Records)
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [searchQuery, setsearchQuery] = useState('')
  const [searchQuery1, setsearchQuery1] = useState('')

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();

    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

      if (!searchQuery && !searchQuery1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }

      return nameMatches && designationMatches;
    };

    const filteredData = advanceRows.filter(filterRow);


    setFilteredRows(filteredData);

  }, [searchQuery, advanceRows, searchQuery1]);

  const handleview = (params) => {
    console.log(params)
    setOpenModal(true)
    axios.get(`${urllink}HRmanagement/get_advancedetails_for_employee?employeeid=${params?.employeeid}`)
      .then((res) => {
        console.log(res);
        setadvancedetails(res.data)
      })
      .catch((err) => {
        console.error(err);

      })
  }

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Advance Management </h4>
      </div>
      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">Employee Name <span>:</span></label>
          <input
            type="text"
            id="date"
            name='employeeName'
            placeholder='Enter Employee Name'
            value={searchQuery}
            onChange={(e) => setsearchQuery(e.target.value)}
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">Designation <span>:</span></label>
          <select
            name="designation"
            value={searchQuery1}
            onChange={(e) => setsearchQuery1(e.target.value)}
            className="new-custom-input-phone vital_select"
            required
          >
            <option value="select">Select </option>
            {rolename.map((role) => (
              <option key={role.role_id} value={role.role_name}>
                {role.role_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Employee ID</th>
              <th id="slectbill_ins">Employee Name</th>
              <th id="slectbill_ins">Designation</th>
              <th id="slectbill_ins">Issued Date</th>
              <th id="slectbill_ins">Request Amount</th>
              {/* <th id="slectbill_ins">Reason</th> */}
              <th id="slectbill_ins">No.of.Installment</th>
              <th id="slectbill_ins">Status</th>
              {/* <th id="slectbill_ins">Reject Reason</th> */}
              {/* <th id="slectbill_ins">Issued By</th> */}
              <th id="slectbill_ins">View</th>
            </tr>
          </thead>
          <tbody>
            {filteredRows.length > 0 && filteredRows.map((leave, index) => (
              <tr key={index}>
                <td>{leave.employeeid}</td>
                <td>{leave.employeename}</td>
                <td>{leave.designation}</td>
                <td>{leave.issueddate}</td>
                <td>{leave.reqamount}</td>
                {/* <td>{leave.reason}</td> */}
                <td>{leave.installment}</td>
                <td>{leave.status}</td>
                {/* <td>{leave.rejectreason || '-'}</td> */}
                {/* <td>{leave.issuedby}</td> */}
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleview(leave)}
                    startIcon={<VisibilityIcon />}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwPopupforreason uwagduaguleaveauto foradvanceview" onClick={(e) => e.stopPropagation()}>
            <div className='appointment'>
              <div className='h_head'>
                <h4>Installment Details</h4>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Approved Amount</th>
                      <th id="slectbill_ins">Approved Date</th>
                      <th id="slectbill_ins">No.of Installment</th>
                      <th id="slectbill_ins">Amount Per Month</th>
                      <th id="slectbill_ins">No.of Month Paid</th>
                      <th id="slectbill_ins">Installment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log(advancedetails)}
                    {advancedetails?.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.RequestAmount}</td>
                        <td>{employee.IssuedDate}</td>
                        <td>{employee.RepaymentDue}</td>
                        <td>{employee.AmountDeductPerMonth}</td>
                        <td>{employee.No_of_MonthPaid || 0}</td>
                        <td>{employee.Installment_Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={() => setOpenModal(false)}>
                  Close
                </button>
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default AddvaceManagement;


