import * as React from "react";
import { useState ,useEffect} from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

export default function EmergencyExamination() {

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  console.log("jjjjjjjjj", IpNurseQueSelectedRow);
  const [examinationData, setExaminationData] = useState({
    Date: "",
    Time: "",
    Pallor: "",
    Jaundice: "",
    Cyanosis: "",
    Clubbing: "",
    Lymphadenopathy: "",
    Oedema: "",
    Thyomegaly: "",
    Breast: "",
    info: "",
    Ctvs: "",
    RespiratorySystem: "",
    Cns: "",
    AbdomenPelvis: "",
    SystemAffected: "",
    InvestigationDate: "",
    Investigation: "",
    Report: "",
    ProvisionalDiagnosis: "",
    
    // Preventive: "",
    // Curative: "",
    // investigation: "",
    // Rehabilitative: "",
    // Palliative: "",
  });


const [selectedExamination, setSelectedExamination] = useState([]);

useEffect(() => {
  getExamination();
}, [IpNurseQueSelectedRow]);

console.log(selectedExamination)
  const [checkboxvalues,setCheckboxvalues] = useState({
    Preventive: false,
    Curative: false,
    investigation: false,
    Rehabilitative: false,
    Palliative: false,
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setExaminationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setCheckboxvalues((prevData) => ({
      ...prevData,
      [name]: checked ? value : "", // Clear the value if unchecked
    }));
  };


  const getExamination = () => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios.get(`https://vesoftometic.co.in/EmergencyManagement/get_Examination?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&Location=${userRecord?.location}`)
        .then((response) => {
          console.log(response.data)
          // if (response.data && response.data.length > 0) {
            setSelectedExamination(response.data);
          // } else {
          //   setSelectedExamination([]); // Clear the state if no data found
          //   toast.warning("No examination data found.");
          // }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          toast.error("Error fetching examination data.");
        });
    }
  };
  


  // const getExamination = () => {
  //   if (IpNurseQueSelectedRow?.Booking_Id) {
  //     axios.get(`https://vesoftometic.co.in/EmergencyManagement/get_Examination?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`)
  //       .then((response) => {
  //         if (response.data && response.data.length > 0) {
  //           setSelectedExamination(response.data);
  //           console.log(response.data,"jhgfdsasdfghjk")
           
  //         } else {
  //           setSelectedExamination([]);
  //           toast.warning("No examination data found.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data: ", error);
  //         toast.error("Error fetching examination data.");
  //       });
  //   }
  
  // }
  
    // useEffect(() => {
    //   getExamination();
    // }, [IpNurseQueSelectedRow]);
  



  // useEffect(() => {
  //   if (ipNurseQueSelectedRow?.Booking_Id) {
  //     axios.get(`https://vesoftometic.co.in/EmergencyManagement/get_Examination?Booking_Id=${ipNurseQueSelectedRow?.Booking_Id}`)
  //       .then((response) => {
  //         setSelectedExamination(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching examination data: ", error);
  //       });
  //   }
  // }, [ipNurseQueSelectedRow]);


















  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setCheckboxvalues(prevState => ({
  //     ...prevState,
  //     [name]: checked
  //   }));
  // };

  const postExaminationData = () => {
   
      const requiredfields = [
        "Date",
        "Time",
        "Pallor",
        "Jaundice",
        "Cyanosis",
        "Clubbing",
        "Lymphadenopathy",
        "Oedema",
        "Thyomegaly",
        "Breast",
        "info",
        "Ctvs",
        "RespiratorySystem",
        "Cns",
        "AbdomenPelvis",
        "SystemAffected",
        "InvestigationDate",
        "Investigation",
        "Report",
        "ProvisionalDiagnosis",
        
        // "Preventive",
        // "Curative",
        // "investigation",
        // "Rehabilitative",
        // "Palliative",
      ];

    const missingFields = requiredfields.filter((field) => !examinationData[field]);

    if (missingFields.length > 0) {
      alert("Please fill in the following fields: " + missingFields.join(", "));
    } else {
      const sendData = {
        ...checkboxvalues,
        ...examinationData,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
        // Add other necessary fields here
      };

      axios
        .post(`https://vesoftometic.co.in/EmergencyManagement/insert_Examination`, sendData)
        .then((response) => {
          console.log(response);
          
          successMsg("Data saved successfully");
          getExamination();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };



  // const fetchData = useCallback(() => {
   

  //   Axios.get(
  //     `https://vesoftometic.co.in/EmergencyManagement/get_Examination?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
  //   )
  //     .then((response) => {
  //       if (response.data) {
  //         setSelectedExamination(response.data);
  //       } else {
  //         setSelectedExamination([]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data: ", error);
  //       toast.error("Error fetching data.");
  //     });
  // }, [formData, Location]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);


  return (
    <>
      <div className="new-patient-registration-form">
              
              <div className="RegisFormcon">
                     <div className="RegisForm_1">
                        <label> Date<span>:</span></label>
                          <input
                              type="date"
                              id="Date"
                              name="Date"
                              value={examinationData.Date}
                              onChange={handleInputChange}
                          />
                    </div>
                    <div className="RegisForm_1">
                        <label htmlFor="Time">Time<span>:</span></label>
                        <input
                          type="time"
                          id="Time"
                          name="Time"
                          value={examinationData.Time}
                          onChange={handleInputChange}
                        />
                   </div>
              </div>
              <br />


              <div className="RegisFormcon">
                  
                  <div className="RegisForm_1">
                      <label htmlFor="Pallor">PALLOR<span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="PallorYes"
                          name="Pallor"
                          value="Yes"
                          checked={examinationData.Pallor === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label >
                          <input
                            type="checkbox"
                            id="PallorNo"
                            name="Pallor"
                            value="No"
                            checked={examinationData.Pallor === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>

                  <div className="RegisForm_1">
                      <label htmlFor="Jaundice">JAUNDICE <span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="JaundiceYes"
                          name="Jaundice"
                          value="Yes"
                          checked={examinationData.Jaundice === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label >
                          <input
                            type="checkbox"
                            id="JaundiceNo"
                            name="Jaundice"
                            value="No"
                            checked={examinationData.Jaundice === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>
            

                  <div className="RegisForm_1">
                      <label htmlFor="Cyanosis">CYNOSIS <span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="CyanosisYes"
                          name="Cyanosis"
                          value="Yes"
                          checked={examinationData.Cyanosis === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            id="CyanosisNo"
                            name="Cyanosis"
                            value="No"
                            checked={examinationData.Cyanosis === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>

                
                  <br/>

              </div>

              <br/>

              <div className="RegisFormcon">

                  <div className="RegisForm_1">
                    <label htmlFor="Clubbing">CLUBBING <span>:</span></label>
                    <div>
                      <label >
                      <input
                        type="checkbox"
                        id="ClubbingYes"
                        name="Clubbing"
                        value="Yes"
                        checked={examinationData.Clubbing === "Yes"}
                        onChange={handleInputChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div>
                      <label >
                        <input
                          type="checkbox"
                          id="ClubbingNo"
                          name="Clubbing"
                          value="No"
                          checked={examinationData.Clubbing === "No"}
                          onChange={handleInputChange}
                        />
                        No
                      </label>
                    </div>
                  
                  </div>
                  <br/>

                  <div className="RegisForm_1">
                      <label htmlFor="Lymphadenopathy">LYMPHADENOPATHY <span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="LymphadenopathyYes"
                          name="Lymphadenopathy"
                          value="Yes"
                          checked={examinationData.Lymphadenopathy === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label >
                          <input
                            type="checkbox"
                            id="LymphadenopathyNo"
                            name="Lymphadenopathy"
                            value="No"
                            checked={examinationData.Lymphadenopathy === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>

                  <div className="RegisForm_1">
                      <label htmlFor="Oedema">OEDEMA <span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="OedemaYes"
                          name="Oedema"
                          value="Yes"
                          checked={examinationData.Oedema === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label >
                          <input
                            type="checkbox"
                            id="OedemaNo"
                            name="Oedema"
                            value="No"
                            checked={examinationData.Oedema === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>
            

                  

              </div>

              <br/>

              <div className="RegisFormcon">

                  <div className="RegisForm_1">
                      <label htmlFor="Thyomegaly">THYROMEGALY <span>:</span></label>
                      <div>
                        <label >
                        <input
                          type="checkbox"
                          id="ThyomegalyYes"
                          name="Thyomegaly"
                          value="Yes"
                          checked={examinationData.Thyomegaly === "Yes"}
                          onChange={handleInputChange}
                          />
                          Yes
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            id="ThyomegalyNo"
                            name="Thyomegaly"
                            value="No"
                            checked={examinationData.Thyomegaly === "No"}
                            onChange={handleInputChange}
                          />
                          No
                        </label>
                      </div>
                    
                  </div>
                  <br/>

                  <div className="RegisForm_1">
                    <label htmlFor="Breast">BREAST <span>:</span></label>
                    <div>
                      <label >
                      <input
                        type="checkbox"
                        name="Breast"
                        value="Normal"
                        checked={examinationData.Breast === "Normal"}
                        onChange={handleInputChange}
                        />
                        Normal
                      </label>
                    </div>
                    <div>
                      <label >
                        <input
                          type="checkbox"
                          name="Breast"
                          value="Abnormal"
                          checked={examinationData.Breast === "Abnormal"}
                          onChange={handleInputChange}
                        />
                        Abnormal
                      </label>
                    </div>
                  
                  </div>
                 

                  

              </div><br/>
                 
                 
                 <div>
                    <div className="RegisFormcon">
                        <h4>If Yes, specify  :</h4>
                    </div><br/>
                    <div className="RegisFormcon">
                        <div className="">
                            
                            
                            <textarea
                              className="edwuedy47y"
                              placeholder="Enter details..."
                              name="info"
                              value={examinationData.info}
                              onChange={handleInputChange}
                            />
                        
                      
                        </div>
                    </div>
                 </div>

              <br/>

              <div>
                  <div className="RegisFormcon">
                      <h4>CTVS :</h4>
                  </div><br/>

                  <div className="RegisFormcon">
                      <div className="">
                        
                        
                          <textarea
                            className="edwuedy47y"
                            placeholder="Enter details..."
                            name="Ctvs"
                            value={examinationData.Ctvs}
                            onChange={handleInputChange}
                          />
                      </div>
                  </div ><br/>
              </div>

              <div>
                  <div className="RegisFormcon">
                    <h4>RESPIRATORY SYSTEM :</h4>

                  </div><br/>

                  <div className="RegisFormcon">
                      <div className="">
                        
                        <textarea
                          className="edwuedy47y"
                          placeholder="Enter details..."
                          name="RespiratorySystem"
                          value={examinationData.RespiratorySystem}
                          onChange={handleInputChange}
                        />
                    </div>
                  </div><br/>
              </div>

              <div>
                  <div className="RegisFormcon">
                    <h4>C.N.S. EXAM:</h4>
                  </div><br/>

                  <div className="RegisFormcon">
                      <div className="">
                          
                          <textarea
                            className="edwuedy47y"
                            placeholder="Enter details..."
                            name="Cns"
                            value={examinationData.Cns}
                            onChange={handleInputChange}
                          />
                      </div>

                  </div>
              </div>  <br/>


              <div>
                    <div className="RegisFormcon">
                      <h4>ABDOMEN & PELVIS</h4>
                    </div><br/>
                    
                    <div className="RegisFormcon">
                        <div className="">
                            
                            
                            <textarea
                              className="edwuedy47y"
                              placeholder="Enter details..."
                              name="AbdomenPelvis"
                              value={examinationData.AbdomenPelvis}
                              onChange={handleInputChange}
                            />
                        </div>
                      </div>  
              </div><br/>

              <div>
                   <div className="RegisFormcon">
                      <h4>DETAILED EXAMINATION OF SYSTEM AFFECTED (additional)</h4>
                    </div><br/>

                    <div className="RegisFormcon">
                        <div className="">
                                <textarea
                                className="edwuedy47y"
                                placeholder="Enter details..."
                                  name="SystemAffected"
                                  value={examinationData.SystemAffected}
                                  onChange={handleInputChange}
                                />
                        </div>
                    </div>


              </div><br/>

              <div >
                   
                  <div className="RegisFormcon">
                    <h4>INVESTIGATION DONE PRIOR TO ADMISSION</h4>
                  </div><br/>

                  <div className="RegisFormcon">
                          <div className="RegisForm_1">
                              <label>Investigation Date<span>:</span></label>
                                <input
                                    type="date"
                                    id="Date"
                                    name="InvestigationDate"
                                    value={examinationData.InvestigationDate}
                                    onChange={handleInputChange}
                                />
                          </div>

                            <div className="RegisForm_1">
                              <label>Investigation<span>:</span></label>
                                  <textarea
                                    placeholder="Enter details..."
                                    name="Investigation"
                                    value={examinationData.Investigation}
                                    onChange={handleInputChange}
                                  />
                            </div>

                            <div className="RegisForm_1">
                            <label>Report<span>:</span></label>
                                  <textarea
                                    placeholder="Enter details..."
                                    name="Report"
                                    value={examinationData.Report}
                                    onChange={handleInputChange}
                                  />
                            </div>

                          
                      </div>
               


              </div><br/>

              
              <div>
                   <div className="RegisFormcon">
                      <h4>PROVISIONAL DIAGNOSIS</h4>
                    </div><br/>

                    <div className="RegisFormcon">
                        <div className="">
                                <textarea
                                className="edwuedy47y"
                                placeholder="Enter details..."
                                  name="ProvisionalDiagnosis"
                                  value={examinationData.ProvisionalDiagnosis}
                                  onChange={handleInputChange}
                                />
                        </div>
                    </div>


              </div><br/>

              <div className="RegisFormcon">
              <div className="RegisFormcon">
                      <h4>Patient Condition</h4>
                    </div><br/>

                <div className="RegisForm_1">
                        <label>
                            <input
                                type="checkbox"
                                name="Preventive"
                                checked={checkboxvalues.Preventive}
                                onChange={handleCheckboxChange}
                            />Preventive
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="Curative"
                                checked={checkboxvalues.Curative}
                                onChange={handleCheckboxChange}
                            />Curative
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="investigation"
                                checked={checkboxvalues.investigation}
                                onChange={handleCheckboxChange}
                            />Investigation
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="Rehabilitative"
                                checked={checkboxvalues.Rehabilitative}
                                onChange={handleCheckboxChange}
                            />Rehabilitative
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="Palliative"
                                checked={checkboxvalues.Palliative}
                                onChange={handleCheckboxChange}
                            />Palliative
                        </label>

                </div>
              </div>


                  
                 

             
              

              


        

            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={postExaminationData}>Add</button>
            </div>


      <div className="for">
      <div className="custom-header">
        <h4>Examination</h4>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="vital_Twidth">Date</th>
              <th id="vital_Twidth">Time</th>
              <th id="vital_Twidth">Pallor</th>
              <th id="vital_Twidth">Jaundice </th>
              <th id="vital_Twidth">Cyanosis </th>
              <th id="vital_Twidth">Clubbing </th>
              <th id="vital_Twidth">Lymphadenopathy </th>
              <th id="vital_Twidth">Oedema </th>
              <th id="vital_Twidth">Thyomegaly </th>
              <th id="vital_Twidth">Breast </th>
              <th id="vital_Twidth">If Yes, Specify </th>
              <th id="vital_Twidth">Ctvs  </th>
              <th id="vital_Twidth">RespiratorySystem  </th>
              <th id="vital_Twidth">Cns  </th>
              <th id="vital_Twidth">AbdomenPelvis  </th>
              <th id="vital_Twidth">SystemAffected   </th>
              <th id="vital_Twidth">InvestigationDate   </th>
              <th id="vital_Twidth">Investigation   </th>
              <th id="vital_Twidth">Report    </th>
              <th id="vital_Twidth">ProvisionalDiagnosis    </th>
              <th id="vital_Twidth">PatientCondition </th>
            </tr>
          </thead>
          <tbody>
              {Array.isArray(selectedExamination) && selectedExamination.length > 0 ? (
                selectedExamination.map((examination, index) => (
                  <tr key={index}>
                    <td>{examination.Date}</td>
                    <td>{examination.Time}</td>
                    <td>{examination.Pallor}</td>
                    <td>{examination.Jaundice}</td>
                    <td>{examination.Cyanosis}</td>
                    <td>{examination.Clubbing}</td>
                    <td>{examination.Lymphadenopathy}</td>
                    <td>{examination.Oedema}</td>
                    <td>{examination.Thyomegaly}</td>
                    <td>{examination.Breast}</td>
                    <td>{examination.info}</td>
                    <td>{examination.Ctvs}</td>
                    <td>{examination.RespiratorySystem}</td>
                    <td>{examination.Cns}</td>
                    <td>{examination.AbdomenPelvis}</td>
                    <td>{examination.SystemAffected}</td>
                    <td>{examination.InvestigationDate}</td>
                    <td>{examination.Investigation}</td>
                    <td>{examination.Report}</td>
                    <td>{examination.ProvisionalDiagnosis}</td>
                    <td>{examination.checkbox_values_str}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="21">No data available</td>
                </tr>
              )}
           </tbody>

        </table>
      </div>
      <ToastContainer />
    </div>
       
  
      
      </div>
      
     
    </>
  );
}
