import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Ensure you import axios
import { useSelector } from 'react-redux';

const IpLabTest = () => {
  const [testlist, settestlist] = useState([]);
 
  const [selectedtest, setselectedtest] = useState('');
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [summa, setsumma] = useState([]);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  useEffect(() => {
      axios
          .get(`https://lims.vesoftometic.co.in/usercontrol/test_and_group_forpackage`, {
              headers: {
                  'tokenid': '7a5822b9-aaec-4f2c-a672-3e62d537683f',
                  'password': 'U*5EEA/jxPM/@|,$0B>"',
              },
          })
          .then((response) => {
              const data = response.data;
              console.log('data3333333333333', data);
              settestlist(data);
          })
          .catch((error) => {
              console.error("Error fetching test data:", error);
          });
  }, [urllink]);

  const handlechange = (event) => {
      setselectedtest(event.target.value);
  };

  const handleadd = () => {
    const filteredData = testlist
        .filter((p) => p.name === selectedtest)
        .map((item) => ({
            ...item,
            PatientId: IpNurseQueSelectedRow.PatientId
        }));

    setsumma((prev) => [
        ...prev,
        ...filteredData
    ]);
};


  const handleSave = () =>{
    console.log(summa)
    axios.post(`${urllink}labtest/posttestdetailsforintraction`, summa)
    .then((response)=>{
      console.log(response);
      
    })
    .catch((error)=>{
      console.log(error);
      
    })
  }
  return (
      <>
          <div className="form-container22">
              <div className="header-patient33">
                  <h3>Test Master</h3>
              </div>

              <div className="form-row55">
                  <div className="form_row_66 formrow_chse_pic">
                      <label htmlFor="idFile" className="idfile_width">
                          Test Name :
                      </label>

                      <input
                          type="text"
                          list='testlist'
                          onChange={handlechange}
                      />
                      <datalist id='testlist'>
                          {testlist.map((item, index) => (
                              <option key={index} value={item.name}>{item.name}</option>
                          ))}
                      </datalist>
                  </div>

                  <button onClick={handleadd}>Add</button>
              </div>

              <table>
                <thead>
                  <th>S.I</th>
                  <th>Testcode</th>
                  <th>Testname</th>
                </thead>
                <tbody>
                  
                  {summa.map((item, ind)=>(
                    <tr>
                    <td>{ind +1}</td>
                    <td>{item.Test_Code}</td>
                    <td>{item.name}</td>
                    </tr>
                  ))}
                  
                </tbody>
              </table>


              
          </div>
          
          <button onClick={handleSave}>Save</button>
                  </>
  );
}

export default IpLabTest;