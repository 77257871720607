import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import './Neww.css';
import Button from '@mui/material/Button';
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const MasterList= () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedrow,setSelectedrow] =useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    // const [selectedMethodId, setSelectedMethodId] = useState(null);
    const pageSize = 10;
    const dispatchvalue=useDispatch();
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const showdown = filteredRows.length;
  const urllink=useSelector(state=>state.userRecord?.UrlLink)
   

    useEffect(() => {
        axios.get(`${urllink}usercontrol/gettestdescriptiondata`)
            .then((response) => {
                const data = response.data.map((row) => ({
                    id: row.Test_Id,
                    ...row,
                }));
                setsumma(data);
            })
            .catch((error) => {
                console.error('Error fetching test description data:', error);
            });
    }, []);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {  
            const lowerCaseSupplierName = row.Test_Name ? row.Test_Name.toLowerCase() : '';
            return lowerCaseSupplierName.includes(lowerCaseQuery);
        });
        setFilteredRows(filteredData);
    }, [searchQuery, summa]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // const handleEditClick = (params) => {
    //     console.log(params);
    //     setSelectedrow(params.row);
        
    //     // Use the callback function to log the updated state
    //     setSelectedrow((updatedRow) => {
    //         console.log(updatedRow);
    //         dispatchvalue({ type: 'TestMaster', value: updatedRow });
    //         // Assuming '/testmaster' is the route for the TestMaster component
    //         navigate('/Test Master');
    //     });
    // };
    const handleEditClick = (params) => {
        console.log(params);
        
        setSelectedrow((updatedRow) => {
            // Set isEditMode to true and include the original row
            const updatedRowWithEditMode = { ...params.row, isEditMode: true };
            
            // Log the updatedRow
            console.log(updatedRowWithEditMode);
    
            // Dispatch the updatedRow
            dispatchvalue({ type: 'TestMaster', value: updatedRowWithEditMode });
    
            // Navigate to the Test Master component
            navigate('/Test Master');
    
            // Return the updatedRow
            return updatedRowWithEditMode;
        });
    };
    
    

    const dynamicColumns = [
        { field: 'Test_Id', headerName: 'Test_Id', width: 140 },
        { field: 'Test_Name', headerName: 'Test Name', width: 200 },
        { field: 'Test_Code', headerName: 'Test Code', width: 150 },
        { field: 'Department', headerName: 'Department', width: 150 },
        { field: 'Method_Name', headerName: 'Method Name', width: 150 },
        { field: 'Specimen_Name', headerName: 'Specimen Name', width: 150 },
        { field: 'UOM', headerName: 'UOM', width: 150 },
        { field: 'Container_Name', headerName: 'Container', width: 150 },
        {field:'Header',headerName:'Header',width:150},
        { field: 'Display_Text', headerName: 'Display-Text', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={()=>handleEditClick(params)}>
                
        <EditIcon />

                    </Button>
                </>
            ),
        },
    ];

    
     

    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>Master List</h3>
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Test Name :</label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Test Name' />
                    </div>
                    <button className='btn_1' type='submit'>
                        <SearchIcon />
                    </button>
                </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MasterList;
