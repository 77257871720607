import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";



export default function InsuranceClaimDocuments() {




  const navigate = useNavigate();
  

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );


  const[DischargerState,setDischargerState]=useState({
    RequestedAmount:'',
    BillNumber:''
  })

  

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const yourStyles={
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }


  //Discharged

  const [SignedForm, setSignedForm] = useState([
    {
      Name:'SignedForm',
      Document_file: null,
      Document_Remarks: "Signed Claim Form",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [DischargeLetter, setDischargeLetter] = useState([
    {
      Name:'DischargeApproval',
      Document_file:null,
      Document_Remarks: "Discharge Approval Letter",
      Document_Date: "",
      Document_Ack: false,

    },
  ]);

  const [SupportingDocument, setSupportingDocument] = useState([
    {
      Name:'SupportingDocument',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
      Document_Download:false,
    },
  ]);

  

  const [ViewImportantDoc,setViewImportantDoc]=useState([])

  const [ViewOtherDoc,setViewOtherDoc]=useState([])










  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }
  
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;
  
    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };
  

// ------------------------------------------------------------------------------------



  //------

  const handleChangeRowDocuments7 = (index, key, value) => {
    const updatedRows = [...SignedForm];
    updatedRows[index][key] = value;
    setSignedForm(updatedRows);
  };

  const addRow13 = () => {
    setSignedForm((prevRows) => [
      ...prevRows,
      {
        Name:'investigation',
        Document_file:null,
        Document_Remarks: "Signed Claim Form",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow13 = (index) => {
    setSignedForm((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments8 = (index, key, value) => {
    const updatedRows = [...DischargeLetter];
    updatedRows[index][key] = value;
    setDischargeLetter(updatedRows);
  };

  const addRow14 = () => {
    setDischargeLetter((prevRows) => [
      ...prevRows,
      {
        Name:'finalBill',
        Document_file:null,
        Document_Remarks: "Discharge Approval Letter",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow14 = (index) => {
    setDischargeLetter((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments9 = (index, key, value) => {
    const updatedRows = [...SupportingDocument];
    updatedRows[index][key] = value;
    setSupportingDocument(updatedRows);
  };

  const addRow15 = () => {
    setSupportingDocument((prevRows) => [
      ...prevRows,
      {
        Name:'SupportDocument',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,        
      },
    ]);
  };
  const removeRow15 = (index) => {
    setSupportingDocument((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //--------------

  const handleImportDoc = (index, key, value) => {
    const updatedRows = [...ViewImportantDoc];
    updatedRows[index][key] = value;
    setViewImportantDoc(updatedRows);
  };

  const handleOtherDoc = (index, key, value) => {
    const updatedRows = [...ViewOtherDoc];
    updatedRows[index][key] = value;
    setViewOtherDoc(updatedRows);
  };




//   ------------
  
 
  // const DischargeSavebtnFun =()=>{


  //   const MRN = InsuranceUpdatedata.MRN
  //   const ContactNumber=InsuranceUpdatedata.ContactNumber

  //   const Location = userRecord.location 
  //   const createAt = userRecord.username 


  //   // console.log('klkl',SignedForm,DischargeLetter,SupportingDocument,rows16)

  //   const formData = new FormData();

  //   formData.append('MRN', MRN);
  //   formData.append('ContactNumber', ContactNumber);
  //   formData.append('Location', Location);
  //   formData.append('createAt', createAt);

  //   formData.append('SignedForm', JSON.stringify(SignedForm));
  //   formData.append('DischargeLetter', JSON.stringify(DischargeLetter));
  //   formData.append('SupportingDocument', JSON.stringify(SupportingDocument));

    
  //   // Append Document_file from each row to formData
  //   const appendFiles = (formData, rows, prefix) => {
  //       rows.forEach((row, index) => {
  //           if (row && row.Document_file) {
  //               formData.append(`${prefix}[${index}][Document_file]`, row.Document_file);
  //           }
  //       });
  //   };
    
  //   appendFiles(formData, SignedForm, 'SignedForm');
  //   appendFiles(formData, DischargeLetter, 'DischargeLetter');
  //   appendFiles(formData, SupportingDocument, 'SupportingDocument');



  //   axios.post(`https://vesoftometic.co.in/Insurance/Update_DischargeSave_Insurance`,formData)
  //   .then((response) => {
  //       console.log('Form data submitted.',response.data)
        
  //   })
  //   .catch((error) => {
  //       console.error(error);
  //   });

  // }


  const ClaimSavebtnFun =()=>{

    const MRN = InsuranceUpdatedata.MRN
    const ContactNumber=InsuranceUpdatedata.ContactNumber

    const Location = userRecord.location 
    const createAt = userRecord.username 
    
    console.log(SignedForm,DischargeLetter,SupportingDocument,ViewImportantDoc,ViewOtherDoc)

    const formData = new FormData();

    formData.append('MRN', MRN);
    formData.append('ContactNumber', ContactNumber);
    formData.append('Location', Location);
    formData.append('createAt', createAt);

    formData.append('SignedForm', JSON.stringify(SignedForm));
    formData.append('DischargeLetter', JSON.stringify(DischargeLetter));
    formData.append('SupportingDocument', JSON.stringify(SupportingDocument));
    formData.append('ViewImportantDoc', JSON.stringify(ViewImportantDoc));
    formData.append('ViewOtherDoc', JSON.stringify(ViewOtherDoc));



    
    // Append Document_file from each row to formData
    const appendFiles = (formData, rows, prefix) => {
        rows.forEach((row, index) => {
            if (row && row.Document_file) {
                formData.append(`${prefix}[${index}][Document_file]`, row.Document_file);
            }
        });
    };
    
    appendFiles(formData, SignedForm, 'SignedForm');
    appendFiles(formData, DischargeLetter, 'DischargeLetter');
    appendFiles(formData, SupportingDocument, 'SupportingDocument');
    appendFiles(formData, ViewImportantDoc, 'ViewImportantDoc');
    appendFiles(formData, ViewOtherDoc, 'ViewOtherDoc');


  axios.post(`https://vesoftometic.co.in/Insurance/Post_ClaimDocument_Insurance`,formData)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        
    })
    .catch((error) => {
        console.error(error);
    });



  }

// --------------------------------------------------------

useEffect(()=>{

  if (Object.values(InsuranceUpdatedata).length !== 0) {

    axios.get(`https://vesoftometic.co.in/Insurance/get_All_Documents_data`, {
        params: InsuranceUpdatedata.MRN
    }
    )
        .then((response) => {
            console.log('vv1111',response.data);

            let IMDocments =response.data.Important_document_data

            let othDocment=response.data.other_document_data


            const updatedIMDocments = IMDocments.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });
            
            const updatedothDocment = othDocment.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });

            if(updatedIMDocments.length !==0){
            setViewImportantDoc(updatedIMDocments)
            }
            if(updatedothDocment.length !==0){
            setViewOtherDoc(updatedothDocment)
            }

        })
        .catch((error) => {
            console.log(error);
        });
   }

  

},[InsuranceUpdatedata])
 

// ------------------File

const handleVisibilityClick = async (ConsentForm) => {
  console.log('ConsentForm', ConsentForm.type);

  if (ConsentForm.type !== "application/pdf") {
    const fileURL = URL.createObjectURL(ConsentForm);
    setModalContent(fileURL);
    setModalIsOpen(true);
  } else {
    // Convert the PDF file to a base64 string
    const base64String = await readFileAsBase64(ConsentForm);
    const Pdffile = `data:application/pdf;base64,${base64String}`;
    console.log('Pdffile', Pdffile);
    setModalContent(Pdffile);
    setModalIsOpen(true);
  }
};

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

const closeModal = () => {
  setModalIsOpen(false);
  setModalContent('');
};

  return (
    <>
     <div className="Supplier_Master_Container">
    
       
        <div className="RegisFormcon column_regisFormcon_forinsurance">
            {/* <div className="h_heade34">
            <h3>Cl Documents</h3>
            </div> */}

            <div className="Selected-table-container">
            <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                <tr>
                    <th className="Provisional_Diagnosis">
                    File Name
                    </th>
                    <th className="ICD_Code">Remarks</th>
                    <th className="ICD_Code">Submitted Date</th>
                    <th className="ICD_Code">Couriered</th>
                    <th className="ICD_Code">Action</th>
                </tr>
                </thead>
                <tbody>
                {SignedForm.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {SignedForm[index]['Document_file'] === null ?<span>{row.Document_Remarks}</span>:
                        <span style={{color:'blue',cursor:'pointer'}} onClick={() => handleVisibilityClick(SignedForm[index]['Document_file'])}>{row.Document_Remarks}</span>}
                     <label className="file-labelx">                      
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments7(
                                index,
                                "Document_file",
                                file
                            )}}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                    {row.Document_Remarks}
                    </td>
                    <td>
                    {row.Document_Date || '-'}
                    </td>
                    
                    <td>
                     {row.Document_file === null  ?'-'
                       : <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments7(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )}
                        />
                     }
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow13}
                        >
                        <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow13(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {DischargeLetter.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {DischargeLetter[index]['Document_file'] === null ?<span>{row.Document_Remarks}</span>:
                    <span style={{color:'blue',cursor:'pointer'}} onClick={() => handleVisibilityClick(DischargeLetter[index]['Document_file'])}>{row.Document_Remarks}</span>}
                    <label className="file-labelx">
                         <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments8(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        {row.Document_Remarks}
                    </td>
                    <td>
                       {row.Document_Date || '-'}                       
                    </td>
                    <td>
                    {row.Document_file === null ?'-'
                       :<input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments8(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />}
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow14}
                        >
                        <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow14(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {ViewImportantDoc.length !== 0 ?
                  ViewImportantDoc.map((row, index) => (
                    <tr key={index}>
                    <td>
                        <span style={{color:'blue',cursor:'pointer'}} onClick={() => handleVisibilityClick(ViewImportantDoc[index]['Document_file'])}>
                        {ViewImportantDoc[index]['Name']}
                        </span>
                    </td>
                    <td>
                        {ViewImportantDoc[index]['Document_Remarks'] || '-'}
                    </td>
                    <td>
                        {ViewImportantDoc[index]['Document_Date'] || '-'}
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleImportDoc(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td>
                       -
                    </td>
                    </tr>
                 ))
                :""
                }
               
                </tbody>
            </table>
            </div>


        <div className="h_heade34">
            <h3>Supporting Documents</h3>
        </div>

            <div className="Selected-table-container">
            <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                <tr>
                    <th className="Provisional_Diagnosis">
                    File Name
                    </th>
                    <th className="ICD_Code">Remarks</th>
                    <th className="ICD_Code">Submitted Date</th>
                    <th className="ICD_Code">Couriered</th>
                    <th className="ICD_Code">Action</th>
                </tr>
                </thead>
                <tbody>
                {SupportingDocument.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {SupportingDocument[index]['Document_file'] === null ?<span>Support Document</span>:
                    <span style={{color:'blue',cursor:'pointer'}} onClick={() => handleVisibilityClick(SupportingDocument[index]['Document_file'])}>Support Document</span>}
                    <label className="file-labelx"> 
                          <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments9(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments9(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        {row.Document_Date || '-'}
                    </td>
                    <td>
                       {row.Document_file === null ?'-'
                       :<input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments9(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                        }
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow15}
                        >
                      <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow15(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))} 

                {ViewOtherDoc.length !== 0 ?
                ViewOtherDoc.map((row, index) => (
                    <tr key={index}>
                    <td>
                        <span style={{color:'blue',cursor:'pointer'}} onClick={() => handleVisibilityClick(ViewOtherDoc[index]['Document_file'])}>
                        {ViewOtherDoc[index]['Name']}
                        </span>
                    </td>
                    <td>
                        {ViewOtherDoc[index]['Document_Remarks'] || '-'}
                    </td>
                    <td>
                        {ViewOtherDoc[index]['Document_Date'] || '-'}
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleOtherDoc(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td>
                       -
                    </td>
                    </tr>
                 ))
                :""
                }           
                </tbody>
            </table>
            </div>
        </div>
      

      </div>


    <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>

         
     <div className="submit_button_prev_next">
        <button onClick={ClaimSavebtnFun}>
            Save                
        </button>
        </div>
        
        </div>
        

        

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>

    </>
  );
}
