import React from "react";
import Logo from "../../../src/assets/logo.png";
// import "./MedicalHistoryForm.css";
import { useState,useEffect } from "react";
import axios from 'axios';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from "react-redux";

function MedicalHistoryForm() {
  
 
  const [openModal, setOpenModal] = useState(true)
  
  const [medicalHistory, setMedicalHistory] = useState({
    // MedicalHistory
    Anemia: false,
    Arthritis: false,
    Asthma: false,
    Cancer: false,
    ChronicObstructivePulmonaryDisease: false,
    ClottingDisorder: false,
    SkinDisease: false,
    CongestiveHeartFailure: false,
    CrohnsDisease: false,
    Depression: false,
    Diabetes: false,
    Emphysema: false,
    EndocrineProblems: false,
    GERD: false,
    Glaucoma: false,
    Hepatitis: false,
    HivAids: false,
    Hypertension: false,
    KidneyDisease: false,
    MyocardialInfarction: false,
    PepticUlcerDisease: false,
    Seizures: false,
    Stroke: false,
    UlcerativeColitis: false,
  });
 
    const [others, setOthers] = useState(false);
    const [othersCheckbox, setOthersCheckbox] = useState('');

    const [others1, setOthers1] = useState(false);
    const [othersCheckbox1, setOthersCheckbox1] = useState('');
  
    // const handleOthersChange = (event) => {
    //   setOthers(event.target.value);
    // };

    // const handleOthersChange = (event) => {
    //     setOthersCheckbox(event.target.value);
       
    //   };

    
    const handleCheckboxChange = (key) => {
      setMedicalHistory((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
      setOthers(false); // Uncheck "Others" checkbox if any medical history checkbox is checked
    };

    const handleOthersChange = (event) => {
      const isChecked = event.target.checked;
      setOthers(isChecked);
      if (isChecked) {
        // Set all medicalHistory values to false
        const updatedMedicalHistory = Object.fromEntries(
          Object.entries(medicalHistory).map(([key]) => [key, false])
        );
        setMedicalHistory(updatedMedicalHistory);
      }
      setOthersCheckbox(''); // Clear the "Others" textarea when checkbox is unchecked
    };

      // const handleOthersChange = (event) => {
      //   const isChecked = event.target.checked;
      //   setOthers(isChecked);
      //   if (isChecked) {
      //     // Set all medicalHistory values to false
      //     const updatedMedicalHistory = Object.fromEntries(
      //       Object.entries(medicalHistory).map(([key]) => [key, false])
      //     );
      //     setMedicalHistory(updatedMedicalHistory);
      //   }
      // };
    
      const handleMedicalHistoryChange = (event) => {
        const { name, checked } = event.target;
        setMedicalHistory((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
        if (checked) {
          setOthers(false);
        }
      };
    

    // const handleOthersChange1 = (event) => {
    //     setOthersCheckbox1(event.target.value);
       
    //   };

  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    date: new Date().toLocaleDateString(),
    reason: "",
    Description:'',
  });
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  console.log(IpNurseQueSelectedRow,'IpNurseQueSelectedRowwwwwwwwwww')
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord,'userRecord')
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  
  // const handleCheckboxChange = (condition) => {
  //   setMedicalHistory({
  //     ...medicalHistory,
  //     [condition]: !medicalHistory[condition],
  //   });
  // };

  const [socialHistory, setSocialHistory] = useState({
    alcoholUseNever: false,
    alcoholUseOccasionally: false,
    alcoholUseDaily: false,

    tobaccoUseNever: false,
    tobaccoUseOccasionally: false,
    tobaccoUseDaily: false,

    SmokingUseNever: false,
    SmokingUseOccasionally: false,
    SmokingUseDaily: false,
  });

  const handleCheckboxChange2 = (name) => {
    setSocialHistory((prevState) => {
      let updatedState = { ...prevState };

      // Deselect all other checkboxes in the same category
      switch (name) {
        case "alcoholUseNever":
        case "alcoholUseOccasionally":
        case "alcoholUseDaily":
          updatedState = {
            ...prevState,
            alcoholUseNever: false,
            alcoholUseOccasionally: false,
            alcoholUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        case "tobaccoUseNever":
        case "tobaccoUseOccasionally":
        case "tobaccoUseDaily":
          updatedState = {
            ...prevState,
            tobaccoUseNever: false,
            tobaccoUseOccasionally: false,
            tobaccoUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        case "SmokingUseNever":
        case "SmokingUseOccasionally":
        case "SmokingUseDaily":
          updatedState = {
            ...prevState,
            SmokingUseNever: false,
            SmokingUseOccasionally: false,
            SmokingUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        default:
          break;
      }

      return updatedState;
    });
  };

  const [familyHistory, setFamilyHistory] = useState({
    CancerPolyps: false,
    Anemia1: false,
    Diabetes1: false,
    BloodClots1: false,
    HeartDisease1: false,
    Stroke1: false,
    HighBloodPressure1: false,
    AnesthesiaReaction1: false,
    BleedingProblems1: false,
    Hepatitis1: false,
    Tb: false,
    SkinDisease: false,
    
  });


  const [getdatastate, setgetdatastate] = useState(false);

  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `https://vesoftometic.co.in/IcuManagement/get_MedicalHistory_Form?Booking_Id=${IpNurseQueSelectedRow.Booking_Id}`
        )
        .then((response) => {
          const data = response.data[0];
          console.log("Fetched medical history data:", data);

          // Ensure that each field has a defined value
          const updatedFormData = {
            MedicalHistoryCheckbox: data.MedicalHistoryCheckbox || "", 
            MedicalHistoryOthers: data.MedicalHistoryOthers || "",
            SocialHistoryCheckbox: data.SocialHistoryCheckbox || "",
            FamilyHistoryCheckbox: data.FamilyHistoryCheckbox || "",
            FamilyHistoryOthers: data.FamilyHistoryOthers || "",
            RelationShip1: data.RelationShip1 || "",
          };

          // Parse and set the state for MedicalHistoryCheckbox
          if (updatedFormData.MedicalHistoryOthers === '') {
            const parsedCheckboxData1 = updatedFormData.MedicalHistoryCheckbox.split(",").reduce((acc, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
            setMedicalHistory(parsedCheckboxData1);
          } else {
            setOthers(true);
            setOthersCheckbox(updatedFormData.MedicalHistoryOthers);
          }

          // Parse and set the state for FamilyHistoryCheckbox
          if (updatedFormData.FamilyHistoryOthers === '') {
            const parsedCheckboxData2 = updatedFormData.FamilyHistoryCheckbox.split(",").reduce((acc, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
            setFamilyHistory(parsedCheckboxData2);
          } else {
            setOthers1(true);
            setOthersCheckbox1(updatedFormData.FamilyHistoryOthers);
          }

          // Parse and set the state for SocialHistoryCheckbox
          const parsedCheckboxData3 = updatedFormData.SocialHistoryCheckbox.split(",").reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
          setSocialHistory(parsedCheckboxData3);

          // Update FamilyHistoryInfo
          const updatedFamilyHistoryInfo = {
            
            RelationShip1: updatedFormData.RelationShip1,
          };
          setFamilyHistoryInfo(updatedFamilyHistoryInfo);

          console.log(updatedFormData, 'updatedFormData');
        })
        .catch((error) => {
          console.error("Error fetching medical history:", error);
        });
    }
  }, [IpNurseQueSelectedRow, getdatastate]);



  const handleCheckboxChange3 = (key) => {
    setFamilyHistory((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setOthers1(false); // Uncheck "Others" checkbox if any family history checkbox is checked
  };


  const handleOthersChange1 = (event) => {
    const isChecked = event.target.checked;
    setOthers1(isChecked);
    if (isChecked) {
      // Set all familyHistory values to false
      const updatedFamilyHistory = Object.fromEntries(
        Object.entries(familyHistory).map(([key]) => [key, false])
      );
      setFamilyHistory(updatedFamilyHistory);
    }
    setOthersCheckbox1(''); // Clear the "Others" textarea when checkbox is unchecked
  };

  // const handleCheckboxChange3 = (condition) => {
  //   setFamilyHistory({
  //     ...familyHistory,
  //     [condition]: !familyHistory[condition],
  //   });
  // };

  const [familyHistoryInfo, setFamilyHistoryInfo] = useState({

    FamilyName: " ",
    Age1: "",
    RelationShip1: "",

  });

  ///.......................


  const handleInputWomenChange= (e) => {
    const { name, value } = e.target;
    setFamilyHistoryInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

const getSelectedMedicalHistory = (medicalHistory) => {
  const selectedHistory = [];
  Object.keys(medicalHistory).forEach(key => {
      if (medicalHistory[key]) {
          selectedHistory.push(key);
      }
  });
  return selectedHistory;
};
const selectedMedicalHistory = getSelectedMedicalHistory(medicalHistory);
console.log(selectedMedicalHistory);

const getSelectedSocialHistory = (socialHistory) => {
  const getSelectedSocialHistory = [];
  Object.keys(socialHistory).forEach(key => {
      if (socialHistory[key]) {
        getSelectedSocialHistory.push(key);
      }
  });
  return getSelectedSocialHistory;
};
const selectedSocialHistory = getSelectedSocialHistory(socialHistory);
console.log(selectedSocialHistory);


const getSelectedFamilyHistory = (familyHistory) => {
  const getSelectedFamilyHistory = [];
  Object.keys(familyHistory).forEach(key => {
      if (socialHistory[key]) {
        getSelectedFamilyHistory.push(key);
      }
  });
  return getSelectedFamilyHistory;
};
const selectedFamilyHistory = getSelectedFamilyHistory(socialHistory);
console.log(selectedFamilyHistory);

///.......................


const handlePrint = useReactToPrint({
  onBeforePrint: () => {
    console.log('Before');
    setOpenModal(false)
},
  



content: () => document.getElementById('reactprintcontent'),
  onAfterPrint: async () => {

     
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);

      try {
          if (printdata) {
              const contentWidth = printdata.offsetWidth;
              const padding = 20; // Adjust the padding as needed
              const pdfWidth = contentWidth + 2 * padding; // Add padding to width
              const pdfHeight = contentWidth * 1.5; // Add padding to height
              const pdf = new jsPDF({
                  unit: 'px',
                  format: [pdfWidth, pdfHeight],
              });


              const selectedCheckboxes = [];

              // Function to add selected checkboxes from an object to the selectedCheckboxes array
              const addToSelected = (obj) => {
                  Object.keys(obj).forEach(key => {
                      if (obj[key]) {
                          selectedCheckboxes.push(key);
                      }
                  });
              };

              // Add selected checkboxes from socialHistory
              addToSelected(socialHistory);

              // Add selected checkboxes from familyHistory
              addToSelected(familyHistory);

              // Add selected checkboxes from medicalHistory
              addToSelected(medicalHistory);

              // Render selected checkboxes
              selectedCheckboxes.forEach((checkbox, index) => {
                  pdf.text(`- ${checkbox}`, padding, padding + (index * 20)); // Adjust y-coordinate for each checkbox
              });

              pdf.html(printdata, {
                  x: padding,
                  y: padding,
                  callback: () => {
                      const generatedPdfBlob = pdf.output('blob');
                    
                      console.log('generatedPdfBlob', generatedPdfBlob);
                      const data = new FormData();
                      data.append('generatedPdfBlob', generatedPdfBlob);
                      data.append('PatientId',IpNurseQueSelectedRow.PatientId);
                      data.append('PatientName',IpNurseQueSelectedRow.PatientName);
                      // data.append('Admitdate',IpNurseQueSelectedRow.Admitdate);
                      // data.append('PrimaryDoctor',IpNurseQueSelectedRow.PrimaryDoctor);
                      data.append('Booking_Id',IpNurseQueSelectedRow.Booking_Id);
                      data.append('CreatedBy',userRecord?.username);
                      data.append('RelationShip1',familyHistoryInfo.RelationShip1);
                      data.append('Location',userRecord?.location);
                      data.append('Type','Medical');
                     
                      
                      data.append('MedicalHistoryCheckbox', Object.keys(medicalHistory).filter(p => medicalHistory[p]).join(','));
                      data.append('FamilyHistoryCheckbox', Object.keys(familyHistory).filter(p => familyHistory[p]).join(','));
                      data.append('SocialHistoryCheckbox', Object.keys(socialHistory).filter(p => socialHistory[p]).join(','));



                      // Append the 'others' checkbox and its value if it is checked
                      if (others) {
                        data.append('MedicalHistoryOthers', othersCheckbox);
                      }

                      // Append the 'others1' checkbox and its value if it is checked
                      if (others1) {
                        data.append('FamilyHistoryOthers', othersCheckbox1);
                      }

                      // Example Axios POST request to send the generated PDF to the server
                      axios.post(`https://vesoftometic.co.in/IcuManagement/insert_MedicalHistory_Form`, data)
                           .then(response => {
                            // setMedicalHistory();
                            // setSocialHistory();
                            // setFamilyHistory();
                            setgetdatastate();
                              // Handle successful response if needed
                              console.log('Print request sent successfully');
                           })
                           .catch(error => {
                              // Handle error if needed
                              console.error('Error sending print request:', error);
                           });
                  },
              });
          } else {
              throw new Error('Unable to get the target element');
          }
         
          setOpenModal(true)
      } catch (error) {
          console.error('Error generating PDF:', error);
      }
  }
});


const handlePrintSave=()=>{
  setOpenModal(false)
  setTimeout(() => {
    handlePrint()
  }, 500);
}

const formatLabel = (label) => {
  // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
  if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
    return label
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
  } else {
    return label;
  }
};

  return (
    <>
      <body>
        <div className="Medical_History_container" id="reactprintcontent" >
            
          <div className="form-section5">
              <div className=" dkwjd">
                <h3>Personal Medical History </h3>
              </div>
              
                <br/>
                <br/>
        
            
          {openModal ? <div className="div_ckkkbox_head">
            
            {Object.keys(medicalHistory).map((labelname, indx) => (
          <React.Fragment key={labelname}>
            {indx % 8 === 0 && (
              <div className="div_ckkck_box">
                {Object.keys(medicalHistory).slice(indx, indx + 8).map((key) => (
                  <label key={key} className="checkbox-label">
                    <input
                      type="checkbox"
                      id={key}
                      className="checkbox-input"
                      checked={medicalHistory[key]}
                      onChange={() => handleCheckboxChange(key)}
                    />
                    {formatLabel(key)}
                    
                  </label>
                  
                ))}
              
              </div>
            )}
          </React.Fragment>
            ))}
      
          
              
              </div>:
              <div>
              {selectedMedicalHistory.map((data,index)=>(
                <ul key={index}>
                  <li>{data}</li>
                </ul>
              )

              )}
                </div>}
                
                {openModal ? (
                  <div className="checkbox-label">
                    <label key="others" className="checkbox-label1">
                      <input
                        type="checkbox"
                        id="others"
                        className="checkbox-input"
                        checked={others}
                        onChange={handleOthersChange}
                        // onChange={(e) => setOthers(e.target.checked)}
                      />
                      <span style={{ marginRight: '8px' }}>Others</span>
                    </label>
                    {others && (
                      <textarea
                        id="others-textarea"
                        value={othersCheckbox}
                        onChange={(e) => setOthersCheckbox(e.target.value)}
                        // onChange={handleOthersChange}
                        placeholder="Please specify..."
                        style={{ marginLeft: '8px' }}
                        className="textarea-wide1"
                      />
                    )}
                  </div>
                ) : null}

            </div>
          
            


          
            <div className="form-section5">
              <div className=" dkwjd">
                <h3>Social History </h3>
              
              </div>
              
              <br></br>
              <br/>
              
              
            
            {openModal?  <div className="div_ckkkbox_head sccx3">              
                <div className="div_ckkck_box alcho_tac_drg11">
                  <div className="ffdff44">
                    <div>
                      <label className="checkbox-label  alcho_tac_drg">
                        {" "}
                        Alcohol use -{" "}
                      </label>
                    </div>
                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.alcoholUseNever}
                          onChange={() => {
                            handleCheckboxChange2("alcoholUseNever");
                          }}
                        />
                        Never
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.alcoholUseOccasionally}
                          onChange={() => {
                            handleCheckboxChange2("alcoholUseOccasionally");
                          }}
                        />
                        Occasionally
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.alcoholUseDaily}
                          onChange={() => {
                            handleCheckboxChange2("alcoholUseDaily");
                          }}
                        />
                        Daily
                      </label>
                    </div>
                  </div>

                  <div className="div_ckkck_box alcho_tac_drg11">
                    <div className="ffdff44">
                      <div>
                        <label className="checkbox-label alcho_tac_drg">
                          {" "}
                          Tobacco use -{" "}
                        </label>
                      </div>
                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.tobaccoUseNever}
                            onChange={() => {
                              handleCheckboxChange2("tobaccoUseNever");
                            }}
                          />
                          Never
                        </label>
                      </div>

                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.tobaccoUseOccasionally}
                            onChange={() => {
                              handleCheckboxChange2("tobaccoUseOccasionally");
                            }}
                          />
                          Occasionally
                        </label>
                      </div>

                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.tobaccoUseDaily}
                            onChange={() => {
                              handleCheckboxChange2("tobaccoUseDaily");
                            }}
                          />
                          Daily
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="div_ckkck_box alcho_tac_drg11">
                    <div className="ffdff44">
                      <div>
                        <label className="checkbox-label alcho_tac_drg">
                          {" "}
                          Smoking use -{" "}
                        </label>
                      </div>
                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.SmokingUseNever}
                            onChange={() => {
                              handleCheckboxChange2("SmokingUseNever");
                            }}
                          />
                          Never
                        </label>
                      </div>

                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.SmokingUseOccasionally}
                            onChange={() => {
                              handleCheckboxChange2("SmokingUseOccasionally");
                            }}
                          />
                          Occasionally
                        </label>
                      </div>

                      <div className="flx_cjk_labl3">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="checkbox-input ddsfe"
                            checked={socialHistory.SmokingUseDaily}
                            onChange={() => {
                              handleCheckboxChange2("SmokingUseDaily");
                            }}
                          />
                          Daily
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>:  
                  <div>
              {selectedSocialHistory.map((data,index)=>(
                <ul key={index}>
                  <li>{data}</li>
                </ul>
              )

              )}
                </div>}
    
              <div className="form-section5">
                <div className=" dkwjd">
                  <h3>Family History </h3>
                
                </div>
              
                <br></br>
                <br/>
                {openModal? <div className="div_ckkkbox_head">
                  <div className="div_ckkck_box">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.CancerPolyps}
                        onChange={() => handleCheckboxChange3("CancerPolyps")}
                      />
                      Cancer/Polyps
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.Anemia1}
                        onChange={() => handleCheckboxChange3("Anemia1")}
                      />
                      Anemia
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.Diabetes1}
                        onChange={() => handleCheckboxChange3("Diabetes1")}
                      />
                      Diabetes
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.BloodClots1}
                        onChange={() => handleCheckboxChange3("BloodClots1")}
                      />
                      Blood Clots
                    </label>
                  </div>

                  <div className="div_ckkck_box">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.HeartDisease1}
                        onChange={() => handleCheckboxChange3("HeartDisease1")}
                      />
                      Heart Disease
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.Stroke1}
                        onChange={() => handleCheckboxChange3("Stroke1")}
                      />
                      Stroke
                    </label>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.HighBloodPressure1}
                        onChange={() =>
                          handleCheckboxChange3("HighBloodPressure1")
                        }
                      />
                      High Blood Pressure
                    </label>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.AnesthesiaReaction1}
                        onChange={() =>
                          handleCheckboxChange3("AnesthesiaReaction1")
                        }
                      />
                      Anesthesia Reaction
                    </label>
                  </div>

                  <div className="div_ckkck_box">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.BleedingProblems1}
                        onChange={() =>
                          handleCheckboxChange3("BleedingProblems1")
                        }
                      />
                      Bleeding Problems
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.Hepatitis1}
                        onChange={() => handleCheckboxChange3("Hepatitis1")}
                      />
                      Hepatitis
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.Tb}
                        onChange={() => handleCheckboxChange3("Tb")}
                      />
                      TB
                    </label>

                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={familyHistory.SkinDisease}
                        onChange={() => handleCheckboxChange3("SkinDisease")}
                      />
                      Skin Disease
                    </label>

                  
                  </div>
                  
                </div>:        <div>
              {selectedFamilyHistory.map((data,index)=>(
                <ul key={index}>
                  <li>{data}</li>
                </ul>
              )

              )}
              
                </div>}
                {openModal ? (
                    <div className="checkbox-label ">
                    <label key="others1"  className="checkbox-label1 " >
                            <input
                                type="checkbox"
                                id="others1"
                                className="checkbox-input"
                                checked={others1}
                                onChange={handleOthersChange1}
                                //  onChange={(e) => setOthers1(e.target.checked)}
                            />
                            <span style={{ marginRight: '8px' }}>Others</span>
                            
                    </label>
                    {others1 && (
                            <textarea
                                id="others-textarea1"
                                value={othersCheckbox1}
                                onChange={(e) => setOthersCheckbox1(e.target.value)}
                                //  onChange={handleOthersChange1}
                                placeholder="Please specify..."
                                style={{ marginLeft: '8px' }}
                                className="textarea-wide1"
                            />
                            )}

                    </div>
                ):null}
                
                <br/>

                <div className="form-section56">
                  
                  <label className="form-field56">
                    {" "}
                    Relationship :
                    <input
                      type="text"
                      name="RelationShip1"
                      value={familyHistoryInfo.RelationShip1}
                      onChange = {handleInputWomenChange}
                    />
                  </label>
                </div>
                <br></br>
              
              </div>
            </div>
            <div className="Register_btn_con">
          
                <button className="RegisterForm_1_btns print-button3" onClick={handlePrintSave }>Print</button>
            </div>
        </div>
      </body>
    </>
  );
};

export default MedicalHistoryForm;
