import React, { useState, useEffect } from 'react';
// import "./Navigation.css";
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import { ToastContainer, toast } from "react-toastify";


function PastHistory2(props) {
  const formData = props.formData;
  const create = props.userRecord.username;
  const Location = props.userRecord.location;

  const [cosmeticSurgery, setCosmeticSurgery] = useState('');
  const [adverseReactions, setAdverseReactions] = useState('');
  const [allergiesToCosmeticProducts, setAllergiesToCosmeticProducts] = useState('');
  const [onMedications, setOnMedications] = useState('');
  const [allergies, setAllergies] = useState('');
  const [smokingHabits, setSmokingHabits] = useState('');
  const [alcoholConsumption, setAlcoholConsumption] = useState('');
  const [pregnant, setPregnant] = useState('');
  const [breastfeeding, setBreastfeeding] = useState('');
  const [skinType, setSkinType] = useState('');
  const [other, setOther] = useState('');

  useEffect(() => {
    const fetchData = () => {
      
      const PatientID = formData.PatientID;
      
  
      Axios.get(`https://vesoftometic.co.in/doctorsworkbench/for_common_get_pasthistory?patientid=${PatientID}&location=${Location}`)
        .then((response) => {
          console.log("Fetched data:", response.data);
          if (response.data.length > 0) {
            // Assuming the data is an array of objects
            const matchedData = response.data.filter(
              (item) => item.PatientID === formData.PatientID
            );
  
            if (matchedData.length > 0) {
              const mostRecentData = matchedData[0];
  
              setCosmeticSurgery(mostRecentData.CosmeticSurgery);
              setAdverseReactions(mostRecentData.AdverseReactions);
              setAllergiesToCosmeticProducts(
                mostRecentData.AllergiesToCosmeticProducts
              );
              setOnMedications(mostRecentData.Medications);
              setAllergies(mostRecentData.Allergies);
              setSmokingHabits(mostRecentData.SmokingHabits);
              setAlcoholConsumption(mostRecentData.AlcoholConsumption);
              setPregnant(mostRecentData.Pregnant);
              setBreastfeeding(mostRecentData.Breastfeeding);
              setSkinType(mostRecentData.SkinType);
              setOther(mostRecentData.Other);
            } else {
              console.log("No matching data found for the given PatientID");
            }
          } else {
            console.log("No data found for the given PatientID");
          }
        })
        .catch((error) => {
          // Handle errors, e.g., show an error message
          console.error(error);
        });
    };
  
    fetchData();
  }, [formData.PatientID]); // Only re-fetch data when PatientID changes
  
  const handleSave = () => {
    const dataToSend = {
      PatientID: formData.PatientID,
      visitid: formData.visitNo,
      cosmetic_surgery: cosmeticSurgery,
      adverse_reactions: adverseReactions,
      allergies_to_cosmetic_products: allergiesToCosmeticProducts,
      medications: onMedications,
      allergies: allergies,
      smoking_habits: smokingHabits,
      alcohol_consumption: alcoholConsumption,
      pregnant: pregnant,
      breastfeeding: breastfeeding,
      skin_type: skinType,
      other: other,
      Created_By: create,
      location: Location
      // Add other data as needed
    };

    console.table(dataToSend  )
    // Send a POST request to your PHP script
    Axios.post('https://vesoftometic.co.in/doctorsworkbench/insert_pasthistory', dataToSend)
      .then((response) => {
        // Handle the response from the server, e.g., show a success message
        alert(response.data.message);
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error(error);
      });
  };


  return (
    <>
      <Helmet>
        <title>Past-History</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Have You undergone any cosmetic surgery before?</label>
            <select id="Question 1 - cosmetic surgery"
              name="Question 1 - cosmetic surgery"
              value={cosmeticSurgery}
              onChange={(e) => setCosmeticSurgery(e.target.value)}
              className='new-custom-select-title'>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Have you experienced any adverse reactions to previous treatments?</label>
            <select id="Question 2 - previous treatments adverse reaction"
              name="Question 2 - previous treatments adverse reaction"
              value={adverseReactions}
              onChange={(e) => setAdverseReactions(e.target.value)}
              className='new-custom-select-title'>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Do you have any allergies to cosmetic products?</label>
            <select id="Question 3 - allergies to cosmetic products"
              name="Question 3 - allergies to cosmetic products"
              value={allergiesToCosmeticProducts}
              onChange={(e) => setAllergiesToCosmeticProducts(e.target.value)}
              className='new-custom-select-title'>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Are you currently taking any medications?</label>
            <select id="Question 4 - On medications"
              name="Question 4 -On medications"
              value={onMedications}
              onChange={(e) => setOnMedications(e.target.value)}
              className='new-custom-select-title'>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Allergies (Food, Medications, Others)</label>
            <textarea
              className="area_pasthistory"
              id="Question 5 - Allergies"
              name="Question 5 - Allergies"
              rows="2"
              value={allergies}
              onChange={(e) => setAllergies(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Smoking Habits</label>
            <select
              id="Question 6 - Smoking Habits"
              name="Question 6 - Smoking Habits"
              className='new-custom-select-title'
              value={smokingHabits}
              onChange={(e) => setSmokingHabits(e.target.value)}
            >
              <option value="">Select</option>
              <option value="smoker">smoker</option>
              <option value="Non-smoker">Non-smoker</option>
            </select>
          </div>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Alcohol Consumptions(Frequency)</label>
            <select
              id="Question 7 - Alcohol Consumptions"
              name="Question 7 - Alcohol Consumptions"
              className='new-custom-select-title'
              value={alcoholConsumption}
              onChange={(e) => setAlcoholConsumption(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Never">Never</option>
              <option value="Occasionally">Occasionally</option>
              <option value="Regularly">Regularly</option>
            </select>
          </div>
        </div>
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Are you Pregnant?</label>
            <select
              id="Question 8 - Pregnant"
              name="Question 8 - Pregnant"
              className='new-custom-select-title'
              value={pregnant}
              onChange={(e) => setPregnant(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Are you currently breastfeeding?</label>
            <select
              id="Question 9 - breastfeeding"
              name="Question 9 - breastfeeding"
              className='new-custom-select-title'
              value={breastfeeding}
              onChange={(e) => setBreastfeeding(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className='new-patient-info-container'>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Skin Type</label>
            <select
              id="Question 10 - Skin Type"
              name="Question 10 - Skin Type"
              className='new-custom-select-title'
              value={skinType}
              onChange={(e) => setSkinType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Oily">Oily</option>
              <option value="Dry">Dry</option>
              <option value="Combination">Combination</option>
              <option value="Sensitive">Sensitive</option>
            </select>
          </div>
          <div className="new-custom-form-row width_pasthist">
            <label htmlFor="title" className='new-custom-label-title pasthist'>Other</label>
            <textarea
              className="area_pasthistory"
              id="Question 11 - Other"
              name="Question 11 - Other"
              rows="2"
              value={other}
              onChange={(e) => setOther(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='new-button-container'>
          <button className="btncon_add" onClick={handleSave}>Save</button>
        </div>
        <ToastContainer/>
      </div>
    </>
  );
}

export default PastHistory2;