import React, { useState } from 'react';
// import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { alignProperty } from '@mui/material/styles/cssUtils';
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from 'react-redux';

function Units() {
  

  // const[methodData,setMethodData]=useState([])
  const [isEditMode, setIsEditMode] = useState(false);
  const [unitData, setUnitData] = React.useState([]);
// const [method,setMethod]=useState('');
// const [methodcode,setMethodCode] = useState('');
const [unit,setUnit] = useState('');
  const [selectedid, setSelectedid ] = useState(null);
  const [ unitcode,setUnitCode] = useState('');
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

const handleSubmitUnits = async () => {
  if (!unit.trim() || !unitcode.trim()) {
    alert('Both unit Code Name and unit are required.');
    return; // Exit the function early if validation fails
  }
  try {
    // Make a POST request to your Django backend endpoint
     const response = await axios.post(`${urllink}mainddepartment/insertunit`, {
      unit,
      unitcode,
    });

    // Handle the response as needed
    console.log(response.data);
    setUnit('');
    setUnitCode('')
    // Optionally, you can reset the state after a successful submission
   
    fetchUnitData();
  } catch (error) {
    console.error('An error occurred:', error);
    // Handle error scenarios
  }
};


React.useEffect(() => {
  fetchUnitData();
}, []);


const fetchUnitData = () => {
  axios.get(`${urllink}mainddepartment/getunit`)
    .then((response) => {
      const data = response.data;
      console.log("data",data)

      setUnitData(data)
    })
    .catch((error) => {
      console.error('Error fetching unit data:', error);
    });
};


const handleEdit = (row) => {
  setUnit(row.unit_name);
  setUnitCode(row.unit_code)
  setIsEditMode(true);
  setSelectedid(row.unit_id)   // Assuming `method_id` is the identifier
};

const handleUpdateMethod = async () => {
  try {
    const response = await axios.post(`${urllink}mainddepartment/updateunit`, {
      method_id: selectedid,
      method_name: unit,
      method_code: unitcode,
    });
    console.log(response.data);
    setUnit('');
    setUnitCode('')
  setIsEditMode(false);
  setSelectedid('')
  fetchUnitData();
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Units Management</h3>
          </div>
        </div>
     
        <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Unit</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Unit :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Unit Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={unitcode}
                    onChange={(e) => setUnitCode(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitUnits}>
                 {isEditMode ? 'Update' : <AddIcon />}
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Unit Name</th>
                        <th >Unit Code</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                    {unitData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.unit_id}</td>
                          <td>{row.unit_name}</td>
                          <td>

                            {row.unit_code}
                          </td>
                          <td>
                             <button onClick={() => handleEdit(row)}><EditIcon/></button>
                          </td>

                        </tr>
                      ))}
                     
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div>
      </div>
    </>
  );
}

export default Units;
