import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const OtRequest = () => {
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();


  const [otData, setOtData] = useState([]);
  const [SurgeryNameDetail, setSurgeryNameDetail] = useState([]);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [status,setstatus] = useState('Pending')

  console.log("natha", IpNurseQueSelectedRow);
  const currentDate = new Date().toISOString().split("T")[0];
  const [surgerydeptforsurgery, setsurgerydeptforsurgery] = useState("");
  console.log(surgerydeptforsurgery);
  const [surgerydeptdataforsurgery, setsurgerydeptdataforsurgery] = useState([]);
  // Get current date
  const [surgeonnamebydept, setsurgeonnamebydept] = useState([]);
  const [otRequest, setOtRequested] = useState({
    BookingId: IpNurseQueSelectedRow?.BookingId,

    SurgeonName: "",
    SurgeryName: "",
    PatientName: IpNurseQueSelectedRow?.PatientName,
    PrimaryDocter: IpNurseQueSelectedRow?.PrimaryDoctor,
    Date: currentDate,
    Time: "",
    Status: "pending",
  });
  console.log(otRequest, "dddd");

  useEffect(() => {
    axios
      .get(`${urllink}OTManagement/get_surgery_name_bydept?surgerydeptforsurgery=${surgerydeptforsurgery}`)
      .then((response) => {
        console.log("surgeryname", response);
        setSurgeryNameDetail(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${urllink}OTManagement/get_separated_surgeon_name?surgerydeptforsurgery=${surgerydeptforsurgery}`
      )
      .then((response) => {
        console.log("surgeonname", response);
        setsurgeonnamebydept(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [surgerydeptforsurgery]);

  React.useEffect(() => {
    fetchSurgerydeptData();
    fetchotrequestdata()
  }, [status,userRecord]);

  const fetchSurgerydeptData = () => {
    axios
      .get(`${urllink}usercontrol/getsurgerydept`)
      .then((response) => {
        console.log("surgerydepartment", response.data);
        setsurgerydeptdataforsurgery(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const coloumnss = [
    { field: "id", headerName: "Ot Request Id", width: 100 },
    { field: "BookingId", headerName: "Booking Id", width: 100 },
    { field: "PatientName", headerName: "PatientName", width: 100 },
    {
      field: "Surgery_Department",
      headerName: "Surgery Department",
      width: 160,
    },
    { field: "Surgery_Name", headerName: "Surgery Name", width: 130 },
    { field: "Surgeon_Name", headerName: "Surgeon Name", width: 130 },
    { field: "Requested_Date", headerName: "Requested Date", width: 140 },
    { field: "Requested_Time", headerName: "Requested Time", width: 140 },
    { field: "Primary_Doctor", headerName: "Primary Doctor", width: 140 },
    { field: "Approved_Date", headerName: "Approved Date", width: 150 },
    { field: "Approve_Time", headerName: "Approve Time", width: 150 },
    { field: "Status", headerName: "Status", width: 100 },
  ];

  const [editingIndex, setEditingIndex] = useState(null);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = otData.length;
  const totalPages = Math.ceil(otData.length / 10);

  const [table1, settable1] = useState([]);
  const [table2, settable2] = useState([]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOtRequested((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlePlusClick = () => {
    if (!otRequest.surgerydeptforsurgery && !otRequest.SurgeryName) {
      alert("Please select  surgery name.");
      return;
    }
    // Add the selected surgery name to the list
    settable1((prev) => [...prev, otRequest.SurgeryName]);
    // Clear the input field after adding
    setOtRequested((prevOtRequest) => ({ ...prevOtRequest, SurgeryName: "" }));
  };
  const handlePlusClick1 = () => {
    if (!otRequest.surgerydeptforsurgery && !otRequest.SurgeonName) {
      alert("Please select department and surgeon name .");
      return;
    }
    // Add the selected surgery name to the list
    settable2((prev) => [...prev, otRequest.SurgeonName]);
    // Clear the input field after adding
    setOtRequested((prevOtRequest) => ({ ...prevOtRequest, SurgeonName: "" }));
  };
  const handleDelete1 = (index) => {
    // Remove the entry at the specified index from the list
    const updatedTable2 = [...table2];
    updatedTable2.splice(index, 1);
    settable2(updatedTable2);
  };

  const handleDelete = (index) => {
    // Remove the entry at the specified index from the list
    const updatedTable1 = [...table1];
    updatedTable1.splice(index, 1);
    settable1(updatedTable1);
  };

  const handleSaveAdd = () => {
    console.log(table1.length);
    console.log(table2);

    if (
      !otRequest.BookingId ||
      !otRequest.PatientName ||
      !otRequest.PrimaryDocter ||
      !otRequest.Date ||
      !otRequest.Time ||
      table1.length == 0 ||
      table2.length == 0 ||
      !surgerydeptforsurgery
  ) {
      alert(
          "All fields are Mandatory. Please Fill in the following:\n" +
          (!otRequest.BookingId ? "- Booking ID\n" : "") +
          (!otRequest.PatientName ? "- Patient Name\n" : "") +
          (!otRequest.PrimaryDocter ? "- Primary Doctor\n" : "") +
          (!surgerydeptforsurgery ? "- Surgery Department\n" : "") +
          (table1.length == 0 ? "- Select and Add the Surgery Name\n" : "") +
          (table2.length == 0 ? "- Select and Add the Surgeon Name\n" : "") +
          (!otRequest.Date ? "- Date\n" : "") +
          (!otRequest.Time ? "- Time\n" : "") 
      );
      return;
  }

    // Check if both table1 and table2 are empty
    if (table1.length === 0 && table2.length === 0) {
      alert(
        "Please add at least one entry to either SurgeryName and SurgeonName."
      );
      return;
    }
    console.log("1232", otRequest);



    const parms = {
      ...otRequest,
      surgerydeptforsurgery: surgerydeptforsurgery,
      table1: table1,
      table2: table2,
      createdby: userRecord?.username,
      patientID: IpNurseQueSelectedRow?.PatientId,
      location: userRecord?.location
    };

    axios
      .post(`${urllink}OTManagement/insert_Ot_Request`, parms)
      .then((response) => {
        console.log(response);
        setsurgerydeptforsurgery('')
        settable1([])
        settable2([])
        fetchotrequestdata()

      })
      .catch((error) => {
        console.log("otinsert :", error);
      });
  };




  const fetchotrequestdata = () => {
    axios
      .get(`${urllink}OTManagement/get_Ot_Request?status=${status}&location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data;
        console.log("orrequestgetdata", data);

        setOtData([
          ...data.map((row, index) => ({
            id: index + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.log("error fetching otrequest data:", error);
      });
  }



  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <>
      <div className="appointment">
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label>
              Booking Id <span>:</span>
            </label>
            <input
              type="text"
              name="BookingId"
              value={IpNurseQueSelectedRow.BookingId}
              onChange={(e) =>
                setOtRequested({ ...otRequest, BookingId: e.target.value })
              }
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              name="PatientName"
              value={IpNurseQueSelectedRow.PatientName}
              onChange={(e) =>
                setOtRequested({ ...otRequest, PatientName: e.target.value })
              }
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Primary Doctor <span>:</span>
            </label>
            <input
              type="text"
              name="PrimaryDocter"
              value={IpNurseQueSelectedRow.PrimaryDoctor}
              onChange={(e) =>
                setOtRequested({ ...otRequest, PrimaryDocter: e.target.value })
              }
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="surgerydeptforsurgery">
              Surgery Department <span>:</span>
            </label>
            <select
              id="surgerydeptforsurgery"
              name="surgerydeptforsurgery"
              value={surgerydeptforsurgery}
              onChange={(e) => setsurgerydeptforsurgery(e.target.value)}
              required
            >
              {console.log(surgerydeptdataforsurgery)}
              <option value="">Select</option>
              {surgerydeptdataforsurgery.map((facilitydata, index) => (
                <option key={index + 1} value={facilitydata.Surgery_Dept}>
                  {facilitydata.Surgery_Dept}
                </option>
              ))}
            </select>
          </div>

          <div className="RegisForm_1">
            <label>
              Surgery Name <span>:</span>
            </label>
            <select
              name="SurgeryName"
              className="with_add_dctr_thrtebook"
              value={otRequest.SurgeryName}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              {SurgeryNameDetail.length > 0 &&
                SurgeryNameDetail.map((surgery, index) => (
                  <option key={index + 1} value={surgery.Surgery_Name}>
                    {surgery.Surgery_Name}
                  </option>
                ))}
            </select>

            <button className="Addnamebtn2222" onClick={handlePlusClick}>
              +
            </button>
          </div>

          <div className="RegisForm_1">
            <label>
              Surgeon Name <span>:</span>
            </label>
            <select
              name="SurgeonName"
              className="with_add_dctr_thrtebook"
              value={otRequest.SurgeonName}
              onChange={handleInputChange}
            //   onChange={(e) => setOtRequested({ ...otRequest, SurgeonName: e.target.value })}
            >
              <option value="">Select</option>
              {surgeonnamebydept.map((facilitydata, index) => (
                <option key={index + 1} value={facilitydata.EmployeeName}>
                  {facilitydata.EmployeeName}
                </option>
              ))}
            </select>
            <button className="Addnamebtn2222" onClick={handlePlusClick1}>
              +
            </button>
          </div>

          <div className="RegisForm_1">
            <label>
              Date <span>:</span>
            </label>
            <input
              type="date"
              name="Date"
              min={currentDate}
              onChange={(e) =>
                setOtRequested({ ...otRequest, Date: e.target.value })
              }
              value={otRequest.Date}
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Time <span>:</span>
            </label>
            <input
              type="time"
              name="Time"
              onChange={(e) =>
                setOtRequested({ ...otRequest, Time: e.target.value })
              }
            />
          </div>
        </div>
        <br />

        <div style={{ display: "flex" }}>
          {table1.length > 0 && (
            <div className="Selected-table-container RateCard_table">
              <br />
              <br />
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">S No</th>
                    <th id="vital_Twidth">Surgery Name</th>
                    <th id="vital_Twidth">Actions</th>{" "}
                    {/* Add Actions column */}
                  </tr>
                </thead>
                <tbody>
                  {table1.map((surgery, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{surgery}</td>
                      <td>
                        <FaTrash onClick={() => handleDelete(index)} />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <br />
        <div style={{ display: "flex" }}>
          {table2.length > 0 && (
            <div className="Selected-table-container RateCard_table">
              <br />
              <br />
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">S No</th>
                    <th id="vital_Twidth">Surgeon Name</th>
                    <th id="vital_Twidth">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {table2.map((surgery, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{surgery}</td>
                      <td>
                        <FaTrash onClick={() => handleDelete1(index)} />{" "}
                        {/* Delete icon */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <br />
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSaveAdd}>
            Add
          </button>
        </div>
        <br />
        <div className="con_1">
        <div className="inp_1">
            <label htmlFor="status">Status:</label>
            <select
              name="status"
              id="status"
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
            </select>
          </div>
        </div>
       
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={otData.slice(page * pageSize, (page + 1) * pageSize)}
                pageSize={10}
                columns={coloumnss} // You need to define your dynamic columns here
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="Ip_data_grid"
              />
              {showdown > 0 && otData.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && otData.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OtRequest;

