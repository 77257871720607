import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function Reconciliation() {
  const [formDataReconciliation, setFormDataReconciliation] = useState({
    totalBillAmount: "",
    preAuthAuthorizedAmount: "",
    claimAuthorizedAmount: "",
    tpaDeductions: "",
    tpaAdjustments: "",
    tpaNonPayable: "",
    tpaSettledAmount: "",
    patientResponsibility: "",
    patientDiscount: "",
    patientAdjustments: "",
    patientPayable: "",
    reconcileRemarks: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataReconciliation({
      ...formDataReconciliation,
      [name]: value,
    });
  };

  const [tpaPaid, setTpaPaid] = useState("");

  const handleTpaPaidChange = (e) => {
    setTpaPaid(e.target.value);
  };

  const [patientPaid, setPatientPaid] = useState("");

  const handlePatientPaidChange = (e) => {
    setPatientPaid(e.target.value);
  };

  const [rowsTransaction, setRowsTransaction] = useState([
    {
      dateTransaction: "",
      txnNoTransaction: "",
      bankTransaction: "",
      amountTransaction: "",
    },
  ]);

  const handleChangeRowTransaction = (index, key, value) => {
    const newRows = [...rowsTransaction];
    newRows[index][key] = value;
    setRowsTransaction(newRows);
  };

  const addRowTransaction = () => {
    setRowsTransaction([
      ...rowsTransaction,
      {
        dateTransaction: "",
        txnNoTransaction: "",
        bankTransaction: "",
        amountTransaction: "",
      },
    ]);
  };

  const removeRowTransaction = (index) => {
    const newRows = [...rowsTransaction];
    newRows.splice(index, 1);
    setRowsTransaction(newRows);
  };

  const [rowsPatientTransaction, setRowsPatientTransaction] = useState([
    {
      datePatientTransaction: "",
      txnNoPatientTransaction: "",
      bankPatientTransaction: "",
      amountPatientTransaction: "",
    },
  ]);

  const handleChangeRowPatientTransaction = (index, key, value) => {
    const newRows = [...rowsPatientTransaction];
    newRows[index][key] = value;
    setRowsPatientTransaction(newRows);
  };

  const addRowPatientTransaction = () => {
    setRowsPatientTransaction([
      ...rowsPatientTransaction,
      {
        datePatientTransaction: "",
        txnNoPatientTransaction: "",
        bankPatientTransaction: "",
        amountPatientTransaction: "",
      },
    ]);
  };

  const removeRowPatientTransaction = (index) => {
    const newRows = [...rowsPatientTransaction];
    newRows.splice(index, 1);
    setRowsPatientTransaction(newRows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formDataReconciliation);
    // Reset form data if needed
    // setFormData({
    //   totalBillAmount: "",
    //   preAuthAuthorizedAmount: "",
    //   claimAuthorizedAmount: "",
    //   tpaDeductions: "",
    //   tpaAdjustments: "",
    //   tpaNonPayable: "",
    //   tpaSettledAmount: "",
    //   patientResponsibility: "",
    //   patientDiscount: "",
    //   patientAdjustments: "",
    //   patientPayable: "",
    //   reconcileRemarks: "",
    // });
  };

  return (
    <div className="Insurance_Dashboard_head">
      <div className="Insurance_dash_hending">
        <h3>Reconciliation</h3>
      </div>
      <br />

      <form onSubmit={handleSubmit}>
        <div className="flx_recn_pay_settld">
          <div className="flx_recn_pay_settld2">
            <h3>Settlement Details(Rs.)</h3>

            <br />
            <div className="settmt_div_alg_ff">
              <label>
                Total Bill Amount<span>:</span>
              </label>
              <input
                type="number"
                name="totalBillAmount"
                value={formDataReconciliation.totalBillAmount}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Pre-Auth Authorized Amount<span>:</span>
              </label>
              <input
                type="number"
                name="preAuthAuthorizedAmount"
                value={formDataReconciliation.preAuthAuthorizedAmount}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Claim Authorized Amount<span>:</span>
              </label>
              <input
                type="number"
                name="claimAuthorizedAmount"
                value={formDataReconciliation.claimAuthorizedAmount}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                TPA Deductions<span>:</span>
              </label>
              <input
                type="number"
                name="tpaDeductions"
                value={formDataReconciliation.tpaDeductions}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                TPA Adjustments<span>:</span>
              </label>
              <input
                type="number"
                name="tpaAdjustments"
                value={formDataReconciliation.tpaAdjustments}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                TPA Non-Payable<span>:</span>
              </label>
              <input
                type="number"
                name="tpaNonPayable"
                value={formDataReconciliation.tpaNonPayable}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                TPA Settled Amount<span>:</span>
              </label>
              <input
                type="number"
                name="tpaSettledAmount"
                value={formDataReconciliation.tpaSettledAmount}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Patient Responsibility<span>:</span>
              </label>
              <input
                type="number"
                name="patientResponsibility"
                value={formDataReconciliation.patientResponsibility}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Patient Discount<span>:</span>
              </label>
              <input
                type="number"
                name="patientDiscount"
                value={formDataReconciliation.patientDiscount}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Patient Adjustments<span>:</span>
              </label>
              <input
                type="number"
                name="patientAdjustments"
                value={formDataReconciliation.patientAdjustments}
                onChange={handleInputChange}
              />
            </div>

            <div className="settmt_div_alg_ff">
              <label>
                Patient Payable<span>:</span>
              </label>
              <input
                type="number"
                name="patientPayable"
                value={formDataReconciliation.patientPayable}
                onChange={handleInputChange}
              />
            </div>
            <br />
            <div className="bdr_flx_tpa">
              <label>TPA Outstanding</label>
              <p>1787</p>
            </div>
            <br />

            <div className="dwwxsw">
              <label>Reconcile Remarks</label>
              <textarea
                placeholder="Enter Reconcile Remarks here..."
                name="reconcileRemarks"
                value={formDataReconciliation.reconcileRemarks}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flx_recn_pay_settld2">
            <h3>Payment Details(Rs.)</h3>

            <h4>TPA Transactions</h4>

            <div className="Selected-table-container">
              <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                  <tr>
                    <th>Date</th>
                    <th>Txn ID/Cq No</th>
                    <th>Bank</th>
                    <th>Amount</th>
                    <th className="add32_Code">
                      <span onClick={addRowTransaction}>
                        <AddIcon className="add32_Code" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rowsTransaction.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="ICD_Code amountTransaction_reconc0"
                          value={row.dateTransaction}
                          onChange={(e) =>
                            handleChangeRowTransaction(
                              index,
                              "dateTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.txnNoTransaction}
                          onChange={(e) =>
                            handleChangeRowTransaction(
                              index,
                              "txnNoTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.bankTransaction}
                          onChange={(e) =>
                            handleChangeRowTransaction(
                              index,
                              "bankTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.amountTransaction}
                          onChange={(e) =>
                            handleChangeRowTransaction(
                              index,
                              "amountTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="add32_Code">
                        <span onClick={() => removeRowTransaction(index)}>
                          <RemoveIcon className="add32_Code" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <div className="settmt_div_alg_ff">
              <label>
                TPA Paid<span>:</span>
              </label>
              <input
                type="number"
                value={tpaPaid}
                onChange={handleTpaPaidChange}
              ></input>
            </div>

            <br />
            <h4>Patient Transactions</h4>

            <div className="Selected-table-container">
              <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                  <tr>
                    <th>Date</th>
                    <th>Txn ID/Cq No</th>
                    <th>Bank</th>
                    <th>Amount</th>
                    <th className="add32_Code">
                      <span onClick={addRowPatientTransaction}>
                        <AddIcon className="add32_Code" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rowsPatientTransaction.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="ICD_Code amountTransaction_reconc0"
                          value={row.datePatientTransaction}
                          onChange={(e) =>
                            handleChangeRowPatientTransaction(
                              index,
                              "dateTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.txnNoPatientTransaction}
                          onChange={(e) =>
                            handleChangeRowPatientTransaction(
                              index,
                              "txnNoTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.bankPatientTransaction}
                          onChange={(e) =>
                            handleChangeRowPatientTransaction(
                              index,
                              "bankTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="ICD_Code _reconc0"
                          value={row.amountPatientTransaction}
                          onChange={(e) =>
                            handleChangeRowPatientTransaction(
                              index,
                              "amountTransaction",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="add32_Code">
                        <span
                          onClick={() => removeRowPatientTransaction(index)}
                        >
                          <RemoveIcon className="add32_Code" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <div className="settmt_div_alg_ff">
              <label>
                Patient Paid<span>:</span>
              </label>
              <input
                type="number"
                value={patientPaid}
                onChange={handlePatientPaidChange}
              ></input>
            </div>
            <div className="bdr_flx_tpa">
              <label>TPA Outstanding</label>
              <p>1787</p>
            </div>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Reconciliation;
