import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmergencyMlcRegister() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    MlcNo: "",
    IndoorOpdNo: "",
    ExamineDate: "",
    ExamineTime: "",
    Name: "",
    Age: "",
    gender: "",
    Address: "",
    Referred: "",
    Identification: "",
    LhtiOfPatient: "",
    HistoryAndAlleged: "",
    DetailsOfInjuries: "",
    RadiologicalInvestigation: "",
    FinalDiagnosis: "",
    DateOfAdmission: "",
    AdDate: "",
    AdTime: "",
    PoliceStation: "",
    ConstableName: "",
    BuckleNo: "",
    SignOfMo: "",
    DateOfDischarge: "",
    DisDate: "",
    DisTime: "",
    DisPoliceStation: "",
    DisConstable: "",
    DisBuckleNo: "",
    DisSignOfMo: "",
    SignOfConsultant: "",
    ConName: "",
    ConRegNo: "",
    Location:userRecord.location,
    bookingid: IpNurseQueSelectedRow.Booking_Id,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });


  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  

  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);

      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);

              // Construct the request body including previous state
              const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('MlcNo', ConcernformData.MlcNo);
              formData.append('IndoorOpdNo', ConcernformData.IndoorOpdNo);
              formData.append('ExamineDate', ConcernformData.ExamineDate);
              formData.append('ExamineTime', ConcernformData.ExamineTime);
              formData.append('Name', ConcernformData.Name);
              formData.append('Age', ConcernformData.Age);
              formData.append('Referred', ConcernformData.Referred);
              formData.append('Identification', ConcernformData.Identification);
              formData.append('LhtiOfPatient', ConcernformData.LhtiOfPatient);
              formData.append('HistoryAndAlleged', ConcernformData.HistoryAndAlleged);
              formData.append('gender', ConcernformData.gender);
              formData.append('DetailsOfInjuries', ConcernformData.DetailsOfInjuries);
              formData.append('Address', ConcernformData.Address);
              formData.append('RadiologicalInvestigation', ConcernformData.RadiologicalInvestigation);
              formData.append('FinalDiagnosis', ConcernformData.FinalDiagnosis);
              formData.append('DateOfAdmission', ConcernformData.DateOfAdmission);
              formData.append('AdDate', ConcernformData.AdDate);
              formData.append('AdTime', ConcernformData.AdTime);
              formData.append('PoliceStation', ConcernformData.PoliceStation);
              formData.append('ConstableName', ConcernformData.ConstableName);
              formData.append('BuckleNo', ConcernformData.BuckleNo);
              formData.append('DateOfDischarge', ConcernformData.DateOfDischarge);
              formData.append('DisDate', ConcernformData.DisDate);
              formData.append('DisTime', ConcernformData.DisTime);
              formData.append('DisPoliceStation', ConcernformData.DisPoliceStation);
              formData.append('DisConstable', ConcernformData.DisConstable);
              formData.append('DisBuckleNo', ConcernformData.DisBuckleNo);
              formData.append('ConName', ConcernformData.ConName);
              formData.append('ConRegNo', ConcernformData.ConRegNo);
              formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);

              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_MlcRegisterForm`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;

  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };


  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent " id="reactprintcontent">
        <br />
        <div>



          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <h3>MLC Register Format</h3><br />

          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <h2>Chirayu Hospital </h2><h5>  Salvi Stop, Ratnagiri Pin - 415 639</h5>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <h5>YOUR ONLY DEFENCE, IN THE COURT OF LAW, IS YOUR RECORDS, SO THEY MUST BE UP-TO</h5>
          </div>
        </div>

        <div className="RegisFormcon_consent_consent">


          <div className="RegisForm_1_consent_consent">
            <label htmlFor="MlcNo">
              M.L.C. NO<span>:</span>
            </label>
            <input
              type="text"
              id="MlcNo"
              name="MlcNo"
              value={ConcernformData.MlcNo}
              onChange={handleChange}
              required
            />

          </div>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="IndoorOpdNo">
              INDOOR/O.P.D.No<span>:</span>
            </label>
            <input
              type="text"
              id="IndoorOpdNo"
              name="IndoorOpdNo"
              value={ConcernformData.IndoorOpdNo}
              onChange={handleChange}
              required
            />

          </div>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="ExamineDate">
              Examination Date<span>:</span>
            </label>
            <input
              type="date"
              id="ExamineDate"
              name="ExamineDate"
              value={ConcernformData.ExamineDate}
              onChange={handleChange}
              required
            />

          </div>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="ExamineTime">
              Examination Time<span>:</span>
            </label>
            <input
              type="time"
              id="ExamineTime"
              name="ExamineTime"
              value={ConcernformData.ExamineTime}
              onChange={handleChange}
              required
            />

          </div>

        </div>



        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent">
            <label htmlFor="Name">
              Name <span>:</span>
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              value={ConcernformData.Name}
              onChange={handleChange}
              required
            // style={{width: '100px'}}
            />
          </div>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="Age">
              Age<span>:</span>
            </label>
            <input
              type="text"
              id="Age"
              name="Age"
              value={ConcernformData.Age}
              onChange={handleChange}
              required
            // style={{width: '100px'}}

            />
          </div>
          <div className="RegisForm_1_consent_consent">
            <label>
              Gender <span>:</span>
            </label>
            <div className="RegisForm_1_consent_consent_radiooo_head35r">
              <div className="RegisForm_1_consent_consent_radiooo female4d">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="male"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                />
                <label htmlFor="male"> Male </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo female4eed">
                <input
                  className="consent_consent_radiooo_inputt"
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                />
                <label htmlFor="female"> Female </label>
              </div>
              <div className="RegisForm_1_consent_consent_radiooo transgender98">
                <input
                  type="radio"
                  id="transgender"
                  name="gender"
                  value="TransGender"
                  className="consent_consent_radiooo_inputt"
                  onChange={handleChange}
                />
                <label htmlFor="transgender"> Transgender </label>
              </div>
            </div>
          </div>

        </div>

        <div className="RegisFormcon_consent_consent" style={{ textAlign: 'left' }}>

          <div className="RegisForm_1_consent_consent">
            <label>
              Address <span>:</span>
            </label>
            <textarea
              name="Address"
              value={ConcernformData.Address}
              onChange={handleChange}
              required
            ></textarea>
          </div>


        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="Referred">
              By whom brought/referred(Name & Address) <span>:</span>
            </label>
            <textarea
              name="Referred"
              value={ConcernformData.Referred}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="Identification">
              Identification Marks <span>:</span>
            </label>
            <textarea
              name="Identification"
              value={ConcernformData.Identification}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="LhtiOfPatient">
              L.H.T.I. of the Patient <span>:</span>
            </label>
            <textarea
              name="LhtiOfPatient"
              value={ConcernformData.LhtiOfPatient}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>


        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="HistoryAndAlleged">
              History and alleged cause of injury <span>:</span>
            </label>
            <textarea
              name="HistoryAndAlleged"
              value={ConcernformData.HistoryAndAlleged}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="DetailsOfInjuries">
              Details of Injuries / Clinical Features <span>:</span>
            </label>
            <textarea
              name="DetailsOfInjuries"
              value={ConcernformData.DetailsOfInjuries}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="RadiologicalInvestigation">
              Radiological Investigations <span>:</span>
            </label>
            <textarea
              name="RadiologicalInvestigation"
              value={ConcernformData.RadiologicalInvestigation}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        <div className="RegisFormcon_consent_consent">
          <div className="RegisForm_1_consent_consent" >
            <label htmlFor="FinalDiagnosis">
              Final Diagnosis<span>:</span>
            </label>
            <textarea
              name="FinalDiagnosis"
              value={ConcernformData.FinalDiagnosis}
              onChange={handleChange}
              required
            ></textarea>

          </div>
        </div>

        {/* Design align */}
        <br />
        <h4
          style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
          Date of Admission
        </h4>

        <div className="edewdce dssxxsssa">
          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DateOfAdmission">
                Admission Date<span>:</span>
              </label>
              <input
                type="date"
                id="DateOfAdmission"
                name="DateOfAdmission"
                value={ConcernformData.DateOfAdmission}
                onChange={handleChange}
                required
              />

            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="AdDate">
                Date<span>:</span>
              </label>
              <input
                type="date"
                id="AdDate"
                name="AdDate"
                value={ConcernformData.AdDate}
                onChange={handleChange}
                required
              />

            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="AdTime">
                Time<span>:</span>
              </label>
              <input
                type="time"
                id="AdTime"
                name="AdTime"
                value={ConcernformData.AdTime}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="PoliceStation">
                Police Station<span>:</span>
              </label>
              <input
                type="text"
                id="PoliceStation"
                name="PoliceStation"
                value={ConcernformData.PoliceStation}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="ConstableName">
                Constable's Name<span>:</span>
              </label>
              <input
                type="text"
                id="ConstableName"
                name="ConstableName"
                value={ConcernformData.ConstableName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="BuckleNo">
                Buckle No<span>:</span>
              </label>
              <input
                type="text"
                id="BuckleNo"
                name="BuckleNo"
                value={ConcernformData.BuckleNo}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <br />
          <div className="sigCanvas2_head11 uwytywe6262309" style={{ justifyContent: 'center' }} >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5
                style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature of MO</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


          </div>
        </div>




        {/* End */}

        {/*start Align Right Side */}
<br />
        <h4
          style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
          Date of Discharge
        </h4>
        <div className="edewdce">
          <div className="RegisFormcon_consent_consent ">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DateOfDischarge">
                Date of Discharge<span>:</span>
              </label>
              <input
                type="date"
                id="DateOfDischarge"
                name="DateOfDischarge"
                value={ConcernformData.DateOfDischarge}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DisDate">
                Date<span>:</span>
              </label>
              <input
                type="date"
                id="DisDate"
                name="DisDate"
                value={ConcernformData.DisDate}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DisTime">
                Time<span>:</span>
              </label>
              <input
                type="time"
                id="DisTime"
                name="DisTime"
                value={ConcernformData.DisTime}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DisPoliceStation">
                Police Station<span>:</span>
              </label>
              <input
                type="text"
                id="DisPoliceStation"
                name="DisPoliceStation"
                value={ConcernformData.DisPoliceStation}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DisConstable">
                Constable's Name<span>:</span>
              </label>
              <input
                type="text"
                id="DisConstable"
                name="DisConstable"
                value={ConcernformData.DisConstable}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="DisBuckleNo">
                Buckle No<span>:</span>
              </label>
              <input
                type="text"
                id="DisBuckleNo"
                name="DisBuckleNo"
                value={ConcernformData.DisBuckleNo}
                onChange={handleChange}
                required
              />
            </div>
          </div>
<br />
<div className="jwhyw66w4">
          <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature of MO</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


          </div>

          <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>Signature of Consultant</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


          </div>

          </div>

          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="ConName">
                Name<span>:</span>
              </label>
              <input
                type="text"
                id="ConName"
                name="ConName"
                value={ConcernformData.ConName}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div className="RegisFormcon_consent_consent">
            <div className="RegisForm_1_consent_consent" >
              <label htmlFor="ConRegNo">
                Reg.No<span>:</span>
              </label>
              <input
                type="text"
                id="ConRegNo"
                name="ConRegNo"
                value={ConcernformData.ConRegNo}
                onChange={handleChange}
                required
              />
            </div>
          </div>

        </div>














        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmergencyMlcRegister;
