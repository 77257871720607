import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";



export default function InsurancePreAuthDischargeDocument() {




  const navigate = useNavigate();
  

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );


  const[DischargerState,setDischargerState]=useState({
    RequestedAmount:'',
    BillNumber:''
  })

  

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const yourStyles={
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }


  //Discharged

  const [rows13, setRows13] = useState([
    {
      Name:'investigation',
      Document_file: null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows14, setRows14] = useState([
    {
      Name:'finalBill',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows15, setRows15] = useState([
    {
      Name:'dischargeSummary',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows16, setRows16] = useState([
    {
      Name:'dischargeotherDocument',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);



  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }
  
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;
  
    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };
  

// ------------------------------------------------------------------------------------



  //------

  const handleChangeRowDocuments7 = (index, key, value) => {
    const updatedRows = [...rows13];
    updatedRows[index][key] = value;
    setRows13(updatedRows);
  };

  const addRow13 = () => {
    setRows13((prevRows) => [
      ...prevRows,
      {
        Name:'investigation',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow13 = (index) => {
    setRows13((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments8 = (index, key, value) => {
    const updatedRows = [...rows14];
    updatedRows[index][key] = value;
    setRows14(updatedRows);
  };

  const addRow14 = () => {
    setRows14((prevRows) => [
      ...prevRows,
      {
        Name:'finalBill',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow14 = (index) => {
    setRows14((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments9 = (index, key, value) => {
    const updatedRows = [...rows15];
    updatedRows[index][key] = value;
    setRows15(updatedRows);
  };

  const addRow15 = () => {
    setRows15((prevRows) => [
      ...prevRows,
      {
        Name:'dischargeSummary',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow15 = (index) => {
    setRows15((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //----

  const handleChangeRowDocuments10 = (index, key, value) => {
    const updatedRows = [...rows16];
    updatedRows[index][key] = value;
    setRows16(updatedRows);
  };

  const addRow16 = () => {
    setRows16((prevRows) => [
      ...prevRows,
      {
        Name:'dischargeotherDocument',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow16 = (index) => {
    setRows16((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };


  
 
  const DischargeSavebtnFun =()=>{


    const MRN = InsuranceUpdatedata.MRN
    const ContactNumber=InsuranceUpdatedata.ContactNumber

    const Location = userRecord.location 
    const createAt = userRecord.username 


    // console.log('klkl',rows13,rows14,rows15,rows16)

    const formData = new FormData();

    formData.append('MRN', MRN);
    formData.append('ContactNumber', ContactNumber);
    formData.append('Location', Location);
    formData.append('createAt', createAt);

    formData.append('rows13', JSON.stringify(rows13));
    formData.append('rows14', JSON.stringify(rows14));
    formData.append('rows15', JSON.stringify(rows15));
    formData.append('rows16', JSON.stringify(rows16));

    
    // Append Document_file from each row to formData
    const appendFiles = (formData, rows, prefix) => {
        rows.forEach((row, index) => {
            if (row && row.Document_file) {
                formData.append(`${prefix}[${index}][Document_file]`, row.Document_file);
            }
        });
    };
    
    appendFiles(formData, rows13, 'rows13');
    appendFiles(formData, rows14, 'rows14');
    appendFiles(formData, rows15, 'rows15');
    appendFiles(formData, rows16, 'rows16');



    axios.post(`https://vesoftometic.co.in/Insurance/Update_DischargeSave_Insurance`,formData)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        
    })
    .catch((error) => {
        console.error(error);
    });

  }

// --------------------------------------------------------

 

useEffect(() => {

  if (Object.values(InsuranceUpdatedata).length !== 0 && InsuranceUpdatedata.Papersstatustype === "DISCHARGE" || InsuranceUpdatedata.Papersstatustype  === "DISCHARGED") {
      // console.log('Vathuruchu', InsuranceUpdatedata)
      axios.get(
          `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Discharge_Documents_data`, {
          params: InsuranceUpdatedata.MRN
      }
      )
          .then((response) => {
              // console.log('vrrrr',response.data);

              const data = response.data
              const Investigationlist_data=data.Investigationlist_data
              const FinalBilllist_data=data.FinalBilllist_data
              const Dischargelist_data=data.Dischargelist_data
              const other_document_data=data.other_document_data
              // console.log('qqq', photo_id_data,other_document_data,signed_form_data)

              const updatedRows13 = Investigationlist_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });
            
            const updatedRows14 = FinalBilllist_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });
            
            const updatedRows15 = Dischargelist_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });

            const updatedRows16 = other_document_data.map((element) => {
              const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
              return {...element, Document_file: file};
          });


          if(updatedRows13.length !==0){
            setRows13(updatedRows13);
           }
          if(updatedRows14.length !==0){
            setRows14(updatedRows14);
           }
          if(updatedRows15.length !==0){            
            setRows15(updatedRows15);
           }
           if(updatedRows16.length !==0){            
            setRows16(updatedRows16);
           }
          

          })
          .catch((error) => {
              console.log(error);
          });

  }
}, [InsuranceUpdatedata])




// ------------------File

const handleVisibilityClick = async (ConsentForm) => {
  console.log('ConsentForm', ConsentForm.type);

  if (ConsentForm.type !== "application/pdf") {
    const fileURL = URL.createObjectURL(ConsentForm);
    setModalContent(fileURL);
    setModalIsOpen(true);
  } else {
    // Convert the PDF file to a base64 string
    const base64String = await readFileAsBase64(ConsentForm);
    const Pdffile = `data:application/pdf;base64,${base64String}`;
    console.log('Pdffile', Pdffile);
    setModalContent(Pdffile);
    setModalIsOpen(true);
  }
};

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

const closeModal = () => {
  setModalIsOpen(false);
  setModalContent('');
};

  return (
    <>
     <div className="Supplier_Master_Container">
    
       
        <div className="RegisFormcon column_regisFormcon_forinsurance">
            <div className="h_heade34">
            <h3>Pre-Auth Documents</h3>
            </div>

            <div className="Selected-table-container">
            <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                <tr>
                    <th className="Provisional_Diagnosis">
                    File Name
                    </th>
                    <th className="ICD_Code">Remarks</th>
                    <th className="ICD_Code">Submitted Date</th>
                    <th className="ICD_Code">ACK</th>
                    <th className="ICD_Code">Action</th>
                </tr>
                </thead>
                <tbody>
                {rows13.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows13[index]['Document_file'] === null ? <span>Investigation Report</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows13[index]['Document_file'])} >Investigation Report</span>}
                        <label className="file-labelx">
                      
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments7(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments7(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments7(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments7(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow13}
                        >
                        <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow13(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {rows14.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows14[index]['Document_file'] === null ? <span>Final Bill</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows14[index]['Document_file'])} >Final Bill</span>}
                        <label className="file-labelx">
                         <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments8(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments8(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments8(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments8(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow14}
                        >
                        <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow14(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {rows15.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows15[index]['Document_file'] === null ? <span>Discharge Summary</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows15[index]['Document_file'])} >Discharge Summary</span>}
                        <label className="file-labelx"> 

                          <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments9(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments9(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments9(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments9(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow15}
                        >
                      <AddIcon className="add32_Code" />

                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow15(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {rows16.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows16[index]['Document_file'] === null ? <span>Other Documents</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows16[index]['Document_file'])} >Other Documents</span>}
                        <label className="file-labelx">
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                            const file = e.target.files[0];
                            handleChangeRowDocuments10(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={
                            row.Document_Remarks
                        }
                        onChange={(e) =>
                            handleChangeRowDocuments10(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={
                            row.Document_Date
                        }
                        onChange={(e) =>
                            handleChangeRowDocuments10(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={
                            row.Document_Ack
                        }
                        onChange={(e) =>
                            handleChangeRowDocuments10(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow16}
                        >
                        <AddIcon className="add32_Code" />
                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow16(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
      

      </div>


    <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>

         
     <div className="submit_button_prev_next">
        <button onClick={DischargeSavebtnFun}>
            Save                
        </button>
        </div>
        
        </div>
        

        

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>

    </>
  );
}
