import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function ContraVoucher() {
  const cu_date = format(new Date(), "yyyy-MM-dd");

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [isedit, setisedit] = useState(false);

  const [datalistdata, setdatalistdata] = useState([]);

  const [Paymentlist, setPaymentlist] = useState([]);

  // console.log('Paymentlist',Paymentlist)
  // console.log(userRecord)

  const [ArrayForMultiplePayment, setArrayForMultiplePayment] = useState([]);

  const [ContraformState, setContraformState] = useState({
    VoucherNo: "",
    VoucherDate: cu_date,
    VoucherNarration: "",
    DrAccount: "",
    DrAccountOpnBalance: "",
    DrAccountCurBalance: "",
    DrAccountBalanceType: "",
    DebitAmount: "",
  });

  console.log(ContraformState);

  const [CreditAccountstate, setCreditAccountstate] = useState({
    CrAccount: "",
    CrAccountUnderGroup: "",
    CrAccountCurBalance: "",
    CrAccountOpnBalance: "",
    CrAccountBalanceType: "",
    CreditAmount: "",
  });

  const [MultiplePaymentdata, setMultiplePaymentdata] = useState({
    PaymentType: "",
    PaymentAmount: "",
    Remarks: "",
  });

  useEffect(() => {
    getContraNum();
  }, []);

  const getContraNum = () => {
    axios
      .get(
        `https://vesoftometic.co.in/FinanceMagement/get_Contra_Voucher_invoice_no`
      )
      .then((response) => {
        const data = response.data.nextContraInvoiceNo;
        console.log("invoice", data);

        setContraformState((prev) => ({
          ...prev,
          VoucherNo: data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getdatalistdata();
  }, []);

  const getdatalistdata = () => {
    axios
      .get(`https://vesoftometic.co.in/FinanceMagement/get_Ledger_for_Cur_balance`)
      .then((response) => {
        const data = response.data.LedgerEntries;
        console.log("invoice", data);
        setdatalistdata(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("eede", datalistdata);

    if (datalistdata.length !== 0 && ContraformState.DrAccount !== "") {
      let findData = datalistdata.find(
        (ele) => ele.Ledger_Name === ContraformState.DrAccount
      );

      if (findData) {
        setContraformState((prev) => ({
          ...prev,
          DrAccountOpnBalance: findData.Current_Balance,
          DrAccountCurBalance: findData.Current_Balance,
          DrAccountBalanceType: findData.Depit_or_Credit,
        }));
      } else {
        setContraformState((prev) => ({
          ...prev,
          DrAccountCurBalance: "",
          DrAccountBalanceType: "",
        }));
      }
    } else {
      setContraformState((prev) => ({
        ...prev,
        DrAccountCurBalance: "",
        DrAccountBalanceType: "",
      }));
    }
  }, [ContraformState.DrAccount, datalistdata]);

  useEffect(() => {
    if (datalistdata.length !== 0 && CreditAccountstate.CrAccount !== "") {
      let findData = datalistdata.find(
        (ele) => ele.Ledger_Name === CreditAccountstate.CrAccount
      );

      // console.log('eede',findData)

      if (findData) {
        setCreditAccountstate((prev) => ({
          ...prev,
          CrAccountOpnBalance: findData.Current_Balance,
          CrAccountCurBalance: findData.Current_Balance,
          CrAccountBalanceType: findData.Depit_or_Credit,
          CrAccountUnderGroup: findData.Group_Name,
        }));
      } else {
        setCreditAccountstate((prev) => ({
          ...prev,
          CrAccountCurBalance: "",
          CrAccountOpnBalance: "",
          CrAccountBalanceType: "",
          CrAccountUnderGroup: "",
          CreditAmount: "",
        }));
      }
    } else {
      setCreditAccountstate((prev) => ({
        ...prev,
        CrAccountCurBalance: "",
        CrAccountOpnBalance: "",
        CrAccountBalanceType: "",
        CrAccountUnderGroup: "",
        CreditAmount: "",
      }));
    }
  }, [CreditAccountstate.CrAccount, datalistdata]);

  useEffect(() => {
    if (CreditAccountstate.CreditAmount !== "") {
      const CrdAmo =
        +CreditAccountstate.CrAccountOpnBalance -
        +CreditAccountstate.CreditAmount;

      setCreditAccountstate((prev) => ({
        ...prev,
        CrAccountCurBalance: CrdAmo,
      }));
    } else {
      setCreditAccountstate((prev) => ({
        ...prev,
        CrAccountCurBalance: +CreditAccountstate.CrAccountOpnBalance,
      }));
    }
  }, [CreditAccountstate.CreditAmount]);

  useEffect(() => {
    if (ContraformState.DebitAmount !== "") {
      const balAmo =
        +ContraformState.DrAccountOpnBalance + +ContraformState.DebitAmount;

      if (balAmo) {
        setContraformState((prev) => ({
          ...prev,
          DrAccountCurBalance: balAmo,
        }));
      } else {
        setContraformState((prev) => ({
          ...prev,
          DrAccountCurBalance: ContraformState.DrAccountOpnBalance,
        }));
      }
    }
  }, [ContraformState.DebitAmount]);

  useEffect(() => {
    if (Paymentlist.length !== 0) {
      const Amounttotal = Paymentlist.reduce(
        (total, ele) => total + +ele.CreditAmount,
        0
      );

      // console.log("Total Amount:", Amounttotal);

      setContraformState((prev) => ({
        ...prev,
        DebitAmount: Amounttotal,
      }));
    } else {
      setContraformState((prev) => ({
        ...prev,
        DebitAmount: 0,
      }));
    }
  }, [Paymentlist]);

  useEffect(() => {
    if (
      ArrayForMultiplePayment.length !== 0 &&
      CreditAccountstate.CrAccountUnderGroup === "Bank Accounts"
    ) {
      const Amounttotal = ArrayForMultiplePayment.reduce(
        (total, ele) => total + +ele.PaymentAmount,
        0
      );

      setCreditAccountstate((prev) => ({
        ...prev,
        CreditAmount: Amounttotal,
      }));
    } else if (
      ArrayForMultiplePayment.length === 0 &&
      CreditAccountstate.CrAccountUnderGroup === "Bank Accounts"
    ) {
      setCreditAccountstate((prev) => ({
        ...prev,
        CreditAmount: 0,
      }));
    }
  }, [ArrayForMultiplePayment]);

  // console.log('1212',ArrayForMultiplePayment)

  const ClearContradata = () => {
    setContraformState({
      VoucherNo: "",
      VoucherDate: cu_date,
      VoucherNarration: "",
      DrAccount: "",
      DrAccountOpnBalance: "",
      DrAccountCurBalance: "",
      DrAccountBalanceType: "",
      DebitAmount: "",
    });
  };

  const handlecleardata = () => {
    setCreditAccountstate({
      CrAccount: "",
      CrAccountCurBalance: "",
      CrAccountUnderGroup: "",
      CrAccountOpnBalance: "",
      CrAccountBalanceType: "",
      CreditAmount: "",
    });
  };

  const clearmultiplpaydata = () => {
    setMultiplePaymentdata({
      PaymentType: "",
      PaymentAmount: "",
      Remarks: "",
    });
  };

  const Addmuldiplepayment = () => {
    const requiredfields = ["PaymentType", "PaymentAmount", "Remarks"];

    const existing = requiredfields.filter(
      (field) => !MultiplePaymentdata[field]
    );

    if (existing.length === 0) {
      setArrayForMultiplePayment((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          ...MultiplePaymentdata,
        },
      ]);

      clearmultiplpaydata();
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const add_data = () => {
    console.log(CreditAccountstate);
    const requiredfields = [
      "CrAccount",
      "CrAccountCurBalance",
      "CrAccountBalanceType",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !CreditAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.find(
        (ele) =>
          ele.CrAccount.toLowerCase() ===
          CreditAccountstate.CrAccount.toLowerCase()
      );

      if (CheckName) {
        alert("CrAccount Already Exists");
      } else {
        setPaymentlist((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            MulitPayment: ArrayForMultiplePayment,
            ...CreditAccountstate,
          },
        ]);

        handlecleardata();
      }

      setArrayForMultiplePayment([]);
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Editdataform = (row) => {
    console.log("row", row);
    let paydatas = row.MulitPayment;
    setCreditAccountstate((prev) => ({
      ...prev,
      id: row.id,
      CrAccount: row.CrAccount,
      CrAccountCurBalance: row.CrAccountCurBalance,
      CrAccountOpnBalance: row.CrAccountOpnBalance,
      CrAccountBalanceType: row.CrAccountBalanceType,
      CreditAmount: row.CreditAmount,
    }));
    setArrayForMultiplePayment(paydatas);
  };

  const Deletedataform = (row) => {
    setPaymentlist((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const Deletemultipay = (row) => {
    setArrayForMultiplePayment((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const update_data = () => {
    const requiredfields = [
      "CrAccount",
      "CrAccountCurBalance",
      "CrAccountBalanceType",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !CreditAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.filter(
        (ele) => ele.id !== CreditAccountstate.id
      ).find(
        (ele) =>
          ele.CrAccount.toLowerCase() ===
          CreditAccountstate.CrAccount.toLowerCase()
      );

      if (CheckName) {
        alert("CrAccount Already Exists");
      } else {
        setPaymentlist((prevList) => {
          const updatedList = prevList.filter(
            (ele) => ele.CrAccount !== CreditAccountstate.CrAccount
          );
          return [
            ...updatedList,
            { ...CreditAccountstate, MulitPayment: ArrayForMultiplePayment },
          ];
        });

        handlecleardata();
        setArrayForMultiplePayment([]);
        setisedit(false);
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Postalldata = () => {
    const requiredfields = [
      "VoucherNo",
      "VoucherDate",
      "VoucherNarration",
      "DrAccount",
      "DrAccountCurBalance",
      "DrAccountBalanceType",
      "DebitAmount",
    ];

    const existing = requiredfields.filter((field) => !ContraformState[field]);

    if (existing.length === 0) {
      let Insertdata = {
        ContraformState: ContraformState,
        Paymentlist: Paymentlist,
        Location: userRecord.location,
        CreatedBy: userRecord.username,
      };

      console.log("Insertdata", Insertdata);

      axios
        .post(
          "https://vesoftometic.co.in/FinanceMagement/insert_Contra_Voucher_Entry_details",
          Insertdata
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.Message);
          ClearContradata();
          setPaymentlist([]);
          getContraNum();
          getdatalistdata();
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Contra Voucher</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="VoucherNo">
            Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            required
            readOnly
            value={ContraformState.VoucherNo}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                VoucherNo: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherDate">
            Voucher Date <span>:</span>
          </label>
          <input
            type="date"
            name="VoucherDate"
            required
            value={ContraformState.VoucherDate}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                VoucherDate: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherNarration">
            Short Narration <span>:</span>
          </label>
          <textarea
            type="text"
            name="VoucherNarration"
            required
            value={ContraformState.VoucherNarration}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                VoucherNarration: e.target.value,
              })
            }
          />
        </div>

        {/* </div> */}

        {/* <div className="new-patient-info-container "> */}
        <div className="RegisForm_1">
          <label htmlFor="DrAccount">
            Dr Account <span>:</span>
          </label>
          <input
            type="text"
            name="DrAccount"
            list="DrAC_list"
            required
            value={ContraformState.DrAccount}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                DrAccount: e.target.value,
              })
            }
          />
          <datalist id="DrAC_list">
            {datalistdata.map((item, index) => (
              <option key={index} value={item.Ledger_Name}></option>
            ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="DrAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="DrAccountCurBalance"
            required
            readOnly
            value={ContraformState.DrAccountCurBalance}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                DrAccountCurBalance: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="DrAccountBalanceType">
            Dr/Cr<span>:</span>
          </label>
          <input
            type="text"
            name="DrAccountBalanceType"
            required
            readOnly
            value={ContraformState.DrAccountBalanceType}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                DrAccountBalanceType: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="DebitAmount">
            Debit <span>:</span>
          </label>
          <input
            type="text"
            name="DebitAmount"
            required
            readOnly
            value={ContraformState.DebitAmount}
            onChange={(e) =>
              setContraformState({
                ...ContraformState,
                DebitAmount: e.target.value,
              })
            }
          />
        </div>

        {/* </div> */}

        {/* <div className="new-patient-info-container "> */}

        <div className="RegisForm_1">
          <label htmlFor="CrAccount">
            Cr Account <span>:</span>
          </label>
          <input
            type="text"
            name="CrAccount"
            list="CrAC_list"
            required
            value={CreditAccountstate.CrAccount}
            onChange={(e) =>
              setCreditAccountstate({
                ...CreditAccountstate,
                CrAccount: e.target.value,
              })
            }
          />
          <datalist id="CrAC_list">
            {datalistdata
              .filter((item) => item.Ledger_Name !== ContraformState.DrAccount)
              .map((item, index) => (
                <option key={index} value={item.Ledger_Name}></option>
              ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CrAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="CrAccountCurBalance"
            required
            readOnly
            value={CreditAccountstate.CrAccountCurBalance}
            onChange={(e) =>
              setCreditAccountstate({
                ...CreditAccountstate,
                CrAccountCurBalance: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CrAccountBalanceType">
            Dr/Cr<span>:</span>
          </label>
          <input
            type="text"
            name="CrAccountBalanceType"
            required
            readOnly
            value={CreditAccountstate.CrAccountBalanceType}
            onChange={(e) =>
              setCreditAccountstate({
                ...CreditAccountstate,
                CrAccountBalanceType: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CreditAmount">
            Credit <span>:</span>
          </label>
          <input
            type="number"
            name="CreditAmount"
            required
            readOnly={
              CreditAccountstate.CrAccountUnderGroup === "Bank Accounts"
            }
            value={CreditAccountstate.CreditAmount}
            onChange={(e) =>
              setCreditAccountstate({
                ...CreditAccountstate,
                CreditAmount: e.target.value,
              })
            }
          />
        </div>
      </div>
      <br />
      {CreditAccountstate.CrAccountUnderGroup === "Bank Accounts" ? (
        <>
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="">
                Payment Type <span>:</span>
              </label>
              <select
                name="PaymentType"
                required
                value={MultiplePaymentdata.PaymentType}
                onChange={(e) =>
                  setMultiplePaymentdata({
                    ...MultiplePaymentdata,
                    PaymentType: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                <option value="Cheque">Cheque</option>
                <option value="OnlinePayment">OnlinePayment</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="">
                Amount <span>:</span>
              </label>
              <input
                type="number"
                name="PaymentAmount"
                placeholder="Enter Amount"
                required
                value={MultiplePaymentdata.PaymentAmount}
                onChange={(e) =>
                  setMultiplePaymentdata({
                    ...MultiplePaymentdata,
                    PaymentAmount: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="">
                Remark <span>:</span>
              </label>
              <input
                type="text"
                name="Remarks"
                placeholder="Enter Remark"
                required
                value={MultiplePaymentdata.Remarks}
                onChange={(e) =>
                  setMultiplePaymentdata({
                    ...MultiplePaymentdata,
                    Remarks: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="Register_btn_con">
            <div className="RegisterForm_1_btns">
              <AddIcon
                style={{ cursor: "pointer" }}
                onClick={Addmuldiplepayment}
              />
            </div>
          </div>

          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Payment Type</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ArrayForMultiplePayment.map((row, index) => (
                  <tr key={index}>
                    <td>{row.id}</td>
                    <td>{row.PaymentType}</td>
                    <td>{row.PaymentAmount}</td>
                    <td>{row.Remarks}</td>
                    <td>
                      <DeleteIcon
                        onClick={() => {
                          Deletemultipay(row);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="for_total9">
            <div className="for_total9_xyz">Total</div>:
            <div className="for_total9_xyz">
              {CreditAccountstate.CreditAmount || 0}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={isedit ? update_data : add_data}
        >z
          {isedit ? "Update" : "Add"}
        </button>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Particulars</th>
              <th>Current Balance</th>
              <th>Debit</th>
              <th>CreditAmount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Paymentlist.map((row, index) => (
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.CrAccount}</td>
                <td>{row.CrAccountCurBalance}</td>
                <td>{row.CrAccountBalanceType}</td>
                <td>{row.CreditAmount}</td>
                <td>
                  <EditIcon
                    onClick={() => {
                      Editdataform(row);
                      setisedit(true);
                    }}
                  />

                  <DeleteIcon
                    onClick={() => {
                      Deletedataform(row);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="for_total9">
            <div className="for_total9_xyz">Total</div>:
            <div className="for_total9_xyz">
              {CreditAccountstate.CreditAmount || 0}
            </div>
          </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          // onClick={isedit?update_data:add_data}
          onClick={Postalldata}
        >
          {/* {isedit?'Update':'Submit'} */}
          Submit
        </button>
      </div>
    </div>
  );
}

export default ContraVoucher;
