import React, { useState } from 'react';
// import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { alignProperty } from '@mui/material/styles/cssUtils';
import EditIcon from "@mui/icons-material/Edit";

import { useSelector } from 'react-redux';
function Methods() {
  

  const[methodData,setMethodData]=useState([])
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);

const [method,setMethod]=useState('');
const [methodcode,setMethodCode] = useState('');
const urllink=useSelector(state=>state.userRecord?.UrlLink)


const handleSubmitMethods = async () => { 
  if (!method.trim() || !methodcode.trim()) {
    alert('Both unit Code Name and unit are required.');
    return; // Exit the function early if validation fails
  }
  try {
    // Make a POST request to your Django backend endpoint
     const response = await axios.post(`${urllink}mainddepartment/insertmethod`, {
      method,
      methodcode,
    });

    // Handle the response as needed
    console.log(response.data);
    
    // Optionally, you can reset the state after a successful submission
    setMethod('');
    setMethodCode('');
    fetchMethodData();
  } catch (error) {
    console.error('An error occurred:', error);
    // Handle error scenarios
  }
};
React.useEffect(() => {
  fetchMethodData();
}, []);
const fetchMethodData = () => {
  axios.get(`${urllink}mainddepartment/getmethod`)
    .then((response) => {
      const data = response.data;
      console.log("data",data)

      setMethodData(data)
    })
    .catch((error) => {
      console.error('Error fetching method data:', error);
    });
};

const handleEdit = (row) => {
  setMethod(row.method_name);
  setMethodCode(row.method_code);
  setIsEditMode(true);
  setSelectedMethodId(row.method_id); // Assuming `method_id` is the identifier
};

const handleUpdateMethod = async () => {
  try {
    const response = await axios.post(`${urllink}mainddepartment/updatemethod`, {
      method_id: selectedMethodId,
      method_name: method,
      method_code: methodcode,
    });
    console.log(response.data);
    setMethod('');
    setMethodCode('');
    setIsEditMode(false);
    setSelectedMethodId(null);
    fetchMethodData();
  } catch (error) {
    console.error('An error occurred:', error);
  }
};


  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Methods Management</h3>
          </div>
        </div>
        <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Methods</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Method Name :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Method Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={methodcode}
                    onChange={(e) => setMethodCode(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitMethods}>
  {isEditMode ? 'Update' : <AddIcon />}
</button>

              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Method Name</th>
                        <th >Method Code</th>
                        <th>Edit</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                    {methodData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.method_id}</td>
                          <td>{row.method_name}</td>
                          <td>{row.method_code}
                          </td>
                          <td>
                             <button onClick={() => handleEdit(row)}><EditIcon/></button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div>
        {/* <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Unit</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Unit :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Unit Code :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={unitcode}
                    onChange={(e) => setUnitCode(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" >
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Unit Name</th>
                        <th >Unit Code</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                     
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div> */}
      </div>
    </>
  );
}

export default Methods;
