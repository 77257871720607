import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function Hemodynamics() {

    const successMsg = (message) => {
        toast.success(`${message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          containerId: "toast-container-over-header",
          style: { marginTop: "50px" },
        });
      };
    
      const userwarn = (warningMessage) => {
        toast.warn(`${warningMessage}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          style: { marginTop: "50px" },
        });
      };

      

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  console.log(IpNurseQueSelectedRow);

//   const IpNurseQueSelectedRow = useSelector(
//     (state) => state.InPatients?.IpNurseQueSelectedRow
//   );
//   console.log("natha", IpNurseQueSelectedRow);

 
  const[Summastate,setSummastate]=useState(false)

  const [HemodynamicsData, setHemodynamicsData] = useState({
    Date: "",
    Time: "",
    Category: "",
    SubCategory: "",
    Status: "",
  });

  const [CategoryData, setCategoryData] = useState([]);
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [StatusData, setStatusData] = useState([]);


  const [dateAndTimeOptions, setDateAndTimeOptions] = useState([]);

  const [getdata,setgetdata]=useState(false)


  

  useEffect(() => {
    axios
    .get(`https://vesoftometic.co.in/IcuManagement/get_Category_list`)
    .then((response) => {
      console.log(response);
      setCategoryData(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  
 

  const getSubCategoryList = useCallback((category) => {
    axios
      .get(`https://vesoftometic.co.in/IcuManagement/get_SubCategory_list?Category=${category}`)
      .then((response) => {
        console.log(response);
        setSubCategoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);




  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setHemodynamicsData((prev) => ({
      ...prev,
      Category: category,
      SubCategory: "", // Reset subcategory when category changes
    }));
    // Fetch subcategories when the category changes
    if (category) {
        getSubCategoryList(category);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHemodynamicsData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };




    useEffect(() => {
    if (HemodynamicsData.SubCategory) {
      axios
      .get(`https://vesoftometic.co.in/IcuManagement/get_Status_list?SubCategory=${HemodynamicsData.SubCategory}`)
      .then((response) => {
        console.log(response);
        setStatusData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [HemodynamicsData.SubCategory]);




  




  
  
  const cleardata = () => {
    setHemodynamicsData({
      Date: "",
      Time: "",
      Category: "",
      SubCategory: "",
      Status: "",
    });
  };
 
  
  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/IcuManagement/get_Date_and_Time?Booking_Id=${IpNurseQueSelectedRow.Booking_Id}`)
      .then((response) => {
        // Assuming response.data is an array of objects with Date and Time properties
        setDateAndTimeOptions(response.data);
        console.log(response.data,"jjjjjjjjjjjjjjjjjjjjjjjjjjjj")
      })
      .catch((error) => {
        console.error("Error fetching category options:", error);
      });

    
  }, [IpNurseQueSelectedRow.Booking_Id,getdata]);
  
  

  
  

  
  
  const handleSave = () => {
    const requiredfields = [
      "Date",
      "Time",
      "Category",
      "SubCategory",
      "Status",
      
  ];
  const existing = requiredfields.filter((field) => !HemodynamicsData[field]);

  if (existing.length > 0) {
    alert(("please fill empty fields:", existing.join(",")));
  } else {
    const data = {
    ...HemodynamicsData,
    Booking_Id: IpNurseQueSelectedRow.Booking_Id,
    Createby: userRecord.username
  };

   
  axios.post(`https://vesoftometic.co.in/IcuManagement/insert_hemodynamics`, data)
  .then((response) => {
    console.log(response);
    if (response.data.message) {
      successMsg(response.data.message);
      setSummastate(!Summastate);
      setgetdata(!getdata)
    
      cleardata();
    } else {
      userwarn(response.data.Exists);
    }
  })
  .catch((error) => {
    console.log(error);
  });
}
   
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };


// get datas




 

  // useEffect(() => {
  //   // Fetch category options from backend
  //   axios
  //     .get("https://vesoftometic.co.in/IcuManagement/get_Date_and_Time")
  //     .then((response) => {
  //       setDateAndTimeOptions(response.data)
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching category options:", error);
  //     });
  // }, []);

 

// const handleCategoryChange = (event) => {
//     setCategory(event.target.value); // Update selected category
// };

  // const Post_vital_data = () => {
  //   const requiredfields = ["Date", "Time", "Category", "SubCategory", "Status"];
  //   const existing = requiredfields.filter((field) => !HemodynamicsData[field]);

  //   // Your logic to post data
  // };

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="RegisFormcon">
          {Object.keys(HemodynamicsData).map((labelname, index) => (
            <div className="RegisForm_1" key={index}>
              <label>
                {labelname === "Category" ? "Category" : formatLabel(labelname)}
                <span>:</span>
              </label>
              {labelname === "Category" ? (
                <select
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select</option>
                  {CategoryData.map((category) => (
                    <option key={category.id} value={category.Category}>
                      {category.Category}
                    </option>
                  ))}
                </select>
              ) : labelname === "SubCategory" ? (
                <select
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {SubCategoryData.map((subcategory,index) => (
                    <option key={index} value={subcategory.SubCategory}>
                      {subcategory.SubCategory}
                    </option>
                  ))}
                </select>
              ) : labelname === "Status" ? (
                <select
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {StatusData.map((statusItem,index) => (
                    <option key={index} value={statusItem.Status}>
                      {statusItem.Status}
                    </option>
                  ))}
                </select>
              ) : labelname === "Time" ? (
                <input
                  type="time"
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleInputChange}
                />
              ) : labelname === "Date" ? (
                <input
                  type="date"
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  type="number"
                  name={labelname}
                  value={HemodynamicsData[labelname]}
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))}
        </div>
        <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleSave}>Save</button>
            {/* <button onClick={handleSave}>Save</button> */}
          </div>
        {/* Rest of your JSX */}
      </div>


{/*..............table show.........................*/}

{
  dateAndTimeOptions?.map((row, ind) => (
    <div key={ind} >
      <h3 style={{width:'100%',textAlign:'center' ,display:'flex',justifyContent:'center',gap:'20px'}}>
        <span style={{color:'black'}}> 
        Date: {row.Date} 
          </span>
          <span style={{color:'black'}}>
          Time: {row.Time}
          </span>
      </h3>
      <br></br>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-around'}}>
      {
  Object.keys(row?.Data).map((category, indx) => (
    <div key={indx} style={{ marginBottom: '20px' }}>
      <h4 style={{ textAlign: 'center', color:'grey' }}>{category}</h4>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>SubCategory</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {row?.Data[category]?.map((p, i) => (
              <tr key={i}>
                <td>{p.SubCategory}</td>
                <td>{p.Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  ))
}
</div>

    </div>
  ))
}




{/*..............table show.........................*/}
      
      <ToastContainer />
    </>
  );
  
}
