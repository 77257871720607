import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
// import "../ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import '../../Emergency/Jeeva.css'


export default function EmergencyHistory() {
  const dispatchvalue = useDispatch();
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("jjjjjjjjj", IpNurseQueSelectedRow);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [page, setPage] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [summa, setSumma] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false); // Define renderFlag state

  const initialFormData = {}; // Replace this with your initial form data

  const [showTextarea, setShowTextarea] = useState(false); 
  // const [showTextarea1, setShowTextarea1] = useState(false); 

  

  // const [showPainFields, setShowPainFields] = useState(false);

  const [textareaValue, setTextareaValue] = useState(""); 
  const [tobaccoInfo,setTobaccoInfo] = useState("");
  const [alcoholInfo, setAlcoholInfo] = useState("");
  const [drugsInfo, setDrugsInfo] = useState("");
  


  const [checkboxValues, setCheckboxValues] = useState({
    Tobacco: 'No',
    Alcohol: 'No',
    Drugs: 'No',
    AbusePotential:  'No',
    Pain:  'No',
    
   
  });


  const [EmergencyFormData, setEmergencyFormData] = useState({
    Allergies: "",
    AllergiesInfo: "",
    Occupation: "",
    InformantReliability: "",
    CheifComplains: "",
    PresentIllness: "",
    Pain: "",
    Site: "",
    Duration: "",
    Score: "", 
    Frequency: "",
    Character: "",
    AggravatingRelievingFactors: "",
    PastMedicalHistory: "",
    SurgicalHistory: "",
    Menarche: "",
    Cycle: "",
    lmp: "",
    Contraception: "",
    MaritalHistory: "",
    pain: "",
    VaginalDischarge: "",
    ObstetricHistory: "",
    Tobacco: "",
    tobaccoInfo: "",
    Alcohol: "",
    alcoholInfo: "",
    Drugs: "",
    drugsInfo: "",
    FamilyHistory: "",
    SocioEconomicHistory: "",
    AbusePotential: "",
    AbusePotentialInfo: "",
    Date: "",
    Time: "",

    
  });
  
 

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();



  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `${urllink}EmergencyManagement/get_Emergency_History?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data[0];
          console.log("Fetched surgical history data:", data.AllergiesInfo);
  
          setEmergencyFormData((prev)=>({
            ...prev,
            Date: data?.Date || '',
            Time: data?.Time || '',
            Allergies: data?.Allergies || "", 
            AllergiesInfo: data?.AllergiesInfo || '',
            Occupation: data?.Occupation || "",
            InformantReliability: data?.InformantReliability || "",
            CheifComplains: data?.CheifComplains || "",
            PresentIllness: data?.PresentIllness || "",
            Pain: data?.Pain || "",
            Site: data?.Site || "",
            Duration: data?.Duration || "",
            Score: data?.Score || "",
            Frequency: data?.Frequency || "",
            Character: data?.Character || "",
            AggravatingRelievingFactors: data?.AggravatingRelievingFactors || "",
            PastMedicalHistory: data?.PastMedicalHistory || "",
            SurgicalHistory: data?.SurgicalHistory || "",
            Menarche: data?.Menarche || "",
            Cycle: data?.Cycle || "",
            lmp: data?.lmp || "",
            Contraception: data?.Contraception || "",
            MaritalHistory: data?.MaritalHistory || "",
            pain: data?.pain || "",
            VaginalDischarge: data?.VaginalDischarge || "",
            ObstetricHistory: data?.ObstetricHistory || "",
            Tobacco: data?.Tobacco || "",
            tobaccoInfo: data?.tobaccoInfo || "",
            Alcohol: data?.Alcohol || "",
            alcoholInfo: data?.alcoholInfo || "",
            Drugs: data?.Drugs || "",
            drugsInfo: data?.drugsInfo || "",
            FamilyHistory: data?.FamilyHistory || "",
            SocioEconomicHistory: data?.SocioEconomicHistory || "",
            AbusePotential: data?.AbusePotential || "",
            AbusePotentialInfo: data?.AbusePotentialInfo || "",
          }))
  
          // Set checkbox values based on fetched data
          setCheckboxValues({
            Tobacco: data?.Tobacco ,
            Alcohol: data?.Alcohol ,
            Drugs: data?.Drugs ,
            AbusePotential: data?.AbusePotential ,
            Pain: data?.Pain ,
            // Set other fields similarly
          });
        })
        .catch((error) => {
          console.error("Error fetching surgical history:", error);
        });
    }
  }, [IpNurseQueSelectedRow, urllink, renderFlag]);
  
  
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically

  useEffect(()=>{
      if (EmergencyFormData.Allergies === "Select") {
        setShowTextarea(false);
      } else {
        setShowTextarea(true);
      }
    
  },[EmergencyFormData.Allergies])


  const handleInputChange = (e) => {
    const { name, value,checked, type } = e.target;
    console.log(name, value, type);


    
  if (name === "tobaccoInfo") {
    setTobaccoInfo(value);
  } else if (name === "alcoholInfo") {
      setAlcoholInfo(value);
  } else if (name === "drugsInfo") {
      setDrugsInfo(value);
  } else if (name === "AbusePotentialInfo") {
    setTextareaValue(value);
  }

    setCheckboxValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  
    setEmergencyFormData((prev) => ({
      ...prev,
      
      [name]: value,
    }));

    // if (type === "checkbox") { // Check if it's a checkbox input
    //   setCheckboxValues(prevState => ({
    //     ...prevState,
    //     [name]: value ? "Yes" : "No", // Update checkbox value based on checked status
    //   }));
    // } else {
    //   setEmergencyFormData(prev => ({
    //     ...prev,
    //     [name]: value,
    //   }));
    // }
  };
  



 
  const Post_vital_data = () => {
    const requiredfields = [
      "Allergies",
      "AllergiesInfo",
      "Occupation",
      "InformantReliability",
      "CheifComplains",
      "PresentIllness",
      "Pain",
      "Site",
      "Duration",
      "Score",
      "Frequency",
      "Character",
      "AggravatingRelievingFactors",
      "PastMedicalHistory",
      "SurgicalHistory",
      "Menarche",
      "Cycle",
      "lmp",
      "Contraception",
      "MaritalHistory",
      "pain",
      "VaginalDischarge",
      "ObstetricHistory",
      "Tobacco",
      "tobaccoInfo",
      "Alcohol",
      "alcoholInfo",
      "Drugs",
      "drugsInfo",
      "FamilyHistory",
      "SocioEconomicHistory",
      "AbusePotential",
      "AbusePotentialInfo",
      "Date",
      "Time",
    ];


   
    const existing = requiredfields.filter((field) => !EmergencyFormData[field]);

    if (existing.length > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const Allsenddata = {
        ...EmergencyFormData,
        ...checkboxValues,
        AbusePotentialInfo: textareaValue,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_History`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          setRenderFlag(!renderFlag);
          setSuccessMessage("Data saved successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    
    <>
  
  
    <div className="new-patient-registration-form">
      <div className="RegisFormcon">
        <div className="RegisForm_1">
        <label htmlFor="Date"> Date:</label>
            <input
                type="date"
                id="Date"
                name="Date"
                value={EmergencyFormData.Date}
                onChange={handleInputChange}
            />

            <label htmlFor="Time">Time:</label>
            <input
                type="time"
                id="Time"
                name="Time"
                value={EmergencyFormData.Time}
                onChange={handleInputChange}
            />
        </div>
      </div>
      <br/>
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="allergies">Allergies <span>:</span></label>
            <select
              name="Allergies"
              value={EmergencyFormData.Allergies}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              <option value="food">Food</option>
              <option value="drug">Drug</option>
              <option value="others">Others</option>
            </select>
            <br/>
            {showTextarea && (
              <div>
                
                <textarea
                  name="AllergiesInfo"
                  value={EmergencyFormData.AllergiesInfo}
                  onChange={handleInputChange}
                />
              </div>
            )}
        </div>
  
        <div className="RegisForm_1">
          <label htmlFor="Occupation">Occupation <span>:</span></label>
          <textarea
            name="Occupation"
            value={EmergencyFormData.Occupation}
            onChange={handleInputChange}
          />
          
        </div>
  
        <div className="RegisForm_1">
          <label htmlFor="InformantReliability">Informant / Reliability<span>:</span></label>
          <textarea
            name="InformantReliability"
            value={EmergencyFormData.InformantReliability}
            onChange={handleInputChange}
          />
        </div>
  
        <div className="RegisForm_1">
          <label htmlFor="CheifComplaints">Chief Complaints with Duration <span>:</span></label>
          <textarea
            name="CheifComplains"
            value={EmergencyFormData.CheifComplains}
            onChange={handleInputChange}
          />
        </div>
  
        
        
  
       
        
  
        
      </div>
      <br />

     
    
          <h4>History of Present Illness:(Elaborate Symptoms in detail)</h4>
          <br/>
          <div className="RegisFormcon" >
              <div className="">
                  
                
                    <textarea
                        className="edwuedy47y"
                        placeholder="Enter details..."
                        name="PresentIllness"
                        value={EmergencyFormData.PresentIllness}
                        onChange={handleInputChange}
                        
                     />
                
              </div>
  
            </div>
            <br/>

          <div className="RegisForm_1">
            <label htmlFor="Pain">Pain <span>:</span></label>
            <div>
              <label >
              <input
                 type="checkbox"
                 id="painYes"
                 name="Pain"
                 value="Yes"
                 checked={checkboxValues.Pain === "Yes"}
                 onChange={handleInputChange}
                />
                Yes
              </label>
            </div>
            <div>
              <label >
                <input
                   type="checkbox"
                   id="painNo"
                   name="Pain"
                   value="No"
                   checked={checkboxValues.Pain === "No"}
                   onChange={handleInputChange}
                />
                No
              </label>
            </div>
          {/* Conditionally render the fields based on the state */}
          {/* {showPainFields && (
            <>
              
                <div className="RegisFormcon"> 
                  <label htmlFor="site">Site</label>
                  
                  <textarea
                    name="Site"
                    value={EmergencyFormData.Site}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="RegisFormcon">
                  <label htmlFor="duration">Duration</label>
                  
                  <textarea
                    name="Duration"
                    value={EmergencyFormData.Duration}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="RegisFormcon">
                  <label htmlFor="score">Score</label>
                 
                  <textarea
                    name="Score"
                    value={EmergencyFormData.Score}
                    onChange={handleInputChange}
                  />
                </div>
              
            </>
          )} */}
        </div>
        <br></br>
        <div className="RegisFormcon">
                <div className="RegisForm_1"> 
                  <label htmlFor="site">Site <span>:</span></label>
                  
                  <textarea
                    name="Site"
                    value={EmergencyFormData.Site}
                    onChange={handleInputChange}
                  />
                  
                 
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="duration">Duration <span>:</span></label>
                  
                  <textarea
                    name="Duration"
                    value={EmergencyFormData.Duration}
                    onChange={handleInputChange}
                  />
                  
                 
                </div>
                <div className="RegisForm_1">
                 <label htmlFor="score">Score <span>:</span></label>
                 
                 <textarea
                    name="Score"
                    value={EmergencyFormData.Score}
                    onChange={handleInputChange}
                  />
                 
                 
                </div>
        </div>
<br/>

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="Frequency">Frequency<span>:</span></label>
                <select
                  name="Frequency"
                  value={EmergencyFormData.Frequency}
                  onChange={handleInputChange}
                >
                  <option value="Select">Select</option>
                  <option value="Continuous">Continuous</option>
                  <option value="Intermittent">Intermittent</option>
                          
                </select>
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Character">Character<span>:</span></label>
                <select
                  name="Character"
                  value={EmergencyFormData.Character}
                  onChange={handleInputChange}
                >
                  <option value="Select">Select</option>
                  <option value="Continuous">Sharp & Pricing</option>
                  <option value="Intermittent">Colicky</option>
                  <option value="Compressive">Compressive</option>
                  <option value="DullAndDiffuse">Dull & diffuse</option>
                          
                </select>
              </div>

              <div className="RegisForm_1">
                <label htmlFor="AggravatingRelievingFactors">Aggravating / RelievingFactors <span>:</span></label>
                <textarea
                  name="AggravatingRelievingFactors"
                  value={EmergencyFormData.AggravatingRelievingFactors}
                  onChange={handleInputChange}
                />
              </div>
          </div>

<br/>

{/* <h4 style={{
                  color: "var(--labelcolor)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "start",
                  padding: "10px",
                }}
              > Past Medical History(Including chronic illness)</h4> */}
            
          <h4>Past Medical History(Including chronic illness)</h4>
          <br/>
              <div className="RegisFormcon">
                <div className="">
                  
       
                  
                    <textarea
                          className="edwuedy47y"
                          placeholder="Enter details..."
                          name="PastMedicalHistory"
                          value={EmergencyFormData.PastMedicalHistory}
                          onChange={handleInputChange}
                      />
                      
                
                </div>

              </div>
         
        <br/>

         


          <div>
              <div className="RegisFormcon">
                <h4>Surgical History (Year / Procedure /Hospital)</h4>

              </div>
              <div className="RegisFormcon">
                <div className="">
                  
                <br />

                <textarea
                   className="edwuedy47y"
                    placeholder="Enter details..."
                    name="SurgicalHistory"
                    value={EmergencyFormData.SurgicalHistory}
                    onChange={handleInputChange}
                  />
                </div>

              </div>
          </div>
          <br/>

         
          <div className="RegisFormcon"><h4>Menstrual History</h4></div>
          <br/>
          <div className="RegisFormcon">
          
            <div className="RegisForm_1">
            <label htmlFor="Menarche">Menarche<span>:</span></label>
              
       
            <textarea
                name="Menarche"
                value={EmergencyFormData.Menarche}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="Menarche">Cycle<span>:</span></label>
            <textarea
                name="Cycle"
                value={EmergencyFormData.Cycle}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="lmp">LMP<span>:</span></label>
            <textarea
                name="lmp"
                value={EmergencyFormData.lmp}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="Contraception">Contraception<span>:</span></label>
            <textarea
                name="Contraception"
                value={EmergencyFormData.Contraception}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="MaritalHistory">Marital History<span>:</span></label>
            <textarea
                name="MaritalHistory"
                value={EmergencyFormData.MaritalHistory}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="pain">Pain<span>:</span></label>
            <textarea
                name="pain"
                value={EmergencyFormData.pain}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="VaginalDischarge">Vaginal discharge<span>:</span></label>
            <textarea
                name="VaginalDischarge"
                value={EmergencyFormData.VaginalDischarge}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
            <label htmlFor="ObstetricHistory">Obstetric History<span>:</span></label>
            <textarea
                name="ObstetricHistory"
                value={EmergencyFormData.ObstetricHistory}
                onChange={handleInputChange}
              />
            </div>
          </div>   
<br/>
          <div className="RegisFormcon"><h3>Personal History</h3></div>
          <br/>
          <div className="RegisFormcon"><h4>Addictions :</h4></div>
<br/>
          

         <div className="RegisFormcon">
              <div className="RegisForm_1">

                      <div className="RegisFormcon">
                          <div className="wdvw_yd">
                              <label>
                                  <input
                                      type="checkbox"
                                      id="Tobacco"
                                      name="Tobacco"
                                      value={checkboxValues.Tobacco === "Yes"?'No':'Yes'}
                                      checked={checkboxValues.Tobacco === "Yes"}
                                      onChange={handleInputChange}
                                  />
                              </label>
                          </div>
                          <div className="RegisFormcon">
                              
                              <label htmlFor="Tobacco">
                                    Tobacco
                              </label>
                            
                          </div>
                        
                  </div>
                  <label htmlFor="tobaccoInfo">Tobacco Information:</label>
                  <textarea
                    id="tobaccoInfo"
                    name="tobaccoInfo"
                    value={EmergencyFormData.tobaccoInfo}
                    onChange={handleInputChange}
                  />
              </div>
              <div className="RegisForm_1">
                     <div className="RegisFormcon">
                       <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="Alcohol"
                                    name="Alcohol"
                                    value={checkboxValues.Alcohol === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.Alcohol ==="Yes"}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div className="RegisFormcon">
                              <label htmlFor="Alcohol">
                                  <p> Alcohol</p>
                              </label>
                        </div>
                      
                      </div>
                <label htmlFor="alcoholInfo">Alcohol Information:</label>
                <textarea
                  id="alcoholInfo"
                  name="alcoholInfo"
                  value={EmergencyFormData.alcoholInfo}
                  onChange={handleInputChange}
                />
              </div>

              <div className="RegisForm_1">
                    <div className="RegisFormcon">
                         <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="Drugs"
                                    name="Drugs"
                                    value={checkboxValues.Drugs === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.Drugs === "Yes"}
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        <div className="RegisFormcon">
                            <label htmlFor="Drugs">
                                <p> Drugs</p> 
                            </label>
                        </div>
                        
                       
                        
                    </div>
                <label htmlFor="alcoholInfo">Drug Information:</label>
                
                <textarea
                  id="drugsInfo"
                  name="drugsInfo"
                  value={EmergencyFormData.drugsInfo}
                  onChange={handleInputChange}
                />
              </div>

         </div>



          <br/>  
            <div>
              <div className="RegisFormcon">
                <h4>Family History</h4>
                
              </div>      
              <br/>  
              <div className="RegisFormcon">
                <div className="">
                
              <br />
                <textarea
                    className="edwuedy47y"
                    name="FamilyHistory"
                    value={EmergencyFormData.FamilyHistory}
                    onChange={handleInputChange}
                  />
                </div>

              </div>

            </div>   
          <br/>

            <div>
              <div className="RegisFormcon">
                <h4>Socio Economic History</h4>
                
              </div>      
              <br/>  
              <div className="RegisFormcon">
                <div className="">
                
              <br />
                <textarea
                    className="edwuedy47y"
                    name="SocioEconomicHistory"
                    value={EmergencyFormData.SocioEconomicHistory}
                    onChange={handleInputChange}
                  />
                </div>

              </div>

            </div>  
            
                   
              <br/>

             <div>
                <div className="RegisFormcon">
                    <h4>Abuse Potential :</h4>
                </div> 
                <br/>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                  
                      <label htmlFor="AbusePotential">
                          <input
                              type="checkbox"
                              id="AbusePotential"
                              name="AbusePotential"
                              value="Yes"
                              checked={checkboxValues.AbusePotential === "Yes"}
                              onChange={handleInputChange}
                          />
                          Yes
                      </label>
                      <label>
                          <input
                              type="checkbox"
                              id="AbusePotential"
                              name="AbusePotential"
                              value="No"
                              checked={checkboxValues.AbusePotential === "No"}
                              onChange={handleInputChange}
                          />
                          No
                      </label>
                  
                  </div>
                </div>
                <br/>
                {checkboxValues.AbusePotential === "Yes" && (
                    <div className="RegisFormcon">
                        <textarea
                            rows="4"
                            cols="50"
                            name="AbusePotentialInfo"
                            placeholder="Enter details..."
                            value={EmergencyFormData.AbusePotentialInfo}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
             </div>



            

            
  
  
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={Post_vital_data}>Add</button>
      </div>
  
      
    </div>
  </>
  
  
    );
    
}
