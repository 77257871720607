import { useState } from 'react';
import React from 'react';
import './Medicalhistory.css';

const MedicalHistory = () => {
    const [currentPregnancy, setCurrentPregnancy] = useState({
        bleeding: '',
        nauseaVomiting: '',
        rashFeverIllness: '',
    });

    const [nutrition, setNutrition] = useState({
        calciumAdequate: '',
        vitaminDAdequate: '',
        folicAcidPreconception: '',
        prenatalVitamin: '',
        foodAccessQualityAdequate: '',
        dietaryRestriction: '',
    });

    const [medicalHistory, setMedicalHistory] = useState({
        hypertension: '',
        endocrine: '',
        giLiver: '',
        breast: '',
        gynecological: '',
        urinaryTract: '',
        hematological: '',
        thromboembolicCoag: '',
        giLiver: '',
        mskRheumatology: '',
        bloodTransfusion: '',
        cardiacPulmonary: '',
        otherMedicalHistory: '',
    });

    const [infectiousDisease, setInfectiousDisease] = useState({
        varicellaDisease: '',
        varicellaVaccine: '',
        hiv: '',
        stis: '',
        atRiskPopulations: '',
        hsv: { past: '', present: '' },
        otherInfectiousDisease: '',
    });

    const [familyHistory, setFamilyHistory] = useState({
        medicalConditions: '',
    });

    const [carrierScreening, setCarrierScreening] = useState({
        Hemoglobinopathy: '',
        TaySachsDesease: '',
        AshkenaziJewishscreening: '',

    });

    const [geneticFamilyHistory, setGeneticFamilyHistory] = useState({
        GeneticCondition: '',
        OtherGeneticFamily: '',
        Consangunity: '',

    });

    const [mentalHealth, setMentalHealth] = useState({
        anxiety: { past1: '', present1: '' },
        depression: { past: '', present: '' },
        eatingDisorder: '',
        schizophrenia: '',
        bipolar: '',
        marijuana: '',
        nonPrescribedSubstances: '',
        smokedCigarette: '',
        currentSmoking: '',
        everDrinkAlcohol: '',
        lastDrink: '',
        currentDrinking: '',
        tAceScore: '',
        otherConditions: '',
    });

    const [lifeStyle, setLifeStyle] = useState({

        ocupational: '',
        financialHousing: '',
        poorsocial: '',
        beleifsPractices: '',
        relationshipProblems: '',
        intimatePartner: '',
        parentingConcern: '',
        othersStyle: '',
        comments: '',
    });


    const [isChecked, setIsChecked] = useState(false);
    const [isAlcoholChecked, setIsAlcoholChecked] = useState(false);

    const handleCheckboxChangeAnxiety = (section, key, value) => {
        if (value === mentalHealth[key]) {
            setMentalHealth({ ...mentalHealth, [key]: '' });
        } else {
            setMentalHealth({ ...mentalHealth, [key]: value });
        }
    };

    const handleCheckboxChangeDepression = (section, key, value) => {
        if (value === mentalHealth[key]) {
            setMentalHealth({ ...mentalHealth, [key]: '' });
        } else {
            setMentalHealth({ ...mentalHealth, [key]: value });
        }
    };

    const handleCheckboxChangeLifeStyle = (section, key, value) => {
        if (value === lifeStyle[key]) {
            setLifeStyle({ ...lifeStyle, [key]: '' });
        } else {
            setLifeStyle({ ...lifeStyle, [key]: value });
        }
    };


    const handleCheckboxChange = (section, key, value) => {
        setIsChecked(value === 'Yes');
        setMentalHealth({ ...mentalHealth, [key]: value });
    };

    const handleCheckboxChange1 = (section, key, value) => {
        setIsAlcoholChecked(value === 'Yes');
        setMentalHealth({ ...mentalHealth, [key]: value });
    };

    const handleCheckboxChangeCurrentPregnancy = (section, key, value) => {
        if (value === currentPregnancy[key]) {
            setCurrentPregnancy({ ...currentPregnancy, [key]: '' });
        } else {
            setCurrentPregnancy({ ...currentPregnancy, [key]: value });
        }
    };

    const handleCheckboxChangeNutrition = (section, key, value) => {
        if (value === nutrition[key]) {
            setNutrition({ ...nutrition, [key]: '' });
        } else {
            setNutrition({ ...nutrition, [key]: value });
        }
    };

    const handleCheckboxChangeMedicalHistory = (section, key, value) => {
        if (value === medicalHistory[key]) {
            setMedicalHistory({ ...medicalHistory, [key]: '' });
        } else {
            setMedicalHistory({ ...medicalHistory, [key]: value });
        }
    };

    const handleCheckboxChangeInfectiousDisease = (section, key, value) => {
        if (value === infectiousDisease[key]) {
            setInfectiousDisease({ ...infectiousDisease, [key]: '' });
        } else {
            setInfectiousDisease({ ...infectiousDisease, [key]: value });
        }
    };

    const handleCheckboxChangeFamilyHistory = (section, key, value) => {
        if (value === familyHistory[key]) {
            setFamilyHistory({ ...familyHistory, [key]: '' });
        } else {
            setFamilyHistory({ ...familyHistory, [key]: value });
        }

    };

    const handleCheckboxChangeCarrierScreening = (section, key, value) => {
        if (value === carrierScreening[key]) {
            setCarrierScreening({ ...carrierScreening, [key]: '' });
        } else {
            setCarrierScreening({ ...carrierScreening, [key]: value });
        }

    };

    const handleCheckboxChangeGeneticFamilyHistory = (section, key, value) => {
        if (value === carrierScreening[key]) {
            setGeneticFamilyHistory({ ...geneticFamilyHistory, [key]: '' });
        } else {
            setGeneticFamilyHistory({ ...geneticFamilyHistory, [key]: value });
        }

    };

    return (
        <div className='medical_history_container'>
            <div className='org-header'>
                <h2 className='h_head'>
                    Medical History
                </h2>
            </div>
            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Current Pregnancy
                    </p>
                </div>

                <div className="enquery">
                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Bleeding </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input
                                type='checkbox'
                                name='yes'
                                id='yes'
                                checked={currentPregnancy.bleeding === 'Yes'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'bleeding', 'Yes')}
                            />
                            <label className='check_lable'>Yes</label>
                            <input
                                type='checkbox'
                                name='no'
                                id='no'
                                checked={currentPregnancy.bleeding === 'No'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'bleeding', 'No')}
                            />
                            <label className='check_lable'>No</label>
                        </div>
                    </div>

                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Nausea /Vomiting </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input
                                type='checkbox'
                                name='yes'
                                id='yes'
                                checked={currentPregnancy.nauseaVomiting === 'Yes'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'nauseaVomiting', 'Yes')}
                            />
                            <label className='check_lable'>Yes</label>
                            <input
                                type='checkbox'
                                name='no'
                                id='no'
                                checked={currentPregnancy.nauseaVomiting === 'No'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'nauseaVomiting', 'No')}
                            />
                            <label className='check_lable'>No</label>
                        </div>
                    </div>

                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Rash /Fever /Illness </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input
                                type='checkbox'
                                name='yes'
                                id='yes'
                                checked={currentPregnancy.rashFeverIllness === 'Yes'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'rashFeverIllness', 'Yes')}
                            />
                            <label className='check_lable'>Yes</label>
                            <input
                                type='checkbox'
                                name='no'
                                id='no'
                                checked={currentPregnancy.rashFeverIllness === 'No'}
                                onChange={() => handleCheckboxChangeCurrentPregnancy('currentPregnancy', 'rashFeverIllness', 'No')}
                            />
                            <label className='check_lable'>No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Nutrition
                    </p>
                </div>

                <div className="split-line">
                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Calcium Adequate </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.calciumAdequate === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'calciumAdequate', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.calciumAdequate === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'calciumAdequate', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Vitamin D Adequate </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.vitaminDAdequate === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'vitaminDAdequate', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.vitaminDAdequate === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'vitaminDAdequate', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Folic Acid Preconception </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.folicAcidPreconception === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'folicAcidPreconception', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.folicAcidPreconception === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'folicAcidPreconception', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>

                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Prenatal Vitamin </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.prenatalVitamin === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'prenatalVitamin', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.prenatalVitamin === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'prenatalVitamin', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Food Access /Quality Adequate </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.foodAccessQualityAdequate === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'foodAccessQualityAdequate', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.foodAccessQualityAdequate === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'foodAccessQualityAdequate', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Dietary Restriction </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={nutrition.dietaryRestriction === 'Yes'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'dietaryRestriction', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={nutrition.dietaryRestriction === 'No'}
                                    onChange={() => handleCheckboxChangeNutrition('nutrition', 'dietaryRestriction', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Medical History
                    </p>
                </div>


                <div className="split-line">
                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Hypertension </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.hypertension === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'hypertension', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.hypertension === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'hypertension', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Endocrine </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.endocrine === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'endocrine', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.endocrine === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'endocrine', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>GI/Liver </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.giLiver === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'giLiver', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.giLiver === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'giLiver', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>

                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Breast(Incl.Surgery) </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.breastSurgery === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'breastSurgery', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.breastSurgery === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'breastSurgery', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Gynecological (incl.surgery) </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.gynecologicalSurgery === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'gynecologicalSurgery', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.gynecologicalSurgery === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'gynecologicalSurgery', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Urinary tract </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input
                                    type='checkbox'
                                    name='yes'
                                    id='yes'
                                    checked={medicalHistory.urinaryTract === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'urinaryTract', 'Yes')}
                                />
                                <label className='check_lable'>Yes</label>
                                <input
                                    type='checkbox'
                                    name='no'
                                    id='no'
                                    checked={medicalHistory.urinaryTract === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'urinaryTract', 'No')}
                                />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="split-line">
                    <div className="enquery">

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Hematological </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.hematological === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'hematological', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.hematological === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'hematological', 'No')} />

                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Thrmoboembolic /coag </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.thromboembolicCoag === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'thromboembolicCoag', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.thromboembolicCoag === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'thromboembolicCoag', 'No')} />

                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        {/* <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>GI/Liver </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.giLiver === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'giLiver', 'Yes')} />
                                <label  className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.giLiver === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'giLiver', 'No')} />
                                <label  className='check_lable'>No</label>
                            </div>
                        </div> */}

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Cardiac /Pulmonary </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.cardiacPulmonary === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'cardiacPulmonary', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.cardiacPulmonary === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'cardiacPulmonary', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>


                    </div>
                    <div className="enquery">

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>MSK /Rheumatology </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.mskRheumatology === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'mskRheumatology', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.mskRheumatology === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'mskRheumatology', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Blood Transfusion </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.bloodTransfusion === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'bloodTransfusion', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.bloodTransfusion === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'bloodTransfusion', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Other </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={medicalHistory.otherMedicalHistory === 'Yes'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'otherMedicalHistory', 'Yes')} />
                                <label >Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={medicalHistory.otherMedicalHistory === 'No'}
                                    onChange={() => handleCheckboxChangeMedicalHistory('medicalHistory', 'otherMedicalHistory', 'No')} />
                                <label >No</label>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Infectious Disease
                    </p>
                </div>


                <div className="split-line">
                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Varcella Disease </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={infectiousDisease.varicellaDisease === 'Yes'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'varicellaDisease', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={infectiousDisease.varicellaDisease === 'No'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'varicellaDisease', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Vaicella vaccine </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={infectiousDisease.varicellaVaccine === 'Yes'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'varicellaVaccine', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={infectiousDisease.varicellaVaccine === 'No'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'varicellaVaccine', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>HIV </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={infectiousDisease.hiv === 'Yes'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'hiv', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={infectiousDisease.hiv === 'No'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'hiv', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>
                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>STIs </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={infectiousDisease.stis === 'Yes'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'stis', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={infectiousDisease.stis === 'No'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'stis', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>

                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>At risk Populations(Hep C, TB, Parvo, Toxo) </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={infectiousDisease.atRiskPopulations === 'Yes'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'atRiskPopulations', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={infectiousDisease.atRiskPopulations === 'No'}
                                    onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'atRiskPopulations', 'No')} />
                                <label className='check_lable' >No</label>
                            </div>
                        </div>
                    </div>


                </div>




                {/* <div className="enquery">
                 
                </div> */}
                <div className="enquery">
                   <div className="ques past-prst-alncnt">
                        <label htmlFor="bleed" className='input_lables'>HSV  </label>

                    </div>
                    <div className="ques1">
                        <label htmlFor="bleed" className='input_lables'>past </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input type="checkbox" name="yes" id="yes"
                                checked={infectiousDisease.past === 'Yes'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'past', 'Yes')} />
                            <label className='check_lable'>Yes</label>

                            <input type="checkbox" name="no" id="no"
                                checked={infectiousDisease.past === 'No'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'past', 'No')} />
                            <label className='check_lable'>No</label>
                        </div>
                    </div>


                    <div className="ques1">
                        <label htmlFor="bleed" className='input_lables'>Present </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input type="checkbox" name="yes" id="yes"
                                checked={infectiousDisease.present === 'Yes'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'present', 'Yes')} />
                            <label className='check_lable'>Yes</label>

                            <input type="checkbox" name="no" id="no"
                                checked={infectiousDisease.present === 'No'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'present', 'No')} />
                            <label className='check_lable'>No</label>
                        </div>
                    </div>
                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Other </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input type="checkbox" name="yes" id="yes"
                                checked={infectiousDisease.otherInfectiousDisease === 'Yes'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'otherInfectiousDisease', 'Yes')} />
                            <label >Yes</label>

                            <input type="checkbox" name="no" id="no"
                                checked={infectiousDisease.otherInfectiousDisease === 'No'}
                                onChange={() => handleCheckboxChangeInfectiousDisease('infectiousDisease', 'otherInfectiousDisease', 'No')} />
                            <label >No</label>
                        </div>
                    </div>
                </div>

            



                {/* <div className="enquery1">
                 

                </div> */}


            </div>


            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Family History
                    </p>
                </div>

                <div className="enquery">
                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'> Medical Conditions (eg:Diabetes, thyorid, hypertension, thromboembolic, anaesthetic, Mental Health) </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input
                                type='checkbox'
                                name='yes'
                                id='yes'
                                checked={familyHistory.medicalConditions === 'Yes'}
                                onChange={() => handleCheckboxChangeFamilyHistory('familyHistory', 'medicalConditions', 'Yes')}
                            />
                            <label >Yes</label>
                            <input
                                type='checkbox'
                                name='no'
                                id='no'
                                checked={familyHistory.medicalConditions === 'No'}
                                onChange={() => handleCheckboxChangeFamilyHistory('familyHistory', 'medicalConditions', 'No')}
                            />
                            <label >No</label>
                        </div>
                    </div>
                </div>
            </div>




            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Genetic History
                    </p>
                </div>

                <div className="enquery">
                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Ethnic /Racial Background <span>:</span></label>

                    </div>
                    <div className="ques1">
                        <label htmlFor="bleed" className='input_lables'>Egg </label>
                        <span>:</span>
                        <input type="text" name="yes" id="yes" className='input_txt' />

                    </div>
                    <div className="ques1">
                        <label htmlFor="bleed" className='input_lables'>Age </label>
                        <span>:</span>
                        <input type="text" name="yes" id="yes" className='input_txt' />
                        {/* <label htmlFor="yrs" >Yrs</label> */}
                    </div>
                    <div className="ques1">
                        <label htmlFor="bleed" className='input_lables'>T-ACE Score </label>
                        <span>:</span>
                        <input type="text" name="yes" id="yes" className='input_txt' />

                    </div>




                </div>
                <div className="split-line">
                    <div className="enquery">

                        <div className="sub_title_t">
                            Carrier Screening: at risk ?
                        </div>

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Hemoglobinopathy screening (Asian, African, Middle Eastern, Mediterranean, Hispanic, Caribbean) </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={carrierScreening.Hemoglobinopathy === 'Yes'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'Hemoglobinopathy', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={carrierScreening.Hemoglobinopathy === 'No'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'Hemoglobinopathy', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>

                        </div>

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Tay-Sachs Desease screening (Ashkenazi Jewish, French Canadian, Acadian, Cajun) </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={carrierScreening.TaySachsDesease === 'Yes'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'TaySachsDesease', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={carrierScreening.TaySachsDesease === 'No'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'TaySachsDesease', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>

                        </div>



                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Ashkenazi Jewish screening panel </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={carrierScreening.AshkenaziJewishscreening === 'Yes'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'AshkenaziJewishscreening', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={carrierScreening.AshkenaziJewishscreening === 'No'}
                                        onChange={() => handleCheckboxChangeCarrierScreening('carrierScreening', 'AshkenaziJewishscreening', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="enquery">
                        <div className="sub_title_t">
                            Genetic Family History
                        </div>

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Genetic Condition (e.g: CF, Muscular dystrophy, Chromosomal disoder) </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={geneticFamilyHistory.GeneticCondition === 'Yes'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'GeneticCondition', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={geneticFamilyHistory.GeneticCondition === 'No'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'GeneticCondition', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>




                        </div>

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Other (e.g: Intellectual, Birth defect, Congenital heart, Developmetal delay, Recurrent pregnancy loss, Stillbirth) </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={geneticFamilyHistory.OtherGeneticFamily === 'Yes'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'OtherGeneticFamily', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={geneticFamilyHistory.OtherGeneticFamily === 'No'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'OtherGeneticFamily', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>
                        </div>
                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Consangunity </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={geneticFamilyHistory.Consangunity === 'Yes'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'Consangunity', 'Yes')} />
                                    <label >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={geneticFamilyHistory.Consangunity === 'No'}
                                        onChange={() => handleCheckboxChangeGeneticFamilyHistory('geneticFamilyHistory', 'Consangunity', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>



            </div>
            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Mental Health /Substance Use
                    </p>
                </div>


                <div className="split-line">
                    <div className="enquery">
                        <div className="enquery">
                            <div className="ques past-prst-alncnt">
                                <label htmlFor="anxiety" className='input_lables'>Anxiety</label>
                            </div>
                        </div>
                        <div className="enquery">
                            <div className="ques1">
                                <label htmlFor="anxietyPast" className='input_lables'>Past</label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Past1 === 'Yes'}
                                        onChange={() => handleCheckboxChangeAnxiety('mentalHealth', 'Past1', 'Yes')}
                                        name="anxietyPast"
                                        id="anxietyPast"
                                    />
                                    <label htmlFor="anxietyPast" className='check_lable'>Yes</label>
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Past1 === 'No'}
                                        onChange={() => handleCheckboxChangeAnxiety('mentalHealth', 'Past1', 'No')}
                                        name="anxietyPast"
                                        id="anxietyPastNo"
                                    />
                                    <label htmlFor="anxietyPastNo" className='check_lable'>No</label>
                                </div>
                            </div>

                            <div className="ques1">
                                <label htmlFor="anxietyPresent" className='input_lables'>Present</label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Present1 === 'Yes'}
                                        onChange={() => handleCheckboxChangeAnxiety('mentalHealth', 'Present1', 'Yes')}
                                        name="anxietyPresent"
                                        id="anxietyPresent"
                                    />
                                    <label htmlFor="anxietyPresent" className='check_lable'>Yes</label>
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Present1 === 'No'}
                                        onChange={() => handleCheckboxChangeAnxiety('mentalHealth', 'Present1', 'No')}
                                        name="anxietyPresent"
                                        id="anxietyPresentNo"
                                    />
                                    <label htmlFor="anxietyPresentNo" className='check_lable'>No</label>
                                </div>
                            </div>

                            <div className="ques1">
                                <label htmlFor="tAceScore" className='input_lables'>T-ACE Score</label>
                                <span>:</span>
                                <input type="text" name="tAceScore" id="tAceScore" className='input_txt'/>
                            </div>
                        </div>
                    </div>

                    <div className="enquery">
                        <div className="enquery">
                            <div className="ques past-prst-alncnt">
                                <label htmlFor="depression" className='input_lables'>Depression </label>
                            </div>
                        </div>
                        <div className="enquery">
                            <div className="ques1">
                                <label htmlFor="depressionPast" className='input_lables'>Past</label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Past === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'Past', 'Yes')}
                                        name="depressionPast"
                                        id="depressionPast"
                                    />
                                    <label htmlFor="depressionPast" className='check_lable'>Yes</label>
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Past === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'Past', 'No')}
                                        name="depressionPast"
                                        id="depressionPastNo"
                                    />
                                    <label htmlFor="depressionPastNo" className='check_lable'>No</label>
                                </div>
                            </div>

                            <div className="ques1">
                                <label htmlFor="depressionPresent" className='input_lables'>Present</label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Present === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'Present', 'Yes')} name="depressionPresent"
                                        id="depressionPresent"
                                    />
                                    <label htmlFor="depressionPresent" className='check_lable'>Yes</label>
                                    <input
                                        type="checkbox"
                                        checked={mentalHealth.Present === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'Present', 'No')}
                                        name="depressionPresent"
                                        id="depressionPresentNo"
                                    />
                                    <label htmlFor="depressionPresentNo" className='check_lable'>No</label>
                                </div>
                            </div>

                            <div className="ques1">
                                <label htmlFor="tAceScore" className='input_lables'>T-ACE Score</label>
                                <span>:</span>
                                <input type="text" name="tAceScore" id="tAceScore" className='input_txt' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="split-line">
                    <div className="enquery">
                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Eating disoder </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.eatingDisorder === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'eatingDisorder', 'Yes')} />
                                    <label className='check_lable'>Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.eatingDisorder === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'eatingDisorder', 'No')} />
                                    <label className='check_lable'>No</label>
                                </div>
                            </div>
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Schizophrenia </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.schizophrenia === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'schizophrenia', 'Yes')} />
                                    <label className='check_lable'>Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.schizophrenia === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'schizophrenia', 'No')} />
                                    <label className='check_lable'>No</label>
                                </div>
                            </div>
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Bipolar </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.bipolar === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'bipolar', 'Yes')} />
                                    <label className='check_lable'>Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.bipolar === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'bipolar', 'No')} />
                                    <label className='check_lable'>No</label>
                                </div>
                            </div>

                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Marijuana </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={mentalHealth.marijuana === 'Yes'}
                                    onChange={() => handleCheckboxChangeDepression('mentalHealth', 'marijuana', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={mentalHealth.marijuana === 'No'}
                                    onChange={() => handleCheckboxChangeDepression('mentalHealth', 'marijuana', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                    </div>
                    <div className="enquery">
                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Non-Prescribed Substances /Durgs </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.nonPrescribedSubstances === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'nonPrescribedSubstances', 'Yes')} />
                                    <label className='check_lable'>Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.nonPrescribedSubstances === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'nonPrescribedSubstances', 'No')} />
                                    <label className='check_lable'>No</label>
                                </div>
                            </div>
                        </div>
                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Smocked Cigarette within past 6 months </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.smokedCigarette === 'Yes'}
                                        onChange={() => handleCheckboxChange('mentalHealth', 'smokedCigarette', 'Yes')} />
                                    <label  >Yes</label>

                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.smokedCigarette === 'No'}
                                        onChange={() => handleCheckboxChange('mentalHealth', 'smokedCigarette', 'No')} />
                                    <label  >No</label>
                                </div>
                            </div>


                        </div>




                        {isChecked && (
                            <div className="enquery">
                                <div className="ques">
                                    <label htmlFor="bleed" className='input_lables'>Current Smocking </label>
                                    <span>:</span>
                                    <div className="answer_c">
                                        <input type="text" name='counts' className='input_txt' />
                                        <label htmlFor="cig">cig/day</label>
                                    </div>
                                </div>

                            </div>
                        )}

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="bleed" className='input_lables'>Ever Drink Alcohol? </label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input type="checkbox" name="yes" id="yes"
                                        checked={mentalHealth.everDrinkAlcohol === 'Yes'}
                                        onChange={() => handleCheckboxChange1('mentalHealth', 'everDrinkAlcohol', 'Yes')} />
                                    <label >Yes</label>
                                    <input type="checkbox" name="no" id="no"
                                        checked={mentalHealth.everDrinkAlcohol === 'No'}
                                        onChange={() => handleCheckboxChange1('mentalHealth', 'everDrinkAlcohol', 'No')} />
                                    <label >No</label>
                                </div>
                            </div>




                        </div>
                        {isAlcoholChecked && (

                            <div className="enquery">

                                <div className="ques">
                                    <label htmlFor="bleed" className='input_lables'>Last Drink </label>
                                    <span>:</span>
                                    <input type="text" name="when" id="yes" className='input_txt' />

                                </div>
                                <div className="ques">
                                    <label htmlFor="bleed" className='input_lables'>Current Drinking </label>
                                    <span>:</span>
                                    <input type="text" name="yes" id="yes" className='input_txt' />

                                </div>
                                <div className="ques">
                                    <label htmlFor="bleed" className='input_lables'>T-ACE Score </label>
                                    <span>:</span>
                                    <input type="text" name="yes" id="yes" className='input_txt' />

                                </div>
                            </div>



                        )}

                        <div className="enquery">
                            <div className="ques">
                                <label htmlFor="otherConditions" className='input_lables'>Other (e.g: PTSD, ADD, Personality Disorder)</label>
                                <span>:</span>
                                <div className="answer_c">
                                    <input
                                        type="checkbox"
                                        name="otherConditions"
                                        id="otherConditions"
                                        checked={mentalHealth.otherConditions === 'Yes'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'otherConditions', 'Yes')}
                                    />
                                    <label htmlFor="otherConditions" className='check_lable'>Yes</label>
                                    <input
                                        type="checkbox"
                                        name="no"
                                        id="no"
                                        checked={mentalHealth.otherConditions === 'No'}
                                        onChange={() => handleCheckboxChangeDepression('mentalHealth', 'otherConditions', 'No')}
                                    />
                                    <label className='check_lable'>No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="questions">
                <div className="ques_title_t">
                    <p className='ques_title'>
                        Lifestyle/Social
                    </p>
                </div>


                <div className="split-line">
                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Ocupational Risk </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.ocupational === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'ocupational', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.ocupational === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'ocupational', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Financial /Housing Issues </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.financialHousing === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'financialHousing', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.financialHousing === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'financialHousing', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>



                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Poor social support </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.poorsocial === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'poorsocial', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.poorsocial === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'poorsocial', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Beleifs /Practices affecting care </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.beleifsPractices === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'beleifsPractices', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.beleifsPractices === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'beleifsPractices', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>


                        </div>


                    </div>


                    <div className="enquery">
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Relationship Problems </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.relationshipProblems === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'relationshipProblems', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.relationshipProblems === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'relationshipProblems', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>
                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Intimate partner /Family violence </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.intimatePartner === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'intimatePartner', 'Yes')} />
                                <label className='check_lable'>Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.intimatePartner === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'intimatePartner', 'No')} />
                                <label className='check_lable'>No</label>
                            </div>
                        </div>





                        <div className="ques">
                            <label htmlFor="bleed" className='input_lables'>Parenting Concern (e.g: Developmental disability, Family trauma) </label>
                            <span>:</span>
                            <div className="answer_c">
                                <input type="checkbox" name="yes" id="yes"
                                    checked={lifeStyle.parentingConcern === 'Yes'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'parentingConcern', 'Yes')} />
                                <label >Yes</label>

                                <input type="checkbox" name="no" id="no"
                                    checked={lifeStyle.parentingConcern === 'No'}
                                    onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'parentingConcern', 'No')} />
                                <label >No</label>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="enquery">
                    <div className="ques">
                        <label htmlFor="bleed" className='input_lables'>Others </label>
                        <span>:</span>
                        <div className="answer_c">
                            <input type="checkbox" name="yes" id="yes"
                                checked={lifeStyle.othersStyle === 'Yes'}
                                onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'othersStyle', 'Yes')} />
                            <label >Yes</label>

                            <input type="checkbox" name="no" id="no"
                                checked={lifeStyle.othersStyle === 'No'}
                                onChange={() => handleCheckboxChangeLifeStyle('lifeStyle', 'othersStyle', 'No')} />
                            <label >No</label>
                        </div>
                    </div>




                </div>

            </div>
            <div className="questions1">
                <div className="ques_title_t1">
                    <p className='ques_title'>
                        Comments <span>:</span>
                    </p>


                    <div className="ques">
                        <textarea name="comments" id="comments" cols="50" rows="5"></textarea>
                    </div>


                </div>
            </div>
            <div className="summary-qus">
                <div className="summary">
                    <label htmlFor="completed">Completed By <span>:</span></label>
                    <input type="text" name="completed by" id="" className='input_txt' />
                </div>
                <div className="summary">
                    <label htmlFor="completed">Reviewed By <span>:</span></label>
                    <input type="text" name="review" id="" className='input_txt' />
                </div>
            </div>
            <div className="summary-qus">
                <div className="summary1">
                    <label htmlFor="completed">Signature <span>:</span></label>
                </div>
                <div className="summary1">
                    <label htmlFor="completed">Date <span>:</span></label>
                    <input type="date" name="" id="" className='date-input' />
                </div>
                <div className="summary1">
                    <label htmlFor="completed">MRP Signature <span>:</span></label>
                </div>
                <div className="summary1">
                    <label htmlFor="completed">Date <span>:</span></label>
                    <input type="date" name="" id="" className='date-input' />
                </div>
            </div>
            <div className="question">
                <div className="submition">
                    <button className='submit'>Submit</button>
                </div>
            </div>



        </div>
    )
}

export default MedicalHistory;

