import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./IpNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function IpWardChange() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);


  const [page, setPage] = useState(0);
  const [summa, setsumma] = useState([]);
  const [getdatastate, setgetdatastate] = useState(false);
  const [Wards, setWards] = useState([])
  const [WardRooms, setWardRoom] = useState([])

  const [VitalFormData, setVitalFormData] = useState({
    BookingId: '',
    PatientId: '',
    WardType: '',
    RoomType: '',
    RequestDate: '',
    RequestTime: '',
    Reason: '',
    CreatedBy: '',
    Status:'Pending'
  });
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);


    setVitalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


  useEffect(() => {
    const datte = format(new Date(), "yyyy-MM-dd");
    setVitalFormData((prev) => ({
      ...prev,
      RequestDate: datte,
      RequestTime: format(new Date(), 'HH:mm'),
      BookingId:IpNurseQueSelectedRow?.BookingId,
      PatientId:IpNurseQueSelectedRow?.PatientId,
      CreatedBy:userRecord?.username
    }));
  }, [IpNurseQueSelectedRow,userRecord]);
  useEffect(() => {
    axios.get(`${UrlLink}usercontrol/get_ward_type`)
      .then((response) => {
        console.log(response)
        const data =response.data
        let filteredData = data.filter(p => p.Status === 'Active' && p.Location === userRecord?.location);
        let uniqueData = Array.from(new Set(filteredData.map(item => item.WardType)))
         

        setWards(uniqueData);
      })
      .catch((error) => {
        console.log(error)
      })
  }, [userRecord?.location])
  useEffect(() => {
    axios.get(`${UrlLink}usercontrol/get_Room_type`)
      .then((response) => {
        console.log(response)
        const data =response.data
        let filteredData = data.filter(p => p.Status === 'Active' && p.Location === userRecord?.location && p.WardType===VitalFormData.WardType);
        let uniqueData = Array.from(new Set(filteredData.map(item => item.RoomType)))
         

        setWardRoom(uniqueData)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [VitalFormData.WardType,userRecord?.location])

  useEffect(() => {
    if (IpNurseQueSelectedRow?.BookingId) {
      axios
        .get(
          `${UrlLink}ipregistration/get_reqroomchange?Booking_Id=${IpNurseQueSelectedRow?.BookingId}`
        )
        .then((response) => {
          const data = response.data;
          console.log("222", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching doctor names:", error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.BookingId, getdatastate]);

  const pageSize = 10;
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: 'id',
      headerName: 'S_No',
      width: 40,
    },
    ...Object.keys(VitalFormData).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 10,
      };
    })
  ];

  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }



  const Post_vital_data = () => {
    const requiredfields = [
      "WardType",
      "RoomType",
      "RequestDate",
      "RequestTime",
    ];
    const existing = requiredfields.filter((field) => !VitalFormData[field]);

    if (existing > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
   
      axios
        .post(
          `${UrlLink}ipregistration/insert_reqroomchange`,
          VitalFormData
        )
        .then((response) => {
          console.log(response);
          setgetdatastate(!getdatastate);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="RegisFormcon" id="RegisFormcon_111">
          {Object.keys(VitalFormData).filter((p) => ['WardType', 'RoomType', 'RequestDate', 'RequestTime', 'Reason'].includes(p)).map((labelname, index) => (
            <div className="RegisForm_1" key={index}>
              <label htmlFor={labelname}>
                {formatLabel(labelname)} <span>:</span>
              </label>
              {labelname === "WardType" || labelname === 'RoomType' ? (
                <select
                  name={labelname}
                  value={VitalFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {
                    labelname === 'RoomType' &&

                    Wards.map((wardType, index) => (
                      <option key={index} value={wardType}>{wardType}</option>
                    ))

                  }
                  {
                    labelname === 'RoomType' &&
                    WardRooms.map((wardType, index) => (
                      <option key={index} value={wardType}>{wardType}</option>
                    ))
                  }
                </select>
              ) : labelname === 'Reason' ? (
                <textarea
                  id="Complaint"
                  name="Complaint"
                  placeholder="maximum 250 letters"
                  value={VitalFormData[labelname]}
                  cols="25"
                  rows="3"
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  type={labelname === "RequestDate" ? "date" : labelname === "RequestTime" ? 'time' : "text"}
                  name={labelname}
                  // onKeyDown={blockInvalidChar}
                  // placeholder={`Enter the ${formatLabel(labelname)}`}
                  value={VitalFormData[labelname]}
                  readOnly={labelname === "CapturedDate"}
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))}
        </div>
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <button className='btn-add' onClick={Post_vital_data}>
            Add
          </button>
        </div>



        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
