import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import '../SupplierMaster/SupplierMaster.css'
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from 'react-redux';





const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "var(--ProjectColor)",
                    textAlign: 'Center',
                },
                root: {
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
                        textAlign: 'center',
                        display: 'flex !important',
                        justifyContent: 'center !important'
                    },
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  var(--ProjectColor) !important",
                    display: 'flex',
                    justifyContent: 'center'
                },
            },
        },
    },
});

export default function GrnApprove() {

    const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

    const userRecord = useSelector(state => state.userRecord?.UserData)

    const yourStyles = {
        position: 'absolute',
        inset: '100px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(97 90 90 / 75%)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px'
    }



    const navigate = useNavigate();
    const [page1, setPage1] = useState(0);
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [Productype, setProductype] = useState('');
    const [summa, setsumma] = useState([])
    const [selectedShow, setSelectedShow] = useState(false);
    const [selectedShow1, setSelectedShow1] = useState(false);
    const [statustype, setstatustype] = useState('Waiting')

    const [Rowdata, setRowdata] = useState([]);
    const [SelectedRowss, setSelectedRowss] = useState(null);


    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const showdown1 = Rowdata.length;
    const pageSize1 = 10;

    useEffect(() => {
        if (summa.length > 0 && Rowdata.length > 0) {
            const allRejected = Rowdata.every((node) => node.Status === 'Rejected')




            if (allRejected) {

                console.log('123', allRejected)

                const editrow = {
                    Productype: Productype,
                    index: SelectedRowss,
                    Status: 'Rejected',
                    Approved_By: userRecord?.username
                }
                axios.post(`http://127.0.0.1:8000/SupplierMaster/Update_GRN_Raise_Appove`, editrow)
                    .then((response) => {
                        console.log(response.data)
                        setSelectedShow1(!selectedShow1)
                        setSelectedShow(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }

        }

    }, [summa])


    // useEffect(() => {

    //     if (Productype === 'Medical') {
    //         axios.get(`http://127.0.0.1:8000/SupplierMaster/get_medical_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}`)
    //             .then((response) => {
    //                 const data = response.data.medical_purchase_orders;
    //                 console.log('out', data.medical_purchase_orders);
    //                 setsumma([
    //                     ...data.map((row, ind) => ({
    //                         id: ind + 1,
    //                         ...row,
    //                     }))
    //                 ]);
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching patients data:', error);
    //             });
    //     } else if (Productype === 'Non_Medical') {
    //         axios.get(`http://127.0.0.1:8000/SupplierMaster/get_non_medical_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}`)
    //             .then((response) => {
    //                 const data = response.data.non_medical_purchase_orders;
    //                 console.log('out', data.non_medical_purchase_orders);
    //                 setsumma([
    //                     ...data.map((row, ind) => ({
    //                         id: ind + 1,
    //                         ...row,
    //                     }))
    //                 ]);
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching patients data:', error);
    //             });
    //     }
    //     else if (Productype === 'Assets') {
    //         axios.get(`http://127.0.0.1:8000/SupplierMaster/get_assets_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}`)
    //             .then((response) => {
    //                 const data = response.data.assets_purchase_orders;
    //                 console.log('out', data.assets_purchase_orders);
    //                 setsumma([
    //                     ...data.map((row, ind) => ({
    //                         id: ind + 1,
    //                         ...row,
    //                     }))
    //                 ]);
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching patients data:', error);
    //             });
    //     }
    //     else if (Productype === 'Stationary') {
    //         axios.get(`http://127.0.0.1:8000/SupplierMaster/get_stationary_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}`)
    //             .then((response) => {
    //                 const data = response.data.stationary_purchase_orders;
    //                 console.log('out', data.stationary_purchase_orders);
    //                 setsumma([
    //                     ...data.map((row, ind) => ({
    //                         id: ind + 1,
    //                         ...row,
    //                     }))
    //                 ]);
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching patients data:', error);
    //             });
    //     }
    //     else if (Productype === 'Non_Stationary') {
    //         axios.get(`http://127.0.0.1:8000/SupplierMaster/get_non_stationary_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}`)
    //             .then((response) => {
    //                 const data = response.data.non_stationary_purchase_orders;
    //                 console.log('out', data.non_stationary_purchase_orders);
    //                 setsumma([
    //                     ...data.map((row, ind) => ({
    //                         id: ind + 1,
    //                         ...row,
    //                     }))
    //                 ]);
    //             })      
    //             .catch((error) => {
    //                 console.error('Error fetching patients data:', error);
    //             });
    //     }

    // }, [Productype,statustype,selectedShow1]);



    useEffect(() => {

        if (Productype !== '') {
            if (statustype === 'Recieved' || statustype === 'GRNApproved') {
                if (Productype === 'MedicalConsumable' || 
                Productype === 'MedicalNonConsumable' || 
                Productype === 'NonMedicalConsumable' || 
                Productype === 'NonMedicalNonConsumable' || 
                Productype === 'Surgical') {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_Medical_GRN_data?Status=${statustype}&Productype=${Productype}`)
                        .then((response) => {
                            const data = response.data.data
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error('Error fetching patients data:', error);
                        });
                } else {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_Non_Medical_GRN_data?Status=${statustype}&Productype=${Productype}`)
                        .then((response) => {
                            const data = response.data.data
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            } else {
                if (Productype === 'MedicalConsumable' ||  
                Productype === 'MedicalNonConsumable' || 
                Productype === 'NonMedicalConsumable' || 
                Productype === 'NonMedicalNonConsumable' || 
                Productype === 'Surgical') {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_medical_purchase_order_data?Status=${statustype}`)
                        .then((response) => {
                            const data = response.data.medical_purchase_orders
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error('Error fetching patients data:', error);
                        });
                    }
                // else if (Productype === 'Non_Medical') {
                //     axios.get(`http://127.0.0.1:8000/SupplierMaster/get_non_medical_purchase_order_data?Status=${statustype}`)
                //         .then((response) => {
                //             const data = response.data.non_medical_purchase_orders;
                //             setsumma([
                //                 ...data.map((row, ind) => ({
                //                     id: ind + 1,
                //                     ...row,
                //                 }))
                //             ]);
                //         })
                //         .catch((error) => {
                //             console.error('Error fetching patients data:', error);
                //         });
                // }
                else if (Productype === 'Assets') {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_assets_purchase_order_data?Status=${statustype}`)
                        .then((response) => {
                            const data = response.data.assets_purchase_orders;
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error('Error fetching patients data:', error);
                        });
                }
                else if (Productype === 'Stationary') {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_stationary_purchase_order_data?Status=${statustype}`)
                        .then((response) => {
                            const data = response.data.stationary_purchase_orders;
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error('Error fetching patients data:', error);
                        });
                }
                else if (Productype === 'Non_Stationary') {
                    axios.get(`http://127.0.0.1:8000/SupplierMaster/get_non_stationary_purchase_order_data?Status=${statustype}`)
                        .then((response) => {
                            const data = response.data.non_stationary_purchase_orders;
                            setsumma([
                                ...data.map((row, ind) => ({
                                    id: ind + 1,
                                    ...row,
                                }))
                            ]);
                        })
                        .catch((error) => {
                            console.error('Error fetching patients data:', error);
                        });
                }
            }
        }

    }, [Productype, selectedShow1, statustype]);
    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const index = params.row.items
        setSelectedRowss(params.row.PurchaseOrder_InvoiceNo)
        console.log('index:', index)
        let B_data = index.map((p, index) => ({
            id: index + 1,
            ...p
        }))
        setRowdata(B_data)
        setSelectedShow(true);

    };

    const handleApproveEdit = (params) => {
        const allRejected = params.row.items.every((node) => node.Status === 'Rejected');
        console.log(allRejected);

        const updatedRowdata = params.row.items.map(item => {
            if (item.Status !== 'Rejected') {
                return { ...item, Status: 'Approved' };
            } else {
                return item;
            }
        });
        const data = updatedRowdata.map((row) => ({
            Productype: Productype,
            index: params.row.PurchaseOrder_InvoiceNo,
            Item_Code: row.Item_Code,
            Approved_Quantity: row.ApprovedQuantity,
            Status: row.Status,

        }))
        // console.log(data)
        if (allRejected) {
            alert('All items are Rejected');

        } else {
            if (params.row.Status === 'Waiting') {
                const editrow = {
                    Productype: Productype,
                    index: params.row.PurchaseOrder_InvoiceNo,
                    Status: 'Approved',
                    Approved_By: userRecord?.username
                }
                console.log(editrow)
                const resultReject = window.confirm('Are you sure ,you want to Approve it ?')

                if (resultReject) {
                    axios.post(`http://127.0.0.1:8000/SupplierMaster/Update_GRN_Raise_Appove`, editrow)
                        .then((response) => {
                            console.log(response.data)
                            setSelectedShow1(!selectedShow1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    axios.post(`http://127.0.0.1:8000/SupplierMaster/Approve_mull_the_GRN`, data)
                        .then((response) => {
                            console.log(response.data)
                            setSelectedShow1(!selectedShow1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }
            }
        }


    }
    
    const handleApproveGRN = (params) => {
        console.log(params.row)
        const editrow = {
            Productype: Productype,
            index: params.row.GRN_InvoiceNo,
            Status: 'GRNApproved',
            Approved_By: userRecord?.username
        }
        const GRN_invoice = {
            Productype: Productype,
            grn_invoice_no: params.row.GRN_InvoiceNo,
            Created_By: userRecord?.username,
            Location: params.row.Location
        };
        const resultReject = window.confirm('Are you sure ,you want to Approve it ?')
        if (resultReject) {
            axios.post('http://127.0.0.1:8000/SupplierMaster/Approve_GRN_ivoice', editrow)
                .then((response) => {
                    console.log(response)
                    if (Productype === 'MedicalConsumable' || Productype === 'MedicalNonConsumable' || 
                    Productype === 'NonMedicalConsumable' || Productype === 'NonMedicalNonConsumable' || Productype === 'Surgical' ) {
                        axios.post('http://127.0.0.1:8000/quickstockreceive/insertMedical_Stock_information', GRN_invoice)
                            .then((response) => {
                                console.log(response)
                                setSelectedShow1(!selectedShow1)
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    } else {
                        axios.post('http://127.0.0.1:8000/quickstockreceive/insertAssets_Stock_information', GRN_invoice)
                            .then((response) => {
                                console.log(response)
                                setSelectedShow1(!selectedShow1)
                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    }

                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }
    const handleViewDocument = (params) => {

        console.log('ConsentForm', params.row.Bill_Document);

        const Bill_Document = params.row.Bill_Document

        if (Bill_Document === 'data:image/jpg;base64,' || 'data:application/octet-stream;base64') {
            alert('No Data');
        } else {
            setModalContent(Bill_Document);
            setModalIsOpen(true);
        }
    }


    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent('');
    };


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        console.log('ss', summa)

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = statustype === 'Recieved' || statustype === 'GRNApproved' ? row.GRN_InvoiceNo.toLowerCase() : row.PurchaseOrder_InvoiceNo.toLowerCase();

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery, summa]);





    const handlePageChange1 = (params) => {
        setPage1(params.page);
    };
    const totalPages1 = Math.ceil(Rowdata.length / 10);


    // Define the columns dynamically
    const dynamicColumns = () => {
        if (statustype === 'Recieved' || statustype === 'GRNApproved') {
            return (
                [
                    { field: 'GRN_InvoiceNo', headerName: 'GRN InvoiceNo', width: 200 },
                    { field: 'GRN_Date', headerName: 'GRN date', width: 200 },
                    { field: 'Supplier_Name', headerName: 'Supplier Name', width: 170 },
                    { field: 'Supplier_Code', headerName: 'Supplier_Code', width: 170 },
                    { field: 'Supplier_Bill_No', headerName: 'Supplier BillNo', width: 170 },
                    { field: 'Supplier_Bill_Date', headerName: 'Supplier BillDate', width: 170 },
                    { field: 'Supplier_Bill_Due_Date', headerName: ' BillDue Date', width: 170 },
                    { field: 'Grand_Total', headerName: ' Amount', width: 170 },
                    { field: 'Total_Discount_Type', headerName: ' Discount Type', width: 170 },
                    { field: 'Total_Discount', headerName: ' Discount', width: 170 },
                    { field: 'GRN_Finel_NetAmount', headerName: ' Total', width: 170 },
                    {
                        field: 'Document', headerName: ' Bill Document', width: 170,
                        renderCell: (params) => (
                            <>
                                <Button className='cell_btn' onClick={() => handleViewDocument(params)}>
                                    <VisibilityIcon />
                                </Button>
                            </>
                        ),
                    },
                    { field: 'Status', headerName: 'Status', width: 120 },
                    {
                        field: 'View',
                        headerName: 'View',
                        width: 120,
                        renderCell: (params) => (
                            <>
                                <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                                    <VisibilityIcon />
                                </Button>
                                {params.row.Status === 'Recieved' &&
                                    <Button className='cell_btn' >
                                        <CheckCircleOutlineIcon onClick={() => handleApproveGRN(params)} />
                                    </Button>}
                            </>
                        ),
                    },
                ]
            )
        } else {
            return [
                { field: 'PurchaseOrder_InvoiceNo', headerName: 'PurchaseOrder InvoiceNo', width: 200 },
                { field: 'Purchaserise_date', headerName: 'Purchaserise date', width: 200 },
                { field: 'Supplier_Name', headerName: 'Supplier Name', width: 170 },
                { field: 'Supplier_Code', headerName: 'Supplier_Code', width: 170 },
                { field: 'Status', headerName: 'Status', width: 120 },
                {
                    field: 'View',
                    headerName: 'View',
                    width: 120,
                    renderCell: (params) => (
                        <>
                            <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                                <VisibilityIcon />
                            </Button>
                            {params.row.Status === 'Waiting' &&
                                <Button className='cell_btn' onClick={() => handleApproveEdit(params)}>
                                    <CheckCircleOutlineIcon />
                                </Button>}
                        </>
                    ),
                },

            ]
        }
    };

    const dynamicColumns1 = () => {
        if (statustype === 'Recieved' || statustype === 'GRNApproved') {
            return (
                [
                    { field: 'Item_Code', headerName: 'Item Code', width: 150 },
                    { field: 'Item_Name', headerName: 'Item Name', width: 150 },
                    { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
                    { field: 'Strength', headerName: 'Strength', width: 120 },
                    { field: 'UOM', headerName: 'UOM', width: 120 },
                    { field: 'Pack_type', headerName: 'Pack Type', width: 120 },
                    { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 120 },
                    { field: 'Order_Quantity', headerName: 'Order Quantity', width: 120 },
                    { field: 'RecievedOrder_Quantity', headerName: 'Recieved Quantity', width: 120 },
                    { field: 'RecievedTotalQuantity', headerName: 'Total Quantity', width: 120 },
                    { field: 'Batch_No', headerName: 'Batch_No', width: 120 },
                    { field: 'ManufactureDate', headerName: 'ManufactureDate', width: 120 },
                    { field: 'ExpiryDate', headerName: 'ExpiryDate', width: 120 },
                    { field: 'MRP_Per_Quantity', headerName: 'MRP', width: 120 },
                    { field: 'Purchase_Rate', headerName: 'Purchase Rate', width: 120 },
                    { field: 'GST_Type', headerName: 'TAX Type', width: 120 },
                    { field: 'Tax_Percentage', headerName: 'Tax Percentage ', width: 120 },
                    { field: 'Purchase_Rate_withtax', headerName: 'Purchase_Rate_withtax ', width: 120 },
                    { field: 'Selling_Rate', headerName: 'Selling_Rate ', width: 120 },
                    { field: 'Taxable_Selling_Rate', headerName: 'Taxable_Selling_Rate ', width: 120 },
                    { field: 'DiscountType', headerName: 'DiscountType ', width: 120 },
                    { field: 'Discount', headerName: 'Discount ', width: 120 },
                    { field: 'TotalAmount', headerName: 'TotalAmount ', width: 120 },

                ]
            )
        } else {
            return ([
                { field: 'Item_Code', headerName: 'Item Code', width: 150 },
                { field: 'Item_Name', headerName: 'Item Name', width: 150 },
                { field: 'Generic_Name', headerName: 'Generic Name', width: 150 },
                { field: 'Strength', headerName: 'Strength', width: 120 },
                { field: 'UOM', headerName: 'UOM', width: 120 },
                { field: 'Pack_type', headerName: 'Pack Type', width: 120 },
                { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 120 },
                { field: 'OrderQuantity', headerName: 'Order Quantity', width: 120 },
                { field: 'ApprovedQuantity', headerName: 'Approved Quantity', width: 120 },
                { field: 'TotalQuantity', headerName: 'Total Quantity', width: 120 },
                { field: 'Status', headerName: 'Status', width: 120 },
                {
                    field: 'View',
                    headerName: 'Action',
                    width: 140,
                    renderCell: (params) => (
                        params.row.Status === "Waiting" ? (
                            <>
                                <Button className='cell_btn' onClick={() => handleApproveEditIndent(params)}>
                                    <EditIcon />
                                </Button>
                                <Button className='cell_btn' onClick={() => handleRejectEdit(params)}>
                                    <DeleteForeverIcon />
                                </Button>
                            </>
                        ) : (
                            'Action Completed'
                        )
                    ),
                }

            ])
        }
    }
    const handleApproveEditIndent = (params) => {

        if (params.row.Status !== "Rejected") {

            const newApprovedQuantity = parseFloat(prompt('Enter Approved Quantity:', params.row.ApprovedQuantity));

            if (!isNaN(newApprovedQuantity) && newApprovedQuantity <= params.row.OrderQuantity) {
                // Update the Approved_Quantity and Status
                params.row.ApprovedQuantity = newApprovedQuantity;
                params.row.TotalQuantity = +params.row.Pack_Quantity * +newApprovedQuantity || 0;
                const data = {
                    Productype: Productype,
                    index: SelectedRowss,
                    Item_Code: params.row.Item_Code,
                    Approved_Quantity: params.row.ApprovedQuantity,
                    TotalQuantity: params.row.TotalQuantity,
                    Status: params.row.Status,

                }
                console.log(data)
                axios.post(`http://127.0.0.1:8000/SupplierMaster/Reject_the_GRN`, data)
                    .then((response) => {
                        console.log(response.data)
                        setSelectedShow1(!selectedShow1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // params.row.Status = 'Approved';
                // Add code to update the data in your backend/database
            } else {
                alert('Invalid Approved Quantity. Please enter a valid number Less than or equal Order Quantity.');
            }
        } else {
            alert('The selected Item is Rejected')
        }
    };
    const handleRejectEdit = (params) => {
        if (params.row.Status !== "Rejected") {
            const conform = window.confirm('Are you sure ,want to reject the Item')
            if (conform) {
                params.row.ApprovedQuantity = 0;
                params.row.Status = 'Rejected';
                console.log(params.row)
                const data = {
                    Productype: Productype,
                    index: SelectedRowss,
                    Item_Code: params.row.Item_Code,
                    Approved_Quantity: params.row.ApprovedQuantity,
                    TotalQuantity: params.row.TotalQuantity,
                    Status: params.row.Status,

                }
                axios.post(`http://127.0.0.1:8000/SupplierMaster/Reject_the_GRN`, data)
                    .then((response) => {
                        console.log(response.data)
                        setSelectedShow1(!selectedShow1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }

        } else {
            alert('The selected Item is Already Rejected')
        }
    };
    const dynamicColumns2 = () => {
        if (statustype === 'Recieved' || statustype === 'GRNApproved') {
            return (
                [
                    { field: 'Item_Code', headerName: 'Item Code', width: 150 },
                    { field: 'Item_Name', headerName: 'Item Name', width: 150 },
                    { field: 'Pack_type', headerName: 'Pack Type', width: 120 },
                    { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 120 },
                    { field: 'Order_Quantity', headerName: 'Order Quantity', width: 120 },
                    { field: 'RecievedOrder_Quantity', headerName: 'Recieved Quantity', width: 120 },
                    { field: 'PendingQuantity', headerName: 'Pending Quantity', width: 120 },
                    { field: 'Reason', headerName: 'Reason', width: 120 },
                    { field: 'RecievedTotalQuantity', headerName: 'Total Quantity', width: 120 },
                    { field: 'MRP_Per_Quantity', headerName: 'MRP', width: 120 },
                    { field: 'Purchase_Rate', headerName: 'Purchase Rate', width: 120 },
                    { field: 'GST_Type', headerName: 'TAX Type', width: 120 },
                    { field: 'Tax_Percentage', headerName: 'TAX ', width: 120 },
                    { field: 'Purchase_Rate_withtax', headerName: 'Amount', width: 120 },
                    { field: 'DiscountType', headerName: 'Discount Type', width: 120 },
                    { field: 'Discount', headerName: 'Discount', width: 120 },
                    { field: 'TotalAmount', headerName: 'Total', width: 120 },

                ]
            )
        } else {
            return ([
                { field: 'Item_Code', headerName: 'Item Code', width: 150 },
                { field: 'Item_Name', headerName: 'Item Name', width: 150 },
                { field: 'Pack_type', headerName: 'Pack Type', width: 120 },
                { field: 'Pack_Quantity', headerName: 'Pack Quantity', width: 120 },
                { field: 'OrderQuantity', headerName: 'Order Quantity', width: 120 },
                { field: 'ApprovedQuantity', headerName: 'Approved Quantity', width: 120 },
                { field: 'TotalQuantity', headerName: 'Total Quantity', width: 120 },
                { field: 'Status', headerName: 'Status', width: 120 },
                {
                    field: 'View',
                    headerName: 'Action',
                    width: 140,
                    renderCell: (params) => (
                        params.row.Status === "Waiting" ? (
                            <>
                                <Button className='cell_btn' onClick={() => handleApproveEditIndent(params)}>
                                    <EditIcon />
                                </Button>
                                <Button className='cell_btn' onClick={() => handleRejectEdit(params)}>
                                    <DeleteForeverIcon />
                                </Button>
                            </>
                        ) : (
                            'Action Completed'
                        )
                    ),
                }

            ])
        }
    }




    return (
        <>

<div className="appointment">
        <div className="h_head h_head_h_2">
                    <h4>GRN Appove List</h4>
                    <div className="doctor_select_1 selt-dctr-nse vcxw2er">
                        <label htmlFor="Calender"> Product Type :</label>
                        <select value={Productype} onChange={(e) => { setProductype(e.target.value) }}>
                            <option value=''>Select</option>
                            <option value="MedicalConsumable">Medical Consumable</option>
                            <option value="MedicalNonConsumable">
                                Medical Non-Consumable
                            </option>
                            <option value="NonMedicalConsumable">Non MedicalConsumable</option>
                            <option value="NonMedicalNonConsumable">Non MedicalNon-Consumable</option>
                            <option value="Surgical">Surgical</option>
                            <option value='Assets'>Assets</option>
                            <option value='Stationary'>Stationary</option>
                            <option value='Non_Stationary'>Non Stationary</option>
                        </select>
                    </div>

                </div>
                <div className="con_1 ">
          <div className="inp_1">
                        <label htmlFor="input">Invoice No <span>:</span></label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Invoice No' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Status <span>:</span></label>
                        <select
                            value={statustype}
                            onChange={(e) => setstatustype(e.target.value)}
                        >
                            <option value=''> Select</option>
                            <option value='Recieved'> GRN Recieved</option>
                            <option value='GRNApproved'>GRN Approved</option>
                        </select>
                    </div>

                </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns()} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                    {selectedShow && (
                        <div
                            className={
                                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                            } onClick={() => {
                                setSelectedShow(false);
                            }}

                        >
                            <div className="newwProfiles" style={{ padding: '10px', boxSizing: 'border-box' }} onClick={(e) => e.stopPropagation()}>
                                <div className="appointment">
                                    <div className="calendar_head">
                                        <h3>{Productype}List</h3>
                                    </div>
                                    <div className='IP_grid'>
                                        <ThemeProvider theme={theme}>
                                            <div className='IP_grid_1'>
                                                <DataGrid
                                                    rows={Rowdata.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                                                    columns={Productype === 'MedicalConsumable' || Productype === 'MedicalNonConsumable' ||Productype === 'NonMedicalConsumable' 
                                                    ||Productype === 'NonMedicalNonConsumable' || Productype === 'Surgical'
                                                     ? dynamicColumns1() : dynamicColumns2()} // Use dynamic columns here
                                                    pageSize={10}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: {
                                                                pageSize: 10,
                                                            },
                                                        },
                                                    }}
                                                    pageSizeOptions={[10]}
                                                    onPageChange={handlePageChange1}
                                                    hideFooterPagination
                                                    hideFooterSelectedRowCount
                                                    className='data_grid'
                                                />
                                                {showdown1 > 0 && Rowdata.length > 10 && (
                                                    <div className='IP_grid_foot'>
                                                        <button
                                                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                                            disabled={page === 0}
                                                        >
                                                            Previous
                                                        </button>
                                                        Page {page1 + 1} of {totalPages1}
                                                        <button
                                                            onClick={() =>
                                                                setPage((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                                                            }
                                                            disabled={page1 === totalPages1 - 1}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </ThemeProvider>
                                        {showdown1 !== 0 && Rowdata.length !== 0 ? (
                                            ''
                                        ) : (
                                            <div className='IP_norecords'>
                                                <span>No Records Found</span>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                                        <button
                                            className="closeicon-cs"
                                            onClick={() => {
                                                setSelectedShow(false);
                                            }}

                                        >
                                            close
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    )}
                </div>


                <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { ...yourStyles } }}>
                    <div className="pdf_img_show">
                        {modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
                            <iframe
                                title="PDF Viewer"
                                src={modalContent}
                                style={{
                                    width: "100%",
                                    height: "435px",
                                    border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                                }}
                            />
                        ) : (
                            <img
                                src={modalContent}
                                alt="Concern Form"
                                style={{
                                    width: "80%",
                                    height: "75%",
                                    marginTop: "20px",
                                }}
                            />
                        )}
                        <div className="jhuhhjh">
                            <Button
                                style={{ color: "white" }}
                                className="clse_pdf_img"
                                onClick={closeModal}
                            >
                                <HighlightOffIcon
                                    style={{
                                        fontSize: "40px",
                                        backgroundColor: "#54d854bf",
                                        borderRadius: "40px",
                                    }}
                                />
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}
