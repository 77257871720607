import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
// import "../ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import '../../Emergency/Jeeva.css'


export default function EmergencyTransfertoAndReason() {
  const dispatchvalue = useDispatch();
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("jjjjjjjjj", IpNurseQueSelectedRow);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [page, setPage] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [summa, setSumma] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false); // Define renderFlag state

  const initialFormData = {}; // Replace this with your initial form data

  const [showTextarea, setShowTextarea] = useState(false); 
  // const [showTextarea1, setShowTextarea1] = useState(false); 

  

  // const [showPainFields, setShowPainFields] = useState(false);

  const [textareaValue, setTextareaValue] = useState(""); 
  const [tobaccoInfo,setTobaccoInfo] = useState("");
  const [alcoholInfo, setAlcoholInfo] = useState("");
  const [drugsInfo, setDrugsInfo] = useState("");
  


  const [checkboxValues, setCheckboxValues] = useState({
    BroughtDead: 'No',
    InvestigationTreatment: 'No',
    NonAvailability: 'No',
    NonAvailabilityIcuBed: 'No',
    Toxicatients:  'No',
    DrunkPatients:  'No',
    Unknownatients:  'No',
    CovidCentre:  'No',
    Absconded:  'No',
    DamaNonAffordable:  'No',
    DamaNotWish:  'No',
    DamaInsuranceCashless:  'No',
    
   
  });


  const [EmergencyFormData, setEmergencyFormData] = useState({
    DamaOtherReason: "",
    Date: "",
    Time: "",

    
  });
  
 

  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();



  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `${urllink}EmergencyManagement/get_Emergency_History?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data[0];
          console.log("Fetched surgical history data:", data.AllergiesInfo);
  
          setEmergencyFormData((prev)=>({
            ...prev,
            Date: data?.Date || '',
            Time: data?.Time || '',
            DamaOtherReason: data?.DamaOtherReason || '',
            
          }))
  
          // Set checkbox values based on fetched data
          setCheckboxValues({
            BroughtDead: data?.BroughtDead ,
            InvestigationTreatment: data?.InvestigationTreatment ,
            NonAvailability: data?.NonAvailability ,
            NonAvailabilityIcuBed: data?.NonAvailabilityIcuBed ,
            Toxicatients: data?.Toxicatients ,
            DrunkPatients: data?.DrunkPatients ,
            Unknownatients: data?.Unknownatients ,
            CovidCentre: data?.CovidCentre ,
            Absconded: data?.Absconded ,
            DamaNonAffordable: data?.DamaNonAffordable ,
            DamaNotWish: data?.DamaNotWish ,
            DamaInsuranceCashless: data?.DamaInsuranceCashless ,
           
            // Set other fields similarly
          });
        })
        .catch((error) => {
          console.error("Error fetching surgical history:", error);
        });
    }
  }, [IpNurseQueSelectedRow, urllink, renderFlag]);
  
  
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically

  useEffect(()=>{
      if (EmergencyFormData.Allergies === "Select") {
        setShowTextarea(false);
      } else {
        setShowTextarea(true);
      }
    
  },[EmergencyFormData.Allergies])


//   const handleInputChange = (e) => {
//     const { name, value,checked, type } = e.target;
//     console.log(name, value, type);


 

//     setCheckboxValues(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
  
//     setEmergencyFormData((prev) => ({
//       ...prev,
      
//       [name]: value,
//     }));

   
//   };
  


const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
  
    if (type === "checkbox") {
      // If a checkbox is clicked, uncheck all other checkboxes
      const updatedCheckboxValues = { ...checkboxValues };
      Object.keys(updatedCheckboxValues).forEach((key) => {
        if (key !== name) {
          updatedCheckboxValues[key] = 'No';
        }
      });
  
      // Toggle the value of the clicked checkbox
      updatedCheckboxValues[name] = checked ? 'Yes' : 'No';
  
      setCheckboxValues(updatedCheckboxValues);
    } else {
      setEmergencyFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  
 
  const Post_vital_data = () => {
    const requiredfields = [
      "Date",
      "Time",
      "DamaOtherReason",
    ];


   
    const existing = requiredfields.filter((field) => !EmergencyFormData[field]);

    if (existing.length > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const Allsenddata = {
        ...EmergencyFormData,
        ...checkboxValues,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_History`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          setRenderFlag(!renderFlag);
          setSuccessMessage("Data saved successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    
    <>
  
  
    <div className="new-patient-registration-form">
      <div className="RegisFormcon">
        <div className="RegisForm_1">
        <label htmlFor="Date"> Date:</label>
            <input
                type="date"
                id="Date"
                name="Date"
                value={EmergencyFormData.Date}
                onChange={handleInputChange}
            />

            <label htmlFor="Time">Time:</label>
            <input
                type="time"
                id="Time"
                name="Time"
                value={EmergencyFormData.Time}
                onChange={handleInputChange}
            />
        </div>
      </div>
      <br/>
      
      <br />

     
         


         
         
          <div className="RegisFormcon"><h3>Transfer To & Reason</h3></div>
          <br/>
         
          

         <div className="RegisFormcon">
              <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="BroughtDead"
                                    name="BroughtDead"
                                    value={checkboxValues.BroughtDead === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.BroughtDead === "Yes"}
                                    onChange={handleInputChange}
                                /> Brought Dead
                            </label>
                        </div>
                    </div>
                </div>

                
         </div>
         <br/>
            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="InvestigationTreatment"
                                    name="InvestigationTreatment"
                                    value={checkboxValues.InvestigationTreatment === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.InvestigationTreatment === "Yes"}
                                    onChange={handleInputChange}
                                /> Higher Center For Further Investigation & Treatment
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br/>

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="NonAvailability"
                                    name="NonAvailability"
                                    value={checkboxValues.NonAvailability === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.NonAvailability === "Yes"}
                                    onChange={handleInputChange}
                                /> Non Availability Of Consultant
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

             <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="NonAvailabilityIcuBed"
                                    name="NonAvailabilityIcuBed"
                                    value={checkboxValues.NonAvailabilityIcuBed === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.NonAvailabilityIcuBed === "Yes"}
                                    onChange={handleInputChange}
                                /> Non Availability Of ICU Bed
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/>  

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="Toxicatients"
                                    name="Toxicatients"
                                    value={checkboxValues.Toxicatients === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.Toxicatients === "Yes"}
                                    onChange={handleInputChange}
                                /> Toxic Patients Or Relatives
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/>  


            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="DrunkPatients"
                                    name="DrunkPatients"
                                    value={checkboxValues.DrunkPatients === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.DrunkPatients === "Yes"}
                                    onChange={handleInputChange}
                                /> Drunk Patients
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/>  


            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="Unknownatients"
                                    name="Unknownatients"
                                    value={checkboxValues.Unknownatients === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.Unknownatients === "Yes"}
                                    onChange={handleInputChange}
                                /> Relatives Not Available- Unknown Patients
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="CovidCentre"
                                    name="CovidCentre"
                                    value={checkboxValues.CovidCentre === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.CovidCentre === "Yes"}
                                    onChange={handleInputChange}
                                /> Transfered to COVID centre
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="Absconded"
                                    name="Absconded"
                                    value={checkboxValues.Absconded === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.Absconded === "Yes"}
                                    onChange={handleInputChange}
                                /> Absconded
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="DamaNonAffordable"
                                    name="DamaNonAffordable"
                                    value={checkboxValues.DamaNonAffordable === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.DamaNonAffordable === "Yes"}
                                    onChange={handleInputChange}
                                /> DAMA -Non- Affordable
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="DamaNotWish"
                                    name="DamaNotWish"
                                    value={checkboxValues.DamaNotWish === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.DamaNotWish === "Yes"}
                                    onChange={handleInputChange}
                                /> DAMA -Relatives Not Wish
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

            <div className="RegisFormcon">
                <div className="">

                    <div className="">
                        <div className="wdvw_yd">
                            <label>
                                <input
                                    type="checkbox"
                                    id="DamaInsuranceCashless"
                                    name="DamaInsuranceCashless"
                                    value={checkboxValues.DamaInsuranceCashless === "Yes"?'No':'Yes'}
                                    checked={checkboxValues.DamaInsuranceCashless === "Yes"}
                                    onChange={handleInputChange}
                                /> DAMA -Insurance or Cashless
                            </label>
                        </div>
                    </div>
                </div>
            </div><br/> 

          
            <div>
              <div className="RegisFormcon">
                <h4>DAMA - Other Reasons (with remarks)</h4>
                
              </div>      
              <br/>  
              <div className="RegisFormcon">
                <div className="">
                
              <br />
                <textarea
                    className="edwuedy47y"
                    name="FamilyHistory"
                    value={EmergencyFormData.DamaOtherReason}
                    onChange={handleInputChange}
                  />
                </div>

              </div>

            </div>   
          <br/>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={Post_vital_data}>Add</button>
      </div>
  
      
    </div>
  </>
  
  
    );
    
}
