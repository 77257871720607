import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "../PurchaseRaiseMaster/PurchaseRaiseMaster.css";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function IndentMovementmaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [formDataPurchaseInvoice, setFormDataPurchaseInvoice] = useState({
    ProductCategory: "",
    Department: "",
    IssuedTo: "",
    Reason: "",
    IssuedDate: format(new Date(), "yyyy-MM-dd"),
    // Location:userRecord?.location,
    Location: userRecord?.location,
    CreatedBy: userRecord?.username,
  });
  const [formDataPurchase, setFormDataPurchase] = useState({
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    IssuedQuantity: "",
  });

  const [IndexEdit, setIndexEdit] = useState(null);

  const [ItemCodedetailes, setItemCodedetailes] = useState([]);
  const [Departmentdetials, setDepartmentdetials] = useState([]);
  const [employeeDetials, setemployeeDetials] = useState([]);

  useEffect(() => {
    console.log(
      "formDataPurchaseInvoice.ProductCategory",
      formDataPurchaseInvoice.ProductCategory
    );

    if (formDataPurchaseInvoice.ProductCategory) {
      axios
        .get(
          `http://127.0.0.1:8000/SupplierMaster/get_data_4_indent_movement?ProductType=${formDataPurchaseInvoice.ProductCategory}&location=${formDataPurchaseInvoice.Location}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setItemCodedetailes(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    formDataPurchaseInvoice.ProductCategory,
    formDataPurchaseInvoice.Location,
  ]);

  useEffect(() => {
    axios
      .get(`http://127.0.0.1:8000/usercontrol/getRoleData`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setDepartmentdetials(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formDataPurchaseInvoice.ProductCategory]);
  useEffect(() => {
    const data = Departmentdetials.find((p) => {
      return p.Department === formDataPurchaseInvoice.Department;
    });
    console.log(data);
    if (data) {
      axios
        .get(
          `http://127.0.0.1:8000/usercontrol/getRoleData_by_location?Department=${data.Department}&location=${userRecord?.location}&role_id=${data.role_id}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setemployeeDetials(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [formDataPurchaseInvoice.Department]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "ItemCode") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemCode === value;
      });
      if (Set_datas) {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemName: Set_datas.ItemName,
          GenericName: Set_datas.GenericName ? Set_datas.GenericNamFe : "Null",
          Strength: Set_datas.Strength ? Set_datas.Strength : "Null",
          UOM: Set_datas.UOM ? Set_datas.UOM : "Null",
          IssuedQuantity: "",
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemName: "",
          GenericName: "",
          Strength: "",
          UOM: "",
          IssuedQuantity: "",
        }));
      }
    } else if (name === "ItemName") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemName === value;
      });

      if (Set_datas) {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemCode: Set_datas.ItemCode,
          GenericName: Set_datas.GenericName ? Set_datas.GenericName : "Null",
          Strength: Set_datas.Strength ? Set_datas.Strength : "Null",
          UOM: Set_datas.UOM ? Set_datas.UOM : "Null",
          IssuedQuantity: "",
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemCode: "",
          GenericName: "",
          Strength: "",
          UOM: "",
          IssuedQuantity: "",
        }));
      }
    } else {
      if (name === "IssuedQuantity") {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    if (name === "ProductCategory") {
      setFormDataPurchaseInvoice((prevData) => ({
        ...prevData,
        [name]: value,
        Reason: "",
      }));

      handleCancel();
      setCartItems([]);
    } else {
      setFormDataPurchaseInvoice((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddToCart = () => {
    const existingItem = cartItems.find(
      (item) => item.ItemCode === formDataPurchase.ItemCode
    );

    if (existingItem) {
      alert(
        `Item with code ${formDataPurchase.ItemCode} already exists in the cart.`
      );
    } else {
      let updatedata;
      let requiredFields;
      if (
        formDataPurchaseInvoice.ProductCategory === "MedicalConsumable" ||
        formDataPurchaseInvoice.ProductCategory === "MedicalNonConsumable"
      ) {
        updatedata = {
          ItemCode: formDataPurchase.ItemCode,
          ItemName: formDataPurchase.ItemName,
          GenericName: formDataPurchase.GenericName,
          Strength: formDataPurchase.Strength,
          UOM: formDataPurchase.UOM,
          IssuedQuantity: formDataPurchase.IssuedQuantity,
        };
        requiredFields = [
          "ItemCode",
          "ItemName",
          "GenericName",
          "Strength",
          "UOM",
          "IssuedQuantity",
        ];
      } else if (
        formDataPurchaseInvoice.ProductCategory === "NonMedicalConsumable" ||
        formDataPurchaseInvoice.ProductCategory === "NonMedicalNonConsumable" ||
        formDataPurchaseInvoice.ProductCategory === "Surgical"
      ) {
        updatedata = {
          ItemCode: formDataPurchase.ItemCode,
          ItemName: formDataPurchase.ItemName,
          GenericName: formDataPurchase.GenericName,
          IssuedQuantity: formDataPurchase.IssuedQuantity,
        };
        requiredFields = [
          "ItemCode",
          "ItemName",
          "GenericName",
          "IssuedQuantity",
        ];
      } else {
        updatedata = {
          ItemCode: formDataPurchase.ItemCode,
          ItemName: formDataPurchase.ItemName,
          GenericName: "Null",
          Strength: "Null",
          UOM: "Null",
          IssuedQuantity: formDataPurchase.IssuedQuantity,
        };
        requiredFields = ["ItemCode", "ItemName", "IssuedQuantity"];
      }
      const missingFields = requiredFields.filter(
        (field) => !formDataPurchase[field]
      );
      if (missingFields.length === 0) {
        console.log(updatedata);
        setCartItems((prev) => [...prev, updatedata]);
        handleCancel();
      } else {
        alert(
          `Please fill out all required fields: ${missingFields.join(", ")}`
        );
      }
    }
  };

  const handleDeleteCartItem = (itemCode) => {
    setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
  };
  const handleEditCartItem = (itemCode) => {
    const selectedItem = cartItems.find((item) => item.ItemCode === itemCode);
    setIndexEdit(selectedItem);
    console.log(selectedItem.ItemCode);
    if (selectedItem) {
      // Set the form data with the selected item's data
      setFormDataPurchase({
        ItemCode: selectedItem.ItemCode,
        ItemName: selectedItem.ItemName,
        GenericName: selectedItem.GenericName,
        Strength: selectedItem.Strength,
        UOM: selectedItem.UOM,
        IssuedQuantity: selectedItem.IssuedQuantity,
      });

      // Remove the selected item from the cart
      //   setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
    }
  };

  const handleUpdateCartItem = () => {
    const updatedItem = {
      ItemCode: formDataPurchase.ItemCode,
      ItemName: formDataPurchase.ItemName,
      GenericName: formDataPurchase.GenericName,
      Strength: formDataPurchase.Strength,
      UOM: formDataPurchase.UOM,
      IssuedQuantity: formDataPurchase.IssuedQuantity,
    };

    // Check if the updated item has all required fields
    let requiredFields;
    if (
      formDataPurchaseInvoice.ProductCategory == "MedicalConsumable" ||
      formDataPurchaseInvoice.ProductCategory == "MedicalNonConsumable"
    ) {
      requiredFields = [
        "ItemCode",
        "ItemName",
        "GenericName",
        "Strength",
        "UOM",
        "IssuedQuantity",
      ];
    } else if (
      formDataPurchaseInvoice.ProductCategory == "NonMedicalConsumable" ||
      formDataPurchaseInvoice.ProductCategory == "NonMedicalNonConsumable" ||
      formDataPurchaseInvoice.ProductCategory == "Surgical"
    ) {
      requiredFields = [
        "ItemCode",
        "ItemName",
        "GenericName",
        "IssuedQuantity",
      ];
    }

    const missingFields = requiredFields.filter((field) => !updatedItem[field]);

    if (missingFields.length === 0) {
      // Find the index of the item in the cart with the matching item code
      const indexOfItemToUpdate = cartItems.findIndex(
        (item) => item.ItemCode === formDataPurchase.ItemCode
      );

      // Update the cart with the modified item
      setCartItems((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = updatedItem;
        return updatedCart;
      });

      handleCancel();
      setIndexEdit(null);
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleSubmit = () => {
    // setIsPrintButtonVisible(false);
    // setTimeout(async () => {
    //     window.print();
    //     setIsPrintButtonVisible(true);
    // }, 1000);
    // if (formDataPurchaseInvoice.ProductCategory === 'Medical' || formDataPurchaseInvoice.ProductCategory === 'Non_Medical') {
    const updatedItem = {
      ProductCategory: formDataPurchaseInvoice.ProductCategory,
      Department: formDataPurchaseInvoice.Department,
      IssuedTo: formDataPurchaseInvoice.IssuedTo,
      Reason: formDataPurchaseInvoice.Reason,
      IssuedDate: formDataPurchaseInvoice.IssuedDate,
      CreatedBy: formDataPurchaseInvoice.CreatedBy,
      Location: formDataPurchaseInvoice.Location,
      cartItems: cartItems, /// array
    };

    // };
    console.log(updatedItem);

    const requiredFields = [
      "ProductCategory",
      "Department",
      "IssuedTo",
      "IssuedDate",
      "Reason",
    ];
    const missingFields = requiredFields.filter(
      (field) => !formDataPurchaseInvoice[field]
    );

    if (missingFields.length === 0) {
      axios
        .post(
          "http://127.0.0.1:8000/IndentRaise/insert_All_indent_stock_movement",
          updatedItem
        )
        .then((response) => {
          console.log(response);
          clearFormInputs();
          navigate("/Home/IndentMovementlist");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }

    // console.table(cartItems)
  };

  const handleCancel = () => {
    setFormDataPurchase({
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      IssuedQuantity: "",
    });
  };

  const clearFormInputs = () => {
    setFormDataPurchaseInvoice((prev) => ({
      ...prev,
      ProductCategory: "MedicalConsumable",
      IssuedTo: "",
      Reason: "",
    }));

    setFormDataPurchase({
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      IssuedQuantity: "",
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4 className="Stoct_Product_master_head_h3">
            <ShoppingCartIcon /> Indent Movement
          </h4>
        </div>

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Product Category <span>:</span>
            </label>
            <select
              name="ProductCategory"
              value={formDataPurchaseInvoice.ProductCategory}
              onChange={handleInputChange1}
            >
              <option value="">Select</option>
              <option value="MedicalConsumable">Medical Consumable</option>
              <option value="MedicalNonConsumable">
                Medical Non-Consumable
              </option>
              <option value="NonMedicalConsumable">
                Non MedicalConsumable
              </option>
              <option value="NonMedicalNonConsumable">
                Non MedicalNon-Consumable
              </option>
              <option value="Surgical">Surgical</option>
              <option value="Assets">Assets</option>
              <option value="Stationary">Stationary</option>
              <option value="Non_Stationary">Non Stationary</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Department <span>:</span>
            </label>
            <input
              type="text"
              name="Department"
              list="Department_list"
              value={formDataPurchaseInvoice.Department}
              onChange={handleInputChange1}
              required
              autoComplete="off"
            />
            <datalist id="Department_list">
              {Departmentdetials.map((item, index) => (
                <option key={index} value={item.Department}></option>
              ))}
            </datalist>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="code">
              Issued To <span>:</span>
            </label>
            <input
              type="text"
              list="Department_IssuedTo_list"
              name="IssuedTo"
              value={formDataPurchaseInvoice.IssuedTo}
              onChange={handleInputChange1}
              required
              autoComplete="off"
            />
            <datalist id="Department_IssuedTo_list">
              {employeeDetials.map((item, index) => (
                <option
                  key={index}
                  value={item.EmployeeName}
                >{`${item.EmployeeID} | ${item.EmployeeName}`}</option>
              ))}
            </datalist>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="itemName">
              Issued Date <span>:</span>
            </label>
            <input
              type="date"
              name="IssuedDate"
              value={formDataPurchaseInvoice.IssuedDate}
              onChange={handleInputChange1}
              readOnly
              autoComplete="off"
            />
          </div>

          <div className="Stoct_Product_master_form_row">
            <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Reason <span>:</span>
              </label>
              <input
                type="text"
                name="Reason"
                value={formDataPurchaseInvoice.Reason}
                onChange={handleInputChange1}
                required
              />
            </div>
          </div>
        </div>

        <div className="Add_items_Purchase_Master">
          <span>Add Items</span>
        </div>

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Item Code<span>:</span>
            </label>
            <input
              type="text"
              name="ItemCode"
              list="ItemCode_list"
              value={formDataPurchase.ItemCode}
              onChange={handleInputChange}
              required
              autoComplete="off"
            />
            <datalist id="ItemCode_list">
              {ItemCodedetailes.map((item, index) => (
                <option key={index} value={item.ItemCode}></option>
              ))}
            </datalist>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Item Name <span>:</span>
            </label>
            <input
              type="text"
              name="ItemName"
              list="ItemCode_name"
              value={formDataPurchase.ItemName}
              onChange={handleInputChange}
              required
            />
            <datalist id="ItemCode_name">
              {ItemCodedetailes.map((item, index) => (
                <option key={index} value={item.ItemName}></option>
              ))}
            </datalist>
          </div>

          {(formDataPurchaseInvoice.ProductCategory === "MedicalConsumable" ||
            formDataPurchaseInvoice.ProductCategory ===
              "MedicalNonConsumable" ||
            formDataPurchaseInvoice.ProductCategory ===
              "NonMedicalConsumable" ||
            formDataPurchaseInvoice.ProductCategory ===
              "NonMedicalNonConsumable" ||
            formDataPurchaseInvoice.ProductCategory === "Surgical") && (
              <>
            <div className="RegisForm_1">
              <label htmlFor="code">
                Generic Name <span>:</span>
              </label>
              <input
                type="text"
                name="GenericName"
                value={formDataPurchase.GenericName}
                onChange={handleInputChange}
                required
                readOnly
              />
            </div>
            </>
          )}

          {(formDataPurchaseInvoice.ProductCategory ===
            "NonMedicalConsumable" ||
            formDataPurchaseInvoice.ProductCategory ===
              "NonMedicalNonConsumable" ||
            formDataPurchaseInvoice.ProductCategory === "Surgical") && (
           <> 
           <div className="RegisForm_1">
              <label htmlFor="strength">
                Issued Quantity <span>:</span>
              </label>
              <input
                type="text"
                name="IssuedQuantity"
                value={formDataPurchase.IssuedQuantity}
                onChange={handleInputChange}
                // readOnly
              />
            </div>
            </>
          )}

          {(formDataPurchaseInvoice.ProductCategory === "MedicalConsumable" ||
            formDataPurchaseInvoice.ProductCategory ===
              "MedicalNonConsumable") && (
                <>
            <div className="RegisForm_1">
              <label htmlFor="itemName">
                Strength <span>:</span>
              </label>
              <input
                type="text"
                name="Strength"
                value={formDataPurchase.Strength}
                onChange={handleInputChange}
                readOnly
              />
            </div>
            </>
          )}

          {formDataPurchaseInvoice.ProductCategory !== "MedicalConsumable" &&
            formDataPurchaseInvoice.ProductCategory !==
              "MedicalNonConsumable" &&
            formDataPurchaseInvoice.ProductCategory !==
              "NonMedicalConsumable" &&
            formDataPurchaseInvoice.ProductCategory !==
              "NonMedicalNonConsumable" &&
            formDataPurchaseInvoice.ProductCategory !== "Surgical" && (
             <> <div className="RegisForm_1">
                <label htmlFor="strength">
                  Issued Quantity <span>:</span>
                </label>
                <input
                  type="text"
                  name="IssuedQuantity"
                  value={formDataPurchase.IssuedQuantity}
                  onChange={handleInputChange}
                  // readOnly
                />
              </div>
              </>
            )}
        </div>

        {(formDataPurchaseInvoice.ProductCategory === "MedicalConsumable" ||
          formDataPurchaseInvoice.ProductCategory ===
            "MedicalNonConsumable") && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="generic">
                UOM <span>:</span>
              </label>
              <input
                type="text"
                name="UOM"
                value={formDataPurchase.UOM}
                onChange={handleInputChange}
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="uom">
                Issued Quantity <span>:</span>
              </label>
              <input
                type="number"
                name="IssuedQuantity"
                value={formDataPurchase.IssuedQuantity}
                onChange={handleInputChange}
              />
            </div>
          </>
        )}

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleCancel}>
            Clear
          </button>
          {!IndexEdit ? (
            <button className="RegisterForm_1_btns" onClick={handleAddToCart}>
              Add
            </button>
          ) : (
            <button
              className="RegisterForm_1_btns"
              onClick={handleUpdateCartItem}
            >
              Update
            </button>
          )}
        </div>

        {cartItems.length !== 0 && (
          <div className="Stoct_Product_master_form_table_container">
            <div className="Stoct_Product_master_form_table_container_11">
              {(formDataPurchaseInvoice.ProductCategory ===
                "MedicalConsumable" ||
                formDataPurchaseInvoice.ProductCategory ===
                  "MedicalNonConsumable") && (
                <div className="Stoct_Product_master_form_table_container_11">
                  <table>
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Generic Name</th>
                        <th>Strength</th>
                        <th>UOM</th>
                        <th>Issued Quantity</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ItemCode}</td>
                          <td>{item.ItemName}</td>
                          <td>{item.GenericName}</td>
                          <td>{item.Strength}</td>
                          <td>{item.UOM}</td>
                          <td>{item.IssuedQuantity}</td>
                          <td>
                            <button
                              onClick={() => handleEditCartItem(item.ItemCode)}
                            >
                              edit
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteCartItem(item.ItemCode)
                              }
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {(formDataPurchaseInvoice.ProductCategory ===
                "NonMedicalConsumable" ||
                formDataPurchaseInvoice.ProductCategory ===
                  "NonMedicalNonConsumable" ||
                formDataPurchaseInvoice.ProductCategory === "Surgical") && (
                <div className="Stoct_Product_master_form_table_container_11">
                  <table>
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Generic Name</th>
                        <th>Issued Quantity</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ItemCode}</td>
                          <td>{item.ItemName}</td>
                          <td>{item.GenericName}</td>
                          <td>{item.IssuedQuantity}</td>
                          <td>
                            <button
                              onClick={() => handleEditCartItem(item.ItemCode)}
                            >
                              edit
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteCartItem(item.ItemCode)
                              }
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {formDataPurchaseInvoice.ProductCategory !==
                "MedicalConsumable" &&
                formDataPurchaseInvoice.ProductCategory !==
                  "MedicalNonConsumable" &&
                formDataPurchaseInvoice.ProductCategory !==
                  "NonMedicalConsumable" &&
                formDataPurchaseInvoice.ProductCategory !==
                  "NonMedicalNonConsumable" &&
                formDataPurchaseInvoice.ProductCategory !== "Surgical" && (
                  <div className="Stoct_Product_master_form_table_container_11">
                    <table>
                      <thead>
                        <tr>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Issued Quantity</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.ItemCode}</td>
                            <td>{item.ItemName}</td>
                            <td>{item.IssuedQuantity}</td>
                            <td>
                              <button
                                onClick={() =>
                                  handleEditCartItem(item.ItemCode)
                                }
                              >
                                edit
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteCartItem(item.ItemCode)
                                }
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        )}
        {cartItems.length !== 0 && (
          <div className="button_buton_class">
            <button className="btn-add" onClick={handleSubmit}>
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default IndentMovementmaster;
