import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmerHospitalInformation() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    Date: "",
    Location: userRecord.location,
    Patient_Name: IpNurseQueSelectedRow.PatientName,
    bookingid: IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);

      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);

              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Date', ConcernformData.Date);
              
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);

              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Hospital_Information`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;

  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };


  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >

          <div className="" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>चिरायु हॉस्पिटल, रत्नागिरी</h1>
          </div>

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h3>● रुग्णाच्या नातेवाईकांसाठी महत्वाच्या सूचना ●</h3>
          </div>
          
          
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

          <div className="RegisForm_1_consent_consent">
            <label htmlFor="Date">

              दिनांक<span>:</span>
            </label>
            <input
              type="text"
              id="Date"
              name="Date"
              value={ConcernformData.Date}
              onChange={handleChange}
              required
            />

          </div>

        </div>

       
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
              आम्ही चिरायु हॉस्पिटलचे सर्व डॉक्टर व स्टाफ आपल्या पेशंटला बरे करण्यासाठी पुर्णपणे प्रयत्न करीत असतो.आपले नातेवाईक व इतर
              लोक जे पेशंटला भेटण्यासाठी येतात त्यांचे सहकार्य आमचे काम सुरळीतपणे चालू राहणेसाठी अत्यंत जरुरीचे आहे, यासाठी ही नम्र विनंती.<br/>
           <br/>
            १) कृपया आपल्याला पेशंटसंदर्भात माहिती हवी असल्यास एकाच जबाबदार नातेवाईकाने वैद्यकीय तज्ञांना राऊंड़नंतर त्यांच्या वेळेनुसार केबीनमध्ये भेटावे.<br/>
           <br/>
            २) अतिदक्षता विभागात असलेल्या पेशंटना ठरवून दिलेल्या वेळे शिवाय भेटण्याचा कृपया आग्रह करु नये. त्यांना पूर्णपणे शारिरीक व मानसिक विश्रांती गरजेची असते.
             लहान मुलांना वॉर्डमध्ये नेऊ नये त्यांना इन्फेक्शन होण्याची शक्यता असते.<br/>
             <br/>
             ३)ज्या व्यक्तीकडे हॉस्पिटलने दिलेला नातेवाईकांचा पास असेल त्यांनाच थांबण्यास परवानगी दिली जाईल.<br/>
             <br/>

              ४) वॉर्डमध्ये व स्पेशलरुम मध्ये अॅडमिट असलेल्या पेशंटजवळ एका नातेवाईकाला पेशंटची वैयक्तीक काळजी घेण्यासाठी थांबणे बंधनकारक आहे.
               स्त्री विभागातील पेशंट बरोबर फक्त एका महिला नातेवाईकाला राहण्यास परवानगी आहे.<br/>
               <br/>
               ५) बिल व इतर चार्जेस संबधित माहितीसाठी कॅश काऊंटर नं. ९ वर भेटावे व सांगितल्याप्रमाणे 
               डिपॉझीट आणि इतर चार्जेस नियमीत भरुन आम्हाला सहकार्य करावे.<br/>
               <br/>
               ६)हॉस्पिटल मधील रुग्णोपयोगी यंत्रसामुग्रीना कृपया हात लावू नका वा हाताळण्याचा प्रयत्न करु नका.<br/>
               <br/>
               ७)ज्या पेशंटना सक्तीची विश्रांती आहे अशा पेशंटचेच फक्त स्पंजीग केले जाईल.<br/>
               <br/>
               ८)कृपया पेशंटच्या अंगावर कोणत्याही प्रकारचे दागिने / मौल्यवान वस्तू (मंगळसुत्र, रोख रक्कम, मोबाईल ) तसेच इतर वस्तू ठेऊ नयेत.
                तसेच नातेवाईकांनी आपल्या मौल्यवान वस्तु स्वतःच्या जबाबदारीवर ठेवाव्यात, अशा वस्तू गहाळ झाल्यास हॉस्पिटल व्यवस्थापन जबाबदार 
                राहणार नाही.<br/>
               <br/>
               ९)कृपया कोणत्याही स्टाफला वैयक्तिक बक्षीस देऊ नये.<br/>
               <br/>
               १०)रविवारी बिलिंग सेक्शन बंद असल्यामुळे रविवारी होणाऱ्या डिसचार्ज पेशंटच्या बीलापोटी अॅडव्हान्स घेतला जाईल, अॅडव्हान्सची पावती देऊन
                पेशंटला डिसचार्ज दिला जाईल. फायनल बिल व त्याची पावती सोमवार ते शनिवारी ११ ते ५ मध्ये येऊन घ्यावयाची आहे.<br/>
                <br/>
                ११) आपल्या पेशंटला डिस्चार्ज मिळाल्यानंतर औषधे लिहून दिली जातात व त्यांची माहिती दिली जाते. आपल्याला शंका असल्यास वॉर्डमधील मेडिकल
                 ऑफिसर यांना भेटून शंकानिरसन करुन घेणे, तसेच आपल्या फाईल व सोबत असणारे पॅथॉलॉजीकल रिपोर्टस, एक्स-रेज, सोनोग्राफी रिपोर्टस, 
                 सी.टी. स्कॅन, एम.आर.आय. स्कॅन रिपोर्टस इ. डिस्चार्ज कार्ड सहित आपणास मिळतील याची खात्री करावी.<br/>
                 <br/>
                 १२) हॉस्पिटल चे पार्किंग पुढील बाजूस आहे. तेथे गार्डच्या परवानगीने इतर वाहनांना अडथळा होणार नाही अशा पद्धतीने आपले वाहन योग्य
                  जागी स्वत:च्या जबाबदारीवर पार्क करावे. डॉक्टर्स पार्किंग मध्ये नातेवाईकांनी वाहने पार्क करु नयेत.<br/>
                  <br/>
                  १३) कोणत्याही प्रकारची तक्रार असल्यास कृपया सकाळी ११ ते सायं. ६ या वेळेत अॅडमिनिस्ट्रीव्ह डिपार्टमेंट मध्ये भेटावे. स्टाफशी हुज्जत घालू नये.<br/>
                  <br/>
                  १४) नातेवाईकांनी दिला जाणारा गेटपास हरविल्यास रु.१००/- दंड आकारण्यात येईल.<br/>
                  <br/>
                  १५) पेशंट अॅडमिट झाल्यानंतर पेशंट डिसचार्ज होण्याआधी पेशंटचे आधार कार्ड रिसेप्शन काऊंटरवर जमा करावे.<br/>
                  <br/>






            </p>
            

          </div>


        </div>

        <br />
        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>जबाबदार नातेवाईकाची सही</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


          </div>


        

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmerHospitalInformation;


