import { React, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './PatientVisit.css';

const PatientVisit = () => {
    const [firstVisit,setFirstVisit]=useState({
        date:'',
        visit:'',
        eligibility:'',
        clinicalExam:'',
        anemia:'',
        obstriicExam:'',
        gynecExam:'',
        Bp:'',
        weightheight:'',
        sexuallydiseases:'',
        urinetest:'',
        bloodrhesus:'',
        tetanustoxid:'',
        ironfolic:'',
        emergencies:'',
        antenatal:''
    }) 

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleFirstVisitChange = (event) => {
        const { name, value } = event.target;
        setFirstVisit(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmitFirstVisit = () => {
        console.log('First Visit Data:', firstVisit);
        // Implement logic to send data to backend or perform other actions
    };
   console.log(firstVisit);
    return (
        <div>
            <Accordion expanded={expanded !== 'panel2' && expanded !== 'panel3' && expanded !== 'panel4'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
                    <Typography sx={{ width: '45%', flexShrink: 0 }} id="panel1bh-header">
                        FIRST VISIT
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="absarver">
                            <div className="abserve">
                                <div className="note">

                                    <p>Note : For all Women at first contact with clinics ,regardiess of gestational age.
                                        If first visit later then recommended, cary out all activities up to the  time</p>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="date">Date</label>
                                        <span>:</span>
                                        <input 
                                        type="date" 
                                        name="date" 
                                        id="date" 
                                        className='qus-input'
                                        value={firstVisit.date}
                                        onChange={handleFirstVisitChange} 
                                        placeholder='Enter date' />
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="date">Visit</label>
                                        <span>:</span>
                                        <input 
                                        type="text" 
                                        placeholder='Enter visit' 
                                        name="visit" id="" 
                                        className='qus-input' 
                                        value={firstVisit.visit}
                                        onChange={handleFirstVisitChange} 
                                        />
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="eligibility">Classifying Form indicates eligibility for basic component </label>
                                        <span>:</span>
                                        <textarea 
                                        name="eligibility" 
                                        id="eligibility" 
                                        cols="30"
                                         rows="10"
                                         onChange={handleFirstVisitChange} 
                                         value={firstVisit.eligibility}
                                         ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Clinical">Clinical examination</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Clinical" 
                                        id="Clinical" 
                                        cols="30" 
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        value={firstVisit.clinicalExam}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Anemia">Clinically severe Anemia :Haemoglobin test</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Anemia" 
                                        id="Anemia" 
                                        cols="30" 
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        value={firstVisit.anemia}
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Obstetric">Obstetric examination : Gestational -age estimation ,Uterine height</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Obstetric" 
                                        id="Obstetric" 
                                        cols="30" 
                                        rows="10"
                                        value={firstVisit.obstriicExam}
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Gynaecological">Gynaecological examination (can be postponed untill second visit)</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Gynaecological" 
                                        id="Gynaecological" 
                                        cols="30" 
                                        value={firstVisit.gynecExam}
                                        rows="10"
                                        onChange={handleFirstVisitChange}></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="bp">Blood pressure</label>
                                        <span>:</span>
                                        <textarea 
                                        name="bp" 
                                        id="bp" 
                                        value={firstVisit.bp}
                                        cols="30" 
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Weight">Maternal Weight / Height</label>
                                        <span>:</span>
                                        <textarea 
                                        value={firstVisit.weightheight}
                                        name="Weight" 
                                        id="Weight" 
                                        cols="30" 
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="syphillis">Rapid syphillis test,detection of ymptomatic sexually transmitted diseases - treatment</label>
                                        <span>:</span>
                                        <textarea 
                                        name="syphillis" 
                                        id="syphillis" 
                                        cols="30" 
                                        rows="10"
                                        value={firstVisit.sexuallydiseases}
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Urine">Urine test(multiple dipstick)</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Urine" 
                                        id="Urine"
                                         cols="30" 
                                         rows="10"
                                         value={firstVisit.urinetest}
                                         onChange={handleFirstVisitChange}
                                         ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="rhesus">Blood type and rhesus status</label>
                                        <span>:</span>
                                        <textarea 
                                        name="rhesus" 
                                        id="rhesus" 
                                        cols="30" 
                                        rows="10"
                                        value={firstVisit.bloodrhesus}
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">

                                    <div className="query">
                                        <label className='input_lable' htmlFor="Tetanus">Tetanus toxoid</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Tetanus" 
                                        id="Tetanus" 
                                        cols="30" 
                                        value={firstVisit.tetanustoxid}
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="ron/folic">Prowde iron/folic acid supplementation</label>
                                        <span>:</span>
                                        <textarea 
                                        name="ron/folic" 
                                        id="ron/folic" 
                                        cols="30" 
                                        value={firstVisit.ironfolic}
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="emergencies">Precommendation for emergencies / hotline for emergencies</label>
                                        <span>:</span>
                                        <textarea 
                                        name="emergencies" 
                                        id="emergencies" 
                                        cols="30" 
                                        value={firstVisit.emergencies}
                                        rows="10"
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="antenatal">Complete antenatal card</label>
                                        <span>:</span>
                                        <textarea 
                                        name="antenatal" 
                                        id="antenatal" 
                                        cols="30" 
                                        rows="10"
                                        value={firstVisit.antenatal}
                                        onChange={handleFirstVisitChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="submition">
                                    <button onClick={handleSubmitFirstVisit} className='RegisterForm_1_btns frst-btn-vst'>Submit</button>
                                </div>

                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content">
                    <Typography sx={{ width: '45%', flexShrink: 0 }} id="panel1bh-header">
                        SECOND VISIT
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="absarver">
                            <div className="abserve">

                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="date">Date</label>
                                        <span>:</span>
                                        <input 
                                        type="date" 
                                        name="Date" 
                                        id="Date" 
                                        className='qus-input'
                                        
                                        />
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Visit">Visit</label>
                                        <span>:</span>
                                        <input 
                                        type="text"
                                         placeholder='Enter visit' 
                                         
                                         name="Visit" 
                                         id="Visit" 
                                         className='qus-input' 
                                         />
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="anaemia">Clinical examination for anaemia </label>
                                        <span>:</span>
                                        <textarea 
                                        name="anaemia" 
                                        id="anaemia" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Obstetric">Obstetric examination: gestational-age estimation, uterine height, fetal heart rate </label>
                                        <span>:</span>
                                        <textarea 
                                        
                                        name="Obstetric" 
                                        id="Obstetric" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="bp">Blood pressure</label>
                                        <span>:</span>
                                        <textarea 
                                        name="bp" 
                                        id="bp" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="weight">Maternal weight (only women with low weight at first visit)</label>
                                        <span>:</span>
                                        <textarea 
                                        name="weight" 
                                        id="weight" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Urine">Urine test for protein (only nulliparous/women with previous eclampsia)</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Urine" 
                                        id="Urine" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="iron/folic">Provide iron/folic acid supplementation</label>
                                        <span>:</span>
                                        <textarea 
                                        name="iron/folic" 
                                        id="iron/folic" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="emergencies">Recommendation for emergencies</label>
                                        <span>:</span>
                                        <textarea 
                                        name="emergencies" 
                                        id="emergencies" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="antenatal">Complete antenatal card</label>
                                        <span>:</span>
                                        <textarea 
                                        name="antenatal" 
                                        id="antenatal" 
                                        cols="30" 
                                        rows="10"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="submition">
                                    <button className='RegisterForm_1_btns frst-btn-vst'>Submit</button>
                                </div>


                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content">
                    <Typography sx={{ width: '45%', flexShrink: 0 }} id="panel1bh-header">
                        THIRD VISIT
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="absarver">
                            <div className="abserve">

                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="date">Date</label>
                                        <span>:</span>
                                        <input 
                                        type="date" 
                                        name="date" 
                                        id="date" 
                                        className='qus-input' 
                                        />
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Visit">Visit</label>
                                        <span>:</span>
                                        <input 
                                        
                                        type="text" 
                                        placeholder='Enter visit' 
                                        name="Visit" 
                                        id="Visit" 
                                        className='qus-input' />
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Haemoglobin">Haemoglobin test </label>
                                        <span>:</span>
                                        <textarea 
                                        name="Haemoglobin" 
                                        id="Haemoglobin" 
                                        cols="30" 
                                        rows="10"
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Tetanus">Tetanus toxoid (second dose) </label>
                                        <span>:</span>
                                        <textarea 
                                        name="Tetanus" 
                                        id="Tetanus" 
                                        cols="30" 
                                        rows="10"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Instructions">Instructions for delivery</label>
                                        <span>:</span>
                                        <textarea 
                                        name="Instructions" 
                                        id="Instructions" 
                                        cols="30" 
                                        rows="10"
                                        
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="lactation">Recommendations for lactation/contraception</label>
                                        <span>:</span>
                                        <textarea 
                                        name="lactation" 
                                        id="lactation" 
                                        cols="30" 
                                        rows="10"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="submition">
                                    <button className='RegisterForm_1_btns frst-btn-vst'>Submit</button>
                                </div>



                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content">
                    <Typography sx={{ width: '45%', flexShrink: 0 }} id="panel1bh-header">
                        FOURTH VISIT
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="absarver">
                            <div className="abserve">

                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="date">Date</label>
                                        <span>:</span>
                                        <input 
                                        type="date" 
                                        name="date" 
                                        id="date" 
                                        className='qus-input'
                                         />
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Visit">Visit</label>
                                        <span>:</span>
                                        <input 
                                        type="text" 
                                        placeholder='Enter visit' 
                                        name="Visit" 
                                        id="Visit" 
                                        className='qus-input' />
                                    </div>
                                </div>
                                <div className="split-screen">
                                    <div className="query">
                                        <label className='input_lable' htmlFor="externalversion">Detection Of breech presentation and referral for external version </label>
                                        <span>:</span>
                                        <textarea 
                                        name="externalversion" 
                                        id="externalversion" 
                                        cols="30" 
                                        rows="10"
                                        ></textarea>
                                    </div>
                                    <div className="query">
                                        <label className='input_lable' htmlFor="Complete">Complete ANC card, recommend it be brought to hospital </label>
                                        <span>:</span>
                                        <textarea name="" id="Complete" cols="30" rows="10"></textarea>
                                    </div>
                                </div>




                                <div className="submition">
                                    <button className='RegisterForm_1_btns frst-btn-vst'>Submit</button>
                                </div>

                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>


        </div>
    );
}

export default PatientVisit;


