import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IcuMedicalRecords = () => {
  const [MedicalrecordData, setMedicalrecordData] = useState([]);
  const [page, setPage] = useState(0);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [modalcontent, setmodalcontent] = useState(null);
  const pageSize = 10;
  const showdown = MedicalrecordData.length;
  const totalPages = Math.ceil(MedicalrecordData.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };
  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/ipregistration/get_pdfcontent?BookingId=${IpNurseQueSelectedRow?.Booking_Id}`,{
        headers: {
          'Content-Type': 'application/pdf'
        }
      })
      .then((response) => {
        setMedicalrecordData(
          response.data.map((p, index) => ({
            ...p,
            id: index + 1,
          }))
        );
        console.log("pdf", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [IpNurseQueSelectedRow?.Booking_Id]);
  const handleRequestview = (params) => {
    console.log(params);
    setmodalIsOpen(true);
    setmodalcontent(params);
  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };

  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  // Define the columns dynamically
  const dynamicColumns = [
    { field: "id", headerName: "S_No", width: 100 },
    ...['BookingId',"PatientId", "PatientName", "Admitdate", "PrimaryDoctor", "ReportType","CapturedBy"].map(
      (labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width: ["Instruction"].find((f) => f === labelname)
            ? labelWidth + 100
            : labelWidth + 30,
          valueGetter: (params) => {
            const value = params.row[labelname];
            return value ? value : "-";
          },
        };
      }
    ),
    {
      field: "Action",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestview(params.row.Pdf)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={MedicalrecordData.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && MedicalrecordData.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && MedicalrecordData.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setmodalIsOpen(false)}
        style={{ content: { ...yourStyles } }}
      >
        <div className="pdf_img_show">
          {modalcontent && (
            <iframe
              title="PDF Viewer"
              src={modalcontent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={() => setmodalIsOpen(false)}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IcuMedicalRecords;
