import React, { useEffect } from "react";
import { useState } from "react";
import "./PharmacyBilling.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";

import { useSelector } from "react-redux";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';

function PharmacyBilling() {
  const navigate = useNavigate();

  const Selected_Patient_Pharmacy = useSelector(
    (state) => state.userRecord?.Selected_Patient_Pharmacy
  );
  console.log("Selected_Patient_Pharmacy :", Selected_Patient_Pharmacy);
  const Billing_PatientID = Selected_Patient_Pharmacy?.PatientID;

  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [Billing_date, setBilling_date] = useState(new Date());
  const [totalPaidAmount, settotalPaidAmount] = useState(0);

  // const [paidamount, setPaidamount] = useState('');

  // const [billAmount, setBillAmount] = useState([])

  // const [Billpay_Account, setBillpay_Account] = useState('')

  const [billAmount, setBillAmount] = useState([]);
  const [getamount, setGetAmount] = useState([]);

  const [isEdit, setIsEdit] = useState(null);

  const [formAmount, setFormAmount] = useState({
    paidamount: "",
    Billpay_method: "",
  });

  const reduxvalueUsercord = useSelector((state) => state);
  const userRecord = reduxvalueUsercord.userRecord?.UserData;

  const FilteUser_Name = userRecord?.username;

  // console.log("9595", FilteUser_Name);

  const User_Name = FilteUser_Name;
  const location = userRecord?.location;

  // console.log('location :', location)

  const [DefuldInvoicenumber, setDefuldInvoicenumber] = useState(null);

  const [Prescription_Patient_list, setPrescription_Patient_list] = useState(
    []
  );

  const [Patient_list, setPatient_list] = useState({});

  const [tableData, setTableData] = useState([]);

  const [getStockid_Name, setgetStockid_Name] = useState([]);

  const [Billing_itemtable, setBilling_itemtable] = useState([]);

  const [Billpay_method, setBillpay_method] = useState("");

  const [NetAmount_CDmethod, setNetAmount_CDmethod] = useState("");

  const [NetAmount_CDAmount, setNetAmount_CDAmount] = useState("");

  const [total_netamount, settotal_netamount] = useState(0);

  const [Prescription_doctor, setPrescription_doctor] = useState("");

  const [SelectedPatient_list, setSelectedPatient_list] = useState({
    Doctor_name: "",
    PatientId: "",
    PatientName: "",
    PatientAge: "",
    PatientAddress: "",
    City: "",
    State: "",
    Pincode: "",
    PhoneNumber: "",
  });

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
  });

  const handleAmountSubmit = () => {
    const apiUrl = "https://vesoftometic.co.in/GeneralBilling/overall_amount_table";
    const submissionData = {
      InvoiceNo: DefuldInvoicenumber, // Assuming ClinicDetails is accessible
      payments: billAmount, // Submitting the entire array
      location: userRecord?.location, // Assuming userRecord is accessible
    };

    console.log("submissionData", submissionData);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submissionData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        alert("Added Successfully");
        // Optionally reset billAmount to empty after successful submission
        // setBillAmount([]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const totalPaidAmount = billAmount.reduce(
      (total, ele) => +total + +ele.paidamount,
      0
    );
    settotalPaidAmount(totalPaidAmount);
  }, [billAmount, billAmount.length]);
  const handleChange = (e) => {
    // Calculate the total paid amount

    const { name, value } = e.target; // Destructuring name and value from event target
    console.log(total_netamount);
    console.log(totalPaidAmount);
    if (+totalPaidAmount !== +total_netamount) {
      if (name == "Billpay_method") {
        setFormAmount((prevState) => ({
          ...prevState, // Spread the previous state
          [name]: value, // Update the specific field based on the input's name attribute
        }));
      } else {
        if (billAmount.length > 0) {
          const amttt = parseFloat(total_netamount) - totalPaidAmount;
          if (+amttt >= +value) {
            setFormAmount((prevState) => ({
              ...prevState, // Spread the previous state
              [name]: value, // Update the specific field based on the input's name attribute
            }));
          } else {
            alert(`enter the Correct value blow the Net Amount ${amttt}`);
            setFormAmount((prevState) => ({
              ...prevState, // Spread the previous state
              [name]: "", // Update the specific field based on the input's name attribute
            }));
          }
        } else {
          if (+total_netamount >= +value) {
            setFormAmount((prevState) => ({
              ...prevState, // Spread the previous state
              [name]: value, // Update the specific field based on the input's name attribute
            }));
          } else {
            alert(
              `enter the Correct value blow the Net Amount ${total_netamount}`
            );
            setFormAmount((prevState) => ({
              ...prevState, // Spread the previous state
              [name]: "", // Update the specific field based on the input's name attribute
            }));
          }
        }
      }
    } else {
      alert("No Balance Amount");
    }
  };

  console.log("billAmount", billAmount);

  const handleAdd = () => {
    const req = ["Billpay_method", "paidamount"];
    const missing = req.filter((row) => !formAmount[row]);
    if (missing.length === 0) {
      const exist = billAmount.find(
        (p) => p.Billpay_method === formAmount.Billpay_method
      );
      if (!exist) {
        setBillAmount((prev) => [...prev, formAmount]);
        setFormAmount({
          paidamount: "",
          Billpay_method: "",
        });
      } else {
        alert("The Payment Method already exist");
      }
    } else {
      alert(`enter the required fields : ${missing.join(",")}`);
    }
  };

  const handleEdit = (index) => {
    setIsEdit(index);
    const item = billAmount[index];
    setFormAmount({
      ...item,
    });
  };

  const handleUpdate = () => {
    const req = ["Billpay_method", "paidamount"];
    const missing = req.filter((row) => !formAmount[row]);
    if (missing.length === 0) {
      const data = [...billAmount];
      data[isEdit] = formAmount;

      setBillAmount(data);
      setFormAmount({
        paidamount: "",
        Billpay_method: "",
      });
      setIsEdit(null);
    } else {
      alert(`enter the required fields : ${missing.join(",")}`);
    }
  };

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/doctorsworkbench/get_prescription?PatientID=${Billing_PatientID}&location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        let Datas = response.data;
        // Assuming PatientID is a property in each data element
        setPrescription_Patient_list(
          Datas.filter((ele, ind) => ele.PatientID === Billing_PatientID)
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle the error, e.g., show an error message to the user
      });
  }, [Billing_PatientID, userRecord]);

  useEffect(() => {
    // First Axios request to get personal info
    axios
      .get("https://vesoftometic.co.in/patientmanagement/get_personal_info")
      .then((response) => {
        console.log("Personal Info:", response.data);
        const datas = response.data.find(
          (ele) => ele.PatientID === Billing_PatientID
        );

        if (datas) {
          // Set patient_list state with personal info
          setPatient_list(datas);

          // Second Axios request to get permanent address info
          axios
            .get(
              "https://vesoftometic.co.in/patientmanagement/get_communication_address"
            )
            .then((response) => {
              // console.log("Permanent Address Info:", response.data);
              const addressData = response.data.find(
                (ele) => ele.PatientID === Billing_PatientID
              );

              if (addressData) {
                // Update patient_list state with permanent address info
                setPatient_list((prev) => ({ ...prev, ...addressData }));
              } else {
                console.error(
                  "Permanent address data not found for Billing_PatientID:",
                  Billing_PatientID
                );
              }
            })
            .catch((error) => {
              console.error("Error fetching permanent address info:", error);
              // Handle the error, e.g., show an error message to the user
            });
        } else {
          console.error(
            "Patient data not found for Billing_PatientID:",
            Billing_PatientID
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching personal info:", error);
        // Handle the error, e.g., show an error message to the user
      });
  }, [Billing_PatientID]);

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/Billinginvoice/get_PharmacyBilling_table_invoice`
      )
      .then((response) => {
        setDefuldInvoicenumber(response.data.nextInvoiceNumber);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // console.log("ooo", Patient_list);
  // console.log("xxx", Prescription_Patient_list);

  useEffect(() => {
    setBilling_itemtable((prev) =>
      Prescription_Patient_list.map((ele, index) => ({
        S_No: index + 1,
        ItemName: ele.ItemName,
        Quantity: ele.Qty,
      }))
    );
  }, [Prescription_Patient_list]);

  useEffect(() => {
    // Assuming Prescription_Patient_list is an array of objects
    const uniqueDoctors = [
      ...new Set(Prescription_Patient_list.map((ele) => ele.DoctorName)),
    ];
    setPrescription_doctor(uniqueDoctors);
    console.log("===", uniqueDoctors);
  }, [Prescription_Patient_list]);

  useEffect(() => {
    // let DDD =Prescription_doctor.find((ele)=>{return ele})

    setSelectedPatient_list({
      Doctor_name: Prescription_doctor[0],
      PatientId: Patient_list.PatientID,
      PatientName: Patient_list.FirstName,
      PatientAge: Patient_list.Age,
      PatientAddress: Patient_list.Street,
      City: Patient_list.City,
      State: Patient_list.State,
      Pincode: Patient_list.Pincode,
      PhoneNumber: Patient_list.PhoneNumber,
    });
  }, [Patient_list, Prescription_doctor]);

  console.log("zz", Billing_itemtable);

  // console.log("www",getid_Name)

  // console.log("zz",tableData)

  const [Single_row_data, setSingle_row_data] = useState({
    ItemId: "",
    ItemName: "",
    Generic: "",
    BatchNo: "",
    Exp_Date: "",
    Quantity: "",
    Avail_Qty: "",
    Billing_Quantity: "",
    Unit_Price: "",
    Amount: "",
    CD_Method: "",
    Cash_Discount: "",
    GST: "",
    Original_total: "",
    Total: "",
    CGST: "",
    SGST: "",
  });
  // console.log('single row data :', Single_row_data)
  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(
        `https://vesoftometic.co.in/quickstockreceive/get_quick_list?location=${location}`
      )
      .then((response) => {
        setgetStockid_Name(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord]);

  // console.log("----", getStockid_Name);

  const fetchBatchNos = (itemName, batchNo, userRecord) => {
    console.log(batchNo);

    // Replace 'https://vesoftometic.co.in/hairtree' with the actual backend API endpoint
    const location = userRecord.location;
    axios
      .get(
        `https://vesoftometic.co.in/quickstockreceive/get_name?ItemName=${itemName}&BatchNo=${batchNo}&location=${location}`
      )
      .then((response) => {
        // Handle the response and update the state to set BatchNo values for the respective ItemName
        setTableData(response.data);

        console.log("aa", response.data);

        const temp = response.data[0];

        const Exp_Status = response.data.find(
          (repeat) => repeat.ExpiryStatus === "Next Expiry"
        );

        if (Exp_Status) {
          const confirm = window.confirm(
            "The following tablets will expire within the next 2 weeks"
          );

          console.log(confirm);

          if (confirm === true) {
            setSingle_row_data((prevData) => ({
              ...prevData,
              ItemName: temp.ItemName,
              ItemId: temp.ItemCode,
              Generic: temp.GenericName,
              Exp_Date: temp.Exp_Date,
              Unit_Price: temp.MRP,
              GST: temp.GST,
              CGST: temp.CGST,
              SGST: temp.SGST,
            }));
          } else {
            setSingle_row_data({
              ItemId: "",
              ItemName: "",
              Generic: "",
              BatchNo: "",
              Exp_Date: "",
              Quantity: "",
              Avail_Qty: "",
              Billing_Quantity: "",
              Unit_Price: "",
              Amount: "",
              CD_Method: "",
              Cash_Discount: "",
              GST: "",
              Original_total: "",
              Total: "",
              CGST: "",
              SGST: "",
            });
          }
        } else {
          setSingle_row_data((prevData) => ({
            ...prevData,
            ItemName: temp.ItemName,
            ItemId: temp.ItemCode,
            Generic: temp.GenericName,
            Exp_Date: temp.Exp_Date,
            Unit_Price: temp.MRP,
            GST: temp.GST,
            CGST: temp.CGST,
            SGST: temp.SGST,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log("tableData :", tableData);
    console.log("Single_row_data.BatchNo :", Single_row_data.BatchNo);
    let temp_ob = tableData.find((e) => e.BatchNo === Single_row_data.BatchNo);

    console.log("wquddddddddddk", temp_ob);

    if (temp_ob) {
      setSingle_row_data((prevData) => ({
        ...prevData,
        Exp_Date: temp_ob.Exp_Date,
        Avail_Qty: temp_ob.AvailableQuantity,
        Unit_Price: temp_ob.MRP,
        GST: temp_ob.GST || 0,
        CGST: temp_ob.GST / 2 || 0,
        SGST: temp_ob.GST / 2 || 0,
      }));
    }
  }, [Single_row_data.BatchNo, tableData]);

  useEffect(() => {
    setSingle_row_data((prevData) => ({
      ...prevData,
      Amount:
        Single_row_data.Billing_Quantity * Single_row_data.Unit_Price || 0,
    }));
  }, [Single_row_data.Billing_Quantity, Single_row_data.Unit_Price]);

  useEffect(() => {
    const GSTAmount = (Single_row_data.Amount * Single_row_data.GST) / 100 || 0;
    const Total = Single_row_data.Amount || 0;

    setSingle_row_data((prevData) => ({
      ...prevData,
      Total: Total,
      GSTAmount: GSTAmount,
      Original_total: Total,
    }));
  }, [Single_row_data.Amount, Single_row_data.GST]);

  useEffect(() => {
    if (Single_row_data.Cash_Discount === "") {
      let GST_A_Amout =
        (+Single_row_data.Original_total * +Single_row_data.GST) / 100;

      setSingle_row_data((prevData) => ({
        ...prevData,
        Total: Single_row_data.Original_total,
        GSTAmount: GST_A_Amout.toFixed(2),
      }));
    }
    if (Single_row_data.CD_Method === "") {
      let GST_A_Amout =
        (+Single_row_data.Original_total * +Single_row_data.GST) / 100;
      setSingle_row_data((prevData) => ({
        ...prevData,
        Cash_Discount: "",
        Total: Single_row_data.Original_total,
        GSTAmount: GST_A_Amout.toFixed(2),
      }));
    }
    if (
      Single_row_data.Billing_Quantity === "" &&
      (Single_row_data.CD_Method !== "" || Single_row_data.Cash_Discount !== "")
    ) {
      setSingle_row_data((prevData) => ({
        ...prevData,
        CD_Method: "",
        Cash_Discount: "",
      }));

      alert("Enter Billing Quantity");
    } else {
      if (Single_row_data.CD_Method === "Cash") {
        let CashDiscountAmount =
          Single_row_data.Original_total - Single_row_data.Cash_Discount;
        let GST_A_Amout = (+CashDiscountAmount * +Single_row_data.GST) / 100;

        setSingle_row_data((prevData) => ({
          ...prevData,
          Total: CashDiscountAmount.toFixed(2),
          GSTAmount: GST_A_Amout.toFixed(2),
        }));
      } else if (Single_row_data.CD_Method === "Percentage") {
        const percentageDiscount = Math.max(
          0,
          Math.min(Single_row_data.Cash_Discount, 100)
        );

        const percentageDiscountAmount =
          Single_row_data.Original_total * (percentageDiscount / 100);
        let CashDiscountAmount =
          Single_row_data.Original_total - percentageDiscountAmount;
        let GST_A_Amout = (+CashDiscountAmount * +Single_row_data.GST) / 100;

        setSingle_row_data((prevData) => ({
          ...prevData,
          Total: CashDiscountAmount.toFixed(2),
          GSTAmount: GST_A_Amout.toFixed(2),
        }));
      }
    }
  }, [
    Single_row_data.CD_Method,
    Single_row_data.Original_total,
    Single_row_data.Cash_Discount,
    Single_row_data.Billing_Quantity,
  ]);

  const set_handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "Cash_Discount" && Single_row_data.CD_Method === "") {
      alert("Select Discount type");
    }

    if (name === "ItemName") {
      const newvalue = value.split(",");
      const updatevalue = newvalue[0];
      const updatevalue1 = newvalue[1];
      setSingle_row_data((prevData) => ({
        ...prevData,
        ItemName: updatevalue,
        BatchNo: updatevalue1,
      }));
    } else {
      setSingle_row_data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const Additemforbill = (No) => {
    if (Single_row_data.Billing_Quantity === "") {
      alert("Enter Billing Quantity");
    } else if (
      Single_row_data.CD_Method !== "" &&
      Single_row_data.Cash_Discount === ""
    ) {
      alert("Enter Discount Amount");
    } else {
      const Samedata = Billing_itemtable.find((repeat) => repeat.S_No === No);

      if (Samedata) {
        const updatedStockIdName = getStockid_Name.map((ele) => ({
          ...ele,
          AvailableQuantity:
            ele.BatchNo === parseInt(Single_row_data.BatchNo)
              ? ele.AvailableQuantity -
                parseInt(Single_row_data.Billing_Quantity)
              : ele.AvailableQuantity,
        }));

        setgetStockid_Name(updatedStockIdName);

        let Edit_data = Billing_itemtable.map((repeat) =>
          repeat.S_No === No ? { ...repeat, ...Single_row_data } : repeat
        );

        setBilling_itemtable(Edit_data);
        setSingle_row_data({
          ItemId: "",
          ItemName: "",
          Generic: "",
          BatchNo: "",
          Exp_Date: "",
          Quantity: "",
          Avail_Qty: "",
          Billing_Quantity: "",
          Unit_Price: "",
          Amount: "",
          CD_Method: "",
          Cash_Discount: "",
          GST: "",
          Original_total: "",
          Total: "",
        });
      } else {
        const updatedStockIdName = getStockid_Name.map((ele) => ({
          ...ele,
          AvailableQuantity:
            ele.BatchNo === parseInt(Single_row_data.BatchNo)
              ? ele.AvailableQuantity -
                parseInt(Single_row_data.Billing_Quantity)
              : ele.AvailableQuantity,
        }));

        setgetStockid_Name(updatedStockIdName);

        const S_No = Billing_itemtable.length + 1;

        setBilling_itemtable((prevBillingItemTable) => [
          ...prevBillingItemTable,
          { S_No, ...Single_row_data },
        ]);

        setSingle_row_data({
          ItemId: "",
          ItemName: "",
          Generic: "",
          BatchNo: "",
          Exp_Date: "",
          Quantity: "",
          Avail_Qty: "",
          Billing_Quantity: "",
          Unit_Price: "",
          Amount: "",
          CD_Method: "",
          Cash_Discount: "",
          GST: "",
          Original_total: "",
          Total: "",
        });
      }
    }
  };

  const Editbillingitem = (single_Edit_data) => {
    const updatedStockIdName = getStockid_Name.map((ele) => ({
      ...ele,
      AvailableQuantity:
        ele.BatchNo === parseInt(single_Edit_data.BatchNo)
          ? ele.AvailableQuantity + parseInt(single_Edit_data.Billing_Quantity)
          : ele.AvailableQuantity,
    }));

    setgetStockid_Name(updatedStockIdName);

    // console.log(single_Edit_data);

    setSingle_row_data((prevData) => ({
      ...prevData, // Spread the previous data to retain its values
      ...single_Edit_data, // Spread the properties of single_Edit_data
    }));
  };

  const deletebillingitem = (S_No) => {
    let Temp_delarr = Billing_itemtable.filter((ele) => ele.S_No !== S_No);

    // Calculate the new S_No based on the length of the filtered array
    // const newS_No = Temp_delarr.length + 1;

    // Add the modified array back to the state with the new S_No
    setBilling_itemtable(
      Temp_delarr.map((item, index) => ({ ...item, S_No: index + 1 }))
    );
  };

  const [summary, setSummary] = useState({
    totalItems: 0,
    totalQty: 0,
    totalBase: 0,
    totalGSTPercentage: 0,
    totalGSTAmount: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    const calculateSummary = () => {
      let totalItems = 0;
      let totalQty = 0;
      let totalBase = 0;
      let totalGSTAmount = 0;

      console.log("Billing_itemtable :", Billing_itemtable);

      Billing_itemtable.forEach((medicineInfo) => {
        totalItems++;
        totalQty += isNaN(medicineInfo.Billing_Quantity)
          ? 0
          : parseInt(medicineInfo.Billing_Quantity, 10);
        totalBase += isNaN(medicineInfo.Total)
          ? 0
          : parseFloat(medicineInfo.Total);
        totalGSTAmount +=
          ((medicineInfo.Total || 0) * (medicineInfo.GST || 0)) / 100 || 0;
      });

      return {
        totalItems,
        totalQty,
        totalBase,
        totalGSTAmount,
      };
    };

    const newSummary = calculateSummary();
    setSummary(newSummary);
  }, [Billing_itemtable]);

  useEffect(() => {
    if (summary.totalBase && summary.totalGSTAmount) {
      let TotA = +summary.totalBase + +summary.totalGSTAmount || 0;

      setSummary((prev) => ({
        ...prev,
        totalAmount: TotA,
      }));
      settotal_netamount(TotA);
    }
  }, [summary.totalBase, summary.totalGSTAmount]);

  useEffect(() => {
    if (NetAmount_CDmethod === "") {
      let disdata = Billing_itemtable.map((ele) => {
        let BalaT = ele.Amount;
        return {
          ...ele,
          Total: BalaT.toFixed(2),
        };
      });

      console.log("disdata", disdata);

      setBilling_itemtable(disdata);

      setNetAmount_CDAmount("");
    } else if (NetAmount_CDmethod === "Cash") {
      let DisSplit = +NetAmount_CDAmount / Billing_itemtable.length;

      console.log("DisSplit", DisSplit);

      let disdata = Billing_itemtable.map((ele) => {
        let BalaT = ele.Amount - DisSplit;
        let GST_A_Amout = (+BalaT * +ele.GST) / 100;

        return {
          ...ele,
          Total: BalaT.toFixed(2),
          GSTAmount: GST_A_Amout.toFixed(2),
        };
      });

      console.log("disdata", disdata);

      setBilling_itemtable(disdata);
    } else if (NetAmount_CDmethod === "Percentage") {
      let FinelDis = NetAmount_CDAmount;

      let disdata = Billing_itemtable.map((ele) => {
        let BalaT = ele.Amount - +ele.Amount * (FinelDis / 100);

        let GST_A_Amout = (+BalaT * +ele.GST) / 100;

        return {
          ...ele,
          Total: BalaT.toFixed(2),
          GSTAmount: GST_A_Amout.toFixed(2),
        };
      });

      console.log("disdata", disdata);

      setBilling_itemtable(disdata);
    }
  }, [NetAmount_CDmethod, NetAmount_CDAmount]);

  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);

    if (num === 0) {
      return "Zero";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      // Check if num is NaN or not a valid number
      if (isNaN(num) || num < 0 || !Number.isInteger(num)) {
        return "Invalid input";
      }

      console.log("Processing number:", num);
      if (num === 0) return "Zero";
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return units[Math.floor(num / 100)] + " Hundred " + convert(num % 100);
      if (num < 1000000)
        return (
          convert(Math.floor(num / 1000)) + " Thousand " + convert(num % 1000)
        );
      return (
        convert(Math.floor(num / 1000000)) +
        " Million " +
        convert(num % 1000000)
      );
    };

    return convert(num);
  };

  const handleTotal_SelectCDMethod = (event) => {
    const { value } = event.target;
    setNetAmount_CDmethod(value);
  };

  useEffect(() => {
    axios
      .get(
        `https://vesoftometic.co.in/usercontrol/getClinic?location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        const clinicData = response.data[0];

        if (clinicData) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress:
              clinicData.door_no +
              "," +
              clinicData.area +
              "," +
              clinicData.street,
            ClinicGST: clinicData.Gst_no,
            ClinicCity: clinicData.city,
            ClinicState: clinicData.state,
            ClinicCode: clinicData.pincode,
            ClinicMobileNo: clinicData.phone_no,
            ClinicLandLineNo: clinicData.landline_no,
            ClinicMailID: clinicData.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://vesoftometic.co.in/usercontrol/getAccountsetting")
      .then((response) => {
        console.log(response.data);
        const accountData = response.data;
        if (accountData) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: accountData.clinicName,
            ClinicLogo: `data:image/png;base64,${accountData.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userRecord?.location]);

  const Submitalldata = () => {
    if (billAmount.length === 0) {
      alert("Enter Bill Payment Method");
    } else {
      setIsPrintButtonVisible(false);
      setTimeout(() => {
        handlePrint();
      }, 1000);
      
      

    }


    axios
      .get(
        `https://vesoftometic.co.in/GeneralBilling/get_overall_amount_table?InvoiceNo=${DefuldInvoicenumber}&location=${userRecord.location}`
      )
      .then((responce) => {
        console.log(responce);
        const data = responce.data;
        setGetAmount(data);
      })
      .catch((error) => {
        console.log("errors", error);
 });
};


  const handlePrint = useReactToPrint({
    content: () => document.getElementById('reactprintcontent'),
    onBeforePrint: () => {
      setIsPrintButtonVisible(false);
      console.log('Before');
    },
    onAfterPrint: async () => {
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);

      try {

        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 1.5; // Add padding to height
          const pdf = new jsPDF({
            unit: 'px',
            format: [pdfWidth, pdfHeight],
          });


          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content

            callback: () => {

              const generatedPdfBlob = pdf.output('blob');
              console.log(generatedPdfBlob)

              const formData = new FormData();
              formData.append('DefuldInvoicenumber', DefuldInvoicenumber);
              formData.append("PatientId",SelectedPatient_list.PatientId);
              formData.append("PatientName", SelectedPatient_list.PatientName);
              formData.append('Billing_date', Billing_date.toISOString().split("T")[0]);
              formData.append('user_name', User_Name);
              formData.append('location', location);
              formData.append('pdfData', generatedPdfBlob);
              formData.append('BillType', 'Pharmacy')


              const resetForm = () => {

                axios
                  .get(
                    `https://vesoftometic.co.in/Billinginvoice/get_PharmacyBilling_table_invoice`
                  )
                  .then((response) => {
                    setDefuldInvoicenumber(response.data.nextInvoiceNumber);
                    console.log(response.data);
                    setIsPrintButtonVisible(true);

                  })
                  .catch((error) => {
                    console.error(error);
                  });

                setDefuldInvoicenumber(null);
                setSelectedPatient_list({
                  Doctor_name: "",
                  PatientId: "",
                  PatientName: "",
                  PatientAge: "",
                  PatientAddress: "",
                  City: "",
                  State: "",
                  Pincode: "",
                  PhoneNumber: "",
                });
                setBilling_date(new Date());
                setBillpay_method("");
                setNetAmount_CDmethod("");
                setNetAmount_CDAmount("");
                setBilling_itemtable([]);
                // Reset other state variables as needed
              };

              axios
                .post(
                  "https://vesoftometic.co.in/Billinginvoice/Post_PharmacyBilling_table",
                  {
                    DefuldInvoicenumber,
                    SelectedPatient_list,
                    Billing_date: Billing_date.toISOString().split("T")[0],
                    Billpay_method,
                    NetAmount_CDmethod,
                    NetAmount_CDAmount,
                    summary: { ...summary },
                    total_netamount,
                    User_Name,
                    location,
                    amountInWords: numberToWords(
                      Math.round(total_netamount * 100) / 100
                    ),
                  }
                )
                .then((res) => {
                  axios
                    .post(
                      "https://vesoftometic.co.in/Billinginvoice/Post_PharmacyBilling_Items_table",
                      {
                        DefuldInvoicenumber,
                        Billing_itemtable,
                        location,
                      }
                    )
                    .then((res) => {
                      alert(" Billed Successfully");
                      axios
                        .post(
                          `https://vesoftometic.co.in/Billinginvoice/post_pharmacy_billingpdf`, formData
                        )
                        .then((response) => {
                          console.log(response.data);
                          handleAmountSubmit();
                          // resetForm();
                          navigate('/Home/Pharmacy_Billing_List')
                          setIsPrintButtonVisible(true);
                          
                        })
                        .catch((error) => {
                          console.error(error);
                        });


                    })
                    .catch((error) => {
                      console.error("Error inserting data:", error);
                    });
                })
                .catch((error) => {
                  console.error("Error inserting data:", error);
                });
              console.log('generatedPdfBlob', generatedPdfBlob)
            },
          });
        } else {
          throw new Error('Unable to get the target element');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
   }
}});


  const forPrintData = () => {
    return (
      <div className="billing-invoice" id="reactprintcontent">
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <div
          className="Register_btn_con"
          style={{ color: "hsl(33,100%,50%)", fontWeight: 600 }}
        >
          Pharmacy Billing
        </div>
        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Patient Name <span>:</span>
              </label>
              <span>{SelectedPatient_list.PatientName}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Patient ID <span>:</span>
              </label>
              <span>{SelectedPatient_list.PatientId}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Age <span>:</span>
              </label>
              <span>{SelectedPatient_list.PatientAge}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Gender <span>:</span>
              </label>
              <span>{Patient_list.Gender}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Address <span>:</span>
              </label>
              <span>{SelectedPatient_list.City}</span>
            </div>
          </div>
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Invoice No <span>:</span>
              </label>
              <span>{DefuldInvoicenumber}</span>
            </div>
            <div className="new_billing_div">
              <label>
                GSTIN No <span>:</span>
              </label>
              <span>{ClinicDetials.ClinicGST}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Physician Name <span>:</span>
              </label>
              <span>{SelectedPatient_list.Doctor_name}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Date <span>:</span>
              </label>
              <span>{Billing_date.toLocaleDateString()}</span>
            </div>
            <div className="new_billing_div">
              <label>
                {" "}
                Patient Mobile No <span>:</span>
              </label>
              <span>{SelectedPatient_list.PhoneNumber}</span>
            </div>
          </div>
        </div>

        <div className="new_billing_invoice_detials">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Item Name</th>
                <th>Batch No</th>
                <th>Expiry Date</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>GST Amount</th>
                <th>Discount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {Billing_itemtable.map((medicineInfo, index) => (
                <tr key={index}>
                  <td>{medicineInfo.S_No}</td>
                  <td>{medicineInfo.ItemName}</td>
                  <td>{medicineInfo.BatchNo}</td>
                  <td>{medicineInfo.Exp_Date}</td>
                  <td>{medicineInfo.Billing_Quantity}</td>
                  <td>{medicineInfo.Unit_Price}</td>
                  <td>{medicineInfo.Amount}</td>
                  <td>{medicineInfo.GSTAmount}</td>
                  {medicineInfo.CD_Method === "" ? (
                    <td>No Discount</td>
                  ) : medicineInfo.CD_Method === "Cash" ? (
                    <td> Rs. {medicineInfo.Cash_Discount}</td>
                  ) : (
                    <td> {medicineInfo.Cash_Discount} % </td>
                  )}
                  <td>{medicineInfo.Total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div className="bill_body">
                <label>
                  {" "}
                  Items <span>:</span>
                </label>
                <span>{summary.totalItems}</span>
              </div>
              <div className="bill_body">
                <label>
                  {" "}
                  Quantity <span>:</span>
                </label>
                <span>{summary.totalQty}</span>
              </div>
              <div className="bill_body">
                <label>
                  {" "}
                  MRP <span>:</span>
                </label>
                <span>{summary.totalBase}</span>
              </div>
              <div className="bill_body">
                <label>
                  {" "}
                  SGST <span>:</span>
                </label>
                <span>{summary.totalGSTAmount / 2}</span>
              </div>
              <div className="bill_body">
                <label>
                  {" "}
                  CGST <span>:</span>
                </label>
                <span>{summary.totalGSTAmount / 2}</span>
              </div>
            </div>
            <div className="total_con_bill">
              <div className="bill_body">
                <label>
                  {" "}
                  GST<span>:</span>
                </label>
                <span>{summary.totalGSTAmount}</span>
              </div>

              {billAmount.map((row, index) => (
                <div key={index} className="bill_body">
                  {/* <div className="item-index">{index + 1}</div> */}
                  <label>
                    {row.Billpay_method}
                    <span>:</span>
                  </label>
                  <span>{row.paidamount}</span>
                </div>
              ))}
              <div className="bill_body">
                <label>
                  Net Amount <span>:</span>
                </label>
                <span>{total_netamount.toFixed(2)}</span>
              </div>

              {/* <div className="bill_body">
                            <label> Amount <span>:</span></label>
                            <span>{summary.totalAmount.toFixed(2)}</span>
                        </div>
                        <div className="bill_body">
                            <label> Cash Discount <span>:</span></label>
                            <span>{NetAmount_CDAmount} </span>
                        </div> */}
              <div className="bill_body">
                <label>
                  {" "}
                  Billed By <span>:</span>
                </label>
                <span>{FilteUser_Name} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible ? (
        <div>
          <div className="QuickStock_container">
            <div className="QuickStock_container_header">
              <h4>
                <ShoppingCartIcon />
                Pharmacy Billing
              </h4>
            </div>

            <div className="mannual-header-with">
              <div className="Billing_Invoice_header">
                <h4>AUTO INVOICE</h4>
              </div>

              <div className="invoice_firstpart">
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Invoice No<span>:</span>
                    </label>
                    <input
                      type="text"
                      value={DefuldInvoicenumber}
                      name="DefuldInvoicenumber"
                      readOnly
                      className="Inventory-2"
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Date<span>:</span>
                    </label>
                    <input
                      type="Date"
                      name="Billing_date"
                      value={Billing_date.toISOString().split("T")[0]} // Convert to ISO string and extract the date part
                      onChange={(e) =>
                        setBilling_date(new Date(e.target.value))
                      }
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Physician Name <span>:</span>
                    </label>
                    <input
                      name="doctor_name"
                      readOnly
                      value={Prescription_doctor}
                    />
                  </div>
                </div>
              </div>

              <div className="invoice_Secondpart">
                <h3 style={{ height: "40px" ,fontSize:'16px' }}>Patient Detailes :</h3>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Patient ID <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Patient_list.PatientID}
                      name="PatientID"
                      readOnly
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Patient Name <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Patient_list.FirstName}
                      name="Patient_Name"
                      readOnly
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Patient Age <span>:</span>
                    </label>
                    <input
                      type="number"
                      name="Patient_Age"
                      value={Patient_list.Age}
                      readOnly
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Patient Address <span>:</span>
                    </label>
                    <input
                      name="Street"
                      type="text"
                      value={Patient_list.Street}
                      readOnly
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      City <span>:</span>
                    </label>
                    <input
                      name="City"
                      type="text"
                      value={Patient_list.City}
                      readOnly
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      State <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Patient_list.State}
                      name="State"
                      readOnly
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Pincode <span>:</span>
                    </label>
                    <input
                      type="number"
                      value={Patient_list.Pincode}
                      name="Pincode"
                      readOnly
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="itemCode">
                      Phone Number<span>:</span>
                    </label>
                    <input
                      readOnly
                      type="number"
                      value={Patient_list.PhoneNumber}
                      name="Phone_Number"
                    />
                  </div>
                </div>
              </div>

              <br></br>

              <div className="for33">
                <div className="h_head">
                  <h4>Selected Medicine</h4>
                </div>

                <div className="Selected-table-container444">
                  <table className="selected-medicine-table222">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Item Name</th>
                        <th>Batch No</th>
                        <th>Exp Date</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>CGST %</th>
                        <th>SGST %</th>
                        <th>GSTAmount</th>
                        <th>Discount</th>
                        <th>Total</th>

                        <th className="hideDataForPrint">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Billing_itemtable.map((medicineInfo, index) => {
                        const CGST = medicineInfo.GST / 2 || 0;
                        const SGST = medicineInfo.GST / 2 || 0;

                        return (
                          <tr key={index}>
                            <td>{medicineInfo.S_No}</td>
                            <td>{medicineInfo.ItemName}</td>
                            <td>{medicineInfo.BatchNo}</td>
                            <td>{medicineInfo.Exp_Date}</td>
                            <td>{medicineInfo.Billing_Quantity}</td>
                            <td>{medicineInfo.Unit_Price}</td>
                            <td>{medicineInfo.Amount}</td>
                            <td>{CGST}%</td>
                            <td>{SGST}%</td>
                            <td>{medicineInfo.GSTAmount}</td>
                            {medicineInfo.CD_Method === "" ? (
                              <td>No Discount</td>
                            ) : medicineInfo.CD_Method === "Cash" ? (
                              <td> Rs. {medicineInfo.Cash_Discount}</td>
                            ) : (
                              <td>{medicineInfo.Cash_Discount} % </td>
                            )}
                            <td>{medicineInfo.Total}</td>
                            <td>
                              <div className="Action_btns">
                                <button
                                  className="delnamebtn"
                                  onClick={() => {
                                    Editbillingitem(medicineInfo);
                                  }}
                                >
                                  <EditIcon />
                                </button>
                                <button
                                  className="delnamebtn"
                                  onClick={() => {
                                    deletebillingitem(medicineInfo.S_No);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
           
              <br></br>

              <div className="for33">
                <div className="h_head">
                  <h4>Item Detailes :</h4>
                </div>
      
                <div className="Selected-table-container444">
                  <table className="selected-medicine-table222">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Item Id</th>
                        <th>Generic</th>
                        {/* <th>Batch No</th> */}
                        <th>Exp Date</th>
                        <th>Quantity</th>
                        <th>Billing Quantity</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Discount Type</th>
                        <th>Discount Amount</th>
                        <th>GST</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="input-sech-fld">
                          <div className="QuickStock_container_div">
                            <input
                              list="browsers"
                              name="ItemName"
                              id="browser"
                              className="inpt-pad-btm"
                              value={Single_row_data.ItemName}
                              onChange={set_handleInputChange}
                            />

                            <datalist id="browsers">
                              {getStockid_Name.map((item, index) => (
                                <option
                                  key={item.ItemCode}
                                  value={`${item.ItemName},${item.BatchNo}`}
                                >
                                  {`${item.ItemName} | BatchNo:${
                                    item.BatchNo
                                  } | Ava.Quantity:${
                                    item.AvailableQuantity
                                  } | MRP:${parseFloat(item.MRP).toFixed(2)}`}
                                </option>
                              ))}
                            </datalist>
                            <button
                              className="ItemName_searchicon itnm-icon"
                              onClick={() => {
                                if (
                                  Single_row_data.ItemName &&
                                  Single_row_data.BatchNo
                                ) {
                                  fetchBatchNos(
                                    Single_row_data.ItemName,
                                    Single_row_data.BatchNo,
                                    userRecord
                                  );
                                } else {
                                  alert("Select Batch Number");
                                }
                              }}
                            >
                              <SearchIcon />
                            </button>
                          </div>
                        </td>

                        <td className="input-sech-fld">
                          <input
                            list="ItemIds"
                            name="ItemId"
                            id="F_ItemId"
                            value={Single_row_data.ItemId}
                            onChange={set_handleInputChange}
                            readOnly
                          />
                        </td>

                        <td className="input-sech-fld">
                          <input value={Single_row_data.Generic} readOnly />
                        </td>

                        {/* <td><select
name="BatchNo"
className="Select_billing"
value={Single_row_data.BatchNo}
onChange={set_handleInputChange}
>
<option value="">select</option>
{tableData.map((item) => (
<option key={item.BatchNo} value={item.BatchNo}>
    {item.BatchNo}
</option>
))}
</select>
</td> */}

                        <td className="input-sech-fld">
                          <input readOnly value={Single_row_data.Exp_Date} />
                        </td>
                        <td> {Single_row_data.Quantity}</td>

                        <td className="input-sech-fld">
                          <input
                            name="Billing_Quantity"
                            value={Single_row_data.Billing_Quantity}
                            onChange={set_handleInputChange}
                          />
                        </td>

                        <td className="input-sech-fld">
                          <input readOnly value={Single_row_data.Unit_Price} />
                        </td>
                        <td className="input-sech-fld">
                          <input readOnly value={Single_row_data.Amount} />
                        </td>

                        <td className="input-sech-fld">
                          <select
                            name="CD_Method"
                            value={Single_row_data.CD_Method}
                            onChange={set_handleInputChange}
                            disabled={NetAmount_CDmethod !== ""}
                            className="slect-itm-detls-data"
                          >
                            <option value="">select</option>
                            <option value="Cash">Cash</option>
                            <option value="Percentage">Percentage</option>
                          </select>
                        </td>

                        <td className="input-sech-fld">
                          <input
                            type="number"
                            name="Cash_Discount"
                            value={Single_row_data.Cash_Discount}
                            onChange={set_handleInputChange}
                            disabled={NetAmount_CDmethod !== ""}
                          />
                        </td>

                        <td className="input-sech-fld">
                          <input readOnly value={Single_row_data.GST} />
                        </td>
                        <td>
                          <input
                            className="total-itm-detls-data"
                            readOnly
                            value={Single_row_data.Total}
                          />
                        </td>
                        <td className="input-sech-fld">
                          <button
                            className="Addnamebtn"
                            onClick={() => {
                              Additemforbill(Single_row_data.S_No);
                            }}
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <br></br>

              <div className="summary-container">
                <div className="pymt-flx-with">
                  <div className="fix-pymt-mble">
                    <label htmlFor="">CD Method: </label>
                    <select
                      name="CDMethod"
                      value={NetAmount_CDmethod}
                      onChange={(e) => {
                        handleTotal_SelectCDMethod(e);
                      }}
                      disabled={Billing_itemtable.some(
                        (ele) => ele.CD_Method !== ""
                      )}
                    >
                      <option value="">select</option>
                      <option value="Cash">Cash</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>
                  <div className="cah-d-wth fix-pymt-mble">
                    <label htmlFor="">Cash Discount:</label>
                    <input
                      type="number"
                      name="CashDiscount"
                      value={NetAmount_CDAmount}
                      onChange={(e) => setNetAmount_CDAmount(e.target.value)}
                      disabled={Billing_itemtable.some(
                        (ele) => ele.CD_Method !== ""
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="summary-container">
                <div className="itm-flx-wth">
                  <div className="clm-itm-stl">
                    <label>ITEMS :</label>
                    <input value={summary.totalItems} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>QTY :</label>
                    <input value={summary.totalQty} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>BASE :</label>
                    <input value={summary.totalBase} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>SGST:</label>
                    <input value={summary.totalGSTAmount / 2} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>CGST :</label>
                    <input value={summary.totalGSTAmount / 2} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>GST :</label>
                    <input value={summary.totalGSTAmount} readOnly />
                  </div>
                  <div className="clm-itm-stl">
                    <label>AMOUNT :</label>
                    <input value={summary.totalAmount} readOnly />
                  </div>

                  <div className="clm-itm-stl">
                    <label>Cash Discount :</label>
                    <input value={NetAmount_CDAmount} readOnly />
                  </div>

                  <div className="clm-itm-stl">
                    <label>Net Amount :</label>
                    <input value={total_netamount} readOnly />
                  </div>

                  <div className="clm-itm-stl">
                      <label>Payment Method :</label>
                      <select
                        name="Billpay_method"
                        value={formAmount.Billpay_method}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="OnlinePayment">Online Payment</option>
                        <option value="Cheque">Cheque</option>
                      </select>
                    </div>

                    <div className="clm-itm-stl">
                      <label>Cash Amount :</label>
                      <input
                        type="number"
                        name="paidamount"
                        value={formAmount.paidamount}
                        onChange={handleChange}
                      />
                    </div>
                </div>
                <br />
                <div className="clm-itm-stl">
                  <button
                    className="btncon_add prs-ad-sub-btn"
                    onClick={isEdit !== null ? handleUpdate : handleAdd}
                  >
                    {isEdit !== null ? "Update" : "Add"}
                  </button>
                </div>
               
              
           

              <br />

              <div className="invoice-details">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                      {/* <th>Total Amount </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {billAmount.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.Billpay_method}</td>
                        <td>{row.paidamount}</td>
                        <td>
                          <button onClick={() => handleEdit(index)}>
                            <EditNoteIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="new-button-container"></div>

              <div>
                <p>
                  Amount in Words:{" "}
                  {numberToWords(Math.round(total_netamount * 100) / 100)}
                </p>
              </div>
              </div>
            </div>
          </div>

          {isPrintButtonVisible && (
            <div className="Billing_btn">
              <button className="btn_1" onClick={Submitalldata}>
                Print
              </button>
            </div>
          )}
        </div>
      ) : (
        forPrintData()
      )}
    </>
  );
}

export default PharmacyBilling;
