import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { TbDelta } from "react-icons/tb";
import { GiDrippingTube } from "react-icons/gi";
import './CaptureGroup.css'
import './Navigation.css'
import { LineChart } from '@mui/x-charts/LineChart';


function Navigationple({ userRecord, isSidebarOpen }) {
  const [testDetails, setTestDetails] = useState([]);
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  const [visitid, setvisitid] = useState([])
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [value, setvalue] = useState([])
  const [openModel, setOpenModel]= useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${urllink}Billing/getcapturetestdata?Billinginvoice=${capturedatas.Billing_Invoice}&PatientId=${capturedatas.Patient_Id}`)
      .then((response) => {
        const data = response.data.map((item, index) => ({
          id: index + 1,
          testcode: item.Test_Code,
          testname: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          Test_Method: item.Test_Method,
          uom: item.UOM,
          department: item.Department,
          status: '',
          medicalRemark: '',
          technicalRemark: ''
        }));
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas]);

  const handleStatusChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handleRemarksChange = (index, newValue, type) => {
    const updatedTestDetails = [...testDetails];
    if (type === 'technical') {
      updatedTestDetails[index].technicalRemark = newValue;
    } else if (type === 'medical') {
      updatedTestDetails[index].medicalRemark = newValue;
    }
    setTestDetails(updatedTestDetails);
  };

  const handlesamplesave = () => {
    // Check if every testDetail item has a status and medicalRemark (if needed)
    const isEveryTestValidated = testDetails.every((detail) => detail.status !== '' && detail.medicalRemark !== '');
  
    if (!isEveryTestValidated) {
      alert('Please ensure every test has a status selected and required remarks filled.');
      return; // Stop the function from proceeding further
    }
  
    const postdata = testDetails.map((detail) => ({
      ...detail,
      Billing_invoice: capturedatas.Billing_Invoice,
      patientid: capturedatas.Patient_Id,
      patientname: capturedatas.Patient_Name
    }));
  
    console.log(postdata);
  
    axios.post(`${urllink}Phelobotomist/insersamplecapture`, postdata)
      .then((response) => {
        console.log(response.data);
        navigate('/Home/CaptureSamplelist');
      })
      .catch((error) => {
        console.log(error);
      });
  
    axios.post(`${urllink}Phelobotomist/updatesamplestatus`, {
      Billinginvoice: capturedatas.Billing_Invoice
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const handletrendview =(testname)=>{
    axios.get(`${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`)
    .then((response)=>{
      console.log(response.data)
      const value = response.data.data
      const visit =response.data.visit
      setvalue(value)
      setvisitid(visit)
      setOpenModel(true)
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  return (
    <>
<div className="new-patient-registration-form">
<div className="ShiftClosing_header">
            <h3>Sample Entry Form</h3>
          </div>
{/* <div className="dctr_info_up_head">
  <div className="RegisFormcon ">
    <div className="dctr_info_up_head22">
      <img src={workbenchformData.PatientPhoto || profileimg} alt="Patient Photo" />
      <label>Profile</label>
    </div>
  </div>

  <div className="RegisFormcon">
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Patient Name <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
      </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Patient ID <span>:</span>
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.PatientID}{" "}
      </span>
    </div> */}
    {/* <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Date of birth <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
      </span>
    </div> */}
    {/* <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Age <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Age}{" "} */}
      {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName"> */}
        {/* Gender <span>:</span>{" "} */}
      {/* </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Gender}{" "} */}
      {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Primary Doctor <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.DoctorName}{" "} */}
      {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Location <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Location}{" "} */}
      {/* </span>
    </div>
  </div> */}
{/* </div>  */}

<div className="patientdetails_lab">
          <div className='patientdata'>
            
            <label className='phelobotomist_head'><span className='logo_profile'><CgProfile /></span>Patient Name<span>:</span></label>
            <span>{capturedatas.Patient_Name}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><SlCalender /></span>Date  <span>:</span></label>
            <span>{capturedatas.UpdatedAt}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><MdPhonelinkRing /></span>Phone <span>:</span></label>
            <span>{capturedatas.Phone}</span>
          </div>

          <div className='patientdata'>
            <Barcode value={capturedatas.Barcode || ''} lineColor="black" height={50} width={1.5} />
          </div>
        </div>

</div>

        <div className="samplecapture_table_for_phelobotomist">
          <table className="table_for_plebotomist_sample ">
            <thead>
              <tr>
                <th>Test Name</th>
                <th>Test Code</th>
                <th>Containers</th>
                <th>Specimens</th>
                <th>Capture / Unit</th>
                <th>Status</th>
                <th>Remarks
                  <table className='remarktable'>
                    <tbody>
                    <tr>
                      <th>Technical</th>
                      <th>Medical</th>
                    </tr>
                    </tbody>
                  </table>
                </th>
                <th>Graph</th>
              </tr>
            </thead>
            <tbody>
              {testDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.testname}</td>
                  <td>{detail.testcode}</td>
                  <td>{detail.Container_Name}<GiDrippingTube/></td>
                  <td>{detail.Specimen_Name}</td>
                  <td>{detail.Captured_Unit}{detail.uom}</td>
                  <td>
                    <select
                      name="status"
                      id="status"
                      className='Capture_Status_select'
                      value={detail.status}
                      onChange={(e) => handleStatusChange(index, e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Completed">Collected</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </td>
                  <td>
                    <table className='remarktable'>
                     <tbody>
                     <tr>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            className='table_textarea'
                            value={detail.technicalRemark}
                            onChange={(e) => handleRemarksChange(index, e.target.value, 'technical')}
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            className='table_textarea'
                            value={detail.medicalRemark}
                            onChange={(e) => handleRemarksChange(index, e.target.value, 'medical')}
                          ></textarea>
                        </td>
                      </tr>
                     </tbody>
                    </table>
                  </td>
                  <td>
                    <span className='trends' onClick={()=>handletrendview(detail.testname)}><TbDelta/></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handlesamplesave}>
            Save
          </button>
        </div>
   

      {openModel && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModel(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                <div className="">
                <LineChart
      xAxis={[{ data: [...value] }]}
      xAxisLabel= 'Value'
      yAxisLabel = 'Visits'
      series={[
        {
          data: [...visitid],
        },
      ]}
      width={500}
      height={300}
    />
                </div>
       
                <div className="Register_btn_con regster_btn_contsai">
                
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModel(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
    </>
  );
}

export default Navigationple;
 