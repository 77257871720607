import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import Barcode from "react-barcode";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { toPng } from 'html-to-image';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ImCancelCircle } from "react-icons/im";
const theme = createTheme({
  components: { 
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const IpNurseDrugRequest = () => {

  const [issueddrugs, setissueddrugs] = useState([]);
  const [isChecked , setisChecked] = useState(false);
  const [ClinicDetails, setClinicDetails] = useState({});
  const [selectedVital, setSelectedVital] = useState([]);
  const [Savedata, setsavedata] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [clinicLogo, setClinicLogo] = useState(null);
  const [doctorsign, setDoctorSign] = useState(null);
  const [isprintopen, setisprintopen] = useState(true);
  const [totalquantity, settotalquantity] = useState(0);
  const [requesttype, setrequesttype] = useState('Inhouse')
  const [issuedquantity, setissuedquatity] = useState(0);
  const [prescriptioncode, setprescriptioncode] = useState('')
  const [dummy, setdummy] = useState([])
  const [page, setPage]  = useState(0);
  const [pagetype ,setpagetype] = useState('Request');
  const [ordertype , setordertype] = useState('');
  const [selectedDate, setselectedDate] = useState('')
  const pageSize = 10;
  const showdown = issueddrugs.length;
  const totalPages = Math.ceil(issueddrugs.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const [pdfBlob, setPdfBlob] = useState(null);
  const [preview, setpreview] = useState(false);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);


  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const userRecord = useSelector(
        (state)=> state.userRecord?.UserData
    )

    const IpNurseQueSelectedRow = useSelector(
        (state) => state.InPatients?.IpNurseQueSelectedRow
      );
      console.log('IpNurseQueSelectedRow................', IpNurseQueSelectedRow)
    

      useEffect(() => {
        axios.get(`${UrlLink}usercontrol/getClinic?location=${userRecord?.location}`)
          // console.log(response.data)
          .then(response => {
            const record = response.data[0];
            console.log(response)
            setClinicDetails(record);
    
          })
          .catch(error => {
            console.error('Error:', error);
    
          });
    
        axios
          .get(`${UrlLink}usercontrol/getAccountsetting`)
          .then((response) => {
            console.log(response.data)
            if (response.data) {
              const firstClinic = response.data;
              setClinicLogo(`data:image/png;base64,${firstClinic.Clinic_Logo}`);
            } else {
              console.error('No record found');
            }
          })
          .catch((error) => console.error('Error fetching data'));
      }, []);
    useEffect(()=>{

       if(pagetype === 'Request') {
        axios.get(`${UrlLink}ipregistration/get_for_doc_drugs_nurse_request?Bookingid=${IpNurseQueSelectedRow?.BookingId}&Location=${userRecord?.location}`)
        .then((response)=>{
            console.log(response)
            setissueddrugs(
              response.data.map((item, index)=>({
                ...item,
                id : index + 1
              }))
            )
        })
        .catch((error)=>{
            console.log(error)
        })}
        else if (pagetype === 'Approve'){
          axios.get(`${UrlLink}ipregistration/get_completed_prescribed_medicine?location=${userRecord?.location}`)
          .then((response)=>{
              console.log(response)
              setissueddrugs(
                response.data.map((item, index)=>({
                  ...item,
                  id : index + 1
                }))
              )
          })
          .catch((error)=>{
              console.log(error) 
          })
        }
    },[isChecked, pagetype,IpNurseQueSelectedRow?.BookingId])

    useEffect(() => {
      const currentDate = new Date().toISOString().split('T')[0];
      // setAppointmentDate(currentDate);
      setselectedDate(new Date(), 'Asia/Kolkata');
    }, []);
  
    const currdate = selectedDate && format(selectedDate, ' dd / MM / yy');

    useEffect(()=>{
      axios.get(`${UrlLink}ipregistration/get_drug_for_pharmacy_billing`)
      .then((response)=>{
        console.log(response)
        const data = response.data
        console.log(data)

        setPrescriptionData(data)
      })
      .catch((error)=>{
        console.log(error)
      })
    },[]);
    
    const formatLabel = (label) => {
      // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
      if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
        return label
          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
          .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
      } else {
        return label;
      }
    };
  
    function getTextWidth(text) {
      // Create a dummy element to measure text width
      const dummyElement = document.createElement("span");
      dummyElement.textContent = text;
      dummyElement.style.visibility = "hidden";
      dummyElement.style.whiteSpace = "nowrap";
      document.body.appendChild(dummyElement);
  
      // Get the width of the text
      const width = dummyElement.offsetWidth;
  
      // Remove the dummy element
      document.body.removeChild(dummyElement);
  
      return width;
    }
    // Define the columns dynamically

    const handleStatusChange = (row) => {
      console.log('row:', row);
    
      axios.post(`${UrlLink}ipregistration/insert_nurse_received_drugs`, row)
        .then((response) => {
          console.log('Response:', response.data);
         
          setisChecked((prev) => !prev);
        })
        .catch((error) => {
          console.error('Error:', error);
          
        });
    };
    const handleStatusChange1=(row)=>{
      console.log('row.......................', row)

    }

    const dynamicColumns = () => {
      if (pagetype === 'Request') {
        return [
          { field: "id", headerName: "S No", width: 100 },
          ...['BookingId', "PrescibtionId", "Department", "DoctorName", "GenericName", "MedicineCode", "MedicineName", "Dosage", "Route", "Quantity"].map((labelname, index) => {
            const formattedLabel = formatLabel(labelname);
            const labelWidth = getTextWidth(formattedLabel);
    
            return {
              field: labelname,
              headerName: formattedLabel,
              width: ["Instruction"].find((f) => f === labelname) ? labelWidth + 100 : labelWidth + 30,
              valueGetter: (params) => {
                const value = params.row[labelname];
                return value ? value : "-";
              },
            };
          }),
          {
            field: "Action",
            headerName: "View",
            width: 120,
            renderCell: (params) => (
              <>
                <Button className="cell_btn" onClick={() => handleChange(params.row)}>
                  <EditIcon />
                </Button>
              </>
            ),
          },
        ];
      } else {
        return [
          { field: "id", headerName: "S No", width: 100 },
          ...['BookingId', "PrescriptionId", "Department", "DoctorName", "GenericName", "MedicineCode", "MedicineName", "Dosage", "Route", "Quantity", "RequestQuantity"].map((labelname, index) => {
            const formattedLabel = formatLabel(labelname);
            const labelWidth = getTextWidth(formattedLabel);
    
            return {
              field: labelname,
              headerName: formattedLabel,
              width: ["Instruction"].find((f) => f === labelname) ? labelWidth + 100 : labelWidth + 30,
              valueGetter: (params) => {
                const value = params.row[labelname];
                return value ? value : "-";
              },
            };
          }),
          {
            field: "Action",
            headerName: "View",
            width: 120,
            renderCell: (params) => (
              <>
                <Button className="cell_btn" onClick={() => handleStatusChange(params.row)}>
                  <CheckCircleOutlineIcon />
                </Button>
              </>
            ),
          },
          {
            field: "Actions",
            headerName: "Cancel",
            width: 120,
            renderCell: (params) => (
              <>
                <Button className="cell_btn" onClick={() => handleStatusChange1(params.row)}>
                  <ImCancelCircle />
                </Button>
              </>
            ),
          },
        ];
      }
    };
    
   
    const handleChange = (row) => {
      console.log('row', row);
      setisChecked(true);
      settotalquantity(row?.Quantity);
      setdummy(([
        row
      ]))      
    };

    console.log('dummy', dummy)
    useEffect(() => {
      let newIssuedQuantity = 0;
      if (ordertype === 'Daily') {
        const Frequency = dummy[0]?.FrequencyTime.split(',');
        const Frequencyitem = Frequency.length;
        newIssuedQuantity = Frequencyitem;
      } else if (ordertype === 'Total') {
        newIssuedQuantity = totalquantity;
      }

     const now = new Date()
     const formattedTime = format(now, "HH:mm:ss");
     const newdate = format(new Date(), "yyyy-MM-dd");
     
      setissuedquatity(newIssuedQuantity);
    
      setdummy(prev => ({
        ...prev,
        totalquantity: totalquantity,
        issuedquantity: newIssuedQuantity,
        ordertype: ordertype,
        requesttype: requesttype,
        requesttime: `${newdate},${formattedTime}`
      }));
    }, [ordertype, totalquantity]);
    

    const handlenurserequestdrug = ()=>{
      const postdata = {
        ...dummy
      }

      if(issuedquantity > 0){

      axios.post(`${UrlLink}ipregistration/insert_nurse_request_drugs`,
    postdata)
    .then((response)=>{
      console.log(response)
      setisChecked(false)
      setissuedquatity('')
      settotalquantity('')
      setordertype('')
      if (dummy.length === 0){
        setisprintopen(true)
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }else{
    alert("Please Enter Request Quantity")
  }

    };

    useEffect(()=>{
      axios.get(`${UrlLink}ipregistration/get_barcode_for_inhouse_pharmacy`)
      .then((response)=>{
        console.log(response.data)
        const data = response.data
        setprescriptioncode(data)
      })
      .catch((error)=>{
        console.log(error)
      })
    },[])


    const handlePrint = useReactToPrint({
      content: () => document.getElementById('reactprintcontentprescrib'),
      onBeforePrint: () => {
        console.log('Before');
      },
      onAfterPrint: async () => {
        setPdfBlob(null);
        console.log('After');
        const printdata = document.getElementById('reactprintcontentprescrib');
        console.log('printdata', printdata);
  
        try {
  
          if (printdata) {
            const contentWidth = printdata.offsetWidth;
            const padding = 20; 
            const pdfWidth = contentWidth + 2 * padding;
            const pdfHeight = contentWidth * 1.5;
            const pdf = new jsPDF({
              unit: 'px',
              format: [pdfWidth, pdfHeight],
            });
            const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));
          
           
            const barcodeImage1 = await new Promise((resolve, reject) => {
              const barcodeImg1 = new Image();
              barcodeImg1.onload = () => resolve(barcodeImg1);
              barcodeImg1.onerror = reject;
              barcodeImg1.src = barcodeImagecon1;
            });
          
    
            // Append barcode image to the .Register_btn_con_barcode element
            const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
            barcodeContainer1.innerHTML = ''; // Clear previous content
            barcodeContainer1.appendChild(barcodeImage1);
            // Append barcode image to the .Register_btn_con_barcode element
            pdf.html(printdata, {
              x: padding, // Set x-coordinate for content
              y: padding, // Set y-coordinate for content
              callback: () => {
                const generatedPdfBlob = pdf.output('blob');
                setPdfBlob(generatedPdfBlob);
                console.log('generatedPdfBlob', generatedPdfBlob)
                // setIsPrintButtonVisible(true)
              },
  
            });
          } else {
            throw new Error('Unable to get the target element');
          }
          setpreview(false)
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      }
    });


    console.log('prescriptionData,hdudfhiordoigtrfiotgrouigouierhdujredhyolh', prescriptionData)
    const forprintdata = () =>{

      return(
        <div className="billing-invoice" id='reactprintcontentprescrib'>
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={clinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            {/* <span>{ClinicDetails.concern_name}</span> */}
            <div>
                  <span >{ClinicDetails.door_no},</span>
                  </div>
            <div>
              <span >{ClinicDetails?.door_no + ',' + ClinicDetails.street + ',' + ClinicDetails.area + ',' + ClinicDetails.city + ',' + ClinicDetails.state + '-' + ClinicDetails.pincode}</span>
            </div>
            <div>
              <span>{ClinicDetails.phone_no + ' , '}</span>
              <span>{ClinicDetails.ClinicLandLineNo + ' , '}</span>
              <span>{ClinicDetails.email}</span>
            </div>
  
          </div>
  
        </div>
        <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
          Patient Preview
        </div>
  
        <div className="new_billing_address">
        <div className='Register_btn_con_barcode_prescrib' id="get_imagecontent_1">
          <Barcode value={ prescriptioncode ||''} lineColor="black" height={40} width={0.9} fontSize={12} />
        </div>
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>Patient Name <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.PatientName}</span>
            </div>
            <div className="new_billing_div">
              <label>Patient ID <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.PatientId}</span>
            </div>
            <div className="new_billing_div">
              <label>Age <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.Age}</span>
            </div>
            <div className="new_billing_div">
              <label>Gender <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.Gender}</span>
            </div>
            {/* <div className="new_billing_div">
                      <label>Next Appointment <span>:</span></label>
                      <span>{selectedVital[2]?.Next_Appointment}</span>
                  </div> */}
  
          </div>
          <div className="new_billing_address_2">
            
            <div className="new_billing_div">
              <label>Date <span>:</span></label>
              <span>{currdate}</span>
            </div>
            <div className="new_billing_div">
              <label>Room No <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.RoomNo} /{IpNurseQueSelectedRow?.RoomType}-{IpNurseQueSelectedRow?.WardName}</span>
            </div>
  
            {/* <div className="new_billing_div">
                      <label>Date <span>:</span></label>
                      <span>{Billing_date}</span>
                  </div> */}
            <div className="new_billing_div">
              <label> Attender No <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.AttenderMobileNo}</span>
            </div>
            <div className="new_billing_div">
              <label> Patient No <span>:</span></label>
              <span>{IpNurseQueSelectedRow?.PatientPhoneNo}</span>
            </div>
          </div>
        </div>
  
        
        <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
          Prescription
        </div>
        <div className="new_billing_invoice_detials summa_table_for_priscribtion">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Generic Name</th>
                <th>Item Name</th>
                {/* <th>Medicine</th> */}
                <th>Dose</th>
                <th>Route</th>
                {/* <th>Frequency</th> */}
                {/* <th>Duration</th> */}
                <th>Qty</th>
                {/* <th>Instruction</th> */}
              </tr>
            </thead>
            <tbody>
  
              {prescriptionData.map((medicine, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{medicine.GenericName}</td>
                    <td>{medicine.MedicineName}</td>
                    {/* <td>{medicine.Medicine}</td> */}
                    <td>{medicine.Dosage}</td>
                    <td>{medicine.Route}</td>
                    {/* <td>{medicine.Frequency}</td> */}
                    {/* <td>{medicine.Duration}</td> */}
                    <td>{medicine.RequestQuantity}</td>
                    {/* <td>{medicine.Instruction}</td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
  
  
        <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: '70px' }}>
  
          <div className="invoice_detials_total_1 neww_invoicedetials">
            
            <div className="total_con_bill">
              <div className="bill_body">
                <label>Doctor Name <span>:</span></label>
                <span>{IpNurseQueSelectedRow?.PrimaryDoctor}</span>
              </div>
              <div className="bill_body">
                <label> Doctor sign <span>:</span></label>
                <img src={doctorsign} alt="sign"
                  style={{ width: '90px', height: '40px', marginLeft: '50px' }}
                />
              </div>
              {/* <div className="bill_body">
                          <label>Net Amount <span>:</span></label>
                          <span>{initialState.totalNetAmount}</span>
                      </div> */}
  
            </div>
          </div>
         
  
        </div>
        

          

      </div>
      )
    }


    
  return (
    <>
      <div className="RegisterTypecon">
                    <div className="RegisterType">
                        {["Request", "Approve"].map((p, ind) => (
                            <div className="registertypeval" key={ind}>
                                <input
                                    type="radio"
                                    id={p}
                                    name="pagetype"
                                    checked={pagetype === p}
                                    onChange={(e) => setpagetype(e.target.value)}
                                    value={p}
                                />
                                <label htmlFor={p}>
                                    {p}
                                </label>
                            </div>
                        ))}
                    </div>
      </div>
          {isprintopen ?
          
            <div className="Supplier_Master_Container">
            
    <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid

                rows={issueddrugs.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && issueddrugs.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && issueddrugs.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {isChecked && pagetype === 'Request'?(
          <>
       <div className="Total_input_container" style={{ width: "70%" }}>
       <div className="inp_container_all_intakeoutput">
                    <label>
                    Order Type <span>:</span>
                    </label>
                    <select
                    name="RequestType"
                    value={requesttype}
                    onChange={((e)=>setrequesttype(e.target.value))}
                    >
                    {/* <option value="">Select</option> */}
                    <option value="Inhouse">In House</option>
                    <option value="Outsourced">Out Source</option>
                    <option value="Replacement">Replacement</option>
                  </select>
                  </div>
              
                  <div className="inp_container_all_intakeoutput">
                    <label>
                      Quantity <span>:</span>
                    </label>
                    <input
                      type="number"
                      onKeyDown={blockInvalidChar}
                      value={totalquantity}
                      readOnly
                      // onChange={(e) =>
                      //   setpostdataSOS((prev) => ({
                      //     ...prev,
                      //     Quantity: e.target.value,
                      //   }))
                      // }
                    />
                  </div>
                  
                  <div className="inp_container_all_intakeoutput">
                    <label>
                    Request Type <span>:</span>
                    </label>
                    <select
                    name="RequestType"
                    value={ordertype}
                    onChange={((e)=>setordertype(e.target.value))}
                    >
                    <option value="">Select</option>
                    <option value="Total">Total</option>
                    <option value="Daily">Daily</option>
                  </select>

                    
                  </div>
                  <div className="inp_container_all_intakeoutput">
                    <label>
                    Request Quantity <span>:</span>
                    </label>
                    <input
                      type="number"
                      onKeyDown={blockInvalidChar}
                      value={issuedquantity}
                      readOnly
                      // onChange={(e) =>
                      //   setpostdataSOS((prev) => ({
                      //     ...prev,
                      //     Quantity: e.target.value,
                      //   }))
                      // }
                    />

                    
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    width: "100%",
                  }}
                >
                  <button className="btn-add" onClick={()=>handlenurserequestdrug()}>
                    Add
                  </button>
                </div>

 {/* <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid

                rows={dummy?.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={columnsforverify} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && dummy?.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && dummy?.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div> */}

        </>
        ):null}
      </div> : forprintdata() }
        {isprintopen?null:<button onClick={handlePrint} className="print_button" >
            Print
          </button>}


    </>
  )
}

export default IpNurseDrugRequest