import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';


// import EditIcon from '@mui/icons-material/Edit';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';



export default function InsuranceMaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceMasterEdit = useSelector(
    (state) => state.InsuranceStore?.InsuranceMasterEdit
  );

  const dispatchvalue = useDispatch()

  const navigate = useNavigate();

  const [SelectFormdata,setSelectFormdata]=useState('Client')

  const [INmasterFormData, setINmasterFormData] = useState({
      InsuranceCode:"",
      InsuranceName: "",
      Type: "",
      TPAName: "",
      PolicyType: "",
      PayerZone: "",
      PayerMemberId: "",
      ContactPerson: "",
      MailId: "",
      PhoneNumber: "",
      TreatmentList:null,
      OtherDocuments:null,
      Status:''
  });


  const [ClientmasterFormData, setClientmasterFormData] = useState({
    ClientCode:"",
    ClientName: "",
    ContactPerson: "",
    MailId: "",
    PhoneNumber: "",
    TreatmentList:null,
    OtherDocuments:null,
    Status:''
});

  const [isValid, setIsValid] = useState(true);


  // console.log('INmasterFormData',INmasterFormData)

  // const [TreatmentFilds,setTreatmentFilds]=useState({
  //   Treatment:'',
  //   Amount:''
  // })


  // const [TreatmentAmountlist,setTreatmentAmountlist]=useState([])

  // const[updateState,setupdateState]=useState(false)







  useEffect(() => {
    if(SelectFormdata !== ''){
      getInsuranceCode()   
    }
},[SelectFormdata]);


const base64toFile = (base64String, fileName, mimeType) => {
  if (!base64String) {
    console.error("base64String is undefined or null.");
    return null;
  }

  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const paddedBase64String = base64String + padding;

  try {
    const byteString = atob(paddedBase64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    return null;
  }
};


useEffect(()=>{

  if(Object.keys(InsuranceMasterEdit).length !==0 && InsuranceMasterEdit.SelectFormdata !==''){

  console.log('InsuranceMasterEdit',InsuranceMasterEdit)

  
  const capturedImage1 = base64toFile(
    InsuranceMasterEdit.DecoFile1,
    InsuranceMasterEdit.DecoFile1name,
    InsuranceMasterEdit.DecoFile1type
  );

  const capturedImage2 = base64toFile(
    InsuranceMasterEdit.DecoFile2,
    InsuranceMasterEdit.DecoFile2name,
    InsuranceMasterEdit.DecoFile2type
  );


  console.log(capturedImage1,capturedImage2)
  
  setSelectFormdata(InsuranceMasterEdit.SelectFormdata)

 if(InsuranceMasterEdit.SelectFormdata === 'Insurance'){
  setINmasterFormData((prev)=>({
    ...prev,
    InsuranceCode: InsuranceMasterEdit.InsuranceCode,
    InsuranceName: InsuranceMasterEdit.InsuranceName,
    Type: InsuranceMasterEdit.Type,
    TPAName: InsuranceMasterEdit.TPAName,
    PolicyType: InsuranceMasterEdit.PolicyType,
    PayerZone: InsuranceMasterEdit.PayerZone,
    PayerMemberId: InsuranceMasterEdit.PayerMemberId,
    ContactPerson: InsuranceMasterEdit.ContactPerson,
    MailId: InsuranceMasterEdit.MailId,
    PhoneNumber: InsuranceMasterEdit.PhoneNumber,
    TreatmentList:capturedImage1,
    OtherDocuments:capturedImage2,
    Status:InsuranceMasterEdit.Status
  }))
 }else{
  

  setClientmasterFormData((prev)=>({
    ...prev,
    ClientCode:InsuranceMasterEdit.ClientCode,
    ClientName:InsuranceMasterEdit.ClientName,
    ContactPerson:InsuranceMasterEdit.ContactPerson,
    MailId: InsuranceMasterEdit.MailId,
    PhoneNumber: InsuranceMasterEdit.PhoneNumber,
    TreatmentList:capturedImage1,
    OtherDocuments:capturedImage2,
    Status:InsuranceMasterEdit.Status,
  }))

 }



  }

},[InsuranceMasterEdit])

const getInsuranceCode =()=>{
  if(Object.keys(InsuranceMasterEdit).length === 0){
 
    if(SelectFormdata === 'Insurance'){
      axios
      .get(
        `https://vesoftometic.co.in/Insurance/get_InsuranceCode`
      )
      .then((response) => {
        console.log('ccc',response.data);
    
        const INdata=response.data.InsuranceCode
    
        setINmasterFormData((prev)=>({
          ...prev,
          InsuranceCode:INdata,
        }))
        
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      axios
      .get(
        `https://vesoftometic.co.in/Insurance/get_ClientCode`
      )
      .then((response) => {
        console.log('ccc',response.data);
    
        const INdata=response.data.ClientCode
    
        setClientmasterFormData((prev)=>({
          ...prev,
          ClientCode:INdata,
        }))
        
      })
      .catch((error) => {
        console.log(error);
      });
    }




    }

}

  
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const isValidEmail = emailPattern.test(email);
    setIsValid(isValidEmail);
  };

  
  const handleInputChange = (e) => {

    const { name, value, type } = e.target;
     console.log(name, value, type);
    
    if(name === 'TreatmentList' || name === 'OtherDocuments'){

      const selectedFile = e.target.files[0];

      setINmasterFormData((prev) => ({
        ...prev,
        [name]: selectedFile,
      }));
    }
    else if(name === "PhoneNumber"){

      console.log(value)

      const newval = value.length;
      if (newval <= 10) {
        setINmasterFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        alert("Phone Number must contain 10 digits");
      }

    }
    else if(name === "Type" && value === "MAIN"){
      
      setINmasterFormData((prev) => ({
          ...prev,
          [name]: value,
          TPAName:'Nill'
        }));
     
      validateEmail(value);
    } 
    else if(name === "MailId"){
      
      setINmasterFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
     
      validateEmail(value);
    }    
    else{

    const uppercaseValue = (name === "InsuranceName"  || name === "TPAName"  || name === "PolicyType" 
    || name === "PayerZone" || name === "PayerMemberId") ? value.toUpperCase() : value;

      setINmasterFormData((prev) => ({
        ...prev,
        [name]: uppercaseValue,
      }));
    }
  };


  // --------------clint

  
  const handleInputChangeClint = (e) => {

    const { name, value, type } = e.target;
     console.log(name, value, type);
    
    if(name === 'TreatmentList' || name === 'OtherDocuments'){

      const selectedFile = e.target.files[0];

      setClientmasterFormData((prev) => ({
        ...prev,
        [name]: selectedFile,
      }));
    }
    else if(name === "PhoneNumber"){

      console.log(value)

      const newval = value.length;
      if (newval <= 10) {
        setClientmasterFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        alert("Phone Number must contain 10 digits");
      }

    }
    else if(name === "MailId"){
      
      setClientmasterFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
     
      validateEmail(value);
    }    
    else{

    const uppercaseValue = (name === "ClientName"  || name === "TPAName"  || name === "PolicyType" 
    || name === "PayerZone" || name === "PayerMemberId") ? value.toUpperCase() : value;

      setClientmasterFormData((prev) => ({
        ...prev,
        [name]: uppercaseValue,
      }));
    }
  };



  // const ClearTabledata=()=>{
  //   setTreatmentFilds({
  //     Treatment:'',
  //     Amount:'',
  //   })
  // }


  // const handleTreatment=(e)=>{

  //   const { name, value, type } = e.target;
  //   console.log(name, value, type);

  //    setTreatmentFilds((prev)=>({
  //     ...prev,
  //     [name]:value,
  //    }))

  // }


  // const AddTRlist = () => {
  //   const requiredfields = ['Treatment', 'Amount'];
  
  //   const emptyFields = requiredfields.filter(field => !TreatmentFilds[field]);
  
  //   if (emptyFields.length > 0) {
  //     alert("Please fill empty fields: " + emptyFields.join(", "));
  //   } else {
  //     const CheckData = TreatmentAmountlist.some(ele => ele.Treatment.toLowerCase() === TreatmentFilds.Treatment.toLowerCase());
  //     console.log('CheckData',CheckData)
  //     if (CheckData) {
  //       alert('Treatment name already exists');
  //     } else {
  //       setTreatmentAmountlist(prevList => [
  //         ...prevList,
  //         { ...TreatmentFilds, Id: prevList.length + 1 }
  //       ]);
  
  //       ClearTabledata();
  //     }
  //   }
  // };

  


  // const handelEditTable=(id)=>{

  //   setupdateState(true)

  //   const newlist = TreatmentAmountlist.find((ele) => ele.Id === id);

  //   setTreatmentFilds((prev)=>({
  //     ...prev,
  //     Treatment: newlist.Treatment,
  //     Amount: newlist.Amount,
  //   }))

    
  // }

//   const handelRemoveTable = (id) => {
//     console.log(id);
//     const newlist = TreatmentAmountlist.filter((ele) => ele.Id !== id);
//     const updatedList = newlist.map((ele, index) => ({ ...ele, Id: index + 1 }));
//     setTreatmentAmountlist(updatedList);
    
// };  



// const UpdateTRlist = () => {
  

//   const newlist = TreatmentAmountlist.filter((ele) => ele.Treatment.toLowerCase() !== TreatmentFilds.Treatment.toLowerCase());


//   const updatedList = newlist.map((ele, index) => ({ ...ele, Id: index + 1 }));

//   setTreatmentAmountlist((prevList) => [
//     ...updatedList,
//     { ...TreatmentFilds, Id: updatedList.length + 1 }
//   ]);

//   ClearTabledata();
//   setupdateState(false);
// };





  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };

  const cleardata = () => {
    setINmasterFormData({
      InsuranceCode:"",
      InsuranceName: "",
      Type: "",
      TPAName: "",
      PolicyType: "",
      PayerZone: "",
      PayerMemberId: "",
      ContactPerson: "",
      MailId: "",
      PhoneNumber: "",
      TreatmentList:null,
      OtherDocuments:null,
      Status:''
    });
  };


  const clearClientdata = () => {
    setClientmasterFormData({
      ClientCode:"",
      ClientName: "",
      ContactPerson: "",
      MailId: "",
      PhoneNumber: "",
      TreatmentList:null,
      OtherDocuments:null,
      Status:''

    });
  };

  const Add_Insurance_Master_data = () => {

    if(SelectFormdata === 'Insurance'){

      const requiredfields = [
        'InsuranceCode',
        'InsuranceName',
        'Type',
        'TPAName',
        'PolicyType',
        'PayerZone',
        'PayerMemberId',
        'ContactPerson',
        'MailId',
        'PhoneNumber',
        'TreatmentList',
        'OtherDocuments',
        'Status'
      ];
    
      const emptyFields = requiredfields.filter((field) => !INmasterFormData[field]);
    
      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {
  
  
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  
      // console.log(INmasterFormData.MailId)
  
      const cond = regex.test(INmasterFormData.MailId)
  
      // console.log(cond)   
  
      if(!cond){
        alert("This is not a valid email format!")
      }
      else{
  
  
  
        const INdata={
          ...INmasterFormData,    
          Location:userRecord.location,
          CapturedBy:userRecord.username,     
        }
  
        console.log(INdata)
  
      const formData = new FormData();
  
      for (const key in INdata) {
        if (INdata.hasOwnProperty(key)) {
          const value = INdata[key];
          if (typeof value === 'object' && value !== null && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      }
  
  
  
        axios
          .post(
            `https://vesoftometic.co.in/Insurance/insert_Insurance_Master_datas`,
            formData 
          )
          .then((response) => {
            console.log(response);
            cleardata();
            getInsuranceCode()   
          })
          .catch((error) => {
            console.error("Error adding insurance master data:", error);
          });
      }
  
      }
  

    }
    else if(SelectFormdata === 'Client'){

      const requiredfields = [
        'ClientCode',
        'ClientName',
        'ContactPerson',
        'MailId',
        'PhoneNumber',
        'TreatmentList',
        'OtherDocuments',
        'Status'
      ];
    
      const emptyFields = requiredfields.filter((field) => !ClientmasterFormData[field]);
    
      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {
  
  
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  
      // console.log(ClientmasterFormData.MailId)
  
      const cond = regex.test(ClientmasterFormData.MailId)
  
      // console.log(cond)   
  
      if(!cond){
        alert("This is not a valid email format!")
      }
      else{
  
        const INdata={
          ...ClientmasterFormData,    
          Location:userRecord.location,
          CapturedBy:userRecord.username,     
        }
  
        console.log(INdata)
  
      const formData = new FormData();
  
      for (const key in INdata) {
        if (INdata.hasOwnProperty(key)) {
          const value = INdata[key];
          if (typeof value === 'object' && value !== null && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      }
  
  
  
        axios
          .post(
            `https://vesoftometic.co.in/Insurance/insert_Client_Master_datas`,
            formData 
          )
          .then((response) => {
            console.log(response);
            clearClientdata();
            getInsuranceCode()   
          })
          .catch((error) => {
            console.error("Error adding Client master data:", error);
          });
      }
  
      }

    }

  
      
  };



  const Update_Insurance_Master_data = () => {


    if(SelectFormdata === 'Insurance'){
    const requiredfields = [
      'InsuranceCode',
      'InsuranceName',
      'Type',
      'TPAName',
      'PolicyType',
      'PayerZone',
      'PayerMemberId',
      'ContactPerson',
      'MailId',
      'PhoneNumber',
      'TreatmentList',
      'OtherDocuments',
      'Status'
    ];
  
    const emptyFields = requiredfields.filter((field) => !INmasterFormData[field]);
  
    if (emptyFields.length > 0) {
      alert("Please fill empty fields: " + emptyFields.join(", "));
    } else {


    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // console.log(INmasterFormData.MailId)

    const cond = regex.test(INmasterFormData.MailId)

    // console.log(cond)   

    if(!cond){
      alert("This is not a valid email format!")
    }
    else{



      const INdata={
        ...INmasterFormData,    
        Location:userRecord.location,
        CapturedBy:userRecord.username,     
      }

      console.log(INdata)

    const formData = new FormData();

    for (const key in INdata) {
      if (INdata.hasOwnProperty(key)) {
        const value = INdata[key];
        if (typeof value === 'object' && value !== null && !(value instanceof File)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }



      axios
        .post(
          `https://vesoftometic.co.in/Insurance/Update_Insurance_Master_datas`,
          formData 
        )
        .then((response) => {
          console.log(response);
          cleardata();
          getInsuranceCode()   
          dispatchvalue({ type:'InsuranceMaster', value: {} })
          navigate('/Home/InsuranceMasterList')


        })
        .catch((error) => {
          console.error("Error adding insurance master data:", error);
        });
    }

    }}
    else if(SelectFormdata === 'Client'){

      const requiredfields = [
        'ClientCode',
        'ClientName',
        'ContactPerson',
        'MailId',
        'PhoneNumber',
        'TreatmentList',
        'OtherDocuments',
        'Status'
      ];
    
      const emptyFields = requiredfields.filter((field) => !ClientmasterFormData[field]);
    
      if (emptyFields.length > 0) {
        alert("Please fill empty fields: " + emptyFields.join(", "));
      } else {
  
  
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  
      // console.log(ClientmasterFormData.MailId)
  
      const cond = regex.test(ClientmasterFormData.MailId)
  
      // console.log(cond)   
  
      if(!cond){
        alert("This is not a valid email format!")
      }
      else{
  
        const INdata={
          ...ClientmasterFormData,    
          Location:userRecord.location,
          CapturedBy:userRecord.username,     
        }
  
        console.log(INdata)
  
      const formData = new FormData();
  
      for (const key in INdata) {
        if (INdata.hasOwnProperty(key)) {
          const value = INdata[key];
          if (typeof value === 'object' && value !== null && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      }
  
  
  
        axios
          .post(
            `https://vesoftometic.co.in/Insurance/Update_Client_Master_datas`,
            formData 
          )
          .then((response) => {
            console.log(response);
            clearClientdata();
            getInsuranceCode()   
            dispatchvalue({ type:'InsuranceMaster', value: {} })
            navigate('/Home/InsuranceMasterList')
          })
          .catch((error) => {
            console.error("Error adding Client master data:", error);
          });
      }
  
      }

    }


      
  };
  
  
  return (
    <>
      <div className="Stoct_Product_master">
        <div className="Stoct_Product_master_head">
          <h3 className="Stoct_Product_master_head_h3">Insurance / Client Master</h3>
        </div>
      </div>

      <div className="Supplier_Master_Container">



        <div className="Total_input_container">

        <div className="inp_container_all_intakeoutput">
        <label >
            Type <span>:</span>
        </label>
        <select
          name='SelectFormdata'
          value={SelectFormdata}
          onChange={(e)=>setSelectFormdata(e.target.value)}
        >
          <option value="">Select</option>
          <option value="Insurance">Insurance</option>
          <option value="Client">Client</option>
        </select>
        </div>
        {SelectFormdata === 'Insurance' ?
          Object.keys(INmasterFormData).map((labelname, index) => (
                  <div className="inp_container_all_intakeoutput" key={index}>
                  <label>
                    {labelname === 'PlanOrPolicyName' ? 'Plan/Policy Name' :labelname === 'Type' ?'Insurance Type' : formatLabel(labelname)} <span>:</span>
                  </label>
                  {labelname === "Type" ? (
                    <select
                    name={labelname}
                    value={INmasterFormData[labelname]}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="TPA">TPA</option>
                    <option value="MAIN">Main</option>
                  </select>
                ) : labelname === "Status" ? (
                  <select
                  name={labelname}
                  value={INmasterFormData[labelname]}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              ):(
                  <input
                    readOnly={labelname === "InsuranceCode"}
                    disabled={labelname === "TPAName" && INmasterFormData.Type === "MAIN"}
                    style={{
                      color:
                        labelname === "MailId"
                          ? isValid
                            ? "initial"
                            : "red"
                          : "",
                    }}
                    type={
                      labelname === "ValidFrom" || labelname === "ValidUpto"
                        ? "date"
                        : labelname === "MailId"
                        ? "email"
                        : labelname === "TreatmentList" || labelname === 'OtherDocuments' ? "file" : "text"
                    }
                    accept={labelname === 'TreatmentList' || labelname === 'OtherDocuments' ? ".pdf, image/*" : ""}
                    name={labelname}
                    value={labelname === 'TreatmentList' || labelname === 'OtherDocuments' ? "" : INmasterFormData[labelname]}
                    onChange={handleInputChange}
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />
                )}
              </div>
            ))
          :
          Object.keys(ClientmasterFormData).map((labelname, index) => (
            <div className="inp_container_all_intakeoutput" key={index}>
              <label>
                {labelname === 'PlanOrPolicyName' ? 'Plan/Policy Name' : formatLabel(labelname)} <span>:</span>
              </label>
              {
                 labelname === "Status" ? (
                  <select
                  name={labelname}
                  value={ClientmasterFormData[labelname]}
                  onChange={handleInputChangeClint}
                >
                  <option value="">Select</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              ) : 
                
                <input
                readOnly={labelname === "ClientCode"}
                style={{
                  color:
                    labelname === "MailId"
                      ? isValid
                        ? "initial"
                        : "red"
                      : "",
                }}
                type={
                  labelname === "MailId"
                    ? "email"
                    : labelname === "TreatmentList" || labelname === 'OtherDocuments' ? "file" : "text"
                }
                accept={labelname === 'TreatmentList' || labelname === 'OtherDocuments' ? ".pdf, image/*" : ""}
                name={labelname}
                value={labelname === 'TreatmentList' || labelname === 'OtherDocuments' ? "" : ClientmasterFormData[labelname]}
                onChange={handleInputChangeClint}
                className="Stoct_Product_master_form_Input"
                autoComplete="off"
              />}
            </div>
          ))} 

        </div>
        

        

      </div>

      

      <br/>

      {/* <h5 style={{textAlign:'center'}}>Treatment List</h5>
      <div className="con_1 ">
        {Object.keys(TreatmentFilds).map((LapN,Ind)=>(
          <div className="inp_container_all_intakeoutput" key={Ind}>
            <label>
            {formatLabel(LapN)}<span>:</span>
            </label>
            <input
              name={LapN}
              value={TreatmentFilds[LapN]}
              type={LapN === 'Treatment' ? 'text' :'number'}
              className="Stoct_Product_master_form_Input" 
              onChange={handleTreatment}            
            />
          </div>))}
         <button className="Addnamebtn" onClick={updateState?UpdateTRlist:AddTRlist}>
             +
         </button>
      </div> */}


     

               {/* <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container " style={{width:'900px'}}>
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Treatment</th>
                        <th >Amount</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TreatmentAmountlist.map((client, index) => (
                        <tr key={index}>
                          <td>{client.Id}</td>
                          <td>{client.Treatment}</td>
                          <td>{client.Amount}</td>
                          <td style={{display:'flex',justifyContent:'space-evenly'}}>
                            <button className='Addnamebtn_pt2' onClick={()=>handelEditTable(client.Id)}>
                                <EditIcon />
                              </button>
                            <button className='Addnamebtn_pt2' onClick={()=>handelRemoveTable(client.Id)}>
                                 <DeleteForeverIcon />
                              </button>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

      </div>  */}
        
        <br/>
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
        {Object.keys(InsuranceMasterEdit).length !==0 ?<button className='btn-add' onClick={Update_Insurance_Master_data} >
            Update
          </button> :<button className='btn-add' onClick={Add_Insurance_Master_data} >
            Add
          </button>}
        </div>
    </>
  );
}
