import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import '../../Stock/Neww.css'
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';




const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "var(--ProjectColor)",
                    textAlign: 'Center',
                },
                root: {
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
                        textAlign: 'center',
                        display: 'flex !important',
                        justifyContent: 'center !important'
                    },
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  var(--ProjectColor) !important",
                    display: 'flex',
                    justifyContent: 'center'
                },
            },
        },
    },
});
const IpBillingList = () => {


    const userRecord = useSelector(state => state.userRecord?.UserData)




    const dispatchvalue = useDispatch()
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([])


    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handleRequestEdit = (params) => {
        const Data = summa.find((p) => p.id === params.id)
        dispatchvalue({ type: 'IpBillingData', value: Data })
        navigate('/Home/IP_Billing')

    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    useEffect(() => {
        const location = userRecord.location
        const datas = [
            { id: 'BLO24000001', PatientName: 'Vishva S M', MRNNumber: '1', Location: 'CHENNAI', PrimaryDoctor: 'Ram Moorthy', PhoneNumber: '8685744767', Age: '33', Gender: 'Male', City: 'Chennai', State: 'TamilNadu', Street: 'Vadapalani', Pincode: '6000012',Discount:'10'},
            { id: 'BLO24000002', PatientName: 'Ananya Gupta', MRNNumber: '2', Location: 'DELHI', PrimaryDoctor: 'Amit Kumar', PhoneNumber: '9812345678', Age: '28', Gender: 'Female', City: 'New Delhi', State: 'Delhi', Street: 'Connaught Place', Pincode: '110001',Discount:'12'},
            { id: 'BLO24000003', PatientName: 'Rajesh Patel', MRNNumber: '3', Location: 'CHENNAI', PrimaryDoctor: 'Priya Sharma', PhoneNumber: '9967554433', Age: '45', Gender: 'Male', City: 'Mumbai', State: 'Maharashtra', Street: 'Bandra', Pincode: '400050',Discount:'13'},
            { id: 'BLO24000004', PatientName: 'Divya Singh', MRNNumber: '4', Location: 'CHENNAI', PrimaryDoctor: 'Rahul Jain', PhoneNumber: '8055667788', Age: '35', Gender: 'Female', City: 'Bangalore', State: 'Karnataka', Street: 'Indiranagar', Pincode: '560038',Discount:'10'},
            { id: 'BLO24000005', PatientName: 'Amit Khanna', MRNNumber: '5', Location: 'CHENNAI', PrimaryDoctor: 'Neha Reddy', PhoneNumber: '8976543210', Age: '50', Gender: 'Male', City: 'Hyderabad', State: 'Telangana', Street: 'Gachibowli', Pincode: '500032',Discount:'10'},
            { id: 'BLO24000006', PatientName: 'Pooja Sharma', MRNNumber: '6', Location: 'CHENNAI', PrimaryDoctor: 'Ravi Verma', PhoneNumber: '9112233445', Age: '30', Gender: 'Female', City: 'Jaipur', State: 'Rajasthan', Street: 'Vaishali Nagar', Pincode: '302021',Discount:'10'}
        ]
        setsumma(datas.filter((p) => p.Location === location))
    }, [userRecord.location]);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.PatientName.toLowerCase();

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery, summa]);





    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'id', headerName: 'Patient Id', width: 200 },
        { field: 'PatientName', headerName: 'Patient Name', width: 200 },
        { field: 'MRNNumber', headerName: 'MRN Number', width: 150 },
        { field: 'PrimaryDoctor', headerName: 'Primary Doctor', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <VisibilityIcon />
                    </Button>
                </>
            ),
        },
    ];




    return (
        <>
            <Helmet>
                <title> List</title>
            </Helmet>
            <div className="appointment">
                <div className="h_head">
                    <h4>IP Billing List</h4>
                </div>
                <div className="con_1 ">

                    <div className="inp_1">
                        <label htmlFor="input">Patient Name <span>:</span></label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Item Name' />
                    </div>
                    <button className='btn_1' type='submit'>
                        <SearchIcon />
                    </button>
                    {/* <button className='btn_1' type='submit' onClick={() => handleRequestForm()} title='Add New Stock'>
                            <LoupeIcon />
                        </button> */}
                </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <div className='grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className='data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default IpBillingList;
