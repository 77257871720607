import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import IcuNurseVitals from "./IcuNurseVitals";
import { useNavigate } from "react-router-dom";
import IcuIntakeOutput from "./IcuIntakeOutput";
import IcuDrugAdminister from "./IcuDrugAdminister";
import LabReport from "../../TreatmentRecords/LabReport";
import IcuLabTest from "./IcuLabTest";
import IcuLeninChange from "./IcuLeninChange";
import TheatreBooking from "../Operationtheatre/TheatreBooking";
import IcuWardChange from "./IcuWardChange";
import MedicalHistoryForm2 from "./IcuMedicalHistoryForm2";
import MedicalHistoryForm from "./IcuMedicalHistoryForm";
import IcuMedicalHistoryForm from "./IcuMedicalHistoryForm";
import VentilatorSettings from "./VentilatorSettings";
import BloodGas from "./BloodGas";
import BloodLines from "./BloodLines"
import DrainageTubes from "./DrainageTubes"
import UrinaryCathetor from "./UrinaryCathetor"
import SurgicalSite from "./SurgicalSite"
import BedsoreManagement from "./BedsoreManagement";
import BloodTransfusionRecord from "./BloodTransfusionRecord";
import PatientCare from "./PatientCare";
import Hemodynamics from "./hemodynamics";
import News from "./News";
import ProgressNotes from "./ProgressNotes";
import IcuTreatment from "./IcuTreatment";
import OtManagementForm from "./IpPreoperativeChecklist";


function IcuNurseWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("vitalForm");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/IcuNurseQuelist')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.Booking_Id}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("OtManagementForm")} style={{ color: activeTab === 'OtManagementForm' ? "white" : '' }}>
                            Ip Pre-Operative Checklist
                            </button>
                            |
                            <button onClick={() => handleTabChange("IcuTreatment")} style={{ color: activeTab === 'IcuTreatment' ? "white" : '' }}>
                            Treatment/Diagnosis
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                        Surgical History
                                    </button>
                            </div>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                        Lenin Change
                                    </button>
                                    <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                        OT Booking
                                    </button>
                                    <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                        Ward/Bed Change
                                    </button>
                                    {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                   
                                    <button onClick={() => handleTabChange("BloodGas")} style={{ color: activeTab === 'BloodGas' ? "white" : '' }}>
                                        Blood Gas
                                    </button>
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("VentilatorSettings")} style={{ color: activeTab === 'VentilatorSettings' ? "white" : '' }}>
                                        Ventilator Settings
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'IntensivePatientMonitoring' || activeTab === 'IntensivePatientMonitoring' ? "white" : '' }}>Intensive Patient Monitoring</button>
                                <div class="Lab_dropdown_content">
                                   
                                <button onClick={() => handleTabChange("BloodLines")} style={{ color: activeTab === 'BloodLines' ? "white" : '' }}>
                                        Blood Lines
                                </button>
                                
                                <button onClick={() => handleTabChange("DrainageTubes")} style={{ color: activeTab === 'DrainageTubes' ? "white" : '' }}>
                                Drainage Tubes
                                </button>
                                
                                <button onClick={() => handleTabChange("UrinaryCathetor")} style={{ color: activeTab === 'UrinaryCathetor' ? "white" : '' }}>
                                Urinary Catheter
                                </button>
                                
                                <button onClick={() => handleTabChange("SurgicalSite")} style={{ color: activeTab === 'SurgicalSite' ? "white" : '' }}>
                                Surgical Site
                                </button>
                                
                                <button onClick={() => handleTabChange("BedsoreManagement")} style={{ color: activeTab === 'BedsoreManagement' ? "white" : '' }}>
                                Bedsore Management 
                                </button>
                                
                                <button onClick={() => handleTabChange("BloodTransfusionRecord")} style={{ color: activeTab === 'BloodTransfusionRecord' ? "white" : '' }}>
                                Blood Transfusion Record
                                </button>
                            
                            </div>
                           
                            </div>

                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'GeneralPatientCare' || activeTab === 'GeneralPatientCare' ? "white" : '' }}>General Patient Care</button>
                                <div class="Lab_dropdown_content">
                                   
                                    <button onClick={() => handleTabChange("PatientCare")} style={{ color: activeTab === 'PatientCare' ? "white" : '' }}>
                                    Patient Care
                                    </button>
                                    <button onClick={() => handleTabChange("Hemodynamics")} style={{ color: activeTab === 'Hemodynamics' ? "white" : '' }}>
                                    Patient Hemodynamics
                                    </button>
                                    
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("ProgressNotes")} style={{ color: activeTab === 'ProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>

                            |
                            <button onClick={() => handleTabChange("News")} style={{ color: activeTab === 'News' ? "white" : '' }}>
                            News
                            </button>
                            
                            

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                                    <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <button onClick={() => handleTabChange("OtManagementForm")} style={{ color: activeTab === 'OtManagementForm' ? "white" : '' }}>
                            Ip Pre-Operative Checklist
                            </button>
                            |
                            <button onClick={() => handleTabChange("IcuTreatment")} style={{ color: activeTab === 'IcuTreatment' ? "white" : '' }}>
                            Treatment/Diagnosis
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                        Surgical History
                                    </button>
                            </div>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                        Lenin Change
                                    </button>
                                    <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                        OT Booking
                                    </button>
                                    <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                        Ward/Bed Change
                                    </button>
                                    {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    {/* <button onClick={() => handleTabChange("VentilatorSettings")} style={{ color: activeTab === 'VentilatorSettings' ? "white" : '' }}>
                                        Ventilator Settings
                                    </button> */}
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("VentilatorSettings")} style={{ color: activeTab === 'VentilatorSettings' ? "white" : '' }}>
                            Ventilator Settings 
                            </button>
                            |
                            <div class="Lab_dropdown">
                            <button class="Lab_button" style={{ color: activeTab === 'IntensivePatientMonitoring' || activeTab === 'IntensivePatientMonitoring' ? "white" : '' }}>Intensive Patient Monitoring</button>
                                <div class="Lab_dropdown_content">
                                   
                                <button onClick={() => handleTabChange("BloodLines")} style={{ color: activeTab === 'BloodLines' ? "white" : '' }}>
                                        Blood Lines
                                </button>
                                
                                <button onClick={() => handleTabChange("DrainageTubes")} style={{ color: activeTab === 'DrainageTubes' ? "white" : '' }}>
                                Drainage Tubes
                                </button>
                            
                                <button onClick={() => handleTabChange("UrinaryCathetor")} style={{ color: activeTab === 'UrinaryCathetor' ? "white" : '' }}>
                                Urinary Catheter
                                </button>
                                
                                <button onClick={() => handleTabChange("SurgicalSite")} style={{ color: activeTab === 'SurgicalSite' ? "white" : '' }}>
                                Surgical Site
                                </button>
                                
                                <button onClick={() => handleTabChange("BedsoreManagement")} style={{ color: activeTab === 'BedsoreManagement' ? "white" : '' }}>
                                Bedsore Management 
                                </button>
                                
                                <button onClick={() => handleTabChange("BloodTransfusionRecord")} style={{ color: activeTab === 'BloodTransfusionRecord' ? "white" : '' }}>
                                Blood Transfusion Record
                                </button>
                            
                            </div>
                          
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'GeneralPatientCare' || activeTab === 'GeneralPatientCare' ? "white" : '' }}>General Patient Care</button>
                                <div class="Lab_dropdown_content">
                                
                                    <button onClick={() => handleTabChange("PatientCare")} style={{ color: activeTab === 'PatientCare' ? "white" : '' }}>
                                    Patient Care
                                    </button>
                                    <button onClick={() => handleTabChange("Hemodynamics")} style={{ color: activeTab === 'Hemodynamics' ? "white" : '' }}>
                                    Patient Hemodynamics
                                    </button>
                            
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("ProgressNotes")} style={{ color: activeTab === 'ProgressNotes' ? "white" : '' }}>
                            Progress Notes
                            </button>

                            |
                            <button onClick={() => handleTabChange("News")} style={{ color: activeTab === 'News' ? "white" : '' }}>
                            News
                            </button>

                        </h2>
                        
                        
                        
                    </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <IcuNurseVitals/>}
            {activeTab === "MedicalHistory" && <IcuMedicalHistoryForm/>}
            {activeTab === "SurgicalHistory" && <MedicalHistoryForm2/>}
            {activeTab === "Intake/OutPut" && <IcuIntakeOutput/>}
            {activeTab === "DrugAdministration" && <IcuDrugAdminister/>}
            {activeTab === "Lab" && <IcuLabTest/>}
            {activeTab === "LabReport" && <LabReport/>}
            {activeTab === "Lenin" && <IcuLeninChange/>}
            {activeTab === "OT_Booking" && <TheatreBooking/>}
            {activeTab === "Ward/Bed" && <IcuWardChange/>}
            {activeTab === "VentilatorSettings" && <VentilatorSettings/>}
            {activeTab === "BloodGas" && <BloodGas/>}
            {activeTab === "BloodLines" && <BloodLines/>}
            {activeTab === "DrainageTubes" && <DrainageTubes/>}
            {activeTab === "UrinaryCathetor" && <UrinaryCathetor/>}
            {activeTab === "SurgicalSite" && <SurgicalSite/>}
            {activeTab === "BedsoreManagement" && <BedsoreManagement/>}
            {activeTab === "BloodTransfusionRecord" && <BloodTransfusionRecord/>}
            {activeTab === "PatientCare" && <PatientCare/>}
            {activeTab === "Hemodynamics" && <Hemodynamics/>}
            {activeTab === "ProgressNotes" && <ProgressNotes/>}
            {activeTab === "IcuTreatment" && <IcuTreatment/>}
            {activeTab === "News" && <News/>}
            {activeTab === "OtManagementForm" && <OtManagementForm/>}
            
            {console.log(activeTab)}
        </>
    );
}

export default IcuNurseWorkbench;

