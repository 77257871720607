import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmerJwelleryConsent() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    Date: "",
    Time: "",
    Name: "",
    FreeText1: "",
    FreeText2: "",
    Custody: "",
    PatientsBody: "",
    SisterSign: "",
    SisterName: "",
    SisterDate: "",
    SisterTime: "",
    GuardiansSign: "",
    GuardiansName: "",
    GuardiansDate: "",
    GuardiansTime: "",
    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  
  // const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  // const clearSignature = () => {
  //   signatureRef.current.clear();
  // };

  // const saveSignature = () => {
  //   console.log("Signature saved");
  // };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Date', ConcernformData.Date);
              formData.append('Time', ConcernformData.Time);
              formData.append('Name', ConcernformData.Name);
              formData.append('FreeText1', ConcernformData.FreeText1);
              formData.append('FreeText2', ConcernformData.FreeText2);
              formData.append('Custody', ConcernformData.Custody);
              formData.append('PatientsBody', ConcernformData.PatientsBody);
              formData.append('SisterSign', ConcernformData.SisterSign);
              formData.append('SisterName', ConcernformData.SisterName);
              formData.append('SisterDate', ConcernformData.SisterDate);
              formData.append('SisterTime', ConcernformData.SisterTime);
              formData.append('GuardiansSign', ConcernformData.GuardiansSign);
              formData.append('GuardiansName', ConcernformData.GuardiansName);
              formData.append('GuardiansDate', ConcernformData.GuardiansDate);
              formData.append('GuardiansTime', ConcernformData.GuardiansTime);
              // formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_JewelleryConsent`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div>
         
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>JEWELLERY CONSENT</h3>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h6>स्वस्ति मेडीकल फाऊंडेशन चे</h6>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h1>चिरायु</h1><h4 style={{marginLeft: '6px'}}> हॉस्पिटल</h4>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>मौल्यवान वस्तूंचे संमतीपत्र</h3>
          </div>
        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex',alignItems: 'left' , justifyContent: 'flex-start',  textAlign: 'start' }}>

            <div className="RegisForm_1_consent_consent">
                <label htmlFor="Name">
                पेशंटचे नाव <span>:</span>
                    </label>
                    <input
                      type="text"
                      id="Name"
                      name="Name"
                      value={ConcernformData.Name}
                      onChange={handleChange}
                      required
                    />
                
            </div>
            
            

        </div>
        
       

        <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  >
              <p style={{ marginLeft: '25px', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">पोलीस निरीक्षक,<br/>
              रत्नागिरी शहर / ग्रामीण पोलीस ठाणे<br/>
              विषय - MLC नोंद करण्याबाबत.</p>
          </div>
        </div>

        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            पेशंट हॉस्पिटलमध्ये आज दि.
                <input
                type="text"
                name="Date"
                value={ConcernformData.Date}
                onChange={handleChange}
                required
                />
                रोजी अॅडमीट करीत आहे, सदरपेशंट जवळील असलेल्या सर्व मौल्यवान वस्तू मी
                <input
                type="text"
                name="FreeText1"
                value={ConcernformData.FreeText1}
                onChange={handleChange}
                required
                style={{width:'630px'}}
                />
                नाते
                <input
                type="text"
                name="FreeText2"
                value={ConcernformData.FreeText2}
                onChange={handleChange}
                required
                />
                ताब्यात घेतल्या आहेत.
                <br/>
                <br/>
                ताब्यात घेतलेल्या वस्तूंची यादी -<br/>
                <br/>
                <textarea
                type="text"
                name="Custody"
                value={ConcernformData.Custody}
                onChange={handleChange}
                required
                style={{
                  width: '650px',
                  height: '100px'
                }}
                >

                </textarea>
                

            </p>
            
          </div>

          
        </div><br/>
        <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
              <p style={{margin: '0', fontSize: '12px', fontWeight: 'bold'}} className="eeiuujeuwu39 jjj">
              या शिवाय अन्य काही चीजवस्तू किंवा इतर मौल्यवान वस्त पेशंटजवळ नाहीत हे मी खात्रीकरून घेतले असून <br/>
              नंतर माझी किंवा अन्य कोणाचीही हॉस्पिटल तसेच हॉस्पिटल कर्मचाऱ्यांविरूद्ध या बाबत तक्रार असणार नाही. <br/>
              पुढील मौल्यवान वस्तू मी माझ्या जबाबदारीवर पेशंटच्या अंगावर ठेवलेल्या आहेत. तसेच पेशंटच्या अंगावर परत<br/>
              घातलेल्या दागिन्यांची जबाबदारी हॉस्पिटलची असणार नाही, त्याबद्दल माझी कोणाबद्दलही कसलीही तक्रार<br/>
              राहणार नाही.</p>
          </div>
        </div>

        <br />

        <div className="summadiv">
          <div className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex',justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>
            
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
            पेशंटच्या अंगावर ठेवलेल्या चिजवस्तू  -<br/>
                <br/>
                <textarea
                type="text"
                name="PatientsBody"
                value={ConcernformData.PatientsBody}
                onChange={handleChange}
                required
                style={{
                  width: '650px',
                  height: '100px'
                }}
                >

                </textarea>
            </p>
          </div>
        </div>


        <div className="summadiv summadiv1" >
        
        <div  className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex', flexWrap: 'wrap',  alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold',textAlign:'left' }}><br/>
            सिस्टरची सही : 
               <input
                type="text"
                name="SisterSign"
                value={ConcernformData.SisterSign}
                onChange={handleChange}
                required
                /><br/>
                 नाव : 
               <input
                type="text"
                name="SisterName"
                value={ConcernformData.SisterName}
                onChange={handleChange}
                required
                /><br/>
            दिनांक:  
            <input
                type="text"
                name="SisterDate"
                value={ConcernformData.SisterDate}
                onChange={handleChange}
                required
                /><br/>


                
             वेळ :
            <input
                type="text"
                name="SisterTime"
                value={ConcernformData.SisterTime}
                onChange={handleChange}
                required
                />
                
          </p>
        </div>

        <div  className="RegisForm_1_consent_consent_ppp"  style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', textAlign: 'start' }}>
            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold',textAlign:'left' }}><br/>
            नातेवाईकाची सही :
               <input
                type="text"
                name="GuardiansSign"
                value={ConcernformData.GuardiansSign}
                onChange={handleChange}
                required
                /><br/>
                 नाव : 
               <input
                type="text"
                name="GuardiansName"
                value={ConcernformData.GuardiansName}
                onChange={handleChange}
                required
                /><br/>
            दिनांक:  
            <input
                type="text"
                name="GuardiansDate"
                value={ConcernformData.GuardiansDate}
                onChange={handleChange}
                required
                /><br/>


                
             वेळ :
            <input
                type="text"
                name="GuardiansTime"
                value={ConcernformData.GuardiansTime}
                onChange={handleChange}
                required
                />
                
          </p>
        </div>
       </div>

       
      

        {/* <div className="sigCanvas2_head11" >
            <div className="RegisForm_1_consent_consent sigCanvas2_head" >
                <div>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                    width: 200,
                    height: 100,
                    className: "sigCanvas2",
                    }}
                />
                </div>
                <h5>वैद्यकीय अधिकारी, <br/>
                 चिरायु हॉस्पिटल, रत्नागिरी</h5>

                <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                    Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                    Save
                </button>
                </div>

            </div>


        </div> */}

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmerJwelleryConsent;




// नातेवाईकाचे नाव : <span>-</span> 
// <input
//     type="text"
//     name="NameOfRelative"
//     value={ConcernformData.NameOfRelative}
//     onChange={handleChange}
//     required
//     /><br/>


    
// पत्ता : <span>-</span>  
// <input
//     type="text"
//     name="Address"
//     value={ConcernformData.Address}
//     onChange={handleChange}
//     required
//     /><br/>

// सही / अंगठा : <span>-</span>  
// <input
//     type="text"
//     name="SignatureLhti1"
//     value={ConcernformData.SignatureLhti1}
//     onChange={handleChange}
//     required
//     /><br/>