import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";


function OTSurgeryMaster() {

    const [SurgeryName, setSurgeryName] = useState('')
    const [SurgeryNameArray, setSurgeryNameArray] = useState([])
    const [Category, setCategory] = useState('');

    const [selectSurgeryName, setselectSurgeryName] = useState('')
    const [selectedCategory, setselectedCategory] = useState('');
    const [selectedinstrumentName, setselectedinstrumentName] = useState('')
    const [Quantity, setQuantity] = useState('');

    const [uniqueInstrumentNames, setUniqueInstrumentNames] = useState([]);

    const [SurgeryNameandinstrumentArray, setSurgeryNameandinstrumentArray] = useState([]);

    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
    const urllink = useSelector((state) => state.userRecord?.UrlLink);
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const [searchquery, setsearchquery] = useState('')
    const [searchquery1, setsearchquery1] = useState('')
    const [searchquery2, setsearchquery2] = useState('')

    const [searchquery1instrument, setsearchquery1instrument] = useState([])
    const [surgeryID, setsurgeryID] = useState(null)
    const [SurgeryInstrumentID, setSurgeryInstrumentID] = useState(null)


    const [edit, setedit] = useState('');

    useEffect(() => {
        fetchgetSurgeryName_and_Instruments()
        fetchgetSurgeryName_and_InstrumentMaster()
    }, [])

    useEffect(() => {
        if (selectSurgeryName) {
            // Filter SurgeryNameArray to get only the instrument names for the selected surgery name
            const instruments = SurgeryNameArray
                .filter(item => item.SurgeryName === selectSurgeryName)
                .map(item => item.Category);

            // Remove duplicates using Set
            const uniqueInstruments = [...new Set(instruments)];

            // Set the unique instrument names
            setUniqueInstrumentNames(uniqueInstruments);

        } else {
            // If no surgery name is selected, set uniqueInstrumentNames to an empty array
            setUniqueInstrumentNames([]);

        }

        if (searchquery) {
            // Filter SurgeryNameArray to get only the instrument names for the selected surgery name
            const instruments = SurgeryNameArray
                .filter(item => item.SurgeryName === searchquery)
                .map(item => item.Category);

            // Remove duplicates using Set
            const uniqueInstruments = [...new Set(instruments)];


            setsearchquery1instrument(uniqueInstruments)
        } else {
            // If no surgery name is selected, set uniqueInstrumentNames to an empty array
            setsearchquery1instrument([]);

        }
    }, [selectSurgeryName, SurgeryNameArray, searchquery]);







    const submitsurgeryname = () => {

        const data = new FormData();

        data.append('SurgeryName', SurgeryName)
        data.append('Category', Category)
        data.append('surgeryID', surgeryID)
        data.append('createdBy', userRecord?.username)
        data.append('Location', userRecord?.location)


        axios.post(`${urllink}usercontrol/insertSurgeryName_and_Instruments`, data)
            .then((response) => {
                console.log(response.data)
                if (response.data.Error) {
                    userwarn(response.data.Error)
                }
                // else{
                //     successMsg(response.data.Message)
                // }
                fetchgetSurgeryName_and_Instruments()
                setCategory('')
                setsurgeryID(null)
                setedit(false)
            })
            .catch((error) => {
                console.error(error)
                userwarn(error)
            })
    }



    const fetchgetSurgeryName_and_Instruments = () => {
        axios.get(`${urllink}usercontrol/getSurgeryName_and_Instruments`)
            .then((response) => {
                setSurgeryNameArray(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }



    const submitsurgeryandinstrumentmaster = () => {
        const data1 = new FormData();

        data1.append('SurgeryName', selectSurgeryName)
        data1.append('Instruments', selectedCategory)
        data1.append('instrumentName', selectedinstrumentName)
        data1.append('createdBy', userRecord?.username)
        data1.append('Quantity', Quantity)
        data1.append('Location', userRecord?.location)
        data1.append('SurgeryInstrumentID', SurgeryInstrumentID)


        axios.post(`${urllink}usercontrol/insertSurgeryName_and_Instrumentsmaster`, data1)
            .then((response) => {
                console.log(response.data)
                if (response.data.Error) {
                    userwarn(response.data.Error)
                }

                fetchgetSurgeryName_and_InstrumentMaster()
                if (edit == true) {
                    setselectSurgeryName('')
                    setselectedCategory('')
                    setselectedinstrumentName('')
                    setQuantity('')
                    setSurgeryInstrumentID(null)
                    setedit(false)
                } else {
                    setselectedinstrumentName('')
                    setQuantity('')
                    setSurgeryInstrumentID(null)
                    setedit(false)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }


    const fetchgetSurgeryName_and_InstrumentMaster = () => {
        axios.get(`${urllink}usercontrol/getSurgeryName_and_Instrumentmaster`)
            .then((response) => {
                setSurgeryNameandinstrumentArray(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleEditSurgeryName = (row) => {
        console.log(row)
        setedit(true)
        setSurgeryName(row.SurgeryName)
        setCategory(row.Category)
        setsurgeryID(row.SurgeryID)
    }


    const handleEditSurgeryAndInstrumentMaster = (row) => {
        console.log(row)
        setselectSurgeryName(row.SurgeryName)
        setselectedCategory(row.Category)
        setselectedinstrumentName(row.InstrumentName)
        setQuantity(row.Quantity)
        setedit(true)
        setSurgeryInstrumentID(row.SurgeryInstrumentID)
    }

    const successMsg = (message) => {
        toast.success(message, {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            containerId: "toast-container-over-header",
            style: { marginTop: "50px" },
        });
    };
    const userwarn = (warningMessage) => {
        toast.warn(`${warningMessage}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };
    const errmsg = (error) => {
        toast.error(error, {
            position: "top-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };



    return (
        <div className='appointment'>
            <div className='h_head'>
                <h4>Surgery Masters</h4>
            </div>
            <div className="ShiftClosing_Container">
                <div className="">
                    <h2 style={{ textAlign: "center" }}>Surgery</h2>
                    <br />
                    <div className="con_1 ">
                        <div className="inp_1">
                            <label htmlFor="SurgeryName">
                                Surgery Name <span>:</span>
                            </label>
                            <input
                                type="text"
                                id="SurgeryName"
                                name="SurgeryName"
                                value={SurgeryName}
                                onChange={(e) => setSurgeryName(e.target.value)}
                                placeholder="Enter Surgery Name"
                            // style={{ width: "150px" }}
                            />
                        </div>
                        <div className="inp_1">
                            <label htmlFor="Category">
                                category<span>:</span>
                            </label>
                            <input
                                type="text"
                                id="Category"
                                name="Category"
                                value={Category}
                                onChange={(e) => setCategory(e.target.value)}
                                placeholder="Enter Instruments Name"
                            // style={{ width: "150px" }}
                            />
                        </div>

                    </div>
                    <div className='con_1'>

                        <button className="btn_1" onClick={submitsurgeryname}>
                            {edit ? "Update" : <AddIcon />}
                        </button>
                    </div>
                    <br />
                    <div
                        style={{ width: "100%", display: "grid", placeItems: "center" }}
                    >
                        <h4>Table</h4>
                        <br />
                        <div className="inp_1">
                            <label htmlFor="selectSurgeryName" >
                                Surgery Name <span>:</span>
                            </label>
                            <select
                                type="text"
                                id="selectSurgeryName"
                                name="selectSurgeryName"
                                value={searchquery2}
                                onChange={(e) => setsearchquery2(e.target.value)}


                            >
                                <option value=''>Select</option>
                                {[...new Set(SurgeryNameArray.map(item => item.SurgeryName))].map((surgeryName, index) => (
                                    <option key={index} value={surgeryName}>{surgeryName}</option>
                                ))}

                            </select>
                        </div>

                        <div className="Selected-table-container ">
                            <table className="selected-medicine-table2 ">
                                <thead>
                                    <tr>
                                        <th>SurgeryID</th>
                                        <th>Surgery Name</th>
                                        <th>category</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                {searchquery2 ? (
                                    <tbody>
                                        {SurgeryNameArray.filter(item => item.SurgeryName === searchquery2).map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.SurgeryID}</td>
                                                <td>{row.SurgeryName}</td>
                                                <td>{row.Category}</td>
                                                <td>

                                                    <button className="cell_btn" onClick={() => handleEditSurgeryName(row)}
                                                    ><EditIcon /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <div className="IP_norecords">
                                        <span>No Records</span>
                                    </div>
                                )}
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div className="ShiftClosing_Container">
                <div className="">
                    <h2 style={{ textAlign: "center" }}>Instruments</h2>
                    <br />
                    <div className="con_1 ">
                        <div className="inp_1">
                            <label htmlFor="selectSurgeryName" >
                                Surgery Name <span>:</span>
                            </label>
                            <select
                                type="text"
                                id="selectSurgeryName"
                                name="selectSurgeryName"
                                value={selectSurgeryName}
                                onChange={(e) => setselectSurgeryName(e.target.value)}


                            >
                                <option value=''>Select</option>
                                {[...new Set(SurgeryNameArray.map(item => item.SurgeryName))].map((surgeryName, index) => (
                                    <option key={index} value={surgeryName}>{surgeryName}</option>
                                ))}

                            </select>
                        </div>
                        <div className="inp_1">
                            <label htmlFor="selectedCategory" >
                                category <span>:</span>
                            </label>
                            <select
                                type="text"
                                id="selectedCategory"
                                name="selectedCategory"
                                value={selectedCategory}
                                onChange={(e) => setselectedCategory(e.target.value)}
                                placeholder="Enter Intruments"
                            >
                                <option value=''>Select</option>
                                {uniqueInstrumentNames.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className='con_1'>
                        <div className="inp_1">
                            <label htmlFor="selectedinstrumentName" >
                                Intrument Name <span>:</span>
                            </label>
                            <input
                                type="text"
                                id="selectedinstrumentName"
                                name="selectedinstrumentName"
                                value={selectedinstrumentName}
                                onChange={(e) => setselectedinstrumentName(e.target.value)}
                                placeholder="Enter Intrument Name"

                            />
                        </div>
                        <div className="inp_1">
                            <label htmlFor="Quantity" >
                                Quantity <span>:</span>
                            </label>
                            <input
                                type="text"
                                id="Quantity"
                                name="Quantity"
                                value={Quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                placeholder="Enter Quantity"

                            />
                        </div>

                    </div>
                    <div className='con_1'>

                        <button className="btn_1" onClick={submitsurgeryandinstrumentmaster}>
                            {edit ? "Update" : <AddIcon />}
                        </button>
                    </div>
                    <br />
                    <div
                        style={{ width: "100%", display: "grid", placeItems: "center" }}
                    >

                        <h4>Table</h4>
                        <br />
                        <div className='con_1'>
                            <div className="inp_1">
                                <label htmlFor="selectSurgeryName" >
                                    Surgery Name <span>:</span>
                                </label>
                                <select
                                    type="text"
                                    id="selectSurgeryName"
                                    name="selectSurgeryName"
                                    value={searchquery}
                                    onChange={(e) => setsearchquery(e.target.value)}


                                >
                                    <option value=''>Select</option>
                                    {[...new Set(SurgeryNameArray.map(item => item.SurgeryName))].map((surgeryName, index) => (
                                        <option key={index} value={surgeryName}>{surgeryName}</option>
                                    ))}

                                </select>
                            </div>
                            <div className="inp_1">
                                <label htmlFor="selectedCategory" >
                                    category <span>:</span>
                                </label>
                                <select
                                    type="text"
                                    id="selectedCategory"
                                    name="selectedCategory"
                                    value={searchquery1}
                                    onChange={(e) => setsearchquery1(e.target.value)}
                                    placeholder="Enter Intruments"
                                >
                                    <option value=''>Select</option>
                                    {searchquery1instrument.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="Selected-table-container ">

                            <table className="selected-medicine-table2 ">
                                <thead>
                                    <tr>
                                        <th>Surgery InstrumentID</th>
                                        <th>Surgery Name</th>
                                        <th>Category</th>
                                        <th>Instruments Name</th>
                                        <th>Quantity</th>
                                        <th>Edit</th>

                                    </tr>
                                </thead>
                                {searchquery && searchquery1 ? (
                                    <tbody>
                                        {SurgeryNameandinstrumentArray.filter(item => item.SurgeryName === searchquery && item.Category === searchquery1).map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.SurgeryInstrumentID}</td>
                                                <td>{row.SurgeryName}</td>
                                                <td>{row.Category}</td>
                                                <td>{row.InstrumentName}</td>
                                                <td>{row.Quantity}</td>
                                                <td>

                                                    <button className="cell_btn" onClick={() => handleEditSurgeryAndInstrumentMaster(row)}
                                                    ><EditIcon /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <div className="IP_norecords">
                                        <span>No Records</span>
                                    </div>
                                )}
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default OTSurgeryMaster;






