import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import "./IcuNurseVitals.css";
import "../../ICU Management/ICUNurseflow/IcuNurseVitals.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function EmergencyBloodTransfusedRecord() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);


  const [page, setPage] = useState(0);
  const [summa, setsumma] = useState([]);
  const [getdatastate, setgetdatastate] = useState(false);

  const [DrainageTubesData, setDrainageTubesData] = useState({
    
    Date: "",
    Time: "",
    BloodGroup: "",
    Type: "",
    NoOfBags: "",
    AnyAdverseReactions: "",
    Remarks: "",
    
    
    
  });
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);
  
    
      setDrainageTubesData((prev) => ({
        ...prev,
        [name]: value,
      }));
    
};

const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  

  
  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `https://vesoftometic.co.in/IcuManagement/get_BloodTransfusionRecord?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data;
          console.log("222", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching doctor names:", error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id, getdatastate]);

  const pageSize = 10;
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
 
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: 'id',
      headerName: 'S_No',
      width: 40,
    },
    ...Object.keys(DrainageTubesData).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);
  
      return {
        field: labelname,
        headerName: formattedLabel,
        width: labelWidth + 90,
      };
    })
    
  ];
  
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }

  const cleardata = () => {
    setDrainageTubesData({
        Date: "",
        Time: "",
        BloodGroup: "",
        Type: "",
        NoOfBags: "",
        AnyAdverseReactions: "",
        Remarks: "",
      
    });
  };

  const Post_vital_data = () => {
    const requiredfields = [
        "Date",
        "Time",
        "BloodGroup",
        "Type",
        "NoOfBags",
        "AnyAdverseReactions",
        "Remarks",
      
    ];
    const existing = requiredfields.filter((field) => !DrainageTubesData[field]);

    if (existing > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const Allsenddata = {
        ...DrainageTubesData,
        Booking_Id: IpNurseQueSelectedRow.Booking_Id,
        Patient_Name: IpNurseQueSelectedRow.PatientName,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
      };
      axios
        .post(
          `https://vesoftometic.co.in/IcuManagement/insert_BloodTransfusionRecord`,
          Allsenddata
        )
        .then((response) => {
          console.log(response);
          cleardata();
          setgetdatastate(!getdatastate);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="new-patient-registration-form">
      <div className="RegisFormcon">
  {Object.keys(DrainageTubesData).map((labelname, index) => (
    <div className="RegisForm_1" key={index}>
      <label>
        {labelname === "LocationLR" ? (
          "Location"
        ) :
         (
          formatLabel(labelname)
        )}
        <span>:</span>
      </label>
      {labelname === "BloodGroup" ? (
        <select
          name={labelname}
          value={DrainageTubesData[labelname]}
          onChange={handleInputChange}
        >
          <option value="">Select</option>
          <option value="A positive (A+)">A positive (A+)</option>
          <option value="A negative (A-)">A negative (A-)</option>
          <option value="B positive (B+)">B positive (B+)</option>
          <option value="B negative (B-)">B negative (B-)</option>
          <option value="O positive (O+)">O positive (O+)</option>
          <option value="O negative (O-)">O negative (O-)</option>
          <option value="AB positive (AB+)">AB positive (AB+)</option>
          <option value="AB negative (AB-)">AB negative (AB-)</option>
          
        </select>
      ) : labelname === "Type" ? (
        <select
          name={labelname}
          value={DrainageTubesData[labelname]}
          onChange={handleInputChange}
        >
          <option value="">Select</option>
          <option value="Whole Blood">Whole Blood</option>
          <option value="Plasma">Plasma</option>
          <option value="Platelets">Platelets</option>
          <option value="RBCs">RBCs</option>
          

          
        </select>
      ) : labelname === "NoOfBags" ? (
        <>
            <input
            name="NoOfBags"
            type="number"
            onKeyDown={blockInvalidChar}
            style={{ width: "100px" }}
            value={DrainageTubesData.Measurement}
            onChange={handleInputChange}
            />
            
        </>
      ) : labelname === "Remarks" ? (
        <>
        <textarea name="Remarks" value={DrainageTubesData.Remarks} onChange={handleInputChange} />
            
            
        </>
      ) : labelname === "AnyAdverseReactions" ? (
        <select
          name={labelname}
          value={DrainageTubesData[labelname]}
          onChange={handleInputChange}
        >
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
          

          
        </select>
      ) : labelname === "Date" ? (
        <input
          type="date"
          name={labelname}
          value={DrainageTubesData[labelname]}
          onChange={handleInputChange}
        />
      ) : (
        <input
          type={labelname === "Time" ? "time":  "number"}
          name={labelname}
          onKeyDown={blockInvalidChar}
          // placeholder={`Enter the ${formatLabel(labelname)}`}
          value={DrainageTubesData[labelname]}
          onChange={handleInputChange}
        />
      ) }
    </div>
  ))}
</div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={Post_vital_data}>
            Add
          </button>
        </div>
        <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                  columns={dynamicColumns} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown > 0 && summa.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page === 0}
                    >
                      Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                      onClick={() =>
                        setPage((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown !== 0 && summa.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        
        

       
      </div>
      <ToastContainer />
    </>
  );
}
