import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ProgressNotes = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: "Center",
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
              {
                textAlign: "center",
                display: "flex !important",
                justifyContent: "center !important",
              },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
  });
  const [page, setPage] = useState(0);
  const [writtenByPrimaryDoctor, setWrittenByPrimaryDoctor] = useState(null);
  const [textareaVisible, setTextareaVisible] = useState(false);
  const [primaryDr, setPrimaryDr] = useState("");

  const [progressData, setprogressData] = React.useState(false);
  const [showTextarea, setShowTextarea] = useState(true);
  const [isSaveButton, setIsSaveButton] = useState(true);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);

  console.log("IpNurseQueSelectedRow", IpNurseQueSelectedRow);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  console.log("userRecord", userRecord);
  const [notes, setNotes] = useState("");
  const [subnotes, setSubNotes] = useState("");
  const [specialization, setSpecialization] = useState("");


  const handleNotesChange = (event) => {
    const { name, value } = event.target;

    if (name === "writtenByPrimaryDoctor") {
      if (value === "yes") {
        setTextareaVisible(true);
      } else {
        setTextareaVisible(false);
        setSubNotes(""); // Clear subnotes when "Yes" is selected
      }
      setWrittenByPrimaryDoctor(value);
    } else {
      if (value.length <= 1000) {
        if (name === "notes") {
          setNotes(value);
        } else if (name === "specialization") {
          setSpecialization(value);
        } else if (name === "subnotes" && textareaVisible) {
          setSubNotes(value);
        }
      }
    }
  };

  const handleViewIconClick = (row) => {
    setNotes(row.Progress_Notes);
    setSpecialization(row.Specialization);
    setWrittenByPrimaryDoctor(row.Written_by === 'no' ? 'no' : 'yes');
    if (row.Written_by === 'no') {
      setTextareaVisible(false);
      setSubNotes('');
    } else {
      setTextareaVisible(true);
      setSubNotes(row.Written_by);
    }
    setShowTextarea(true);
    setIsSaveButton(false);
  };
  const handleNewButtonClick = () => {
    setShowTextarea(true);
    setIsSaveButton(true);
    setNotes('');
    setSpecialization('');
    setSubNotes('');
    setTextareaVisible(false);
    setWrittenByPrimaryDoctor(null);
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const dynamicColumns = [
    { field: "id", headerName: "Progress Id", width: 100 },
    { field: "Patient_Id", headerName: "Patient Id", width: 120 },
    { field: "Booking_Id", headerName: "Booking ID", width: 120 },
    { field: "Department", headerName: "Department", width: 120 },
    { field: "Captured_Date", headerName: "Captured Date", width: 120 },
    { field: "Captured_Time", headerName: "Captured Time", width: 120 },
    {
      field: "Action",
      headerName: "view",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn">
            <VisibilityIcon
              onClick={() => {
                handleViewIconClick(params.row);
              }}
            />
          </Button>
        </>
      ),
    },
  ];
  const handlePrintSave = () => {
    if (notes == "") {
      userwarn("please fill the notes");
    }
    if (textareaVisible == true && subnotes == "") {
      userwarn("please fill written by");
    }
    const data = new FormData();
    data.append("patientId", IpNurseQueSelectedRow?.PatientId);
    data.append("bookingId", IpNurseQueSelectedRow?.BookingId);
    data.append("Name", userRecord?.username);
    data.append("Department", "Nurse");
    data.append("Specialization", specialization);
    data.append("role_id", userRecord?.role_id);
    data.append("Notes", notes);
    data.append("PrimaryDoctor", IpNurseQueSelectedRow.PrimaryDoctor);
    data.append("location", userRecord?.location);
    if (writtenByPrimaryDoctor === "yes") {
      data.append("writtenByDetails", subnotes);
    } else {
      data.append("writtenByDetails", writtenByPrimaryDoctor);
    }

  
    axios
      .post(`${UrlLink}ipregistration/insert_progress_notes`, data)
      .then((response) => {
        console.log("datas", response.data);
        console.log("datas", response.data);
        setNotes("");
        setSpecialization("");
        setSubNotes("");
        setWrittenByPrimaryDoctor("");
        setTextareaVisible(false);
        setprogressData(!progressData);
      })
      .catch((error) => {

        console.error("Error :", error);
      });
  };

 
  console.log("notes", notes);
  console.log("specializzation", specialization);
  console.log("subnotes", subnotes);

  // useEffect(() => {
  //   axios
  //     .get(`${UrlLink}ipregistration/get_progress_notes`)
  //     .then((response) => {
  //       const data = response.data;
  //       const Records = data?.map((userdata, index) => ({
  //         id: userdata?.Progress_Id,
  //         Patient_Id: userdata?.Patient_Id,
  //         Booking_Id: userdata?.Booking_Id,
  //         Department: userdata?.Department,
  //         Created_By: userdata?.Created_By,
  //         Captured_Date: userdata?.Captured_Date,
  //         Captured_Time: userdata?.Captured_Time,
  //         Progress_Notes: userdata?.Progress_Notes,
  //         Docter_Name: userdata?.Docter_Name,
  //         Written_by:userdata?.Written_by,
  //         Specialization:userdata?.Specialization
  //       }));
  //       setFilteredRows(Records);
  //       const showdata = Records.find(
  //         (r, index) => index === Records.length - 1
  //       );
  //       console.log(showdata, "00000 ");
  //       setNotes(showdata?.Progress_Notes);
  //       setSubNotes(showdata?.Written_by);
  //       setSpecialization(showdata?.Specialization);
  //       setIsSaveButton(false);
  //       console.log("progress notes data:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [progressData]);
  useEffect(() => {
    axios
      .get(`${UrlLink}ipregistration/get_progress_notes`)
      .then((response) => {
        const data = response.data;
        console.log(data)
        const Records = data.map((userdata) => ({
          id: userdata.Progress_Id,
          Patient_Id: userdata.Patient_Id,
          Booking_Id: userdata.Booking_Id,
          Department: userdata.Department,
          Created_By: userdata.Created_By,
          Captured_Date: userdata.Captured_Date,
          Captured_Time: userdata.Captured_Time,
          Progress_Notes: userdata.Progress_Notes,
          Docter_Name: userdata.Docter_Name,
          Written_by: userdata.Created_Name,
          Specialization: userdata.Specialization,
        }));
        setFilteredRows(Records);
        if (Records.length > 0) {
          const lastRecord = Records[Records.length - 1];
          setNotes(lastRecord.Progress_Notes);
          setSpecialization(lastRecord.Specialization);
          setWrittenByPrimaryDoctor(lastRecord.Written_by === 'no' ? 'no' : 'yes');
          if (lastRecord.Written_by === 'no') {
            setTextareaVisible(false);
            setSubNotes('');
          } else {
            setTextareaVisible(true);
            setSubNotes(lastRecord.Written_by);
          }
          setIsSaveButton(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [progressData]);

  return (
    <div className="Medical_History_container" id="reactprintcontent">
      <div className="form-section5">
        <div className="form-field5">
          <label htmlFor="newPulse">
            Primary Doctor <span>:</span>
          </label>
          
          <input
            type="text"
            id="primaryDr"
            name="primaryDr"
            value={`${IpNurseQueSelectedRow?.PrimaryDoctor}`}
            required
          />
        </div>
      </div>

     
      <div className="form-section5">
        <div className="form-field5">
          <label htmlFor="newPulse">
            Specialization <span> :</span>
          </label>
          <input
            type="text"
            id="specialization"
            name="specialization"
            value={specialization}
            onChange={handleNotesChange}
            maxLength={1000}
            readOnly={!showTextarea || !isSaveButton}
            required
          />
        </div>
      </div>
      <div className="form-section5">
        <div className="form-field5">
          <label htmlFor="newPulse">
            On Behalf Of <span>:</span>
          </label>
          <input
            type="radio"
            id="writtenByYes"
            name="writtenByPrimaryDoctor"
            value="yes"
            checked={writtenByPrimaryDoctor === "yes"}
            onChange={handleNotesChange}
          />
          <label htmlFor="writtenByYes">Yes</label>
          <input
            type="radio"
            id="writtenByNo"
            name="writtenByPrimaryDoctor"
            value="no" 
            checked={writtenByPrimaryDoctor === "no"}
            onChange={handleNotesChange}
          />
          <label htmlFor="writtenByNo">No</label>
        </div>
      </div>
      {textareaVisible && (
        <div className="form-section5">
          <div className="form-field5">
            <label htmlFor="subnotes">
              Written By<span>:</span>
            </label>
            <input
              type="text"
              id="subnotes"
              name="subnotes"
              value={subnotes}
              onChange={handleNotesChange}
              maxLength={1000}
            />
          </div>
        </div>
      )}
       <div className="form-section5">
        <div className="form-field5">
          <label htmlFor="newPulse">
            Progress Notes <span>:</span>
          </label>
          <textarea
            type="text"
            id="notes"
            name="notes"
            value={notes}
            onChange={handleNotesChange}
            maxLength={1000}
            style={{width:"700px",height:"200px"}}
            readOnly={!showTextarea || !isSaveButton}
            required
          />
        </div>
      </div>
      <div className="Register_btn_con">
        {isSaveButton ? (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handlePrintSave}
          >
            Save
          </button>
        ) : (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handleNewButtonClick}
          >
            New
          </button>
        )}
      </div>

      <div className="IP_grid">
        <ThemeProvider theme={theme}>
          <div className="IP_grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" Ip_data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProgressNotes;
