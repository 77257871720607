import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TheatreBooking.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa";
import { Button } from "@mui/material";
import Edit from "@mui/icons-material/Edit";

const TheatreBooking = () => {
  const OtData = useSelector((state) => state.InPatients?.submissionData);
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: "Center",
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
              {
                textAlign: "center",
                display: "flex !important",
                justifyContent: "center !important",
              },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid var(--ProjectColor) !important",
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
  });
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);
  console.log("IpNurseQueSelectedRow", IpNurseQueSelectedRow);
  console.log("OtQUELIST", OtData);
  const [bookingData, setBookingData] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = bookingData.length;
  const totalPages = Math.ceil(bookingData.length / 10);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [pagesview, setpagesview] = useState(false);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (pagesview) {
      setValue(1);
    }
  }, [pagesview]);
  const [nurseArray, setNurseArray] = useState([]);
  const [pasentdetailearr, setpasentdetailearr] = useState([]);

  const [Theaternamelist, setTheaternamelist] = useState([]);
  const [locationlist, setLocationlist] = useState([]);
  const [showSelectOptions, setShowSelectOptions] = useState(false);

  const [Doctordetailearr, setDoctordetailearr] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);

  const [singlepasentdetaileobj, setpasentdetaileobj] = useState({});
  const [ipList, setIpList] = useState({});

  useEffect(() => {
    setpasentdetaileobj(IpNurseQueSelectedRow);
  }, []);
  useEffect(() => {
    setIpList(OtData);
  }, []);
  const [SearchIPno, setSearchIPno] = useState("");
  const [mrnNo, setMrnNo] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientType, setPatientType] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");

  const [ward, setWard] = useState("");
  const [bedNo, setBedNo] = useState("");

  const [Opdate, setOpdate] = useState("");

  const [Optimeinput, setOptime] = useState("");

  const [Theatername, setTheatername] = useState("");

  const [SelecetSpecialization, setSelecetSpecialization] = useState("");

  const [Filterspecial, setFilterspecial] = useState([]);

  const [SelecetDoctorename, setSelecetDoctorename] = useState("");

  const [SelectedDoctors, setSelectedDoctors] = useState([]);
  const [SelectedSugeon, setSelectedSugeon] = useState([]);
  const [AsstSelectedSugeon, setAsstSelectedSugeon] = useState([]);
  const [SelectedAnesthesiologist, setSelectedAnesthesiologist] = useState([]);
  const [SelectedAnesthesiaTechnician, setSelectedAnesthesiaTechnician] =
    useState([]);
  const [SelectedNurse, setSelectedNurse] = useState([]);
  console.log(SelectedNurse);
  const [FilterspecialDoctorename, setFilterspecialDoctorename] = useState([]);

  const [Oprationstarttime, setOprationstarttime] = useState("");
  const [Oprationendtime, setOprationendtime] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const [OprationDurationhours, setOprationDurationhours] = useState("");

  const [Employeedataarr, setEmployeedataarr] = useState([]);

  console.log("Employeedataarr", Employeedataarr);

  const [SelectSurgeon, setSelectSurgeon] = useState("");

  const [Surgeonstemparr, setSurgeonstemparr] = useState([]);

  const [SelectAssSurgeon, setSelectAssSurgeon] = useState("");

  const [AssSurgeontemparr, setAssSurgeontemparr] = useState([]);

  const [SelectAnaesthetician, setSelectAnaesthetician] = useState("");

  const [Anaestheticiantemparr, setAnaestheticiantemparr] = useState([]);

  const [SelectTechnician, setSelectTechnician] = useState("");

  const [Techniciantemparr, setTechniciantemparr] = useState([]);

  const [SelectNurse, setSelectNurse] = useState("");

  const [Nursetemparr, setNursetemparr] = useState([]);

  const [OPmedicineArr, setOPmedicineArr] = useState([]);

  const [FilterOPmedicineArr, setFilterOPmedicineArr] = useState([]);

  const [Medicine_Type, SetMedicine_Type] = useState("");

  const [TabletORSyringedata, setTabletORSyringedata] = useState("");

  const [AnaesthetistDose, setAnaesthetistDose] = useState("");

  const [Anaesth_Requ_data_arr, setAnaesth_Requ_data_arr] = useState([]);

  const [Edit_btn_condition, setEdit_btn_condition] = useState(false);

  const [Pre_Opration_notes, setPre_Opration_notes] = useState("");

  const [Post_Opration_notes, setPost_Opration_notes] = useState("");

  const [OT_Notes_input, setOT_Notes_input] = useState("");

  const [OT_Remarks_input, setOT_Remarks_input] = useState("");
  const [locationName, setLocationName] = useState("");

  const [type, setType] = useState("pre_con");

  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_all_Docter_name")
      .then((response) => {
        console.log("doctername", response);
        setDoctordetailearr(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_all_anesthesiologist_name")
      .then((response) => {
        console.log("anesthesiologist", response);
        setAnaestheticiantemparr(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        "https://vesoftometic.co.in/ipregistration/get_all_asst_anesthesiologist_name"
      )
      .then((response) => {
        console.log("Asst_anesthesiologist", response);
        setTechniciantemparr(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/usercontrol/get_Theatre_No")
      .then((response) => {
        console.log("TheaterNo", response);
        setTheaternamelist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/usercontrol/get_Theatre_No")
      .then((response) => {
        console.log("location", response);
        setLocationlist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [
    Theatre_Booking_personal_information,
    setTheatre_Booking_personal_information,
  ] = useState([]);

  const [Theatre_Booking_Doctors_Details, setTheatre_Booking_Doctors_Details] =
    useState([]);

  const [
    Theatre_Booking_Anaesthetist_Details,
    setTheatre_Booking_Anaesthetist_Details,
  ] = useState([]);

  const [Theatre_Booking_Other_Details, setTheatre_Booking_Other_Details] =
    useState([]);

  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_all_nurse_name")
      .then((response) => {
        console.log(response);
        setNurseArray(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_all_surgeon_name")
      .then((response) => {
        console.log("surgeonnames", response.data);
        setSurgeonstemparr(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_all_asst_surgeon_name")
      .then((response) => {
        console.log("Asstsurgeon", response.data);
        setAssSurgeontemparr(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/getallinputdetailes/get_anaesthetist_requirements_list"
      )
      .then((response) => setOPmedicineArr(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setFilterspecial([
      ...new Set(Doctordetailearr.map((item) => item.Specialization)),
    ]);
  }, [SelecetSpecialization]);

  useEffect(() => {
    setFilterspecialDoctorename(
      Doctordetailearr.filter(
        (item) => item.Specialization === SelecetSpecialization
      )
    );
  }, [SelecetDoctorename]);

  // const getdata_OP_pasent = () => {
  //   if (SearchIPno === "") {
  //     alert("Enter IP No");
  //   } else {
  //     let Srchipno = parseInt(SearchIPno);
  //     let tamp_obj = pasentdetailearr?.find((ele) => {
  //       return +ele.IP_NO === +Srchipno;
  //     });
  //     console.log(pasentdetailearr);
  //     console.log(tamp_obj);
  //     setpasentdetaileobj(tamp_obj);
  //   }
  // };
  const handlepatientsearch = () => {
    const IP_No = singlepasentdetaileobj?.PatientId || SearchIPno;
    const phonenum = singlepasentdetaileobj?.PatientPhoneNo || phoneNo;

    if (!IP_No && !phonenum) {
      alert("Enter IP No");
    } else {
      axios
        .get(
          `https://vesoftometic.co.in/ipregistration/get_for_inpatient_info?phonenum=${phonenum}&IP_No=${IP_No}`
        )
        .then((response) => {
          console.log("bharathigetpatient", response.data);
          const data = response.data.patient_info;

          console.log(data);

          if (data) {
            setpasentdetaileobj((prevState) => ({
              PatientName: data?.PatientName,
              Booking_Id: data?.Booking_Id || "",
              PatientId: data.PatientId || "",
              Gender: data?.Gender || "",
              PatientPhoneNo: data?.PatientPhoneNo || "",
              Age: data?.Age || "",
              PatientType: data?.PatientType || "",
              // Add other fields if necessary
            }));
          } else {
            alert(`Data Not Found For This ${IP_No || phonenum}`);
            setSearchIPno("");
            setPhoneNo("");
            setpasentdetaileobj((prevState) => ({
              PatientId: "",
              PatientName: "",
              Booking_Id: "",
              Gender: "",
              PatientPhoneNo: "",
              Age: "",
              PatientType: "",
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const SubmitSurgionname = () => {
    if (SelectSurgeon === "") {
      alert("please enter the Surgeonname");
    } else if (Surgeonstemparr.some((e) => e.Name === SelectSurgeon)) {
      alert("You are alredy enter the Surgeonname");
      setSelectSurgeon("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.surgeons.some((surgeon) =>
          surgeon.Name.includes(SelectSurgeon)
        );
      })
    ) {
      alert("Surgeon was busy");
      setSelectSurgeon("");
    } else {
      let emp_id = Employeedataarr.filter((e) => e.Name === SelectSurgeon).find(
        (e) => e
      )?.id;

      setSurgeonstemparr([
        ...Surgeonstemparr,
        { Emp_id: emp_id, Name: SelectSurgeon },
      ]);
      setSelectSurgeon("");
    }
  };

  const DeleteSurgeonsname = (Name) => {
    let Balancearr = Surgeonstemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setSurgeonstemparr(Balancearr);
    setSelectSurgeon("");
  };

  const SubmitAssSurgionname = () => {
    if (SelectAssSurgeon === "") {
      alert("please enter the Ass.Surgenname");
    } else if (AssSurgeontemparr.some((e) => e.Name === SelectAssSurgeon)) {
      alert("You are alredy enter the Ass.Surgenname");
      setSelectAssSurgeon("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Assistant_Surgeon.some((Assistant_Surgeon) =>
          Assistant_Surgeon.Name.includes(SelectAssSurgeon)
        );
      })
    ) {
      alert("Assistant_Surgeon was busy");
      setSelectAssSurgeon("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectAssSurgeon
      ).find((e) => e)?.id;

      setAssSurgeontemparr([
        ...AssSurgeontemparr,
        { Emp_id: emp_id, Name: SelectAssSurgeon },
      ]);
      setSelectAssSurgeon("");
    }
  };

  const DeleteAssSurgeonsname = (Name) => {
    let Balancearr = AssSurgeontemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setAssSurgeontemparr(Balancearr);
    setSelectAssSurgeon("");
  };

  const SubmitAnaestheticianname = () => {
    if (SelectAnaesthetician === "") {
      alert("please enter the Anaesthetician");
    } else if (
      Anaestheticiantemparr.some((e) => e.Name === SelectAnaesthetician)
    ) {
      alert("You are alredy enter the Anaesthetician");
      setSelectAnaesthetician("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Anaesthetician.some((Anaesthetician) =>
          Anaesthetician.Name.includes(SelectAnaesthetician)
        );
      })
    ) {
      alert("Anaesthetician was busy");
      setSelectAnaesthetician("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectAnaesthetician
      ).find((e) => e)?.id;

      setAnaestheticiantemparr([
        ...Anaestheticiantemparr,
        { Emp_id: emp_id, Name: SelectAnaesthetician },
      ]);
      setSelectAnaesthetician("");
    }
  };

  const DeleteAnaestheticianname = (Name) => {
    let Balancearr = Anaestheticiantemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setAnaestheticiantemparr(Balancearr);
    setSelectAnaesthetician("");
  };

  const SubmitTechnicianname = () => {
    if (SelectTechnician === "") {
      alert("please enter the Technician");
    } else if (Techniciantemparr.some((e) => e.Name === SelectTechnician)) {
      alert("You are alredy enter the Technician");
      setSelectTechnician("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Technician.some((Technician) =>
          Technician.Name.includes(SelectTechnician)
        );
      })
    ) {
      alert("Technician was busy");
      setSelectTechnician("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectTechnician
      ).find((e) => e)?.id;

      setTechniciantemparr([
        ...Techniciantemparr,
        { Emp_id: emp_id, Name: SelectTechnician },
      ]);
      setSelectTechnician("");
    }
  };

  const DeleteTechnicianname = (Name) => {
    let Balancearr = Techniciantemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setTechniciantemparr(Balancearr);
    setSelectTechnician("");
  };

  const SubmitNursename = () => {
    if (SelectNurse === "") {
      alert("please enter the Nurse");
    } else if (Nursetemparr.some((e) => e.Name === SelectNurse)) {
      alert("You are alredy enter the Nurse");
      setSelectNurse("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Nurse.some((Nurse) => Nurse.Name.includes(SelectNurse));
      })
    ) {
      alert("Nurse was busy");
      setSelectNurse("");
    } else {
      let emp_id = Employeedataarr.filter((e) => e.Name === SelectNurse).find(
        (e) => e
      )?.id;

      setNursetemparr([...Nursetemparr, { Emp_id: emp_id, Name: SelectNurse }]);
      setSelectNurse("");
    }
  };

  const DeleteNursename = (Name) => {
    let Balancearr = Nursetemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setNursetemparr(Balancearr);
    setSelectNurse("");
  };

  const SubmitAnaesthetist_Requirements = () => {
    if (Medicine_Type === "") {
      alert("please enter the Medicine Type");
    }
    if (TabletORSyringedata === "") {
      alert("please enter the Tablet / Syringe Name");
    }
    if (AnaesthetistDose === "") {
      alert("please enter the Dose");
    } else if (
      Anaesth_Requ_data_arr.some(
        (e) =>
          e.MedicineType === Medicine_Type &&
          e.TabletORSyringe_Name === TabletORSyringedata
      )
    ) {
      alert("You are alredy enter the Tablet OR Syringe");
      SetMedicine_Type("");
      setTabletORSyringedata("");
      setAnaesthetistDose("");
    } else {
      setAnaesth_Requ_data_arr([
        ...Anaesth_Requ_data_arr,
        {
          MedicineType: Medicine_Type,
          TabletORSyringe_Name: TabletORSyringedata,
          Dose: AnaesthetistDose,
        },
      ]);
      SetMedicine_Type("");
      setTabletORSyringedata("");
      setAnaesthetistDose("");
      setFilterOPmedicineArr([]);
    }
  };

  const Delete_Anaesthetist_Req = (Name) => {
    console.log(Name);

    let Balancearr = Anaesth_Requ_data_arr.filter((ele) => {
      return ele.TabletORSyringe_Name !== Name;
    });
    setAnaesth_Requ_data_arr(Balancearr);
    setSelectAssSurgeon("");
    SetMedicine_Type("");
    setTabletORSyringedata("");
    setAnaesthetistDose("");
  };

  useEffect(() => {
    if (
      !!singlepasentdetaileobj &&
      Theatre_Booking_personal_information.some((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      })
    ) {
      // alert("Alredy OP Booked")

      let OP_p_detaile = Theatre_Booking_personal_information.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      let OP_D_detaile = Theatre_Booking_Doctors_Details.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      let OP_A_detaile = Theatre_Booking_Anaesthetist_Details.filter((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });
      console.log("yyy", OP_A_detaile);

      let OP_O_detaile = Theatre_Booking_Other_Details.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      setOpdate(OP_p_detaile.OPDate);
      setOptime(OP_p_detaile.OPtime);
      setTheatername(OP_p_detaile.Theatre_Name);
      setSelecetSpecialization(OP_p_detaile.Specialization);
      setSelecetDoctorename(OP_p_detaile.Doctor_Name);
      setOprationstarttime(OP_p_detaile.Start_Time);
      setOprationendtime(OP_p_detaile.End_Time);
      setOprationDurationhours(OP_p_detaile.Duration);
      setSurgeonstemparr(OP_D_detaile.surgeons);
      setAssSurgeontemparr(OP_D_detaile.Assistant_Surgeon);
      setAnaestheticiantemparr(OP_D_detaile.Anaesthetician);
      setTechniciantemparr(OP_D_detaile.Technician);
      setNursetemparr(OP_D_detaile.Nurse);
      setAnaesth_Requ_data_arr(OP_A_detaile);
      setPre_Opration_notes(OP_O_detaile.Pre_opration_detailes);
      setPost_Opration_notes(OP_O_detaile.Post_opration_detailes);
      setOT_Notes_input(OP_O_detaile.OT_Notes);
      setOT_Remarks_input(OP_O_detaile.Remarks);

      setEdit_btn_condition(true);
    }
  }, [singlepasentdetaileobj]);

  const Theater_Doctor_det_submit_fun = () => {
    const inputTime = Optimeinput;
    const hoursToAdd = parseInt(OprationDurationhours);
    console.log(inputTime);
    console.log(hoursToAdd);

    const timeParts = inputTime.split(":");
    const dateObj = new Date();
    dateObj.setHours(Number(timeParts[0]));
    dateObj.setMinutes(Number(timeParts[1]));

    // Add hours
    dateObj.setHours(dateObj.getHours() + hoursToAdd);

    // Format the result back to a string
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const End_time = `${hours}:${minutes}`;

    if (
      Theatre_Booking_personal_information.some((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      })
    ) {
      alert("Alredy OP Booked");
    }

    if (
      SearchIPno === "" ||
      Object.keys(singlepasentdetaileobj).length === 0 ||
      Object.keys(ipList).length === 0 ||
      Opdate === "" ||
      Optimeinput === "" ||
      Theatername === "" ||
      SelecetSpecialization === "" ||
      SelecetDoctorename === "" ||
      Oprationstarttime === "" ||
      OprationDurationhours === "" ||
      Surgeonstemparr.length === 0 ||
      AssSurgeontemparr.length === 0 ||
      Anaestheticiantemparr.length === 0 ||
      Techniciantemparr.length === 0 ||
      Nursetemparr.length === 0 ||
      phoneNo === "" ||
      Oprationendtime === ""
    ) {
      alert("Please Enter All data");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Theatre_Name === Theatername
        );
      })
    ) {
      alert("Theater Alredy Booked");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Doctor_Name === SelecetDoctorename
        );
      })
    ) {
      alert("Doctor Alredy Booked");
    } else {
      let HSI_NO = singlepasentdetaileobj?.HSI_NO;
      let PatientName = singlepasentdetaileobj?.PatientName;
      let Date = ipList?.Approved_Date;
      let Time = ipList?.Approve_Time;
      let PrimaryDoctor = singlepasentdetaileobj?.PrimaryDoctor;
      let Surgeon_Name = ipList?.Surgeon_Name;
      let PatientType = singlepasentdetaileobj?.PatientType;
      // let PatientGender = singlepasentdetaileobj?.PatientGender;
      let PatientPhoneNo = singlepasentdetaileobj?.PatientPhoneNo;
      // let PatientWardno = singlepasentdetaileobj?.WardName.BedNo;
      let PatientWardno = {
        wardName: singlepasentdetaileobj?.WardName,
        bedNo: singlepasentdetaileobj?.BedNo,
      };
      let PatientGender = {
        Gender: singlepasentdetaileobj?.Gender,
        Age: singlepasentdetaileobj?.Age,
      };
      console.log("sssss", PatientWardno.wardName); // Accessing the ward name
      console.log("ssssss", PatientWardno.bedNo);

      axios
        .post(
          "http://localhost:5000/OT_management_link/Theatre_Booking_personal_information",
          {
            SearchIPno,
            HSI_NO,
            Opdate,
            Optimeinput,
            PatientName,
            Date,
            Time,
            PatientPhoneNo,
            PrimaryDoctor,
            Surgeon_Name,
            PatientType,
            PatientGender,
            PatientWardno,
            Theatername,
            SelecetSpecialization,
            SelecetDoctorename,
            Oprationstarttime,
            Oprationendtime,
            OprationDurationhours,
            End_time,
          }
        )
        .then((response) => {
          // setTheatername("")
          // setSelecetSpecialization("")
          // setSelecetDoctorename("")
          // setOprationstarttime("")
          // setOprationDurationhours("")
          // setOpdate("")
          // setOptime("")
          // setSearchIPno("")
          // setpasentdetaileobj({
          //     "id":"",
          //     "IP_NO":"",
          //     "HSI_NO":"",
          //     "PatientName": "",
          //     "PatientGender": "",
          //     "PatientWardno":"",
          //     "patientType":""
          // })
          console.log(response);
          alert("Data successfully Submit");
        })
        .catch((error) => console.log(error));

      axios
        .post(
          "http://localhost:5000/OT_management_link/Theatre_Booking_Doctors_Details",
          {
            SearchIPno,
            Surgeonstemparr,
            AssSurgeontemparr,
            Anaestheticiantemparr,
            Techniciantemparr,
            Nursetemparr,
          }
        )
        .then((response) => {
          // setSurgeonstemparr([])
          // setAssSurgeontemparr([])
          // setAnaestheticiantemparr([])
          // setTechniciantemparr([])
          // setNursetemparr([])
          console.log(response);
          alert("Data successfully Submit");
          setpagesview(!pagesview);
        })
        .catch((error) => console.log(error));
    }
  };
  useEffect(() => {
    if (Oprationstarttime && Oprationendtime) {
      // Calculate the duration when both start and end times are selected
      const startTimeObj = new Date(`2024-01-01T${Oprationstarttime}`);
      const endTimeObj = new Date(`2024-01-01T${Oprationendtime}`);
      const timeDifference = endTimeObj - startTimeObj;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      setOprationDurationhours(hours);
    }
  }, [Oprationstarttime, Oprationendtime]);

  const Theater_Doctor_det_Update_fun = () => {
    const inputTime = Optimeinput;
    const hoursToAdd = parseInt(OprationDurationhours);

    const timeParts = inputTime.split(":");
    const dateObj = new Date();
    dateObj.setHours(Number(timeParts[0]));
    dateObj.setMinutes(Number(timeParts[1]));

    // Add hours
    dateObj.setHours(dateObj.getHours() + hoursToAdd);

    // Format the result back to a string
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const End_time = `${hours}:${minutes}`;

    if (
      SearchIPno === "" ||
      Object.keys(singlepasentdetaileobj).length === 0 ||
      Object.keys(ipList).length === 0 ||
      Opdate === "" ||
      Optimeinput === "" ||
      Theatername === "" ||
      SelecetSpecialization === "" ||
      SelecetDoctorename === "" ||
      Oprationstarttime === "" ||
      OprationDurationhours === "" ||
      Surgeonstemparr.length === 0 ||
      AssSurgeontemparr.length === 0 ||
      Anaestheticiantemparr.length === 0 ||
      Techniciantemparr.length === 0 ||
      Nursetemparr.length === 0
    ) {
      alert("Please Enter All data");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.IP_NO === SearchIPno &&
          ele.OPDate === Opdate &&
          ele.Theatre_Name === Theatername &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      })
    ) {
      alert("Theater Alredy Booked");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.IP_NO === SearchIPno &&
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Doctor_Name === SelecetDoctorename
        );
      })
    ) {
      alert("Doctor Alredy Booked");
    } else {
      let OP_Theatre_ID = Theatre_Booking_personal_information.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      }).OP_Theatre_ID;
      let Ip_No = singlepasentdetaileobj?.PatientId;
      let HSI_NO = singlepasentdetaileobj?.HSI_NO;
      let PatientName = singlepasentdetaileobj?.PatientName;
      let Date = ipList?.Approved_Date;
      let Time = ipList?.Approve_Time;
      let Surgeon_Name = ipList?.Surgeon_Name;
      let PatientPhoneNo = singlepasentdetaileobj?.PatientPhoneNo;
      let PatientType = singlepasentdetaileobj?.PatientType;
      // let PatientGender = singlepasentdetaileobj?.PatientGender;
      // let PatientWardno = singlepasentdetaileobj?.PatientWardno;
      let PatientGender = {
        Gender: singlepasentdetaileobj?.Gender,
        Age: singlepasentdetaileobj?.Age,
      };
      let PatientWardno = {
        wardName: singlepasentdetaileobj?.WardName,
        bedNo: singlepasentdetaileobj?.BedNo,
      };
      axios
        .post(
          `http://localhost:5000/OT_management_link/Theatre_Booking_personal_information_update/${OP_Theatre_ID}`,
          {
            SearchIPno,
            HSI_NO,
            Opdate,
            Optimeinput,
            PatientName,
            Date,
            Time,
            Surgeon_Name,
            PatientPhoneNo,
            PatientType,
            PatientGender,
            PatientWardno,
            Theatername,
            SelecetSpecialization,
            SelecetDoctorename,
            Oprationstarttime,
            OprationDurationhours,
            Oprationendtime,
            End_time,
          }
        )
        .then((response) => {
          // setTheatername("")
          // setSelecetSpecialization("")
          // setSelecetDoctorename("")
          // setOprationstarttime("")
          // setOprationDurationhours("")
          // // setSearchIPno("")
          // setOpdate("")
          // setOptime("")
          // setpasentdetaileobj({
          //     "id":"",
          //     "IP_NO":"",
          //     "HSI_NO":"",
          //     "PatientName": "",
          //     "PatientGender": "",
          //     "PatientWardno":"",
          //     "patientType":""
          // })

          console.log(response);
          alert("Data successfully Updated");
        })
        .catch((error) => console.log(error));

      axios
        .post(
          `http://localhost:5000/OT_management_link/Theatre_Booking_Doctors_Details_update/${OP_Theatre_ID}`,
          {
            SearchIPno,
            Surgeonstemparr,
            AssSurgeontemparr,
            Anaestheticiantemparr,
            Techniciantemparr,
            Nursetemparr,
          }
        )
        .then((response) => {
          // setSurgeonstemparr([])
          // setAssSurgeontemparr([])
          // setAnaestheticiantemparr([])
          // setTechniciantemparr([])
          // setNursetemparr([])
          console.log(response);
          alert("Data successfully Updated");
        })
        .catch((error) => console.log(error));

      setpagesview(!pagesview);
    }
  };

  const OT_after_OP_submit = () => {
    axios
      .post(
        "http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details",
        {
          SearchIPno,
          Anaesth_Requ_data_arr,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        "http://localhost:5000/OT_management_link/Theatre_Booking_Other_Details",
        {
          SearchIPno,
          Pre_Opration_notes,
          Post_Opration_notes,
          OT_Notes_input,
          OT_Remarks_input,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
        setpagesview(!pagesview);
        setValue(0);
        setTheatername("");
        setSelecetSpecialization("");
        setSelecetDoctorename("");
        setOprationstarttime("");
        setOprationDurationhours("");
        setSearchIPno("");
        setOpdate("");
        setOptime("");
        setpasentdetaileobj({
          id: "",
          IP_NO: "",
          HSI_NO: "",
          PatientName: "",
          PatientGender: "",
          PatientWardno: "",
          PatientType: "",
        });
        setIpList({
          Date: "",
          Time: "",
        });

        setSurgeonstemparr([]);
        setAssSurgeontemparr([]);
        setAnaestheticiantemparr([]);
        setTechniciantemparr([]);
        setNursetemparr([]);
        setAnaesth_Requ_data_arr([]);
        setPre_Opration_notes("");
        setPost_Opration_notes("");
        OT_Notes_input("");
        OT_Remarks_input("");
      })
      .catch((error) => console.log(error));
  };

  const OT_after_OP_update = () => {
    let OP_Theatre_ID = Theatre_Booking_personal_information.find((ele) => {
      return ele.IP_NO === parseInt(SearchIPno);
    }).OP_Theatre_ID;

    axios
      .delete(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details_update/${OP_Theatre_ID}`
      )
      .then((response) => {
        console.log(response);
        alert("Delete");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details_update/${OP_Theatre_ID}`,
        {
          SearchIPno,
          OP_Theatre_ID,
          Anaesth_Requ_data_arr,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Other_Details_update/${OP_Theatre_ID}`,
        {
          SearchIPno,
          Pre_Opration_notes,
          Post_Opration_notes,
          OT_Notes_input,
          OT_Remarks_input,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Ddddddd");
        setpagesview(!pagesview);
        setValue(0);
        setTheatername("");
        setSelecetSpecialization("");
        setSelecetDoctorename("");
        setOprationstarttime("");
        setOprationDurationhours("");
        setSearchIPno("");
        setOpdate("");
        setOptime("");
        setpasentdetaileobj({
          id: "",
          IP_NO: "",
          HSI_NO: "",
          PatientName: "",
          PatientPhoneNo: "",
          PatientGender: "",
          PatientWardno: "",
          PatientType: "",
        });
        setIpList({
          Date: "",
          Time: "",
        });

        setSurgeonstemparr([]);
        setAssSurgeontemparr([]);
        setAnaestheticiantemparr([]);
        setTechniciantemparr([]);
        setNursetemparr([]);
        setAnaesth_Requ_data_arr([]);
        setPre_Opration_notes("");
        setPost_Opration_notes("");
        OT_Notes_input("");
        OT_Remarks_input("");
      })
      .catch((error) => console.log(error));
  };

  const handlePlusDocter = () => {
    if (!SelecetDoctorename) {
      alert("Please select a doctor's name.");
      return;
    }

    setSelectedDoctors((prevDoctors) => [...prevDoctors, SelecetDoctorename]);
    setSelecetDoctorename("");
  };
  const handlePlusSurgeon = () => {
    if (!SelectSurgeon) {
      alert("Please select a surgeon's name.");
      return;
    }
    setSelectedSugeon((prevSurgeons) => [...prevSurgeons, SelectSurgeon]);
    setSelectSurgeon("");
  };
  const handlePlusAsstSurgeon = () => {
    if (!SelectAssSurgeon) {
      alert("Please select a Asst Surgeon's name.");
      return;
    }
    setAsstSelectedSugeon((prevAsstSurgeons) => [
      ...prevAsstSurgeons,
      SelectAssSurgeon,
    ]);

    setSelectAssSurgeon("");
  };
  const handlePlusAnesthesiologist = () => {
    if (!SelectAnaesthetician) {
      alert("Please select a Anesthesiologist name.");
      return;
    }
    setSelectedAnesthesiologist((prevAnesthesiologist) => [
      ...prevAnesthesiologist,
      SelectAnaesthetician,
    ]);

    setSelectAnaesthetician("");
  };
  const handlePlusAnesthesiaTechnician = () => {
    if (!SelectTechnician) {
      alert("Please select a Anesthesia Technician name.");
      return;
    }
    setSelectedAnesthesiaTechnician((prevAnesthesiaTechnician) => [
      ...prevAnesthesiaTechnician,
      SelectTechnician,
    ]);

    setSelectTechnician("");
  };
  const handlePlusNurse = () => {
    if (!SelectNurse) {
      alert("Please select a Nurse name.");
      return;
    }
    setSelectedNurse((prevNurse) => [...prevNurse, SelectNurse]);

    setSelectNurse("");
  };

  const handleDeleteDoctor = (index) => {
    setSelectedDoctors((prevDoctors) => {
      const updatedDoctors = [...prevDoctors];
      updatedDoctors.splice(index, 1); // Remove the doctor at the specified index
      return updatedDoctors;
    });
  };

  const handleDeleteSurgeon = (index) => {
    setSelectedSugeon((prevsurgeon) => {
      const updatedSurgeon = [...prevsurgeon];
      updatedSurgeon.splice(index, 1); // Remove the doctor at the specified index
      return updatedSurgeon;
    });
  };
  const handleDeleteAsstSurgeon = (index) => {
    setAsstSelectedSugeon((prevasstsurgeon) => {
      const updatedAsstSurgeon = [...prevasstsurgeon];
      updatedAsstSurgeon.splice(index, 1); // Remove the doctor at the specified index
      return updatedAsstSurgeon;
    });
  };
  const handleDeleteAnesthesiologist = (index) => {
    setSelectedAnesthesiologist((prevAnesthesiologist) => {
      const updatedprevAnesthesiologist = [...prevAnesthesiologist];
      updatedprevAnesthesiologist.splice(index, 1); // Remove the doctor at the specified index
      return updatedprevAnesthesiologist;
    });
  };
  const handleDeleteAnesthesiaTechnician = (index) => {
    setSelectedAnesthesiaTechnician((prevAnesthesiaTechnician) => {
      const updatedprevAnesthesiaTechnician = [...prevAnesthesiaTechnician];
      updatedprevAnesthesiaTechnician.splice(index, 1); // Remove the doctor at the specified index
      return updatedprevAnesthesiaTechnician;
    });
  };
  const handleDeleteNurse = (index) => {
    setSelectedNurse((prevnurse) => {
      const updatedNurse = [...prevnurse];
      updatedNurse.splice(index, 1); // Remove the doctor at the specified index
      return updatedNurse;
    });
  };

  const handleEditClick1 = (params) => {
    console.log(params.row);
    setTheatername(params.row.TheatreName);
    setSearchIPno(params.row.IP_No);
    setMrnNo(params.row.MRN_NO);
    setPatientName(params.row.Patient_Name);
    setPatientType(params.row.Patient_Type);
    setOpdate(params.row.Date);
    setOptime(params.row.Time);
    setGender(params.row.Gender);
    setAge(params.row.Age);
    setWard(params.row.Ward);
    setBedNo(params.row.BedNo);
    setTheatername(params.row.TheatreName);
    setSelecetSpecialization(params.row.Specialization);
    setOprationstarttime(params.row.StartTime);
    setOprationendtime(params.row.EndTime);
    setOprationDurationhours(params.row.Duration);
    setPhoneNo(params.row.Phone_No);
    setLocationName(params.row.Location);
    const surgeon = params.row.Surgeon.split(", ");

    surgeon.forEach((name) => {
      setSelectedSugeon((prev) => [...prev, name.trim()]);
    });
    const doctor = params.row.DoctorName.split(", ");
    doctor.forEach((name) => {
      setSelectedDoctors((prev) => [...prev, name.trim()]);
    });
    const asstsurgeon = params.row.AsstSurgeonName.split(", ");
    asstsurgeon.forEach((name) => {
      setAsstSelectedSugeon((prev) => [...prev, name.trim()]);
    });
    const anesthesiologist = params.row.AnesthesiologistName.split(", ");
    anesthesiologist.forEach((name) => {
      setSelectedAnesthesiologist((prev) => [...prev, name.trim()]);
    });
    const anesthesiatechnician = params.row.AnesthesiaTechnician.split(", ");
    anesthesiatechnician.forEach((name) => {
      setSelectedAnesthesiaTechnician((prev) => [...prev, name.trim()]);
    });
    const nurse = params.row.NurseName.split(", ");
    nurse.forEach((name) => {
      setSelectedNurse((prev) => [...prev, name.trim()]);
    });
  };

  const coloumnss = [
    {
      field: "Ot_Theater_Booking_Id",
      headerName: "Ot_Theater_Booking_Id",
      width: 170,
    },
    { field: "IP_No", headerName: "IP No", width: 50 },
    { field: "MRN_NO", headerName: "MRN NO ", width: 80 },
    { field: "Phone_No", headerName: "Phone No", width: 80 },
    { field: "Patient_Name", headerName: "Patient Name", width: 130 },
    { field: "Patient_Type", headerName: "Patient Type", width: 130 },
    { field: "Gender", headerName: "Gender", width: 100 },
    { field: "Age", headerName: "Age", width: 30 },
    { field: "Date", headerName: "Date", width: 50 },
    { field: "Time", headerName: "Time", width: 50 },
    { field: "Ward", headerName: "Ward No", width: 60 },
    { field: "BedNo", headerName: "Bed No", width: 60 },
    { field: "TheatreName", headerName: "TheatreName", width: 100 },
    { field: "Specialization", headerName: "Specialization", width: 140 },
    { field: "DoctorName", headerName: "Doctor Name", width: 130 },
    { field: "StartTime", headerName: "StartTime", width: 100 },
    { field: "EndTime", headerName: "EndTime", width: 100 },
    { field: "Duration", headerName: "Duration", width: 100 },
    { field: "Surgeon", headerName: "Surgeon", width: 100 },
    { field: "AsstSurgeonName", headerName: "AsstSurgeonName", width: 160 },
    {
      field: "AnesthesiologistName",
      headerName: "Anesthesiologist Name",
      width: 160,
    },
    {
      field: "AnesthesiaTechnician",
      headerName: "Anesthesia Technician",
      width: 160,
    },
    { field: "NurseName", headerName: "Nurse Name", width: 100 },
    { field: "Location", headerName: "Location", width: 100 },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn">
            <Edit onClick={() => handleEditClick1(params)} />
          </Button>
        </>
      ),
    },
  ];
  const handleSave = () => {
    const parms = {
      Ip_No: singlepasentdetaileobj?.PatientId || SearchIPno,
      MRN_NO: singlepasentdetaileobj?.Booking_Id || mrnNo,
      PatientName: singlepasentdetaileobj?.PatientName || patientName,
      PatientType: singlepasentdetaileobj?.PatientType || patientType,
      phoneNo: singlepasentdetaileobj?.PatientPhoneNo || phoneNo,
      Date: ipList?.Approved_Date || Opdate,
      Time: ipList?.Approve_Time || Optimeinput,
      Gender: singlepasentdetaileobj?.Gender || gender,
      Age: singlepasentdetaileobj?.Age || age,
      WardNo: singlepasentdetaileobj?.WardName || ward,
      BedNo: singlepasentdetaileobj?.BedNo || bedNo,
      Theatername: Theatername,
      Specialization: SelecetSpecialization,
      SelectedDoctors: SelectedDoctors,
      StartTime: Oprationstarttime,
      EndTime: Oprationendtime,
      Duration: OprationDurationhours,
      SelectedSugeon: SelectedSugeon,
      AsstSelectedSugeon: AsstSelectedSugeon,
      SelectedAnesthesiologist: SelectedAnesthesiologist,
      SelectedAnesthesiaTechnician: SelectedAnesthesiaTechnician,
      SelectedNurse: SelectedNurse,
      locationName: locationName,
    };

    axios
      .post(
        "https://vesoftometic.co.in/ipregistration/insert_Booking_Request",
        parms
      )
      .then((response) => {
        console.log(response);
        setpasentdetaileobj({});
        setIpList({});
        setSearchIPno("");
        setMrnNo("");
        setPatientName("");
        setPatientType("");
        setPhoneNo("");
        setOpdate("");
        setOptime("");
        setGender("");
        setAge("");
        setWard("");
        setBedNo("");
        setTheatername("");
        setSelecetSpecialization("");
        setSelectedDoctors([]);
        setOprationstarttime("");
        setOprationendtime("");
        setOprationDurationhours("");
        setSelectedSugeon([]);
        setAsstSelectedSugeon([]);
        setSelectedAnesthesiologist([]);
        setSelectedAnesthesiaTechnician([]);
        setSelectedNurse([]);
        setLocationName("");
      })
      .catch((error) => {
        console.log("otbooking :", error);
      });
  };
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  useEffect(() => {
    axios
      .get("https://vesoftometic.co.in/ipregistration/get_Booking_Request")
      .then((response) => {
        const data = response.data;
        console.log("ot Theater Booking data", data);

        setBookingData([
          ...data.map((row, index) => ({
            id: index + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.log("error fetching ottheater booking data:", error);
      });
  }, []);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Theatre Booking</h4>
        </div>
        <br />

        <div className="Theatre_booking_con">
          <div className="Theatre_booking_con_form">
            <div className="RegisFormcon">
              <div className="RegisForm_1 input-with-icon">
                <label>
                  IP No<span>:</span>
                </label>
                <input
                  type="text"
                  value={singlepasentdetaileobj?.PatientId || SearchIPno}
                  onChange={(e) => {
                    setSearchIPno(e.target.value);
                  }}
                />
                <button
                  className="searching_input_icon3"
                  onClick={handlepatientsearch}
                >
                  <SearchIcon />
                </button>
              </div>

              <div className="RegisForm_1">
                <label>
                  MRN No<span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.Booking_Id || mrnNo}
                  type="text"
                  onChange={(e) => {
                    setMrnNo(e.target.value);
                  }}
                />
              </div>
              <div className="RegisForm_1 input-with-icon">
                <label>
                  Phone Number<span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.PatientPhoneNo || phoneNo}
                  type="number"
                  onChange={(e) => {
                    setPhoneNo(e.target.value);
                  }}
                />
                <button
                  className="searching_input_icon3"
                  onClick={handlepatientsearch}
                >
                  <SearchIcon />
                </button>
              </div>
              <div className="RegisForm_1">
                <label>
                  Patient Name<span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.PatientName || patientName}
                  type="text"
                  onChange={(e) => {
                    setPatientName(e.target.value);
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Patient Type<span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.PatientType || patientType}
                  type="text"
                  onChange={(e) => {
                    setPatientType(e.target.value);
                  }}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Date<span>:</span>
                </label>
                <input
                  type="date"
                  value={ipList?.Approved_Date || Opdate}
                  onChange={(e) => {
                    setOpdate(e.target.value);
                  }}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Time<span>:</span>
                </label>
                <input
                  type="time"
                  value={ipList?.Approve_Time || Optimeinput}
                  onChange={(e) => {
                    setOptime(e.target.value);
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Age/Sex <span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.Age || age}
                  type="text"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  style={{ width: "20px" }}
                />
                <input
                  value={singlepasentdetaileobj?.Gender || gender}
                  type="text"
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  style={{ width: "110px" }}
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Ward / Bed No <span>:</span>
                </label>
                <input
                  value={singlepasentdetaileobj?.BedNo || bedNo}
                  type="text"
                  onChange={(e) => {
                    setBedNo(e.target.value);
                  }}
                  style={{ width: "20px" }}
                />
                <input
                  value={singlepasentdetaileobj?.WardName || ward}
                  type="text"
                  onChange={(e) => {
                    setWard(e.target.value);
                  }}
                  style={{ width: "110px" }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Theatre Name<span>:</span>
                </label>
                <input
                  type="text"
                  list="TheaterList"
                  onMouseOver={() => setShowSelectOptions(true)}
                  onMouseOut={() => setShowSelectOptions(false)}
                  value={Theatername}
                  onChange={(e) => {
                    setTheatername(e.target.value);
                  }}
                />

                <datalist id="TheaterList">
                  {Theaternamelist.map((theater, index) => (
                    <option key={index} value={theater.Theatre_No} />
                  ))}
                </datalist>
              </div>

              <div className="RegisForm_1">
                <label>
                  Specialization<span>:</span>
                </label>
                <input
                  type="text"
                  value={SelecetSpecialization}
                  onChange={(e) => {
                    setSelecetSpecialization(e.target.value);
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Doctor Name<span>:</span>
                </label>
                <input
                  list="DoctorsnameList"
                  value={SelecetDoctorename}
                  className="with_add_dctr_thrtebook"
                  onChange={(e) => {
                    setSelecetDoctorename(e.target.value);
                  }}
                />

                <datalist id="DoctorsnameList">
                  {Doctordetailearr?.map((doctor) => (
                    <option
                      key={doctor?.EmployeeID}
                      value={doctor.EmployeeName}
                    ></option>
                  ))}
                </datalist>
                <button className="Addnamebtn2222" onClick={handlePlusDocter}>
                  +
                </button>
              </div>

              <div className="RegisForm_1">
                <label>
                  Start Time<span>:</span>
                </label>
                <input
                  type="time"
                  value={Oprationstarttime}
                  onChange={(e) => {
                    setOprationstarttime(e.target.value);
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Duration(.hrs)<span>:</span>
                </label>
                <input
                  type="number"
                  value={OprationDurationhours}
                  onChange={(e) => {
                    setOprationDurationhours(e.target.value);
                  }}
                  min="1"
                />{" "}
              </div>

              <div className="RegisForm_1">
                <label>
                  Surgeon<span>:</span>
                </label>
                <input
                  type="text"
                  list="SurgeonnameList"
                  className="with_add_dctr_thrtebook"
                  value={ipList?.Surgeon_Name || SelectSurgeon}
                  onChange={(e) => {
                    setSelectSurgeon(e.target.value);
                  }}
                />
                <datalist id="SurgeonnameList">
                  {Surgeonstemparr.map((surgeon) => (
                    <option
                      key={surgeon?.EmployeeID}
                      value={surgeon.EmployeeName}
                    ></option>
                  ))}
                </datalist>

                <button className="Addnamebtn2222" onClick={handlePlusSurgeon}>
                  +
                </button>
              </div>
              <div className="RegisForm_1">
                <label>
                  Asst.Surgeon<span>:</span>
                </label>
                <input
                  type="text"
                  list="Ass_SurgeonnameList"
                  className="with_add_dctr_thrtebook"
                  value={SelectAssSurgeon}
                  onChange={(e) => {
                    setSelectAssSurgeon(e.target.value);
                  }}
                />
                <datalist id="Ass_SurgeonnameList">
                  {AssSurgeontemparr.map((asstsurgeon) => (
                    <option
                      key={asstsurgeon?.EmployeeID}
                      value={asstsurgeon.EmployeeName}
                    ></option>
                  ))}
                </datalist>

                <button
                  className="Addnamebtn2222"
                  onClick={handlePlusAsstSurgeon}
                >
                  +
                </button>
              </div>

              <div className="RegisForm_1">
                <label>
                  Anesthesiologist<span>:</span>
                </label>
                <input
                  type="text"
                  list="AnaestheticianList"
                  className="with_add_dctr_thrtebook"
                  value={SelectAnaesthetician}
                  onChange={(e) => {
                    setSelectAnaesthetician(e.target.value);
                  }}
                />
                <datalist id="AnaestheticianList">
                  {(Array.isArray(Anaestheticiantemparr)
                    ? Anaestheticiantemparr
                    : []
                  ).map((ass) => (
                    <option
                      key={ass?.EmployeeID}
                      value={ass?.EmployeeName}
                    ></option>
                  ))}
                </datalist>

                <button
                  className="Addnamebtn2222"
                  onClick={handlePlusAnesthesiologist}
                >
                  +
                </button>
              </div>

              <div className="RegisForm_1">
                <label>
                  Anesthesia Technician<span>:</span>
                </label>
                <input
                  type="text"
                  list="TechnicianList"
                  className="with_add_dctr_thrtebook"
                  value={SelectTechnician}
                  onChange={(e) => {
                    setSelectTechnician(e.target.value);
                  }}
                />
                <datalist id="TechnicianList">
                  {(Array.isArray(Techniciantemparr)
                    ? Techniciantemparr
                    : []
                  ).map((as) => (
                    <option
                      key={as?.EmployeeID}
                      value={as.EmployeeName}
                    ></option>
                  ))}
                </datalist>

                <button
                  className="Addnamebtn2222"
                  onClick={handlePlusAnesthesiaTechnician}
                >
                  +
                </button>
              </div>
              <div className="RegisForm_1">
                <label>
                  Nurse<span>:</span>
                </label>
                <input
                  type="text"
                  list="NurseList"
                  className="with_add_dctr_thrtebook"
                  value={SelectNurse}
                  onChange={(e) => {
                    setSelectNurse(e.target.value);
                  }}
                />
                <datalist id="NurseList">
                  {nurseArray.map((nurse) => (
                    <option
                      key={nurse?.EmployeeID}
                      value={nurse.EmployeeName}
                    />
                  ))}
                </datalist>
                <button className="Addnamebtn2222" onClick={handlePlusNurse}>
                  +
                </button>
              </div>

              <div className="RegisForm_1">
                <label>
                  End Time<span>:</span>
                </label>
                <input
                  type="time"
                  value={Oprationendtime}
                  onChange={(e) => {
                    setOprationendtime(e.target.value);
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Location<span>:</span>
                </label>
                <input
                  type="text"
                  list="locationlist"
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                />
                <datalist id="locationlist">
                  {locationlist.map((location, index) => (
                    <option
                      key={index}
                      value={`${location.Block}/${location.Floor}`}
                    />
                  ))}
                </datalist>
              </div>
            </div>
          </div>
        </div>

        <br />

        {SelectedDoctors.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45">Doctor Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {SelectedDoctors.map((doctor, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td id="vital_Twidth45">{doctor}</td>
                    <td>
                      <FaTrash onClick={() => handleDeleteDoctor(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <br />
        <br />

        {SelectedSugeon.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45">Surgeon Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {SelectedSugeon.map((sugeon, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td id="vital_Twidth45">{sugeon}</td>
                    <td>
                      <FaTrash onClick={() => handleDeleteSurgeon(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <br></br>

        {AsstSelectedSugeon.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45">Asst Surgeon Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {AsstSelectedSugeon.map((asstsurgeon, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td id="vital_Twidth45">{asstsurgeon}</td>
                    <td>
                      <FaTrash onClick={() => handleDeleteAsstSurgeon(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <br></br>
        {SelectedAnesthesiologist.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45"> Anesthesiologist Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {SelectedAnesthesiologist.map((anesthesiologist, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td id="vital_Twidth45">{anesthesiologist}</td>
                    <td>
                      <FaTrash
                        onClick={() => handleDeleteAnesthesiologist(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <br></br>
        {SelectedAnesthesiaTechnician.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45"> Anesthesia Technician</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {SelectedAnesthesiaTechnician.map(
                  (anesthesiaTechnician, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td id="vital_Twidth45">{anesthesiaTechnician}</td>
                      <td>
                        <FaTrash
                          onClick={() =>
                            handleDeleteAnesthesiaTechnician(index)
                          }
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
        <br></br>
        {SelectedNurse.length > 0 && (
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S No</th>
                  <th id="vital_Twidth45"> Nurse Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {SelectedNurse.map((nurse, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nurse}</td>
                    <td>
                      <FaTrash onClick={() => handleDeleteNurse(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            Submit
          </button>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={bookingData.slice(page * pageSize, (page + 1) * pageSize)}
                pageSize={10}
                columns={coloumnss} // You need to define your dynamic columns here
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="Ip_data_grid"
              />
              {showdown > 0 && bookingData.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && bookingData.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TheatreBooking;
