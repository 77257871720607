import React, { useState, useEffect } from 'react';
import './Organization.css';
import axios from 'axios';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from 'react-redux';
function ReflexMaster() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  // Initialize state for CPT and CPRT
  const [mainTest, setMainTest] = useState("");
  const [reflexTest, setReflexTest] = useState("");
  const [reflexCode, setReflexCode] = useState("");
  const [reflexData, setReflexData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);

  const [testname, setTestName] = useState("");

  // Handle changes in CPT input
  const handlemainTestChange = (event) => {
    setMainTest(event.target.value);
  };

  // Handle changes in CPRT input
  const handlereflexTestChange = (event) => {
    setReflexTest(event.target.value);
  };
  const handlereflexCodeChange = (event) => {
    setReflexCode(event.target.value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReflexData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  

  useEffect(()=>{
    axios.get(`${urllink}usercontrol/gettestname`)
    .then((response)=>{
        console.log(response.data)
        const data= response.data.test_name
        setTestName(data);
    })
    .catch((error)=>{
        console.log(error)
    })
},[])

  const handlesubmit =()=>{
    const postdata={
      maintest: mainTest,
      reflextestname: reflexTest,
      reflexcode:reflexCode,
      testname: testname
    }

    if(!mainTest || !reflexTest || !reflexCode){
      alert("Please Enter All data")
    }
    else{
      axios.post(`${urllink}usercontrol/insertreflexdata`,postdata)
      .then((response)=>{
        console.log(response)
        setMainTest(''); 
        setReflexCode('');
        setTestName('');
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  };
  React.useEffect(() => {
    fetchReflexData();
  }, []);
  const fetchReflexData = () => {
    axios
      .get(`${urllink}usercontrol/getreflexdata`)
      .then((response) => {
        const data = response.data;
        console.log("fetchReflexData", data);

        setReflexData(data);
      })
      .catch((error) => {
        console.error("Error fetching Reflex data:", error);
      });
  };
  const handleEdit = (row) => {
    setMainTest(row.Main_Test);
    setReflexTest(row.Reflex_Test);
    setMainTest(row.Main_Test);
    setReflexCode(row.Reflex_Code);
    setTestName(row.Test_Name);
    setIsEditMode(true);
    setSelectedid(row.Test_id); // Assuming `id` is the identifier
  };

  const handleUpdateMethod = () => {
    const updatedata = {
      method_id: selectedid, // Assuming `id` is the identifier
      mainTest: mainTest,
      reflexTest: reflexTest,
      reflexCode:reflexCode,
      testname: testname,
    };

    axios
      .post(
        `${urllink}usercontrol/updatereflexdata`,
        updatedata
      )
      .then((response) => {
        console.log(response.data);
        setIsEditMode(false);
        setSelectedid(null);
        setMainTest("");
        setReflexTest("");
        setReflexCode("");
        setTestName("");
        fetchReflexData();
      })

      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <div className="appointment">

        <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="selectTest">
            Test Name<span>:</span>
          </label>
          <input
            type="text"
            id="testname"
            name="selectTest"
            // placeholder="Enter Test Name"
            // className="new_clinic_form_inp"
            required
            value={testname}
            onChange={handleInputChange}
            readOnly
          />
        </div>
          {/* <div className="RegisForm_1">
            <label htmlFor="productName" >
              MainTest<span>:</span>
            </label>
            <input
              type="text"
              id="maintest"
              name="mainTest"
              // placeholder="Enter MainTest"
              value={mainTest}
              onChange={handlemainTestChange} // Handle changes in CPT input
              required
            />
          </div> */}

          <div className="RegisForm_1">
            <label htmlFor="productType" >
              ReflexTest<span>:</span>
            </label>
            <input
              type="text"
              id="reflexTest"
              name="reflexTest"
              // placeholder="Enter ReflexTest"
              value={reflexTest}
              onChange={handlereflexTestChange} // Handle changes in CPRT input
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="productType" >
              ReflexCode<span>:</span>
            </label>
            <input
              type="text"
              id="reflexCode"
              name="reflexCode"
              // placeholder="Enter ReflexCode"
              value={reflexCode}
              onChange={handlereflexCodeChange} // Handle changes in CPRT input
              required
            />
          </div>
        </div>
        <div className='Register_btn_con'>
        <button
          className="btn_1"
          onClick={isEditMode ? handleUpdateMethod : handlesubmit}
        >
          {isEditMode ? "Update" : <AddIcon />}
        </button>
      </div>
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <h4>Table</h4>

        <div className="Selected-table-container ">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Test Name</th>
                {/* <th>Main Test</th> */}
                <th>Reflex Test</th>
                <th>Redflex Code</th>

                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {reflexData.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.Test_Name}</td>
                  {/* <td>{row.Main_Test}</td> */}
                  <td>{row.Reflex_Test}</td>
                  <td>{row.Reflex_Code}</td>

                  <td>
                    <button onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    
    </div>
  );
}

export default ReflexMaster;

