import React, { useState, useEffect } from 'react';
import './Organization.css';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
function AgeSetUp() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [testname,setTestName]=useState()
  const [ageSetupData, setAgeSetupData] = useState({
    gender:'',
    fromAge: '',
    ageType: 'day',
    toAge: '',
    toAgeType: 'day',
    fromvalue:'',
    tovalue:'',
    panicLow: '',
    panicHigh: '',
  });


const dispatchvale = useDispatch();

dispatchvale({type: "Agesetup" ,value:ageSetupData})

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAgeSetupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(()=>{
    axios.get(`${urllink}usercontrol/gettestname`)
    .then((response)=>{
        console.log(response.data)
       const data= response.data.test_name
        setTestName(data)
    })
    .catch((error)=>{
        console.log(error)
    })
},[])

const handleSubmit = () => {
  // Basic validation check for mandatory fields
  if (!testname || !ageSetupData.gender || !ageSetupData.fromAge || !ageSetupData.toAge || !ageSetupData.fromvalue || !ageSetupData.tovalue || !ageSetupData.panicLow || !ageSetupData.panicHigh) {
    console.log('Mandatory fields are missing');
    // Consider using a more user-friendly way to notify about the error, such as setting an error state and displaying it in the UI
    return;
  }

  const postData = {
    ...ageSetupData,
    testname, // Ensure this matches the backend expected field name, adjust if necessary
  };

  axios.post(`${urllink}usercontrol/insertagesetup`, postData)
    .then((response) => {
      console.log(response.data);
      successMsg(response.data.message);
      setAgeSetupData({
        gender: '',
        fromAge: '',
        ageType: 'day',
        toAge: '',
        toAgeType: 'day',
        fromvalue: '',
        tovalue: '',
        panicLow: '',
        panicHigh: '',
      });
    })
    .catch((error) => {
      // errmsg(error)
      console.log(error);
    });
};



  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  }


  return (
    <div className="Clinic_det_new">
      
      <div className="bill_table_invoice">
        <div className='new_user_items'>
          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="selectTest">
                 Test Name<span>:</span>
              </label>
              <input
                type="text"
                id="testname"
                name="selectTest"
                placeholder="Enter Test Name"
                className="new_clinic_form_inp"
                required
                value={testname}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="selectTest">
                 Gender<span>:</span>
              </label>
              <select
                id="gender"
                name="gender"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.gender}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Baby">Baby</option>
                <option value="Both">Both</option>
              </select>
              
            </div>
          </div>

          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="fromAge">
                From Age<span>:</span>
              </label>
              <input
                type="number"
                id="fromAge"
                name="fromAge"
                placeholder="Enter Age"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.fromAge}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="ageType">
                Type<span>:</span>
              </label>
              <select
                id="ageType"
                name="ageType"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.ageType}
                onChange={handleInputChange}
              >
                <option value="day">Day(s)</option>
                <option value="month">Month(s)</option>
                <option value="year">Year(s)</option>
              </select>
            </div>
          </div>

          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="toAge">
                To Age<span>:</span>
              </label>
              <input
                type="number"
                id="toAge"
                name="toAge"
                placeholder="Enter Age"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.toAge}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="toAgeType">
                Type<span>:</span>
              </label>
              <select
                id="toAgeType"
                name="toAgeType"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.toAgeType}
                onChange={handleInputChange}
              >
                <option value="day">Day(s)</option>
                <option value="month">Month(s)</option>
                <option value="year">Year(s)</option>
              </select>
            </div>
          </div>
          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicLow">
                From Value<span>:</span>
              </label>
              <input
                type="number"
                id="fromvalue"
                name="fromvalue"
                placeholder="Enter From Value"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.fromvalue}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicHigh">
                To Value<span>:</span>
              </label>
              <input
                type="number"
                id="tovalue"
                name="tovalue"
                placeholder="Enter To Value"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.tovalue}
                onChange={handleInputChange}
              />
            </div>
          </div>


          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicLow">
                Panic Low<span>:</span>
              </label>
              <input
                type="number"
                id="panicLow"
                name="panicLow"
                placeholder="Enter Panic Low"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.panicLow}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicHigh">
                Panic High<span>:</span>
              </label>
              <input
                type="number"
                id="panicHigh"
                name="panicHigh"
                placeholder="Enter Panic High"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.panicHigh}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='Register_btn_con'>
            <button className='RegisterForm_1_btns' onClick={handleSubmit}>Submit</button>
          </div>
          <ToastContainer />

        </div>
      </div>
    </div>
  );
}

export default AgeSetUp;
