import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from 'react-router-dom'
import { colors } from '@mui/material';
import { red } from '@mui/material/colors';
import { useDispatch } from 'react-redux';

function Reportcapture({ userRecord, isSidebarOpen }) {
  const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [visitid, setvisitid] = useState()
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log('capturedatas', capturedatas)

  const dispatchvalue = useDispatch()

  const navigate = useNavigate()


  useEffect(() => {

    axios.get(`http://127.0.0.1:8000/Billing/getcompletedsample?Billinginvoice=${capturedatas?.Billing_Invoice}`)
      .then((response) => {
        console.log('------------',response)
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testcode: item.Test_Code,
          testname: item.Test_Name,
          testnames: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.PanicLow,
          panichigh: item.PanicHigh,
          Calculation: item.Calculation,
          Formula: item.Formula,
          patient_name: capturedatas.Patient_Name,
          Billing_Invoice: capturedatas.Billing_Invoice,
          barcodedata: capturedatas.Barcode_id,
          parameterone: item.Parameterone,
          parametertwo: item.parametertwo,
          uom: item.UOM,


          // You don't need to explicitly include the 'actions' field here
        }));
        setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas.Billing_Invoice]);




const handleRemarksChange=(index, newValue)=>{
  
const updatedvall=[...testDetails]
updatedvall[index].capturevalue=newValue
setTestDetails(updatedvall)
   
}
const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();


useEffect(() => {
  const updatedTestDetails = testDetails.map((test) => {
    if (test.Calculation === "Yes") {
      const formula = test.Formula.trim();
      const parts = tokenizeFormula(formula); // Tokenize formula excluding parentheses and operators
     
      const parameters = {};
      
      // Populate parameters with values from testDetails
      parts.forEach(element => {
        if (isNaN(element)) {
          const parameterTest = testDetails.find(item => item.testname === element);
          parameters[element] = parameterTest ? parseFloat(parameterTest.capturevalue) || 0 : 0;
        }
      });
      
      // Evaluate expression using parameters
      return evaluateExpression(formula, parameters)
        .then(result => {
          console.log('res........', result);
          return { ...test, capturevalue: parseFloat(result) || 0 }; // Return a new object with updated capturevalue
        })
        .catch(error => {
          console.error('Error evaluating expression:', error);
          return { ...test, capturevalue: 0 }; // Return a new object with capturevalue set to 0 in case of error
        });
    }
    return test;
  });
  
  // Wait for all promises to resolve
  Promise.all(updatedTestDetails)
    .then(updatedTestDetailsResolved => {
      // Check if the updatedTestDetailsResolved is different from the current state
      const isTestDetailsChanged = JSON.stringify(updatedTestDetailsResolved) !== JSON.stringify(testDetails);

      // Update the state only if the testDetails has changed
      if (isTestDetailsChanged) {
        setTestDetails(updatedTestDetailsResolved);
      }
    });
}, [testDetails]); // Depend on testDetails to trigger the effect

async function evaluateExpression(tokens, parameters) {
  console.log(parameters);
  try {
    const response = await axios.get('http://127.0.0.1:8000/Billing/getformula_calculated_value', {
      params: {
        formula: tokens,
        parameters: JSON.stringify(parameters)
      }
    });
    console.log(response.data);
    const currentOperand = response.data?.valll;
    console.log(currentOperand, '0000000000000000');
    return parseFloat(currentOperand) || 0;
  } catch (error) {
    console.error('Error fetching expression:', error);
    return 0;
  }
}


function tokenizeFormula(formula) {
  // Add spaces around operators and parentheses to tokenize them properly
  formula = formula.replace(/([+\-*/%^()])/g, ' $1 ');
  // Split by spaces to get tokens
 
  const tokens = formula.split(/\s+/).filter(token => token.trim() !== '');

  // Remove parentheses and operators from tokens
  return tokens.filter(token => !['(', ')', '+', '-', '*', '/', '%', '^'].includes(token));
}










  const handlemedicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].medicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handletecnicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].technicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };


  const handleSubmitAnalyseReport = () => {
    // console.log(filteredRows);
    const postdata = {
      ...testDetails,
      Billing_Invoice: capturedatas.Billing_Invoice,
      visitid: capturedatas.Visit_Id,
      patient_Id: capturedatas.Patient_Id

    }




    console.log(postdata)

    axios.post('http://127.0.0.1:8000/Phelobotomist/insert_analyse_investigation_result', postdata)
      .then((response) => {
        console.log(response)
        dispatchvalue({ "type": "Navigationlab", value: "" });
        navigate('/Home/AnalayseReportEntry');
      })
      .catch((error) => {
        console.log(error)
      })
  }










  const capture = testDetails.map((i) => i.capturevalue)
  console.log('capture', capture)



  return (
    <>
      <div className="samplecapture_component">
        {/* <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Analysis Report Entry</h3>
          </div>
        </div> */}



        <div className="samplecapture_table_for_phelobotomist">
          <table className='table_for_plebotomist_sample'>
            <thead>
              <tr>
                <th>Test Name</th>
                <th>Specimen</th>
                <th>Capture Value</th>
                <th>Reference Range</th>
                <th>Indicator</th>
                <th>Remarks
                  <table className='remarktable'>
                    <tr>
                      <th>Technical</th>
                      <th>Medical</th>
                    </tr>
                  </table>
                </th>



              </tr>
            </thead>

            <tbody>
              {testDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.testname}</td>
                  <td>
                    {detail.Specimen_Name}
                  </td>
                  <td>
                    {
                      detail.Calculation === 'Yes' ?
                        detail.capturevalue :
                        <input type="number" name="" id="" className='Capture_Status_select1' onKeyDown={blockInvalidChar} value={detail.capturevalue} onChange={(e) => { handleRemarksChange(index, e.target.value) }} />
                    }
                  </td>
                  <td>
                    {detail.paniclow}-{detail.panichigh}{detail.uom}
                  </td>
                  <td>
                    <span >
                      {Number(detail.capturevalue) >= (detail.panichigh) ? (<span className='indicator'><TiArrowUpThick style={{ color: red }} /></span>) : Number(detail.capturevalue) <= (detail.paniclow) ? (<span className='indicatorlow'> <TiArrowDownThick /></span>) : <p>Normal</p>}
                    </span>
                  </td>
                  <td>
                    <table className='remarktable'>
                      <tr>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            value={detail.technicalRemark}
                            className='table_textarea'
                            onChange={(e) => handletecnicalRemarksChange(index, e.target.value)}
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            value={detail.medicalRemark}
                            className='table_textarea'
                            onChange={(e) => handlemedicalRemarksChange(index, e.target.value)}
                          ></textarea>
                        </td>
                      </tr>
                    </table>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default Reportcapture;



