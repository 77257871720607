import React, { useEffect, useState, useCallback } from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useSelector } from 'react-redux';

const LeaveApproval = () => {

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchQuery1, setSearchQuery1] = useState('');
  // const [searchQuerypre, setSearchQuerypre] = useState('')
  // const [searchQuerypre1, setSearchQuerypre1] = useState('')
  const [showsudden, setshowsudden] = useState(false)
  const [leaveCounts, setLeaveCounts] = useState({});
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const [expanded, setExpanded] = useState("panel1");
  // const [filteredRows, setFilteredRows] = useState([]);
  // const [filteredRows1, setFilteredRows1] = useState([]);
  // const [rolename, setRolename] = useState([]);
  const [status, setStatus] = useState('');
  const [reject, setReject] = useState('');
  // const [rows12, setRows12] = useState([]);
  const [permissionLeaveCounts, setpermissionLeaveCounts] = useState([]);
  const [prevleavedetails, setprevleavedetails] = useState([]);


  // useEffect(() => {
  //   axios
  //     .get(`${urllink}HRmanagement/getRole_all`)
  //     .then((response) => {
  //       console.log(response.data);
  //       setRolename(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [userRecord?.location]);






  const fetchData =useCallback( async () => {
    try {
      const url = `${urllink}HRmanagement/get_leave_register_data?location=${userRecord?.location}`;
      const leaveRegisterResponse = await fetch(url);

      if (!leaveRegisterResponse.ok) {
        throw new Error(`HTTP error! Status: ${leaveRegisterResponse.status}`);
      }

      const leaveRegisterData = await leaveRegisterResponse.json();

      let combinedData = [];

      if (Array.isArray(leaveRegisterData)) {
        combinedData = leaveRegisterData.map((userdata, index) => ({
          id: index + 1,
          Sl_No: userdata.Sl_No,
          employeeid: userdata.EmployeeID,
          employeename: userdata.EmployeeName,
          leaveType: userdata.LeaveType,
          fromdate: userdata.FromDate,
          todate: userdata.ToDate,
          days: userdata.DaysCount,
          designation: userdata.designation,
          reason: userdata.Reason
        }));
      } else {
        console.error("Leave register data is not an array:", leaveRegisterData);
      }

      setRows(combinedData);

    } catch (error) {
      console.error("Error in fetching data:", error);
    }
  },[urllink, userRecord])




  const fetchPermissionsData = useCallback(() => {
    fetch(`${urllink}HRmanagement/get_for_permission?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromtime: userdata.fromtime,
            totime: userdata.totime,
            hours: userdata.HoursCount,
            reason: userdata.Reason,
            designation: userdata.designation,
          }));
          setRows1(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },[urllink, userRecord])

  const handleVisibilityClick = (prams) => {
    const location1 = userRecord?.location;
    const employeeId = prams;
    axios.get(`${urllink}HRmanagement/get_employee_consumed_and_remain_leave?location=${location1}&EmployeeId=${employeeId}`)
      .then((responce) => {
        const data = responce.data;
        console.log('data', data)
        setLeaveCounts(data);
        setOpenModal1(true)
      })
      .catch((error) => {
        console.log(error)
      })


    axios.get(`${urllink}HRmanagement/get_all_leave_register_by_employeeid?location=${location1}&EmployeeID=${employeeId}`)
      .then((response) => {
        const data = response.data;
        console.log('data', data)
        setprevleavedetails(data);
        setOpenModal1(true)
      })
      .catch((error) => {
        console.log(error)
      })

  };

  const handleVisibilityClick1 = (prams) => {
    const location1 = userRecord?.location;
    const employeeId = prams;
    axios.get(`${urllink}HRmanagement/get_employee_consumed_and_remain_permission_leave?location=${location1}&EmployeeId=${employeeId}`)
      .then((response) => {
        const data = response.data;
        console.log('data', data)
        setpermissionLeaveCounts(data);
        setOpenModal3(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
  };

  const handleEditClick1 = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReject(newReason);
  };

  const handleSubmission = () => {
    const apiUrl = `${urllink}HRmanagement/update_leave_status`;

    const submissionData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      reject: reject,
    };
    console.log(submissionData)
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Submission response:', data);
        setOpenModal2(false)
        setStatus('');
        setReject();
        setshowsudden(!showsudden)
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  };

  const handleSubmission1 = () => {
    const submissionData = new FormData();

    submissionData.append('Sl_No', selectedRowData.Sl_No);
    submissionData.append('status', status);
    submissionData.append('reject', reject);

    axios.post(`${urllink}HRmanagement/update_permission_status`, submissionData)
      .then((response) => {
        setOpenModal(false);
        setStatus('');
        setReject('');
        setshowsudden(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const [page, setPage] = useState(0);
  // const pageSize = 10;

  // useEffect(() => {
  //   const lowerCaseQuery = searchQuery.toLowerCase();
  //   const lowerCaseQuery1 = searchQuery1.toLowerCase();

  //   const filterRow = (row) => {
  //     if (row.employeename && row.designation) {
  //       const lowerCaseEmployeeName = row.employeename.toLowerCase();
  //       const lowerCaseDesignation = row.designation.toLowerCase();
  //       const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
  //       const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

  //       if (!searchQuery && !searchQuery1) {
  //         return true;
  //       }

  //       return nameMatches && designationMatches;
  //     }
  //     return false;
  //   };

  //   const filteredData = rows.filter(filterRow);

  //   setFilteredRows(filteredData);

  // }, [searchQuery, searchQuery1, rows]);

  // useEffect(() => {
  //   const lowerCaseQuerypre = searchQuerypre.toLowerCase();
  //   const lowerCaseQuerypre1 = searchQuerypre1.toLowerCase();

  //   const filterRow = (row) => {
  //     const lowerCaseEmployeeName = row.employeename.toLowerCase();
  //     const lowerCaseDesignation = row.designation.toLowerCase();
  //     const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuerypre);
  //     const designationMatches = lowerCaseDesignation.includes(lowerCaseQuerypre1);

  //     if (!searchQuerypre && !searchQuerypre1) {
  //       return true;
  //     }

  //     return nameMatches && designationMatches;
  //   };

  //   const filteredData = rows1.filter(filterRow);

  //   setFilteredRows1(filteredData);

  // }, [searchQuerypre, searchQuerypre1, rows1]);

  // const [leavecolumns] = React.useState([
  //   { field: 'id', headerName: 'Serial No', width: 180 },
  //   { field: "leavetype", headerName: "Leave Type", width: 190 },
  //   { field: "available", headerName: "Availed Leaves", width: 180 },
  //   { field: "remaining", headerName: "Remaining Leaves", width: 180 },

  // ]);

  // useEffect(() => {
  //   setRows12([
  //     {
  //       id: 1,
  //       leavetype: 'Sick Leave',
  //       available: leaveCounts.sickleave,
  //       remaining: leaveCounts.remainsick < 0 ? 'Limit Exceeded' : leaveCounts.remainsick,
  //     },
  //     {
  //       id: 2,
  //       leavetype: 'Casual Leave',
  //       available: leaveCounts.casualleave,
  //       remaining: leaveCounts.remaincasual < 0 ? 'Limit Exceeded' : leaveCounts.remaincasual,
  //     },
  //     {
  //       id: 3,
  //       leavetype: 'Total Leave',
  //       available: leaveCounts.totalleave,
  //       remaining: leaveCounts.remainleave < 0 ? 'Limit Exceeded' : leaveCounts.remainleave,
  //     },
  //   ]);
  // }, [leaveCounts]);


  React.useEffect(() => {
    fetchData();
    fetchPermissionsData()
  },[fetchData,fetchPermissionsData]);

  return (
    <>
      <div className='appointment'>
        <div className='h_head'>
          <h4>Manage Leave & Permission Requests</h4>
        </div>
        <div className="Add_items_Purchase_Master">
          <span>Leave Request List</span>
        </div>
        <div className="appointment">
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th id="slectbill_ins">Sl. No</th>
                  <th id="slectbill_ins">Employee ID</th>
                  <th id="slectbill_ins">Employee Name</th>
                  <th id="slectbill_ins">Designation</th>
                  <th id="slectbill_ins">Leave Type</th>
                  <th id="slectbill_ins">From Date</th>
                  <th id="slectbill_ins">To Date</th>
                  <th id="slectbill_ins">Days</th>
                  <th id="slectbill_ins">Reason</th>
                  <th id="slectbill_ins">Action</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row) => (
                  <tr key={row.id}>
                    <td>{row.Sl_No}</td>
                    <td>{row.employeeid}</td>
                    <td>{row.employeename}</td>
                    <td>{row.designation}</td>
                    <td>{row.leaveType}</td>
                    <td>{row.fromdate}</td>
                    <td>{row.todate}</td>
                    <td>{row.days}</td>
                    <td>{row.reason}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleVisibilityClick(row.employeeid)}
                        startIcon={<VisibilityIcon />}
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => handleEditClick(row)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div className="Add_items_Purchase_Master">
          <span>Permission Request List</span>
        </div>
        <div className="appointment">
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th id="slectbill_ins">Sl. No</th>
                  <th id="slectbill_ins">Employee ID</th>
                  <th id="slectbill_ins">Employee Name</th>
                  <th id="slectbill_ins">Designation</th>
                  <th id="slectbill_ins">Permission Type</th>
                  <th id="slectbill_ins">From Time</th>
                  <th id="slectbill_ins">To Time</th>
                  <th id="slectbill_ins">Reason</th>
                  <th id="slectbill_ins">Action</th>
                </tr>
              </thead>
              <tbody>
                {rows1?.map((row) => (
                  <tr key={row.id}>
                    <td>{row.Sl_No}</td>
                    <td>{row.employeeid}</td>
                    <td>{row.employeename}</td>
                    <td>{row.designation}</td>
                    <td>{row.leaveType}</td>
                    <td>{row.fromtime}</td>
                    <td>{row.totime}</td>
                    <td>{row.reason}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleVisibilityClick1(row.employeeid)}
                        startIcon={<VisibilityIcon />}
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => handleEditClick1(row)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {openModal && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            }
            onClick={() => { setOpenModal(false) }}
          >
            <div className="newwProfiles newwPopupforreason uwagduaguleaveauto" onClick={(e) => e.stopPropagation()}>
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">Status<span>:</span></label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>
                    <option value="Approved">Approved</option>
                    <option value="Not Approved">Not Approved</option>

                  </select>
                </div>
              </div>
              {status === 'Not Approved' && (
                <div className="RegisFormcon leavecon" >
                  <div className="RegisForm_1 leaveform_1 leaveaiftesatg">
                    <label htmlFor="reason">Reason <span>:</span></label>
                    <textarea
                      type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
                  </div>
                </div>
              )}
              <div className="Register_btn_con regster_btn_contsai">
                <button className="RegisterForm_1_btns" onClick={handleSubmission1}>Submit</button>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        {openModal2 && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            }
            onClick={() => { setOpenModal2(false) }}
          >
            <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">Status<span>:</span></label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>

                    <option value="Approved">Approved</option>


                    <option value="Not Approved">Not Approved</option>

                  </select>
                </div>
              </div>
              {status === 'Not Approved' && (
                <div className="RegisFormcon leavecon" >
                  <div className="RegisForm_1 leaveform_1 leaveaiftesatg">
                    <label htmlFor="reason">Reason <span>:</span></label>
                    <textarea
                      type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
                  </div>
                </div>
              )}
              <div className="Register_btn_con regster_btn_contsai">
                <button className="RegisterForm_1_btns" onClick={handleSubmission}>Submit</button>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setOpenModal2(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        {openModal1 && (
          <div
            className={isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"}
            onClick={() => { setOpenModal1(false) }}
          >
            <div className="newwProfiles newwPopupforreason uwagduaguleaveauto" onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="Add_items_Purchase_Master">
                  <span>Total Leave Details</span>
                </div>
                <div className="Selected-table-container">
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th id="slectbill_ins">Leave Type</th>
                        <th id="slectbill_ins">Total Leave</th>
                        <th id="slectbill_ins">Availed Leaves</th>
                        <th id="slectbill_ins">Remaining Leaves</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaveCounts.length > 0 && leaveCounts.map((leave, index) => (
                        <tr key={index}>
                          <td>{leave.leave_type}</td>
                          <td>{leave.total_leave}</td>
                          <td>{leave.availed_leaves}</td>
                          <td>{leave.remaining_leaves}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
                <div className="Add_items_Purchase_Master">
                  <span>Prev Leave Details</span>
                </div>
                <div className="Selected-table-container">
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th id="slectbill_ins">Leave Type</th>
                        <th id="slectbill_ins">From Date</th>
                        <th id="slectbill_ins">To Date</th>
                        <th id="slectbill_ins">Days</th>
                        <th id="slectbill_ins">Reason</th>
                        <th id="slectbill_ins">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prevleavedetails.length > 0 && prevleavedetails.map((leave, index) => (
                        <tr key={index}>
                          <td>{leave.LeaveType}</td>
                          <td>{leave.FromDate}</td>
                          <td>{leave.ToDate}</td>
                          <td>{leave.days}</td>
                          <td>{leave.Reason}</td>
                          <td>{leave.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>
              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={() => setOpenModal1(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        {openModal3 && (
          <div
            className={isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"}
            onClick={() => { setOpenModal3(false) }}
          >
            <div className="newwProfiles newwPopupforreason uwagduaguleaveauto" onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
                <div className='h_head'>
                  <h4>Previous Approved Permission Hours</h4>
                </div>
                <div className="Selected-table-container">
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th id="slectbill_ins">Leave Type</th>
                        <th id="slectbill_ins">Date</th>
                        <th id="slectbill_ins">Approved Hours</th>
                        <th id="slectbill_ins">Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissionLeaveCounts.length > 0 ? permissionLeaveCounts.map((leave, index) => (
                        <tr key={index}>
                          <td>{leave.leave_type}</td>
                          <td>{leave.permission_date}</td>
                          <td>{leave.hours_count}</td>
                          <td>{leave.reason}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="4">No leave records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={() => setOpenModal3(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default LeaveApproval;




