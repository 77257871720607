import React, { useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const IcuLeninChange = () => {
  const [leninformdata, setLeninformdata] = useState({
    RequestType: 'OnRequest',
    Type: '',
    Reason: '',
    RequestedBy: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [summa, setSumma] = useState([]);
  const pageSize = 10;
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("natha", IpNurseQueSelectedRow);
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const handleAdd = () => {
    // Add functionality
    if (!leninformdata.Type || !leninformdata.Reason) {
      alert("Please fill out all fields.");
      return;
    }
    const index= summa.length+1
    const data={
        id:summa.length+1,
        ...leninformdata,
        RequestedBy:userRecord?.username,
        Location:userRecord?.location,
        Booking_Id:IpNurseQueSelectedRow?.Booking_Id,
        RoomId:IpNurseQueSelectedRow?.RoomId,
        PatientId:IpNurseQueSelectedRow?.PatientId,
        PatientName:IpNurseQueSelectedRow?.PatientName,
        Status:'Requested'
    }
    setSumma([...summa, data]);
    setLeninformdata({ RequestType: 'OnRequest', Type: '', Reason: '',RequestedBy:'' });
  };

  const handleEdit = () => {
    // Edit functionality
    if (editingIndex === null) return;
    const updatedSumma = [...summa];
    updatedSumma[editingIndex] = leninformdata;
    setSumma(updatedSumma);
    setLeninformdata({ RequestType: 'OnRequest', Type: '', Reason: '' });
    setEditingIndex(null);
  };


  const coloumnss=[
    
        {  field:'id' , headerName: 'S_No',width:  100,},
        {  field:'Booking_Id' , headerName: 'Admission Id',width:  100,},
        {  field:'RoomId' , headerName: 'Room Id',width:  100,},
        {  field:'PatientId' , headerName: 'Patient Id',width:  100,},
        {  field:'PatientName' , headerName: 'Patient Name',width:  100,},
        {  field:'RequestType' , headerName: 'RequestType',width:  100,},
        {  field:'Type' , headerName: 'Type',width:  100,},
        {  field:'Reason' , headerName: 'Reason',width:  100,},
        {  field:'RequestedBy' , headerName: 'Requested By',width:  100,},
        {  field:'Location' , headerName: 'Location',width:  100,},
        {  field:'Status' , headerName: 'Status',width:  100,},
        
   
  ]
  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="Total_input_container">
          <div className="inp_container_all_intakeoutput" >
            <label>
              Request Type <span>:</span>
            </label>
            <input
              type="text"
              name='RequestType'
              readOnly
              value={leninformdata.RequestType}
            />
          </div>
          <div className="inp_container_all_intakeoutput" >
            <label>
              Type <span>:</span>
            </label>
            <select
              name='Type'
              value={leninformdata.Type}
              onChange={(e) => setLeninformdata({ ...leninformdata, Type: e.target.value })}
            >
              <option value="">Select</option>
              <option value="Lenin">Lenin</option>
              <option value="Dusting">Dusting</option>
              <option value="Toilet">Toilet</option>
            </select>
          </div>
          <div className="inp_container_all_intakeoutput" >
            <label>
              Reason <span>:</span>
            </label>
            <textarea
              name="Reason"
              value={leninformdata.Reason}
              onChange={(e) => setLeninformdata({ ...leninformdata, Reason: e.target.value })}
            />
          </div>
        </div>
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          {editingIndex === null ? (
            <button className='btn-add' onClick={handleAdd}>
              Add
            </button>
          ) : (
            <button className='btn-add' onClick={handleEdit}>
              Edit
            </button>
          )}
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)}
                pageSize={10}
                columns={coloumnss} // You need to define your dynamic columns here
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="Ip_data_grid"
                onRowClick={(params) => {
                  setLeninformdata(params.row);
                  setEditingIndex(params.rowIndex);
                }}
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default IcuLeninChange;
