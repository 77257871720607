import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas
// import "./ConsentFormCreate.css";
import "../../ICU Management/ICUDoctorflow/ConsentFormCreate.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';


function EmerIpdAdmissionConsent() {

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord)

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("natha", IpNurseQueSelectedRow);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [ConcernformData, setFormData] = useState({
    
    FreeText: "",
    Name: "",
    PatientName: "",
    RelationshipWithPatient: "",
    Date: "",
    NameOfWitness: "",
    Time: "",
    Address: "",
    SignatureLhti1: "",
    SignatureLhti2: "",
    Location:userRecord.location,
    Patient_Name:IpNurseQueSelectedRow.PatientName,
    bookingid:IpNurseQueSelectedRow.Booking_Id,
  });

  console.log('ConcernformData', ConcernformData)
  const signatureRef = useRef(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...ConcernformData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    console.log("Signature saved");
  };



  // console.log(signatureRef,'kjkjk')

  // const handleSave = () => {

  //   const canvasData = signatureRef.current.toDataURL()

  //   // console.log('canvasData',canvasData)

  //   const data = {
  //     ...ConcernformData,
  //     canvasData: canvasData,
  //     Createby: userRecord.username
  //   };

  //   // If IsCategoryEdit is true, it means we are updating an existing category
  //   axios.post(`https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_ConcernForm`, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data.message) {
  //         successMsg(response.data.message);
  //       } else {
  //         userwarn(response.data.Exists);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };


  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Check if the content is ready to be printed
    const printContent = document.getElementById("reactprintcontent");
    console.log(printContent, 'hhhhhhhhhhhh')
    setIsContentReady(!!printContent);
  }, []);

  // const handlePrintAndSave = () => {
  //   // Call the function to save data to the database
  //   handleSave();

  //   // Call the function to trigger printing
  //   // Call the function to trigger printing if content is ready
  //   if (isContentReady) {
  //     handlePrint();
  //   } else {
  //     console.error("Content is not ready for printing");
  //   }
  // };



  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
      if (!isContentReady) {
        // Content is not ready, prevent printing
        throw new Error("Content is not ready for printing");
      }
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);
  
      try {
        if (printdata) {
          // Get the content height and width
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdf = new jsPDF("p", "px", [contentWidth, contentHeight]); // Define a PDF instance with 'portrait' orientation and 'A4' size
          pdf.html(printdata, {
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              // saveOrUploadPdf(generatedPdfBlob);
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
  
              // Construct the request body including previous state
              // const canvasData = signatureRef.current.toDataURL();
              const formData = new FormData();
              formData.append('bookingid', ConcernformData.bookingid);
              formData.append('Location', ConcernformData.Location);
              formData.append('Patient_Name', ConcernformData.Patient_Name);
              formData.append('Date', ConcernformData.Date);
              formData.append('Time', ConcernformData.Time);
              formData.append('Name', ConcernformData.Name);
              formData.append('FreeText', ConcernformData.FreeText);
              formData.append('PatientName', ConcernformData.PatientName);
              formData.append('Address', ConcernformData.Address);
              formData.append('NameOfWitness', ConcernformData.NameOfWitness);
              formData.append('RelationshipWithPatient', ConcernformData.RelationshipWithPatient);
              formData.append('SignatureLhti1', ConcernformData.SignatureLhti1);
              formData.append('SignatureLhti2', ConcernformData.SignatureLhti2);
              // formData.append('canvasData', canvasData);
              formData.append('generatedPdfBlob', generatedPdfBlob);
              formData.append('Createby', userRecord.username);
  
              console.log("requestBodyyyyyyyyyyyyyyyyyyyyy :", formData);
              // Send the request
              axios
                .post(
                  `https://vesoftometic.co.in/EmergencyManagement/insert_Emergency_IpdAdmission`, formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
                    },
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setIsPrintButtonVisible(true);
                  // handleSave();
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  
  // const saveOrUploadPdf = (pdfBlob) => {
  //   // Convert the PDF blob to a Base64 string
  //   const reader = new FileReader();
  //   reader.readAsDataURL(pdfBlob);
  //   reader.onloadend = () => {
  //     const base64Data = reader.result;
  
  //     // Save the Base64 string to localStorage
  //     localStorage.setItem('pdfData', base64Data);
  //     console.log('PDF saved to localStorage.');
  //   };
  // };
  

  // const handlePrintAndSave = () => {
  //   try {
  //     // Call the function to trigger printing
  //     handlePrint();

  //     // Call the function to save data to the database
  //     handleSave();
  //   } catch (error) {
  //     console.error("Error while printing:", error);
  //   }
  // };


  // const handlePrint = () => {
  //   window.print();
  // };


  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const location = userRecord?.location;




  useEffect(() => {

    axios
      .get(`https://vesoftometic.co.in/usercontrol/getAccountsetting`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.clinicName);
          setClinicLogo(`data:image/*;base64,${data.clinicLogo}`);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="appointment case_sheet_consent" id="reactprintcontent">
        <br />
        <div >
         
          

          <div className="qqqqq" style={{ display: 'flex', justifyContent: 'center', padding: '3px', alignItems: 'center' }}>
            <h1>चिरायु</h1><h4 style={{marginLeft: '15px'}}> हॉस्पिटल</h4>
          </div>
          <hr/>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>IPD ADMISSION CONSENT</h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>आय. पी. डी. ॲडमिशन कन्सेंट</h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h6>( रुग्णांना दाखल करुन घेण्याचा हक्क रुग्णालयाच्या व्यवस्थापनाने राखून ठेवला आहे.)</h6>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center' }}>
            <h3>अनुमती पत्र</h3>
          </div>

        </div>

        <div className="RegisFormcon_consent_consent" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', alignItems: 'right' }}>

        </div>

        
        <div className=" summadiv">
          <div className="RegisForm_1_consent_consent_ppp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'start' }}>

            <p className="eeiuujeuwu39" style={{ margin: '0', fontSize: '12px', fontWeight: 'bold' }}>
             
            १) डॉक्टरांनी मला / आम्हाला माझ्या / आमच्या रुग्णाच्या (रुग्णाचे नाव
              <input
                type="text"
                name="FreeText"
                value={ConcernformData.FreeText}
                onChange={handleChange}
                style={{width: '500px'}}
                required
                
                />
            ) आजाराची व त्याच्या <br/> प्रकृतीचीपरिस्थितीची संपूर्ण माहिती व कल्पना दिली आहे.<br/>
           <br/>
            २) मला / आम्हाला डॉक्टरांनी कराव्या लागणाऱ्या तपासण्या, औषधोपचार त्यांचे परिणाम व संभाव्य विकृती व विकोप इ.
             सर्व बाबींची संपूर्ण व स्पष्ट कल्पना दिली आहे.<br/>
             <br/>
            ३) गरज भासल्यास रुग्णाच्या बाबतीत जरुरीप्रमाणे अतिरिक्त विशेष तज्ञाचा सल्ला घेण्याचा माझी / आमची संमती आहे.
              त्या संबंधीच्या खर्चाची जबाबदारी माझ्यावर / आमच्यावर राहील.<br/>
             <br/>

            ४) गरज भासल्यास रुग्णास अ. द. वि. ( अति दक्षता विभाग) मध्ये हलविण्यात माझी / आमची संमती आहे.
               अशा निर्णयाचे परिणाम व त्यासाठी करावा लागणारा खर्च याची जबाबदारी मी/ आम्ही स्वीकारु.<br/>
               <br/>
            ५) आवश्यकतेनुसार कराव्या लागणाऱ्या तपासण्या व औषधोपचार यांना माझी / आमची संमती आहे. 
               त्याचबरोबर या तपासण्या / औषधोपचारामुळे काही दुष्परिणाम होण्याची शक्यता असल्यास त्याबद्दल मी/ आम्ही संबंधीत डॉक्टरांकडून वेळोवेळी माहिती जाणून घेवू.<br/>
               <br/>
            ६)रुग्णालयात वापरण्यात येणारी औषधे, सलाईनच्या बाटल्या, सलाईन सेट इत्यादी वस्तूंचे रुग्णालयात उत्पादन केले जात नाही व रुग्णालयात वापरण्यात येणारी औषधे 
            प्रमाणित कंपन्यांची असतात याची मला जाणीव आहे.<br/>
               <br/>
            ७) रुग्णाच्या प्रकृतीविषयी वेळीवेळी मी डॉक्टरांकडून माहिती करुन घेईन. <br/>
            <br/>
            ८)रुग्ण अथवा रुग्णाच्या नातेवाईकांकडून रुग्णालयातील वस्तूंची मोडतोड झाल्यास त्याची सर्व
 जबाबदारी     माझ्यावर / आमच्यावर राहील व त्याचा वेगळा आकार मी/आम्ही भरेन / भरू.<br/>
            <br/>
            ९)कृपया कोणत्याही स्टाफला वैयक्तिक बक्षीस देऊ नये.<br/>
            <br/>
            १०) रुग्णालयाच्या नियमांप्रमाणे रुग्णालयाच्या परवानगीधारक व्यक्तीस शस्त्रक्रिया व इतर 
            योजनेत / माझ्या / रुग्णाच्या शरीराचे काढलेले व घेतलेले अवयव / स्नायू तपासण्यास व 
            यानंतर त्याची योग्य विल्हेवाट लावण्यास मी/ आम्ही अनुमती देत आहे / आहोत.<br/>
            <br/>
            ११) वैद्यकीय ज्ञानाच्या अभिवृध्दीसाठी उपचार / शस्त्रक्रिया करताना घेतलेली छायाचित्रे 
            (Photo) दृकश्राव्य (Video) आम्हाला मान्य आहे.<br/>
              <br/>
            १२)वरील सर्व कलमे मी/ आम्ही वाचली आहेत व ती माझ्यावर / आमच्यावर बंधनकारक आहेत.
            ती मी/ आम्ही रुग्णालयाच्या अधिकाऱ्यांना / रुग्णास (नाव)
            <input
                type="text"
                name="Name"
                value={ConcernformData.Name}
                onChange={handleChange}
                style={{width: '380px'}}
                required
                />
                या रुग्णालयात दाखल करुन घेण्याची विनंती करतो.
            
            <br/>
            <br/>
             
            </p>
            

          </div>


        </div>

      

       

     
      

       <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className="shdywy656230">
                    <label> रुग्णाचे नाव <span>:</span></label>
                    <input type="text"
                    name="PatientName"
                    value={ConcernformData.PatientName}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>
                  <div className="shdywy656230">
                    <label> रुग्णाशी नाते :<span>:</span></label>
                    <input type="text"
                    name="RelationshipWithPatient"
                    value={ConcernformData.RelationshipWithPatient}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label> तारीख <span>:</span></label>
                    <input type="text"
                    name="Date"
                    value={ConcernformData.Date}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                  <div className="shdywy656230">
                    <label> वेळ <span>:</span></label>
                    <input type="text"
                    name="Time"
                    value={ConcernformData.Time}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>

                </td>

                <td>
                  <div className="shdywy656230">

                      <label> सही / अंगठा <span>:</span></label>
                      <input type="text"
                      name="SignatureLhti1"
                      value={ConcernformData.SignatureLhti1}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div>

                </td>
              </tr>


               <tr>

                <td>
                  <div className="shdywy656230">

                      <label> साक्षीदाराचे नाव <span>:</span></label><br/>
                      <input 
                      type="text"
                      name="NameOfWitness"
                      value={ConcernformData.NameOfWitness}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div>


                    {/* <div className="shdywy656230">

                      <label>  पत्ता <span>:</span> </label>
                      <input 
                      type="text"
                      name="Address"
                      value={ConcernformData.Address}
                      onChange={handleChange}
                      required
                      >
                      </input>
                    </div> */}

                    <div className="shdywy656230">
                      <label> पत्ता : <span>:</span></label>
                      <textarea
                        name="Address"
                        value={ConcernformData.Address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  
                </td>

                <td>
                 

                  <div className="shdywy656230">

                    <label> सही / अंगठा <span>:</span> </label>
                    <input 
                    type="text"
                    name="SignatureLhti2"
                    value={ConcernformData.SignatureLhti2}
                    onChange={handleChange}
                    required
                    >
                    </input>
                  </div>


                </td>

              </tr>

            </tbody>
          </table>
        </div>

        <br />
        <div className="sigCanvas2_head11 uwytywe6262309" >
            <div className="" >
              <div>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 190,
                    height: 100,
                    className: "sigCanvas2",
                  }}
                />
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>डॉक्टरांची सही</h5>

              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={clearSignature}>
                  Clear
                </button>
                <button className="RegisterForm_1_btns" onClick={saveSignature}>
                  Save
                </button>
              </div>

            </div>


          </div>

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns printgr5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <br />
      </div>
    </>
  );
}

export default EmerIpdAdmissionConsent;
