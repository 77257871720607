import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";







const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
export default function RateCardList() {

    const dispatchvalue = useDispatch()

    const [SelectFormdata,setSelectFormdata]=useState('services')

    const [SelectStatus,setSelectStatus]=useState('Active')


    const [SelectRatecard,setSelectRatecard]=useState('General')

    const [SelectVisittype,setSelectVisittype]=useState('OP')


    const reduxvalueUsercord = useSelector(state => state)

    const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen);
    const userRecord = reduxvalueUsercord.userRecord?.UserData

    const navigate = useNavigate();
    const [page, setPage] = useState(0);

    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };


    const [Rowdata, setRowdata] = useState([]);
    const [Rowdata1, setRowdata1] = useState([]);

    const [selectedShow, setSelectedShow] = useState(false);
    const [page1, setPage1] = useState(0);
    const showdown1 = Rowdata.length;
    const pageSize1 = 10;

    const handlePageChange1 = (params) => {
      setPage1(params.page);
    };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

    

    console.log('vcvc',userRecord)

    const yourStyles={
        position: 'absolute',
        inset: '100px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(97 90 90 / 75%)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px'
      }
  

      useEffect(() => {
        if (SelectFormdata === 'services') {
          axios
            .get(`https://vesoftometic.co.in/usercontrol/get_All_Services_data`)
            .then((response) => {
              console.log(response.data);
              const filteredData = response.data.filter((item) => item.Status === SelectStatus);
              const data = filteredData.map((p, index) => ({
                id: index + 1,
                ...p
              }));
              console.log('data', data);
              setFilteredRows(data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (SelectFormdata === 'Doctors') {
          axios
            .get(`https://vesoftometic.co.in/usercontrol/get_All_DoctorsRate_data`)
            .then((response) => {
              console.log(response.data);
              const filteredData = response.data.filter((item) => item.Status === SelectStatus);
              const data = filteredData.map((p, index) => ({
                id: index + 1,
                ...p
              }));
              console.log('datasss', data);
              setFilteredRows(data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, [SelectFormdata, SelectStatus]);


      useEffect(() => {
        const Finddata = Rowdata1.filter(ele => ele.Rate_Card_Type === SelectRatecard &&
          ele.Visit_Type === SelectVisittype);
        setRowdata(Finddata);
      }, [Rowdata1, SelectRatecard, SelectVisittype]);
      
    
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const ind = params.row
        const index={
          ...ind,
          SelectFormdata:SelectFormdata
        }
        console.log('indexvv:',index) 

        dispatchvalue({ type:'RateCarddata', value: index })

        navigate('/Home/RateCardMasterAll')
        
    };

   

    const handleRequestForm = () => {

        navigate('/Home/RateCardMasterAll')
        dispatchvalue({ type:'RateCarddata', value: {} })


    }


    const handleviewEdit = (params) => {

      if(SelectFormdata === 'services'){
        const index = params.row.Service_id
      console.log('index:',index)
      axios
        .get(`https://vesoftometic.co.in/usercontrol/get_list_view_Services_data`, {
          params: index
        })
        .then((response) => {
          console.log('dedede',response.data);
          const Adata=response.data
        let B_data = Adata.map((p, index) => ({
            id: index + 1,
            ...p
          }))
          setRowdata1(B_data)          
          setSelectedShow(true);

        })
        .catch((error) => {
          console.log(error);
        });
    }
    else{
      const index = params.row.DoctorId
      console.log('index:',index)
      axios
        .get(`https://vesoftometic.co.in/usercontrol/get_list_view_Doctors_data`, {
          params: index
        })
        .then((response) => {
          console.log('dedede',response.data);
          const Adata=response.data
        let B_data = Adata.map((p, index) => ({
            id: index + 1,
            ...p
          }))
          setRowdata1(B_data)          
          setSelectedShow(true);

        })
        .catch((error) => {
          console.log(error);
        });
     

    }
      
  };

    
    
   

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'Service_id', headerName: 'services Id', width: 100 },
        { field: 'Service_name', headerName: 'services Name', width: 220 },
        { field: 'TaxApplicable', headerName: 'Tax Applicable', width: 150 },
        { field: 'TaxPercentage', headerName: 'Tax Percentage', width: 150},
        { field: 'Status', headerName: 'Status', width: 150 },
        { field: 'CreatedBy', headerName: 'CreatedBy', width: 150 },
        { field: 'CreateDate', headerName: 'Create Date', width: 150 },
        {
          field: 'View',
          headerName: 'View',
          width: 120,
          renderCell: (params) => (
            <>
              <Button className='cell_btn' onClick={() => handleviewEdit(params)}>
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <EditIcon/>
                    </Button>
                </>
            ),
        },
    ];

  // -------------Doctors

  const dynamicColumns1 = [
    { field: 'DoctorId', headerName: 'Doctors Id', width: 100 },
    { field: 'specialties', headerName: 'specialties', width: 150 },
    { field: 'FirstName', headerName: 'FirstName', width: 180 },
    { field: 'MiddleName', headerName: 'MiddleName', width: 180 },
    { field: 'LastName', headerName: 'LastName', width: 180 },
    { field: 'Status', headerName: 'Status', width: 180 },
    { field: 'CreatedBy', headerName: 'CreatedBy', width: 150 },
    { field: 'CreateDate', headerName: 'Create Date', width: 150 },
    {
      field: 'View',
      headerName: 'View',
      width: 120,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleviewEdit(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },{
        field: 'Action',
        headerName: 'Action',
        width: 120,
        renderCell: (params) => (
            <>
                <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                    <EditIcon/>
                </Button>
            </>
        ),
    },
];

// ---------------------------pop

const dynamicColumns2 = [
  { field: 'id', headerName: 'S.No', width: 100 },
  { field: 'Rate_Card_Type', headerName: 'Rate_Card_Type', width: 220 },
  { field: 'Insurance_OR_Clint', headerName: 'Insurance_OR_Clint', width: 150 },
  { field: 'Visit_Type', headerName: 'Visit_Type', width: 150},
  { field: 'Ward_Type', headerName: 'Ward_Type', width: 150 },
  { field: 'Room_Type', headerName: 'Room_Type', width: 150 },
  { field: 'Amount', headerName: 'Amount', width: 150 },
];

// -------------Doctors

const dynamicColumns3 = [
  { field: 'id', headerName: 'S.No', width: 100 },
  { field: 'Rate_Card_Type', headerName: 'Rate_Card_Type', width: 220 },
  { field: 'Insurance_OR_Clint', headerName: 'Insurance_OR_Clint', width: 150 },
  { field: 'Visit_Type', headerName: 'Visit_Type', width: 150},
  { field: 'Ward_Type', headerName: 'Ward_Type', width: 150 },
  { field: 'Room_Type', headerName: 'Room_Type', width: 150 },
  { field: 'Amount', headerName: 'Amount', width: 150 },
];

   



    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="services_dash_hending">
                    <h3>services / Doctors Master List</h3>
                </div>

                <div className="serch_dash_insur" style={{width:'65%'}}>
                
                <div className="Total_input_container">
                  <div className="inp_container_all_intakeoutput">
                  <label >
                      Type <span>:</span>
                  </label>
                  <select
                    name='SelectFormdata'
                    value={SelectFormdata}
                    onChange={(e)=>setSelectFormdata(e.target.value)}
                  >
                    <option value="services">services</option>
                    <option value="Doctors">Doctors</option>
                  </select>
                  </div>
                  <div className="inp_container_all_intakeoutput">
                  <label >
                      Status <span>:</span>
                  </label>
                  <select
                    name='SelectStatus'
                    value={SelectStatus}
                    onChange={(e)=>setSelectStatus(e.target.value)}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  </div>
                </div>
              
                <p onClick={handleRequestForm}>
                <button className="add_new_auth_wuith"><AddIcon /> New </button>
                </p>
                </div>

               
               
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={SelectFormdata === 'services'?dynamicColumns:dynamicColumns1} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>

            {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>{SelectFormdata}List</h3>
                </div>
                <div style={{display:'grid',placeItems:'center'}}>
                <div className="serch_dash_insur" style={{width:'max-content'}}>
                <div className="Total_input_container">
                  <div className="inp_container_all_intakeoutput">
                  <label >
                     Rate Card Type <span>:</span>
                  </label>
                  <select
                    value={SelectRatecard}
                    onChange={(e)=>setSelectRatecard(e.target.value)}
                  >
                    <option value="General">General</option>
                    <option value="VIP">VIP</option>
                    <option value="Insurance">Insurance</option>
                    <option value="Client">Client</option>
                  </select>

                  <label >
                      Visit Type <span>:</span>
                  </label>
                  <select
                    value={SelectVisittype}
                    onChange={(e)=>setSelectVisittype(e.target.value)}
                  >
                    <option value="OP">OP</option>
                    <option value="IP">IP</option>
                    <option value="Emergency">Emergency</option>
                    <option value="DayCare">DayCare</option>
                  </select>
                  </div>
                </div>
                </div>
                </div>
                


                <div className='IP_grid'>
                  <ThemeProvider theme={theme}>
                    <div className='IP_grid_1'>
                      <DataGrid
                        rows={Rowdata.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                        columns={SelectFormdata === 'services'?dynamicColumns2:dynamicColumns3} // Use dynamic columns here
                        pageSize={10}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        onPageChange={handlePageChange1}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className='data_grid'
                      />
                      {showdown1 > 0 && Rowdata.length > 10 && (
                        <div className='IP_grid_foot'>
                          <button
                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                            disabled={page === 0}
                          >
                            Previous
                          </button>
                          Page {page1 + 1} of {totalPages1}
                          <button
                            onClick={() =>
                              setPage((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                            }
                            disabled={page1 === totalPages1 - 1}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                  {showdown1 !== 0 && Rowdata.length !== 0 ? (
                    ''
                  ) : (
                    <div className='IP_norecords'>
                      <span>No Records Found</span>
                    </div>
                  )}
                </div>
                <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                    }}

                  >
                    close
                  </button>
                </div>
              </div>


            </div>
          </div>
        )}
        </>
    );
}
