import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

export default function IpRegistrationlist() {

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  const userRecord = useSelector(state => state.userRecord?.UserData);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);



  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [summa, setsumma] = useState([])
  const [selectedShow, setSelectedShow] = useState(false);
  const [selectedOptioncalender, setselectedOptioncalender] = useState('Admitted');

  console.log('vcvc', summa)

  useEffect(() => {
    if (userRecord?.location) {
      axios.get(`${UrlLink}ipregistration/get_data_nurse_que_list?Status=${selectedOptioncalender}&Location=${userRecord?.location}`)
        .then((response) => {
          const data = response.data;
          console.log('222', data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            }))
          ]);
        })
        .catch((error) => {
          console.error('Error fetching doctor names:', error);
        });
    }
  }, [selectedOptioncalender, userRecord?.location,UrlLink]);


  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row
    console.log('index:', index)
    dispatchvalue({ type: 'IpAdmissionEdit', value: { edit: true, type: 'IP', BookingId: index.BookingId } })
    navigate('/Home/IP-Registration')


  };




  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log('ss', summa)

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.PatientId.toLowerCase();


      return (
        lowerCaseSupplierName.includes(lowerCaseQuery)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);




  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
 

  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...['BookingId', 'AdmissionPurpose', 'PatientId', 'PatientName', 'PatientPhoneNo', 'Specialization',
      'PrimaryDoctor', 'AdmitDate', 'AdmitTime', 'AttenderName', 'AttenderMobileNo', 'PrimaryDisease'].map((labelname, index) => {
  
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);
  
        return {
          field: labelname,
          headerName: formattedLabel,
          width: ["AdmissionPurpose", "Admitdate", "Time", 'PatientName', 'BookingId'].find((f) => f === labelname)
            ? labelWidth + 100
            : labelWidth + 30,
          valueGetter: (params) => {
            const value = params.row[labelname];  // Retrieve the actual value from the row
            return value !== undefined && value !== null ? value : "-"; // Handle undefined/null values
          },
        };
      }),
    {
      field: 'Action',
      headerName: 'Action',
      width: 80,
      renderCell: (params) => (
        <Button className='cell_btn' onClick={() => handleRequestEdit(params)} title='Edit Informations'>
          <EditIcon />
        </Button>
      ),
    },
  ];
  





  return (
    <>

      <div className="Supplier_Master_Container">

        <div className="Product_Master_h_head">
          <h3>IP Registration List</h3>
          <div className="calender_select_opt slect-view-blk">
            <label htmlFor="Calender"> Select :</label>
            <select className='calender_select_colr' style={{ width: '120px' }} value={selectedOptioncalender} onChange={(e) => setselectedOptioncalender(e.target.value)}>
              <option value="Admitted">Admitted</option>
              <option value="Discharged">Discharged</option>
            </select>
          </div>


        </div>
        <div className="Supplier_Master_con_1 ">

          <div className="Supplier_inputss_forms">
            <label htmlFor="input">Patient Detials :</label>
            <input type="number" value={searchQuery} onChange={handleSearchChange} placeholder='Search here ....' />
          </div>


        </div>

        <div className='IP_grid'>
          <ThemeProvider theme={theme}>
            <div className='IP_grid_1'>
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=' Ip_data_grid'
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className='IP_grid_foot'>
                  <button
                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ''
          ) : (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
          {selectedShow && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              } onClick={() => {
                setSelectedShow(false);
              }}

            >
              <div className="newwProfiles" style={{ padding: '10px', boxSizing: 'border-box' }} onClick={(e) => e.stopPropagation()}>
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>List</h3>
                  </div>
                  <div className="con_1 ">




                  </div>

                  <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}

                    >
                      close
                    </button>
                  </div>
                </div>


              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}



