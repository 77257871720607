import React, { useEffect, useState } from 'react';
import '../../Stock/ProductMaster/ProductMaster.css';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "var(--ProjectColor)",
                    textAlign: 'Center',
                },
                root: {
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
                        textAlign: 'center',
                        display: 'flex !important',
                        justifyContent: 'center !important'
                    },
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  var(--ProjectColor) !important",
                    display: 'flex',
                    justifyContent: 'center'
                },
            },
        },
    },
});
function RoomMasterCreate() {

    const dispatchvalue = useDispatch();

    const userRecord = useSelector(state => state.userRecord?.UserData)
    const [LocationData, setLocationData] = useState([]);



    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isedit, setisedit] = useState(null);
    const [summa, setsumma] = useState([])
    const [update, setupdate] = useState(false)
    const [filteredRows, setFilteredRows] = useState([]);
    const [BuildingName, setBuildingName] = useState([]);
    const [BlockName, setBlockName] = useState([]);
    const [FloorName, setFloorName] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    const [Locationget,setLocationget]=useState('')

    useEffect(() => {
        axios.get(`https://vesoftometic.co.in/usercontrol/getlocationdata`)
          .then((response) => {
            const data = response.data;
            console.log(data)
    
            setLocationData(data.filter((p) => p.location_name !== 'ALL'));
          })
          .catch((error) => {
            console.error('Error fetching location data:', error);
          });
          if(userRecord.location){
            setLocationget(userRecord.location)
          }
      }, [userRecord?.location])


    const [formDataProduct, setFormDataProduct] = useState({
        S_No: '',
        Building: '',
        Block: '',
        Floor: '',
        Ward: '',
        RoomType: '',
        Room: '',
        BedNo: '',
        Charge: '',
        GST:'',
        Status: 'Active',
        Location: '',
        createdby: userRecord?.username,
    });

    const [formDataRoommaster, setformDataRoommaster] = useState({
        TotalRooms: 0,
        CreatedRooms: 0,
        AvailableRooms: 0,
    })
    const [WardRoom, setWardRoom] = useState([])
    const [RoomType, setRoomType] = useState([])
    useEffect(()=>{
        if(Locationget!=='ALL'){
         setFormDataProduct((prev)=>({
             ...prev,
             Location:Locationget
         }))
        }else{
            setFormDataProduct((prev)=>({
                ...prev,
                Location:''
            }))  
        }
     },[Locationget])
    useEffect(()=>{
        if(formDataProduct?.Location){
            axios.get(`https://vesoftometic.co.in/usercontrol/get_Building`)
            .then((response)=>{
                let data=response.data.filter((f)=>f.Location===formDataProduct?.Location).map((p)=>p.BuildingName)
                
                 
                setBuildingName(data)
            })
            }
    },[formDataProduct?.Location])
    useEffect(()=>{
        axios.get(`https://vesoftometic.co.in/usercontrol/get_Block`)
            .then((response)=>{
                let data=response.data.filter((f)=>f.Location===formDataProduct?.Location&&f.BuildingName===formDataProduct.Building).map((p)=>p.BlockName)
                
                 
                setBlockName(data)
            })
    },[formDataProduct?.Location,formDataProduct.Building])
    useEffect(()=>{
        axios.get(`https://vesoftometic.co.in/usercontrol/get_Floor`)
            .then((response)=>{
                let data=response.data.filter((f)=>f.Location===formDataProduct?.Location&&f.BuildingName===formDataProduct.Building&&f.BlockName===formDataProduct.Block).map((p)=>p.FloorName)
                
                 
                setFloorName(data)
            })
    },[formDataProduct?.Location,formDataProduct.Building,formDataProduct.Block])

    
    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.Building.toLowerCase();
            const lowerCaseSupplierCode = row.Block.toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) ||
                lowerCaseSupplierCode.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);

    }, [searchQuery, summa]);

    useEffect(() => {
        if(Locationget){
        axios.get(`https://vesoftometic.co.in/ipregistration/get_Room_Master_Data?BookingStatus=All&Status=All&Location=${Locationget}`)
            .then((response) => {
                console.log(response.data)
                const data = response.data.map((p) => ({
                    ...p,
                    id: p.S_No
                }))
                console.log(data)
                setsumma(data)
            })
            .catch((error) => {
                console.log(error)
            })
        
            axios.get(`https://vesoftometic.co.in/ipregistration/get_Room_Count_data?location=${Locationget}`)
            .then((response) => {
                console.log(response.data)
                const data = response.data

                setformDataRoommaster({
                    TotalRooms: data.TotalRooms,
                    CreatedRooms: data.CreatedRooms,
                    AvailableRooms: data.AvailableRooms
                })
                

            })
            .catch((error) => {
                console.log(error)
            })}
    }, [update,Locationget])



   

   
    useEffect(() => {
        axios.get(`https://vesoftometic.co.in/usercontrol/get_Room_type?Location=${formDataProduct?.Location}`)
            .then((response) => {
                let data=response.data
                
                setWardRoom(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [formDataProduct?.Location])


    useEffect(() => {
        const data= WardRoom.filter((f)=>f.WardType===formDataProduct.Ward)
        setRoomType(data)
    }, [WardRoom,formDataProduct?.Ward])

useEffect(()=>{
    if(formDataProduct?.Ward !=='' && formDataProduct?.RoomType !==''){
        const data= WardRoom.find((p)=>p.WardType===formDataProduct.Ward && p.RoomType === formDataProduct.RoomType)
        console.log(data);
    if(data){
        setFormDataProduct((prev)=>({
            ...prev,
            Charge:data?.TotalCharge,
            GST:data?.GST
        }))
    }
    }
},[formDataProduct?.Ward,formDataProduct?.RoomType])
    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'id', headerName: 'S_No', width: 100 },
        { field: 'Building', headerName: 'Building Name', width: 150 },
        { field: 'Block', headerName: 'Block Name', width: 150 },
        { field: 'Floor', headerName: 'Floor Name', width: 150 },
        { field: 'Ward', headerName: 'Ward Name', width: 120 },
        { field: 'RoomType', headerName: 'Room Type', width: 120 },
        { field: 'Room', headerName: 'Room No', width: 120 },
        { field: 'BedNo', headerName: 'Bed No', width: 120 },
        { field: 'Charge', headerName: 'Charge', width: 120 },
        { field: 'GST', headerName: 'GST', width: 120 },
        { field: 'Location', headerName: 'Location', width: 120 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params.row)}>
                        <BorderColorIcon />
                    </Button>
                </>
            ),
        },
    ];



    const handleInputChange = (event) => {
        const { name, value } = event.target;


        if (name === 'Building') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Block: '',
                Floor: '',
                Ward: '',
                RoomType: '',
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''

            }));
        } else if (name === 'Block') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Floor: '',
                Ward: '',
                RoomType: '',
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''
                

            }));
        }else if (name === 'Floor') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Ward: '',
                RoomType: '',
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''
                

            }));
        }else if (name === 'Ward') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                RoomType: '',
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''
                

            }));
        }else if (name === 'RoomType') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''
            }));
        }else if (name === 'Location') {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Building:'',
                Block: '',
                Floor: '',
                Ward: '',
                RoomType: '',
                Room: '',
                BedNo: '',
                Charge: '',
                GST:''
            }));
        } else {
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,

            }));
        }
    };
    
    const handleSaveOrUpdate = () => {

        const requiredfields = [
            'Building',
            'Block',
            'Floor',
            'Ward',
            'RoomType',
            'Room',
            'BedNo',
            'Charge',
            'GST',
            'Status',
            'Location',
        ]
        const existing = requiredfields.filter((field) => !formDataProduct[field])
        if (existing.length === 0) {
            axios.post(`https://vesoftometic.co.in/ipregistration/${isedit ? 'update_get_Room_Master_Data' : 'insert_get_Room_Master_Data'}`, formDataProduct)
                .then((response) => {
                    console.log(response.data)
                    clearFormInputs()
                    setisedit(null)
                    setupdate(!update)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            alert(`Please fill the required Fields : ${existing.join(',')}`)
        }

    };
    const handleRequestEdit = (params) => {
        setFormDataProduct({ ...params })
        setisedit(params)

    }
    const clearFormInputs = () => {


        setFormDataProduct((prev)=>({
            ...prev,
            Building: '',
            Block: '',
            Floor: '',
            Ward: '',
            RoomType: '',
            Room: '',
            BedNo: '',
            Charge: '',
            GST:'',
            Status: 'Active',
            createdby: userRecord?.username,
        }));

    };
    return (
        <div className="Stoct_Product_master">
            <div className="h_head">
                <h3 >Room Master</h3>
                <div className="calender_select_opt slect-view-blk selt-dctr-nse">
                    <label htmlFor="Calender"> Location :</label>
                    <select className='calender_select_colr' value={Locationget} onChange={((e)=>setLocationget(e.target.value))}>
                        {userRecord.location==="ALL"?LocationData.map((p, index) => (
                            <option key={index} value={p.location_name}>
                                {p.location_name}
                            </option>
                        )):
                        <option value={Locationget}>{Locationget}</option>
                        }
                    </select>
                </div>
            </div>
            <br />
            <div className='Total_input_container_patientData'>
                <div className='room_create_container_total'>
                    <label >Total Beds <span>:</span></label>
                    <span>{formDataRoommaster.TotalRooms}</span>
                </div>
                <div className='room_create_container_total'>
                    <label >Available Beds <span>:</span></label>
                    <span>{formDataRoommaster.AvailableRooms}</span>
                </div>
                <div className='room_create_container_total'>
                    <label >Created Beds <span>:</span></label>
                    <span>{formDataRoommaster.CreatedRooms}</span>
                </div>
            </div>
            <br />
            <div className='Stoct_Product_master_Container'>
                <div className='Total_input_container_patientData'>
                <div className="inp_container_all_intakeoutput">
                        <label htmlFor="strength">Location:</label>
                        {Locationget!=='ALL'?<input
                            type="text"
                            name="Location"
                            placeholder="Enter Location"
                            value={formDataProduct.Location}
                            onChange={handleInputChange}
                            readOnly

                        />
                        :
                        <select
                            name="Location"
                            placeholder="Enter Location "
                            value={formDataProduct.Location}
                            required
                            onChange={handleInputChange}

                        >
                            <option value=''>select </option>


                          {  LocationData.filter((f)=>f.location_name!=="ALL").map((p, index) => (
                            <option key={index} value={p.location_name}>
                                {p.location_name}
                            </option>
                        ))}
                        </select>
                    }
                    </div>
                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="ProductType">Building Name :</label>
                        
                        <select
                            name="Building"
                            placeholder="Enter Building Name"
                            value={formDataProduct.Building}
                            required
                            onChange={handleInputChange}

                        >
                            <option value=''>select </option>


                            {BuildingName.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>

                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="code">Block Name :</label>
                        
                        <select
                            name="Block"
                            placeholder="Enter Block Name"
                            value={formDataProduct.Block}
                            required
                            onChange={handleInputChange}

                        >
                            <option value=''>select </option>


                            {BlockName.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>

                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="itemName">Floor Name:</label>
                        
                        <select
                            name="Floor"
                            placeholder="Enter Floor Name"
                            value={formDataProduct.Floor}
                            required
                            onChange={handleInputChange}

                        >
                            <option value=''>select </option>


                            {FloorName.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>


                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="generic">Ward Type:</label>

                        <select
                            name="Ward"
                            value={formDataProduct.Ward}
                            onChange={handleInputChange}
                        // readOnly={selectedProductIndex}

                        >
                            <option value=''>select </option>


                            {
                               [...new Set(WardRoom.map(p => p.WardType))].map((p, index) => (
                                    <option key={index} value={p}>{p}</option>
                                ))
                            }
                        </select>
                    </div>


                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="strength">Room Type:</label>

                        <select
                            name="RoomType"
                            value={formDataProduct.RoomType}
                            onChange={handleInputChange}

                        >
                            <option value=''>select </option>
                            {
                                RoomType.map((p, index) => (
                                    <option key={index} value={p.RoomType}>{p.RoomType}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="strength">Room No:</label>
                        <input
                            type="text"
                            name="Room"
                            placeholder="Enter Room"
                            value={formDataProduct.Room}
                            onChange={handleInputChange}
                        // readOnly={selectedProductIndex}

                        />
                    </div>



                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="uom">Bed No :</label>
                        <input
                            type="text"
                            name="BedNo"
                            placeholder="Enter Bed No"
                            value={formDataProduct.BedNo}
                            onChange={handleInputChange}

                        />
                    </div>
                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="uom">Charge:</label>
                        <input
                            type="number"
                            name="Charge"
                            placeholder="Enter Charge per Bed"
                            value={formDataProduct.Charge}
                            onChange={handleInputChange}
                            readOnly

                        />
                    </div>





                    
                    <div className="inp_container_all_intakeoutput">
                        <label htmlFor="strength">Status :</label>

                        <select
                            name="Status"
                            value={formDataProduct.Status}
                            onChange={handleInputChange}
                        // readOnly={selectedProductIndex}

                        >

                            <option value='Active'>Active </option>
                            <option value='InActive'>InActive </option>
                        </select>

                    </div>



                </div>
                <div className='Stoct_Product_master_form_Save'>
                    <button className=' Stoct_Product_master_form_Save_button' onClick={handleSaveOrUpdate} >
                        {isedit ? "Update" : "Save"}
                    </button>
                </div>
            </div>
            <div className='IP_grid'>
                <ThemeProvider theme={theme}>
                    <div className='IP_grid_1'>
                        <DataGrid
                            rows={filteredRows?.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                            columns={dynamicColumns} // Use dynamic columns here
                            pageSize={10}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            onPageChange={handlePageChange}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className=' Ip_data_grid'
                        />
                        {showdown > 0 && filteredRows.length > 10 && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() =>
                                        setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                    }
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </ThemeProvider>
                {showdown !== 0 && filteredRows.length !== 0 ? (
                    ''
                ) : (
                    <div className='IP_norecords'>
                        <span>No Records Found</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default RoomMasterCreate;
