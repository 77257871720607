import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";






export default function InsurancePreAuthEnhanceDocument() {




  const navigate = useNavigate();
  

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const InsuranceUpdatedata = useSelector(
    (state) => state.InsuranceStore?.InsuranceUpdatedata
  );

  // console.log('InsuranceUpdatedatalllllkkk',)

  const[EnhanceAmount,setEnhanceAmount]=useState('')

  // ------------


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const yourStyles={
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }



 
  //-------------------------------Enhanced

  const [rows10, setRows10] = useState([
    {
      Name:'doctorsLetter',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows11, setRows11] = useState([
    {
      Name:'interimBill',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  const [rows12, setRows12] = useState([
    {
      Name:'enhancedotherDocument',
      Document_file:null,
      Document_Remarks: "",
      Document_Date: "",
      Document_Ack: false,
    },
  ]);

  


  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }
  
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;
  
    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };
  

// ------------------------------------------------------------------------------------



  // Enhanced

  const handleChangeRowDocuments4 = (index, key, value) => {
    const updatedRows = [...rows10];
    updatedRows[index][key] = value;
    setRows10(updatedRows);
  };

  const addRow10 = () => {
    setRows10((prevRows) => [
      ...prevRows,
      {
        Name:'doctorsLetter',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow10 = (index) => {
    setRows10((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-------

  const handleChangeRowDocuments5 = (index, key, value) => {
    const updatedRows = [...rows11];
    updatedRows[index][key] = value;
    setRows11(updatedRows);
  };

  const addRow11 = () => {
    setRows11((prevRows) => [
      ...prevRows,
      {
        Name:'interimBill',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow11 = (index) => {
    setRows11((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //-----

  const handleChangeRowDocuments6 = (index, key, value) => {
    const updatedRows = [...rows12];
    updatedRows[index][key] = value;
    setRows12(updatedRows);
  };

  const addRow12 = () => {
    setRows12((prevRows) => [
      ...prevRows,
      {
        Name:'enhancedotherDocument',
        Document_file:null,
        Document_Remarks: "",
        Document_Date: "",
        Document_Ack: false,
      },
    ]);
  };
  const removeRow12 = (index) => {
    setRows12((prevRows) =>
      prevRows.filter((row, rowIndex) => rowIndex !== index)
    );
  };

  //------

 
  
 

  const EnhancedSavebtnFun =()=>{


    const MRN = InsuranceUpdatedata.MRN
    const ContactNumber=InsuranceUpdatedata.ContactNumber

    const Location = userRecord.location 
    const createAt = userRecord.username 


    // console.log('klkl',rows10,rows11,rows12)

    const formData = new FormData();

    formData.append('MRN', MRN);
    formData.append('ContactNumber', ContactNumber);
    formData.append('Location', Location);
    formData.append('createAt', createAt);

    formData.append('rows10', JSON.stringify(rows10));
    formData.append('rows11', JSON.stringify(rows11));
    formData.append('rows12', JSON.stringify(rows12));
    
    // Append Document_file from each row to formData
    const appendFiles = (formData, rows, prefix) => {
        rows.forEach((row, index) => {
            if (row && row.Document_file) {
                formData.append(`${prefix}[${index}][Document_file]`, row.Document_file);
            }
        });
    };
    
    appendFiles(formData, rows10, 'rows10');
    appendFiles(formData, rows11, 'rows11');
    appendFiles(formData, rows12, 'rows12');


    axios.post(`https://vesoftometic.co.in/Insurance/Update_EnhanceSave_Insurance`,formData)
    .then((response) => {
        console.log('Form data submitted.',response.data)
        
    })
    .catch((error) => {
        console.error(error);
    });

  }


  
// --------------------------------------------------------



  
useEffect(() => {

  if (Object.values(InsuranceUpdatedata).length !== 0 && InsuranceUpdatedata.Papersstatustype === "ENHANCED"&& InsuranceUpdatedata.Papersstatus === "APPROVED") {
      // console.log('Vathuruchu', InsuranceUpdatedata)
      axios.get(
          `https://vesoftometic.co.in/Insurance/get_Pre_Auth_Enhance_Documents_data`, {
          params: InsuranceUpdatedata.MRN
      }
      )
          .then((response) => {
              // console.log('vrrrr',response.data);

              const data = response.data
              const Doctors_Letter_data=data.Doctors_Letter_data
              const Interim_Bill_data=data.Interim_Bill_data
              const other_document_data=data.other_document_data
              // console.log('qqq', photo_id_data,other_document_data,signed_form_data)

              const updatedRows10 = Doctors_Letter_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });
            
            const updatedRows11 = Interim_Bill_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });
            
            const updatedRows12 = other_document_data.map((element) => {
                const file = base64toFile(element.Document_file, element.DecoFile1name, element.DecoFile1type);
                return {...element, Document_file: file};
            });


            if(updatedRows10.length !==0){
              setRows10(updatedRows10);
             }
            if(updatedRows11.length !==0){
              setRows11(updatedRows11);
             }
            if(updatedRows12.length !==0){            
              setRows12(updatedRows12);
             }

          })
          .catch((error) => {
              console.log(error);
          });

  }
}, [InsuranceUpdatedata])





// ------------------File

const handleVisibilityClick = async (ConsentForm) => {
  console.log('ConsentForm', ConsentForm.type);

  if (ConsentForm.type !== "application/pdf") {
    const fileURL = URL.createObjectURL(ConsentForm);
    setModalContent(fileURL);
    setModalIsOpen(true);
  } else {
    // Convert the PDF file to a base64 string
    const base64String = await readFileAsBase64(ConsentForm);
    const Pdffile = `data:application/pdf;base64,${base64String}`;
    console.log('Pdffile', Pdffile);
    setModalContent(Pdffile);
    setModalIsOpen(true);
  }
};

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

const closeModal = () => {
  setModalIsOpen(false);
  setModalContent('');
};

  return (
    <>
     <div className="Supplier_Master_Container">

        {/* Enhanced */}
    
        <div className="RegisFormcon column_regisFormcon_forinsurance">
            <div className="h_heade34">
            <h3>Pre-Auth Documents</h3>
            </div>

            <div className="Selected-table-container">
            <table className="selected-medicine-table2 _hide_hover_table">
                <thead className="Spl_backcolr_09">
                <tr>
                    <th className="Provisional_Diagnosis">
                    File Name
                    </th>
                    <th className="ICD_Code">Remarks</th>
                    <th className="ICD_Code">Submitted Date</th>
                    <th className="ICD_Code">ACK</th>
                    <th className="ICD_Code">Action</th>
                </tr>
                </thead>
                <tbody>
                {rows10.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows10[index]['Document_file'] === null ? <span>Doctor's Letter</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows10[index]['Document_file'])} >Doctor's Letter</span>}
                       <label className="file-labelx">
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{

                              const file = e.target.files[0];
                            handleChangeRowDocuments4(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments4(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments4(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments4(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow10}
                        >
                        <AddIcon className="add32_Code" />
                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow10(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {rows11.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows11[index]['Document_file'] === null ? <span>Interim Bill</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows11[index]['Document_file'])} >Interim Bill</span>}
                        <label className="file-labelx">                                                
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                              const file = e.target.files[0];
                            handleChangeRowDocuments5(
                                index,
                                "Document_file",
                                file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments5(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments5(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments5(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow11}
                        >
                        <AddIcon className="add32_Code" />
                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow11(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}

                {rows12.map((row, index) => (
                    <tr key={index}>
                    <td>
                    {rows12[index]['Document_file'] === null ? <span>Other Documents</span>
                    :<span style={{color:'blue', cursor: 'pointer'}} onClick={() => handleVisibilityClick(rows12[index]['Document_file'])} >Other Documents</span>}
                        <label className="file-labelx">
                        
                        <input
                            type="file"
                            className="file-inputx"
                            accept="image/pdf"
                            onChange={(e) =>{
                              const file = e.target.files[0];

                            handleChangeRowDocuments6(
                                index,
                                "Document_file",
                                  file
                            )
                            }}
                        />
                        <span className="file-buttonx">
                            Choose file
                        </span>
                        </label>
                    </td>
                    <td>
                        <input
                        type="text"
                        className="ICD_Code"
                        value={row.Document_Remarks}
                        onChange={(e) =>
                            handleChangeRowDocuments6(
                            index,
                            "Document_Remarks",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        className="medication_90"
                        value={row.Document_Date}
                        onChange={(e) =>
                            handleChangeRowDocuments6(
                            index,
                            "Document_Date",
                            e.target.value
                            )
                        }
                        />
                    </td>
                    <td>
                        <input
                        type="checkbox"
                        className="medication_90"
                        checked={row.Document_Ack}
                        onChange={(e) =>
                            handleChangeRowDocuments6(
                            index,
                            "Document_Ack",
                            e.target.checked
                            )
                        }
                        />
                    </td>
                    <td className="add32_Code">
                        {index === 0 ? (
                        <span
                            className="add32_Code"
                            onClick={addRow12}
                        >
                        <AddIcon className="add32_Code" />
                        </span>
                        ) : (
                        <span
                            className="add32_Code"
                            onClick={() => removeRow12(index)}
                        >
                         <RemoveIcon className="add32_Code" />
                        </span>
                        )}
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
        


      </div>

       
    
        <div className="submit_button_prev_next">
        <button onClick={EnhancedSavebtnFun}>
            Save                
        </button>
        </div>     

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
            <div className="pdf_img_show">
            {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
                <iframe
                title="PDF Viewer"
                src={modalContent}
                style={{
                    width: "100%",
                    height: "435px",
                    border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                }}
                />
            ) : (
                <img
                src={modalContent}
                alt="Concern Form"
                style={{
                    width: "80%",
                    height: "75%",
                    marginTop: "20px",
                }}
                />
            )}
            <div className="jhuhhjh">
                <Button
                style={{ color: "white" }}
                className="clse_pdf_img"
                onClick={closeModal}
                >
                <HighlightOffIcon
                    style={{
                    fontSize: "40px",
                    backgroundColor: "#54d854bf",
                    borderRadius: "40px",
                    }}
                />
                </Button>
            </div>
            </div>
        </Modal>


    
    </>
  );
}
