import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const IcuProgressNotes = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: "Center",
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
              {
                textAlign: "center",
                display: "flex !important",
                justifyContent: "center !important",
              },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: "flex",
            justifyContent: "center",
          },
        },
      },
    },
  });
  const [page, setPage] = useState(0);

  const [progressData, setProgressData] = useState(false);
  const [showTextarea, setShowTextarea] = useState(true);
  const [isSaveButton, setIsSaveButton] = useState(true);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );

  console.log("IpNurseQueSelectedRow", IpNurseQueSelectedRow);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("userRecord", userRecord);
  const [notes, setNotes] = useState("");
  const handleNotesChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 1000) {
      setNotes(inputValue);
    }
  };
  const handleNewButtonClick = () => {
    setShowTextarea(true);
    setIsSaveButton(true);
    setNotes("");
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };
  const dynamicColumns = [
    { field: "Captured_Date", headerName: "Captured Date", width: 120 },
    { field: "Captured_Time", headerName: "Captured Time", width: 120 },
    { field: "Progress_Notes", headerName: "Progress Notes", width: 500 },
  ];

  const handlePrintSave = () => {
    const requiredfields = ["fill the notes"];
    const existing = requiredfields.filter((field) => !notes[field]);

    if (existing.length > 0) {
      alert(("please fill empty fields:", existing.join(",")));
    } else {
      const data = new FormData();
      data.append("patientId", IpNurseQueSelectedRow?.PatientId);
      data.append("bookingId", IpNurseQueSelectedRow?.Booking_Id);
      data.append("Name", userRecord?.username);
      data.append("Department", userRecord?.username);
      data.append("role_id", userRecord?.role_id);
      data.append("Notes", notes);
      data.append("PrimaryDoctor", IpNurseQueSelectedRow.PrimaryDoctor);
      if (notes === "") {
        userwarn("please fill the notes");
      }
      axios
        .post(`https://vesoftometic.co.in/IcuManagement/insert_Doc_progress_notes`, data)
        .then((response) => {
          console.log("datas", response.data);
          setNotes("");
          setProgressData(!progressData);
        })
        .catch((error) => {
          // Handle error if needed
          console.error("Error :", error);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`https://vesoftometic.co.in/IcuManagement/get_Doc_progress_notes`)
      .then((response) => {
        const data = response.data;
        const Records = data?.map((userdata, index) => ({
          id: userdata?.Progress_Id,
          Patient_Id: userdata?.Patient_Id,
          Booking_Id: userdata?.Booking_Id,
          Department: userdata?.Department,
          Created_Name: userdata?.Created_Name,
          Captured_Date: userdata?.Captured_Date,
          Captured_Time: userdata?.Captured_Time,
          Progress_Notes: userdata?.Progress_Notes,
          Docter_Name: userdata?.Docter_Name,
        }));
        setFilteredRows(Records);
        const showdata = Records.find((r, index) => index === Records.length - 1);
        console.log(showdata, '00000 ');
        setNotes(showdata?.Progress_Notes);
        setIsSaveButton(false);
        console.log("progress notes data:", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [progressData]);

  return (
    <div>
      <div className="RegisForm_1 ">
        <label htmlFor="FirstName">
          Primary Doctor <span>:</span>{" "}
        </label>

        <span className="dctr_wrbvh_pice" htmlFor="FirstName">
          {`${IpNurseQueSelectedRow?.PrimaryDoctor}`}
        </span>
      </div>
      <div className="form-section5">
        <div className="form-field5">
          <label htmlFor="notes">
            {" "}
            Progress Notes <span>:</span>{" "}
          </label>
          <textarea
            id="notes"
            name="notes"
            value={notes}
            onChange={handleNotesChange}
            maxLength={1000}
            readOnly={!showTextarea || !isSaveButton}
          />
        </div>
      </div>

      <div className="Register_btn_con">
        {isSaveButton ? (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handlePrintSave}
          >
            Save
          </button>
        ) : (
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handleNewButtonClick}
          >
            New
          </button>
        )}
      </div>
      <div className="IP_grid">
        <ThemeProvider theme={theme}>
          <div className="IP_grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" Ip_data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default IcuProgressNotes;
