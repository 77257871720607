import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
// import './PatientQueueList.css';
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function ShiftDetails() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord);
  const navigate = useNavigate();
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);


  useEffect(() => {
    fetch(
      `${urllink}HRmanagement/Get_shift_details?location=${userRecord?.location}&employeeid=${userRecord?.EmployeeId}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' is an array as per the provided data structure
        console.log(data);

        setRows(data)
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [userRecord?.location, userRecord?.EmployeeId]);


  return (
    <>

      <div className="appointment">
        <div className="h_head">
          <h3>Shift Details</h3>
        </div>
        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
                <th id="slectbill_ins">Shift startdate</th>
                <th id="slectbill_ins">Shift Enddate</th>
                <th id="slectbill_ins">Shift</th>
                <th id="slectbill_ins">Start Time</th>
                <th id="slectbill_ins"> End Time</th>
                <th id="slectbill_ins">Shift Hours</th>
                <th id="slectbill_ins">Status</th>
              </tr>
            </thead>
            {rows.length > 0 ? rows.map((leave, index) => (
              <tbody>

                <tr key={index}>
                  <td>{leave.Shift_StartDate}</td>
                  <td>{leave.Shift_EndDate}</td>
                  <td>{leave.ShiftName}</td>
                  <td>{leave.Shift_StartTime}</td>
                  <td>{leave.Shift_EndTime}</td>
                  <td>{leave.ShiftHours}</td>
                  <td>{leave.Status}</td>
                </tr>

              </tbody>
            )) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </table>

        </div>
      </div >
    </>
  );
}

export default ShiftDetails;




