import React, { useState, useEffect } from "react";
import "./GroupMaster.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GroupMaster() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [isEditMode, setIsEditMode] = useState(false);
  const [hide, setHide] = useState(false);
  const [groupMasData, setGroupMasData] = useState([]);
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const [testname, setTestName] = useState([]);
  const [selecttest, setselecttest] = useState('')
  const [selectedtest, setselectedtest] = useState([])
  const [testdetails, setTestdetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [SelectedFile, setSelectedFile] = useState(null);

  const testdatas = useSelector((state) => state.userRecord?.GroupMasterData);
  console.log("testdatas", testdatas)
  const [formData, setFormData] = useState({
    groupCode: "",
    groupName: "",
    displayName: "",
    billingCode: "",
    billingName: "",
    gender: "",
    reportType: "",
    departmentName: "",
    testCategory: "",
    logicalCategory: "",
    authorizedUser: "",
    lonicCode: "",
    groupCost: "",
    InterpretationHead: "",
    Interpretation: ""
  });


  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
     if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const [tableData, setTableData] = useState([]);

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const warnmessage = (wmsg) => {
    toast.warn(`${wmsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      "groupCode",
      "groupName",
      "billingCode",
      "billingName",
      "gender",
      "reportType",
      "departmentName",
      "testCategory",
      "logicalCategory",
      "authorizedUser",
      "lonicCode",
      "groupCost"
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length === 0) {
      try {
        // Determine the endpoint based on editMode
        const endpoint = editMode
          ? `${urllink}usercontrol/updategroupmaster`
          : `${urllink}usercontrol/insertgroupmaster`;

        // Make a POST request to the appropriate endpoint
        const response = await axios.post(endpoint, formData);

        // Handle the response as needed
        console.log(response.data);
        setHide(true);

        // Reset the form data after submission
        setFormData({
          groupCode: "",
          groupName: "",
          displayName: "",
          billingCode: "",
          billingName: "",
          gender: "",
          reportType: "",
          departmentName: "",
          testCategory: "",
          logicalCategory: "",
          authorizedUser: "",
          lonicCode: "",
          groupCost: "",
          InterpretationHead: "",
          Interpretation: ""
        });

        // Optionally, you can fetch the updated data after successful submission
        fetchGroupMasterData();
        fetchGroupMasterList();

        if (!editMode) {
          // If it's not in edit mode, insert into insert_groupmaster_testlist
          await axios.post(
            `${urllink}usercontrol/insert_groupmaster_testlist`,
            selectedtest
          );

          console.log("Successfully inserted into insert_groupmaster_testlist");
        }

        // Reset the editMode state after submission
        setEditMode(false);
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenarios
      }
    } else {
      userwarn(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };



  React.useEffect(() => {
    fetchGroupMasterData();
    fetchGroupMasterList();
  }, []);
  const fetchGroupMasterData = () => {
    axios
      .get(`${urllink}usercontrol/getgroupmasterdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setTableData(data);
      })
      .catch((error) => {
        console.error("Error fetching groupmaster data:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urllink}usercontrol/getalltestname`
        );
        console.log(response.data);
        setTestName(response.data);
      } catch (error) {
        console.error("Error fetching test names:", error);
      }
    };

    fetchData();
  }, []);
  // Inside your component
  // 
  useEffect(() => {
    if (testdatas) {
      setFormData((prevData) => ({
        ...prevData,
        groupCode: testdatas.group_code,
        groupName: testdatas.group_name,
        displayName: testdatas.display_name,
        billingCode: testdatas.billing_code,
        billingName: testdatas.billing_name,
        gender: testdatas.gender,
        reportType: testdatas.report,
        departmentName: testdatas.department_name,
        testCategory: testdatas.test_category,
        logicalCategory: testdatas.logical_category,
        authorizedUser: testdatas.authorized_user,
        lonicCode: testdatas.lonic_code,
        group_master_id: testdatas.id
      }));
      setIsEditMode(testdatas.isEditMode)
    }
  }, [testdatas]);




  const handleEdit = (row) => {
    setFormData({
      groupCode: row.group_code,
      groupName: row.group_name,
      displayName: row.display_name,
      billingCode: row.billing_code,
      billingName: row.billing_name,
      gender: row.gender,
      reportType: row.report,
      departmentName: row.department_name,
      testCategory: row.test_category,
      logicalCategory: row.logical_category,
      authorizedUser: row.authorized_user,
      lonicCode: row.lonic_code,
      groupCost: row.group_cost
    });

    setIsEditMode(true);
    setSelectedMethodId(row.group_master_id); // Assuming `group_master_id` is the identifier
  };

  const handleUpdateGroupMaster = async () => {
    try {
      const response = await axios.post(
        `${urllink}usercontrol/updategroupmaster`,
        {
          group_master_id: selectedMethodId,
          groupCode: formData.groupCode,
          groupName: formData.groupName,
          displayName: formData.displayName,
          billingCode: formData.billingCode,
          billingName: formData.billingName,
          gender: formData.gender,
          reportType: formData.reportType,
          departmentName: formData.departmentName,
          testCategory: formData.testCategory,
          logicalCategory: formData.logicalCategory,
          authorizedUser: formData.authorizedUser,
          lonicCode: formData.lonicCode,
          groupCost: formData.groupCost,
        }
      );
      console.log(response.data);

      setFormData({
        groupCode: "",
        groupName: "",
        displayName: "",
        billingCode: "",
        billingName: "",
        gender: "",
        reportType: "",
        departmentName: "",
        testCategory: "",
        logicalCategory: "",
        authorizedUser: "",
        lonicCode: "",
        groupCost: "",
      });
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchGroupMasterData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const handleInsertGroupMasterTestList = () => {

    console.log(selecttest)
    const newvalues = {
      groupCode: formData.groupCode,
      groupName: formData.groupName,
      TestName: selecttest
    }
    console.log(newvalues)
    setselectedtest(
      prev => ([
        ...prev,
        newvalues
      ])
    )
  }


  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
     if (type === "Documents") {
        axios
          .post(
            `${urllink}usercontrol/post_groupmaster_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error);
          });
      }
    }
  };


  const fetchGroupMasterList = () => {
    axios
      .get(`${urllink}usercontrol/getgroupmastermasterlist`)
      .then((response) => {
        const data = response.data;
        console.log("masterlist", data);

        setTestdetails(data);
      })
      .catch((error) => {
        console.error("Error fetching groupmaster masterlist data:", error);
      });
  };

  // };
  const handleDelete = (index) => {
    // Create a copy of the selectedtest array
    const updatedTests = [...selectedtest];

    // Remove the row at the specified index
    updatedTests.splice(index, 1);

    // Update the state with the modified data
    setselectedtest(updatedTests);
  };



  console.log("formData", formData)

  const handleTestdata = (testData) => {
    const updatedata = {
      ...formData
    }
    console.log(updatedata)
    axios.post(`${urllink}usercontrol/updategroupmaster`, updatedata)
      .then((response) => {
        console.log(response.data)
        setFormData({
          groupCode: "",
          groupName: "",
          displayName: "",
          billingCode: "",
          billingName: "",
          gender: "",
          reportType: "",
          departmentName: "",
          testCategory: "",
          logicalCategory: "",
          authorizedUser: "",
          lonicCode: "",
          groupCost: "",
        });
      })

      .catch((error) => {
        console.log(error)
      })

  };





  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Group Master</h4>
      </div>
      <div className="RegisFormcon">

     
            <div className="RegisForm_1">
              <label className="" htmlFor="groupCode">
                Group Code<span>:</span>
              </label>
              <input
                type="text"
                id="groupCode"
                name="groupCode"
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                // className="new_clinic_form_inp111"
                // placeholder="Enter your Group Code"
                required
                onChange={handleInputChange}
                value={formData.groupCode}
              />
            </div>
            <div className="RegisForm_1">
              <label className="" htmlFor="GroupName">
                Group Name<span>:</span>
              </label>
              <input
                type="text"
                id="groupName"
                name="groupName"
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                // className="new_clinic_form_inp111"
                // placeholder="Enter your Group Name"
                required
                onChange={handleInputChange}
                value={formData.groupName}
              />
            </div>
         
        
            <div className="RegisForm_1">
              <label htmlFor="displayName" className="">
                Display Name<span>:</span>
              </label>
              <input
                type="text"
                id="displayName"
                name="displayName"
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                // className="new_clinic_form_inp111"
                // placeholder="Enter your Display Name"
                required
                onChange={handleInputChange}
                value={formData.displayName}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="billingCode" className="">
                Billing Code<span>:</span>
              </label>

              <input
                type="text"
                id="billingCode"
                name="billingCode"
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                // className="new_clinic_form_inp111"
                // placeholder="Enter your Billing Code"
                required
                onChange={handleInputChange}
                value={formData.billingCode}
              />
            </div>
   
   
          <div className="RegisForm_1">
            <label htmlFor="billingName" className="">
              Billing Name<span>:</span>
            </label>
            <input
              type="text"
              id="billingName"
              name="billingName"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              // className="new_clinic_form_inp111"
              // placeholder="Enter your Billing Name"
              required
              onChange={handleInputChange}
              value={formData.billingName}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="gender" >
              Gender<span>:</span>
            </label>
            <select
              id="gender"
              name="gender"
              required
              // className="new_clinic_form_inp111"
              onChange={handleInputChange}
              value={formData.gender}
            >
              <option value="">Select</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>
       
        {/* <div><dddd/div> */}
     
          <div className="RegisForm_1">
            <label htmlFor="report" >
              Report<span>:</span>
            </label>
            <select
              id="reportType"
              name="reportType"
              required
              // className="new_clinic_form_inp111"
              onChange={handleInputChange}
              value={formData.reportType}
            >
              <option value="">Select</option>
              <option value="INPATIENT">Inpatient</option>
              <option value="OUTPATIENT">Outpatient</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="departmentName" className="">
              Department Name<span>:</span>
            </label>
            <select
              id="departmentName"
              name="departmentName"
              required
              // className="new_clinic_form_inp111"
              onChange={handleInputChange}
              value={formData.departmentName}
            >
              <option value="">Select</option>
              <option value="BLOOD">Blood</option>
              <option value="SAMPLE">Sample</option>
            </select>
          </div>


        {/* <div><dddd/div> */}
    
          <div className="RegisForm_1">
            <label htmlFor="testcategory" className="">
              Test Category<span>:</span>
            </label>

            <select
              id="testCategory"
              name="testCategory"
              // className="new_clinic_form_inp111"
              onChange={handleInputChange}
              value={formData.testCategory}
            >
              <option value="">Select</option>
              <option value="INPATIENT">Inpatient</option>
              <option value="OUTPATIENT">Outpatient</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="logicalcategory" className="">
              Logical Category<span>:</span>
            </label>

            <select
              id="logicalCategory"
              name="logicalCategory"
              // className="new_clinic_form_inp111"
              onChange={handleInputChange}
              value={formData.logicalCategory}
            >
              <option value="">Select</option>
              <option value="INPATIENT">Inpatient</option>
              <option value="OUTPATIENT">Outpatient</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>
       

          <div className="RegisForm_1">
            <label htmlFor="autoauthorizeduser" className="">
              Auto Authorized User<span>:</span>
            </label>

            <input
              type="text"
              id="authorizedUser"
              name="authorizedUser"
              // className="new_clinic_form_inp111"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              // placeholder="Enter your Auto Authorized User"
              required
              onChange={handleInputChange}
              value={formData.authorizedUser}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="loniccode" className="">
              Lonic Code<span>:</span>
            </label>

            <input
              type="text"
              id="loincCode"
              name="lonicCode"
              // className="new_clinic_form_inp111"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              // placeholder="Enter your Lonic Code"
              required
              onChange={handleInputChange}
              value={formData.lonicCode}
            />
          </div>

       
     
          <div className="RegisForm_1">
            <label htmlFor="autoauthorizeduser" className="">
              Interpretation Head<span>:</span>
            </label>

            <textarea
              type="text"
              id="InterpretationHead"
              name="InterpretationHead"
              // className="new_clinic_form_inp111"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              // placeholder="Enter Head"
              required
              onChange={handleInputChange}
              value={formData.InterpretationHead}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="loniccode" className="">
              Interpretation<span>:</span>
            </label>

            <textarea
              type="text"
              id="Interpretation"
              name="Interpretation"
              // className="new_clinic_form_inp111"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              // placeholder="Enter Interpretation"
              required
              onChange={handleInputChange}
              value={formData.Interpretation}
            />
          </div>

      
        {/* testname */}
        {!isEditMode && (
 <>
            <div className="RegisForm_1">
              <label htmlFor="groupcost" className="">
                Group Cost<span>:</span>
              </label>

              <input
                type="number"
                id="groupCost"
                name="groupCost"
                // className="new_clinic_form_inp111"
                pattern="[A-Za-z ]+"
                title="Only numbers and spaces are allowed"
                // placeholder="Enter your Group Cost"
                required
                onChange={handleInputChange}
                value={formData.groupCost}
              />
            </div>

            <div className="RegisForm_1">
              <label className="" htmlFor="test names">
                Test Name <span>:</span>
              </label>
              {/* <span style={{ display: "flex", gap: "2rem" }}> */}
                <Autocomplete
                  freeSolo
                  id="test-name-autocomplete"
                  disableClearable
                  // style={{ width: "180px" }}
                  className="auto_completed_solo"
                  name="testname"
                  onChange={(e) => { setselecttest(e.target.textContent) }}
                  value={selecttest}

                  options={testname.map((option) => option)} // Map your testName array to the options prop
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        className: "Supplier_Master_form_Con_div_Row_input_autocomplete",
                  style:{border: 'none'}

                      }}
                    />
                  )}
                />

              {/* </span> */}
            </div>
            <div className="RegisForm_1">
                  <label>
                    {" "}
                    Upload CSV File <span>:</span>{" "}
                  </label>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    id="Servicechoose"
                    required
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="Servicechoose"
                    className="RegisterForm_1_btns choose_file_update"
                  >
                    Choose File
                  </label>
                  <button
                    className="RegisterForm_1_btns choose_file_update"
                    onClick={() => handleCsvupload("Documents")}
                  >
                    Upload
                  </button>
                </div>

            </>  )}
            </div>

        {!isEditMode && <div className="Register_btn_con">
          <button
            className="btn_1"
            onClick={handleInsertGroupMasterTestList}
          // onClick={handleSubmit}
          >
            <AddIcon />
          </button>
        </div>}


        {!isEditMode && <div>

          {/* <h4>Table</h4> */}

          {/* Inside your render function */}

          <div className="Selected-table-container ">
            <table className="selected-medicine-table2 ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Group Code</th>
                  <th>Group Name</th>
                  <th>Test Name</th>

                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {selectedtest.map((row, index) => (

                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.groupCode}</td>
                    <td>{row.groupName}</td>

                    <td>{row.TestName}</td>
                    <td>
                      <button onClick={() => handleDelete(index)}>

                        <DeleteIcon />
                      </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
        <div className="Register_btn_con">
          {/* <button
            className="btn_1"
             onClick={handleSubmit}
          >
          submit
          </button> */}
          <button className="RegisterForm_1_btns" onClick={isEditMode ? handleTestdata : handleSubmit}>
            {isEditMode ? 'Update' : 'Submit'}
          </button>

        </div>


        <ToastContainer />
     
    </div>
  );
}

export default GroupMaster;
