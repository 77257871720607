import React, { useEffect, useState } from 'react'
import "./Commonregister.css";
import OpRegistertration from './OpRegistertration';
import InPatientRegistration from './InPatientRegistration';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const CommonRegistration = () => {
  const navigate = useNavigate();
  const [AppointmentRegisType,setAppointmentRegisType]=useState('OP')
  const [isbookingedit, setisbookingedit] = useState(false)
  const IpAdmissionEdit = useSelector(state => state.InPatients?.IpAdmissionEdit);
  
 
  useEffect(() => {
    if (Object.keys(IpAdmissionEdit).length !== 0) {
      setisbookingedit(true)
      setAppointmentRegisType(IpAdmissionEdit.type)
    
    } 
  }, [IpAdmissionEdit])

  


  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Registration</h4>
        </div>
        <div className="RegisterTypecon">
          <div className="RegisterType">
            {["OP", "IP"].map((p, ind) => (
              <div className="registertypeval" key={ind}>
                <input
                  type="radio"
                  id={p}
                  name="appointment_type"
                  checked={AppointmentRegisType === p}
                  onChange={(e) => setAppointmentRegisType(e.target.value)}
                  value={p}
                  disabled={p==='OP'&&isbookingedit}
                />
                <label htmlFor={p}>
                  {p === "OP"
                    ? "Out Patient (OP)"
                    : p === "IP"
                      ? "In Patient (IP)"
                      : p}
                </label>
              </div>
            ))}
          </div>
        </div>
        <br />
        {AppointmentRegisType==='OP' && <OpRegistertration/>}
        {AppointmentRegisType==='IP' && <InPatientRegistration/>}
      </div>
    </>
  )
}

export default CommonRegistration
