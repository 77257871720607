import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../ICUNurseflow/IcuNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IcuDocIntakeOutput = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const [summa, setsumma] = useState([]);
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    if(type==='Intake'){
      setPage(params.page);
    }else{
      setPage1(params.page);
    }
  };
  const [type, setType] = useState("Intake");



  useEffect(()=>{
if(IpNurseQueSelectedRow &&IpNurseQueSelectedRow?.Booking_Id){
  axios.get(`https://vesoftometic.co.in/IcuManagement/get_Intake_Output_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&gettype=${type}`)
  .then((response)=>{
    const data = response.data;
      console.log("222", data);
      setsumma([
        ...data.map((row, ind) => ({
          id: ind + 1,
          ...row,
        })),
      ]);
  })
  .catch((error)=>{
    console.log(error)
  })
}
  },[type,IpNurseQueSelectedRow,IpNurseQueSelectedRow?.Booking_Id])
  const handleChange = (event) => {
    setType(event.target.value);

  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const dynamicColumns = ()=>{
    if(type==='Intake'){
        return([
            { field: 'id', headerName: 'S_No', width: 100 },
            { field: 'Date', headerName: 'Date', width: 100 },
            { field: 'Time', headerName: 'Time', width: 150 },
            { field: 'IntakeType', headerName: 'Intake Type', width: 150 },
            { field: 'IntakeMode', headerName: 'Intake Mode', width: 100 },
            { field: 'Site', headerName: 'Site', width: 200 },
            { field: 'Measurement', headerName: 'Measurement', width: 150 },
            { field: 'Duration', headerName: 'Duration', width: 120 },
            { field: 'Remarks', headerName: 'Remarks', width: 150 },
        ])
    }
    else{
        return([
            { field: 'id', headerName: 'S_No', width: 100 },
            { field: 'Date', headerName: 'Date', width: 100 },
            { field: 'Time', headerName: 'Time', width: 150 },
            { field: 'OutputType', headerName: 'Output Type', width: 200 },
            { field: 'Measurement', headerName: 'Measurement', width: 150 },
            { field: 'Remarks', headerName: 'Remarks', width: 150 },
        ])
  }
  }
    
       
        
  return (
    <>
      <div className="Supplier_Master_Container">
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Intake"
              style={{
                height: "30px",
                width: "100px",
                backgroundColor:
                  type === "Intake"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              Intake
            </ToggleButton>
            <ToggleButton
              value="Output"
              style={{
                backgroundColor:
                  type === "Output"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
                width: "100px",
                height: "30px",
              }}
              className="togglebutton_container"
            >
              Output
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
     
       

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={type==='Intake'?summa.slice(page * pageSize, (page + 1) * pageSize):summa.slice(page1 * pageSize, (page1 + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      type==='Intake'?
                      setPage((prevPage) => Math.max(prevPage - 1, 0)):
                      setPage1((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={type==='Intake'?page:page1 === 0}
                  >
                    Previous
                  </button>
                  Page {type==='Intake'?page:page1 + 1} of {totalPages}
                  <button
                    onClick={() =>
                      type==='Intake'?
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      ):
                      setPage1((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                    }
                    disabled={type==='Intake'?page:page1 === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default IcuDocIntakeOutput;
